
import React, { useState } from 'react';
import CustomButton from './CustomButton';
import { SvgIcons } from '../icons';

const ImageUpload = ({ imageFile, ratio, isCustomButton,index, formik, state }) => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const handleFileInputChange = (event) => {
    let currentRatio = ratio || (16 / 9);
    const files = event.target.files;

    const uploadedImages = [];
    // Loop through each selected image file

    // console.log('arr',state);



    for (let i = 0; i < files.length; i++) {

      console.log('Starte', [state , i]);



      if (state == 'topSection') {
        formik.setValues({
          ...formik.values, topSection: {
            images: [...formik.values.topSection.images, files[i]],
            description: formik.values.topSection.description
          }
        })
      } else if (state == 'bottomSection') {
        formik.values.bottomCards[index].image = files[i]
        formik.setValues({ ...formik.values })
       
      }
      console.log('Starte', formik.values);
      const file = files[i];
      // Check if file is an image
      if (!file || !file.type.startsWith('image/')) {
        setError('Please select an image file.');
        return;
      }

      // Load the image file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          // Check if image has 16:9 aspect ratio
          if (img.width / img.height !== currentRatio) {
            // Crop the image to 16:9 aspect ratio
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const aspectRatio = currentRatio;
            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width / img.height > aspectRatio) {
              newWidth = img.height * aspectRatio;
            } else {
              newHeight = img.width / aspectRatio;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            const x = (img.width - newWidth) / 2;
            const y = (img.height - newHeight) / 2;

            ctx.drawImage(img, x, y, newWidth, newHeight, 0, 0, newWidth, newHeight);

            // Set the image state
            canvas.toBlob((blob) => {
              const url = URL.createObjectURL(blob);
              console.log('url', url);
              uploadedImages.push(url);
              setImages(url);
              imageFile(url);
            }, 'image/jpeg');
          } else {
            // Set the image state
            const url = URL.createObjectURL(file);
            uploadedImages.push(url);
            setImages(url);
            imageFile(url);
          }

          setError('');
        }
      }
    }

  }
  // const handleFileInputChange = (event)=>{
  //   const files = event.target.files;
  //     console.log('FILES',files);
  // }
  const hiddenFileInput = React.useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return (
    <label>
      {!isCustomButton ?
        <CustomButton
          onClick={handleClick}
          btntext="upload"
          varient="tertiary"
        />
        :

        <div className='mr_8 cp'
          onClick={() => handleClick}
        >
          <SvgIcons.Add />
        </div>
      }
      <input type="file" ref={hiddenFileInput} multiple onChange={handleFileInputChange} style={{ display: 'none' }} />
    </label>
  );
}
export default ImageUpload;

