import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/staff'

const Create = async({payload}) => await axios.post(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})

const Update = async({payload}) => await axios.put(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})

const ResendEmail = async({payload}) => await axios.post(env.API_URL+service+'/resend', payload, {headers: await MainService.getTokenHeader()})

const Get = async({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Delete = async({query}) => await axios.delete(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const BusinessUserService = {
    Create      : Handlers.Services(Create),
    Update      : Handlers.Services(Update),
    Get         : Handlers.Services(Get),
    Delete      : Handlers.Services(Delete),
    ResendEmail : Handlers.Services(ResendEmail),
}

export default BusinessUserService
