import { CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ActiveStatus from '../../../../components/ActiveStatus'
import CustomButton from '../../../../components/CustomButton'
import Loader from '../../../../components/Loader'
import { BrandContext } from '../../../../context/Brand.context'
import { LayoutContext } from '../../../../context/Layout.context'
import { Communication } from '../../../../enums'
import { CommunicationService } from '../../../../services'
import Actions from '../Actions'
import CommunicationList from '../CommunicationList'

export default function PushNotificationList(props) {
    
    const layout  = useContext(LayoutContext)
    const brand   = useContext(BrandContext)
    const history = useHistory()

    const [state, setState] = useState({
        loader   : true,
        apiError : ''
    })
    const [tableData, setTableData] = useState([])

    const viewDetailsFunc = (template) => {
        // console.log('working', template);
    }


    const tableHeadings =  [
        { id: 'serial', label: 'Serial' },
        { id: 'name', label: 'Template Name' },
        { id: 'message', label: 'Message' },
        { id: 'actions', label: 'Actions' },
        { id: 'active', label: 'Active' },
    ]


    const handleDeleteFunc = async (template) => {
        setState({loader: true})
        const payload = {
            _id     : template._id,
            delete  : true,
        }

        const {error, response} = await CommunicationService.Update({toaster: true, payload})
        if(error) return setState({loader: false, apiError: error.message})
             
            tableData.map((data, idx)=> {
                if(data.props.id === template._id)
                {
                    tableData.splice(idx, 1);
                }
            })


            // setTableData(tableData);
        
        return setState({loader: false})
    }

    const handleEditFunc = (template) => {
        history.push({
            pathname : props.match.url + '/push/create/' + template._id,
            state    : template
        })
    }


    const handleResponse = (response) => {

        const smsTemplates = response.map((element, index) => 
            // {
                createData({
                    serial      :   index,
                    name        :   element.communicationName,
                    message     :   element.message,
                    active      :   <ActiveStatus active={element.active} />,
                    actions     :   <Actions
                                        viewDetailsFunc  = {() => viewDetailsFunc(element)}
                                        handleDeleteFunc = {() => handleDeleteFunc(element)}
                                        handleEditFunc   = {() => handleEditFunc(element)}
                                    />,
                    _id         :   element._id,
                    template    :   element
                })
            // }
        )

        setTableData([...smsTemplates])

    }

    const filterArrayDelete = (response) => {
        const arr = []
        response.data.map((element, idx)=>{
            if(element.delete === false)
            {
                arr.push({...element})
            }
        })


        handleResponse(arr);
    }
 

    const onLoad = async () => {
        const query = {
            brandId           : brand._id,
            communicationType : Communication.CommunicationTypes.PUSH_NOTIFICATION
        }
        const {error, response} = await CommunicationService.GetQueriedCommunication({query})
        // console.log('response', response);
        setState({...state, loader: false})
        error && setState({...state, apiError: error.message, loader: false})
        response && filterArrayDelete(response)
        // console.log('asd');
    }

    useEffect(onLoad, [])
    
    useEffect(()=>{
        // console.log('sdsdsd');
        // layout.setLayout({   
        //     title       : "Push Notification",
        //     mainPage    : 'Communication',
        //     borderBottom: true,
        //     backTitles  : ['Communication'],
        //     edit        : false,
        //     button      : <CustomButton icon={!state.loader ? <CircularProgress size={20} color={'inherit'}/> : ''} className=""  btntext={'Create Push Notification'} onClick={()=>history.push(props.match.url + '/push/create')} />
        // });
    },[])


    function createData({serial ,name, phone,  message, active, _id, actions, template}) {
        return { 
            props: {
                name             : 'rowProps',
                class            : '',
                id               : _id,
                communicationName: template.communicationName,
                communicationType: template.communicationType,
                checkbox         : false
            },
            template: {template},
            serial: {
                label: serial,
                class: '',
            },
            name: {
                label   : name,
                class   : '',
                numeric : false
            },
            message: {
                label : message,
                class : '',
            }, 
            actions: {
                label   : actions,
                class   : '',
                numeric : false
            }, 
            active: {
                label   : active,
                class   : '',
                numeric : false
            },
            
        };
    }

    return (
        state.loader ? <Loader width={180} height={180}/> :
        <> 
            <CustomButton icon={state.loader ? <CircularProgress size={20} color={'inherit'}/> : ''} className="align-items-center position-absolute"  btntext={'Create Push Notification'} style={{top: '15px', right: '15px', zIndex: '9999'}} onClick={()=>history.push(props.match.url + '/push/create')} />
            <CommunicationList {...props} btntext="Add Notification" title={''} tableHeadings={tableHeadings} response={tableData}/>
        </>
    )
}
