import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/template'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Get = async () => await axios.get(env.API_URL+service, {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GetQueriedTemplate = async ({query}) => await axios.get(env.API_URL+service + '/getbyemail'+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const TemplateService = {
    Create              : Handlers.Services(Create),
    Get                 : Handlers.Services(Get),
    Update              : Handlers.Services(Update),
    GetQueriedTemplate  : Handlers.Services(GetQueriedTemplate),
}

export default TemplateService                          
