import * as yup from 'yup';
import { User } from '../enums'

const Create = yup.object({
    fullName    : yup.string().required('This field is required'),
    email       : yup.string().email().required(),
    subbrand    : yup.string(),
    // dialingCode : yup.string().required('This field is required').matches(/^\+[1-9]\d{0,4}$/),
    dialingCode : yup.string().matches(/^(\+?\d{1,3}|\d{1,4})$/),
    phone       : yup.string().matches(/^\d{1,10}$/),
    role        : yup.string().required().oneOf(Object.values(User.Roles))
})


export {
    Create,
}