import React, {useState, useEffect, useContext} from 'react'

import {useHistory} from 'react-router-dom'

import FitContentInput from '../../../components/FitContentInput'
import CustomButton from '../../../components/CustomButton'

import {PngIcons, SvgIcons} from '../../../icons'

import {AgencyContext} from '../../../context/Agency.context'
import {LayoutContext} from '../../../context/Layout.context'
import {BrandContext} from '../../../context/Brand.context'

import { ColorSchemeCode } from '../../../enums/ColorScheme'
import { CircularProgress } from '@mui/material'
import CustomSelect from '../../../components/CustomSelect'
import { Options } from '../../../utils'
import ConnectionsView from '../subpages/ConnectionsView'
import { CampaignService, FlowService, GrowthtoolService } from '../../../services'

import { useFormik } from 'formik';
import { GrowthtoolVld } from '../../../validation'
import { showToaster } from '../../../utils/utils'
import * as localForage from 'localforage'
import { GrowthTools } from '../../../enums'
import Loader from '../../../components/Loader'

export default function CW() {
    const [flow, setFlow] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})
    const [loader , setLoader] = useState(false);
    
    const brand   = useContext(BrandContext)
    const layout  = useContext(LayoutContext)
    const agency  = useContext(AgencyContext)
    const history = useHistory();
    
    const [state, setState] = useState({
        processing : false,
        apiError   : '',
    })

    const [growthtool, setGrowthtool] = useState(history.location.state)

    const onLoad = async() => {
        setLoader(true)
        layout.setLayout({editTitle  : formik.values.growthtoolName || ""})

        const {response, error} = await FlowService.Get({query: { brandId: brand._id, flowType:'new' ,delete: false }})
        
        if(response) {
            setFlow(response.data);
        }
        setLoader(false)
    }

    const handleCampaigns = (campaigns) => {
        setCampaigns([...campaigns])
        const campaign = campaigns.find(campaign => campaign._id === formik.values.campaignId)
        setSelectedCampaign(campaign || {})
    }
    

    const CallServices = async () => {
        const query = {
            brandId      : brand._id,
            campaignType : 5
        }
        const { error, response } = await CampaignService.GetQueriedCampaign({query})
        response && handleCampaigns(response.data)
    }

    useEffect(()=>{
        onLoad();
        CallServices();
    },[])


    const initState = {
        flow           : history.location.state && history.location.state.flowId ? history.location.state.flowId[0]              : '',
        growthtoolName : history.location.state && history.location.state.growthtoolName ? history.location.state.growthtoolName : '',
        growthtoolType : history.location.state && history.location.state.growthtoolType ?history.location.state.growthtoolType  : '',
        flowName       : '',
    }

    const formik = useFormik({
        initialValues      : { ...initState },
        validationSchema   : GrowthtoolVld.CW,
        isInitialValid     : true,
        enableReinitialize : true
    })


    const setFlowName = () => {

        flow.filter((element, idx)=>{
            (element._id == formik.values.flow) && formik.setValues({...formik.values, flowName : element.name})
        })
    }

    useEffect(()=>{
        setFlowName();
    },[formik.values.flow, flow])

      const handleSubmit = async() => {
      
            setState({...state, processing: true})
            const payload = {
                _id           : growthtool._id,
                campaignId    : campaigns[0]._id,
                flowId: flow.length === 1 ? [flow[0]._id] : [formik.values.flow],
                growthtoolName: layout.elements.editTitle
            }
            // console.log('payload ', payload)
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET){
                const user = await localForage.getItem('user')
                payload.widget.desiredPath = `${user._id}/${brand.brandName}`
            }
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.USER_DASHBOARD){
                const user = await localForage.getItem('user')
                payload.userDashboard.desiredPath = `${user._id}/${brand.brandName}`
            }
    
            const { error, response } = await GrowthtoolService.UpdateGrowthTool({toaster: true, payload})
            setState({...state, processing: false})
            if(error) return setState({...state, processing: false, apiError: error.message})
    
            const location = window.location.pathname.split('/')
            location.pop()
            location.pop()
            history.push(location.join('/'))
            return
    }

    useEffect(()=>{
        layout.setLayout({
            edit       : true,placeholder : "Growthtool",
            borderBottom: true,
            backTitles  : ['Channels'],
            switch      : "",
            button     : 
                <CustomButton
                    btntext  = {'Publish & close'}
                    type     = {'submit'}
                    onClick  = {handleSubmit}
                    disabled = {!formik.isValid}
                    icon     = {state.processing && <CircularProgress size={20} color={'inherit'}/>}
                />
        })
         },[campaigns, formik.values, growthtool ,state.processing,layout.elements.editTitle])


    useEffect(()=>{
        layout.setLayout({
            editTitle : growthtool.growthtoolName || ''
        })
        console.log("FORMIK", formik.values)
    },[])



    return (
        <div id="manyChatGrowthTool" >
            {/* <Header growthtool={growthtool} campaigns={campaigns} history={history} formik={formik} /> */}
            {!loader ?  <div className="insideComponent d-flex">
                <div className="w-30">
                    <LeftPane growthtool={growthtool} formik={formik}  flow={flow}/>
                </div>
                <div className="flowSection w-70">
                   <ConnectionsView formik={formik} />
                </div>
            </div> : <Loader height="32px" width="32px" />}
        </div>
    )
}
 
const FieldView = () => {

    const fieldLabels = [
        'Redeem Field',
        'Message Field',
        'Finance Field',
        'Add Wallet Field',
    ]

    return(
        <div className="middle h-100">
            <div className="d-flex align-items-center">
                <div className="d-flex flex-column arrowSection">
                    {fieldLabels.map((element, idx)=>
                    <div className="d-flex">
                        <div className="fieldBox Body14R color-neutral100 middle">
                            {element}
                        </div>
                        <div className="mt_10">
                            <SvgIcons.ArrowIcon color={ColorSchemeCode.tableActionDropdownColor} />
                        </div>
                        <div className="fieldBox Body14R color-neutral100 middle">
                            {element}
                        </div>
                        <div className="mt_10">
                        <SvgIcons.ArrowIcon color={ColorSchemeCode.tableActionDropdownColor} />
                        </div>
                    </div>
                    )}
                </div>
                <div className="manyChatIcon middle ml_32">
                    <img src={PngIcons.ManyChat} height="70" width="70" alt="" />
                </div>
            </div>
        </div>
    )
}

function LeftPane({formik, flow, growthtool}) {
    return (
        <div className="d-flex">
            <div className="leftIconsPane border-right">
                <div className="singleIconBox middle border-bottom activeIcon" >
                    <SvgIcons.CommunicationChatFlowIcon height='18px' width='18px' color={ColorSchemeCode.cAFAEAE}/>
                    {/* <SvgIcons.NavSettingIcon height='18px' width='18px'/> */}
                </div>
            </div>
            <div className="rightDetailsPane w-100">
                <div className="headerBar Link14M color-neutral60">
                    Connection
                </div>
                <div className="belowHeaderContent">
                    <div className="color-neutral100 Heading16M mb_4 mt_40">
                      Connect with Flow
                    </div>
                    <div className="Link13R color-neutral60 mb_16">Please select the flow to connect with Channel</div>                    <div className="customLabel mt_24">
                        Flow Name
                    </div>
                    <select
                        options = {
                            <>
                            {flow && flow.length > 0 && flow.map((value, index)=> (
                                <option value={value._id}>{value.name}</option>
                            ))}
                            </>
                        }
                        className   = {flow.length == 0 && 'disabled'}
                        value       = {formik.values.flow}
                        placeholder = "Flow"
                        onChange    = {(e)=>formik.setValues({...formik.values, flow: e.target.value})}
                    >
                        </select>
                </div>
            </div>
        </div>
    )
}

const Header = ({formik, history, flow, campaigns, growthtool, ...props}) => {

    const [state, setState] = useState({
        processing : false,
        apiError   : '',
    })
    
    const agency = useContext(AgencyContext)
    const brand  = useContext(BrandContext)

    const [editStatus, setEditStatus] = useState()
    
    const handlePreview = () =>{
    }

    const handleSubmit = async() => {
        if(!formik.values.flow || formik.values.flow==="None" || formik.values.flow==="") {
            showToaster({position: 'top-right', title: 'Title Error', message:  'Please connect flow with your growth tool' , severity: 'error'})
            return 
        }
        else{
            setState({...state, processing: true})
            
            const payload = {
                _id           : growthtool._id,
                campaignId    : campaigns[0]._id,
                flowId        : [formik.values.flow],
                growthtoolName: formik.values.growthtoolName
            }
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET){
                const user = await localForage.getItem('user')
                payload.widget.desiredPath = `${user._id}/${brand.brandName}`
            }
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.USER_DASHBOARD){
                const user = await localForage.getItem('user')
                payload.userDashboard.desiredPath = `${user._id}/${brand.brandName}`
            }
    
            const { error, response } = await GrowthtoolService.UpdateGrowthTool({toaster: true, payload})
            setState({...state, processing: false})
            if(error) return setState({...state, processing: false, apiError: error.message})
    
            // const path = props.location.pathname.split('/')
            // path.pop()
            const location = window.location.pathname.split('/')
            location.pop()
            location.pop()
            history.push(location.join('/'))
            // return response && history.push(path.join('/'))
            return
        }
    }


    return (
        <div className='publish-btn  w-100 headerLeftStick '>
            <div className="headerBox position-relative">
                <FitContentInput
                    onClick       = {()=>formik.setValues({...formik.values,toggle: true})}
                    setEditStatus = {setEditStatus}
                    placeholder   = "Growthtool Name"
                    className     = {'border-none display-flex justify-content-center'}
                    value         = {formik.values.growthtoolName}
                    onChange      = {(value)=>formik.setValues({...formik.values, growthtoolName: value, toggle: true})}
                    icon          = {{color: ColorSchemeCode.c262626, width:"14px" , height:'14px' , class: 'ml_10 align-self-center'}}
                />
                <div className="leftHeaderItems d-flex">
                    <div className="">
                            <CustomButton
                                btntext  = {'Publish & close'}
                                // type     = {'submit'}
                                onClick  = {handleSubmit}
                                disabled = {!formik.isValid}
                                icon     = {state.processing && <CircularProgress size={20} color={'inherit'}/>}
                            />
                    </div>
                    <div className="ml_16 cp mr_16 middle" onClick={()=>handlePreview()} >
                        <SvgIcons.EyeIcon  />
                    </div>
                    <div className="ml_8 cp middle" onClick={()=>handlePreview()} >
                        <SvgIcons.DownloadIcon  />
                    </div>
                </div>
            </div>
        </div>

    )
}