import React, { useContext, useState } from 'react'

import RadioGroup from '@mui/material/RadioGroup';
import { CircularProgress, FormControl, FormHelperText } from '@mui/material';
import { BrandContext } from '../../../context/Brand.context';
import { LayoutContext } from '../../../context/Layout.context';
import { BrandVld } from '../../../validation';
import { useFormik } from 'formik';
import { RedemptionType } from '../../../enums/enums';

import CustomButton from '../../../components/CustomButton';
import CustomRadio from '../../../components/CustomRadio';
import { BrandService } from '../../../services';

export default function Redemption() {

    const brand = useContext(BrandContext)
    const layout = useContext(LayoutContext)

    const [state, setState] = useState({
        loader   : false,
        apiError : ''
    })

    const initState = {
        brandRedemptionType    : brand.brandRedemptionType || RedemptionType.SELF,
    }

    const handleSubmit = async () => {
        setState({loader: true, apiError: ''})
        
        const payload = {
            _id                : brand._id,
            brandType          : brand.brandType,
            brandRedemptionType: formik.values.brandRedemptionType
        }
        
        const { error, response } = await BrandService.Update({toaster: true, payload})
        if(error) return setState({loader: false, apiError: error.error})
        
        brand.updateBrand(response.data)
        return setState({loader: false, apiError: ''})
        
    }
    
    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues      : { ...initState },
        onSubmit           : handleSubmit,
        validationSchema   : BrandVld.updateRedemptionType,
        isInitialValid     : true,
        enableReinitialize : true
    })
    
    React.useEffect(()=>{
        layout.setLayout({expandedBar: true, header: true,
            title       : 'Settings',
            switch      : "",
            borderBottom: false,
            button      : <CustomButton
                            btntext  = {"Save changes"}
                            type     = "submit"
                            disabled = {state.loader || !formik.isValid}
                            icon     = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                            onClick  = {handleSubmit}
                        />
        })
    },[formik.values, state.loader])



    return (
        <div>
             <div className="Heading22M color-neutral100 mb_4 pl_0">
                 Brand Redemption Type
            </div>
            <div className="Body14R color-neutral60">
                Description
            </div>
             <FormControl className='mt_12' error = {formik.touched.brandRedemptionType && formik.errors.brandRedemptionType}>
                <RadioGroup 
                    name     = "brandRedemptionType"
                    value    = {formik.values.brandRedemptionType}
                    onChange = {formik.handleChange}
                    onFocus  = {formik.handleBlur}
                >
                    <CustomRadio 
                        value = {RedemptionType.SELF}
                        label = "Express Checkout"
                    />
                    <CustomRadio 
                        value={RedemptionType.CASHIER}
                        label="Cashier Redemption"
                    />
                </RadioGroup>
                <FormHelperText>
                    {formik.touched.brandRedemptionType && formik.errors.brandRedemptionType ? formik.errors.brandRedemptionType : ''}
                </FormHelperText>
            </FormControl>
        </div>
    )
}
