import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import CustomTable from "../../../components/customTable/CustomTable";
import CustomButton from "../../../components/CustomButton";
import CustomTextField from "../../../components/CustomTextField";
import ViewDetail from "../../../components/ViewDetail";
import DesignDropdown from "../../../components/CustomDropDown";
import EmptyScreen from "../../../components/EmptyScreen";

import ClickOutside from "../../../utils/ClickOutside";
import { ColorSchemeCode } from "../../../enums/ColorScheme";
import { getProgramActiveStatus } from "../../../utils/Options";

import PngIcons from "../../../icons/png.icon";
import SvgIcons from "../../../icons/svg.icon";
import { GrowthTools } from "../../../enums";
import {
  CampaignService,
  GrowthtoolService,
  FlowService,
} from "../../../services";
import { BrandContext } from "../../../context/Brand.context";

import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, IconButton, Typography } from "@mui/material";
import CustomsModal from "../../../components/CustomModal";

import CopyText from "../../../components/CopyText";
import { LayoutContext } from "../../../context/Layout.context";
import Loader from "../../../components/Loader";
import QrCode from "../../../components/QrCode";
import { AgencyContext } from "../../../context/Agency.context";
import { utils } from "../../../utils";
import CustomTableV2 from "../../../components/CustomTableV2";
import CustomSwitch from "../../../components/CustomSwitch";
import DeleteModal from "../../../components/DeleteModal";
import CustomSearch from "../../../components/CustomSearch";
import { showToaster } from "../../../utils/utils";
import QRcode from "../../../components/QrCode";
import localforage, * as localForage from "localforage";
// import localforage from "localforage";

const GrowthToolsList = [
  {
    label: "Many Chat",
    type: GrowthTools.GrowhttoolTypes.MANYCHAT,
    icon: PngIcons.ManyChat,
  },
  {
    label: "Chatrace",
    type: GrowthTools.GrowhttoolTypes.CHATRACE,
    icon: PngIcons.Chatrace,
  },
  {
    label: "Chat Fuel",
    type: GrowthTools.GrowhttoolTypes.CHATFUEL,
    icon: PngIcons.SmallIcons.ChatFuelIcon,
  },
  {
    label: "Active Chat",
    type: GrowthTools.GrowhttoolTypes.ACTIVECHAT,
    icon: PngIcons.SmallIcons.ActiveChatIcon,
  },
  {
    label: "Uchat",
    type: GrowthTools.GrowhttoolTypes.UCHAT,
    icon: PngIcons.UchatIcon,
  },
  // {
  //     label : 'Twillio',
  //     type  : GrowthTools.GrowhttoolTypes.MANYCHAT,
  //     icon  : PngIcons.SmallIcons.TwilioIcon,
  // },
  {
    label: "QR Code",
    type: GrowthTools.GrowhttoolTypes.QR_CODE,
    icon: PngIcons.SmallIcons.QrcodeIcon,
  },
  {
    label: "API",
    type: GrowthTools.GrowhttoolTypes.API,
    icon: PngIcons.CloudWaitress,
  },
  {
    label: "APP",
    type: GrowthTools.GrowhttoolTypes.APP,
    icon: PngIcons.SmallIcons.AppIcon,
  },
  {
    label: "Landing Page",
    type: GrowthTools.GrowhttoolTypes.LANDING_PAGE,
    icon: PngIcons.SmallIcons.LandingpageIcon,
  },
  {
    label: "Widget",
    type: GrowthTools.GrowhttoolTypes.WIDGET,
    icon: PngIcons.SmallIcons.WidgetIcon,
  },
  {
    label: "User Dashboard",
    type: GrowthTools.GrowhttoolTypes.USER_DASHBOARD,
    icon: PngIcons.SmallIcons.DashboardIcon,
  },
  {
    label: "GHL",
    type: GrowthTools.GrowhttoolTypes.GHL,
    icon: PngIcons.GhlIcon,
  },
  {
    label: "Popup",
    type: GrowthTools.GrowhttoolTypes.POPUP,
    icon: PngIcons.PopUp,
  },
];

export default function GrowthToolList(props) {
  const layout = useContext(LayoutContext);
  const [btnLoader, setbtnLoader] = useState(false);
  const [campaigns, setCampaigns] = useState([])

  useEffect(() => {
    layout.setLayout({
      title: "Channels",
      borderBottom: false,
      edit: false,
      backTitles: [],
      editTitle: "",
      switch      : "",
      button: (<></>
        // <CustomButton
        //   btntext   = {btnLoader ? <div style={{display: 'flex',gap:12}}><CircularProgress size={20} color={'inherit'}/>Create Opt-in Page</div> : "Create Opt-in Page"}
        //   fontSize={"14px"}
        //   borderRadius={"12px"}
        //   onClick={handleGrowthToolCreate}
        // />
      ),
    });
  }, [window.location.pathname, btnLoader]);

  const brand = useContext(BrandContext);
  const history = useHistory();

  const [processing, setProcessing] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [growthtools, setGrowthtools] = useState();
  const [modal, setModal] = useState();
  const [selectedGrowthTool, setselectedGrowthTool] = useState("");
  const [growthToolClickCheck, setgrowthToolClickCheck] = useState(true);
  const [growthToolClickCheckApp, setgrowthToolClickCheckApp] = useState(true);
  const [growthToolClickCheckPOS, setgrowthToolClickCheckPOS] = useState(true);
  const [Flows, setFlows] = useState([]);

  const [state, setState] = useState({
    viewDetail: false,
    growthToolList: [],
    selectedFilter: "",
    tableData: [],
    loader: true,
    apiError: "",
    deleteLoader: false,
  });
  
  const [campaign, setCampaign] = useState();

  const updateTable = (list) => {
    const growthtools = getTableList(list);
    setTableData([...growthtools]);
  };

  const getTableList = (list) => {
    return list.map((growthtool, idx) =>
      createData({
        growthtool: growthtool,
        serial: idx,
        name: <GrowthToolName growthtool={growthtool} />,
        impressions: "N/A",
        optins: "N/A",
        conversations: "N/A",
        opened: "N/A",
        qrCode: (
          <QrCodeFunc
            setgrowthToolClickCheck={setgrowthToolClickCheck}
            growthToolClickCheck={growthToolClickCheck}
            selectedGrowthTool={selectedGrowthTool}
            growthtool={growthtool}
          />
        ),
        link: <LinkComponent growthtool={growthtool} />,
        actions: (
          <ActionComponent
            growthtool={growthtool}
            growthToolList={list}
            updateTable={updateTable}
          />
        ),
      })
    );
  };

  const [result, setResult] = useState([]);
  console.log("Growth",growthtools)
  const pushArr = (arr, body) => {
    arr.forEach(async (v, index) => {
      body.push({
        id: v._id,
        value: v,
      });
    });
    return body;
  };

  const onLoad = async () => {
    let campaignn = await localforage.getItem('campaign');
    let query
    console.log('campaign123',campaignn);
    if((!campaignn)|| campaignn.length === 0){
      const campaignData = await CampaignService.GetQueriedCampaign({
        query: { brandId: brand._id, campaignType: 5 },
      });
      console.log('brand brand',brand);
      console.log('campaignData',campaignData);
      setCampaign(campaignData.response.data[0]);
      localforage.setItem("campaign", campaignData.response.data[0]);
      query = {
        brandId: brand._id,
        // campaignId: campaignData.response.data[0]._id,
        campaignId: campaignData.response.data[0]._id,
      };
    }
    else{
      query = {
        brandId: brand._id,
        // campaignId: campaignData.response.data[0]._id,
        campaignId: campaignn[0]?._id || campaignn._id,
      };
      setCampaign(campaignn[0]);
    }
    console.log('campaigncampaign',campaign);
    console.log('query ', query)
    console.log('query ', query)
    let GetQueriedGrowthTool = await localforage.getItem('GetQueriedGrowthTool');
    console.log('GetQueriedGrowthTool',GetQueriedGrowthTool);
    if(!GetQueriedGrowthTool){
      const { error, response } = await GrowthtoolService.GetQueriedGrowthTool({query});
      console.log("ERROR",error);
      error && setState({ ...state, apiError: error.message, loader: false });
      if (response) {
        console.log('responseresponseresponse',response)
        let body = [];
        let arr = [];
        localForage.setItem("GetQueriedGrowthTool", response.data);
        updateTable(response.data);
        setGrowthtools(response.data);
        arr = pushArr(response.data, body);
        setResult(arr);
        // localforage.setItem('growthTools', arr);
      }
    }
    else{
      let body = [];
      let arr = [];
      updateTable(GetQueriedGrowthTool);
      setGrowthtools(GetQueriedGrowthTool);
      arr = pushArr(GetQueriedGrowthTool, body);
      setResult(arr);
    }
    // console.log('2');
    setState({ ...state, loader: false });
  };

  useEffect(onLoad, []);

  useEffect(async () => {
    let flow = await localForage.getItem("flow");
    console.log('flow',flow);
    if(!flow){
      const getFlows = await FlowService.Get({query: { brandId: brand._id, flowType:'new' ,delete: false }})
      if (getFlows?.response?.data) {
        localForage.setItem("flow", getFlows.response.data);
        setFlows(getFlows.response.data);
      }
    }
    else{
      setFlows(flow);
    }
  }, []);

  function createData({
    growthtool,
    serial,
    actions,
    name,
    impressions,
    optins,
    conversations,
    opened,
    qrCode,
    link,
  }) {
    return {
      props: {
        name: "rowProps",
        class: "",
        id: growthtool._id,
        growthtoolType: growthtool.growthtoolType,
        growthtoolName: growthtool.growthtoolName,
        checkbox: false,
        // fn             : ()=>handleRowClick(growthtool)
      },
      serial: {
        label: serial,
        class: "",
      },
      name: {
        label: name,
        class: "w-fit-content",
        // fn    : handleColFunc
      },
      impressions: {
        label: impressions,
        class: "",
        numeric: false,
      },
      optins: {
        label: optins,
        class: "",
        numeric: false,
      },
      conversations: {
        label: conversations,
        class: "",
        numeric: false,
      },
      opened: {
        label: opened,
        class: "",
        numeric: false,
      },
      qrCode: {
        label: qrCode,
        class: "",
        numeric: false,
      },
      Link: {
        label: link,
        class: "",
        numeric: false,
      },
      actions: {
        label: actions,
        class: "",
        numeric: false,
      },
    };
  }


        const handleCampaigns = (campaigns) => {
            setCampaigns([...campaigns])
        }
    
        const CallServices = async () => {
            const query = { brandId: brand._id, campaignType: 5 }
            const { error, response } = await CampaignService.GetQueriedCampaign({ query })
            response && handleCampaigns(response.data)
        }
    
        useEffect(() => {
            CallServices();
        }, [])

  const handleGrowthToolCreate = async() => {
    let campaignlf = await localforage.getItem('campaign');
    if((!campaignlf)|| campaignlf.length === 0){
      const campaignData = await CampaignService.GetQueriedCampaign({
        query: { brandId: brand._id, campaignType: 5 },
      });
      console.log('brand brand',brand);
      console.log('campaignData',campaignData);
      localforage.setItem("campaign", campaignData.response.data[0]);
      campaignlf=campaignData.response.data[0]
    }
      console.log('====================================',campaigns);
              // setState({...state, loader: true})
              setbtnLoader(true)
  
              const payload = {
                  brandId: brand._id,
                  campaignId: campaignlf[0]._id,
                  growthtoolName : "New Opt-in Page",
                  growthtoolType : "landingPage"
              }
  
              console.log('CreateGrowthTool',payload);
      console.log('====================================');
      
              const {error, response} = await GrowthtoolService.CreateGrowthTool({toaster: true, payload})
              setState({...state, loader: false})
              if(error) return setState({...state, apiError: error.message})
  
              const path = props.match.url.split('/')
              // path.pop()
  
              // const WidgetCondition      = formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET
              // const LandingPagecondition = formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.LANDING_PAGE
              // const Poscondition = formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.POS
  
              // console.log('path ', path);
  
              console.log('response ', response)
  
              // const url =  LandingPagecondition || Poscondition ? path.join('/') + '/' + response.data._id : path.join('/') + '/' + response.data._id  
              const url = path.join('/') + '/' + response.data._id 
  
              let campaign = await localforage.getItem('campaign');
              if((!campaign)|| campaign.length === 0){
                const campaignData = await CampaignService.GetQueriedCampaign({
                  query: { brandId: brand._id, campaignType: 5 },
                });
                console.log('brand brand',brand);
                console.log('campaignData',campaignData);
                localforage.setItem("campaign", campaignData.response.data[0]);
                campaign=campaignData.response.data[0]
              }
              console.log('campaign',campaign);
              const query = {brandId: brand._id,campaignId: campaign[0]._id};
              const GetQueriedGrowthTool = await GrowthtoolService.GetQueriedGrowthTool({query});
              localForage.setItem("GetQueriedGrowthTool", GetQueriedGrowthTool.response.data);
              // console.log('url ---> ', url);
  

                  console.log('url',url);
                  console.log('response.data',response.data);
                  history.push({
                      pathname : url,
                      state    : response.data
                  })
                  setbtnLoader(false)
    // history.push(props.match.url + "/create");
  };

  const handleDelete = async () => {
    setState({ ...state, deleteLoader: true });
    const payload = {
      _id: deleteIndex,
      delete: true,
    };
    return;
    const { error, response } = await GrowthtoolService.UpdateGrowthTool({
      toaster: true,
      payload,
    });
    let body = [];
    let arr = [];
    if (response) onLoad();
    setState({ ...state, deleteLoader: true });
    setDeleteModal(false);
  };

  const [show, setShow] = useState({
    filter: false,
    mainLoader: false,
    deleteLoader: false,
    inventoryModal: false,
  });

  const handleSelectedGrowthTool = async (element) => {
    console.log('CLICKED');
    if(element.value.growthtoolType !== GrowthTools.GrowhttoolTypes.API){
      const landingPageCondition =
        element.value.growthtoolType ===
          GrowthTools.GrowhttoolTypes.LANDING_PAGE &&
        (!element.value.landingPage ||
          (element.value.landingPage && !element.value.landingPage.json));
      const posCondition =
        element.value.growthtoolType === GrowthTools.GrowhttoolTypes.POS &&
        (!element.value.pos || (element.value.pos && !element.value.pos.json));

      let url;
      await setselectedGrowthTool(element.value);

      if (element.value.growthtoolType === GrowthTools.GrowhttoolTypes.UCHAT) {
        console.log('one')
        const path = props.match.url.split("/");
        let uchatUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.UCHAT +
          "/" +
          element.value._id;
        history.push({ pathname: uchatUrl, state: element.value });
      } else if (
        element.value.growthtoolType === GrowthTools.GrowhttoolTypes.MANYCHAT
      ) {
        const path = props.match.url.split("/");
        let manyChatUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.MANYCHAT +
          "/" +
          element.value._id;
        history.push({ pathname: manyChatUrl, state: element.value });
      } else if (
        element.value.growthtoolType === GrowthTools.GrowhttoolTypes.CHATRACE
      ) {
        console.log('two')

        const path = props.match.url.split("/");
        let chatraceUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.CHATRACE +
          "/" +
          element.value._id;
        history.push({ pathname: chatraceUrl, state: element.value });
      } else if (
        element.value.growthtoolType === GrowthTools.GrowhttoolTypes.API
      ) {
        console.log('three')

        const path = props.match.url.split("/");
        let apiUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.API +
          "/" +
          element.value._id;
        history.push({ pathname: apiUrl, state: element.value });
      } else if (
        element.value.growthtoolType === GrowthTools.GrowhttoolTypes.APP
      ) {
        console.log('four')
        const path = props.match.url.split("/");
        let apiUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.APP +
          "/" +
          element.value._id;
        history.push({ pathname: apiUrl, state: element.value });
      } else if (
        element.value.growthtoolType === GrowthTools.GrowhttoolTypes.GHL
      ) {
        console.log('five')

        const path = props.match.url.split("/");
        let apiUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.GHL +
          "/" +
          element.value._id;
        history.push({ pathname: apiUrl, state: element.value });
      }

      else if (element.value.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET) {
        console.log('six')

        const path = props.match.url.split("/");
        let widgetUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.WIDGET +
          "/" +
          element.value._id;
        history.push({ pathname: widgetUrl, state: element.value });
      } 
      
      else if (element.value.growthtoolType === GrowthTools.GrowhttoolTypes.POPUP) {

        const path = props.match.url.split("/");
        let popupUrl =
          path.join("/") +
          "/" +
          GrowthTools.GrowhttoolTypes.POPUP +
          "/" +
          element.value._id;
        history.push({ pathname: popupUrl, state: element.value });
      }

      else {
        console.log('last')

        url =
          landingPageCondition || posCondition
            ? history.location.pathname + "/" + element.value._id 
            : history.location.pathname + "/" + element.value._id;
        setgrowthToolClickCheck(false);
        setgrowthToolClickCheckApp(false);
        setgrowthToolClickCheckPOS(false);
        setTimeout(() => {
        console.log('growthToolClickCheck',growthToolClickCheck)
        console.log('growthToolClickCheck',{...element.value , growthtools : result })

          if (growthToolClickCheck) {
            element.value.growthTools = result
            history.push({ pathname: url, state: element.value   });
          }
        });
      }
    }
  };

  return (
    <div id="growthtoolmain">
      {(state.loader && result && result.length === 0) ? 
      (
        <Loader basewidth={0} baseHeight={0} />
      ) : (result && result.length > 0 && !state.loader) ? (
        <div className="">
          <div className="d-flex w-100 color-neutral90 Link11M audienceTableGrid overflow-hidden">
          </div>
          <div className="bar mb_18"></div>
          <div className="data">
            {
            
            result && result.length > 0 &&
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>
              {result.map((value, index) => (
                (value.value.growthtoolType==="landingPage" ||
                  // ?(value.value.growthtoolType==="landingPage"&&(value.value.referralUrl)):
                  value.value.growthtoolType==="widget")&&
                <Card key={index} sx={{ flex: '1 1 300px',height: 'fit-content' }} onClick={() => {
                  handleSelectedGrowthTool(value);
                }}>
                  {console.log('value',value)}
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                    <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                        <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                        {
                          value.value.growthtoolType==="landingPage"&&value.value.referralUrl?'Referral Page':
                          value.value.growthtoolType==="landingPage"?'Landing Page':
                          value.value.growthtoolType==="widget"?'Widget':
                        utils.capitalize(value.value.growthtoolName)}
                        </Typography>
                        <img src={PngIcons.tick} width={16} height={16}/>
                      </div>
                      <Typography variant="h6" style={{ fontFamily: 'Inter'}} color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3, // Limits to 2 lines
                        textOverflow: 'ellipsis',
                      }}>
                        {
                          value.value.growthtoolType==="landingPage"&&value.value.referralUrl?'A dedicated page for customers to refer friends and earn rewards. Easily track and manage your referral program.':
                          value.value.growthtoolType==="landingPage"?"Create custom landing pages hosted by walletly for various campaigns. Easy to opt customers into your loyalty and reward program.":
                          value.value.growthtoolType==="widget"?"Enable your customers to engage with rewards and points via a widget on your website.":
                          ''
                        }
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={
                        value.value.growthtoolType==="landingPage"&&value.value.referralUrl?PngIcons.newRCBlock:
                        value.value.growthtoolType==="landingPage"?PngIcons.newLPBlock:
                        value.value.growthtoolType==="widget"?PngIcons.WidgetBlock:
                        ''}
                      alt={value.name}
                    />
                  </CardActionArea>
                </Card>
              ))}
           <Card key={'index'} sx={{ flex: '1 1 300px' }} onClick={() => {
                  history.push(props.match.url+'/share/page')
                }}>
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                  <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div className="d-flex gap-6">
                      <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                      Social Referral Program
                      </Typography>
                      <img src={PngIcons.tick} width={16} height={16}/>
                    </div>
                    <Typography variant="h6" color="text.secondary" style={{fontSize: '12px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3, // Limits to 2 lines
                        textOverflow: 'ellipsis',
                      }}>
                        Allow customers to share and earn rewards on social media, with options for boosted points on select dates.
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={PngIcons.newshareChannelBlock}
                      alt={'value.name'}
                    />
                  </CardActionArea>
                </Card>
            </div>
           }
           {/* <div className="bar mt_40 mb_40"></div>
            {
            
            result && result.length > 0 &&
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>
              {result.map((value, index) => (
                // (value.value.growthtoolType==="landingPage"&&(!value.value.referralUrl))&&
                (value.value.growthtoolType==="landingPage"&&(!value.value.referralUrl))&&
                <Card key={index} sx={{ flex: '1 1 300px',height: 'fit-content' }} onClick={() => {
                  handleSelectedGrowthTool(value);
                }}>
                  {console.log('value',value)}
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                    <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                        <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                        {
                          value.value.growthtoolType==="landingPage"&&value.value.referralUrl?'Referral Page':
                          value.value.growthtoolType==="landingPage"?'Landing Page':
                          value.value.growthtoolType==="widget"?'Widget':
                        utils.capitalize(value.value.growthtoolName)}
                        </Typography>
                        <img src={PngIcons.tick} width={16} height={16}/>
                      </div>
                      <Typography variant="h6" color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3, // Limits to 2 lines
                        textOverflow: 'ellipsis',
                      }}>
                        {
                          value.value.growthtoolType==="landingPage"&&value.value.referralUrl?"A dedicated page for customers to refer friends and earn rewards. Easily track and manage your referral program."
                          :value.value.growthtoolType==="landingPage"?"Create custom landing pages hosted by walletly for various campaigns. Easy to opt customers into your loyalty and reward program.":
                          value.value.growthtoolType==="widget"?"Widget channel enables you to connect your flows with your lightspeed pos.":
                          ''
                        }
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={
                        value.value.growthtoolType==="landingPage"&&value.value.referralUrl?PngIcons.newRCBlock:
                        value.value.growthtoolType==="landingPage"?PngIcons.newLPBlock:
                        value.value.growthtoolType==="widget"?PngIcons.largetIcons.WidgetIcon:
                        ''}
                      alt={value.name}
                    />
                  </CardActionArea>
                </Card>
              ))}
              <div onClick={handleGrowthToolCreate} className='w-100  trigger1' style={{ alignContent: 'center', color:'#2960EC', minHeight: '140px' }} varient='tertiary'>
                <SvgIcons.Add color={'#2960EC'}/><br/>
                Add New Landing Page
              </div>
            </div>


           } */}

          </div>
          
          <CustomsModal
            component={
              <WidgetDetails
                modal={{
                  value: modal ? modal.userDashboard || modal.widget : {},
                  setModal,
                }}
              />
            }
            open={modal}
          />
        </div>
      ) : 
          <EmptyScreen
          title={"Channels"}
          btnTitle={"Create Channel"}
          btnClick={handleGrowthToolCreate}
        />
      }
      <CustomsModal
        open={deleteModal}
        minWidth={"600px"}
        component={
          <DeleteModal
            icon={state.deleteLoader}
            title="growth tool"
            onCancel={() => setDeleteModal(false)}
            onDelete={handleDelete}
          />
        }
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}

const WidgetDetails = ({ modal }) => {
  return (
    <ClickOutside onClick={() => modal.setModal()}>
      <div>
        <div className="Body14R mb_10">
          Embed this code to head of your site
        </div>
        <div className="d-flex bg-color-cF6F6F9 borderRadius-20 pl_15 pr_15 py-2">
          <div className="mr_6 Body14R fw-5 opacity-10 color-textfieldColor">
            Code:{" "}
          </div>
          <CopyText
            content={`<script src=${modal.value.widgetUrl} ></script>`}
          />
        </div>
      </div>
    </ClickOutside>
  );
};

const ActionComponent = ({
  setgrowthToolClickCheckPOS,
  growthToolClickCheckPOS,
  growthtool,
  growthToolList,
  updateTable,
  setgrowthToolClickCheck,
  growthToolClickCheck,
  selectedGrowthTool,
}) => {
  const [viewMore, setViewMore] = useState(false);
  const [processing, setProcessing] = useState();
  const history = useHistory();

  const handleDeleteFunc = async (e) => {
    e.stopPropagation();
    setProcessing("delete&" + growthtool.id);
    const payload = {
      _id: growthtool.id,
      delete: true,
    };
    const { error, response } = await GrowthtoolService.UpdateGrowthTool({
      toaster: true,
      payload,
    });
    setProcessing();
    setViewMore(false);
    if (error) return;


    const filtered = growthToolList.filter((item, idx) => {
      return item._id != response.data._id;
    });

    updateTable([...filtered]);
    window.location.reload();
  };

  const viewMoreItems = [
    // {
      //   hide:
      //     growthtool.value.growthtoolType ==
      //       GrowthTools.GrowhttoolTypes.LANDING_PAGE ||
      //     growthtool.value.growthtoolType === GrowthTools.GrowhttoolTypes.POS
      //       ? false
      //       : true,
      // },
      {
      component: <LinkComponent growthtool={growthtool} />,
      component:
        growthtool.value.growthtoolType ===
        GrowthTools.GrowhttoolTypes.LANDING_PAGE ? (
          <QrCodeFunc
            setgrowthToolClickCheck={setgrowthToolClickCheck}
            growthToolClickCheck={growthToolClickCheck}
            selectedGrowthTool={selectedGrowthTool}
            growthtool={growthtool}
          />
        ) : growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.POS ? (
          <QrCodeFunc
            setgrowthToolClickCheckPOS={setgrowthToolClickCheckPOS}
            growthToolClickCheckPOS={growthToolClickCheckPOS}
            selectedGrowthTool={selectedGrowthTool}
            growthtool={growthtool}
          />
        ) : (
          ""
        ),
      hide:
        growthtool.value.growthtoolType ===
          GrowthTools.GrowhttoolTypes.LANDING_PAGE ||
        growthtool.value.growthtoolType === GrowthTools.GrowhttoolTypes.POS
          ? false
          : true,
    },
    {
      name: "Delete",
      function: handleDeleteFunc,
      processing: processing === "delete&" + growthtool.id,
      icon: <SvgIcons.DeleteIcon height={21} width={21} />,
    },
  ];

  return (
    <div
      className="d-flex justify-flex-end"
      onClick={(e) => e.stopPropagation()}
    >
      <ClickOutside onClick={() => setViewMore(false)}>
        <div
          className="cp mt_5 position-relative"
          onClick={() => setViewMore(!viewMore)}
        >
          <SvgIcons.ViewMoreIcon
            color={ColorSchemeCode.tableActionDropdownColor}
          />
          {viewMore && (
            <div
              className="position-absolute"
              style={{ right: 0, top: 8, zIndex: 5 }}
            >
              <ViewDetail width="200px" items={viewMoreItems} />
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

const QrCodeFunc = ({
  growthtool,
  coords,
  campaign,
  selectedGrowthTool,
  setgrowthToolClickCheck,
  setgrowthToolClickCheckApp,
  setgrowthToolClickCheckPOS,
  growthToolClickCheck,
}) => {
  const [downloadQrLoader, setDownloadQrLoader] = useState(false);


  growthtool = growthtool.value;

  const brand = useContext(BrandContext);
  const agency = useContext(AgencyContext);
  const getContent = {
    [GrowthTools.GrowhttoolTypes.WIDGET]: () =>
      `<script src=${growthtool.widget.widgetUrl} ></script>`,
    // [GrowthTools.GrowhttoolTypes.LANDING_PAGE]: () => `${window.location.host}/page/${growthtool._id}?lat=${brand.location.lat}&lng=${brand.location.lng}`,
    [GrowthTools.GrowhttoolTypes.LANDING_PAGE]: () =>
      `${window.location.host}/page/${growthtool._id}`,
    [GrowthTools.GrowhttoolTypes.MANYCHAT]: () => "-",
  };

  // const content =  getContent[growthtool.growthtoolType]()
  // const content = `${window.location.host}/page/${growthtool._id}?lat=${brand.location.lat}&lng=${brand.location.lng}`
  const content =  brand.channelDomain ? `${brand.channelDomain}/page/${growthtool._id}`:`${(window.location.host === 'api.walletly.ai' || window.location.host.includes(agency.domain)) ? 'catchly.io' : window.location.host}/page/${growthtool._id}`;
  // const content =  `${(window.location.host === 'api.walletly.ai' || window.location.host.includes(agency.domain)) ? 'yonga.app' : window.location.host}/page/${growthtool._id}`;
  const content2 = brand.channelDomain ? 
  `${brand.channelDomain}/page/?growthtoolId=${
    growthtool._id
  }&lat=${brand.location.lat}&lng=${brand.location.lng}&agencyId=${
    brand.ageny && brand.agency.id ? brand.agency.id : ""
  }&brandId=${brand._id}&campaignCode=${
    campaign && campaign.campaignCode
  }&campaignId=${campaign && campaign._id}`:
  `${window.location.host}/page/?growthtoolId=${
    growthtool._id
  }&lat=${brand.location.lat}&lng=${brand.location.lng}&agencyId=${
    brand.ageny && brand.agency.id ? brand.agency.id : ""
  }&brandId=${brand._id}&campaignCode=${
    campaign && campaign.campaignCode
  }&campaignId=${campaign && campaign._id}`;
  const qrcode = {
    size: 200,
    text: (window.location.host.includes('localhost') ? 'http://' : 'https://') + (campaign ? content2 : content),
    typeNumber: 10,
  };



  const downloadQr = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setgrowthToolClickCheck && setgrowthToolClickCheck(true);
    setgrowthToolClickCheckApp && setgrowthToolClickCheckApp(true);
    setgrowthToolClickCheckPOS && setgrowthToolClickCheckPOS(true);
    setDownloadQrLoader(true);
    setTimeout(() => {
      const canvas = document.getElementsByTagName("canvas");
      if (canvas) {
        const pngUrl = canvas[0]
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "Qrcode.jpg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      setDownloadQrLoader(false);
    });
  };

  return (
    <div onClick={downloadQr}>
      <div className="">
        <div className="downloadLink d-flex cp">
          <a className="text-center cp" download="Qrcode.png">
            {downloadQrLoader ? (
              <CircularProgress size={20} color={"inherit"} />
            ) : (
              <SvgIcons.DownloadIcon
                height={18}
                width={18}
                color={utils.invertColor(agency.agencyColor, {
                  black: "black",
                  white: "white",
                })}
              />
            )}
          </a>
          <div className="ml_8" onClick={downloadQr}>
            Download QR Code
          </div>
        </div>
      </div>
      {growthtool._id && (
        <div className="d-none">
          <QRcode qrcode={qrcode} />
        </div>
      )}
    </div>
  );
};

const LinkComponent = ({ growthtool }) => {
  const [tooltipText, setTooltipText] = useState("copy");
  const [coords, setCoords] = useState();

  const brand = useContext(BrandContext);

  const getCoords = async () => {
    const pos = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return {
      long: pos.coords.longitude,
      lat: pos.coords.latitude,
    };
  };

  const onload = async () => {
    let result;
    if (brand && brand.location && brand.location.lat) {
      result = { lat: brand.location.lat, long: brand.location.lng };
    } else {
      result = await getCoords();
    }
    setCoords(result);
  };

  useEffect(onload, []);

  const handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const getContent = {
      [GrowthTools.GrowhttoolTypes.WIDGET]: () =>
        `<script src=${growthtool.value.widget.widgetUrl} ></script>`,
      [GrowthTools.GrowhttoolTypes.LANDING_PAGE]: () =>
        `${
          window.location.host.includes("localhost")
            ? window.location.host
            : "https://" + window.location.host
        }/page/${growthtool.value._id}`,
      [GrowthTools.GrowhttoolTypes.POS]: () =>
        `${
          window.location.host.includes("localhost")
            ? window.location.host
            : "https://" + window.location.host
        }/page/${growthtool.value._id}`,
      [GrowthTools.GrowhttoolTypes.MANYCHAT]: () => "-",
    };
    const content = getContent[growthtool.value.growthtoolType]();
    navigator.clipboard.writeText(content);
    ReactTooltip.show(
      document.getElementById("tooltip" + growthtool.value._id)
    );
    setTooltipText("copied");
  };

  return (
    <div className="cp">
      <div
        className="cp Body14R"
        onClick={handleCopy}
        id={"tooltip" + growthtool._id}
        data-for={"sourceCopy" + growthtool._id}
        data-tip={tooltipText}
      >
        <SvgIcons.CopyIcon
          height={17}
          width={17}
          color={ColorSchemeCode.Paragraph}
        />
        <span className="ml_8">Copy Web URL</span>
      </div>
      <ReactTooltip
        place="right"
        backgroundColor={ColorSchemeCode.GeneralBlack}
        className="line-height-10 opacity-8 p_8 borderRadius-6"
        id={"sourceCopy" + growthtool._id}
        getContent={() => tooltipText}
      />
    </div>
  );
};

const GrowthToolName = ({ growthtool, getFlowName }) => {

  const item = GrowthToolsList.find(
    (items) => items.type === growthtool.growthtoolType
  );

  return (
    <div className="d-flex mt_4">
      <div className="growthToolName middle borer borderRadius-4 pl_4 pr_4 pb_4 pt_4">
        <img src={item && item.icon} width="30" height="30" />
      </div>
      <div className="ml_16 middle">
        <div className="Heading15M color-neutral100">
          {growthtool.growthtoolName}
        </div>
      </div>
    </div>
  );
};
