import React, { useState, useRef, useEffect } from 'react'

import { utils } from '../../utils'

import CustomTextField from '../CustomTextField'
import LocationAutoCompleteField from '../LocationAutoCompleteField';

let autoComplete
let autoCompleteListener

function loadAutocomplete(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current)
    autoComplete.setFields(["address_components", "geometry", "formatted_address", "utc_offset_minutes"])
    // console.log('loaderd ',autoComplete )
}

function updateListener({ getPlace, locationSummary }) {
    autoCompleteListener && window.google.maps.event.removeListener(autoCompleteListener)
    autoCompleteListener = autoComplete.addListener("place_changed", () => {
        getPlace && getPlace(autoComplete.getPlace())
        locationSummary && locationSummary(utils.summarisedLocation(autoComplete.getPlace()))
    })
    // console.log('autoCompleteListener ', autoCompleteListener)
}


function Autocomplete({ getPlace, locationSummary, value, onChange, ...props }) {

    const autoCompleteRef = useRef(null)
    const [focus , setFocus] = useState(false)

    const onLoad = () => loadAutocomplete(autoCompleteRef)
    const onUpdate = () => updateListener({ getPlace, locationSummary })

    useEffect(onLoad, [])
    useEffect(onUpdate, [value])
    let focu;
    const handleFocus = () => {
        focu = !focus;
        setFocus(focu)
    }
    const handleBlur = () => {
        focu = !focus;
        setFocus(focu)
    }


    // console.log('autoCompleteRef ', autoCompleteRef.current)




    return (
        <LocationAutoCompleteField
            value={value}
            inputRef={autoCompleteRef}
            onChange={onChange}
            onFocus     = {handleFocus}
             onBlur      = {handleBlur}
             checkOne    = {focus}
            {...props}
        />
    )
}

export default Autocomplete
