import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { LayoutContext } from "../../../../context/Layout.context";
import { PngIcons, SvgIcons } from "../../../../icons";
import localforage from "localforage";
import { CommunicationService, DealService, EarningWayService, FlowService } from "../../../../services";
import { BrandContext } from "../../../../context/Brand.context";
import { ClickOutside, Options, utils } from "../../../../utils";
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import { AgencyContext } from "../../../../context/Agency.context";
import { CircularProgress, Tooltip } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import localForage from 'localforage'
import DeleteModal from "../../../../components/DeleteModal";
import CustomsModal from "../../../../components/CustomModal";
import CustomTextField from '../../../../components/CustomTextField';
import CustomModal from '../../../../components/CustomModal';
import { Campaign, enums } from "../../../../enums";
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomTextArea from '../../../../components/CustomTextArea';
import CustomSelect from "../../../../components/CustomSelect";
import ReactTooltip from "react-tooltip";
import CustomTextFieldValue from "../../../../components/CustomTextFieldValue";
function EarnBuilder() {

    const history  = useHistory();
    const layout   = useContext(LayoutContext);
    const brand    = useContext(BrandContext);
    const agency   = useContext(AgencyContext);
    const [spare, setSpare] = useState(false)

    console.log('Bran',brand);

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [desc, setDesc] = useState(false);
    const [btnloader, setBtnloader] = useState(false);

    const [sms, setSms] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const [addtrigger, setAddtrigger] = useState(false)
    const [idx, setIdx] = useState('')
    const [viewMore, setViewMore] = useState(false)
    
    const [addtrigger1, setAddtrigger1] = useState(false)
    const [viewMore1, setViewMore1] = useState(false)
    
    const [show, setShow] = useState({
        modal    : false,
        value    : '',
        title    : '',
        key      : '',
        dealModal : false,
        onChange : ()=>console.log("")
    })


    const [dropdownTrigger, setDropdownTrigger] = useState([
        {
            name        : 'All Customer',
            key         : 'allCustomer',
            img         : PngIcons.RewardBuilder.checkin,
            description : 'It will trigger for all customer not depending on any condition.'
        },
        {
            name        : 'One Time Deal',
            key         : 'oneTimeDeal',
            img         : PngIcons.RewardBuilder.oneTimeDeal,
            description : 'It will trigger deal will be rewarded to customer only one time.'
        },
        {
            name        : 'Customer Tag',
            key         : 'customerTag',
            img         : PngIcons.RewardBuilder.tagCustomer,
            description : 'It triggers when a customer has the tag you tagged them. e.g., Loyal Customer'
        },
        {
            name        : 'Customer Type',
            key         : 'customerType',
            img         : PngIcons.RewardBuilder.customerType2,
            description : 'It triggers when a customer has the tag you tagged them. e.g., Favorite Customer'
        },
        {
            name        : 'Customer Inactivity',
            key         : 'customerInactivity',
            img         : PngIcons.RewardBuilder.customerInactivity,
            description : 'It triggers when a customer has no activity for a certain period.'
        },
        {
            name        : 'Reward Status',
            key         : 'dealStatus',
            img         : PngIcons.RewardBuilder.dealStatus,
            description : 'It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)'
        },
        {
            name        : 'Event',
            key         : 'event',
            img         : PngIcons.RewardBuilder.event,
            description : 'It triggers when a specific event occurs.'
        },
        {
            name        : 'Points',
            key         : 'point',
            img         : PngIcons.RewardBuilder.points,
            description : 'It triggers when a specific amount of points are achieved.'
        },
        {
            name        : 'Visits',
            key         : 'visit',
            img         : PngIcons.RewardBuilder.visits,
            description : 'It triggers when a customer has a certain amount of visits to your brand.'
        },
        {
            name        : 'Referral reward for sender',
            key         : 'referral',
            img         : PngIcons.RewardBuilder.oneTimeDeal,
            description : 'It triggers when a customer Reffered numbers of customer'
        },
        {
            name        : 'Referral reward for receiver',
            key         : 'referrallogin',
            img         : PngIcons.RewardBuilder.checkin,
            description : 'It triggers when a customer create pass using referral'
        },
    ])
    const [dropdownAction, setDropdownAction] = useState([
        {
            name        : 'Add Tag',
            key         : 'addTag',
            img         : PngIcons.RewardBuilder.addTag,
            description : 'Description'
        },
        {
            name        : 'Give Points',
            key         : 'givePoint',
            img         : PngIcons.RewardBuilder.addPoints,
            description : 'Description'
        },
        {
            name        : 'Deduct Points',
            key         : 'deductPoint',
            img         : PngIcons.RewardBuilder.deductPoints,
            description : 'Description'
        },
        {
            name        : 'Customer Type',
            key         : 'customerType',
            img         : PngIcons.RewardBuilder.customerType,
            description : 'It triggers when a customer has the tag you tagged them. e.g., Loyal Customer'
        },
        {
            name        : 'Reward',
            key         : 'rewardDeal',
            img         : PngIcons.RewardBuilder.rewardDeal,
            description : 'Description'
        },
        {
            name        : 'Remove Tag',
            key         : 'removeTag',
            img         : PngIcons.RewardBuilder.removeTag,
            description : 'Description'
        },
        {
            name        : 'Send SMS',
            key         : 'sendSms',
            img         : PngIcons.RewardBuilder.sendSMS,
            description : 'Description'
        },
    ])

    const [finalstate, setFinalstate] = useState(history.location.state?.flow || [{state: [], state1: []}])


    useEffect(async () => {
        const smsApi = await CommunicationService.GetQueriedCommunication({ query: { delete: false, brandId: brand._id, smsType: 'draft', communicationType: 'sms' } });
        if(smsApi.response) setSms(smsApi.response.data)

        let deal = await localforage.getItem("deal");
        if ((!deal) || deal.length === 0) {
        const query = { delete: false, brandId: brand._id };
        const { error, response } = await DealService.Get({ query });
        if (response) deal = response.data;
        }

        let temp = [];
        if (deal && deal.length > 0)
        for (const value of deal) {
            temp.push({
            label: value.name,
            value: value._id,
            image: <img src={value.image} width="20px" heigth="20px" />,
            data: value,
            });
        }
        setFilteredOptions(temp);
    }, []);

    const [payload, setpayload] = useState({
        _id               : history.location.state._id,
        earningName       : history?.location?.state?.earningName || 'earningName',
        activity          : history.location.state.activity || '',
        earningType       : history.location.state.earningType || '',
        earningValue      : history.location.state.earningValue || '0',
        active            : history.location.state.active ,
        expiration        : history.location.state.expiration || 1 ,
        earningValueRange : history.location.state.earningValueRange || [],
        description       : history.location.state.description || ''
    })
    console.log('SPECIAL',history.location.state.active  );
//     useEffect(() => {
//         layout.elements.editTitle = history.location?.state?.earningName
//     }
//    ,[])
    // useEffect(() => {
    //     layout.setLayout({
    //     // edit        : false,
    //     // placeholder : layout.elements.editTitle || history?.location?.state?.earningName ,
    //     //   borderBottom: true,
    //     button: <>
    //     <CustomButton 
    //         icon    = {btnloader && <CircularProgress size={20} color={'inherit'}/>}
    //         varient = {"primary"} btntext = {"Save Changes"} onClick = {async()=>{
    //         setBtnloader(true)
    //         if(payload.earningValueRange&&payload.earningValueRange.length>0){
    //             payload.earningValueRange.map((value, index)=>{
    //                 delete payload.earningValueRange[index].check
    //                 if(value.maxValue==='') delete payload.earningValueRange[index].maxValue
    //             })
    //         }
    
    //         if(payload.activity === Campaign.Activities.EARNING.CHECKIN)
    //         {
    //             if(payload.earningType === Campaign.EarningTypes.RANGE) delete payload.earningValue
    //             else delete payload.earningValueRange
    //         }
    //         else if(payload.earningType === Campaign.EarningTypes.RANGE){
    //             delete payload.passcodeType;
    //             delete payload.earningValue;
    //             delete payload.passCode;
    //             delete payload.sendSms;
    //         }
    //         else 
    //         {
    //             delete payload.earningValueRange;
    //             delete payload.passcodeType;
    //             delete payload.passCode;
    //             delete payload.sendSms;
    //         }
    //         // console.log('payload',payload)
    //         payload.earningName = layout.elements.editTitle;
    //         const { error, response } = await EarningWayService.Update({toaster: true, payload})
    //         console.log('response',[payload,response.data]);
    //         if(response.data){
    //             // let selectedLoyalityEarningWays = await localForage.getItem('selectedLoyalityEarningWays')   
    //             let selectedLoyalityEarningWays = await localForage.getItem('earningways')   
    //             selectedLoyalityEarningWays?.map((element, idx)=>{
    //                 if(element._id === history.location.state._id) {
    //                     selectedLoyalityEarningWays[idx] = response.data;
    //                     if(selectedLoyalityEarningWays[idx].earningType === 'range') delete selectedLoyalityEarningWays[idx].earningValue
    //                     else if(selectedLoyalityEarningWays[idx]?.earningValueRange && selectedLoyalityEarningWays[idx].earningType !== 'range') delete selectedLoyalityEarningWays[idx].earningValueRange
    //                     console.log('selectedLoyalityEarningWays', selectedLoyalityEarningWays)
    //                     // localForage.setItem('selectedLoyalityEarningWays', selectedLoyalityEarningWays) 
    //                     localForage.setItem('earningways', selectedLoyalityEarningWays) 
    //                 }
    //             })
    //         }  
    //         setBtnloader(false)
    //         if(brand?.integrations?.cloudwaitress?.length>0){
    //             const location = window.location.pathname.replace('reward/earn','redeem')
    //             history.push(location)
    //         }else{
    //             const location = window.location.pathname.split('/')
    //         location.pop()
    //         history.push(location.join('/'))
    //         }
            

    //     }} />
        
    //     </>
    //     ,
    //     });
    // }, [layout.elements.editTitle, finalstate, btnloader, payload.name, deleteModal, deleteLoader, window.location.pathname, payload]);
    
    // useEffect(() => {
    //     layout.setLayout({
    //         placeholder : layout.elements.editTitle || history?.location?.state?.earningName ,
    //       borderBottom: true,
    //       switch : <></>,
    //       editTitle: history.location.state.earningName,
    //     });
    //   }, []);

      useEffect(()=>{
        layout.setLayout({
            borderBottom: false,
            edit        : false,
            backTitles  : [],
            title       : payload.activity === Campaign.Activities.EARNING.SIGNUP?"Sign Up":"Points",
            switch : '',
            button: <></>
        })
        // onLoad();
    },[])

    // useEffect(()=>{
    //     layout.setLayout({
    //       placeholder :  !history?.location?.state?.name ? "New Rule" : history.location.state ? layout.elements.editTitle : layout.elements.editTitle ,
    //     });
    //   },[layout.elements.editTitle])
    // useEffect(()=>{
    //     layout.setLayout({
    //         editTitle: history?.location?.state?.earningName || '',
    //         edit     : true,
    //         switch   : <div>
    //         <CustomSwitch 
    //             checked={payload.active}
    //             onChange={(e)=>setpayload({...payload, active : e.target.checked})}
    //         />
    //      </div>,
            
    //     })
    // },[])
    useEffect(() => {
        setSpare(!spare)
    }, [])
    const handleDelete = async() => {
        setDeleteLoader(true)
        let temp = []
        await FlowService.Update({payload : {_id: history.location.state?._id, brandId: brand._id, agencyId: agency._id, delete: true}})
        let flow = await localForage.getItem('flow');
        if(flow && flow.length > 0){
            flow.map((value, index)=>{
                if(history.location.state?._id!==value._id) temp.push(value)
            })
        }
        localForage.setItem('flow', temp);

        setViewMore(false)
        setDeleteLoader(false)
        setDeleteModal(false)

        const location = window.location.pathname.split('/')
        location.pop()
        history.push(location.join('/'))
    }
    const deleteFunc = (index, i, state) => {
        if(state){
            finalstate[i].state.splice(index, 1)
            setViewMore(false)
            setIdx('')
            setFinalstate([...finalstate])
        }
        else{
            finalstate[i].state1.splice(index, 1)
            setViewMore1(false)
            setIdx('')
            setFinalstate([...finalstate])
        }
    }

    const func = (value, key) => {
        // console.log('====================================');
        // console.log('abc',value, 'key', key);
        // console.log('====================================');
        [key] = value
        setFinalstate([...finalstate])
    }

 

    

    const removingEarningValue = (element, idx) => {
        const values = payload.earningValueRange.filter((element, index)=>{
           return  index != idx
        })

        setpayload({...payload , earningValueRange : values})
    }
    console.log('PAYld',payload);
    console.log('PAYld',payload);


    const addPointRangesFunc = () => {
        let newRange = {
            minValue : 0,
            maxValue : 0,
            points   : 0,
        }
        setpayload({...payload , earningValueRange : [...payload.earningValueRange , newRange]})
    }
    useEffect(()=>{
        if(brand?.postPaid && brand?.postPaid == true) setpayload({...payload,earningType : Campaign.EarningTypes.RANGE})
    },[brand])

  return (
    <>
    {<div id="RewardBuilder" className="pt_16 pr_40 pb_16 pl_40 middle">
        <CustomsModal 
            open      = { deleteModal }
            minWidth  = {'600px'}
            component = {<DeleteModal icon={deleteLoader} title="Deal" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
            onClose   = {()=> setDeleteModal(false)}
        />
        {console.log('history.location.state',history.location.state.earningName)}
        {console.log('history.location.stateoop',layout.elements)}
        {console.log('Layout',layout)}
        {console.log('payload',payload)}
        {<>
        <div className="w-55 mt_40">
            <div className="mb_12 d-flex gap-16 position-relative" >
                {payload.activity === Campaign.Activities.EARNING.SPENDING?<Tooltip placement="left" title={<div className="B12R">Points cannot be turned off</div>}>
                <img 
                className={payload.active?"imgcard":"imgcard"} onClick={()=>{
                    if(payload.activity === Campaign.Activities.EARNING.SIGNUP)setpayload({...payload, active: !payload.active})
                }} 
                 src={payload.activity === Campaign.Activities.EARNING.SIGNUP?PngIcons.newEWSU:PngIcons.newEWS} width={68} height={68}/>
                </Tooltip>:
                <img 
                className={payload.active?"imgcard":"imgcard"} onClick={()=>{
                    if(payload.activity === Campaign.Activities.EARNING.SIGNUP)setpayload({...payload, active: !payload.active})
                }} 
                 src={payload.activity === Campaign.Activities.EARNING.SIGNUP?PngIcons.newEWSU:PngIcons.newEWS} width={68} height={68}/>
                }
                <div>
                    <div className="Heading22M color-neutral100">{payload.activity === Campaign.Activities.EARNING.SIGNUP?"Sign Up":"Points"}{<img className="ml_8 position-absolute" style={{left: '43px'}} src={payload.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
                    <div className="Body14R color-neutral60">{payload.activity === Campaign.Activities.EARNING.SIGNUP?"On every sign up user will get points":
                    payload.activity !== Campaign.Activities.EARNING.SIGNUP ? 
                    payload.earningType===Campaign.EarningTypes.INCREMENTAL ? "On every $1 spending user will get points" : 
                    payload.earningType===Campaign.EarningTypes.FIXED ? "1 Points Earned On Every Amount" : 
                    "Set range of spending to give points" : "On every signup user will get points"
                    }</div>
                </div> 
            </div>
            <div className="bar mb_26"></div>
            <div className={`d-flex w-100 gap-24 ${!payload.active&&`disabled`} expanded`}>
                {payload.activity === Campaign.Activities.EARNING.SPENDING&&<div className=" w-50 position-relative">
                    <CustomSelect
                    label={"Point Expiration"}
                    className=""
                    value={payload.expiration}
                    onChange={(e)=>{setpayload({...payload, expiration: e.target.value})}}
                    options={[
                    {
                        name: "None",
                        value: 1
                    },
                    {
                        name: "6 Month",
                        value: 180
                    },
                    {
                        name: "12 Month",
                        value: 360
                    }
                    ]}
                />
                    </div>}
                {payload.activity === Campaign.Activities.EARNING.SPENDING&&<div className=" w-50 position-relative">
                    <div className="position-relative ">
                        <div className="w-100">
                            <div className="w-100">
                            <div className="">
                                {(payload.activity !== Campaign.Activities.EARNING.SIGNUP) && <div className="B14M color-neutral80 underline">
                                    <CustomSelect
                                        label={"Spending"}
                                        onChange={(e)=>{setpayload({...payload, earningType: e.target.value})}}
                                        options={[
                                            {
                                            name: "Increment of points",//(recommended)
                                            value: Campaign.EarningTypes.INCREMENTAL
                                            },
                                            {
                                            name: "Fixed amount of points",
                                            value: Campaign.EarningTypes.FIXED
                                            },
                                            {
                                              name: "Range Bucket",
                                              value: Campaign.EarningTypes.RANGE
                                            },
                                        ]}
                                        value={payload.earningType}
                                        />
                                </div>}
                                {/* {(payload.activity !== Campaign.Activities.EARNING.SIGNUP) ? <div className="ml_2 d-flex B14M color-neutral80 border-none" >{payload.earningType===Campaign.EarningTypes.INCREMENTAL? "on every $1 Spend" : payload.earningType===Campaign.EarningTypes.FIXED ? "1 Points Earned On Every Amount" : "set range of spending to give points"}</div> : <div className="ml_2 d-flex B14M color-neutral80 border-none">On every signup user will get points</div>} */}
                            </div>
                        
                            { desc &&(
                                <>
                            <label className="earning-desc mt_15">Description (optional)</label>
                                <div className="description">
                                    <CustomTextField 
                                        
                                        // label       = {'Description (optional)'}
                                        // inputProps={{
                                        //     style: {
                                        //       height: "50px",
                                        //     },
                                        //   }}
                                        multiline
                                        // rows={2}
                                        maxRows={2}
                                        name        = 'earningWay.description'
                                        placeholder = "Enter Short Description about the product?"
                                        className   = "for-fontt"
                                        background  = '#F5F7FC'
                                        fontSize={14}
                                        value       = {payload.description}
                                        onChange    = {(e)=>{
                                            payload.description=e.target.value;
                                            console.log("sss",payload.description);
                                        }}
                                        // onBlur      = {formik.handleChange}
                                    />
                                </div>
                                </>)
                                }
                            </div>
                        </div>
                    </div>
                </div>}
                <div className=" w-50 position-relative">
                <div className=" position-relative ">
                        <div className="w-100">
                            {/* <div className="w-100 align-items-center d-flex space-between mb_8">
                                <div className="w-100 align-items-center d-flex gap-8">
                                    <img src={payload.earningType===Campaign.EarningTypes.INCREMENTAL? PngIcons.RewardBuilder.addPoints : payload.earningType===Campaign.EarningTypes.FIXED ? PngIcons.RewardBuilder.addPoints : PngIcons.RewardBuilder.addPoints} height={20} width={20} />
                                    {(payload.activity !== Campaign.Activities.EARNING.SIGNUP) ?
                                    <div className="d-flex space-between hpx-24 align-items-center"><div className="Body14M color-neutral70">{payload.earningType===Campaign.EarningTypes.INCREMENTAL? "Give Points" : payload.earningType===Campaign.EarningTypes.FIXED ? "Amount" : "Range Bucket"}</div>
                                    </div>
                                    :
                                    <div className="d-flex space-between hpx-24 align-items-center"><div className="Body14M color-neutral70">{"Points"}</div>
                                    </div>
                                    }
                                </div>
                            </div> */}
                            <div className="w-100">
                                {console.log("payload.earningValueE",payload.earningValue)
                                }
                                {console.log("payload.activeE",payload.active)
                                }
                                {(payload.earningType===Campaign.EarningTypes.INCREMENTAL||payload.earningType===Campaign.EarningTypes.FIXED)&&<div className="">
                                    <CustomTextFieldValue
                                        value={payload.active?payload.earningValue:"0"}
                                        onChange={(e)=>{

                                            if(payload.earningValue!==0 || e.target.value !== 0 || payload.earningValue!=="0" || e.target.value !== '0'){
                                                payload.active = true
                                            }
                                            console.log("asdasdasd");
                                            
                                            setpayload({...payload, earningValue: e.target.value})
                                            setShow({...show, value: e.target.value})
                                        }}
                                        type="number"
                                        label={(payload.earningType===Campaign.EarningTypes.INCREMENTAL||payload.activity === Campaign.Activities.EARNING.SIGNUP)? "Points" : payload.earningType===Campaign.EarningTypes.FIXED ? "Amount" : "Range Bucket"}
                                    />
                                </div>}
                                {
                                    payload.earningType===Campaign.EarningTypes.RANGE&&
                                    <div className="position-relative">
                                        {payload.earningValueRange.map((element, idx)=><div className="signelEarningRange w-100 mb_16">
                                            <div className="position-relative">
                                                {idx > 0 && <div className="cp Body14R color-neutral60 position-absolute" style={{right: 0, top: 0}} onClick={()=>removingEarningValue(element, idx)}>
                                                    <SvgIcons.CrossIcon/>
                                                </div>}
                                            </div>
                                            <div className="d-flex showArrow " >
                                                <div style={{width: 100}}>
                                                    <CustomTextField 
                                                        icon     = "$"
                                                        widthIcon = '10px'

                                                        position = "start"
                                                        paddingLeft = '7px'
                                                        top      = {15}
                                                        name     = {`earningValueRange.[${idx}].minValue`}
                                                        type     = "number"
                                                        width    = "w-100"
                                                        onChange = {(e)=>{
                                                            payload.earningValueRange[idx].minValue = parseInt(e.target.value)
                                                            setpayload({...payload})
                                                        }}
                                                        value       = {payload.earningValueRange[idx].minValue}
                                                    />
                                                </div>
                                                <div className="text-center ml_5 mr_5 align-self-center color-neutral100">-</div>
                                                <div className='mr_40 showArrow' style={{width: 100}}>
                                                    <CustomTextField 
                                                        icon      = "$"
                                                        widthIcon = '10px'
                                                        position  = "start"
                                                        paddingLeft = '7px'
                                                        top      = {15}
                                                        width     = "100"
                                                        name      = {`earningValueRange.[${idx}].maxValue`}
                                                        type      = "number"
                                                        className = {idx===0?(payload.earningValueRange[idx].check ? "mr_8" : "mr_8"):(payload.earningValueRange[idx].check ? "mr_8" : "mr_8")}
                                                        onChange  = {(e)=>{
                                                            payload.earningValueRange[idx].maxValue = parseInt(e.target.value)
                                                            setpayload({...payload})
                                                        }}
                                                        value     = {payload.earningValueRange[idx].maxValue}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex mt_8 wrapRemove">
                                               <div className="Body14R color-neutral60 ">Points awarded:</div>
                                                <input 
                                                        type      = "number"
                                                        name      = {`earningValueRange.[${idx}].points`}
                                                        onChange  = {(e)=>{
                                                            payload.earningValueRange[idx].points = parseInt(e.target.value)
                                                            setpayload({...payload})
                                                        }}
                                                        // onChange  = {(e) => onChangePoints(e,idx)}
                                                        value     = {payload.earningValueRange[idx].points ? payload.earningValueRange[idx].points : 0}
                                                        className = "outline-none border-none color-neutral100 B14M ml_5 underline background-inherit"
                                                />
                                            
                                            </div>
                                        </div>)}
                                        <div className="mt_8">
                                            <CustomButton className="w-100"
                                                btntext = "Add Range"
                                                icon    = {<SvgIcons.Add color={ColorSchemeCode.themeColor}/>}
                                                varient = "tertiary"
                                                onClick = {addPointRangesFunc}
                                                size = "l"
                                                disabled={brand.postPaid && brand?.postPaid == true}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {console.log('pay',payload)}
                </div>
            </div>
                <div className="bar mt_20 mb_20"></div>
                <div className="d-flex gap-10">
                <CustomButton 
                        icon    = {btnloader && <CircularProgress size={20} color={'inherit'}/>}
                        varient = {"primary"} btntext = {"Save Changes"} 
                        onClick = {async()=>{
                        setBtnloader(true)
                        if(payload.earningValueRange&&payload.earningValueRange.length>0){
                            payload.earningValueRange.map((value, index)=>{
                                delete payload.earningValueRange[index].check
                                if(value.maxValue==='') delete payload.earningValueRange[index].maxValue
                            })
                        }
                
                        // if(payload.activity === Campaign.Activities.EARNING.SIGNUP){
                        //     if(payload.earningValue === 0 || payload.earningValue === "0") {
                        //         payload.active = false
                        //         payload.earningValue = 0 
                        //     }
                        //     else{
                        //         payload.active = true   
                        //     }

                        // }
                        if(payload.activity === Campaign.Activities.EARNING.CHECKIN)
                        {
                            if(payload.earningType === Campaign.EarningTypes.RANGE) delete payload.earningValue
                            else delete payload.earningValueRange

                        }
                        else if(payload.earningType === Campaign.EarningTypes.RANGE){
                            delete payload.passcodeType;
                            delete payload.earningValue;
                            delete payload.passCode;
                            delete payload.sendSms;
                        }
                        else 
                        {
                            delete payload.earningValueRange;
                            delete payload.passcodeType;
                            delete payload.passCode;
                            delete payload.sendSms;
                        }
                        payload.earningName = layout.elements.editTitle || payload.activity;
                        console.log('payloaE',payload)
                        const { error, response } = await EarningWayService.Update({toaster: true, payload})
                        console.log('responseE',[payload,response, error]);
                        console.log('responseE',[payload,response.data]);
                        if(response.data){
                            // let selectedLoyalityEarningWays = await localForage.getItem('selectedLoyalityEarningWays')   
                            let selectedLoyalityEarningWays = await localForage.getItem('earningways')   
                            selectedLoyalityEarningWays?.map((element, idx)=>{
                                if(element._id === history.location.state._id) {
                                    selectedLoyalityEarningWays[idx] = response.data;
                                    if(selectedLoyalityEarningWays[idx].earningType === 'range') delete selectedLoyalityEarningWays[idx].earningValue
                                    else if(selectedLoyalityEarningWays[idx]?.earningValueRange && selectedLoyalityEarningWays[idx].earningType !== 'range') delete selectedLoyalityEarningWays[idx].earningValueRange
                                    console.log('selectedLoyalityEarningWays', selectedLoyalityEarningWays)
                                    // localForage.setItem('selectedLoyalityEarningWays', selectedLoyalityEarningWays) 
                                    localForage.setItem('earningways', selectedLoyalityEarningWays) 
                                }
                            })
                        }  
                        setBtnloader(false)
                        if(brand?.integrations?.cloudwaitress?.length>0){
                            const location = window.location.pathname.replace('reward/earn','redeem')
                            history.push(location)
                        }else{
                            const location = window.location.pathname.split('/')
                        location.pop()
                        history.push(location.join('/'))
                        }
                        

                    }} />
                    <CustomButton varient = {"secondary1"} btntext = {"Cancel"} onClick={()=>{history.goBack()}} />
                </div>
                </div>
            </>
        }

        <CustomModal
            component = {<EditComponent payload={payload} setPayload={setpayload} func={func} setShow={setShow} show={show} finalstate={finalstate} setFinalstate={setFinalstate} />}
            open      = {show.modal}
            minWidth  = {'450px'}
            padding   = "16px"
        />
    </div>}
    </>
  );
}

const EditComponent = ({setShow , show, func, payload, setPayload}) => {

    const handleConfirm = () => {
        setShow({...show, modal: false})
    }

    return(
        <div className="">
            <div className='Heading18M color-neutral100 mb_24 capitalize'>
                {show.title} title
            </div>
            <CustomTextField
                onChange    = {(e)=>{
                    if(show.key === 'payload.earningValue'){
                        setPayload({...payload, earningValue: e.target.value})
                        setShow({...show, value: e.target.value})
                    }
                }}
                value       = {show.value}
                placeholder = {`Enter ${show.title}`}
                label       = {show.title}
            />
            <div className="d-flex justify-flex-end mt_16">
                <CustomButton
                    btntext = {'Confirm'}
                    onClick = {handleConfirm}
                    // disabled= {layout.elements.editTitle.length < 1 && true}
                />
            </div>
        </div>
    )
}

export default EarnBuilder;


const DealModalComponent = ({deal, onClose, filteredOptions}) =>{
    // console.log('deal ', deal);
    // console.log('deal ', filteredOptions);
    const [val, setVal] = useState('')
    useEffect(() => {
      for (const value of filteredOptions) {
        if(deal?.rewardDeal === value.value || deal?.deal === value.value) setVal(value.data)
      }
    }, [deal])
    
    return(
        <div className="">
            <div className="d-flex mb_24 space-between">
                <h3 className="H22M color-neutral100">
                    Deal Details
                </h3>
                <div className="cp" onClick={()=>onClose()}><SvgIcons.CloseIcon/></div>

            </div>
            <div className="d-flex gap-16">
                <div className="dealImageBox ">
                    <img className="border borderRadius-4 object-fit-cover" src={val?.image} alt="" width={165} height={132} />
                </div>
                <div>
                    <div className="B12M color-neutral80 mb_6">Deal Name</div>
                    <div className="B14M color-neutral100 mb_16">{deal.dealname}</div>
                    <div className="B12M color-neutral80 mb_6">Description</div>
                    <div className="B12R color-neutral50">{val?.description}</div>
                </div>

            </div>
            {/* <div className="mt_16 d-flex justify-flex-end">
                <CustomButton 
                    btntext={"Close"}
                    onClick={()=>onClose()}
                    varient="secondary"
                />
            </div> */}
        </div>
    )
}