import React, { useEffect, useState } from 'react'
import CustomButton from "../../../../../components/CustomButton";
import CustomTextField from "../../../../../components/CustomTextField";
import Fade from 'react-reveal/Fade';

export function SetupCloudWaitress ({formik}) {
  
    //indexing should be done!!
    const onChangeFunc = (e,key)=>{
      formik.values.cloudwaitress[formik.values.selectedCWIndex][key] = e.target.value;
      formik.setValues({...formik.values})
    }

    const [selectedCW, setSelectedCW] = useState(formik.values.cloudwaitress[formik.values.selectedCWIndex]);
    useEffect(()=>{
      if(formik.values.cloudwaitress.length == 0 ){
        let cloudWaitress = {
            name     : '',
            key      : '',
            url      : '',
            pabblyurl: '',
        }
        setSelectedCW(cloudWaitress);
      }
    },[formik.values.cloudwaitress])


    return (
      <Fade bottom>
        <div>
        <h1 className='Heading24M'>CloudWaitress</h1>
        <p className='hedingbelowPara'>Connect to CloudWaitress by integrating</p>
        <form>
          <div  className = "auth-form hpx-350 mt_30">
            <div className="w-100">
                  <CustomTextField
                    label     = "Secret Key*"
                    type      = "text"
                    onChange  = {(e)=>onChangeFunc(e,'key')}
                    value     = {selectedCW?.key || ""}
                  />
              </div>
              <div className="w-100 mt_10">
                <CustomTextField
                  label     = "Resturant Name*"
                  type      = "text"
                  className = "lh-24"
                  onChange  = {(e)=>onChangeFunc(e,'name')}
                  value     = {selectedCW?.name || ""}
                />
              </div>

              <div className="w-100 mt_10">
                <CustomTextField
                  label     = "Resturant URL*"
                  type      = "text"
                  className = "lh-24"
                  onChange  = {(e)=>onChangeFunc(e,'url')}
                  value     = {selectedCW?.url || ""}
                />
              </div>

              <div className="w-100 mt_10">
                <CustomTextField
                  label     = "Pabbly URL"
                  type      = "text"
                  onChange  = {(e)=>onChangeFunc(e,'pabblyUrl')}
                  value     = {selectedCW?.pabblyUrl || ""}
                  className = "lh-24"
                />
              </div>

            </div>
        </form>
     </div> 
     </Fade>
    )
  }


export default SetupCloudWaitress;