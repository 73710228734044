import React, { useContext, useState } from 'react'
import CustomButton from '../../components/CustomButton'
import CustomTextField from '../../components/CustomTextField'
import { PngIcons, SvgIcons } from '../../icons'
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { showToaster } from '../../utils/utils';
import { LocationService, TransactionService } from '../../services';
import { AgencyContext } from '../../context/Agency.context';
import { CircularProgress } from '@mui/material';
import localforage from 'localforage';

import CustomModal from '../../components/CustomModal'
import { utils } from '../../utils'
import { Brand } from '../../enums';


function LandingPageValidation() {
    const [selected, setSelected] = useState()
    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const params = useParams()
    const [type, settype] = useState('password')
    const [text, setText] = useState('')
    const agency = useContext(AgencyContext)
    const [showModal, setShowModal] = useState(false)
    const [transitionData, setTransitionData] = useState('')
    const [Error, setError] = useState('');



  window.onbeforeunload = function (e) {
      if (e) e.returnValue = history.push('/page/'+params.growthtoolId)
      return history.push('/page/'+params.growthtoolId);
  };


  const eyeClick = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      settype('password')
    } else {
      x.type = "password";
      settype('text')
    }
  }

  const setAddress = async () => {
    const ipAddress = await LocationService.GetIpAddress()
    if(ipAddress.error) return

    const {error, response} = await LocationService.GetLocation({ipAddress: ipAddress.response.ip})
    if(error) return

    const location = {
        lat     : response.latitude,
        lng     : response.longitude,
        address : response.city + ' ' + response.country_name
    }

    return location;
}
    return (
        <div className="ml_24 mr_24 position-relative">
            <div className="Heading20M color-neutral100 mt_48">Enter Validation Passcode.</div>
            <div className="Body14R color-neutral60 mt_8 mb_24">Ask the cashier to enter the validation passcode.</div>
            <div>
                <CustomTextField 
                label       = "Pascode"
                className   = "w-100"
                type        = "password"
                placeholder = "Passcode"
                id          = "password"
                value       = {text}
                onChange    = {(e)=>setText(e.target.value)}
                icon        = {<div className={"cp"} onClick={eyeClick}>{type !== 'password' ? <SvgIcons.EyeIconPassword/> : <SvgIcons.CrossEyeIcon/>}</div>}
                position    = "end"
                />
                <div className="error">{Error}</div>
            </div>
            <div className="w-100 mt_16" >
                <CustomButton btntext="Done"
                disabled  = {loader}
                icon      = {loader && <CircularProgress size={20} color={'inherit'}/>}
                className="w-100" height="48px" onClick={async()=>{
                  if(text === history.location.state.earningWays.passCode){
                    setLoader(true)
                    setError('');
                    let payload = {...history.location.state.payload, points : history.location.state.points, amount: history.location.state.amount, flowId: history.location.state.flowIdArray}
                    delete payload.firstName;
                    if(!history.location.state.dealDetail) payload.actionTriggerCall = true
                    let SelfSingleLoyalityTransaction = await TransactionService.SelfSingleLoyalityTransaction({payload, headers: history.location.state.headers})
                    if(SelfSingleLoyalityTransaction.error && SelfSingleLoyalityTransaction.error.data) {return history.push({pathname:'/page/'+params.growthtoolId+'/redeem', state: {...SelfSingleLoyalityTransaction.error.data, tiers : history.location.state.tiers}})}
                    if(SelfSingleLoyalityTransaction.error && !SelfSingleLoyalityTransaction.error.success) { setLoader(false);  setError(SelfSingleLoyalityTransaction.error.error); return}
                    if(SelfSingleLoyalityTransaction.response && history.location.state.dealDetail) {
                          payload = {
                              transaction : SelfSingleLoyalityTransaction.response.data.transaction,
                              availDeal   : history.location.state.dealDetail,
                              flowId      : history.location.state.flowIdArray
                          }

                          const {response, error} = await TransactionService.redeemDealTransaction({payload})
                          if(error){
                            setLoader(false); setError(error.message);
                          }
                          let deals = await localforage.getItem('deals');
                          deals.map((element, idx)=> {
                              if(element._id === history.location.state.dealDetail._id && element.uniqueId === history.location.state.dealDetail.uniqueId )
                              {
                                  deals[idx] = {...element, avail: true}
                              }  
                          })

                          setTransitionData(SelfSingleLoyalityTransaction.response.data)
                        //   history.push({pathname:'/page/'+params.growthtoolId+'/congrats', state: {...SelfSingleLoyalityTransaction.response.data, tiers : history.location.state.tiers }})
                            setShowModal(true)
                          localforage.setItem('deals', deals);
                      }
                      setTransitionData(SelfSingleLoyalityTransaction.response.data)
                      setShowModal(true)
                    //   history.push({pathname:'/page/'+params.growthtoolId+'/congrats', state: {...SelfSingleLoyalityTransaction.response.data, tiers : history.location.state.tiers}})
                      setLoader(false)
                  }
                  else {
                      showToaster({position : 'bottom-right',title    : 'Pass Code is not correct',message  : 'Pass Code is not correct',severity : 'error'})
                        setShowModal(false)
                    }
                  }}/>

                  <CustomModal 
                    component = {<ModalComponent history={history} state ={{...transitionData, tiers : history.location.state.tiers }}/>}
                    open      = {showModal}
                    minWidth  = {'250px'}
                  />
            </div>
        </div>
    )
}



const ModalComponent = ({state, history})=>{

    const goToMainPage = async()=>{
        let pageUrl  = history.location.pathname;
        pageUrl = pageUrl.split('/');
        pageUrl.pop();
        pageUrl = pageUrl.join('/');
        pageUrl = pageUrl+'?'+'lat='+state.transaction.location.lat+'&lng'+state.transaction.location.lng;
        await localforage.removeItem('email');
        await localforage.removeItem('deals');
        history.push(pageUrl)
    }


    return (
        <div>
            <div className="w-100 middle">
                <img src={PngIcons.Confetti} alt="congrats" className="middle" height="96px" width="auto" />
                {(!history.location.state.brand.remotePointCheck || history.location.state.brand.remotePointCheck !== Brand.RemotePointCheck.SMS) ? <div className="mt_24 Heading18M color-GeneralBlack text-align-center" style={{width: '270px'}}>{'Congrats '+utils.capitalizeAll(state.transaction.customerName) +'!        You have  been rewarded '+state.transaction.loyaltyCard.points+' points.'}</div> : ''}
                </div>
                {(history.location.state.brand.remotePointCheck === Brand.RemotePointCheck.SMS) ? <div className="text-center mt_16">
                    We have sent you an SMS on your mobile please confirm.
                </div> : ''}
                <div className="w-100 mt_40">
                    <CustomButton btntext="Got it" onClick={goToMainPage} className="w-100" height="48px" />
            </div>
        </div>
    )
}

export default LandingPageValidation
