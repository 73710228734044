import React, {useEffect, useState} from 'react'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { PngIcons, SvgIcons } from '../../icons';

import CustomButton from '../../components/CustomButton';

function CarouselTest() {
  return (
    <div id="NewDealPage" className='middle'>
        <div className='middle dealOuterCard position-relative pb_40'>
            <div className="webScreen middle">
                <img 
                src       = {(PngIcons.walletlyLogoFull)}
                height    = "64px"
                width     = "64px"
                className = 'borderRadius-60 mb_24'
                alt       = ""
            />
            <div className='color-neutral60 Body14R'>Claim your point by availing deals applicable.</div>
            <div className='mt_16 pointsBox color-neutral100 position-relative fs-14 pt_12'>
                <div className='giftBoxLogo'><SvgIcons.GiftIcon/></div>
                100 PTS
            </div>
            <Carousel 
                className='mt_32' 
                showArrows 
                showStatus 
                infiniteLoop 
                showIndicators 
                showThumbs = {false}
                swipeable 
                useKeyboardArrows 
                width  = {310}
                height = {200}
            >
                <div>
                    <img width={240} height={200} src="https://images.unsplash.com/photo-1653781509531-c0f1cd64c223?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80" />
                </div>
                <div>
                    <img width={0} height={200} src="https://images.unsplash.com/photo-1653687484037-ea26ebfdf89d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
                </div>
                <div>
                    <img width={240} height={200} src="https://images.unsplash.com/photo-1649972904366-7309c5e13abd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
                </div>
                <div>
                    <img width={240} height={200} src="https://images.unsplash.com/photo-1649972904366-7309c5e13abd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
                </div>
                <div>
                    <img width={240} height={200} src="https://images.unsplash.com/photo-1649972904366-7309c5e13abd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
                </div>
                <div>
                    <img width={240} height={200} src="https://images.unsplash.com/photo-1649972904366-7309c5e13abd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
                </div>
                <div>
                    <img width={240} height={200} src="https://images.unsplash.com/photo-1649972904366-7309c5e13abd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
                </div>
                
            </Carousel>

            <div className="Heading16M color-neutral100 mt_8 mb_4">Whole PERi-PERi Chicken+2 Large Sides</div>  
            <div className="Body14R color-Paragaph text-center mb_50 fs-12">Choose 2 large serves from our sides menu. 5,660kj (kj do not include side choices)</div>
{           /*             
            { value.condition === 'visit'?
                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition} </div>
                </div>
                :
                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition}</div>
                </div>
            } 
            */}
            <div className='claimButton w-100'>
                <CustomButton 
                    btntext   = {'Claim Points'}
                    className = "w-100 ClaimBtn"
                    height    = "56px"
                />
            </div>
            </div>
        </div>
    </div>
  )
}

export default CarouselTest