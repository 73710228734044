import React, { useState } from 'react'
import {useHistory} from 'react-router-dom'

import { ChromePicker } from 'react-color';

import CustomAccordion from '../../../../components/CustomAccordion'
import CustomTextField from '../../../../components/CustomTextField'

import { SvgIcons } from '../../../../icons';
import { ClickOutside } from '../../../../utils';

function Add() {

    const [state, setState] = useState([
        {
            name               : 'Bronze Membership',
            points             : '400',
            bgColor            : '#FFFFFF',
            color              : '#000000',
            accelerationName   : '',
            accelerationPoints : '',

            expanded      : false,
            colorPicker   : false,
            bgColorPicker : false
        },
        {
            name               : 'Silver Membership',
            points             : '400',
            bgColor            : '#FFFFFF',
            color              : '#000000',
            accelerationName   : '',
            accelerationPoints : '',

            expanded      : false,
            colorPicker   : false,
            bgColorPicker : false
        },
        {
            name               : 'Gold Membership',
            points             : '400',
            bgColor            : '#FFFFFF',
            color              : '#000000',
            accelerationName   : '',
            accelerationPoints : '',

            expanded      : false,
            colorPicker   : false,
            bgColorPicker : false
        },
    ])

    const handleChangeExpanded = ({panel, index}) => (event, isExpanded) => {
        state[index].expanded = isExpanded ? panel : false
        setState([...state]);
    };

    return (
        <div className="w-100">
            {
                state.map((value,index)=>(
                    <CustomAccordion 
                        title        = {value.name}
                        details      = {<Details value={value} state={state} setState={setState} index={index} />}
                        expanded     = {value.expanded}
                        handleChange = {(panel) => handleChangeExpanded({panel, index})}
                        Sidefields   = {['']}
                        type         = "point"
                        className    = "mb_20"

                    />
                ))
            }

            <div onClick={()=>{
                state.push({
                    name               : 'New Membership',
                    points             : '100',
                    bgColor            : '#FFFFFF',
                    color              : '#000000',
                    accelerationName   : '',
                    accelerationPoints : '',
        
                    expanded      : false,
                    colorPicker   : false,
                    bgColorPicker : false
                })
                setState([...state])    }} 
            className="row" 
            style={{height: '56px', width: '100%', backgroundColor: '#FFFFFF', border: '1px dashed #BDBDBD',marginTop: '24px',marginLeft: '0px',alignItems: 'center', direction: 'flex', flexDirection: 'row', justifyContent: 'center',cursor: 'pointer'}}>

                    <div><SvgIcons.ImagePlus width="25" height='26' /></div>
                    <div style={{fontFamily: "Inter", fontWeight: '400', fontSize: '20px', lineHeight: '32.8px', color: '#4F4F4F',marginLeft: '8px'}}>Add Tier</div>
            </div>
        </div>
    )
}


const Details = ({value, index, setState, state}) => {
    return(
        <div className="ml_20">
            <CustomTextField
                label      = "Membership Name"
                value      = {value.name}
                onChange   = {(e)=> {
                    state[index].name = e.target.value;
                    setState([...state])
                }}
            />
            <CustomTextField
                type       = "number"
                label      = "Membership Points"
                value      = {value.points}
                onChange   = {(e)=> {
                    state[index].points = e.target.value;
                    setState([...state])
                }}
            />

            <div className="fw-6 fs-18 color-blackColor">Look & Feel</div>
            <div className="fw-4 fs-14 color-blackColor mb_8">Card Background</div>
            <div className="d-flex">
                <div className="boxBorder mr_35">
                    <div className="innerBoxBorder " style={{backgroundColor: state[index].bgColor}}></div>
                </div>
                <ClickOutside onClick={() => {
                    state[index].bgColorPicker = false
                    setState([...state])
                }}>
                    <CustomTextField
                        icon={
                            <div 
                                className="cp" 
                                onClick={()=>{
                                    state[index].bgColorPicker = !state[index].bgColorPicker
                                    setState([...state])
                                }}  
                                style={{width: '18px', height: '18px', backgroundColor: state[index].bgColor, borderRadius: '50%'}}></div>
                        }
                        position={'start'}
                        value={state[index].bgColor}
                        onChange={(e)=> {
                            state[index].bgColor = e.hex
                            setState([...state])
                        }}
                    />
                    {state[index].bgColorPicker && <ChromePicker 
                        className = "pallette position-absolute z-index-1 "
                        color     = {state[index].bgColor}
                        onChange  = {(e)=>{
                            state[index].bgColor = e.hex
                            setState([...state])
                        }}
                    />}
                </ClickOutside>
            </div>
            <div className="fw-4 fs-14 color-blackColor mb_8">Text Color Background</div>
            <div className="d-flex">
                <div className="boxBorder mr_35">
                    <div className="innerBoxBorder " style={{backgroundColor: state[index].color}}></div>
                </div>
                <ClickOutside onClick={() => {
                    state[index].colorPicker = false
                    setState([...state])
                }}>
                    <CustomTextField
                        icon={
                            <div 
                                className="cp" 
                                onClick={()=>{
                                    state[index].colorPicker = !state[index].colorPicker
                                    setState([...state])
                                }}  
                                style={{width: '18px', height: '18px', backgroundColor: state[index].color, borderRadius: '50%'}}></div>
                        }
                        position={'start'}
                        value={state[index].color}
                        onChange={(e)=> {
                            state[index].color = e.hex
                            setState([...state])
                        }}
                    />
                    {state[index].colorPicker && <ChromePicker 
                        className = "pallette position-absolute z-index-1 "
                        color     = {state[index].color}
                        onChange  = {(e)=>{
                            state[index].color = e.hex
                            setState([...state])
                        }}
                    />}
                </ClickOutside>
            </div>
        </div>
    )
}

export default Add
