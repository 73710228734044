import React from 'react'
import { ColorSchemeCode } from '../../../enums/ColorScheme'
import { PngIcons, SvgIcons } from '../../../icons'

function Block({Icon, className, title, subtitle, detail}){
    return(
        <div id="Block" className={className}>
            <div className="d-flex align-items-center">
                <div className={`middle icon ${className}`}><Icon color={ColorSchemeCode.white}/></div>
                <div className="headline4 color-neutral100 Heading18M ml_8">{title}</div>
            </div>
            <div className={`details ${className}`}>
                {/* {className === 'growthtool' && <div><img src={PngIcons.LandingpageIcon} height={20} width={20} className="mr_8" alt=""/></div>} */}
                <div>
                    <div className="headline4 color-neutral100 Heading16M fw-5">{subtitle}</div>
                    <div className="Caption12M color-neutral60 fs-12 fw-5">{detail}</div>
                </div>
            </div>
        </div>
    )
}


function ConnectionsView({formik, selectedCampaign}) {
    return (
        <div id="Connections">
            <div className="d-flex align-items-center">
                <Block 
                    // Icon      = {SvgIcons.NavGrowthIcon}
                    Icon      = {SvgIcons.GrowthToolFlowIcon}
                    className = "growthtool"
                    title     = {'Growth Tool'}
                    subtitle  = {formik.values?.growthtoolType.toUpperCase() || 'Landing Page'}
                    detail    = {''}
                />
                <svg style={{'height':'3px', 'backgroundColor':'lightgray', 'width':'110px'}} />
                <Block 
                    // Icon={SvgIcons.NavLoyatlyIcon} 
                    Icon      = {SvgIcons.FlowDealIcon}
                    className = "campaign"
                    title     = {'Flow'}
                    subtitle  = {formik.values.flowName || formik.values.growthtoolName ||  'Flow Name'}
                    detail    = {formik.values.flow ? formik.values.flow.name : 'Detail'}
                />
            </div>
        </div>
    )
}


export default ConnectionsView
