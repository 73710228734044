import React, { useContext } from 'react'
import {Route, Switch, Redirect} from "react-router-dom";
import SettingHeader from '../../components/setting/SettingHeader';
import { LayoutContext } from '../../context/Layout.context';
import Analytics from './subpages/Analytics'
import UpdateCoupon from './subpages/UpdateCoupon'
import Coupon from './subpages/Coupon.js'
import RuleBuilder from './subpages/RuleBuilder'
import { CampaignContextComponent } from '../../context/Campaign.context';

function Campaigns(props) {
    const layout = useContext(LayoutContext)
    return (
        <CampaignContextComponent>
       
            <Switch>
                <Route exact path = {props.match.url + '/analytics'}   component    = {Analytics}/>
                <Route path       = {props.match.url + '/coupon'}      component    = {Coupon}/>
                <Route path       = {props.match.url + '/:_id'}        render       = {() => <UpdateCoupon {...props}  />} />
                <Route path       = {props.match.url + '/rulebuilder'} render       = {() => <RuleBuilder  {...props}  />} />
                
                <Redirect path = {props.match.url + '/*'} to = {props.match.url+ '/coupon/detail'} />
                <Redirect path = {props.match.url} to        = {props.match.url+ '/coupon/detail'} />
            </Switch>
        </CampaignContextComponent>
    )
}

export default Campaigns
