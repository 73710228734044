import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router';

import CustomButton from '../../../../components/CustomButton';
import CustomModal from '../../../../components/CustomModal';
import CustomTextField from '../../../../components/CustomTextField';
import CustomTextArea from '../../../../components/CustomTextArea';
import {ImageModalV2} from '../../../../components/ImageModalV2/ImageModalV2';
import LogoUploader from '../../../../components/LogoUploader';
import DeleteModal from '../../../../components/DeleteModal';

import { LayoutContext } from '../../../../context/Layout.context';
import { InventoryVld } from '../../../../validation';
import { Options, utils } from '../../../../utils';
import { showToaster, capitalizeAll } from '../../../../utils/utils';

import { FormikConsumer, useFormik } from 'formik';
import localforage, * as localForage from 'localforage'
import { CampaignService, InventoryService, PassDesignService } from '../../../../services';
import { CircularProgress } from '@mui/material';
import { BrandContext } from '../../../../context/Brand.context';
import { AgencyContext } from '../../../../context/Agency.context';
import { Coupon } from '../../../../validation/Campaign.validation';


export default function AddGiftCard({onLoad,onClose, item}) {
    const history = useHistory();

    const [giftCardData, setGiftCardData] = useState({
        edit   : item._id ? true : false,
        product: item,
    })
    
    const [state, setstate] = useState({
        apiError    : '',
        loader      : false,
        deleteLoader: false,
        deleteModal : false,
    })
    
    const [imageModal, setImageModal]    = useState(
        {
            giftCardImageFile              : {open: false, imageEvent: '', return: ''},
        })
        
        const layout = useContext(LayoutContext)
        const brand  = useContext(BrandContext)
        const agency = useContext(AgencyContext)
        
  
    const initState = {
        giftCardLogo     : giftCardData.product && giftCardData.product.image ||'',
        giftCardImageFile: '',
        campaignName     : giftCardData.product && giftCardData.product.campaignName ||'',
        campaignType     : giftCardData.product && giftCardData.product.campaignType || 10,
        detail           : giftCardData.product && giftCardData.product.detail || '',
        amount           : giftCardData.product && giftCardData.product.amount || '',
    }

    useEffect(()=>{
        !giftCardData.edit && formik.setValues({...formik.values, prefix : utils.getRandomString(3).toUpperCase()})
    },[])



    const handleSubmit = async() =>{
        setstate({...state, loader: true});

        if(formik.values.brandLogoFile == "" && formik.values.brandLogo == ""){
            showToaster({
                position : 'top-right',
                title    : 'Missing Product Logo',
                message  : 'Product Logo is required',
                severity : 'error'
            })
        }
        else{
            const user = await localForage.getItem('user')
            const desiredPath = `${user._id}/${formik.values.productName}`

            let logo
            if(formik.values.giftCardImageFile && !formik.errors.giftCardImageFile)
            {
                logo = await utils.uploadImage({file: formik.values.giftCardImageFile, desiredPath})
                if(logo.error) return setstate({...state, loader: false, apiError: logo.error.message})
            }

            let payload = {
                campaignName: formik.values.campaignName.toLowerCase(),
                campaignType: 10,
                detail      : formik.values.detail,
                image       : logo ? logo.response.data : (giftCardData.product && giftCardData.product.image),
                amount      : formik.values.amount,
                brandId     : brand._id
            }

            if(!giftCardData.edit){
                const {response , error} = await CampaignService.CreateCampaign({toaster: true, payload})
                if(error) setstate({...state, loader: false, apiError: error.error})
                else{
                    let giftCardCampaigns = await localforage.getItem('giftCardCampaigns');
                    if(giftCardCampaigns == null) giftCardCampaigns = [];
                    giftCardCampaigns.push(response.data);
                    localforage.setItem('giftCardCampaigns', giftCardCampaigns);
                    const passDesign = await PassDesignService.CreatePreBuiltPassDesign({payload: {campaignType: 10,campaignId: response.data._id, brandId : brand._id}})
                    // console.log('passDesign',passDesign);
                    onLoad(true);
                    onClose();
                }
                setstate({...state, loader: false})
            }
            else{
                const {response, error} = await CampaignService.UpdateCampaign({toaster: true, payload: {...payload, _id: giftCardData.product._id}})
                if(error) setstate({...state, loader: false, apiError: error.error})
                else{
                    let giftCardCampaigns = await localForage.getItem('giftCardCampaigns')
                    giftCardCampaigns.map((element, idx)=> {
                        if(element._id === giftCardData.product._id) giftCardCampaigns[idx] = response.data
                    })
                    localForage.setItem('giftCardCampaigns', giftCardCampaigns)
                    if(onLoad) onLoad(true);
                    onClose();
                }
                setstate({...state, loader: false})
            }
            
        }
        setstate({...state, loader: false})

    }
    
    const formik = useFormik({
        initialValues     : { ...initState },
        onSubmit          : handleSubmit,
        validationSchema  : Coupon,
        isInitialValid    : false,
        enableReinitialize: true
        // isInitialValid   : inventoryData && inventoryData.edit,
    })
    
    // useEffect(()=>{
    //     layout.setLayout({
    //         title      : "Create Rinse & Repeat",
    //         expandedBar: false,
    //         button     : <div>
    //         {inventoryData.edit && <CustomButton 
    //             btntext = {"Delete"}
    //             varient = "warningSecondary"
    //             onClick = {()=>setstate({...state, deleteModal : true})}
                
    //         />}
    //         <CustomButton 
    //             btntext   = {"Cancel"}
    //             varient   = "secondary"
    //             className = "ml_8"
    //             onClick   = {()=>{
    //                 if(formik.dirty) setLeave(true)
    //                 else{
    //                     const location = window.location.pathname.split('/')
    //                     location.pop()
    //                     history.push(location.join('/'))
    //                 }
    //             }}
    //         />
    //         <CustomButton 
    //             btntext   = {inventoryData.edit ? "Save Changes" : "Add Item"}
    //             disabled  = {state.loader || !formik.isValid}
    //             onClick   = {handleSubmit}
    //             icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
    //             disabled  = {state.loader || !formik.isValid}
    //             className = "ml_8"
    //         />
    //     </div>
    //     });
    // },[formik.isValid, formik.values, state.loader, formik.dirty])

    const handlePriceChange = (e) => {
        if(e.target.value >= 0) {
            formik.setValues({...formik.values, amount : e.target.value})
        }
    }

    const handleProductDelete = async() => {
        // setstate({...state, deleteLoader: true})
        // const {response, error} = await InventoryService.Update({toaster: true, payload: {delete: true, _id: inventoryData.product._id}})
        // if(response.data){
        //     let inventory = await localforage.getItem('inventory');
        //     let newInventory = inventory.filter((element)=>{
        //        return element._id !== response.data._id
        //     })
        //     localforage.setItem('inventory', newInventory);
        // }        
        // if(error) setstate({...state, loader: false, apiError: error.error})
        // else{ 
        //     onClose(); 
        //     onLoad(true);
        //     setstate({...state, deleteModal : false})
        // }
        // setstate({...state, deleteLoader: false})
    }

    return (
        <div id="GiftCard">
            <div className="row h-100">
                <section className="col-md-12">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="Heading22M color-neutral100">
                            Create Gift Card
                        </div>
                        <div className="Body14R color-neutral60 mt_4">
                            Set your item image, name and description
                        </div>
                        <LogoUploader
                            src       = {formik.values.giftCardImageFile ? URL.createObjectURL(formik.values.giftCardImageFile) : formik.values.brandLogo}
                            label     = "Item Image"
                            hint      = "Upload a 214 X 125 pixel PNG, or JPG to display in browser tabs."
                            className = "mt_24 pl_0 col-md-12 mb_24 z-index-100"
                            onChange  = {(e)=> {setImageModal({...imageModal, giftCardImageFile: {...imageModal.giftCardImageFile, open: true, imageEvent: e}}) }}
                            error     = {formik.values.giftCardImageFile && formik.errors.giftCardImageFile}
                            btntext   = "Upload Logo"
                        />
                        {console.log('formik.values ', formik.values)}
                        <div className="error">{formik.errors.imageFile}</div>

                        <div className="mt_24">
                            <CustomTextField 
                                label      = "Gift Card Name*"
                                className  = "w-100"
                                name       = "campaignName"
                                value      = {formik.values.campaignName}
                                onChange   = {formik.handleChange}
                                inputProps = {{onFocus: formik.handleBlur }}
                                error      = {formik.touched.campaignName && formik.errors.campaignName}
                                helperText = {formik.touched.campaignName && formik.errors.campaignName ? formik.errors.campaignName : ''}
                            />
                        </div>

                        <div className="mt_24">
                            <CustomTextField 
                                label      = "Amount*"
                                className  = "w-100"
                                name       = "amount"
                                type       = "number"
                                value      = {formik.values.amount}
                                inputProps = {{onFocus: formik.handleBlur }}
                                onChange   = {formik.handleChange}
                                error      = {formik.touched.amount && formik.errors.amount}
                                helperText = {formik.touched.amount && formik.errors.amount ? formik.errors.amount : ''}
                                icon       = {<>$</>}
                                position   = "start"
                            />
                        </div>
                        <div className="mt_24">
                            <CustomTextArea 
                                label      = "Description*"
                                className  = "w-100"
                                name       = "detail"
                                value      = {formik.values.detail}
                                onChange   = {formik.handleChange}
                                inputProps = {{onFocus: formik.handleBlur }}
                                error      = {formik.touched.detail && formik.errors.detail}
                                helperText = {formik.touched.detail && formik.errors.detail ? formik.errors.detail : ''}
                            />
                        </div>

                        <div className="mt_24 d-flex justify-flex-end">
                            <div>
                                {giftCardData.edit && <CustomButton 
                                    btntext = {"Delete"}
                                    varient = "warningSecondary"
                                    onClick = {()=>setstate({...state, deleteModal : true})}
                                    
                                />}
                                <CustomButton 
                                    btntext   = {"Cancel"}
                                    varient   = "secondary"
                                    className = "ml_8"
                                    onClick   = {onClose}
                                />
                                <CustomButton 
                                    btntext   = {giftCardData.edit ? "Save Changes" : "Add Item"}
                                    disabled  = {state.loader || !formik.isValid}
                                    onClick   = {handleSubmit}
                                    icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                                    className = "ml_8"
                                />
                            </div>
                        </div>

                    </form>
                </section>

                {/* <section className="col-md-5 border-left col-md-offset-2 rightSection">
                    
                    <div className="searchBox">
                        <CustomSearch 
                            placeholder = "Search Items"
                            className   = "w-100"
                        
                        />
                    </div>

                    <div className="mt_100 text-right removeBtnBox">
                        <button className="removeBtn Link14M">
                            Remove all
                        </button>
                    </div>

                    <section className="listedItems">
                        {inventoryData.products.map((element, idx)=>
                        <div className="row mr_0">
                            <div className="col-md-6 leftListedItem d-flex">
                                <div className="pl_3">
                                    <img
                                        src       = {element.image}
                                        alt       = ""
                                        height    = "40"
                                        width     = "40"
                                        className = "productImage"
                                    />
                                </div>
                                <div className="Link13R color-neutral100 middle ml_8">
                                    {element.name}
                                </div>
                            </div>
                            <div className="col-md-3 Link13R middle color-neutral100">
                                {element.price}
                            </div>
                            <div className="col-md-3 middle pr_0">
                                <CustomButton 
                                    btntext = "Remove"
                                    varient = "warningSecondary"
                                />
                            </div>
                        </div>)}
                    </section>


                    <div className="itemBoxBottom">
                        <div className="Body16R color-neutral100">
                            {inventoryData.products.length} items
                        </div>
                    </div>

                </section> */}
            </div>


            <CustomModal
                open      = { imageModal.giftCardImageFile.open }
                onClose   = { ()=> setImageModal({...imageModal, logo: {...imageModal.giftCardImageFile, open: false}}) }
                minWidth  = "470px"
                minHeight = "470px"
                padding   = "0px"
                component = { 
                    <ImageModalV2 
                        imageModal    = { imageModal }
                        setImageModal = { setImageModal }
                        image         = { 'giftCardImageFile' }
                        formik        = { formik }
                    />
                }
            />  

            <CustomModal 
                open      = { state.deleteModal }
                minWidth  = {'600px'}
                component = {<DeleteModal icon={state.deleteLoader} title="Gift Card" onCancel={()=> setstate({...state, deleteModal : false})} onDelete={handleProductDelete}/>}
                onClose   = {()=> setstate({...state, deleteModal : false})}
            />

        </div>
        
    )
}
