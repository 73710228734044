export const color = {
    white100: "#FFFFFF",
    white200: "#FBFBFB",
    white300: "#F4F4F4",
    white400: "#E3E3E3",
    white500: "#CBCBCB",
    black100: "#000000",
    black200: "#2A2A2A",
    black300: "#444444",
    black400: "#666666",
    black500: "#888888"
  };
  
  export const radius = {
    sm: "2px",
    md: "6px"
  };
  
  export const fontSize = {
    sm: "12px",
    md: "15px"
  };
  
  export const space = {
    "5": "5px",
    "10": "10px",
    "15": "15px",
    "20": "20px",
    "25": "25px",
    "30": "30px",
    "35": "35px",
    "40": "40px",
    "45": "45px"
  };
  