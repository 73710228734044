import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function Editor({value, onChange}) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [],
      [{ color: ["red", "#785412",'#EB5757', '#F2994A', '#F2C94C', '#219653', '#27AE60', '#6FCF97','#2F80ED','#2D9CDB','#56CCF2'] }],
      [{ background: ["red", "#785412",'#EB5757', '#F2994A', '#F2C94C', '#219653', '#27AE60', '#6FCF97','#2F80ED','#2D9CDB','#56CCF2'] }]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font"
  ];


  return (
    <>
      <ReactQuill
        theme    = "snow"
        modules  = {modules}
        formats  = {formats}
        value    = {value}
        onChange = {onChange}
      />
    </>
  );
}

export default Editor;
