import * as yup from 'yup';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    firstName   : yup.string().required(REQ_ERROR),
    lastName    : yup.string().required(REQ_ERROR),
    email       : yup.string().email().required(REQ_ERROR),
    // dialingCode : yup.string().required(REQ_ERROR).matches(/^\+[1-9]\d{0,4}$/),
    dialingCode : yup.string().required(REQ_ERROR).matches(/^(\+?\d{1,3}|\d{1,4})$/),
    phone       : yup.string().strict().required(REQ_ERROR).matches(/^\d{1,10}$/),
})
export {
    Create,
}