import React, { useState, useEffect, useContext } from 'react'

import { GrowthtoolService, TemplateService, UtilsService } from '../../services'

import { PngIcons } from '../../icons'

// import Preview from './Preview'
import CustomButton from '../../components/CustomButton'
import Loader from '../../components/Loader'
import { LayoutContext } from '../../context/Layout.context'
import { useHistory, useParams } from 'react-router-dom'
import { utils } from '../../utils'

function TemplateList(props) {

    const layout     = useContext(LayoutContext)
    const growthtool = props.location.state || {}
    const history    = useHistory()

    const [state, setState] = useState({
        loader            : true,
        selectedDesign    : {},
        updatedGrowthtool : {}
    })
    const [designs, setDesigns] = useState([])

    const GetDesigns = async () => {
        const designs = await UtilsService.GetTemplateDesigns()
        designs.response && setDesigns(designs.response.data)
        setState({...state, loader: false})
    }

    const GetDesignsEmail = async () => {
        const query = {templateType: 'email'}
        const {error, response} = await TemplateService.GetQueriedTemplate({query});
        response && setDesigns(response.data)
        setState({...state, loader: false})
    }


    const onLoad = async () => {
        layout.setLayout({expandedBar: false})
        props.location.state.templates === 'email' ?   await GetDesignsEmail()  :    await GetDesigns()
        return () => layout.setLayout({expandedBar: true})
    }

    useEffect(onLoad, [])

    const handleSelectedDesign = (design) => {
        growthtool[growthtool.growthtoolType] = {json: design.json}
        setState({...state, selectedDesign: design})
    }

    const handleUseTemplate = () => {
        const path = props.location.pathname.split('/')
        path.pop()
        props.location.pathname.includes('communication') 
        ? 
            history.push({pathname: path.join('/') + '/create', state: {design: state.selectedDesign}}) 
        : 
            history.push({pathname: path.join('/'), state: growthtool})
    }

    return (
        <div id="TemplateList">
            <div className="templates">
                <div className="Body14R fw-5 fs-18">
                    Select Templates
                </div>
                
                {state.loader && <Loader width={180} height={180}/>}

                <div className="templateRow mb_32">
                    {designs.map((design,idx)=>
                    <div key={idx} onClick={e=>handleSelectedDesign(design)} className={`singleTempalteBox ${design._id === state.selectedDesign._id && 'active'} mt_32`}>
                        <div className="templateSection">
                            <img src={design.htmlCanvas} style={{width: '100%', maxWidth: '250px'}} alt=""/>
                        </div>
                        <div className="titleSection color-c000000 fw-5 fs-16">
                            {design.templateName}
                        </div>
                    </div>)}
                </div>
            </div>
                <div className="footerPart">
                    <CustomButton
                        btntext  = "Use Template"
                        // height   = "48px"
                        disabled = {utils.emptyJSON(state.selectedDesign)}
                        onClick  = {handleUseTemplate}
                    />
                </div>
            </div>
    )
}

export default TemplateList