import { CircularProgress } from '@mui/material';
import React, { useContext, useState } from 'react'
import CustomButton from '../../components/CustomButton';
import CustomSelect from '../../components/CustomSelect';
import CustomTextField from '../../components/CustomTextField';
import { AgencyContext } from '../../context/Agency.context';
import { PngIcons } from '../../icons';
import { StripeCouponService } from '../../services'

function StripeCoupon() {
    const agency = useContext(AgencyContext);
    const [data, setdata] = useState([])
    const [state, setstate] = useState({
        quantity           : 0,
        duration           : "",
        amount_off         : "",
        currency           : "usd",
        email              : "",
        max_redemptions    : 1,
        couponType         : "",
        duration_in_months : 0,
        loader             : false,
        next               : 0,
        plan               : 99
    })
    return (
        <div className="position-relative">
            <div id='Auth' className="middle">
                <div className="cardForm text-center" style={{justifyContent: 'center'}}>
                {state.next === 0 && <>
                    <div>
                        <img src={(agency.logo || PngIcons.WalletlyShadedLogo)} height="60px" width="60px" className='borderRadius-60' alt="" />
                        <div className="Heading22M color-neutral100">{"Stripe Coupon"}</div>  
                        <div className="mt_8 Caption12M color-Paragaph text-center">Please enter following fields to create your stripe coupon.</div>
                    </div>
                    <div className="w-100 text-center mt_24">
                        <CustomSelect
                            label     = "Select Plan"
                            value     = {state.plan}
                            onChange  = {(e)=>setstate({...state, plan: e.target.value})}
                            className = "w-100 mb_8 text-align-left"
                            options   = {[
                                    {
                                        name : 'Connect Brand / Unlimited',
                                        value : 99
                                    },
                                    {
                                        name : 'Agency Sign Up',
                                        value : 599
                                    },
                                    {
                                        name : 'Brand Pay As You Go',
                                        value : 15
                                    }
                                ]
                            // <>
                            //     <option value = {99}>Connect Brand / Unlimited</option>
                            //     <option value = {599}>Agency Sign Up</option>
                            //     <option value = {15}>Brand Pay As You Go</option>
                            // </>
                            }
                        />
                        <CustomTextField 
                            label     = {"Quantity"}
                            value     = {state.quantity}
                            type      = {"number"}
                            className = "w-100 mb_8"
                            onChange  = {(e)=>setstate({...state, quantity: e.target.value})}
                        />
                        <CustomSelect
                            label     = "Duration"
                            value     = {state.duration}
                            onChange  = {(e)=>setstate({...state, duration: e.target.value})}
                            className = "w-100 mb_8 text-align-left"
                            options   = {[
                                {
                                    name : 'Forever',
                                    value : 'forever'

                                },
                                {
                                    name : 'Repeating',
                                    value : 'repeating'
                                },
                                {
                                    name : 'Once',
                                    value : 'once'
                                }
                            ]
                            // <>
                            //     <option value="forever">Forever</option>
                            //     <option value="repeating">Repeating</option>
                            //     <option value="once">Once</option>
                            // </>
                            }
                        />
                        {
                            state.duration==="repeating"&&
                            <CustomTextField type="number" className="w-100 mb_8" label="Duration In Month" value={state.duration_in_months} onChange={(e)=>setstate({...state, duration_in_months: e.target.value})}/>
                        }
                        <CustomTextField 
                            label     = {"Amount"}
                            value     = {state.amount_off}
                            className = "w-100 mb_8"
                            onChange  = {(e)=>setstate({...state, amount_off: e.target.value})}
                        />
                        <CustomTextField 
                            label     = {"Currency"}
                            value     = {state.currency}
                            className = "w-100 mb_8"
                            onChange  = {(e)=>setstate({...state, currency: e.target.value})}
                        />
                        <CustomTextField 
                            label     = {"Email"}
                            value     = {state.email}
                            type      = {"email"}
                            className = "w-100 mb_8"
                            onChange  = {(e)=>setstate({...state, email: e.target.value})}
                        />
                        <CustomTextField 
                            label     = {"Max Redemptions"}
                            value     = {state.max_redemptions}
                            type      = {"number"}
                            className = "w-100 mb_8"
                            onChange  = {(e)=>setstate({...state, max_redemptions: e.target.value})}
                        />
                        <CustomSelect
                            label     = "CouponType"
                            value     = {state.couponType}
                            onChange  = {(e)=>setstate({...state, couponType: e.target.value})}
                            className = "w-100 mb_8 text-align-left"
                            options   = {
                            [{
                                name : 'agencyCoupon',
                                value : 'agencyCoupon'
                            },
                            {
                                name : 'brandCoupon',
                                value : 'brandCoupon',
                            }]
                            // <>
                            //     <option value="agencyCoupon">agencyCoupon</option>
                            //     <option value="brandCoupon">brandCoupon</option>
                            // </>
                            }
                        />

                        <div>
                            <CustomButton 
                                btntext   = "Confirm"
                                className = "w-100"
                                height    = "40px"
                                // disabled  = {state.loader}
                                // icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                                onClick   = {async()=>{
                                    setstate({...state, loader: true})
                                    const payload = {
                                        ...state,
                                        amount_off: (parseInt(state.plan)-parseInt(state.amount_off))+"00",
                                    }
                                    if(state.duration!=="repeating") delete payload.duration_in_months
                                    delete payload.loader
                                    delete payload.next
                                    delete payload.plan
                                    console.log(payload);
                                    const data = await StripeCouponService.Create({payload, toaster: true})
                                    if(data && data.response && data.response.data && data.response.data.length > 0) setdata(data.response.data)
                                    if(data && data.response && data.response.data && data.response.data.length > 0) setstate({...state, next: 1, loader: false})
                                    else setstate({...state, loader: false})
                                }}
                            />
                        </div>
                    
                    </div>
                    </>}

                    {state.next === 1 && <div>
                        <div style={{minWidth: '500px'}}></div>
                        <div style={{height: '50vh', overflowY: 'auto'}}>
                        {
                            data && data.length > 0 && data.map((value, index)=>(
                                <div className="Body14R color-GeneralBlack mr_8">{value.couponCode}</div>
                                ))
                        }
                        </div>
                        <CustomButton className="w-100 mt_24" height="40px" btntext="Back" varient="secondary" onClick={()=>setstate({...state, next: 0})} />
                </div>}
                </div>
                
            </div>
        </div>
    )
}

export default StripeCoupon
