import * as yup from 'yup';
import { enums } from '../enums';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    // productId  : yup.string().required(REQ_ERROR),
    name       : yup.string().required(REQ_ERROR),
    startDate  : yup.string().required(REQ_ERROR),
    endDate    : yup.string().required(REQ_ERROR),
    entries    : yup.number().required(REQ_ERROR),
    winnerDeal : yup.string().required(REQ_ERROR),
    loserDeal  : yup.string().nullable(),
    optinMethod: yup.string().required(REQ_ERROR),
})


export {
    Create,
}