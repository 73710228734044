import React, { useContext, useEffect, useState } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import Earnpoints from './loyaltysub/Earnpoints';
import Redeempoints from './loyaltysub/Redeempoints';
import PointSystemSetting from './loyaltysub/Pointsystemsetting';
import CampaignFunctions from '../../../components/loyalty/CampaignFunctions';

import { BrandContext } from '../../../context/Brand.context';
import { BrandService, CampaignService, PassDesignService, TierService } from '../../../services';
import { Campaign } from '../../../enums';
import Loader from '../../../components/Loader';

import { ClickOutside } from '../../../utils';
import CircularProgress from '@mui/material/CircularProgress';
import SvgIcons from '../../../icons/svg.icon';
import { ColorSchemeCode } from '../../../enums/ColorScheme'
import PassDesignMain from '../../passDesign/PassDesignMain';
import CopyText from '../../../components/CopyText';
import { CampaignContext } from '../../../context/Campaign.context';
import Tier from './loyaltysub/Tier';
import Venue from './loyaltysub/Venue';
import AddTier from './loyaltysub/AddTier';
import AddVenue from './loyaltysub/AddVenue';

import localForage from 'localforage';
import localforage from 'localforage';
import Giftcards from './loyaltysub/Giftcards';
import SingleGiftCard from './loyaltysub/SingleGiftCard';


export default function LoyaltyPoints(props) {

    const brand    = useContext(BrandContext)
    const campaign = useContext(CampaignContext)


    // console.log('Inside points ', window.location.pathname)

    const [loader, setLoader]                     = useState(false)
    const [campaigns, setCampaigns]               = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})


    const preSelectedCampaign = (campaigns) => {
        const filteredCampaign = campaigns.filter(campaign => campaign._id === brand.selectedCampaign[campaign.campaignType])[0]
        setSelectedCampaign(filteredCampaign)
        campaign.updateCampaign(filteredCampaign)
        // localForage.setItem('selectedLoyalityCampaign', filteredCampaign)
    }

    const createPreBuiltDesign = async (response) => {
        let payload = {
            brandId      : response.brandId,
            campaignId   : response._id,
            campaignType : response.campaignType
        }

        const prebuiltDesign = await PassDesignService.CreatePreBuiltPassDesign({payload})

        let tiers = await localForage.getItem('tiers')
        if(!tiers)
        {
            payload = {brandId : response.brandId, campaignId : response._id}
            const prebuildData = await TierService.PreBuild({payload});
            if(prebuildData.response) localForage.setItem('tiers', prebuildData.response.data)        
        }
                    
        return
    }


    const createPreBuiltCampaign = async () => {
        const payload = {
            brandId      : brand._id,
            campaignType : Campaign.CampaignTypes.LOYALTY_CARD
        }
        const {error, response} = await CampaignService.CreatePreBuiltCampaign({payload})
        if(error) return
        let allLoyalityCampaigns;
        if(response) localForage.setItem('allLoyalityCampaigns', response.data)
        if(response) allLoyalityCampaigns = response.data

        await createPreBuiltDesign(response.data)

        setCampaigns([allLoyalityCampaigns])
        setSelectedCampaign(allLoyalityCampaigns) 

        const brandUpdates = {_id: brand._id, selectedCampaign: {[response.data.campaignType]: response.data._id}}
        const updateBrand = await BrandService.Update({payload: brandUpdates})

        updateBrand.response && brand.updateBrand(updateBrand.response.data)

        return
    }

    const onLoad = async () => {
        let allLoyalityCampaigns;
        allLoyalityCampaigns = await localForage.getItem('allLoyalityCampaigns')
        if(!brand._id) window.location.reload();

        if(!allLoyalityCampaigns)
        {
            const query = { 
                brandId: brand._id,
                campaignType : Campaign.CampaignTypes.LOYALTY_CARD
            }
            const campaign = await CampaignService.GetQueriedCampaign({query})
            if(campaign.response) {
                if(campaign.response) localForage.setItem('allLoyalityCampaigns', campaign.response.data)
                if(campaign.response) allLoyalityCampaigns = campaign.response.data
            }
            
            if(campaign.error) await createPreBuiltCampaign()
        }

        if(allLoyalityCampaigns) {
            preSelectedCampaign(allLoyalityCampaigns)
            setCampaigns(allLoyalityCampaigns)
            await createPreBuiltDesign(allLoyalityCampaigns[0])
        }
        return setLoader(false)
    }

    useEffect(onLoad, [])

    return (
        <div id="loyaltyCampaign" className="component">
            {loader && <Loader baseHeight={'85vh'} width={180} height={180}/>}
            {!loader && 
            <>
            {(
                window.location.pathname === props.match.url + '/earn' ||
                window.location.pathname === props.match.url + '/redeem' ||
                window.location.pathname === props.match.url + '/tier' ||
                window.location.pathname === props.match.url + '/setting'
            ) &&
                <LoyaltyCampaignListDropDown 
                    campaigns            = {campaigns}
                    setCampaigns         = {setCampaigns}
                    brand                = {brand}
                    selectedCampaign     = {selectedCampaign}
                    setSelectedCampaign  = {setSelectedCampaign}
                    createPreBuiltDesign = {createPreBuiltDesign}
                />
            }
            <Switch>    
                <Route 
                    exact
                    path={props.match.url + '/tier'} 
                    render={props=><Tier {...props} selectedCampaign={selectedCampaign}/>}
                />

                <Route 
                    path={props.match.url + '/tier/add'} 
                    exact
                    render={props=><AddTier {...props} selectedCampaign={selectedCampaign}/>}
                />
                <Route 
                    path={props.match.url + '/redeem'} 
                    exact 
                    render={props=><Redeempoints {...props} selectedCampaign={selectedCampaign}/>}
                />
              
                <Route 
                    exact
                    path={props.match.url + '/subbrands'} 
                    render={props=><Venue {...props} selectedCampaign={selectedCampaign}/>}
                />
                <Route 
                    path={props.match.url + '/subbrands/add'} 
                    exact
                    render={props=><AddVenue {...props} selectedCampaign={selectedCampaign}/>}
                />
                <Route 
                    path={props.match.url + '/setting'} 
                    exact
                    component={PointSystemSetting}
                />
                <Route 
                    exact
                    path={props.match.url + '/giftcards'} 
                    render={props=><Giftcards {...props} selectedCampaign={selectedCampaign}/>}
                />
                <Route 
                    exact
                    path={props.match.url + '/giftcard/:id'} 
                    render={props=><SingleGiftCard {...props} selectedCampaign={selectedCampaign}/>}
                />
                <Route
                    path={props.match.url + '/'} 
                    render={props=><Earnpoints {...props} selectedCampaign={selectedCampaign}/>} 
                />
                {/* <Route
                    path={props.match.url + '/design'}
                    exact
                    render={(props)=> <PassDesignMain {...props} selectedCampaign={selectedCampaign}/>}
                /> */}

                {/* <Redirect path={props.match.url + '/*'} to={props.match.url + '/earn'} />
                <Redirect path={props.match.url} to={props.match.url + '/earn'} /> */}
            </Switch>
            </>
            }
        </div>
    )
}


function LoyaltyCampaignListDropDown({campaigns, setCampaigns, brand, selectedCampaign, setSelectedCampaign, createPreBuiltDesign}) {
    const [processingElement, setProcessingElement]       = useState()
    const [campaignDrop, setCampaignDrop]                 = useState(false)
    const [campaignNameEdit, setCampaignNameEdit]         = useState()
    const [loadingNewCampaign, setLoadingNewCampaign]     = useState(false)
    const [filteredCampaigns, setFilteredCampaigns]       = useState()


    const campaign = useContext(CampaignContext)

    const handleCampaginNameChange = ({e, idx}) => {
        campaigns[idx].campaignName = e.target.value
        setCampaigns([...campaigns])
    }

    const UpdateCampaignName = async ({e, idx, _id}) => {
        e.stopPropagation()
        setProcessingElement(idx)
        const updatedName = campaigns[idx].campaignName
        const payload = {_id, campaignName: updatedName}
        const {error, response} = await CampaignService.UpdateCampaign({toaster: true, payload})
        setCampaignNameEdit()
        setProcessingElement()
    }

    const AddNewCampaign = async () => {
        setLoadingNewCampaign(true)
        const payload = {
            brandId      : brand._id,
            campaignType : Campaign.CampaignTypes.LOYALTY_CARD
        }
        const {error, response} = await CampaignService.CreatePreBuiltCampaign({toaster: true, payload})
        if(error) return setLoadingNewCampaign(false);
        response && campaigns.unshift(response.data)
        await createPreBuiltDesign(response);
        setCampaigns([...campaigns])

        setSelectedCampaign(response.data)
        const brandUpdates = {_id: brand._id, selectedCampaign: {[response.data.campaignType]: response.data._id}}
        const updateBrand = await BrandService.Update({payload: brandUpdates})
        updateBrand.response && brand.updateBrand(updateBrand.response.data)

        setLoadingNewCampaign(false)
    }

    const changeSelectedCampaign = async (idx) => {
        setSelectedCampaign(campaigns[idx])
        setCampaignDrop(false)
        campaign.updateCampaign(campaigns[idx])

        const brandUpdates = {_id: brand._id, selectedCampaign: {[campaigns[idx].campaignType] : campaigns[idx]._id}}
        const updateBrand = await BrandService.Update({payload: brandUpdates})

        updateBrand.response && brand.updateBrand(updateBrand.response.data)
    }

    const filterCampaign = (e) => {
        const filteredCampaign = campaigns.filter(campaign => {
            const name = campaign.campaignName.toLocaleLowerCase()
            return name.includes(e.target.value.toLocaleLowerCase())
        })
        setFilteredCampaigns([...filteredCampaign])
    }

    return(
        <div className="col-12 headerBar display-none">
            <div className="col-7 d-flex pl_0">
                <div className="position-relative col-8 pl_0 pr_0">
                    <ClickOutside onClick={()=> setCampaignDrop(false)}>
                        <div className="campaignSelect" onClick={()=> setCampaignDrop(!campaignDrop)}>
                            <div className="mr_29">{selectedCampaign?.campaignName}</div>
                            <SvgIcons.CustomDropDownReplacedTriangleIcon width={10} height={10} color={ColorSchemeCode.neutral80} />
                        </div>
                        {campaignDrop && 
                            <div className="campaign-dropdown">
                                <div onClick={AddNewCampaign} className={`${loadingNewCampaign && "disabled"} d-flex justify-content-between cp pl_17 pt_12 pr_9 pb_12`}>
                                    <div className="color-neutral80">Loyalty Program</div>
                                    {loadingNewCampaign ?  <CircularProgress size={20} color={'inherit'}/> : <div><SvgIcons.Add /></div>}
                                </div>
                                <div className="d-flex campaign-search">
                                    <div><SvgIcons.SearchIcon color={ColorSchemeCode.c828282}/></div>
                                    <input 
                                        placeholder = "Search"
                                        onChange    = {filterCampaign}
                                    />
                                </div>

                                <div className="fixed-block">
                                    {(filteredCampaigns || campaigns).map((campaign, idx) => (
                                        <div 
                                            key       = {idx}
                                            className = {`cp campaign-list ${campaign._id === selectedCampaign._id && 'selected'} ${campaignNameEdit === idx && 'active'}`}
                                            onClick   = {()=>changeSelectedCampaign(idx)}
                                        >
                                            {campaignNameEdit === idx ?
                                                <> 
                                                <div className="col-10 p_0">
                                                    <input 
                                                        className   = "w-100"
                                                        placeholder = "Campaign Name"
                                                        value       = {campaign.campaignName}
                                                        onChange    = {(e) => handleCampaginNameChange({e, idx})}
                                                        onClick     = {(e)=> e.stopPropagation()}
                                                    />
                                                    {!campaign.campaignName && <div className="error ml_0">campaign name is required</div>}
                                                </div>
                                                </>
                                                : 
                                                <div>{campaign.campaignName}</div> 
                                            }
                                            <div className="edit-name">
                                                {processingElement === idx && <CircularProgress size={20} color={'inherit'}/>}
                                                {campaignNameEdit === idx && processingElement !== idx && <span className={`${!campaign.campaignName && 'disabled'}`} onClick={(e)=>UpdateCampaignName({e, idx, _id: campaign._id})}><SvgIcons.DoneCheck/></span>}
                                                {campaignNameEdit !== idx && <span onClick={(e)=> {e.stopPropagation();setCampaignNameEdit(idx)}}><SvgIcons.EditPencil/></span>}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        }
                    </ClickOutside>
                </div>
            </div>
            <div className="col-5 d-flex justify-flex-end pr_0">
                    <CampaignFunctions selectedCampaign={selectedCampaign} brand={brand}/>
            </div>
        </div>
    )
}

