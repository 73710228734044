import localforage from 'localforage';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomButton from '../../components/CustomButton';
import Loader from '../../components/Loader';
import QRcode from '../../components/QrCode';
import { enums, PassDesign } from '../../enums';
import { PngIcons, SvgIcons } from '../../icons';
import { BrandService, PassService } from '../../services';

const AppleDevices = ['MacIntel', 'iPhone', 'iPod', 'iPad', 'Macintosh']

export default function LandingPageQrCode() {
    const history = useHistory();
    const params = useParams();

    // console.log('Hello rafi');

    const [downloadQrLoader, setDownloadQrLoader] = useState(false);

    const [webUrl, setWebUrl] = useState();
    const [state, setState]       = React.useState({})
    const [pass, setPass]         = React.useState({})
    const [brand, setBrand]       = React.useState({})
    const [loader, setLoader]     = React.useState(false)
    const [device, setDevice]     = React.useState()



    const qrCodeData = {
        passCode : history.location.state.result.passCode,
        deal     : history.location.state.dealDetail
    }

    // console.log('[history.location.state]) ', history.location.state);

    useEffect(()=>{
        let url = history.location.state.qrCode ? '' :  `${(window.location.host.includes('localhost') ? window.location.host : 'https://'+window.location.host)}/page/businesscheckout/${history.location.state.brand._id}/${history.location.state.result.passCode}/${history.location.state.dealDetail._id}/${history.location.state.dealDetail.subtract}/${history.location.state.dealDetail.condition}/${history.location.state.dealDetail.conditionValue}/${history.location.state.flowIdArray ? history.location.state.flowIdArray[0] : ''}`
    },[history.location.state])



    const tempName = history.location.state.qrCodeApp?'':history.location.state.payload.firstName.split(" ")
    const name = tempName?tempName.join("%20"):''


    const qrcodeApp = {
        size       : 250,
        text       : history.location.state.qrCodeApp ? '' : (window.location.host.includes('localhost') ?  window.location.host : 'https://'+window.location.host)+'/page/businesscheckout/?brandId='+history.location.state.brand._id+"&passId="+history.location.state.payload.passId+"&passCode="+history.location.state.result.passCode+"&dealId="+history.location.state.dealDetail._id+"&fullName=customer&subtract="+history.location.state.dealDetail.subtract+"&condition="+history.location.state.dealDetail.condition+"&conditionValue="+(history.location.state.dealDetail.condition==='event'?(history.location.state.dealDetail.conditionValue.event+"&startdate="+history.location.state.dealDetail.conditionValue.date+"&enddate="+history.location.state.dealDetail.conditionValue.enddate) : history.location.state.dealDetail.conditionValue)+(history.location.state.flowIdArray?("&flowId="+history.location.state.flowIdArray[0]):''),
        typeNumber : 10,
    }


    const getPass = async () => {
        const query = {passCode: history.location.state.result.passCode, designType: PassDesign.DesignTypes.APPLE}
        const {error, response} = await PassService.GetPassJSON({query})

        // console.log(
        //       'response', response
        // )

        if(error) return

        response && setPass(response.data[PassDesign.DesignTypes.APPLE])
        setState(response.data)


        const brandQuery = {_id: response.data.brandId, delete: false}
        const brandData = await BrandService.GetPublic({query: brandQuery})
        if(brandData.response && brandData.response.data) setBrand(brandData.response.data)
  
        setLoader(false)
        AppleDevices.includes(navigator.platform) && setDevice(enums.DeviceType.APPLE)
  }

//   const onLoad = () => getPass()

//   React.useEffect(onLoad, [])


    const goToMainPage = async()=>{
        if(history.location.state.mainpageUrl){
            let pageUrl  = history.location.pathname;
            pageUrl = pageUrl.split('/');
            pageUrl.pop();
            pageUrl.pop();
            pageUrl.push(`${history.location.state.brand._id}/${history.location.state.payload.passId}/${history.location.state.flowIdArray[0]}`)
            pageUrl = pageUrl.join('/')
            history.push({pathname : pageUrl , state: true })

       }
        else{
            let pageUrl  = history.location.pathname;
            pageUrl = pageUrl.split('/');
            pageUrl.pop();
            pageUrl = pageUrl.join('/');
            pageUrl = pageUrl+'?'+'lat='+history.location.state.brand.location.lat+'&lng'+history.location.state.brand.location.lng;
            await localforage.removeItem('email');
            await localforage.removeItem('deals');
            history.push(pageUrl)
        }
    }

    const downloadQr = () => {
        setDownloadQrLoader(true)
        setTimeout(() => {
            const canvas = document.getElementById("react-qrcode-logo");
                const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
                let downloadLink = document.createElement("a");
                downloadLink.href = pngUrl;
                downloadLink.download = "Qrcode.jpg";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            setDownloadQrLoader(false)
        }, 5000);
    };

    const [app, setApp] = useState('web')

    return (
        <div id="QrCodeScreen" className="w-100 position-relative">
           {loader ?  <div style={{height:'100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}><Loader baseHeight={60} baseWidth={60}/> </div> :
                <div id="NewDealPage" className='middle'>
                    <div className='dealOuterCard position-relative'>
                    <div className="webScreen middle">
                        <img 
                        src       = {history.location.state.brand.brandLogo}
                        height    = "64px"
                        width     = "64px"
                        className = 'borderRadius-60'
                        alt       = ""
                    />
                    <div className="color-neutral60 Body14R mt_16 mb_16">
                        Scan your QR code to redeem.
                    </div>
                </div>
                    
                {!history.location.state.qrCode&&<div style={{height : 84, padding :12}} className="w-100 border borderRadius-4 position-relative d-flex space-between">
                    <div style={{width : '70%'}}>
                        <div className="d-flex flex-column h-100">
                            <div className="pr_16">
                                <div className="capitalize Heading14M color-neutral100 " style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{history.location.state.dealDetail.name}</div>
                                {/* <div className="Caption12M color-neutral60 verticalEllipses">{history.location.state.dealDetail.description}</div> */}
                            </div>
                            { history.location.state.dealDetail.condition === 'visit'?
                            <div className="w-fit-content mt_8" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{history.location.state.dealDetail.conditionValue + ' ' + history.location.state.dealDetail.condition} </div>
                            </div>
                            :
                            history.location.state.dealDetail.condition === 'event'?
                            <div className="w-fit-content mt_8" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{history.location.state.dealDetail.conditionValue.event + ' ' + history.location.state.dealDetail.condition} </div>
                            </div>
                            :
                            <div className="w-fit-content mt_8" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{history.location.state.dealDetail.conditionValue + ' ' + history.location.state.dealDetail.condition}</div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="">
                        {/* <div className="backgroundCover" style={{ position: 'relative', width: '100px', height:'100px', background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("'+history.location.state.dealDetail.image+'")',backgroundPosition: 'center', backgroundSize: 'cover' }}></div> */}
                        <img className="borderRadius-4" style={{objectFit :'cover'}} height="60" width="60" alt="" src={history.location.state.dealDetail.image}/>
                        {/* <div className="color-white Link14M">{'Need ' + ((value.condition !== 'visit') ? (value.conditionValue - history.location.state.result[history.location.state.result.passType].currentPoints).toString() : value.conditionValue ) + ' ' + value.condition +' to unlock'}</div> */}
                    </div>
                </div>}
    
                {!history.location.state.qrCode&&qrcodeApp&&
                <div className='text-center mt_16 mb_50'>
                    {console.log('qrcodeApp', qrcodeApp)}
                    <QRcode qrcode = {qrcodeApp}/>
                </div>}
              
                {history.location.state.qrCode&& <div className="text-center mt_16 mb_50">
                    <QRcode qrcode={history.location.state.qrCodeApp} />
                </div>}

            

                <div className="mt_16" style={{position: 'absolute', left:'0px', bottom :'0px', width: '100%'}}>
                    <CustomButton 
                        height    = "50px"
                        className = "w-100"
                        btntext   = "Cancel"
                        varient   = {'tertiary'}
                        onClick   = {goToMainPage}
                    />
                </div>
                </div>
                </div>
            }
       </div>
    )
}
