import React, {useContext, useEffect, useState} from 'react';
import {Route, Switch, useHistory} from "react-router-dom";

import SettingHeader from '../../components/setting/SettingHeader';
import GrowthToolsList  from './subpages/GrowthToolList';
import CreateGrowthTool from './subpages/CreateGrowthTool';
import GrowthToolForm from './subpages/GrowthToolForm';
import { LayoutContext } from '../../context/Layout.context';
import TemplateList from '../templates/TemplateList';
import CustomButton from '../../components/CustomButton';
import ManyChat from './manychat/ManyChat';
import { GrowthTools } from '../../enums';
import UChat from './uchat/UChat';
import CW from './cw/CW';
import APP from './app/APP';
import GHL from './ghl/GHL';
import ChatRace from './chatrace/ChatRace';
import WidgetNew from './widget/WidgetNew';
import LandingPage from './landingPage/LandingPageNew';
import SignUpPopUp from './SignUpPopUp/SignUpPopUp';
import LandingPageShare from './landingPage/LandingPageShare';

export default function GrowthTool({...props}) {
    const layout  = useContext(LayoutContext)
    const history = useHistory();

    const handleGrowthToolCreate = () => {
        history.push(props.match.url + '/create')
    }

    return (
        <div id="growthtools">
       
            <div className={"component "}>
                <Switch>    
                    <Route exact path={props.match.url + '/'} component={GrowthToolsList}/>
                    <Route exact path={props.match.url + '/create'} component={CreateGrowthTool} />
                    <Route exact path={props.match.url + '/:_id'} component={LandingPage} />
                    <Route exact path={props.match.url + '/share/page'} component={LandingPageShare} />
                    <Route exact path={props.match.url + '/:_id/templates'} component={TemplateList} />
                    <Route exact path={props.match.url + '/api' + '/:id'} component={CW} />
                    <Route exact path={props.match.url + '/app' + '/:id'} component={APP} />
                    <Route exact path={props.match.url + '/ghl' + '/:id'} component={GHL} />
                    <Route exact path={props.match.url + '/manychat' + '/:id'} component={ManyChat} />
                    <Route exact path={props.match.url + '/chatrace' + '/:id'} component={ChatRace} />
                    <Route exact path={props.match.url + '/uchat' + '/:id'} component={UChat} />
                    <Route exact path={props.match.url + '/widget' + '/:id'} component={WidgetNew} />
                    <Route exact path={props.match.url + '/popup' + '/:id'} component={SignUpPopUp} />
                </Switch>
            </div>
        </div>
    )
}


