import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/redeeming'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GetQueriedEarningWays = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const CreatePreBuiltRedeemingWays = async ({payload}) => await axios.post(env.API_URL+service+'/prebuilt', payload, {headers: await MainService.getTokenHeader()})

const RedeemingWayService = {
    Create                      : Handlers.Services(Create),
    Update                      : Handlers.Services(Update),
    GetQueriedEarningWays       : Handlers.Services(GetQueriedEarningWays),
    CreatePreBuiltRedeemingWays : Handlers.Services(CreatePreBuiltRedeemingWays),
}

export default RedeemingWayService
