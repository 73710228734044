import React, { useEffect, useState } from 'react'
import CustomTextField from "../../../../components/CustomTextField";
import CustomTextArea from '../../../../components/CustomTextArea';
import LogoUploader from '../../../../components/LogoUploader';
import { PngIcons, SvgIcons } from '../../../../icons';
import CustomButton from '../../../../components/CustomButton.js'
import { ColorSchemeCode } from '../../../../enums/ColorScheme'
import CustomsModal from '../../../../components/CustomModal';
import Loader from '../../../../components/Loader'
import { ImageModalV2 } from '../../../../components/ImageModalV2/ImageModalV2';
import { utils } from '../../../../utils';
import localforage from 'localforage';
import { DealService, InventoryService } from '../../../../services';
import { Brand } from '../../../../enums';
import { CircularProgress } from '@mui/material'
import DeleteModal from '../../../../components/DeleteModal';
import Fade from 'react-reveal/Fade';


export function AddRewards({ formik, next, setNext ,setup ,setsetup }) {
    const [imageModal, setImageModal] = useState({
        logo: { open: false, imageEvent: '', return: '' },
    })
    const [state, setState] = useState({
        loader : false
    })

    const [loading , setLoading] = useState(false);
    const [deleteModal, setDeleteModal]   = useState(false);
    const [selectedReward, setselectedReward]   = useState();

    const createProduct = async() => {
        setLoading(true)
        // console.log('formik.values.brandLogoFile ', formik.values.brandLogoFile);

        const brand    = await localforage.getItem('brand');
        console.log('Brand',brand);
        const agencyId = brand?.agency?._id || ""

        setState({ ...state, loader: true, brand : brand });

        // console.log('formik.values.brandLogoFile  ', formik.values.brandLogoFile )

        if (formik.values.brandLogoFile == "") {
          utils.showToaster({
            position: "top-right",
            title   : "Missing Reward Logo",
            message : "Reward Logo is required",
            severity: "error",
          });
        } 
      
        else{
            const user = await localforage.getItem("user");
            const desiredPath = `${user._id}/${formik.values.productName}`;

             
            let logo;
            if (formik.values.brandLogoFile && !formik.errors.brandLogoFile) {
                logo = await utils.uploadImage({
                file: formik.values.brandLogoFile,
                desiredPath,
                });
                if (logo.error)
                return setState({
                    ...state,
                    loader: false,
                    apiError: logo.error.message,
                });
            }

                
            let inventoryPayload = {
                name       : formik.values.rewards[formik.values.selectedRewardIndex].name.toLowerCase(),
                description: formik.values.rewards[formik.values.selectedRewardIndex].description,
                image      : logo && logo.response.data,
                price      : formik.values.rewards[formik.values.selectedRewardIndex].price,
                agencyId   : agencyId,
                brandId    : brand._id,
            };

            let dealPayload = {
                name           : formik.values.rewards[formik.values.selectedRewardIndex].name.toLowerCase(),
                description    : formik.values.rewards[formik.values.selectedRewardIndex].description.toLowerCase(),
                image          : logo && logo.response.data ,
                agencyId       : agencyId,
                brandId        : brand._id ,
                discountType   : Brand.DealDiscountType.AMOUNT_TOTAL_PRODUCT ,
                discountValue  : 0 ,
                discountedPrice: formik.values.rewards[formik.values.selectedRewardIndex].price,
                totalPrice     : formik.values.rewards[formik.values.selectedRewardIndex].price,
            };
            if(!agencyId || agencyId == "") {
                delete inventoryPayload.agencyId;
                delete dealPayload.agencyId;
            }



                inventoryPayload.prefix = utils.getRandomString(3);
                const { response, error } = await InventoryService.Create({
                    // toaster: true,
                    payload : inventoryPayload,
                });
                if (error) setState({ ...state, loader: false, apiError: error.error });
                else {
                    let inventory = await localforage.getItem("inventory");
                    if (inventory == null) inventory = [];
                    inventory.push(response.data);
                    localforage.setItem("inventory", inventory);
        
                    dealPayload.items = [response.data._id];
        
                    const dealres = await DealService.Create({
                    payload: { ...dealPayload },
                    toaster: true,
                    });
                    setLoading(false);
                    // console.log('dealres ', dealres)
                    if (dealres.error){
                    setState({
                        ...state,
                        loader: false,
                        apiError: dealres.error.error,
                    });
                }
                    else {
                        let deal = await localforage.getItem("deal");
                        if (deal === undefined || deal === null) deal = [];
                        deal.push(dealres.response.data);
                        localforage.setItem("deal", deal);
                        formik.values.brandLogoFile = '';
                        formik.values.rewards.splice(formik.values.selectedRewardIndex , 1 ,dealres.response.data )
                        formik.setValues({ ...formik.values })
                        if(setup){
                            setsetup(!setup)
                        }else{
                            setNext(!next);
                        }
                    }
                
          
            }

        }

        setState({ ...state, loader: false });

       
    }

    async function handleNextAnother() {
        if(setup == false){
            setsetup(!setup)
        }
         else{
            setNext(!next);
        }
        formik.setValues({ ...formik.values, loader: false });
        let newReward = {
            name: '',
            price: '',
            description: '',
            image: '',
        }

        formik.values.rewards = [...formik.values.rewards, newReward]
        await formik.setValues({ ...formik.values, selectedRewardIndex: formik.values.rewards.length - 1 })
    }


    useEffect(()=>{
        onLoad();
    },[])


    const onLoad = async() =>{     
        if(setup){
            setsetup(!setup)
        }
        const brand    = await localforage.getItem('brand');
        setState({ ...state, brand : brand });

        console.log('state',state.brand);
        const query  = {brandId : brand._id}

        const { error, response } = await DealService.Get({ query });
        if(response?.data){
            // console.log('zee')
            if(setup){
                setsetup(false)
            }else{
                setNext(false)
            }
            formik.setValues({...formik.values , rewards : response.data, loader: false})
           
        }
        else{
            formik.setValues({...formik.values , loader: false})
            if(setup){
                setsetup(true)
            }
        }
    }

    const handleDelete = async (e) => {
        setDeleteModal(true);
        setLoading(true)
        const payload = {
          _id: selectedReward._id,
          delete: true,
        };
        const { error, response } = await DealService.Update({
          toaster: true,
          payload,
        });
        
        if(error ){
            // console.log('error',error);
        }
        else{
            // console.log('response',response);
            // console.log('responseelete',response);
        const filterdata = formik.values.rewards.filter((reward, idx)=>{
            return reward._id != selectedReward._id
        })
        await localforage.setItem('deal', filterdata)
        formik.setValues({...formik.values, rewards : filterdata})
        }
        setDeleteModal(false)
        setLoading(false)
      };

    const onChangeFunc = (e, key) => {
        formik.values.rewards[formik.values.selectedRewardIndex][key] = e.target.value;
        formik.setValues({ ...formik.values })
    }
// console.log('STATE',state);


    return (
       <Fade top>
        <div id='Addrewards' className=''>
            <h1 className='Heading24M'>Add Rewards</h1>
            <p className="Body16M color-neutral60 ">Please setup rewards for your customers</p>
            {
                setup || next ? <>
                {
                    formik.values.loader  ? <Loader baseHeight='350px'/> : 
                    
                    <>
                    <form className='mt_30'>
                    <div className="auth-form scrolly">

                        <div className="w-100 mt_10">
                            <CustomTextField
                                label       = "Reward Name*"
                                type        = "text"
                                placeholder = "Enter Full Name"
                                onChange    = {(e) => onChangeFunc(e, 'name')}
                                value       = {formik.values?.rewards[formik.values.selectedRewardIndex]?.name || ""}
                            />
                        </div>
                        <div className='w-100 spaecial-neg-padding'>
                            <LogoUploader
                                src           = {formik.values.brandLogoFile !== '' ? URL.createObjectURL(formik.values.brandLogoFile) : formik.values.rewards[formik.values.selectedRewardIndex].image }
                                hint          = {<p className='Body11R color-neutral60'>File requirements JPG, PNG, WebP upto 10 MB. <br />Minimum pixels required: 550 for width, 440 for height.</p>}
                                onChange      = {(e) => { setImageModal({ ...imageModal, logo: { ...imageModal.logo, open: true, imageEvent: e } }) }}
                                btnName       = "Upload"
                                imgHeight     = {132}
                                imgWidth      = {165}
                                borderRadius  = '5px'
                                btnColor      = '#2960EC'
                                btnBackground = '#F2F6FF'
                            />

                            <CustomsModal
                                open      = {imageModal.logo.open}
                                onClose   = {() => setImageModal({ ...imageModal, logo: { ...imageModal.logo, open: false } })}
                                minWidth  = "470px"
                                minHeight = "470px"
                                padding   = "0px"
                                component = {
                                    <ImageModalV2
                                        imageModal    = {imageModal}
                                        setImageModal = {setImageModal}
                                        image         = {'logo'}
                                        formik        = {formik}
                                    />
                                }
                            />

                        </div>

                        <div className="w-100">
                            <CustomTextField
                                label       = "Price*"
                                type        = "number"
                                name        = "Price"
                                placeholder = "Enter Price"
                                top={13}
                                className   = "w-100 "
                                onChange    = {(e) => e.target.value >= 0 && onChangeFunc(e, 'price')}
                                position    = {formik.values.rewards[formik.values.selectedRewardIndex]?.price !== '' ?"start" : ''}
                                value       = {formik.values.rewards[formik.values.selectedRewardIndex]?.price || formik.values.rewards[formik.values.selectedRewardIndex]?.discountedPrice || ""}
                                paddingLeft = {formik.values.rewards[formik.values.selectedRewardIndex]?.price !== '' ?"35px" : ''}
                                icon        = {<div className='wpx-50 mb_0 ml_0 mr_0 d-flex'>{state?.brand?.currency?.code}</div>}
                            />
                           {console.log(" Currency", state.brand)}
                        </div>
                        <div className="description w-100">
                            <CustomTextArea
                                label     = {'Description (optional)'}
                                name      = 'description'
                                className = "w-100"
                                onChange  = {(e) => onChangeFunc(e, 'description')}
                                value     = {formik.values.rewards[formik.values.selectedRewardIndex]?.description || ""}
                            />
                        </div>

                    </div>
                    {<div className="w-100  mt_24 gap-12 d-flex flex-column">
                        <CustomButton
                           varient   = "primary"
                           className = "w-100 hpx-52 mb_40"
                           btntext   = {"Create Product"}
                           onClick   = {() => createProduct()}
                           icon={
                            loading && <CircularProgress size={20} color={"inherit"} />
                        }
                           disabled  = {loading  || (formik.values.rewards[formik.values.selectedRewardIndex] &&  (formik.values.rewards[formik.values.selectedRewardIndex].name == "" ||  formik.values?.rewards[formik.values.selectedRewardIndex].price == "" || formik.values?.brandLogoFile == ''))}
                        />
                    </div>}
                </form>
                </>
                }
                    
                </> : <>
                    { 
                        formik.values.rewards?.length > 0 && formik.values.rewards.map((component, idx) => (
                            // id={idx}
                            <div id={idx}> 
                                <div id={idx} className='w-100 d-flex borderSpecial pb_10 mb_20 mt_20 align-items-center justify-content-space-between'>

                                    <div className='w-10' >
                                        {<img src={component.image ? component.image : ''} className='w-100' alt="logo" />}
                                    </div>

                                    <div className='w-70 RewardsDiv pl_20'>
                                        <h5>{component.name}</h5>
                                        <p className="ellipsis-2-line">{component.description}</p>
                                    </div>

                                    <div className='w-10 d-flex align-items-center justify-content-center fs-14 fw-500'>
                                        <p className=' mb_0 d-flex' style={{gap:"10px"}}>{state?.brand?.currency?.code}{component.discountedPrice}</p>
                                    </div>
                                    {console.log('component ', component._id)}

                                    <div onClick={(e)=>{setDeleteModal(true); setselectedReward(component)}} className='w-10 d-flex align-items-center justify-content-flex-end cursor-pointer'>
                                        <SvgIcons.CrossIcon id={idx} />
                                    </div>
                                </div>

                            </div>
                            
                        ))
                        // 
                        
                    }






                    <div className="w-100 gap-12  d-flex flex-column">
                        <CustomButton
                            varient         = "secondary"
                            className       = "w-100 hpx-52"
                            btntext         = "Add Reward"
                            // backgroundColor = "#F2F6FF"
                            onClick         = {() => handleNextAnother()}
                            // color           = "#2960EC"
                        />
                    </div>
                    <div>
                            <CustomsModal 
                            open      = { deleteModal }
                            minWidth  = {'600px'}
                            component = {<DeleteModal icon={loading}  title="Deal" onCancel={()=> setDeleteModal(false)} onDelete={()=>handleDelete()}/>}
                            onClose   = {()=> setDeleteModal(false)}
                            />

                    </div>

                </>

            }

        </div>
        </Fade>

    )
}

export default AddRewards











