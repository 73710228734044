import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";

import Loader from '../../../../components/Loader';
import CommunicationList from '../CommunicationList';
import Actions from '../Actions';

import PngIcons from '../../../../icons/png.icon';

import { BrandContext } from '../../../../context/Brand.context';
import { LayoutContext } from '../../../../context/Layout.context';
import { Communication } from '../../../../enums';
import { CommunicationService } from '../../../../services';
import ActiveStatus from '../../../../components/ActiveStatus';
import CustomButton from '../../../../components/CustomButton';
import { CircularProgress } from '@mui/material';

function SMSList(props) {

    const brand   = useContext(BrandContext)
    const history = useHistory()

    const [state, setState] = useState({
        loader   : true,
        apiError : ''
    })
    const [tableData, setTableData] = useState([])

    const viewDetailsFunc = (template) => {
        // console.log('working', template);
    }
 
    const handleDeleteFunc = async (template) => {
        setState({loader: true})
        const payload = {
            _id     : template._id,
            delete  : true,
        }

        const {error, response} = await CommunicationService.Update({toaster: true, payload})
        if(error) return setState({loader: false, apiError: error.message})
             
            tableData.map((data, idx)=> {
                if(data.props.id === template._id)
                {
                    tableData.splice(idx, 1);
                }
            })


            // setTableData(tableData);
        
        return setState({loader: false})
    }

    const handleEditFunc = (template) => {
        history.push({
            pathname : props.match.url + '/sms/create/' + template._id,
            state    : template
        })
    }

    const handleResponse = (response) => {

        const smsTemplates = response.map((element, index) => 
            // {
                createData({
                    serial      :   index,
                    name        :   element.communicationName,
                    phone       :   element.phone,
                    message     :   element.message,
                    active      :   <ActiveStatus active={element.active} />,
                    actions     :   <Actions
                                        viewDetailsFunc  = {() => viewDetailsFunc(element)}
                                        handleDeleteFunc = {() => handleDeleteFunc(element)}
                                        handleEditFunc   = {() => handleEditFunc(element)}
                                    />,
                    _id         :   element._id,
                    template    :   element
                })
            // }
        )

        setTableData([...smsTemplates])

    }

    const filterArrayDelete = (response) => {
        const arr = []
        response.data.map((element, idx)=>{
            if(element.delete === false)
            {
                arr.push({...element})
            }
        })


        handleResponse(arr);
    }

    const onLoad = async () => {
        const query = {
            brandId           : brand._id,
            communicationType : Communication.CommunicationTypes.SMS
        }
        const {error, response} = await CommunicationService.GetQueriedCommunication({query})
        // console.log(response);
        setState({...state, loader: false})
        error && setState({...state, apiError: error.message, loader: false})
        response && filterArrayDelete(response)
    }

    useEffect(onLoad, [])

    const tableHeadings =  [
        { id: 'serial', label: 'Serial' },
        { id: 'name', label: 'Template Name' },
        { id: 'message', label: 'Message' },
        { id: 'phone', label: 'Phone No' },
        { id: 'actions', label: 'Actions' },
        { id: 'active', label: 'Active' },
    ]


    const layout = useContext(LayoutContext)
    useEffect(()=>{
    layout.setLayout({
        title       : "SMS",
        mainPage    : 'Communication',
        borderBottom: true,
        backTitles  : ['Communication'],
        edit        : false,
        button      : <CustomButton icon={!state.loader ? <CircularProgress size={20} color={'inherit'}/> : ''} className=""  btntext={'Create Sms'} onClick={()=>history.push(props.match.url + '/sms/create')} />
    });
    },[])

    function createData({serial ,name, phone,  message, active, _id, actions, template}) {
        return { 
            props: {
                name             : 'rowProps',
                class            : '',
                id               : _id,
                communicationName: template.communicationName,
                communicationType: template.communicationType,
                checkbox         : false
            },
            template: {template},
            serial: {
                label: serial,
                class: '',
            },
            name: {
                label   : name,
                class   : '',
                numeric : false
            },
            message: {
                label : message,
                class : '',
            }, 
            phone: {
                label : phone,
                class : '',
                numeric : false
            }, 
            actions: {
                label   : actions,
                class   : '',
                numeric : false
            }, 
            active: {
                label   : active,
                class   : '',
                numeric : false
            },
            
        };
    }

    return state.loader ? <Loader width={180} height={180}/> : <CommunicationList {...props} btntext="Add SMS" title={''} tableHeadings={tableHeadings} response={tableData}/>
}

export default SMSList
