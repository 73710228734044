import React, { useContext, useEffect, useState } from 'react';
import {Route, Switch, Redirect, useHistory} from "react-router-dom";

import Earnstamps from './punchpasssub/Earnpoints';
import Redeempoints from './punchpasssub/Redeempoints';
import PointSystemSetting from './punchpasssub/Pointsystemsetting';
import CampaignFunctions from '../../../components/loyalty/CampaignFunctions';

import { BrandContext } from '../../../context/Brand.context';
import { Campaign } from '../../../enums';
import { BrandService, CampaignService, PassDesignService } from '../../../services';
import Loader from '../../../components/Loader';
import { ClickOutside } from '../../../utils';
import { SvgIcons } from '../../../icons';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { CircularProgress } from '@mui/material';
import PassDesignMain from '../../passDesign/PassDesignMain';
import { CampaignContext } from '../../../context/Campaign.context';

export default function Punchpasses(props) {

    
    const brand    = useContext(BrandContext)
    const campaign = useContext(CampaignContext)

    const [loader, setLoader]                     = useState(true)
    const [campaigns, setCampaigns]               = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})

    const preSelectedCampaign = (campaigns) => {
        console.log('preSelectedCampaign',campaigns);
        const filteredCampaign = campaigns.filter(campaign => campaign._id === brand.selectedCampaign[campaign.campaignType])[0]
        console.log('filteredCampaign',filteredCampaign);
        setSelectedCampaign(filteredCampaign)
        campaign.updateCampaign(filteredCampaign)

    }

    const createPreBuiltDesign = async (response) => {
        const payload = {
            brandId      : response.data.brandId,
            campaignId   : response.data._id,
            campaignType : response.data.campaignType
        }

        const prebuiltDesign = await PassDesignService.CreatePreBuiltPassDesign({payload})
    }

    const createPreBuiltCampaign = async () => {
        const payload = {
            brandId      : brand._id,
            campaignType : Campaign.CampaignTypes.STAMP_CARD
        }
        const {error, response} = await CampaignService.CreatePreBuiltCampaign({payload})
        if(error) return

        await createPreBuiltDesign(response)
        setCampaigns([response.data])
        //sdadas
        setSelectedCampaign(response.data) 

        const brandUpdates = {_id: brand._id, selectedCampaign: {[response.data.campaignType]: response.data._id}}
        const updateBrand = await BrandService.Update({payload: brandUpdates})

        updateBrand.response && brand.updateBrand(updateBrand.response.data)

    }

    const onLoad = async () => {
        
        console.log('campaignpreSelectedCampaign');

        if(!brand._id) window.location.reload()

        const query = { 
            brandId     : brand._id,
            campaignType: Campaign.CampaignTypes.STAMP_CARD
        }
        const campaign = await CampaignService.GetQueriedCampaign({query})
        console.log('campaign',campaign);

        if(campaign.response) {
            setCampaigns(campaign.response.data)
            preSelectedCampaign(campaign.response.data)
            return setLoader(false)
        }

        await createPreBuiltCampaign()
        setLoader(false)
    }

    useEffect(onLoad, [])


    return (
            <div id="loyaltyCampaign" className="component">
                {loader && <Loader width={180} height={180}/>}
                {!loader && 
                <>
                {(
                    window.location.pathname === props.match.url + '/earn' ||
                    window.location.pathname === props.match.url + '/redeem' ||
                    window.location.pathname === props.match.url + '/setting'
                ) &&
                    <LoyaltyCampaignListDropDown 
                        campaigns            = {campaigns}
                        setCampaigns         = {setCampaigns}
                        brand                = {brand}
                        selectedCampaign     = {selectedCampaign}
                        setSelectedCampaign  = {setSelectedCampaign}
                        createPreBuiltDesign = {createPreBuiltDesign}
                    />
                }
                <Switch>    
                    <Route
                        path={props.match.url + '/earn'} 
                        render={props=><Earnstamps {...props} selectedCampaign={selectedCampaign}/>} 
                    />
                    <Route 
                        path={props.match.url + '/redeem'} 
                        render={props=><Redeempoints {...props} selectedCampaign={selectedCampaign}/>}
                    />
                    <Route 
                        path={props.match.url + '/setting'} 
                        component={PointSystemSetting}
                    />
                    <Route
                        path={props.match.url + '/design'}
                        render={(props)=> <PassDesignMain {...props} selectedCampaign={selectedCampaign}/>}
                    />

                    <Redirect path={props.match.url + '/*'} to={props.match.url + '/earn'} />
                    <Redirect path={props.match.url} to={props.match.url + '/earn'} />
                </Switch>
                </>
                }
            </div>
    )
}



function LoyaltyCampaignListDropDown({campaigns, setCampaigns, brand, selectedCampaign, setSelectedCampaign, createPreBuiltDesign}) {
    const [processingElement, setProcessingElement]       = useState()
    const [campaignDrop, setCampaignDrop]                 = useState(false)
    const [campaignNameEdit, setCampaignNameEdit]         = useState()
    const [loadingNewCampaign, setLoadingNewCampaign]     = useState(false)
    const [filteredCampaigns, setFilteredCampaigns]       = useState()


    const campaign = useContext(CampaignContext)
    const history  = useHistory();

    const handleCampaginNameChange = ({e, idx}) => {
        campaigns[idx].campaignName = e.target.value
        setCampaigns([...campaigns])
    }

    const UpdateCampaignName = async ({e, idx, _id}) => {
        e.stopPropagation()
        setProcessingElement(idx)
        const updatedName = campaigns[idx].campaignName
        const payload = {_id, campaignName: updatedName}
        const {error, response} = await CampaignService.UpdateCampaign({toaster: true, payload})
        setCampaignNameEdit()
        setProcessingElement()
    }

    const AddNewCampaign = async () => {
        setLoadingNewCampaign(true)
        const payload = {
            brandId      : brand._id,
            campaignType : Campaign.CampaignTypes.STAMP_CARD
        }
        const {error, response} = await CampaignService.CreatePreBuiltCampaign({toaster: true, payload})
        response && campaigns.unshift(response.data)
        await createPreBuiltDesign(response)
        setCampaigns([...campaigns])

        setSelectedCampaign(response.data)
        const brandUpdates = {_id: brand._id, selectedCampaign: {[response.data.campaignType]: response.data._id}}
        const updateBrand = await BrandService.Update({payload: brandUpdates})
        updateBrand.response && brand.updateBrand(updateBrand.response.data)

        setLoadingNewCampaign(false)
    }

    const changeSelectedCampaign = async (idx) => {
        setSelectedCampaign(campaigns[idx])
        campaign.updateCampaign(campaigns[idx])

        const brandUpdates = {_id: brand._id, selectedCampaign: {[campaigns[idx].campaignType] : campaigns[idx]._id}}
        const updateBrand = await BrandService.Update({payload: brandUpdates})
        updateBrand.response && brand.updateBrand(updateBrand.response.data)
    }

    const filterCampaign = (e) => {
        const filteredCampaign = campaigns.filter(campaign => {
            const name = campaign.campaignName.toLocaleLowerCase()
            return name.includes(e.target.value.toLocaleLowerCase())
        })
        setFilteredCampaigns([...filteredCampaign])
    }


    return(
        <div className="col-12 d-flex headerBar pl_0">
            <div className="col-7 d-flex">
                <div className="position-relative col-5">
                    <ClickOutside onClick={()=> setCampaignDrop(false)}>
                        <div className="campaignSelect" onClick={()=> setCampaignDrop(!campaignDrop)}>
                            <div className="mr_29">{selectedCampaign.campaignName}</div>
                            <SvgIcons.CustomDropDownReplacedTriangleIcon width={10} height={10} color={ColorSchemeCode.neutral80} />
                        </div>
                        {campaignDrop && 
                            <div className="campaign-dropdown">
                                <div onClick={AddNewCampaign} className={`${loadingNewCampaign && "disabled"} d-flex justify-content-between cp pl_17 pt_12 pr_9 pb_12`}>
                                    <div className="color-neutral80">Stamp Card</div>
                                    {loadingNewCampaign ?  <CircularProgress size={20} color={'inherit'}/> : <div><SvgIcons.Add /></div>}
                                </div>
                                <div className="d-flex campaign-search">
                                    <div><SvgIcons.SearchIcon color={ColorSchemeCode.c828282}/></div>
                                    <input 
                                        placeholder = "Search"
                                        onChange    = {filterCampaign}
                                    />
                                </div>

                                <div className="fixed-block">
                                    {(filteredCampaigns || campaigns).map((campaign, idx) => (
                                        <div 
                                            key       = {idx}
                                            className = {`cp campaign-list ${campaign._id === selectedCampaign._id && 'selected'} ${campaignNameEdit === idx && 'active'}`}
                                            onClick   = {()=>changeSelectedCampaign(idx)}
                                        >
                                            {campaignNameEdit === idx ?
                                                <> 
                                                <div className="col-10 p_0">
                                                    <input 
                                                        className   = "w-100"
                                                        placeholder = "Campaign Name"
                                                        value       = {campaign.campaignName}
                                                        onChange    = {(e) => handleCampaginNameChange({e, idx})}
                                                        onClick     = {(e)=> e.stopPropagation()}
                                                    />
                                                    {!campaign.campaignName && <div className="error ml_0">campaign name is required</div>}
                                                </div>
                                                </>
                                                : 
                                                <div>{campaign.campaignName}</div> 
                                            }
                                            <div className="edit-name">
                                                {processingElement === idx && <CircularProgress size={20} color={'inherit'}/>}
                                                {campaignNameEdit === idx && processingElement !== idx && <span className={`${!campaign.campaignName && 'disabled'}`} onClick={(e)=>UpdateCampaignName({e, idx, _id: campaign._id})}><SvgIcons.DoneCheck/></span>}
                                                {campaignNameEdit !== idx && <span onClick={(e)=> {e.stopPropagation();setCampaignNameEdit(idx)}}><SvgIcons.EditPencil/></span>}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        }
                    </ClickOutside>
                </div>
            </div>
                <div className="col-5 d-flex justify-flex-end">
                    <CampaignFunctions selectedCampaign={selectedCampaign} brand={brand}/>
                </div>
            </div>
    )
}




