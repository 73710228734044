import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import CustomButton from "../../../../components/CustomButton";
import CustomSwitch from "../../../../components/CustomSwitch";
import ViewDetail from "../../../../components/ViewDetail";
import CopyText from "../../../../components/CopyText";
import EmptyScreen from "../../../../components/EmptyScreen";

import { TierService } from "../../../../services";

import SvgIcons from "../../../../icons/svg.icon";
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import { ClickOutside, utils } from "../../../../utils";
import { showToaster } from "../../../../utils/utils";
import { CampaignContext } from "../../../../context/Campaign.context";
import { BrandContext } from "../../../../context/Brand.context";
import { CircularProgress, IconButton } from "@mui/material";
import Loader from "../../../../components/Loader";
import Agency from "../../../../enums/Agency";
import CustomsModal from "../../../../components/CustomModal";

import { ImageModalV2 } from "../../../../components/ImageModalV2/ImageModalV2";

import localForage from "localforage";
import { LayoutContext } from "../../../../context/Layout.context";
import DeleteModal from "../../../../components/DeleteModal";
import ReactTooltip from "react-tooltip";
import { enums } from "../../../../enums";

function Tier({ selectedCampaign }) {
  const history = useHistory();

  const campaign = useContext(CampaignContext);
  const brand = useContext(BrandContext);
  console.log('ccpplo',campaign);


  const [deleteModal, setDeleteModal] = useState(false);
  const [state, setstate] = useState([]);
  const [filled, setFilled] = useState(false);
  const [members, setMembers] = useState({
    silver: 0,
    gold: 0,
    platinium: 0,})
  const [loader, setLoader] = useState({
    switch: false,
    activate: false,
    loader: true,
    deleteLoader: false,
  });

  const [show, setShow] = useState({
    selectedTier: "",
    moreItems: false,
    loader: false,
    apiError: "",
    check: true,
    copyText: "",
    validation: true,
    openModal: false,
    textModal: "",
  });
  console.log("selected",selectedCampaign);

  const _get = async () => {
    let tiers = await localForage.getItem("tiers");
    console.log('tiers',tiers);
    let memberz = await localForage.getItem("customersAudience");
    console.log('Mem',memberz);
    memberz?.length > 0 && memberz.map((value,index)=>{
      if(value.pass.loyaltyCard.currentTier?.currentTierName == "Silver Tier"){
        const silv = members.silver++
        setMembers({...members , silv})
      }
      else if(value.pass.loyaltyCard.currentTier.currentTierName == "Gold Tier"){
        const gol = members.gold++
        setMembers({...members , gol})
      }
      if(value.pass.loyaltyCard.currentTier?.currentTierName == "Platinum Tier"){
        const plat = members.platinium++
        setMembers({...members , plat})
      }
    })

    if (!tiers) {
      const query = { campaignId: selectedCampaign._id, delete: false };
      const { response, error } = await TierService.Get({ query });
      if (response?.success) {
        localForage.setItem("tiers", response.data);
        tiers = response.data;

        console.log("respond",response);
      }
      
      if (error?.success) {
         console.log("erroez",error);
        const getTierData = await TierService.Get({
          query: { campaignId: selectedCampaign._id, delete: false },
        });
        if (getTierData.error) {
          const payload = {
            brandId: brand._id,
            campaignId: selectedCampaign._id,
          };
          const prebuildData = await TierService.PreBuild({
            toaster: true,
            payload,
          });
          let sortedTiers =
            prebuildData.response &&
            prebuildData.response.data.sort(
              (a, b) => a.pointRange.minPoints - b.pointRange.minPoints
            );
          setstate(sortedTiers);
          localForage.setItem("tiers", prebuildData.response.data);
          tiers = prebuildData.response.data;
        }
      }
    }

    let sortedTiers =
      tiers &&
      tiers.length > 0 &&
      tiers.sort((a, b) => a.pointRange.minPoints - b.pointRange.minPoints);
    setstate(sortedTiers);

    let arr = [];
    tiers &&
      tiers.length > 0 &&
      tiers.map((element, idx) => {
        if (!element.active) arr.push(element);
      });

    if (arr && arr.length >= 0 && tiers && tiers.length >= 0)
      if (arr.length === tiers.length) setShow({ ...show, check: false });

    return;
  };
  
  const _getValidation = async () => {
    const tiers = await localForage.getItem("tiers");

    const data = await TierService.GetValidation({
      query: { brandId: brand._id, campaignId: campaign._id },
    });
    console.log('data',data);
    console.log('brand._id',brand._id);
    if (data && data.error){
      setShow({ ...show, validation: data.error.success });}
     

    return;
  };

  const onLoad = async () => {
    setLoader({ ...loader, loader: true });

    await _getValidation();
    await _get();
    setLoader({ ...loader, loader: false });

    return;
  };

  const showDetailsFunc = (tier) => {
    setShow({ ...show, selectedTier: tier, moreItems: !show.moreItems });
  };

  const addTierFunc = async () => {
    // history.push(`${history.location.pathname + '/add'}`);
    const tiers = await localForage.getItem("tiers");
    let pointsArr = [];
    tiers &&
      tiers.length > 0 &&
      tiers.map((element, idx) => pointsArr.push(element.pointRange.maxPoints));
    let points = { max: Math.max(...pointsArr) };
    if (show.validation)
      history.push({
        pathname: window.location.pathname + "/add",
        state: { points },
      });
    else
      showToaster({
        position: "top-right",
        title: "Error",
        message:
          "You cannot add the tier now. Customers have enrolled, which will cause customer loss.",
        severity: "error",
      });
  };

  const switchHandleActiveFunc = async () => {
    if (show.validation) {
      setLoader({ ...loader, switch: true });

      const payload = {
        activeAll: !show.check,
        brandId: brand._id,
        campaignId: campaign._id,
      };
      const tierUpdate = await TierService.Update({ payload });
      if (tierUpdate.response) {
        let tiers = await localForage.getItem("tiers");
        let array = [];
        tiers.map((element, idx) => {
          array.push({ ...element, active: !show.check });
        });

        localForage.setItem("tiers", array);
        let sortedTiers =
          array &&
          array.length > 0 &&
          array.sort((a, b) => a.pointRange.minPoints - b.pointRange.minPoints);
        setstate(sortedTiers);

        setShow({ ...show, check: !show.check });
        showToaster({
          position: "bottom-right",
          title: !show.check ? "Enabled" : "Disabled",
          message: `Tiers have been ${!show.check ? "enabled" : "disabled"}`,
          severity: "success",
        });
        return setLoader({ ...loader, switch: false });
      }
      if (tierUpdate.error) {
        setLoader({ ...loader, switch: false });
        return showToaster({
          position: "bottom-right",
          title: "Error",
          message: "Unable to update tiers",
          severity: "error",
        });
      }
    } else
      showToaster({
        position: "top-right",
        title: "Error",
        message:
          "You cannot update the tier now. Customers have enrolled, which will cause customer loss.",
        severity: "error",
      });
  };

  const handleTierDeleteFunc = async () => {
    setLoader({ ...loader, activate: true, deleteLoader: true });
    setShow({ ...show, moreItems: false });
    const payload = {
      _id: show.selectedTier._id,
      delete: true,
      brandId: brand._id,
      campaignId: campaign._id,
    };
    const { response, error } = await TierService.Update({
      toaster: true,
      payload,
    });

    if (response) {
      let tiers = await localForage.getItem("tiers");
      tiers.map((element, idx) => {
        if (element._id === show.selectedTier._id) tiers.splice(idx, 1);
      });

      localForage.setItem("tiers", tiers);
      let sortedTiers =
        tiers &&
        tiers.length > 0 &&
        tiers.sort((a, b) => a.pointRange.minPoints - b.pointRange.minPoints);
      setstate(sortedTiers);
    }
    setDeleteModal(false);
    setLoader({ ...loader, activate: false, deleteLoader: false });
  };
  console.log('showxx',show);

  const handleActive = async (active) => {
    if (!show.validation) {
      setLoader({ ...loader, activate: true });
      setShow({ ...show, moreItems: false });
      const payload = {
        _id: show.selectedTier._id,
        active: !active,
        brandId: brand._id,
        campaignId: campaign._id,
      };
      console.log('payloads',payload);
      const atears = await TierService.Update({ toaster: true, payload });
   

      let tiers = await localForage.getItem("tiers");
      tiers.map((element, idx) => {
        if (element._id === show.selectedTier._id)
          tiers[idx] = { ...element, active: !active };
      });

    {  atears.response.success && localForage.setItem("tiers", tiers)}
      let sortedTiers =
        tiers &&
        tiers.length > 0 &&
        tiers.sort((a, b) => a.pointRange.minPoints - b.pointRange.minPoints);
      setstate(sortedTiers);

      setLoader({ ...loader, activate: false });
    } else {
      showToaster({
        position: "top-right",
        title: "Error",
        message:
          "You cannot update the tier now. Customers have enrolled, which will cause customer loss.",
        severity: "error",
      });
      setShow({ ...show, moreItems: false });
    }
  };
  console.log("SHows",show.validation);

  const handleEditFunc = async (value) => {
    console.log('!show.validation',show.validation);
    // if(show.validation) history.push({pathname : `${history.location.pathname + '/add'}`, state: {...show.selectedTier, edit: true}})
    if (!show.validation)
      history.push({
        pathname: `${history.location.pathname + "/add"}`,
        state: { ...value, edit: true },
      });
    else
      showToaster({
        position: "top-right",
        title: "Error",
        message:
          "You cannot edit the tier now. Customers have enrolled, which will cause customer loss.",
        severity: "error",
      });
  };

  const viewMoreItems = [
    // {
    //   name: "Delete Tier",
    //   function: (e) => {
    //     e.stopPropagation();
    //     setShow({ ...show, moreItems: false });
    //     if (show.validation) setDeleteModal(true);
    //     else
    //       showToaster({
    //         position: "top-right",
    //         title: "Error",
    //         message:
    //           "You cannot delete the tier now. Customers have enrolled, which will cause customer loss.",
    //         severity: "error",
    //       });
    //   },
    //   icon: <SvgIcons.DeleteIcon color={ColorSchemeCode.Heading} />,
    // },
  ];

  // useEffect(onLoad,[])

  useEffect(async () => {
    console.log('selectedCampaign',selectedCampaign);
    if (selectedCampaign?._id) {
      await onLoad();
      setLoader({ ...loader, loader: false });
    }
  }, [selectedCampaign ]);
  useEffect(async () => {
    if (campaign?._id) {
      await _getValidation();
    }
  }, [campaign ]);

  const copyFunc = (id, index) => {
    navigator.clipboard.writeText(id);
    showToaster({
      position: "top-right",
      title: "Info",
      message: "Copied to Clipboard",
      severity: "info",
    });
    setShow({ ...show, moreItems: false });
  };

  const layout = useContext(LayoutContext);

  useEffect(() => {
    setTimeout(() => {
      layout.setLayout({
        expandedBar: true,
        showCollapseIcon: false,
        switch : '',
        // backTitles: ["Loyalty Suite"],
        title:'Loyalty Tier',
        borderBottom: true,
        edit: false,
        button:<></>
        // state ? (
        //   <>
        //     <div id="btn" data-for={"btn"} data-tip={"btn"}>
        //       <div className={!show.validation && "tierDisableButton"}>
        //         <CustomButton
        //           disabled={!show.validation}
        //           btntext="Add Tier"
        //           onClick={addTierFunc}
        //         />
        //       </div>
        //     </div>
        //     {!show.validation && (
        //       <ReactTooltip
        //         backgroundColor={ColorSchemeCode.GeneralBlack}
        //         className="opacity-8 p_8 borderRadius-6"
        //         id="btn"
        //         getContent={() => (
        //           <div>
        //             Customer are using current tiers, <br />
        //             cannot create new tier
        //           </div>
        //         )}
        //         place="bottom"
        //       />
        //     )}
        //   </> )
          // : <></>
        // ,
      });
    }, 100);
  }, [loader, show.validation]);

  return (
    <div id="Tier">
      {loader.loader ? (
        <Loader width={180} height={180} />
      ) : state && state.length > 0 ? (
        <div className="mt_18">
          <div>
            {state.map((value, index) => (
              <>
                {console.log("state", state)}
                <div
                  className="d-flex updatedList space-between cp"
                  onClick={() => handleEditFunc(value)}
                >
                  <div className="d-flex align-items-center">
                    <div className="updatedCard">
                      <div className="w-100 ">
                        <div
                          className="middle"
                          style={{
                            position: "relative",
                            height: "32px",
                            width: "32px",
                            borderRadius: "50%",
                            background: value.backgroundColor,
                          }}
                        >
                          <div
                            className="middle"
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                              background: "white",
                            }}
                          >
                            <div
                              className="borderRadius-50"
                              style={{
                                height: "16px",
                                width: "16px",
                                position: "absolute",
                                background: value.backgroundColor,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml_16">
                      <div className="color-neutral100 d-flex align-items-center Body14R mb_4">
                        {value.name}
                        {
                          value.active ? <div className="TierActive ml_5"><div></div>{"Active"}</div>  :  <div className="TierDeActive ml_5"><div></div>{"Deactive"}</div>
                        }
                        {/* <div className="TierActive ml_5"><div></div>{"Active"}</div> */}
                        {/* <div className="TierDeActive ml_5"><div></div>{"Deactive"}</div> */}

                      </div>
                      <div className="Body14R color-neutral60">
                        {value.pointRange.minPoints +
                          " - " +
                          value.pointRange.maxPoints +
                          " Points"}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gridGaping">
                    <div className="color-neutral100 Body14R">{`${value.name == "Silver Tier" ? members.silver : value.name == "Gold Tier" ? members.gold : value.name == "Platinum Tier" ? members.platinium : '0'} MEMBERS`}</div>
                    <div className="position-relative">
                      <div
                        className="viewMoreIcon cp enabled"
                        onClick={(e) => {
                          e.stopPropagation();
                          showDetailsFunc(value);
                        }}
                      >
                        {show.selectedTier._id === value._id &&
                        loader.activate ? (
                          <CircularProgress size={20} color={"inherit"} />
                        ) : (
                          <SvgIcons.ViewMoreIcon
                            color={ColorSchemeCode.propertySectionTextColor}
                          />
                        )}
                      </div>

                      <div className="enabled">
                        {show.selectedTier._id === value._id && (
                          <ClickOutside
                            onClick={() =>
                              setShow({ ...show, moreItems: false })
                            }
                          >
                            {show.moreItems && (
                              <ViewDetail
                                items={[
                                  {
                                    name: value.active
                                      ? "De-activate Tier"
                                      : "Activate Tier",
                                    function: (e) => {
                                      e.stopPropagation();
                                      handleActive(value.active);
                                    },
                                    icon: (
                                      <SvgIcons.Deactivate
                                        color={ColorSchemeCode.Heading}
                                      />
                                    ),
                                  },
                                  {
                                    name: "Copy Key",
                                    function: (e) => {
                                      e.stopPropagation();
                                      copyFunc(value._id, index + 1);
                                    },
                                    icon: (
                                      <SvgIcons.CopyIcon
                                        color={ColorSchemeCode.Heading}
                                      />
                                    ),
                                  },
                                  ...viewMoreItems,
                                ]}
                              />
                            )}
                          </ClickOutside>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bar"></div>
              </>
            ))}
          </div>
        </div>
      ) : (
        <EmptyScreen
          otherTitle={"No Tiers Added"}
          btnTitle={"Add Tier"}
          btnClick={addTierFunc}
          otherCaption={
            "Add loylty tiers to offer your customer benefits depending on their tiers."
          }
        />
      )}

      <CustomsModal
        open={deleteModal}
        minWidth={"600px"}
        component={
          <DeleteModal
            icon={loader.deleteLoader}
            title="tier"
            onCancel={() => setDeleteModal(false)}
            onDelete={handleTierDeleteFunc}
          />
        }
        onClose={() => setDeleteModal(false)}
      />

      <CustomsModal
        component={
          <div>
            <div className="fs-18 fw-6 color-c000000 mb_8">Customers Found</div>
            <div className="fs-14 fw-4 color-c7A869A">
              Sorry, you cannot {show.textModal} this tier as there are
              customers enrolled in it. It will cause customer loss.
            </div>
            <div className="d-flex justify-content-end mt_24">
              <CustomButton
                // height={'36px'}
                onClick={() => setShow({ ...show, openModal: false })}
                btntext="Okay"
              />
            </div>
          </div>
        }
        open={show.openModal}
        onClose={() => setShow({ ...show, openModal: false })}
        maxWidth={400}
        minWidth={400}
      />
    </div>
  );

  return (
    <>
      {loader.loader ? (
        <Loader width={180} height={180} />
      ) : (
        <div id="Tier" className="ml_24 mr_24 pt_0">
          {
            <div className="d-flex float-left flex-wrap-wrap w-100 mt_24 justify-space-evenly">
              {state && state.length > 0 ? (
                state.map((value, index) => (
                  <div
                    className={
                      "p_24 cardNew otherBorder1px space-between borderRadius-6  cp "
                    }
                    onClick={() => handleEditFunc(value)}
                  >
                    <div className="row">
                      <div className="w-100 col-2">
                        <div
                          className="middle"
                          style={{
                            position: "relative",
                            height: "44px",
                            width: "44px",
                            borderRadius: "50%",
                            background: value.backgroundColor,
                          }}
                        >
                          <div
                            className="middle"
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                              background: "white",
                            }}
                          >
                            <div
                              className="borderRadius-50"
                              style={{
                                height: "24px",
                                width: "24px",
                                position: "absolute",
                                background: value.backgroundColor,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="ml_12 col">
                        <div className="d-flex space-between">
                          <div className="mb_8 color-neutral100 Heading18M w-max-content place-self-end">
                            {value.name}
                          </div>
                          <div
                            className="deleteIcon viewMoreIcon cp enabled"
                            onClick={(e) => {
                              e.stopPropagation();
                              showDetailsFunc(value);
                            }}
                          >
                            {show.selectedTier._id === value._id &&
                            loader.activate ? (
                              <CircularProgress size={20} color={"inherit"} />
                            ) : (
                              <SvgIcons.ViewMoreIcon
                                color={ColorSchemeCode.propertySectionTextColor}
                              />
                            )}
                          </div>
                          <div className="enabled">
                            {show.selectedTier._id === value._id && (
                              <ClickOutside
                                onClick={() =>
                                  setShow({ ...show, moreItems: false })
                                }
                              >
                                {show.moreItems && (
                                  <ViewDetail
                                    items={[
                                      {
                                        name: value.active
                                          ? "De-activate Tier"
                                          : "Activate Tier",
                                        function: (e) => {
                                          e.stopPropagation();
                                          handleActive(value.active);
                                        },
                                        icon: (
                                          <SvgIcons.Deactivate
                                            color={ColorSchemeCode.Heading}
                                          />
                                        ),
                                      },
                                      {
                                        name: "Copy Key",
                                        function: (e) => {
                                          e.stopPropagation();
                                          copyFunc(value._id, index + 1);
                                        },
                                        icon: (
                                          <SvgIcons.CopyIcon
                                            color={ColorSchemeCode.Heading}
                                          />
                                        ),
                                      },
                                      ...viewMoreItems,
                                    ]}
                                  />
                                )}
                              </ClickOutside>
                            )}
                          </div>
                        </div>
                        <div className="color-neutral60 Body14R w-max-content">
                          {value.pointRange.minPoints +
                            " - " +
                            value.pointRange.maxPoints}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex space-between mt_16">
                      <div
                        className="color-neutral60 Body14R"
                        style={{
                          width: "-webkit-fill-available",
                          textAlign: "-webkit-center",
                        }}
                      >
                        <div>
                          {value.benefits.discount.discountValue ? (
                            value.benefits.discount.discountValue + "%"
                          ) : (
                            <div>
                              <div
                                id="custom"
                                data-for={"custom"}
                                data-tip={"custom"}
                              >
                                Custom
                              </div>
                              <ReactTooltip
                                backgroundColor={ColorSchemeCode.Paragraph}
                                className="opacity-8 p_8 borderRadius-6 Caption12R w-12"
                                id="custom"
                                getContent={() =>
                                  value.benefits.discount.customDiscounts.map(
                                    (v, i) => (
                                      <div className="text-align-left">
                                        {i == 0
                                          ? "Monday: " + v + "%"
                                          : i == 1
                                          ? "Tuesday: " + v + "%"
                                          : i == 2
                                          ? "Wednesday: " + v + "%"
                                          : i == 3
                                          ? "Thursday: " + v + "%"
                                          : i == 4
                                          ? "Friday: " + v + "%"
                                          : i == 5
                                          ? "Saturday: " + v + "%"
                                          : i == 6
                                          ? "Sunday: " + v + "%"
                                          : ""}
                                        <br />
                                      </div>
                                    )
                                  )
                                }
                                place="right"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      |
                      <div
                        className="color-neutral60 Body14R "
                        style={{
                          width: "-webkit-fill-available",
                          textAlign: "-webkit-center",
                        }}
                      >
                        <div>
                          {
                            enums.ExtraPoints[
                              value.benefits.points.extraPoints - 1
                            ]
                          }
                        </div>
                      </div>
                      |
                      <div
                        className="color-neutral60 Body14R "
                        style={{
                          width: "-webkit-fill-available",
                          textAlign: "-webkit-center",
                        }}
                      >
                        <div>{value.expirationDays + " Days"}</div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyScreen
                  otherTitle={"No Tiers Added"}
                  btnTitle={"Add Tier"}
                  btnClick={addTierFunc}
                  otherCaption={
                    "Add loylty tiers to offer your customer benefits depending on their tiers."
                  }
                />
              )}
              {/* {
                    state && state.length>0 ?
                        state.map((value, index)=>(
                            <div className={value.active ? "cardDiv position-relative" : "cardDiv position-relative disabled"}>
                                <div className="w-100 middle">
                                    <div className="middle" style={{position: 'relative', height: '80px', width: '80px', borderRadius: '50%', background: value.backgroundColor}}>
                                        <div className="middle" style={{height: '70px', width: '70px', borderRadius: '50%', background:'white'}} >
                                            <div className="borderRadius-50" style={{height: '40px', width: '40px', position: 'absolute', background: value.backgroundColor}}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="color-c000000 fs-18 fw-6 text-align-center mt_16">{value.name}</div>
                                <div className="color-c000000 fs-14 fw-5 text-align-center">{value.pointRange.minPoints+ " - " +value.pointRange.maxPoints}</div>
                                <div className="color-c000000 fs-14 fw-5 text-align-center mb_16">Points</div>
                            
                                <div className="viewMoreIcon cp enabled" onClick={()=>showDetailsFunc(value)}>
                                    {(show.selectedTier._id === value._id) &&  loader.activate ?
                                    <CircularProgress size={20} color={'inherit'}/>
                                    :
                                    <SvgIcons.ViewMoreIcon color={ColorSchemeCode.propertySectionTextColor}/>}
                                </div>
                                <div className="enabled">
                                    {
                                        (show.selectedTier._id === value._id) && 
                                        <ClickOutside onClick={()=>setShow({...show, setShow : false})}>
                                        {show.moreItems && 
                                            <ViewDetail items={[
                                                {
                                                    name    : value.active ? 'De-activate Tier' : 'Activate Tier',
                                                    function: ()=>handleActive(value.active),
                                                    // function: ()=>{},
                                                    // icon    : <SvgIcons.AvalibilityIcon/>
                                                },
                                                ...viewMoreItems
                                            ]}/>
                                        }  
                                    </ClickOutside>}                      
                                </div>

                                <div className="ml_auto d-flex bg-color-cF6F6F9 borderRadius-20 px-4 py-2 h-fit-content position-relative">
                                    <div className="Body14R fw-5 opacity-10 color-textfieldColor d-flex">
                                        Key :
                                        <div className = "Body14R ml_5 color-c828282 opacity-10">
                                            <input className="border-none bg-color-cF6F6F9 inputField" type="text" value={value._id} id={"keyId"+(index+1)} />
                                        </div> 
                                    </div>
                                    {show.copyText !== value._id ? <div className="copyIcon pt_3" onClick={()=>copyFunc(value._id, index+1)}><SvgIcons.CopyIcon/></div> : <div className="copyIcon"><SvgIcons.CopyIcon color={Agency.agencyColor ||  ColorSchemeCode.themeColor}/></div>}
                                </div>

                                <div className="color-c000000 fs-16 fw-6 mt_16">Benifits:</div>
                                <div className="d-flex justify-content-space-between mt_8"><div className="color-c000000 fs-14 fw-6 ">Discount:</div><div className="color-c000000 fs-14 fw-4">{value.benefits.discount.discountValue}</div></div>
                                <div className="d-flex justify-content-space-between mt_8"><div className="color-c000000 fs-14 fw-6 ">Points:</div><div className="color-c000000 fs-14 fw-4">{value.benefits.points.extraPoints}</div></div>
                                <div className="d-flex justify-content-space-between mt_8"><div className="color-c000000 fs-14 fw-6 ">Birthday Coupon:</div><div className="color-c000000 fs-14 fw-4">{"$30 voucher"}</div></div>
                                <div className="d-flex justify-content-space-between mt_8"><div className="color-c000000 fs-14 fw-6 ">Expirations:</div><div className="color-c000000 fs-14 fw-4">{value.expirationDays}</div></div>
    
                            </div>
    
                        ))
                    :

                    <EmptyScreen otherTitle={'No Tiers Added'} btnTitle={'Add Tier'} btnClick={addTierFunc} otherCaption={'Add loylty tiers to offer your customer benefits depending on their tiers.'}/>
                   
                    } */}
            </div>
          }

          <CustomsModal
            open={deleteModal}
            minWidth={"600px"}
            component={
              <DeleteModal
                icon={loader.deleteLoader}
                title="tier"
                onCancel={() => setDeleteModal(false)}
                onDelete={handleTierDeleteFunc}
              />
            }
            onClose={() => setDeleteModal(false)}
          />

          <CustomsModal
            component={
              <div>
                <div className="fs-18 fw-6 color-c000000 mb_8">
                  Customers Found
                </div>
                <div className="fs-14 fw-4 color-c7A869A">
                  Sorry, you cannot {show.textModal} this tier as there are
                  customers enrolled in it. It will cause customer loss.
                </div>
                <div className="d-flex justify-content-end mt_24">
                  <CustomButton
                    // height={'36px'}
                    onClick={() => setShow({ ...show, openModal: false })}
                    btntext="Okay"
                  />
                </div>
              </div>
            }
            open={show.openModal}
            onClose={() => setShow({ ...show, openModal: false })}
            maxWidth={400}
            minWidth={400}
          />
        </div>
      )}
    </>
  );
}

export default Tier;
