
import React, { useContext, useEffect, useState } from 'react'

import localforage, * as localForage from 'localforage'


import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';

import { Options, utils } from '../../../utils';

import { LayoutContext } from '../../../context/Layout.context';
import { BrandService } from '../../../services';
import { SvgIcons } from '../../../icons';
import CustomsModal from '../../../components/CustomModal';
import { CircularProgress, FormControl, FormHelperText, RadioGroup } from '@mui/material';
import CustomButton from '../../../components/CustomButton';
import { Brand } from '../../../enums'
import DeleteModal from '../../../components/DeleteModal';
import { AgencyContext } from '../../../context/Agency.context';
import { showToaster } from '../../../utils/utils';
import CustomRadio from '../../../components/CustomRadio';
import 'react-phone-input-2/lib/style.css'
import CustomSwitch from '../../../components/CustomSwitch';
import { BrandContext } from '../../../context/Brand.context';
import { useFormik } from 'formik';
import { BrandVld } from '../../../validation';


export default function InStore() {

    const brand = useContext(BrandContext);
    const layout = useContext(LayoutContext)



    const handleRadioChange = (event) => {
        const value = event.target.value;
        formik.setValues({ ...formik.values, postPaid: value })

    };
    const initState = {

        inStoreReward: brand.inStoreReward || "false",
        pointStoreOffering: brand.pointStoreOffering || "false",
        postPaid: brand.postPaid ? '1' : '0'
    }
    const [state, setState] = useState({
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        valid: true
    })

    const formik = useFormik({
        initialValues: { ...initState },
        validationSchema: BrandVld.UpdateBrand,
        isInitialValid: true,
        enableReinitialize: true
    })
    console.log('formik is ', formik.values);


    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })

        // const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+'+formik.values.fullPhone)

        // let logo, cover
        // const user        = await localforage.getItem('user')
        // const desiredPath = `${user._id}/${formik.values.brandName}`


        // if(formik.values.brandLogoFile){
        //     logo = await utils.uploadImage({file: formik.values.brandLogoFile, desiredPath})
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        // }
        // if(logo && logo.error) return setState({loader: false, brandingLoader : false, apiError: logo.error.message})

        // // uploading cover
        // if(formik.values.brandCoverFile){
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        //     cover = await utils.uploadImage({file: formik.values.brandCoverFile, desiredPath})
        // }
        // if(cover && cover.error) return setState({loader: false, brandingLoader : false, apiError: cover.error.message})

        // const permissions        = await localforage.getItem('permissions')

        // if(formik.values.brandLogoFile){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].logo = logo.response.data;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }

        // if(formik.values.brandName){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].brandName = formik.values.brandName;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }

        const payload = {
            _id: brand._id,
            inStoreReward: formik.values.inStoreReward == "false" ? false : true,
            pointStoreOffering: formik.values.pointStoreOffering == "false" ? false : true,
            postPaid: formik.values.postPaid == '1' ? true : false

        }

        console.log('extra service payload is ', payload);

        // if(logo && logo.response) payload.brandLogo    = logo.response.data
        // if(cover && cover.response) payload.brandCover = cover.response.data

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })

        brand.updateBrand(response.data)
        return setState({ generalLoader: false, apiError: '' })
    }


    React.useEffect(() => {
        layout.setLayout({
            title: 'Settings',
            borderBottom: false,
            switch: "",
            button: ""
        })
    }, [formik.values, formik.errors, state.loader])


    useEffect(async () => {
        let ways = await localforage.getItem('earningways');
        console.log('ways', ways);
        let spending = ways.filter((ele, idx) => {
            return ele.activity == 'spending'
        })
        if (spending[0]?.earningValueRange?.length > 1 || spending[0].earningType !== 'range') {
            setState({ ...state, valid: false })
        }
        console.log('spending', spending);

    }, [])

    return (

        <>
            <div className="w-100">
                <div className='col-md-12   col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>In-Store Reward Type</div>
                    <CustomButton
                        btntext={"Update"}
                        type="submit"
                        disabled={state.generalLoader}
                        icon={state.generalLoader && <CircularProgress size={20} color={'inherit'} />}
                        onClick={handlePublicProfileUpdateFunc}
                    />

                </div>
                <div className=" col-md-12 col-12 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                <div className="col-12 col-md-12 pl_0 pr_0">
                    <div className="w-70 mt_10">
                        <div className="Heading16M color-neutral100 ">
                            Are you considering implementing a rewards program that is separate from Walletly?
                        </div>
                        {console.log('formik ', formik.values)}
                        <div className="mt_8 ml_11" id='inStore-radio'>
                            <FormControl
                            >
                                <RadioGroup
                                    name="inStoreReward"
                                    onChange={formik.handleChange}

                                    value={formik.values.inStoreReward}
                                >
                                    <CustomRadio
                                        value={"true"}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={"false"}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>

                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="Heading16M color-neutral100" style={{ marginTop: "40px" }}>
                            Would you prefer to disable Auto Redemption on Pass and instead offer manual rewards?
                        </div>
                        <div className='point-two'>This would allow customers to accrue points, but their pass would not be updated until they are eligible for a reward.</div>
                        <div className="mt_8 ml_11" id='inStore-radio2'>
                            <FormControl

                            >
                                <RadioGroup
                                    name="pointStoreOffering"
                                    onChange={formik.handleChange}
                                    value={formik.values.pointStoreOffering}
                                >
                                    <CustomRadio
                                        value={"true"}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={"false"}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>

                                </FormHelperText>
                            </FormControl>
                        </div>

                        {/* //hamza changes */}


                        <div className="Heading16M color-neutral100" style={{ marginTop: "40px" }}>
                            POST PAID
                        </div>
                        <div className='point-two'>This would allow customers to accrue points, but their pass would not be updated until they are eligible for a reward.</div>
                        <div className="mt_8 ml_11" style={{ pointerEvents: state.valid ? 'auto' : 'none' }} id='inStore-radio2'>
                            <FormControl
                            >
                                <RadioGroup
                                    name="sessions"
                                    onChange={handleRadioChange}
                                    value={formik.values.postPaid}
                                >
                                    <CustomRadio
                                        value={'1'}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={'0'}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>

                                </FormHelperText>
                            </FormControl>
                        </div>



                    </div>
                </div>

            </div>
        </>
    )
}
