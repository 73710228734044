import React, {useState, useEffect, useContext} from 'react'

import {useHistory} from 'react-router-dom'

import FitContentInput from '../../../components/FitContentInput'
import CustomButton from '../../../components/CustomButton'

import {PngIcons, SvgIcons} from '../../../icons'

import {AgencyContext} from '../../../context/Agency.context'
import {LayoutContext} from '../../../context/Layout.context'
import {BrandContext} from '../../../context/Brand.context'

import { ColorSchemeCode } from '../../../enums/ColorScheme'
import { CircularProgress } from '@mui/material'
import CustomSelect from '../../../components/CustomSelect'
import { Options } from '../../../utils'
import ConnectionsView from '../subpages/ConnectionsView'
import { CampaignService, FlowService, GrowthtoolService } from '../../../services'

import { useFormik } from 'formik';
import { GrowthtoolVld } from '../../../validation'
import { showToaster } from '../../../utils/utils'
import * as localForage from 'localforage'
import { GrowthTools } from '../../../enums'
import Loader from '../../../components/Loader'

export default function GHL() {
    const [flow, setFlow] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})
    const [loader , setLoader] = useState(false);
    
    const brand   = useContext(BrandContext)
    const layout  = useContext(LayoutContext)
    const agency  = useContext(AgencyContext)
    const history = useHistory();

    const [state, setState] = useState({
        processing : false,
        apiError   : '',
    })
    
    const [growthtool, setGrowthtool] = useState(history.location.state)

    const onLoad = async() => {
        setLoader(true)
        layout.setLayout({editTitle  : formik.values.growthtoolName || ""})

        // const {response, error} = await FlowService.Get({query: {triggerType : "checkIn",brandId: brand._id, delete: false}})
        const {response, error} = await FlowService.Get({query: { brandId: brand._id, flowType:'new' ,delete: false }})
        if(response) {
            let arr2 = [];
            console.log('response ', response.data);
            const flowList = response.data.forEach((element,idx)=>{
                    arr2.push(element);
            })
            setFlow(arr2);
            if (arr2.length == 1) formik.setValues({...formik.values , flow : arr2[0]._id })
            if(arr2.length == 0) {
                showToaster({position : 'bottom-right',title    : 'No Flows!' ,message  : 'Please create Landingpage flows first',severity : 'error'})
            }
        }
        setLoader(false)
    }
    

    const handleCampaigns = (campaigns) => {
        setCampaigns([...campaigns])
        const campaign = campaigns.find(campaign => campaign._id === formik.values.campaignId)
        setSelectedCampaign(campaign || {})
    }
    

    const CallServices = async () => {
        const query = {
            brandId      : brand._id,
            campaignType : 5
        }
        const { error, response } = await CampaignService.GetQueriedCampaign({query})
        response && handleCampaigns(response.data)
    }

    useEffect(()=>{
        layout.setLayout({
           borderBottom : true,
        })
        onLoad();
        CallServices();
    },[])


    const initState = {
        flow           : history.location.state && history.location.state.flowId ? history.location.state.flowId[0]              : '',
        growthtoolName : history.location.state && history.location.state.growthtoolName ? history.location.state.growthtoolName : '',
        growthtoolType : history.location.state && history.location.state.growthtoolType ?history.location.state.growthtoolType  : '',
        flowName       : '',
    }

    const formik = useFormik({
        initialValues      : { ...initState },
        validationSchema   : GrowthtoolVld.CW,
        isInitialValid     : true,
        enableReinitialize : true
    })


    const setFlowName = () => {
        flow.filter((element, idx)=>{
            (element._id == formik.values.flow) && formik.setValues({...formik.values, flowName : element.name})
        })
    }

    useEffect(()=>{
        setFlowName();
    },[formik.values.flow, flow])

    console.log('formik ', formik.values, flow)

    const handleSubmit = async() => {
        if(flow.length < 1) {
            showToaster({position: 'top-right', title: 'Title Error', message:  'Please connect flow with your growth tool' , severity: 'error'})
            return 
        }
        else{
            setState({...state, processing: true})
            const payload = {
                _id           : growthtool._id,
                campaignId    : campaigns[0]._id,
                flowId        : formik.values.flow ? [formik.values.flow] : [flow[0]._id],
                growthtoolName: layout.elements.editTitle
            }
            console.log('payload ', payload)
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET){
                const user = await localForage.getItem('user')
                payload.widget.desiredPath = `${user._id}/${brand.brandName}`
            }
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.USER_DASHBOARD){
                const user = await localForage.getItem('user')
                payload.userDashboard.desiredPath = `${user._id}/${brand.brandName}`
            }
    
            const { error, response } = await GrowthtoolService.UpdateGrowthTool({toaster: true, payload})
            setState({...state, processing: false})
            if(error) return setState({...state, processing: false, apiError: error.message})
    
            const path = window.location.pathname.split('/')
            path.pop()
            const location = window.location.pathname.split('/')
            location.pop()
            location.pop()
            history.push(location.join('/'))
            return
        }
    }

    useEffect(()=>{
        layout.setLayout({
            edit       : true,placeholder : "Growthtool",
            borderBottom: true,
            backTitles  : ['Channels'],
            editTitle  : layout.elements.editTitle || formik.values.growthtoolName,
            button     : 
                <CustomButton
                    btntext  = {'Publish & close'}
                    type     = {'submit'}
                    onClick  = {handleSubmit}
                    disabled = {!formik.isValid}
                    icon     = {state.processing && <CircularProgress size={20} color={'inherit'}/>}
                />
        })
         },[campaigns, formik.values, growthtool ,state.processing,layout.elements.editTitle])


    useEffect(()=>{
        layout.setLayout({
            editTitle : growthtool.growthtoolName || ''
        })
    },[])


    return (
        <div id="manyChatGrowthTool" >
            {!loader ?  <div className="insideComponent d-flex">
                <div className="w-30">
                    <LeftPane growthtool={growthtool} formik={formik}  flow={flow}/>
                </div>
                <div className="flowSection w-70">
                   <ConnectionsView formik={formik} />
                </div>
            </div> : <Loader height="32px" width="32px" />}
        </div>
    )
}

const LeftPane = ({formik, flow}) => {

    const brand = useContext(BrandContext)
    const [selectedSection, setSelectedSection] = useState('setting')

    return(
        <div className="leftSectionWidget">
            <div className='d-flex pt_12 pb_12 border-bottom'>
                    <div className={`tabButton Link13R transition w-50 text-center  ${selectedSection == 'setting' && 'selectedTabButton Link13M'} `} onClick={()=>setSelectedSection('setting')}>
                        Setting
                    </div>
            </div>
            <div className="tabBody">
                        <div>
                            <div className="border-bottom pt_8 pb_8 ">
                            <div className="Link12M color-neutral100 pt_8 pb_8">
                                Connect Flow
                            </div>

                            <div className='d-flex align-items-center gap-12'>
                                <div className="Link11R color-neutral70 w-30">
                                    Flow
                                </div>
                                <select 
                                    className   = {`panelDropdown panelSelect Link11R w-70 transition ${flow.length == 0 && 'disabled'}`}
                                    value       = {formik.values.flow}
                                    placeholder = "Flow"
                                    onChange    = {(e)=>formik.setValues({...formik.values, flow: e.target.value})}
                                >
                                    {flow && flow.length > 0 && flow.map((value, index)=> (
                                        <option value={value._id}>{value.name}</option>
                                    ))}
                                </select>

                                </div>
                            </div>
                        </div>
            </div>
        </div>
    )
}

