import React, {useState} from 'react';


import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Graph from '../../../components/Graph';

import CampaignTypeHeader from '../../../components/referrel/CampaignTypeHeader';


import SvgIcons from '../../../icons/svg.icon';

var date = new Date();


//dummy data for graphing
const data= {
    labels: ['Jan','Feb','Mar','April','May'],
    datasets:[
        {
            label: 'Analytics ' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear(),
            data:  [3,4,3,4,3]
        }
    ]
}

//data for dropdown-customselect

const campagins = [
    {
        field: 'Milestone Campaign'
    },
    {
        field: 'Other Campagin'
    }

]

const campaignActions = [
    {
        field: 'Campaign Actions'
    }

]


export default function Analytics() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => setValue(newValue)
    

    return(
        <div id="analytics">
            <div>
                <CampaignTypeHeader campaignActions={campaignActions} campaigns={campagins} /> 
            </div>     

       
            <div className="col-12">
       
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    aria-label="Analytics Tab" 
                    className="tabs mt_40 pb_24">
                    
                    <Tab label="Analytics" />
                {/* <Tab label="Tab 2" /> */}
                </Tabs>   
        
            </div> 
        
        {value === 0 ?
                <div className="graphBox">
                    
                    <div className="row">
                        
                        <div className="col-2 col-md-12 Heading22R">
                            
                            Analytics <span className="headingDate pb_24">
                            (March {date.getDate()-1}, {date.getFullYear() + ' to today'})
                            </span>

                        </div>

                    </div>
                    
                    <div className="row GraphOuterDiv mt_40">
                        
                        <div className="Details col-2">

                            <div className="subtitle1 mt_24">Invites

                                <div className="mt_10">
                                    <SvgIcons.InviteIcon/><span className="headlineH3 ml_10">0</span>
                                </div>

                            </div>
                            
                            <div className="subtitle1 mt_24">Impressions
                                <div className="mt_10">
                                    <SvgIcons.ImpressionIcon/><span className="headlineH3 ml_10">0</span>
                                </div>

                            </div>

                            <div className="subtitle1 mt_24">Participants
                                
                                <div className="mt_10">
                                    <SvgIcons.ParticipantIcon/><span className="headlineH3 ml_10">0</span>
                                </div>

                            </div>

                            <div className="subtitle1 mt_24">Referrals
                                
                                <div className="mt_10">
                                    <SvgIcons.ReferralIcon/><span className="headlineH3 ml_10">0</span>
                                </div>

                            </div>
                        

                        </div>
                        

                        <div className="graph col-10">
                                <Graph data={data}/>
                        </div>

                        
                    </div>
                
                </div>

        : 
            <></>
            
        }
                    
                
        </div>
    )

}