import axios from "axios";
import { Handlers } from "../utils";
import MainService from "./Main.service";
import env from "../config";
const service = "/v4/auth";

const Login = async ({ payload }) =>
  await axios.post(env.API_URL + service + "/login", payload);

const ForgetPassword = async ({ payload }) =>
  await axios.post(env.API_URL + service + "/forget", payload);

const UpdatePassword = async ({ payload }) =>
  await axios.put(env.API_URL + service + "/updatePassword", payload);

const Signup = async ({ payload }) =>
  await axios.post(env.API_URL + service, payload);

const Resend = async ({ payload }) =>
  await axios.post(env.API_URL + service + "/sendVerification", payload, {
    headers: await MainService.getTokenHeader(),
  });

const sendEmail = async ({ payload }) =>
  await axios.post(env.API_URL + service + "/sendEmail", payload);

const VerifyAccount = async ({ payload }) =>
  await axios.post(env.API_URL + service + "/verifyAccount", payload, {
    headers: await MainService.getTokenHeader(),
  });

const AuthService = {
  Login: Handlers.Services(Login),
  ForgetPassword: Handlers.Services(ForgetPassword),
  UpdatePassword: Handlers.Services(UpdatePassword),
  Signup: Handlers.Services(Signup),
  Resend: Handlers.Services(Resend),
  VerifyAccount: Handlers.Services(VerifyAccount),
  sendEmail: Handlers.Services(sendEmail),
};

export default AuthService;
