import React, { useEffect, useRef, useState } from 'react'
import { PngIcons, SvgIcons } from '../../../../icons';
import CustomTextField from "../../../../components/CustomTextField";
import CustomSelect from '../../../../components/CustomSelect';
import CopyText from "../../../../components/CopyText";
import CustomButton from '../../../../components/CustomButton.js'
import CustomTextArea from '../../../../components/CustomTextArea';
import localforage from 'localforage';
import { CampaignService, DealService, RedeemingWayService } from '../../../../services';
import { utils } from '../../../../utils';
import { enums } from '../../../../enums';
import DeleteModal from '../../../../components/DeleteModal';
import CustomsModal from '../../../../components/CustomModal';
import Loader from '../../../../components/Loader'
import Fade from 'react-reveal/Fade';


export function WaystoRedeems({ formik, setchkAnoSec, chkAnoSec }) {
  const [state, setstate] = useState({
    filteredDeals: [],
    rewardRedemption:
    {
      costingPoint: '',
      cwCode: '',
      name: '',
      rewardValue: '',
    },
  });
  const [brand, setBrand] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedReward, setselectedReward] = useState();
  const [loading, setLoading] = useState(false);
  const costingPointRef = useRef();
  const cwCodeRef = useRef();
  const rewardValueRef = useRef();
  const nameRef = useRef();

  async function handleNext() {
    setchkAnoSec(0);
  }

  async function handleCreateFunc(key) {
    setchkAnoSec(0);

    let campaign = await localforage.getItem('campaign');
    if (!campaign) {
      campaign = await CampaignService.GetQueriedCampaign({
        query: { brandId: brand._id, campaignType: 5 },
      });
      localforage.setItem('campaign', campaign.response.data)
    }

    // // console.log("campaign get", campaign);

    let payload = {
      brandId: brand._id,
      campaignId: campaign?._id || campaign?.response?.data[0]?._id,
      name: state.rewardRedemption.name,
    };
    // console.log("data: ", campaign?.response?.data)
    if (key == 'referral') {
      payload.redeemingWayType = enums.RedeemingWays.REFERRAL;
      payload.name = formik.values.referral.name
      payload.description = formik.values.referral.description
      payload.rewardValue = {
        costingPointTo: formik.values.referral.PointCostTo,
        costingPointBy: formik.values.referral.PointCostBy,
        costingPointType: formik.values.referral.PointCostType,
        discountValue: 0,
      }
      // console.log('here ', payload)

    }

    else if (key == 'product') {
      payload.redeemingWayType = enums.RedeemingWays.PRODUCT;
      payload.rewardValue = { costingPoint: state.rewardRedemption.costingPoint }
      if (state.rewardRedemption.cwCode) payload.rewardValue.cwCode = state.rewardRedemption.cwCode
      delete payload.description
      // console.log('here ', payload)

    }

    else if (key == "birthday") {
      payload.redeemingWayType = enums.RedeemingWays.BIRTHDAY;
      payload.name = "birthday"
      payload.description = formik.values.birthday.description
      payload.rewardValue = { costingPoint: formik.values.birthday.costingPoint }
    }
    
    const { response, error } = await RedeemingWayService.Create({ payload, toaster: true });

    if (response) {
      // // console.log('response ', response)
      let newReward = { ...payload, _id: response.data }
      // console.log("newReward",newReward);
      if (key == "product") {
        // // console.log('zeeee ', state);
        formik.values.rewardRedemption = [...formik.values.rewardRedemption, newReward];
        nameRef.current.value          = ""
        costingPointRef.current.value  = ""
        rewardValueRef.current.value   = ""
        cwCodeRef.current.value        = ""

        setstate({...state, rewardRedemption : {
          costingPoint: '',
          cwCode      : '',
          name        : '',
          rewardValue : '',
        }})

      }
      else if (key == "referral") {
        formik.values.referral = newReward;
      }
      else {
        formik.values.birthday = newReward;
      }
      formik.setValues({ ...formik.values })
    }


  }

  const handleDelete = async (e) => {
    setDeleteModal(true);
    setLoading(true)
    const payload = {
      _id: selectedReward.id,
      delete: true,
    };

    const { error, response } = await RedeemingWayService.Update({
      toaster: true,
      payload,
    });

    if (error) {
      // console.log('error', error);
    }
    else {
      // // console.log('response', response);
      const filterdata = formik.values.rewardRedemption.filter((reward, idx) => {
        return reward._id != selectedReward.id
      })
      // // console.log('filterdata', filterdata);
      if (selectedReward.key == 'referral') {
        formik.setValues({ ...formik.values, referral: filterdata })

      }
      else if (selectedReward.key == 'birthday') {
        formik.setValues({ ...formik.values, birthday: filterdata })
      }
      else {
        formik.setValues({ ...formik.values, rewardRedemption: filterdata })
        nameRef.current.value         = ""
        costingPointRef.current.value = ""
        rewardValueRef.current.value  = ""
        cwCodeRef.current.value       = ""
        setstate({...state, rewardRedemption : {
          costingPoint: '',
          cwCode      : '',
          name        : '',
          rewardValue : '',
        }})

      }

      setDeleteModal(false)
      setLoading(false)
    };
  }
  // console.log('formis',  formik.values);
  const onChangeFunc = (e, key) => {
    setstate({ ...state, rewardRedemption: { ...state.rewardRedemption, [key]: e.target.value } })
  }

  // console.log('state ', state);


  useEffect(() => {
      onLoadFunc();
  }, []);

  const onLoadFunc = async() =>{
    formik.setValues({ ...formik.values, loader: true })
    let brandData = await localforage.getItem('brand');
    setBrand(brandData);

    let { response, error } = await RedeemingWayService.GetQueriedEarningWays({ query: { brandId: brandData._id, delete: false } })

    if (response) {
      // console.log("RESPONSE",response)
      let birthday = response.data.find((reward) => {
        return reward.redeemingWayType == "birthday"
      })
      let referral = response.data.find((reward) => {
        return reward.redeemingWayType == "referral"
      })
      let products = response.data.filter((reward) => {
        return reward.redeemingWayType == "product"
      })
      if (birthday) {
        formik.values.birthday = birthday;
      }
      if (referral) {
        formik.values.referral = referral;
      }
      if (products.length > 0) {
        formik.values.rewardRedemption = products;
      }
      formik.setValues({ ...formik.values })
    }

    // // console.log('response ', response.data)

    let deal = await localforage.getItem("deal");
    if (deal === null || deal.length === 0) {
      const query = { delete: false, brandId: brandData._id };
      const { error, response } = await DealService.Get({ query });
      if (response) deal = response.data;
    }
    let temp = [];
    if (deal && deal.length > 0) {
      for (const value of deal) {
        temp.push({
          label: value.name,
          value: value._id,
          image: <img src={value.image} width="20px" heigth="20px" />,
          data: value,
        });
      }
      setstate({ ...state, filteredDeals: temp })
    }

    formik.setValues({ ...formik.values, loader: false })

  }
console.log('state.filter/',state.filteredDeals.map(value=>({'name':value.label , 'value' : value.value})));
  const dealListOptions = () => {
    
    
    return state.filteredDeals.map(value=>({'name':value.label , 'value' : value.value}))
    // (
      // <>
      //   {/* <option value="">None</option> */}
      //   <option disabled>Select</option>
      //   {state.filteredDeals?.map((value) => (
      //     <option className='Capitalize' value={value.value}>{utils.capitalize(value.data.name)}</option>
      //   ))}
      // </>
    // )
  }



  return (
    <Fade top>
    <div id='WaysToRedeem'>

      <h1 className='Heading24M'>Ways to redeem</h1>

      {
        formik.values.loader ? <div className='w-100'><Loader baseHeight='80px' /></div> :
          <>
            <div id='GrandParent' className='scrolly'>
              <div className='mt_20  borderSpecial' >
                <div className='w-100  hpx-74 cursor-pointer d-flex justify-content-space-between align-items-center '
                  onClick={() => {
                    if (chkAnoSec === 1) {
                      setchkAnoSec(0)
                    }
                    else {
                      setchkAnoSec(1);
                    }
                  }}
                >
                  <div className='w-15'>
                    <img className='wpx-48' src={PngIcons.RewardRedemptionLogo} alt='Logo' />
                  </div>
                  <div className='w-75'>
                    <h5 className='Heading16M'>Reward Redemption</h5>
                  </div>
                  <div className='w-10 d-flex justify-content-flex-end'>
                    {/* <SvgIcons.PlusIcon className='wpx-20.12' color='#D6DAE9' /> */}
                  </div>
                </div>
                {
                  formik.values?.rewardRedemption?.map((reward, idx) => (
                    <div className='w-100 d-flex bg-color-primary0 p_10 mb_8 align-items-center'>
                      <div className='w-45 Heading15M pl_10'>{reward.name}</div>
                      <div className='w-45  d-flex justify-content-center  Body14M'>{reward?.rewardValue.costingPoint} pts</div>
                      <div id={idx} onClick={(e) => { setDeleteModal(true); setselectedReward({ id: reward._id, key: 'reward' }) }} className='w-10 cursor-pointer'>
                        <SvgIcons.CrossIcon />
                      </div>
                    </div>
                  ))}

                <div className={`expand ${chkAnoSec == 1 && 'expand'}`}>
                  {
                    <div className='w-100 mt_8'>
                      <div className='w-100 mb_8 d-flex justify-content-space-between align-items-center'>
                        <h1 className='Heading20M'>Add Reward redemption</h1>
                      </div>
                      <p className='w-100 rewardsPara'>Offering free product as a reward to your customers is a great way to both give back to your most loyal program members, promote new or featured products and control your inventory.</p>

                      <div className="w-100 mt_20">
                        <CustomTextField
                          label={"Reward Name"}
                          type="text"
                          inputRef={nameRef}
                          placeholder="Reward"
                          value={state.rewardRedemption.name != "" ? state.rewardRedemption.name : ''}
                          onChange={(e) => onChangeFunc(e, 'name')}
                          height='40px'
                          background='#F2F6FF'
                        />
                      </div>

                      <div className="categoryBox mt_20 w-100">
                        <div style={{ minWidth: "100%", width: "fit-content" }}>
                          <CustomSelect
                            label={"Reward Value"}
                            options={dealListOptions()}
                            height='40px'
                            inputRef={rewardValueRef}
                            onChange={(e) => onChangeFunc(e, 'rewardValue')}
                          />
                        </div>
                      </div>
                      {/* {console.log('formik.values.rewardRedemption[formik.values.selectedRewardRedemptionIndex]?.costingPoint  ', formik.values.rewardRedemption[formik.values.selectedRewardRedemptionIndex]?.costingPoint )} */}
                      <div className="w-100 mt_20 pb_20 borderSpecial">
                        <CustomTextField
                          label={"Points Cost"}
                          type="number"
                          placeholder="Reward"
                          inputRef={costingPointRef}
                          value={state.rewardRedemption.costingPoint || ''}
                          onChange={(e) => e.target.value >= 0 ? onChangeFunc(e, 'costingPoint') : ''}
                          height='40px'
                          background='#F2F6FF'
                        />
                      </div>
                      <div className='w-100 mt_20  '>
                        <h5 className='Heading16M'>Discount Type</h5>
                        <p className='Body10M color-neutral50' >Please make sure promo code match with CloudWaitress</p>
                      </div>

                      <div className="w-100 mt_8 mb_8 d-flex align-items-center borderSpecial pb_20">
                        <h3 className='Body14R pr_10 mb_0'>Give CW code</h3>
                        <div className='position-relative'>
                          <CustomTextField
                            type="text"
                            className="w-100"
                            value={state.rewardRedemption?.cwCode}
                            onChange={(e) => onChangeFunc(e, 'cwCode')}
                            height='40px'
                            id='CWcode'
                            inputRef={cwCodeRef}
                            background='#F2F6FF'
                          />
                          <div style={{ position: "absolute", top: "15px", right: "16px", }}>
                            <CopyText content={state.rewardRedemption?.cwCode} hideText={true} />
                          </div>
                        </div>
                      </div>
                      <div className='w-100 mb_20 mt_20  d-flex align-items-center flex-column'>
                        <CustomButton
                          varient   = 'secondary'
                          className = "w-99 hpx-52 "
                          btntext="Add Reward"
                          onClick={() => handleCreateFunc('product')}
                          disabled={state.rewardRedemption?.cwCode == '' || state.rewardRedemption?.name == '' || state.rewardRedemption?.costingPoint == '' || state.rewardRedemption?.rewardValue == ''}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
              {/* {console.log('formik.values ', formik.values.referral)} */}




              <div className='mt_20  borderSpecial'>
                <div className='w-100 mt_20 cursor-pointer hpx-74 d-flex justify-content-space-between align-items-center ' onClick={() => {
                  if (chkAnoSec === 2) setchkAnoSec(0)
                  else setchkAnoSec(2)
                }} >
                  <div className='w-15'>
                    <img className='wpx-48' src={PngIcons.ReferralLogo} alt='Logo' />
                  </div>
                  <div className='w-85'>
                    <h5 className='Heading16M'>referral</h5>
                  </div>
                </div>
                {formik.values.referral?._id &&
                  <div className='w-100 d-flex bg-color-primary0 p_10 mb_8 align-items-center'>
                    <div className='w-45 Heading15M pl_10'>{formik.values?.referral?.name}</div>
                    <div className='w-45  d-flex justify-content-center  Body14M'>{formik.values.referral.rewardValue.costingPointBy} - {formik.values.referral.rewardValue.costingPointTo} pts</div>
                    <div onClick={(e) => { setDeleteModal(true); setselectedReward({ id: formik.values.referral._id, key: 'referral' }) }} className='w-10 cursor-pointer'>
                      <SvgIcons.CrossIcon />
                    </div>
                  </div>
                }

               {/* { // console.log("brandd",formik.values.referral?.brandId )} */}

                {/* Condition */}
                {
                 !formik.values.referral?.brandId   &&
                  <div>
                    <div className='w-100 mb_8 d-flex justify-content-space-between align-items-center'>
                      <h1 className='Heading20M'>Add referral</h1>
                      {/* <SvgIcons.CrossIcon /> */}
                    </div>
                    <div className='w-100'>
                      <h1 className='Heading16M'>Reward Value</h1>
                      <p className='Body12R color-neutral50'>when a customer refers someone to our business and that person makes a purchase, both the referrer and the referee receive points. This incentivizes our customers to invite others to try our products or services.</p>
                    </div>
                    <div className="w-100 mt_20">
                        <CustomTextField
                          label={"Reward Name"}
                          type="text"
                          placeholder="Reward Name"
                          value={formik.values.referral.name}
                          // value={state.rewardRedemption.name != "" ? state.rewardRedemption.name : ''}
                          onChange = {(e) => formik.setValues({ ...formik.values, referral: { ...formik.values.referral, name: e.target.value}})}
                          height='40px'
                          background='#F2F6FF'
                        />
                      </div>
                    <div className="w-100 mt_20">
                      <CustomTextField
                        label={"Referrer will earn"}
                        type="number"
                        placeholder="Reward"
                        value={formik.values.referral.PointCostBy}
                        onChange={(e) => e.target.value >= 0 ? formik.setValues({ ...formik.values, referral: { ...formik.values.referral, PointCostBy: e.target.value } }) : ''}
                        height='40px'
                        background='#F2F6FF'
                      />
                    </div>
                    <div className="w-100 mt_20">
                      <CustomTextField
                        label={"Referee will earn"}
                        type="number"
                        placeholder="Reward"
                        value={formik.values.referral.PointCostTo || formik.values.referral?.rewardValue?.PointCostTo }
                        onChange={(e) => e.target.value >= 0 ? formik.setValues({ ...formik.values, referral: { ...formik.values.referral, PointCostTo: e.target.value } }) : ''}
                        height='40px'
                        background='#F2F6FF'
                      />
                    </div>
                    <div className="categoryBox mt_20 w-100">
                      <CustomSelect
                        label={"Activity Type"}
                        height='40px'
                        backgroundColor='#F2F6FF'
                        value={formik.values.referral.activityType}
                        options={[
                          {
                            name : 'Sign Up',
                            value : 'signup'
                          },
                          {
                            name : 'Activity',
                            value : 'activity'
                          }
                        ]
                        }
                        onChange={(e) => formik.setValues({ ...formik.values, referral: { ...formik.values.referral, activityType: e.target.value } })}
                        error={formik.touched.activityType && formik.errors.activityType}
                      />
                    </div>
                    <div className="description mt_20 w-100" >
                      <CustomTextArea
                        label={"Description (optional)"}
                        placeholder = "Enter the short description about the product"
                        className   = "w-100"
                        value       = {formik.values.referral.description}
                        onChange    = {(e) => formik.setValues({ ...formik.values, referral: { ...formik.values.referral, description: e.target.value } })}
                      />
                    </div>
                    <div className='w-100 mb_20 mt_20  d-flex align-items-center flex-column'>
                      <CustomButton
                      varient   = 'secondary'
                      className = "w-99 hpx-52 "
                      btntext   = "Add referral"
                      onClick   = {() => handleCreateFunc('referral')}
                      disabled  = {!formik.values.referral.PointCostBy  || !formik.values.referral.PointCostTo || !formik.values.referral.activityType}
                      // outline="secondary"
                      />
                    </div>


                  </div>
                }
              </div>


              <div className='mt_20  borderSpecial>'>
                <div className='w-100  cursor-pointer hpx-74 d-flex justify-content-space-between align-items-center ' onClick={() => {
                  if (chkAnoSec === 3) setchkAnoSec(0)
                  else setchkAnoSec(3)
                }}  >
                  <div className='w-15'>
                    <img className='wpx-48' src={PngIcons.BirthdayLogo} alt='Logo' />
                  </div>
                  <div className='w-85'>
                    <h5 className='Heading16M'>Birthday</h5>
                  </div>
                </div>
              </div>
              {formik.values.birthday?.brandId &&
                <div className='w-100 d-flex bg-color-primary0 p_10 mb_8 align-items-center'>
                  <div className='w-45 Heading15M pl_10'>Birthday Reward</div>
                  <div className='w-45  d-flex justify-content-center  Body14M'>{formik.values.birthday?.rewardValue?.costingPoint} pts</div>
                  <div onClick={(e) => { setDeleteModal(true); setselectedReward({ id: formik.values.birthday._id, key: 'birthday' }) }} className='w-10 cursor-pointer'>
                    <SvgIcons.CrossIcon />
                  </div>
                </div>
              }

              {/* /condition */}
              {
                !formik.values.birthday?.brandId  &&
                <div>
                  <div className='w-100 mb_8 d-flex justify-content-space-between align-items-center'>
                    <h1 className='Heading20M'>Add Birthday</h1>
                    {/* <SvgIcons.CrossIcon /> */}
                  </div>
                  <div className='w-100'>
                    <h1 className='Heading16M'>Reward Value</h1>
                    <p className='Body12R color-neutral50'>Customers will receive points on their birthday.</p>
                  </div>
                  <div className="w-100 mt_20">
                    <CustomTextField
                      label={"Point Cost"}
                      type="number"
                      placeholder="Reward"
                      onChange={(e) => e.target.value >= 0 ? formik.setValues({ ...formik.values, birthday: { ...formik.values.birthday, costingPoint: e.target.value } }) : ''}
                      value={formik.values.birthday.costingPoint || formik.values?.birthday?.rewardValue?.costingPoint}
                      height='40px'
                      background='#F2F6FF'
                    />
                  </div>
                  <div className="description mt_20 w-100" >
                    <CustomTextArea
                      label={"Description (optional)"}
                      name='description'
                      placeholder="Enter the short description about the product"
                      className="w-100"
                      value={formik.values.birthday.description}
                      onChange={(e) => formik.setValues({ ...formik.values, birthday: { ...formik.values.birthday, description: e.target.value } })}
                    />
                  </div>
                  <div className='w-100 mb_20 mt_30   d-flex align-items-center flex-column'>
                    <CustomButton
                    varient   = 'secondary'
                    className = "w-99 hpx-52"
                    btntext   = "Add Birthday"
                    onClick   = {() => handleCreateFunc('birthday')}
                    disabled  = {formik.values.birthday.costingPoint == ''}
                    // icon={
                    // state.loader && <CircularProgress size={20} color={"inherit"} />
                    // }
                    />
                  </div>

                </div>

              }
              <div>
                <CustomsModal
                  open={deleteModal}
                  minWidth={'600px'}
                  component={<DeleteModal icon={loading} title="Deal" onCancel={() => setDeleteModal(false)} onDelete={() => handleDelete()} />}
                  onClose={() => setDeleteModal(false)}
                />

              </div>
            </div>
          </>
      }



    </div>
    </Fade>
  )
}

export default WaystoRedeems