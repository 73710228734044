import React, {useState, useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import CustomButton from '../../../components/CustomButton';

import {enums, GrowthTools} from '../../../enums';

import {ColorSchemeCode} from '../../../enums/ColorScheme';

import PngIcons from '../../../icons/png.icon';
import SvgIcons from '../../../icons/svg.icon';
import { useFormik } from 'formik';
import { GrowthtoolVld } from '../../../validation';
import FitContentInput from '../../../components/FitContentInput';
import { BrandContext } from '../../../context/Brand.context';
import { CampaignService, FlowService, GrowthtoolService, TransactionService } from '../../../services';
import { LayoutContext } from '../../../context/Layout.context';
import { RedemptionType } from '../../../enums/enums';
import { AgencyContext } from '../../../context/Agency.context';
import Loader from '../../../components/Loader';

export default function CreateGrowthTool(props) {

        const brand   = useContext(BrandContext)
        const history = useHistory()

        const [loader, setLoader] = useState(false);

        const layout = useContext(LayoutContext)

        useEffect(()=>{
                layout.setLayout({
                    header          : true,
                    title           : 'Create Channel',
                    borderBottom    : true,
                    backTitles      : ['Channels'],
                    button          : "",
                    expandedBar     : false,
                    showCollapseIcon: false,
                })
        },[])

        const [ state, setState ] = useState({
            apiError : '',
            loader    : false,
        })
        
        const initState = {
            growthtoolName : 'New Channel',
            growthtoolType : ''
        }
        const [campaigns, setCampaigns] = useState([])
        const [selectedCampaign, setSelectedCampaign] = useState({})

        const handleCampaigns = (campaigns) => {
            setCampaigns([...campaigns])
            const campaign = campaigns.find(campaign => campaign._id === formik.values.campaignId)
            setSelectedCampaign(campaign || {})
        }
    
        const CallServices = async () => {
            const query = { brandId: brand._id, campaignType: 5 }
            const { error, response } = await CampaignService.GetQueriedCampaign({ query })
            response && handleCampaigns(response.data)
        }
    
        useEffect(() => {
            CallServices();
        }, [])

        const handleSubmit = async() => {
    console.log('====================================');
            setState({...state, loader: true})

            const payload = {
                brandId: brand._id,
                campaignId: campaigns[0]._id,
                ...formik.values
            }

            console.log('CreateGrowthTool',payload);

            if(payload.ref){
                console.log('asdasdas');
                delete payload.ref
                payload.referral = true
                payload.referralUrl = true
                payload.growthtoolName = 'Referral Page'
            }
    console.log('CreateGrowthTool',payload);
    console.log('====================================');
    
            const {error, response} = await GrowthtoolService.CreateGrowthTool({toaster: true, payload})
            setState({...state, loader: false})
            if(error) return setState({...state, apiError: error.message})

            const path = props.match.url.split('/')
            path.pop()

            // const WidgetCondition      = formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET
            const LandingPagecondition = formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.LANDING_PAGE
            const Poscondition = formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.POS

            // console.log('path ', path);

            console.log('response ', response)

            const url =  LandingPagecondition || Poscondition ? path.join('/') + '/' + response.data._id : path.join('/') + '/' + response.data._id  


            console.log('formik.values ', formik.values);
            // console.log('url ---> ', url);


            if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.MANYCHAT){
                let manyChatUrl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.MANYCHAT +'/' + response.data._id 
                history.push({
                    pathname : manyChatUrl,
                    state    : response.data
                })
            }

            else if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.CHATRACE){
                let chatraceUrl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.CHATRACE +'/' + response.data._id 
                history.push({
                    pathname : chatraceUrl,
                    state    : response.data
                })
            }


            else if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.API){
                let apiUrl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.API +'/' + response.data._id 
                history.push({
                    pathname : apiUrl,
                    state    : response.data
                })
            }

            else if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.APP){
                // console.log('sdsdsd');
                let apiUrl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.APP +'/' + response.data._id 
                history.push({
                    pathname : apiUrl,
                    state    : response.data
                })
            }
            
            else if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.GHL){
                let apiUrl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.GHL +'/' + response.data._id 
                history.push({
                    pathname : apiUrl,
                    state    : response.data
                })
            }


            else if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.UCHAT){
                let uChatUrl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.UCHAT +'/' + response.data._id 
                history.push({
                    pathname : uChatUrl,
                    state    : response.data
                })
            }

            else if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET){
                let widgetUrl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.WIDGET +'/' + response.data._id 
                history.push({
                    pathname : widgetUrl,
                    state    : response.data
                })
            }

            else if(formik.values.growthtoolType === GrowthTools.GrowhttoolTypes.POPUP){
                let popurl =  path.join('/') + '/' + GrowthTools.GrowhttoolTypes.POPUP +'/' + response.data._id 
                history.push({
                    pathname : popurl,
                    state    : response.data
                })
            }

            else{
                console.log('url',url);
                console.log('response.data',response.data);
                history.push({
                    pathname : url,
                    state    : {...response.data, ref: true}
                })
            }
        }
        // const [disabled, setDisabled] = useState(false)
        const [cwDisabled, setCwDisabled] = useState(false)
        const [appDisabled, setAppDisabled] = useState(false)
        const [ghlDisabled, setGhlDisabled] = useState(false)
        const [manychatDisabled, setManychatDisabled] = useState(false)
        const [uchatDisabled, setUchatDisabled] = useState(false)
        const [reffDisabled, setreffDisabled] = useState(false)
        const [chatraceDisabled, setchatraceDisabled] = useState(false)
        const [lightspeedDisabled, setLightSpeedDisabled] = useState(false)
        const [widgetDisabled, setWidgetDisabled] = useState(false)
        const [popupDisabled, setPopupDisabled] = useState(false)

        useEffect(async() => {
            setLoader(true)
            const campaignData = await CampaignService.GetQueriedCampaign({query:{brandId: brand._id, campaignType: 5}})
            const query = { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.MANYCHAT, campaignId: campaignData.response.data[0]._id }
            const {error, response} = await GrowthtoolService.GetPublicGrowthTool({query})
            if(response && response.data && response.data.length > 0) setManychatDisabled(true)
            
            // const uchat = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.UCHAT, campaignId: campaignData.response.data[0]._id }})
            // if(uchat.response && uchat.response.data && uchat.response.data.length > 0) setUchatDisabled(true)
        
            // const lightspeed = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.POS, campaignId: campaignData.response.data[0]._id }})
            // if(lightspeed.response && lightspeed.response.data && lightspeed.response.data.length > 0) setLightSpeedDisabled(true)
        
            
            // const chatrace = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.CHATRACE, campaignId: campaignData.response.data[0]._id }})
            // if(chatrace.response && chatrace.response.data && chatrace.response.data.length > 0) setchatraceDisabled(true)

            const reff = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.LANDING_PAGE, campaignId: campaignData.response.data[0]._id }})
            if(reff.response && reff.response.data && reff.response.data.length > 0)
            for (const iterator of reff.response.data) {
                if(iterator.referralUrl) setreffDisabled(true)
            }

            const cw = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.API, campaignId: campaignData.response.data[0]._id }})
            if(cw.response && cw.response.data && cw.response.data.length > 0) setCwDisabled(true)
            
            // const app = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.APP, campaignId: campaignData.response.data[0]._id }})
            // if(app.response && app.response.data && app.response.data.length > 0) setAppDisabled(true)

            const widget = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.WIDGET, campaignId: campaignData.response.data[0]._id }})
            if(widget.response && widget.response.data && widget.response.data.length > 0) setWidgetDisabled(true)
            
            const ghl = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.GHL, campaignId: campaignData.response.data[0]._id }})
            if(ghl.response && ghl.response.data && ghl.response.data.length > 0) setGhlDisabled(true)


            // const popup = await GrowthtoolService.GetPublicGrowthTool({query: { brandId: brand._id, growthtoolType: GrowthTools.GrowhttoolTypes.POPUP, campaignId: campaignData.response.data[0]._id }})
            // if(popup.response && popup.response.data && popup.response.data.length > 0) setPopupDisabled(true)
            // if(brand.brandRedemptionType === RedemptionType.ORDER) setDisabled(true)

            // const getFlows = await FlowService.Get({query : {brandId : brand._id, delete: false}});
            const getFlows = await FlowService.Get({query: { brandId: brand._id, flowType:'new' ,delete: false }})
            // // console.log('getFlows', getFlows);

            let cloudwaitressArray = [];
            let appArray           = [];
            let manychatArray      = [];
            let uchatArray         = [];
            let lightspeedArray    = [];
            let ghlArray           = [];
            let widgetArray        = [];
            let chatraceArray      = [];
            let popupArray      = [];


            if(getFlows.response){
                for (const item of getFlows.response.data) {
                    if(item.triggerType === 'signUp'){
                    for (const element of item.flow) {
                            if(element.id === "1" || element.id === 1){
                                if(element?.data?.value?.platform === "cloudwaitress") cloudwaitressArray.push(element);
                                // if(element?.data?.value?.platform === "app") appArray.push(element);
                                // if(element?.data?.value?.platform === "pos") lightspeedArray.push(element);
                                if(element?.data?.value?.platform === "Manychat") manychatArray.push(element);
                                // if(element?.data?.value?.platform === "uChat") uchatArray.push(element);
                                if(element?.data?.value?.platform === "ghl") ghlArray.push(element);
                                // if(element?.data?.value?.platform === "chatrace") chatraceArray.push(element);
                                if(element?.data?.value?.platform === "widget") widgetArray.push(element);
                                // if(element?.data?.value?.platform === "popup") popupArray.push(element);
                            }
                    }
                    }
                }

                // if(cloudwaitressArray.length > 0 && cw.response && cw.response.data && cw.response.data.length > 0 ) setCwDisabled(true)
                if(ghlArray.length > 0 && ghl.response && ghl.response.data && ghl.response.data.length > 0) setGhlDisabled(true)
                // if(appArray.length > 0 && app.response && app.response.data && app.response.data.length > 0) setAppDisabled(true)
                if(manychatArray.length > 0 && response && response.data && response.data.length > 0) setManychatDisabled(true)
                // if(uchatArray.length > 0 && uchat.response && uchat.response.data && uchat.response.data.length > 0)setUchatDisabled(true)
                // if(lightspeedArray.length > 0 && lightspeed.response && lightspeed.response.data && lightspeed.response.data.length > 0) setLightSpeedDisabled(true)
                // if(chatraceArray.length > 0 && chatrace.response && chatrace.response.data && chatrace.response.data.length > 0) setchatraceDisabled(true)
                if(widgetArray.length > 0 && widget.response && widget.response.data && widget.response.data.length > 0) setWidgetDisabled(true)
                // if(popupArray.length > 0 && popup.response && popup.response.data && popup.response.data.length > 0) setPopupDisabled(true)

            }

            setLoader(false)
        }, [])
        
        const formik = useFormik({
            initialValues    : { ...initState },
            onSubmit         : handleSubmit,
            validationSchema : GrowthtoolVld.Create,
            isInitialValid   : false
        })


        return (
            <div id="createTool">
                {loader ? <Loader/> : 
                        <form onSubmit={formik.handleSubmit}>
                            <InitializeGrowthtool popupDisabled={popupDisabled}reffDisabled={reffDisabled} appDisabled={appDisabled} state={state} formik={formik} cwDisabled={cwDisabled} ghlDisabled={ghlDisabled} manychatDisabled={manychatDisabled} uchatDisabled={uchatDisabled} chatraceDisabled={chatraceDisabled} widgetDisabled={widgetDisabled} {...props}/>
                        </form>
                    }
            </div>
        )
    }

function InitializeGrowthtool({formik, popupDisabled, state, widgetDisabled,  cwDisabled,reffDisabled, appDisabled, manychatDisabled, uchatDisabled, chatraceDisabled, ghlDisabled, ...props}){
    // // console.log('appDisabled', appDisabled);
    const brand   = useContext(BrandContext)
    const history = useHistory();

    const agency   = useContext(AgencyContext)
    // // console.log('agency', agency);

    const [selected , setSelected] = useState('All');
    const [selectedBtn , setSelectedBtn] = useState();

    const GrowhttoolTitles = [
        'Widget', 'API',  'Chatbots', 'Business'
    ]

    console.log('selectedBtn ', selectedBtn)

    const GrowthToolsTypes = [
        {
            title : 'Widget',
            components : 
                {
                    name     : 'Opt-in Page', //Landing Page
                    icon     : PngIcons.largetIcons.LandingpageIcon,
                    detail   : 'A landing page channel hosted by walletly. Perfect for situations when you don’t have your own website.',
                    type     : GrowthTools.GrowhttoolTypes.LANDING_PAGE,
                    disabled : false
                }
    
        },
        {
            title : 'Widget',
            components : 
                {
                    name     : 'Referral Page',
                    icon     : PngIcons.largetIcons.LandingpageIcon,
                    detail   : 'A landing page channel hosted by walletly. Perfect for situations when you don’t have your own website.',
                    type     : GrowthTools.GrowhttoolTypes.LANDING_PAGE,
                    disabled : reffDisabled ? true : false
                }
    
        },
        {
            title : 'Widget',
            components : 
                {
                    name       : 'Widget',
                    icon       : PngIcons.largetIcons.WidgetIcon,
                    detail     : 'Widget channel enables you to connect your flows with your lightspeed pos.',
                    type       : GrowthTools.GrowhttoolTypes.WIDGET,
                    disableText: false,
                    disabled   : false,
                }
        },
        // {
        //     title : 'Widget',
        //     components : 
        //         {
        //             name     : 'Lightspeed',
        //             icon     : PngIcons.LightSpeed,
        //             detail   : 'Lightspeed channel enables you to connect your flows with your lightspeed pos.',
        //             type     : GrowthTools.GrowhttoolTypes.POS,
        //             disabled : false
        //         }
    
        // },
        // {
        //     title : 'Widget',
        //     components :
        //         {
        //             name     : 'Signup Popup',
        //             icon     : PngIcons.PopUp,
        //             detail   : 'A popup is a window that automatically appears (“pops up”) on a website while the user is browsing. ',
        //             type     : GrowthTools.GrowhttoolTypes.POPUP,
        //             disabled : popupDisabled ? true : false
        //         }
        // }, 
        {
            title : 'API',
            components : 
                {
                    // name     : 'Cloudwaitress',
                    name     : 'Online Ordering',
                    icon     : PngIcons.CloudWaitress,
                    // detail   : 'Cloudwaitress channel enables you to connect your online customers with your reward flows.',
                    detail   : 'Online Ordering channel enables you to connect your online customers with your reward flows.',
                    type     : GrowthTools.GrowhttoolTypes.API,
                    disabled : brand?.integrations?.cloudwaitress?.length === 0 ? true : cwDisabled ? true : false
                }
        },
        {
            title : 'Chatbots',
            components : 
                {
                    name     : 'Many Chat',
                    icon     : PngIcons.ManyChat,
                    detail   : 'Manychat channel enables you to connect with customers coming from manychat flow.',
                    type     : GrowthTools.GrowhttoolTypes.MANYCHAT,
                    disabled : !brand?.integrations?.manychat ? true : manychatDisabled ? true : false
                }
    
        },
        // {
        //     title : 'Chatbots',
        //     components : 
        //         {   
        //             name     : 'Chatrace',
        //             icon     : PngIcons.Chatrace,
        //             detail   : 'Chatrace channel enables you to connect with customers coming from chatrace flow.',
        //             type     : GrowthTools.GrowhttoolTypes.CHATRACE,
        //             disabled : chatraceDisabled ? true : false
        //         }
    
        // },
        // {
        //     title : 'Chatbots',
        //     components : 
        //         {
        //             name     : 'UChat',
        //             icon     : PngIcons.UchatIcon,
        //             detail   : 'Uchat channel enables you to connect with customers coming from Uchat flow.',
        //             type     : GrowthTools.GrowhttoolTypes.UCHAT,
        //             disabled : !brand?.integrations?.uchat ? true : uchatDisabled ? true : false
        //         }
        // },
        // {
        //     title : 'Business',
        //     components :
        //         {
        //             name     : 'App',
        //             icon     : PngIcons.largetIcons.AppIcon,
        //             detail   : 'The app channel enables you to connect your flows with your mobile application provided by walletly.',
        //             type     : GrowthTools.GrowhttoolTypes.APP,
        //             disabled : appDisabled ? true : false
        //         }
        // },
        {
            title : 'Business',
            components :
                {
                    name     : 'GHL',
                    icon     : PngIcons.GhlIcon,
                    detail   : 'Find and embed your files with GHL and get connected with GHL.',
                    type     : GrowthTools.GrowhttoolTypes.GHL,
                    disabled : !brand?.integrations?.ghl ? true : ghlDisabled ? true : false
                }
        },

    ]

    const handleSelection = (element) => {
        setSelected(element)
        console.log("check", selected)
    }

    return (
        <div className="mb_75">
            <div className="middleComponent mt_44 col-12">
                <div className='row mt_32'>
                    <div className='col-2 d-flex flex-column'>
                        <div className='Caption12M color-neutral60 pl_12'>Channel Types</div>

                        <div className='mt_10'>
                            {
                                GrowhttoolTitles.map((element, idx)=>(
                                    (idx === 0) ? 
                                        <>
                                            <div onClick={() =>{handleSelection('All')}} className={selected === 'All' ? 'w-100 cp pt_8 pl_12 pb_8 borderRadius-4 Heading14M color-ButtonPrimaryBG' : 'w-100 cp pt_8 pl_12 pb_8 borderRadius-4 Body14R color-neutral60'} style={{background: (selected==='All') ? 'rgba(55, 112, 251, 0.1)' : ''}}>
                                                All
                                            </div>
                                            <div onClick={() =>{handleSelection(element)}} className={selected === element ? 'w-100 cp pt_8 pl_12 pb_8 borderRadius-4 Heading14M color-ButtonPrimaryBG' : 'w-100 cp pt_8 pl_12 pb_8 borderRadius-4 Body14R color-neutral60'} style={{background: (selected=== element ) ? 'rgba(55, 112, 251, 0.1)' : ''}}>
                                                {element}
                                            </div>
                                        </>
                                    :
                                        <div onClick={() =>{handleSelection(element)}} className={selected === element  ? 'w-100 cp pt_8 pl_12 pb_8 borderRadius-4 Heading14M color-ButtonPrimaryBG' : 'w-100 cp pt_8 pl_12 pb_8 borderRadius-4 Body14R color-neutral60'} style={{background: (selected=== element ) ? 'rgba(55, 112, 251, 0.1)' : ''}}>
                                            {element}
                                        </div>
                                
                                ))
                            }
                        </div>
                    </div>
                
                    <div className='col-10'>
                        <div className='Body16R color-neutral60 mt_16'>Select your channels</div>
                        <div className="row ml_0 mt_8">
                            {GrowthToolsTypes.map((element, idx)=>
                                (element?.title === selected || selected === 'All') ? 
                                        <div  key={idx} className={`col-4 d-flex flex-column ${element.components.disabled && 'disabled'}`} onClick={()=>{
                                            if(element?.components?.name==="Referral Page") {
                                                console.log('asdasd');
                                                // formik.setValues({...formik.values, ref: true})
                                                formik.setValues({...formik.values, ref: true, growthtoolType: element?.components?.type})
                                            }
                                            else formik.setValues({...formik.values, growthtoolType: element?.components?.type})
                                        }}>
                                                    <div className='mt_24 pr_16'>
                                                        <div className="">
                                                            <div className="mr_8">
                                                                <img className="object-fit-contain" src={element?.components.icon} alt="" width="32" height="32"/>
                                                            </div>
                                                            <div className="Heading16M color-neutral100 mt_10 d-flex align-items-center">{element?.components?.name}</div>
                                                        </div>
                                                        <div className="toolDetail Body14R color-neutral60 mt_4">{element?.components?.detail}</div>
                                                    
                                                        <CustomButton 
                                                            varient   = "tertiary"
                                                            type      = 'submit'
                                                            onClick   = {()=>{
                                                                console.log('element',element);
                                                                setSelectedBtn(idx)
                                                            }}
                                                            // onClick   = {handleSubmit}
                                                            className = 'btnConnect mt_10 pt_8 pb_8 pr_16 cp'
                                                            btntext   = {(state.loader && selectedBtn === idx)  ? <CircularProgress size={20} color={'inherit'}/> : 
                                                            // element?.components.disableText ? 'Connect Twilio First' :
                                                             'Connect'}
                                                        /> 
                                                    </div>
                                        </div>
                                : ''
                            )}
                        </div>
                    </div>

                </div>
            
            </div>
        </div>
    )
}
