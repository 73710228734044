import * as yup from 'yup';
import { Campaign, enums } from '../enums'

const REQ_ERROR = 'This field is required'

const sendGiftCard = yup.object().shape({
    giftCard     : yup.string().required(REQ_ERROR),
    customerName : yup.string().required(REQ_ERROR),
    customerEmail: yup.mixed().required(REQ_ERROR),
})

export {
    sendGiftCard
}