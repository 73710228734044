import React, { useState, useEffect, useContext } from 'react';
import PngIcons from '../../../../icons/png.icon';


import CustomAccordion from '../../../../components/CustomAccordion';
import CustomTextField from '../../../../components/CustomTextField';
import CustomSelect from '../../../../components/CustomSelect';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import CustomRadio from '../../../../components/CustomRadio';
import TierCard from '../../../../components/CustomTierCard';

import RadioGroup from '@mui/material/RadioGroup';
import {ChromePicker} from 'react-color';

import ClickOutside from '../../../../utils/ClickOutside'


import {Options, utils} from '../../../../utils';
import { ColorSchemeCode } from '../../../../enums/ColorScheme';
import { AgencyContext } from '../../../../context/Agency.context';

export default function Pointsystemsetting({changeBreadCrumbTitle}) {

    const [accordians, setAccordians] = useState([]);
    const [openMembership, setOpenMembership] = useState(false);
    const [membershipName, setMembershipName] = useState([]);

    const handleChangeShow = ({panel, idx}) => (event, isExpanded) => {
        accordians[idx].show = isExpanded ? panel : false
        setAccordians([...accordians]);
    };

    const Pointexpsidefield = [
        {
            value: 'Increment of points',
        },
        {
            value: '1 points',
        },
        {
            value: 'Immidately',
        }
    ]

    const Membershiptiersidefield = [
        {
            value: 'Increment of points',
        },
        {
            value: '1 points',
        },
        {
            value: 'Immidately',
        }
    ]


    const Allaccordians = [
        {
            title        : "Point Expiration Settings",
            expanded     : false,
            details      : <PointExpirationDetail/>,
            show         : false,
            sideField    : Pointexpsidefield,
        },
        {
            title        : "Membership Tiers",
            expanded     : false,
            details      : <MemeberShipTierDetail setMembershipName={setMembershipName} setOpenMembership={setOpenMembership}/>,
            show         : false,
            sideField    : Membershiptiersidefield,
        }
    ]


    const Membershipaccordians = [
        {
            title        : "General",
            expanded     : false,
            details      : <GeneralDetail/>,
            show         : false,
            sideField    : Pointexpsidefield,
        },
        {
            title        : "Accelrated Point Earning",
            expanded     : false,
            details      : <AcceleratedPointDetail/>,
            show         : false,
            sideField    : Membershiptiersidefield,
        }
    ]

    useEffect(()=>{
       !openMembership ? setAccordians(Allaccordians)  : setAccordians(Membershipaccordians);
    },[openMembership])


    return (
        <div id="Pointsystemsetting" className="col-12">
            <div className="headline3 mt_24 col-12">
                    Point System Settings
            </div>
            <div className="d-flex">
                    <div className={(openMembership ? 'col-8' : 'col-12') + " mt_24 pr_0 pl_0"}>
                        {
                            accordians.map((element, idx)=>
                                <CustomAccordion 
                                    title        = {element.title}
                                    details      = {element.details}
                                    expanded     = {element.show}
                                    handleChange = {(panel) => handleChangeShow({panel, idx})}
                                    Sidefields   = {element.sideField}
                                />
                            )
                        }
                    </div>
                    
                {openMembership && 
                    <div className="col-6">
                        <div className="mt_40">
                            <TierCard details={membershipName}  text={"Discount on all purchases"}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}


const PointExpirationDetail = () => {

    const AccountActivities = [
        {
            name   : 'Place your order',
            value   : false
        },
        {
            name   : 'Celebrate a birthday',
            value   : false
        },
        {
            name   : 'Sign Up',
            value   : false
        },
    ]

    const ReminderEmail = [
        {
            name  : '30 days before expiry',
            value : false
        },
        {
            name  : '15 days before expiry',
            value : false
        },
        {
            name  : '7 days before expiry',
            value : false
        },
        {
            name  : '2 days before expiry',
            value : false
        },

    ]




    return ( 
        <div className='col-5'>
            <div className=''>
                <CustomSelect 
                    className  = "w-100"
                    options    = {Options.getPointExpiry()}
                    name       = "pointExpiry"
                    label      = 'Point Expiry'
                    autoFocus  = {true}
                />
            </div>

            <div className='mt_24'>
                <CustomTextField 
                    label          = "Expire Points After"
                    className      = "inputTextField w-100"
                    type           = "number"
                    placeholder    = "Months"
                    name           = "pointExpiryMonth"
                    autoFocus      = {false}
                />
            </div>

            <div className="mt_24">
                <div className="Body14R fw-6 color-neutral80 fs-14">
                        Activities which would keep the account active
                </div>
                    <div className="accountActivities mt_10">
                        { AccountActivities.map((value)=>
                            <div className="col-12 pl_0">
                                <CustomCheckBox 
                                    label={value.name} 
                                // onChange={()=>handleActivitiesChange(value)} 
                                />           
                            </div>
                            )
                        }
                    </div>
                </div>

                <div className="mt_24">
                    <div className="Body14R fw-6 color-neutral80 fs-14">
                        Send Reminder Email
                    </div>
                        
                    <div className="reminderEmail mt_10">
                        { ReminderEmail.map((value)=>
                            <div className="col-12 pl_0">
                                <CustomCheckBox 
                                    label={value.name} 
                                    // onChange={()=>handleReminderEmailChange(value)} 
                                />           
                            </div>
                            )
                        }
                    </div>                 
                </div>
        </div>
     );
}


const MemeberShipTierDetail = ({setOpenMembership, setMembershipName}) => {

    const Bronzemembership           =   [
    {
        name    : 'Bronze Membership',
        icon    :  PngIcons.bronzeMedalIcon,
        content :  [
                    {
                        pointname : 'Minimum Points:',
                        points    : '100'
                    },
                    {
                        pointname : 'Total Gained Points:',
                        points    : '450'
                    },
                    {
                        pointname : 'Points Balanced:',
                        points    : '320'
                    },
                    {
                        pointname : 'Duration:',
                        points    : '12 Month'
                    },
                ] 
    }
                    ]
const Silvermembership    =   [
    {
        name    : 'Silver Membership',
        icon    :  PngIcons.silverMedalIcon,
        content :  [
                    {
                        pointname : 'Minimum Points:',
                        points    : '100'
                    },
                    {
                        pointname : 'Total Gained Points:',
                        points    : '450'
                    },
                    {
                        pointname : 'Points Balanced:',
                        points    : '320'
                    },
                    {
                        pointname : 'Duration:',
                        points    : '12 Month'
                    },
                ] 
    }
                    ]

const Goldmembership    =   [
    {
        name    : 'Gold Membership',
        icon    :  PngIcons.goldMedalIcon,
        content :  [
                    {
                        pointname : 'Minimum Points:',
                        points    : '100'
                    },
                    {
                        pointname : 'Total Gained Points:',
                        points    : '450'
                    },
                    {
                        pointname : 'Points Balanced:',
                        points    : '320'
                    },
                    {
                        pointname : 'Duration:',
                        points    : '12 Month'
                    },
                ] 
                        }
                    ]


    return (
        <div className="d-flex col-12 pl_0 pr_0">
             <div className="mr_32">
                <TierCard details={Bronzemembership} setOpenMembership={setOpenMembership} setMembershipName={setMembershipName}/>
             </div>
             <div className="mr_32">
                <TierCard details={Silvermembership} setOpenMembership={setOpenMembership} setMembershipName={setMembershipName}/>
             </div>
             <div className="mr_32">
                <TierCard details={Goldmembership} setOpenMembership={setOpenMembership} setMembershipName={setMembershipName}/>
             </div>
        </div>
    )
}


const GeneralDetail = ({formik, expanded}) => {

    const agency = useContext(AgencyContext)

    const [colorPallete, setColorPallete] = useState(false);
    const [textColorPallete, setTextColorPallete] = useState(false);
    const [backgroundColor, setBackgroundColor]=useState(agency.agencyColor);
    const [textColor, setTextColor]=useState(ColorSchemeCode.textfieldColor);

    return ( 
    
           <div className="col-7">
               <div className="">
                    <CustomTextField 
                        label          = "Membership Level"
                        className      = "inputTextField w-100"
                        type           = "text"
                        placeholder    = "Membership Name"
                        name           = "expirepoints"
                        autoFocus      = {false}
                    />
               </div>
               <div className="mt_24">
                    <CustomTextField 
                        label          = "Minimum Points"
                        className      = "inputTextField w-100"
                        type           = "text"
                        placeholder    = "Points"
                        name           = "minimumPoints"
                        autoFocus      = {false}
                    />
               </div>
               <div className="mt_24">
                    <CustomTextField 
                        label          = "Notes"
                        className      = "inputTextField w-100"
                        type           = "text"
                        name           = "expire points"
                        autoFocus      = {false}
                    />
               </div>
               <div className="mt_24">
                    <div className="customLabel">
                        Background Color
                    </div>
                    <ClickOutside onClick={()=>setColorPallete(false)}>
                    <div className="mt_10 col-12 borderRadius-4 colorBox" style={{backgroundColor: backgroundColor}} onClick={()=>setColorPallete(!colorPallete)}>
                    </div>
                    {colorPallete &&
                        <ChromePicker 
                            className = "pallette position-absolute z-index-1"
                            color     = {backgroundColor}
                            onChange  = {changedColor => setBackgroundColor(changedColor.hex)}
                        />
                    }   
                    </ClickOutside> 
               </div>
               <div className="mt_24">
                    <div className="customLabel">
                        Text Color
                    </div>
                    <ClickOutside onClick={()=>setTextColorPallete(false)}>
                        <div className="mt_10 col-12 borderRadius-4 colorBox" style={{backgroundColor: textColor}} onClick={()=>setTextColorPallete(!textColorPallete)}>
                        </div>
                        {textColorPallete &&
                            <ChromePicker 
                                className = "pallette position-absolute z-index-1"
                                color     = {textColor}
                                onChange  = {changedColor => setTextColor(changedColor.hex)}
                            />
                        }   
                    </ClickOutside> 
               </div>
               
           </div>    
     );
}

const AcceleratedPointDetail = () => {
    
    return ( 
    <div className="col-10">
        <div className="">
                <RadioGroup 
                    name     = "acceleratedPointEarning.name"
                  
                >
                <div className="d-flex">
                    <div className="pt_20 col-4 pl_0 pr_0">
                        <CustomRadio 
                            value = "Flat Amount"
                            label = "Flat Amount"
                        />
                    </div>
                    <div className="col-8 pl_0">
                        <CustomTextField 
                            className      = "inputTextField w-100"
                            type           = "number"
                            name           = "acceleratedPointEarning.points"
                            placeholder    = "0"
                            // error          = {formik.touched.acceleratedPointEarning.points && formik.errors.acceleratedPointEarning.points}
                            // helperText     = {formik.touched.acceleratedPointEarning.points && formik.errors.acceleratedPointEarning.points ? formik.errors.acceleratedPointEarning.points : ''}
                            autoFocus      = {false}
                        />
                    </div>

                </div>
                <div>
                    <CustomRadio 
                        value={"Accelerate member’s points earning by adding"}
                        label="Accelerate member’s points earning by adding"
                    />
                    <div className="d-flex ml_15 mt_10">
                        <CustomTextField 
                            className      = "inputTextField w-15"
                            type           = "number"
                            maxLength      = {10}
                            name           = "acceleratedPointEarning.points"
                            placeholder    = "0"
                            // error          = {formik.touched.acceleratedPointEarning.points && formik.errors.acceleratedPointEarning.points}
                            // helperText     = {formik.touched.acceleratedPointEarning.points && formik.errors.acceleratedPointEarning.points ? formik.errors.acceleratedPointEarning.points : ''}
                            autoFocus      = {false}
                        />
                        <span className="mt_13 ml_15 fs-14">
                            % more points every time.
                        </span>
                    </div>
                </div>

                </RadioGroup>
            </div>
        </div>
               
     );
}



