import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomAction from '../../../../components/CustomAction'
import CustomTable from '../../../../components/customTable/CustomTableAgency'
import CustomTextField from '../../../../components/CustomTextField'
import Loader from '../../../../components/Loader'
import { AgencyContext } from '../../../../context/Agency.context'
import { SvgIcons } from '../../../../icons'
import VenueService from '../../../../services/Venue'
import PageTitle from '../../../../components/setting/SettingPageTitles';
import { BrandContext } from '../../../../context/Brand.context'
import { BrandService, TierService } from '../../../../services'
import CoalitionService from '../../../../services/Coalition.service'
import { CircularProgress, IconButton } from '@mui/material'
import { Brand } from '../../../../enums'
import EmptyScreen from '../../../../components/EmptyScreen';
import { utils } from '../../../../utils'
import localForage from 'localforage'
import Agency from '../../../../enums/Agency'
import CustomTableV2 from '../../../../components/CustomTableV2'
import { ColorSchemeCode } from '../../../../enums/ColorScheme'
import CustomButton from '../../../../components/CustomButton'
import { LayoutContext } from '../../../../context/Layout.context'
import CustomSearch from '../../../../components/CustomSearch'
import CustomsModal from '../../../../components/CustomModal'
import { ClickOutside } from '../../../../utils';
import DeleteModal from '../../../../components/DeleteModal'
import CustomDateRange from '../../../../components/CustomDateRange';
import CustomSelect from '../../../../components/CustomSelect'
import ViewDetail from "../../../../components/ViewDetail";


function Venue({ selectedCampaign, ...props }) {

    const brand = useContext(BrandContext);
    const agency = useContext(AgencyContext);

    const [state, setState] = useState({
        apiError: '',
        loader: false,
    })

    const [filters, setFilters] = useState({
        address: '',
        phone: '',
        startDate: '',
        endDate: '',
    })

    const [show, setShow] = useState({
        filter: false,
        del :'',
    })

    const [deleteModal, setDeleteModal] = useState(false);
    const [venue2, setVenue2] = useState()
    const [venue, setVenue] = useState([])
    const [result, setResult] = useState([])
    const [deleteIndex, setDeleteIndex] = useState()
    const [loader, setLoader] = useState({
        add: false,
        edit: false,
        main: false,
        deleteLoader: false
    })

    const history = useHistory();
    const editFunc = async (v) => {
        setLoader({ ...loader, main: true })
        const query = { parentBrandId: brand._id, childBrandId: v.id }
        const coalitionData = await CoalitionService.Get({ query })
        let wee = v.value;
        console.log('ROW',v.value);
        if (coalitionData.response) history.push({ pathname: window.location.pathname + '/add',
         state:  {...coalitionData.response.data[0] , ...wee , tempBrandCover: wee.brandCover ? wee.brandCover : '', edit: true}
        })
        setLoader({ ...loader, main: false })
    }

    const handleAdd = async (e) => {
        e.preventDefault()
        setState({ ...state, loader: true })
        const getTier = await TierService.Get({ query: { campaignId: selectedCampaign._id } })
        if (getTier.response) history.push({ pathname: props.match.url + '/add', state: { tiers: getTier.response.data, edit: false } })
        setState({ ...state, loader: false })
    }

    // return <IconButton className="deleteIcon" onClick={(e)=>handleDelete(e,index)} >
    const DeleteFunc = ({ index }) => {
        return <IconButton className="deleteIcon" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDeleteModal(true);
            setDeleteIndex(index)
        }}>
            <SvgIcons.DeleteIcon height="15" width="11.67" color={ColorSchemeCode.Paragraph} />
        </IconButton>
    };

    const columns = [
        {
            field: 'BrandName', headerName: 'Brand Name', flex: 2, sortable: true,
            valueFormatter: ({ value }) => value.props.children[2],
            renderCell: (params) => {
                return (
                    result.map((v, i) => (
                        v.id === params.id && <div>{v.BrandName}</div>
                    ))
                );
            }
        },
        { field: 'BRAND NAME', headerName: 'BRAND NAME', flex: 4 },
        { field: 'ADDRESS', headerName: 'ADDRESS', flex: 4 },
        { field: 'Created', headerName: 'Created', align: 'right', headerAlign: 'right', flex: 2 },
        { field: 'PhoneNumber', headerName: 'Phone Number', flex: 2, align: 'right', headerAlign: 'right' },
        {
            field: "", headerName: "", sortable: false, flex: 1, align: 'right', disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center cp " >
                        {/* <DeleteFunc index={params.row.id} /> */}
                        <IconButton className="deleteIcon" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDeleteModal(true);
                            setDeleteIndex(params.row.id)
                        }}>
                            <SvgIcons.DeleteIcon height="15" width="11.67" color={ColorSchemeCode.Paragraph} />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const pushArr = (arr, body) => {
        arr.forEach((v, index) => {
            body.push({
                id: v._id,
                value: v,
                BrandName: <div><img width="32px" height="32px" className="borderRadius-50" src={v.brandLogo} />  {" " + utils.capitalizeAll(v.brandName)}</div>,
                Address: v.location.address,
                Created: new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth() + 1) + '-' + new Date(v.created).getFullYear(),
                PhoneNumber: '(' + v.dialingCode + ') ' + v.phone,
            })
        });

        return body
    }

    const onLoad = async (refresh) => {
        setLoader({ ...loader, main: true })
        let subBrands = await localForage.getItem('subBrands');
        let body = []
        if (history.location.state?.reload || !subBrands || refresh) {
            const query = { delete: false, parentBrandId: brand._id, brandType: Brand.BrandTypes.SUB_BRAND }
            const { error, response } = await BrandService.Get({ query })
            if (response) { localForage.setItem('subBrands', response.data); subBrands = response.data }
            if (error) {
                setLoader({ ...loader, main: false });
                return
            }

        }

        let arr = []
        if (subBrands && subBrands.length > 0) arr = pushArr(subBrands, body)
        setVenue(arr)
        setVenue2(venue)
        setResult(arr)

        setLoader({ ...loader, main: false })
    }

    useEffect(onLoad, [history.location.pathname])

    const handleSearch = (e) => {
        const result = venue.filter(item =>
            item.value.brandName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setResult(result)
    }

    const handleUserSearch = (e) => {

        let result = venue?.filter(item =>
            item.value.brandName.toLowerCase().includes(e.target.value.toLowerCase())
        )
        setVenue2(result);

    }



    const handleDateChange = (ranges) => {
        let startDate = new Date(ranges.selection.startDate).valueOf();
        let endDate = new Date(ranges.selection.endDate).valueOf();
        setFilters({ ...filters, startDate: startDate, endDate: endDate })
    }

    const formik = { isValid: true }

    // const handleDelete = async(e,index) => {
    const handleDelete = async () => {
        // e.preventDefault()
        // e.stopPropagation();
        setLoader({ ...loader, deleteLoader: true })
        let payload;
        payload = { parentBrandId: brand._id, childBrandId: deleteIndex, delete: true }
        const coalitionDelete = await CoalitionService.Update({ payload })
        if (coalitionDelete.error) return setState({ ...state, apiError: 'unable to delete Brand' })
        payload = { _id: deleteIndex, agencyId: agency._id, delete: true }
        const { response, error } = await BrandService.Update({ toaster: true, payload })
        if (error) return setState({ ...state, apiError: 'unable to delete Brand' })
        let body = []
        let arr = []
        if (response) {
            let subBrands = await localForage.getItem('subBrands');
            subBrands.map((element, idx) => {
                if (element._id === response.data._id) subBrands.splice(idx, 1)
            })
            localForage.setItem('subBrands', subBrands)
            if (subBrands && subBrands.length > 0) arr = pushArr(subBrands, body)
            setVenue(arr)
            setResult(arr)


        }
        setLoader({ ...loader, deleteLoader: false })
        setDeleteModal(false)

    }

    const layout = useContext(LayoutContext)

    useEffect(() => {
        setTimeout(() => {
            layout.setLayout({
                expandedBar: true,
                title: 'Sub-Brand',
                // backTitles   : ['Loyalty Suite'],
                borderBottom: true,
                edit: false,
                switch : '',
                button: <CustomButton
                    btntext={"Add Sub-Brand"}
                    disabled={state.loader}
                    icon={state.loader && <CircularProgress size={20} color={'inherit'} />}
                    onClick={(e) => handleAdd(e)}
                />
            })
        }, 100);
    }, [state.loader])


    const handleFilterSearchFunc = async () => {
        let result = venue;
        if (filters.address != "") {
            result = result.filter(item =>
                item.Address.toLowerCase().includes(filters.address.toLowerCase())
            );
        }
        if (filters.phone != "") {
            result = result.filter(item =>
                item.PhoneNumber.toLowerCase().includes(filters.phone.toLowerCase())
            );
        }
        if ((filters.startDate != "" && filters.endDate != "") && result.length > 0) {
            var startDate = new Date(filters.startDate);
            var endDate = new Date(filters.endDate);
            let venueCustomers = [];
            result.map((venue, idx) => {
                var createDate = new Date(venue.value.created);
                if (((createDate.getDate() >= startDate.getDate()) && (createDate.getMonth() >= startDate.getMonth())) && ((createDate.getDate() <= endDate.getDate())) && (createDate.getMonth() >= endDate.getMonth())) {
                    venueCustomers.push(venue);
                }
            })
            result = venueCustomers;
        }
        setResult(result)
        setShow({ ...show, showFiter: false })

    }
    const UserImagePart = ({ color, firstName, lastName, image, id, email }) => {
        return (

            <div className="d-flex align-items-center">
                {image ? (
                    <img className="userImagePart" src={image} width="30" />
                ) : (
                    utils.getAvatar({
                        firstName: firstName,
                        lastName: lastName,
                        id: id,
                        tableName: "customers",
                        width: "38px",
                        heigth: "38px",
                        bgColor: color,
                        // bgColor   : utils.getRandomColor(firstName)
                    })
                )}
                &nbsp;&nbsp;
                <div>
                    <div className=" color-neutral90 Heading15M capitalize">
                        {lastName ? firstName + " " + lastName : firstName}
                    </div>
                    <div className="color-neutral70 Link13R">{email}</div>
                </div>
            </div>
        );
    }
    
    const openDeleteMenu=(value)=>{
        setDeleteIndex(venue[value?.target?.parentNode?.parentNode?.parentNode?.parentNode?.id]?.id)
        setShow({ ...show, del: value?.target?.parentNode?.parentNode?.parentNode?.parentNode?.id })

    }

    console.log('val',show.del);

    return (
        <div className="mr_24 ml_24 mt_24 pt_16">
            {loader.main === true ? <Loader width={180} height={180} /> :
                venue.length == 0 ?
                    <div className="mt_200">
                        <EmptyScreen loader={state.loader} title="Sub-Brand" btnTitle="Add Sub-Brand" btnClick={(e) => handleAdd(e)} />
                    </div>
                    :
                    <>
                        <div className="search d-flex align-items-center justify-content-right w-100 pb_16  mt_16 specialchildformdivwidthremove">
                            <div className="pt_8 pb_8 cp">
                                <SvgIcons.FilterIcon />
                            </div>
                            <div className="color-neutral60 U14M pl_8 pr_16 pt_8 pb_8 cp">
                                Filters
                            </div>
                            <CustomSearch
                                placeholder={"Search"}
                                className={"w-100 searchField"}
                                position={"start"}
                                height="36px"
                                onChange={(e) => handleUserSearch(e)}
                                onClickFilter={() => setShow({ ...show, showFiter: !show.showFiter })}
                                disableEndIcon={true}
                                filterShow={show.showFiter}
                            />
                        </div>

                        <div className="w-100 ">

                            <div>
                                <div className="bar"></div>
                                <div className="d-flex w-100 color-neutral90 Link11M audienceTableGrid overflow-hidden">
                                    <div
                                        className="col-3 pt_16 pb_16 cp ">BRAND NAME</div>
                                    <div className="col-5 pt_16 pb_16 cp">ADDRESS</div>
                                    <div className="col-2 pt_16 pb_16 cp">CREATED</div>
                                    <div className="col-2 pt_16 pb_16 text-left cp">PHONE NUMBER</div>
                                </div>
                                <div className="bar"></div>
                                {venue2?.length > 0 ? venue2?.map((element, idx) => (
                                    <>
                                        <div
                                            className="d-flex align-items-center w-100 dataRow tableRowHover"
                                            id={idx}
                                            onClick={(e)=>editFunc(element)}
                                        >
                                            <div className="col-3">
                                                <UserImagePart
                                                    firstName={element.value.brandName}
                                                    id={element._id}
                                                    image={element.value.brandLogo}
                                                />
                                            </div>
                                            <div className="col-5 color-neutral90 Link13R">
                                                {element?.Address}
                                            </div>
                                            <div className="col-2 text-left color-neutral90 Link13R">
                                                {element?.Created}
                                            </div>

                                            <div className="col-2 color-neutral90 Link13R">
                                                {element.PhoneNumber
                                                    ? element.PhoneNumber
                                                    : "N/A"}
                                            </div>

                                            

                                        </div>
                                        <div className="bar"></div>
                                    </>
                                )) : venue?.map((element, idx) => (
                                    <>
                                        <div
                                            className="d-flex align-items-center w-100 dataRow tableRowHover"
                                            id={idx}
                                            onClick={(e)=>editFunc(element)}

                                        >
                                            <div className="col-3">
                                                <UserImagePart
                                                    firstName={element.value.brandName}
                                                    id={element._id}
                                                    image={element.value.brandLogo}
                                                />
                                            </div>
                                            <div className="col-5 color-neutral90 Link13R">
                                                {element?.Address}
                                            </div>
                                            <div className="col-2 text-left color-neutral90 Link13R">
                                                {element?.Created}
                                            </div>

                                            <div className="col-2 color-neutral90 Link13R">
                                                {element.PhoneNumber
                                                    ? element.PhoneNumber
                                                    : "N/A"}
                                            </div>
                                         

                                        </div>
                                        <div className="bar"></div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </>
            }</div>
    )
}
const CustomFilter = ({ handleDateChange, filters, setFilters, handleFilterSearchFunc, resetFilter }) => {

    const [showDate, setShowDate] = useState(false);

    return (
        <div id="filterComponentAudience">
            <div className="row mt_24">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Address
                </div>
                <div className="col-9">
                    <CustomTextField
                        label="Address"
                        className="w-100"
                        onChange={(e) => setFilters({ ...filters, address: e.target.value })}
                        value={filters.address}
                    />
                </div>
            </div>

            <div className="row mt_24">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Phone
                </div>
                <div className="col-9">
                    <CustomTextField
                        label="Phone"
                        className="w-100"
                        onChange={(e) => setFilters({ ...filters, phone: e.target.value })}
                        value={filters.phone}
                    />
                </div>
            </div>


            <div className="row mt_24 position-relative">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Created
                </div>
                <div className="col-9">
                    <div className={`subscribedDate Body14R color-neutral60 cp d-flex ${showDate && 'isActiveDate'}`} onClick={() => setShowDate(!showDate)}>
                        {(filters.startDate != "" && filters.endDate != "") ?
                            <>
                                <div>
                                    {new Date(filters.startDate).getDate() + '-' + (new Date(filters.startDate).getMonth() + 1) + '-' + new Date(filters.startDate).getFullYear()}
                                </div>
                                <span className="ml_8 mr_8">
                                    -
                                </span>
                                <div>
                                    {new Date(filters.endDate).getDate() + '-' + (new Date(filters.endDate).getMonth() + 1) + '-' + new Date(filters.endDate).getFullYear()}
                                </div>
                            </>
                            :
                            <div className="Body14R color-neutral60">Overall</div>
                        }
                    </div>
                    <ClickOutside onClick={() => setShowDate(false)}>
                        <div className="position-absolute dateBox">
                            {showDate && <CustomDateRange onChange={handleDateChange} />}
                        </div>
                    </ClickOutside>
                </div>
            </div>

            <div className="d-flex mt_24 justify-flex-end">
                <div>
                    <CustomButton
                        btntext="Reset Filter"
                        varient="secondary"
                        className="mr_16"
                        onClick={resetFilter}
                    />
                </div>
                <div>
                    <CustomButton
                        btntext="Search"
                        varient="primary"
                        onClick={handleFilterSearchFunc}
                    />
                </div>
            </div>


        </div>
    )
}



export default Venue
