import React, {useState, useEffect, useContext} from 'react';

import LoyaltyMainComponent from '../../../../components/loyalty/LoyaltyMainComponent';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import CustomButton from '../../../../components/CustomButton'
import CustomSelect from '../../../../components/CustomSelect';
import CustomRadio from '../../../../components/CustomRadio';
import CustomTextField from '../../../../components/CustomTextField';

import { FormControl} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';


import SvgIcons from '../../../../icons/svg.icon';
import { ColorSchemeCode } from '../../../../enums/ColorScheme'
import PngIcons from '../../../../icons/png.icon';
import { AgencyContext } from '../../../../context/Agency.context';



export default function Redeempoints({changeBreadCrumbTitle}) {

    const RedeemPointContent = {
        title   : 'Redeem points',
        subHead : 'Create rewards your customers can redeem with the points they’ve earned. Learn more about ',
        link    : 'how customer redeem points.',
    }


    const Activitytypes = [
        {
            name        : 'Rs 5 off coupon',
            subHead     : '2000 points',
            active      : true,
            image       : PngIcons.dollarCashIcon
        },
        {
            name        : '10% off coupon',
            subHead     : '400 points',
            active      : false,
            image       : PngIcons.hundredIcon
        },
        {
            name        : 'Free shipping coupon',
            subHead     : '200 points',
            active      : false,
            image       : PngIcons.deilveryBoxIcon
        },
    ]

    return(
        <div id="RedeemPoints">
            <LoyaltyMainComponent 
                details               = {RedeemPointContent}
                Activitytypes         = {Activitytypes}
                ActivityTypeDetail    = {<ActivityTypeDetail/>}
                CommunicationDetail   = {<CommunicationDetail/>}
                ActionDetail          = {<ActionDetail/>}
            />
        </div>
    )
  
}


const ActivityTypeDetail = () =>{

    const [selectedActivity, setSelectedActivity] = useState('');

    const Activitytype = [
        {
            name        : 'Rs 5 off coupon',
            image       : PngIcons.dollarCashIcon
        },
        {
            name        : '10% off coupon',
            image       : PngIcons.hundredIcon
        },
        {
            name        : 'Free shipping coupon',
            image       : PngIcons.deilveryBoxIcon
        },
        {
            name        : 'POS amount discount',
            image       : PngIcons.dollarCashIcon
        },
        {
            name        : 'POS percentage off',
            image       : PngIcons.hundredIcon
        }
    ]

    const selectActivitytype = (activityname) =>{
        setSelectedActivity(activityname);
    }

    const agency = useContext(AgencyContext)

    return( 
        <div className="Activitytypedetails col-12"> 
            <div className='Heading4 color-neutral80'>Select Activity Type : </div>     
                {
                    Activitytype.map((element, idx)=>
                            <div className="col-10 d-flex space-between singleProgram" onClick={()=>selectActivitytype(element.name)}>
                                <div className="d-flex">
                                    <div className="imageBox">
                                        <img src={element.image} alt=""/>
                                    </div>
                                    <div className="subtitle1 pt_10 pb_10 ml_8">
                                        {element.name}
                                    </div>
                                </div>
                                {element.name === selectedActivity && 
                                    <div className="mt_12">
                                        <SvgIcons.CircularTickIcon width="24" height="24" backgroundColor={agency.agencyColor}/>
                                    </div>
                                }
                            </div>  
                       )
                }       
        </div>
    )
}



const ActionDetail = () => {
    return(
        <div className="Activitytypedetails col-12 d-flex space-between">
            <div className="col-8">
                <div className='Heading4 color-neutral80'>Point Redemption Style</div>  
                <FormControl 
                    className="mt_24"
                >
                    <RadioGroup 
                        name = "redemptionstyle"
                    >
                        <CustomRadio 
                            value     = {"Increment of points (recommended)"}
                            label     = "Increment of points (recommended)"
                            className = "mt_16"
                        />
                        <CustomRadio 
                            value = {"Fixed amount of points"}
                            label = "Fixed amount of points"
                        />
                    </RadioGroup>
                    {/* <FormHelperText>
                    </FormHelperText> */}
                </FormControl>

                <div className='Heading4 color-neutral80 mt_32'>Earning value</div>  
                <div className="mt_24 d-flex">
                    <div className="col-6 pl_0">
                        <CustomTextField
                            className   = "w-100"
                            label       = "Points Cost"
                            placeholder = "500 Point"
                        />
                    </div>
                    <div className="col-6 pl_0">
                        <CustomTextField
                            className   = "w-100"
                            label       = "Discount Value"
                            placeholder = "Rs 500"
                        />
                    </div>
                </div>
                <div className='Heading4 color-neutral80 mt_32'>Applies to</div>  
                <FormControl 
                    className="mt_24"
                >
                    <RadioGroup 
                        name = "redemptionstyle"
                    >
                        <CustomRadio 
                            value     = {"Entire Order"}
                            label     = "Order"
                            className = "mt_16"
                        />
                        <CustomRadio 
                            value = {"Specific Order"}
                            label = "Specific Order"
                        />
                    </RadioGroup>
                    {/* <FormHelperText>
                    </FormHelperText> */}
                </FormControl>
                <div className="col-6">
                    <CustomSelect
                        // options   = {getProgramApprovaltypes()}
                        className = "w-100"
                    />
                </div>
                <div className='Heading4 color-neutral80 mt_32'>Minimum Requirements</div>  
                <FormControl 
                    className="mt_24"
                >
                    <RadioGroup 
                        name = "redemptionstyle"
                    >
                        <CustomRadio 
                            value     = {"None"}
                            label     = "None"
                            className = "mt_16"
                        />
                        <CustomRadio 
                            value = {"Minimum purchase amount"}
                            label = "Minimum purchase amount"
                        />
                    </RadioGroup>
                    {/* <FormHelperText>
                    </FormHelperText> */}
                </FormControl>
                <div className="col-6">
                    <CustomSelect
                        // options   = {getProgramApprovaltypes()}
                        className = "w-100"
                    />
                </div>
                   <div className='Heading4 color-neutral80 mt_32'>Discount Code</div>  
                <div className="mt_24">   
                    <CustomCheckBox
                        label="Add Prefix to discount code"
                    />
                </div>
                <div className="col-6 position-relative">
                   <div className="Body14R position-absolute discountAmount z-index-1 fw-5">
                        $100OFF-
                   </div>
                   
                   <CustomTextField
                        className="w-100"
                        paddingLeft="80px"
                   />
                </div>
            </div>
        </div>
    )
}

const CommunicationDetail = () =>{

    const [selectedCommunications, setSelectedCommunications]=useState([]);

    const CommunicationTypes = [
        {
            name  : "Notification",
            icon  : <SvgIcons.CommunicationNotificationIcon/>,
            select: false,
        },
        {
            name  : "Email",
            icon  : <SvgIcons.CommunicationEmailIcon/>,
            select: false,
        },
        {
            name  : "SMS",
            icon  : <SvgIcons.CommunicatonSmsIcon/>,
            select: false,
        }
        
    ]

    useEffect(()=>{
        // setSelectedCommunications(CommunicationTypes);
    },[])

    const selectCommunicationType = (element, index) =>{
        selectedCommunications[index].select=!element.select;
        setSelectedCommunications([...selectedCommunications]); 
    }   
    

    return(
        <div className="communication col-12">
            <div className="headlineH4 color-neutral80 fw-6 fs-16">
                Keep your customer up to date 
            </div>
            {selectedCommunications.length !== 0 ?
            <div>
            <div className="Body14R mt_8">
                This Activity will trigger following communication. 
            </div>
            <div className="col-11 d-flex mt_16 pl_0">
                {
                selectedCommunications.map((element, idx)=>
                    <div className="communicationBox col-3 mr_32" onClick={()=>selectCommunicationType(element, idx)}>
                        <div className="selectIcon col-12 pr_0 text-right">
                            {element.select && <SvgIcons.CircularTickIcon/>}
                        </div>
                        <div className="text-center">
                            {element.icon}
                        </div>
                        <div className="Body14R mt_8 text-center">
                            {element.name}
                        </div>
                    </div>
                    )
                }
                
                </div>

            </div>

            :

            <div>
                <div className="Body14R mt_8">
                    Please goto communication tab and link activity with following options.
                </div>
                <div className="mt_8">
                    <CustomButton
                        btntext  = {'Go to communication'}
                        fontSize = {'12px'}
                        // height   = {'40px'}
                    />
                </div>
            </div>
            
        }

        </div>
    )
}