
import React, { useContext, useEffect, useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import CopyText from "../../components/CopyText";
import CustomButton from "../../components/CustomButton";
import CustomTextField from "../../components/CustomTextField";
import CustomCheckBox from "../../components/CustomCheckBox";
import { BrandContext } from "../../context/Brand.context";
import { LayoutContext } from "../../context/Layout.context";
import { UserContext } from "../../context/User.context";
import { ColorSchemeCode } from "../../enums/ColorScheme";
import { SvgIcons } from "../../icons";
import { BrandService, UserService } from "../../services";
import { BrandVld } from "../../validation";
import { ChromePicker } from 'react-color';
import { ClickOutside, utils } from "../../utils";
import ImageUpload from '../../components/ImageUpload';
import Editor from '../../components/QuillEditor';
import Loader from "../../components/Loader";
import { Settings } from "@mui/icons-material";
import VipClub from "../public/VIPClub";
import CustomDropDown from "../../components/CustomDropDown";

export default function ManyChat() {
  const user = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const layout = useContext(LayoutContext);
  const brand = useContext(BrandContext)
  const [selectedSection, setSelectedSection] = useState('styling')
  const [breadCrump, setbreadCrump] = useState([])


  const [styling, setStyling] = useState(true);

  const termsRef = useRef();
  const privacyRef = useRef();
  const [current, setCurrent] = useState(0);

  const [state, setState] = useState({
    loader: false,
    color: 'gradient',
    privacyPolicy: false,
    termConditions: false,
    colorList: {
      solid: [
        '#EB5757', '#F2994A', '#F2C94C', '#219653', '#27AE60', '#6FCF97', '#2F80ED', '#2D9CDB', '#56CCF2'
      ],
      gradient: [
        'linear-gradient(246.71deg, #7AD748 0%, #C79C57 25.52%, #BD459A 52.6%, #5855D8 78.13%, #0990C7 100%)',
        'linear-gradient(90deg, #EF32D9 0%, #89FFFD 100%)',
        'linear-gradient(90deg, #333399 0%, #FF00CC 100%)',
        'linear-gradient(90deg, #5FC3E4 0%, #E55D87 100%)',
        'linear-gradient(90deg, #12FFF7 0%, #B3FFAB 100%)',
        'linear-gradient(90deg, #FF5F6D 0%, #FFC371 100%)',
        'linear-gradient(90deg, #EF629F 0%, #EECDA3 100%)',
        'linear-gradient(90deg, #16BFFD 0%, #CB3066 100%)',
        'linear-gradient(90deg, #F05053 0%, #E1EEC3 100%)',
        'linear-gradient(90deg, #FFAFBD 0%, #FFC3A0 100%)',
        'linear-gradient(90deg, #BC4E9C 0%, #F80759 100%)',
        'linear-gradient(90deg, #FC5C7D 0%, #6A82FB 100%)',
        'linear-gradient(90deg, #12C2E9 0%, #C471ED 50%, #F64F59 100%)',
        'linear-gradient(90deg, #833AB4 0%, #FD1D1D 50%, #FCB045 100%)',
      ]
    },
    colorPallette: false,
  })

  const initState = {
    bottomCards: brand.integrations?.vipClub?.bottomCards || [
      {
        image: '',
        heading: '',
        description: ''
      },

    ],
    webhook: `${"https://api.walletly.ai/api/v4/services/vipclub/webhook"}`,
    points: brand.integrations?.vipClub?.points || 0,
    secretKey: brand.integrations?.vipClub?.secretKey || "",
    backgroundColor: 'linear-gradient(246.71deg, #7AD748 0%, #C79C57 25.52%, #BD459A 52.6%, #5855D8 78.13%, #0990C7 100%)',
    topSection: {
      images: brand?.integrations?.vipClub?.topSection?.images || [],
      description: brand.integrations?.vipClub?.topSection?.description || "",
    },
    bottomSection: {
      images: brand.integrations?.vipClub?.bottomSection?.images || [],
      description: brand.integrations?.vipClub?.bottomSection?.description || '',
    },
    privacyPolicy: brand.integrations?.vipClub?.privacyPolicy || '',
    termsAndConditions: brand.integrations?.vipClub?.termsAndConditions || '',
    headingOne: brand.integrations?.vipClub?.headingOne || 'Why Be A Guest. When You Can Be A Member?',
    headingTwo: brand.integrations?.vipClub?.headingTwo || 'Member Reward Benefits',
    headingThree: brand.integrations?.vipClub?.headingThree || 'The Best Quality Food',
    subHeadingone: brand.integrations?.vipClub?.subHeadingone || 'For $9.99/Month you can enjoy FREE items and amazing discounts everyday!',
    subHeadingtwo: brand.integrations?.vipClub?.subHeadingtwo || '1 Free Large Pizza Every Month 10% Off All Orders Friday & Saturday ',



  };


  let topImages = []
  let bottomImages = []

  const handleSubmit = async () => {
    setState({ ...state, loader: true });



    let desiredPath = `${user._id}/vipClub/image`;


    if (formik?.values?.topSection?.images?.length > 0) {
      for (let image of formik?.values?.topSection?.images) {
        if (typeof image != "string") {
          const uploadImage = await utils.uploadImage({ file: image, desiredPath });
          topImages = [...topImages, uploadImage.response.data]
        }
        else {
          console.log('else image 1', image);
          topImages = [...topImages, image]
        }

      }
    }
    if (formik?.values?.bottomCards?.length > 0) {
      for (var i = 0; i < formik?.values?.bottomCards?.length; i++) {
        if (typeof formik?.values?.bottomCards[i].image != "string") {
          const uploadImage = await utils.uploadImage({ file: formik?.values?.bottomCards[i].image, desiredPath });
          formik.values.bottomCards[i].image = uploadImage.response.data
          formik.setValues({ ...formik.values });
        }

      }
    }
    formik.values.topSection.images = topImages
    // formik.values.bottomSection.images = bottomImages
    formik.setValues({ ...formik.values });
    const payload = {
      _id: brand._id,
      integrations: {
        vipClub: {
          ...formik.values,
        }
      }
    };
    console.log('PAYLOAD', payload);
    const { error, response } = await BrandService.Update({
      toaster: true,
      payload,
    });
    if (response) {
      setState({ ...state, loader: false });
      brand.updateBrand(response.data);
    };
    return;
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: BrandVld.VipClub,
    isInitialValid: false,
  });




  const addCard = () => {
    formik.values.bottomCards.push(
      {
        image: '',
        heading: '',
        description: ''
      }
    )
    formik.setValues({ ...formik.values })
    breadCrump.push(true);
    setbreadCrump(breadCrump)
  }


  useEffect(() => {
    layout.setLayout({
      title: "Vip Club",
      borderBottom: false,
      button: <CustomButton
        btntext="Update"
        onClick={handleSubmit}
        icon={
          state.loader && (
            <CircularProgress size={20} color={"inherit"} />
          )
        }
        disabled={
          formik.errors?.secretKey || formik.errors?.points || formik.values.secretKey == ""
        }
      />
    });
  }, [formik.values, formik.errors, state.loader]);
  useEffect(() => {
    if (breadCrump?.length < formik.values.bottomCards?.length == breadCrump?.length != formik.values.bottomCards?.length) {
      for (const cards of formik?.values?.bottomCards) {
        breadCrump.push(true);
        setbreadCrump(breadCrump)
      }
    }
    console.log('breadCrump', breadCrump)
    console.log('breadCrump', breadCrump?.length < formik.values.bottomCards?.length)
    console.log('breadCrump', !breadCrump?.length == formik.values.bottomCards?.length)
  }, []);


  const handleRemoveImage = (index) => {
    console.log('-----');
    console.log(index);
    console.log('-----');
    formik.values.topSection.images.splice(index, 1);
    formik.setValues({ ...formik.values });
    console.log(formik.values.topSection.images);

  }
  const handleRemoveImageBottom = (index) => {
    formik.values.bottomCards[index].image = ''
    formik.setValues({ ...formik.values });
  }
  const deleteCard = (index) => {
    console.log('index,index', index)
    formik.values.bottomCards.splice(index, 1);
    formik.setValues({ ...formik.values });
    breadCrump.splice(index, 1);
    setbreadCrump(breadCrump)
    setCurrent(0)
  }





  return (

    <div className="w-100 vh-100" style={{ overflow: 'hidden' }} >

      {!loader ? <div style={{ overflow: 'hidden' }} className="insideComponent d-flex w-100">
        {
          <div className="hidden-scroll vh-100 w-40">
            <div className='w-90  pl_16 pr_15 d-flex align-items-center mt_15 justify-content-between'>
              <div className='Heading22M color-neutral100'>{styling == true ? "Styling" : "Settings"}</div>
              {/* <CustomButton
            btntext = "Update"
            onClick = {handleSubmit}
            icon    = {
              state.updateLoader && (
                <CircularProgress size={20} color={"inherit"} />
              )
            }
            disabled={
              state.loader || formik.errors?.secretKey || formik.errors?.points || formik.values.secretKey == ""
            }
          /> */}
            </div>
            <div className="bar mt_10 mb_16 w-100"></div>
            {styling &&
              <div className="leftSectionWidget">

                <div className='d-flex pt_12 pb_12 ml_16 mr_16'>
                  <div className={`tabButton Link13R cp transition w-50 text-center  pt_6 pb_6   ${selectedSection == 'styling' && 'bg-color-primary0 fw-5 Body14M color-primary50 borderRadius-4'} `} onClick={() => { setSelectedSection('styling'); setStyling(true) }}>
                    Styling
                  </div>
                  <div className={`tabButton Link13R cp transition w-50 text-center   pt_6 pb_6  ${selectedSection == 'setting' && 'bg-color-primary0 fw-5 Body14M color-primary50 borderRadius-4'} `} onClick={() => { setSelectedSection('setting'); setStyling(false) }}>
                    Setting
                  </div>
                </div>
                <div className="bar mt_16 mb_16 d-flex justify-content-center w-100"></div>
                <div className="tabBody">
                  <div id="VipClubSetting" className="d-flex flex-column pb_100">

                    <div className="w-100 d-flex flex-column pl_16 pr_16 pt_8">
                      <div className="Heading16M color-Heading">Background Color</div>
                      <div className="d-flex">
                        <div onClick={() => setState({ ...state, color: 'solid' })} className={`pl_16 pr_16 cp pb_8 borderRadius-6 mr_4 Body14R color-primary50 pt_8 ${state.color == 'solid' && 'bg-color-primary0 fw-5 Body14M'}`}>
                          Solid Colors
                        </div>
                        <div onClick={() => setState({ ...state, color: 'gradient' })} className={`pl_16 pr_16 pb_8 borderRadius-6 cp mr_4 Body14R color-primary50 pt_8 ${state.color == 'gradient' && 'bg-color-primary0 Body14M'}`}>
                          Gradients
                        </div>
                      </div>
                      <div className="d-flex mt_8 w-90  flex-wrap gap-4">
                        {state.colorList[state.color].map((color) =>
                          <div onClick={() => formik.setValues({ ...formik.values, backgroundColor: color })} className="cp singleColorBox borderRadius-2 middle mr_6" style={{ background: color, height: 30, width: 30 }}>
                            {color == formik.values.backgroundColor && <SvgIcons.IconTick color={ColorSchemeCode.white} />}
                          </div>
                        )}
                        {state.color == "solid" &&
                          <div onClick={() => setState({ ...state, colorPallette: !state.colorPallette })} className="cp position-relative singleColorBox borderRadius-2 middle mr_6 border" style={{ height: 30, width: 30, border: '1px solid #84889B' }}>
                            <SvgIcons.Add />
                            {state.colorPallette &&
                              <ClickOutside onClick={() => setState({ ...state, colorPallette: false })}>
                                <ChromePicker
                                  className="pallette position-absolute z-index-1"
                                  color={formik.values.backgroundColor}
                                  onChange={(e) => {
                                    state.colorList[state.color] = [...state.colorList[state.color], e.hex];
                                    setState({ ...state })
                                    formik.setValues({ ...formik.values, backgroundColor: e.hex })
                                  }}
                                />
                              </ClickOutside>
                            }
                          </div>
                        }
                      </div>
                      <div className="bar mt_16 mb_16 w-100"></div>
                      <div className="">
                        <div className="Heading16M color-Heading">Club 1</div>
                        <div className='d-flex gap-12 align-items-center'>
                          <div className="Link14R color-neutral70 panelLabel mt_8">Heading</div>
                          <input
                            autocomplete="off"
                            name="headingOne"
                            onChange={formik.handleChange}
                            value={formik.values?.headingOne}
                            className="outline-none  w-80 border-none Link14R underline color-neutral100 "
                            style={{ backgroundColor: '#F5F7FC', padding: '4px' }}


                          />
                        </div>
                        {/* <CustomDropDown/> */}
                        <div className='d-flex gap-12 align-items-center'>
                          <div className="Link14R color-neutral70 panelLabel mt_8">Sub Heading</div>
                          <input
                            autocomplete="off"
                            name="subHeadingone"
                            onChange={formik.handleChange}
                            value={formik.values?.subHeadingone}
                            className="outline-none w-80 border-none Link14R underline color-neutral100 "
                            style={{ backgroundColor: '#F5F7FC', padding: '4px' }}

                          />
                        </div>
                        <div className="Link14R panelLabel color-Heading color-neutral70 mb_8 mt_8">Description</div>
                        <Editor
                          value={formik.values?.topSection?.description}
                          onChange={(content, delta, source, editor) => {
                            formik.values.topSection.description = content;
                            formik.setValues({ ...formik.values })
                          }}
                        />
                        <div className="bar mt_16 w-100 mb_12"></div>
                        <div className="uploadImage">
                          <div className="align-items-center d-flex space-between">
                            {
                              formik.values.topSection.images.length <= 5
                              &&
                              <>
                                <div className="Heading16M color-Heading">

                                  Club 1 Images
                                </div>
                                {/* <ImageUpload state={'topSection'} formik={formik} isCustomButton={true} imageFile={getImageTop} multiple={true} */}
                                <ImageUpload state={'topSection'} formik={formik} isCustomButton={true} multiple={true}
                                />
                              </>
                            }

                          </div>
                          {formik.values?.topSection?.images?.length > 0 && <div className={formik.values.topSection.images.length > 0 ? "imagesSection wpx-200 d-flex flex-wrap" : "imagesSection b-r_5 w-100 d-flex flex-wrap"}>
                            {formik.values?.topSection?.images?.map((image, index) =>
                              <div className="position-relative mr_8 mb_8 cp" style={{ width: '52px', height: '52px' }}>
                                <img height={52} className="borderRadius-4  border" style={{ objectFit: 'cover' }} width={52} src={typeof image == "string" ? image : URL.createObjectURL(image)} alt="" />

                                <div className="overlayAdd middle" onClick={(e) => handleRemoveImage(e, index)}>
                                  <div className="position-absolute" style={{ top: 4, right: 4 }}>
                                    <SvgIcons.CrossIcon color={ColorSchemeCode.white} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>}

                        </div>

                      </div>

                      <div className="bar mt_8 mb_8 w-100"></div>
                      <div className="mt_8">
                        <div className="Heading16M color-Heading">Club 2</div>
                        <div className='d-flex gap-12 align-items-center'>
                          <div className="Link14R color-neutral70 panelLabel mt_4">Heading</div>
                          <input
                            autocomplete="off"
                            name="headingTwo"
                            onChange={formik.handleChange}
                            value={formik.values?.headingTwo}
                            className="outline-none w-80 border-none Link14R underline color-neutral100 "
                            style={{ backgroundColor: '#F5F7FC', padding: '4px' }}

                          />
                        </div>
                        <div className="Link14R panelLabel color-Heading color-neutral70 mb_8 mt_8">Description</div>
                        <Editor
                          value={formik.values?.bottomSection?.description}
                          onChange={(content, delta, source, editor) => {
                            formik.values.bottomSection.description = content;
                            formik.setValues({ ...formik.values })
                          }}
                        />

                        <div className="bar mt_16 w-100 mb_12"></div>
                        {/* <div className="uploadImage">
                          <div className="align-items-center d-flex space-between">
                            {
                              formik.values?.bottomSection?.images?.length <= 5
                              &&
                              <>
                                <div className="Heading16M color-Heading">
                                  Club 2 Images
                                </div>
                                <ImageUpload state={'bottomSection'} formik={formik} isCustomButton={true} imageFile={getImageBottom} multiple={true}
                                />
                              </>
                            }

                          </div>
                          {formik.values?.bottomSection?.images?.length > 0 && <div className={formik.values.bottomSection.images.length > 0 ? "imagesSection w-100 d-flex flex-wrap" : "imagesSection b-r_5 w-100 d-flex flex-wrap"}>
                            {formik.values?.bottomSection?.images?.map((image, index) =>
                              <div className="position-relative mr_8 mb_8 cp" style={{ width: '52px', height: '52px' }}>
                                <img height={52} className="borderRadius-4  border" style={{ objectFit: 'cover' }} width={52} src={typeof image == "string" ? image : URL.createObjectURL(image)} alt="" />

                                <div className="overlayAdd middle" onClick={(e) => handleRemoveImageBottom(e, index)}>
                                  <div className="position-absolute" style={{ top: 4, right: 4 }}>
                                    <SvgIcons.CrossIcon color={ColorSchemeCode.white} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>}

                        </div> */}
                      </div>
                      <div className="Clubthree">
                        <div className="Heading16M color-Heading">Club 3</div>
                        <div className='d-flex gap-12 align-items-center'>
                          <div className="Link14R color-neutral70 panelLabel mt_4">Heading</div>
                          <input
                            autocomplete="off"
                            name="headingThree"
                            onChange={formik.handleChange}
                            value={formik.values?.headingThree}
                            className="outline-none w-80 border-none Link14R underline color-neutral100 "
                            style={{ backgroundColor: '#F5F7FC', padding: '4px' }}
                          />
                        </div>
                        <div className="Cards_Main mt_16">
                          {
                            console.log(
                              'breadCrump', breadCrump
                            )
                          }
                          {
                            formik?.values?.bottomCards?.length > 0 &&
                            formik?.values?.bottomCards.map((element, index) => {
                              return (<>
                                <div style={{ transition: 'all 3s ease-in-out' }} className="OneCardMain mt_16">
                                  <div className="card-upper-heading d-flex justify-content-space-between">
                                    <p className="mb_0 Heading16M color-Heading">Card {index + 1}</p>
                                    <span className="mr_7 d-flex">
                                      <span onClick={(e) => deleteCard(index)} className="mr_10  cp">
                                        <SvgIcons.DeleteIcon color='#DE245C' /></span> <div onClick={() => {
                                          console.log('first', breadCrump)
                                          breadCrump[index] = !breadCrump[index];
                                          console.log('first', breadCrump)

                                          setbreadCrump([...breadCrump])
                                        }} style={{ transform: breadCrump[index] ? 'rotate(0deg)' : 'rotate(90deg)', transition: 'all 0.5s ease-in' }}
                                          className="cp"><SvgIcons.Arrowclosedowngrey /></div></span>
                                  </div>
                                  <div className="bar mt_8 mb_8 w-100"></div>
                                  <div   className={breadCrump[index] ? 'animatpp' : 'aminatezz'}>
                                    {breadCrump[index]
                                      && <div>
                                        <div className="card-image">
                                          {
                                            !formik?.values?.bottomCards[index]?.image
                                            &&
                                            <div className="d-flex justify-content-space-between">
                                              <div className="Heading16M color-Heading">
                                                Card Image
                                              </div>
                                              {/* <ImageUpload state={'topSection'} formik={formik} isCustomButton={true} imageFile={getImageTop} multiple={true} */}
                                              <ImageUpload state={'bottomSection'} index={index} formik={formik} isCustomButton={true} multiple={false}
                                              />
                                            </div>
                                          } {formik?.values?.bottomCards[index]?.image && <div className={formik?.values?.bottomCards[index]?.image ? "imagesSection wpx-200 d-flex flex-wrap" : "imagesSection b-r_5 w-100 d-flex flex-wrap"}>
                                            <div className="position-relative mr_8 mb_8 cp" style={{ width: '52px', height: '52px' }}>
                                              <img height={52} className="borderRadius-4  border" style={{ objectFit: 'cover' }} width={52} src={typeof formik?.values?.bottomCards[index]?.image == "string" ? formik?.values?.bottomCards[index]?.image : URL.createObjectURL(formik?.values?.bottomCards[index]?.image)} alt="" />
                                              <div className="overlayAdd middle" onClick={(e) => handleRemoveImageBottom(index)}>
                                                <div className="position-absolute" style={{ top: 4, right: 4 }}>
                                                  <SvgIcons.CrossIcon color={ColorSchemeCode.white} />
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                          }
                                        </div>
                                        <div className="bar mt_8 mb_8 w-100"></div>
                                        <div className=' card-heading d-flex gap-12 align-items-center'>
                                          <div className="Link14R color-neutral70 panelLabel mt_4">Card Title</div>
                                          <input
                                            autocomplete="off"
                                            name="bottomCards"
                                            onChange={(e) => {
                                              formik.values.bottomCards[index].title = e.target.value
                                              formik.setValues({ ...formik.values })
                                            }}
                                            value={formik.values?.bottomCards[index].title}
                                            className="outline-none w-80 border-none Link14R underline color-neutral100 "
                                            style={{ backgroundColor: '#F5F7FC', padding: '4px' }}

                                          />
                                        </div>
                                        <div className="card-description">
                                          <div className="Link14R panelLabel color-Heading color-neutral70 mb_8 mt_8">Description</div>
                                          <Editor
                                            value={formik.values?.bottomCards[index].description}
                                            onChange={(content, delta, source, editor) => {
                                              formik.values.bottomCards[index].description = content
                                              formik.setValues({ ...formik.values })
                                            }}
                                          />
                                        </div>
                                      </div>}
                                  </div>


                                </div>
                              </>)
                            })
                          }
                          <div style={{ borderRadius: '8px', backgroundColor: '#F2F6FF' }} onClick={addCard} className="Add-Cards mt_8 cp w-100 hpx-48 d-flex justify-content-space-between align-items-center">
                            <p className="ml_20" style={{ fontSize: '12px', fontWeight: '500', margin: 0 }}>Add Cards</p>
                            <span className="mr_20"><SvgIcons.PlusIcon color='#84889B' /></span>
                          </div>
                          <div className="bar mt_8 mb_8 w-100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            }










            {styling == false &&
              <div className="leftSectionWidget">

                <div className='d-flex pt_12 pb_12 border-bottom ml_16 mr_16'>
                  <div className={`tabButton Link13R cp transition w-50 text-center  pt_6 pb_6   ${selectedSection == 'styling' && 'bg-color-primary0 fw-5 Body14M color-primary50 borderRadius-4'} `} onClick={() => { setSelectedSection('styling'); setStyling(true) }}>
                    Styling
                  </div>
                  <div className={`tabButton Link13R cp transition w-50 text-center   pt_6 pb_6  ${selectedSection == 'setting' && 'bg-color-primary0 fw-5 Body14M color-primary50 borderRadius-4'} `} onClick={() => { setSelectedSection('setting'); setStyling(false) }}>
                    Setting
                  </div>
                </div>
                <div className="tabBody">
                  <div id="VipClubSetting" className="d-flex flex-column pb_100">

                    <div className="w-100 d-flex flex-column pl_16 pr_16 pt_8">
                      {/* <div className="bar mt_15 w-100"></div> */}
                      <label className="Heading16M color-Heading">Stripe Webhook</label>
                      <div className="position-relative">
                        <CustomTextField
                          // label     = {"Stripe Webhook"}
                          name="webhook"
                          className="w-100 disabled"
                          value={formik.values?.webhook}
                        />
                        <div style={{ position: "absolute", top: "15px", right: "15px" }}>
                          <CopyText id="webhook" content={formik.values?.webhook} hideText={true} />
                        </div>
                      </div>
                      <div className=" mt_15 w-100"></div>
                      <label className="Heading16M color-Heading">Stripe Secret key</label>
                      <div className="position-relative">
                        <CustomTextField
                          // label     = {"Stripe Secret Key"}
                          name="secretKey"
                          className="w-100 "
                          type="password"
                          helperText={formik.errors.secretKey && formik.errors.secretKey}
                          value={formik.values?.secretKey}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className=" mt_15 w-100"></div>
                      <label className="Heading16M color-Heading">Payment Page</label>
                      <div className="position-relative">
                        <CustomTextField
                          // label     = {"Payment page"}
                          name="payment"
                          className="w-100 disabled"
                          value={`https://${window.location.host}/page/${brand._id}/vipclub`}
                          disabled={true}
                        />
                        <div style={{ position: "absolute", top: "15px", right: "15px", background: 'white' }}>
                          <CopyText
                            id="paymentpage"
                            content={`https://${window.location.host}/page/${brand._id}/vipclub`}
                            hideText={true}
                          />
                        </div>

                      </div>
                      <div className=" mt_15 w-100"></div>
                      <label className="Heading16M color-Heading">Points</label>
                      <div className="position-relative w-100">
                        <CustomTextField
                          placeholder="Points"
                          type="number"
                          name="points"
                          position="start"
                          value={formik.values?.points}
                          onChange={formik.handleChange}
                          inputProps={{ onFocus: formik.handleBlur }}
                          error={formik.errors.points && formik.errors.points}
                          helperText={
                            formik.errors.points &&
                            formik.errors.points &&
                            formik.errors.points
                          }
                        />
                      </div>
                      {/* <div className="mt_8 Heading16M color-Heading">Description</div> */}


                      <div className="bar mt_8 w-100"></div>
                      <div className="w-100 ml_10">
                        <CustomCheckBox
                          label={<p className="mb_0 Link16R">Add Terms & Condition</p>}
                          fontSize='16px '
                          Color='#2E3039'
                          onChange={() => {
                            state.termConditions = !state.termConditions
                            if (!state.termConditions) {
                              formik.values.termsAndConditions = '';
                              formik.setValues({ ...formik.values });
                              termsRef.current.value = ""
                            }
                            setState({ ...state })
                          }}
                        />
                      </div>


                      <div className="position-relative w-100">
                        <CustomTextField
                          label={"Terms & Condition URL"}
                          placeholder="http://www.example.com/terms-and-conditions"
                          type="text"
                          name="termsAndConditions"
                          value={formik.values?.termsAndConditions}
                          onChange={formik.handleChange}
                          inputRef={termsRef}
                          inputProps={{ onFocus: formik.handleBlur }}
                          className={!state.termConditions && 'disabled'}
                          disabled={!state.termConditions}
                          error={formik.errors.termsAndConditions && formik.errors.termsAndConditions}
                          helperText={
                            formik.errors.termsAndConditions &&
                            formik.errors.termsAndConditions &&
                            formik.errors.termsAndConditions
                          }
                        />
                      </div>


                      <div className="w-100 ml_10">
                        <CustomCheckBox
                          label={<p className="mb_0 Link13R">Add Privacy Policy</p>}
                          fontSize='16px '
                          Color='#2E3039'
                          onChange={() => {
                            setState({ ...state, privacyPolicy: !state.privacyPolicy });
                            if (!state.privacyPolicy) {
                              formik.values.privacyPolicy = '';
                              formik.setValues({ ...formik.values });
                              privacyRef.current.value = ""

                            }
                          }}
                        />
                      </div>

                      <div className="position-relative w-100">
                        <CustomTextField
                          label={"Privacy Policy"}
                          className={!state.privacyPolicy && 'disabled'}
                          placeholder="http://www.example.com/privacy-policy"
                          type="text"
                          inputRef={privacyRef}
                          disabled={!state.privacyPolicy}
                          name="privacyPolicy"
                          value={formik.values?.privacyPolicy}
                          onChange={formik.handleChange}
                          inputProps={{ onFocus: formik.handleBlur }}
                          error={formik.errors.privacyPolicy && formik.errors.privacyPolicy}
                          helperText={
                            formik.errors.privacyPolicy &&
                            formik.errors.privacyPolicy &&
                            formik.errors.privacyPolicy
                          }
                        />
                      </div>


                    </div>



                  </div>
                </div>
              </div>

            }

          </div>

        }

        <div className=" hidden-scroll flowSection vh-100 w-60" style={{ backgroundColor: '#F5F7FC', height: 'fit-content' }}>
          <VipClub setCurrent={setCurrent} current={current} formik={formik} />

        </div>
      </div> : <Loader height="32px" width="32px" />}
    </div>
  )
}
