
import React from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import CreateRedemption from './subpages/RedemptionsCreate';

export default function Redemption(props) {

    return (
        <div>
            <Switch>
                <Route exact path = {props.match.url + '/CreateRedemption'}   render= {() =>    <CreateRedemption    show={props.show}   setShow={props.setShow} {...props}  />}/>
                <Redirect path={props.match.url + '/*'} to={props.match.url+ '/CreateRedemption'} />
                <Redirect path={props.match.url} to={props.match.url+ '/CreateRedemption'} />
            </Switch>   
        </div>
    )
}
