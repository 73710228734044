
import React, { useContext, useEffect, useState, useRef } from 'react'

import localforage, * as localForage from 'localforage'


import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';

import { Options, utils } from '../../../utils';
import { CSVLink, CSVDownload } from "react-csv";
import { LayoutContext } from '../../../context/Layout.context';
import { BrandService, PassService } from '../../../services';
import { SvgIcons } from '../../../icons';
import CustomsModal from '../../../components/CustomModal';
import { CircularProgress, FormControl, FormHelperText, RadioGroup } from '@mui/material';
import CustomButton from '../../../components/CustomButton';
import { Brand } from '../../../enums'
import DeleteModal from '../../../components/DeleteModal';
import { AgencyContext } from '../../../context/Agency.context';
import { showToaster } from '../../../utils/utils';
import CustomRadio from '../../../components/CustomRadio';
import 'react-phone-input-2/lib/style.css'
import CustomSwitch from '../../../components/CustomSwitch';
import { BrandContext } from '../../../context/Brand.context';
import { useFormik } from 'formik';
import { BrandVld } from '../../../validation';


export default function Raffle() {

    const brand = useContext(BrandContext);
    const layout = useContext(LayoutContext)
    const [csvData, setCsvData] = useState(null)
    const csvLinkRef = useRef();
    const headers = [
        { label: "airTableUpdate", key: "airTableUpdate" },
        { label: "brandId", key: "brandId" },
        { label: "campaignId", key: "campaignId" },
        { label: "color", key: "color" },
        { label: "created", key: "created" },
        { label: "delete", key: "delete" },
        { label: "entries", key: "entries" },
        { label: "growthToolType", key: "growthToolType" },
        { label: "passCode", key: "passCode" },
        { label: "passDesignId", key: "passDesignId" },
        { label: "passType", key: "passType" },
        { label: "platformType", key: "platformType" },

    ];
    //   setCsvData( [
    //     { fullName : 'Shahmeer' , phone : '923202186570' },
    //     { fullName : 'Shahmeer' , phone : '923202186570' },
    //   ])
    // const csvData = [
    //     { fullName: 'Shahmeer', phone: '923202186570' },
    //     { fullName: 'Shahmeer', phone: '923202186570' },
    // ]

    const initState = {
        goldEntries: brand?.entries?.gold || '',
        platniumEntries: brand?.entries?.platnium || '',
        fromDate: null,
        toDate: null
    }
    console.log('brand?.entries?.gold',brand?.entries?.gold);
    const reef = useRef()
    const [state, setState] = useState({
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
    })

    const formik = useFormik({
        initialValues: { ...initState },
        isInitialValid: true,
        enableReinitialize: true
    })

    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })
        const payload = {
            _id: brand._id,
            entries : {
                gold : formik.values.goldEntries ,
                platnium : formik.values.platniumEntries 
            }
        }
        console.log('extra service payload is ', payload);

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })

        brand.updateBrand(response.data)
        return setState({ generalLoader: false, apiError: '' })
    }



    const getData = async () => {
        console.log('te');
        const { response, error } = await PassService.GetEntries({ query: { brandId: '1v81TNqMGrxD7hwa4mcG', fromDate: formik.values.fromDate, toDate: formik.values.toDate } })
        console.log('respond',response);

        if (response?.data?.length) {
        console.log('respond',response);
            setCsvData(response.data)   
            csvLinkRef.current.link.click();
        } else {
            console.log('error', error);
        }
    }
    React.useEffect(() => {
        layout.setLayout({
            title: 'Settings',
            borderBottom: false,
            switch: "",
            button: ""
        })
    }, [formik.values, formik.errors, state.loader])
    console.log('formik', formik.values);
    console.log('csvData', csvData);




    return (
        <>
            <div className="w-100">
                <div className='col-md-12   col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Raffle</div>
                    <CustomButton
                        btntext={"Update"}
                        type="submit"
                        disabled={state.generalLoader}
                        icon={state.generalLoader && <CircularProgress size={20} color={'inherit'} />}
                    onClick={handlePublicProfileUpdateFunc}
                    />

                </div>
                <div className=" col-md-12 col-12 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                <div className="col-12 col-md-12 pl_0 pr_0">
                    <div className="w-70 mt_10 ">
                        <div className='w-100 mt_26 d-flex justify-content-space-between'>
                            <div className='w-45 '>
                                <CustomTextField
                                    label={`Set Entries Gold`}
                                    name='goldEntries'
                                    value = {formik.values?.goldEntries}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className='w-45'>
                                <CustomTextField
                                    label={`Set Entries Platnium`}
                                    name='platniumEntries'
                                    value = {formik.values?.platniumEntries}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className=" col-md-12 mt_16 col-12 pl_0 pr_0">
                            <div className="bar"></div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 mt_16   col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Export Audience Data</div>
                </div>
                <div className=" col-md-12 col-12 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                <div className="col-12 col-md-12 pl_0 pr_0">
                    <div className="w-70 mt_10 ">
                        <h2 className='Heading16M mt_18'>Select Date Range</h2>
                        <p className='Body14R mt_6' style={{ color: '#84889B' }}>Simply select the desired dates to narrow down your results and find the information you need efficiently.</p>
                        <div className="w-100 d-flex justify-content-space-between mt_20" >
                            <div className="w-45">
                                <CustomTextField
                                    type='date'
                                    label='From'
                                    icon={<SvgIcons.dateIcon />}
                                    position='end'
                                    name='fromDate'
                                    onChange={(e) => formik.setValues({ ...formik.values, fromDate: new Date(e.target.value).valueOf() })}
                                />
                            </div>
                            <div className="w-45">
                                <CustomTextField
                                    type='date'
                                    label='To'
                                    icon={<SvgIcons.dateIcon />}
                                    position='end'
                                    name='toDate'
                                    onChange={(e) => formik.setValues({ ...formik.values, toDate: new Date(e.target.value).valueOf() })}
                                />
                            </div>
                        </div>
                        <div className=" mt_20 col-md-12 col-12 pl_0 pr_0">
                            <div className="bar"></div>
                        </div>
                        <div className='mt_40 '>
                            <CustomButton
                                btntext={"Download"}
                                type="submit"
                                disabled={!formik.values.toDate || !formik.values.fromDate}
                                onClick={() => getData()}
                            />
                            {
                                <CSVLink  ref={csvLinkRef}  className='SpeceCsv' data={csvData || []} headers={headers || []}  >
                                </CSVLink>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
