import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import QrcodeScanner from '../../../components/QrcodeScanner';
import SettingHeader from '../../../components/setting/SettingHeader';

import { PassDesign } from '../../../enums';
import { PassService } from '../../../services';


export default function CameraScanner() {
    const history = useHistory()

    const [state, setState] = useState({
        loader   : false,
        apiError : ''
    })

    const handleQRChange = async (data) => {
        let passCode;
        if(!data) return
        if(data.includes('https') || data.includes('http') || data.includes('localhost:3000')) {
            if(data.includes('dealId')) return window.open(data.includes('localhost:3000') ? 'http://' + data : data, '_blank');
            else{
                let querys = data.split('?');
                let query = querys[1].split('&');
                let filtering = query.filter((element, idx) => {
                    if(element.includes('passCode')){
                        passCode =  element.split('=')[1];
                    }
                }) 
            }
            
        }
        setState({...state, loader: true})
        const query = {passCode: passCode || data, designType: PassDesign.DesignTypes.APPLE}
        const {error, response} = await PassService.GetPassJSON({query})
        setState({...state, loader: false})
        const path = window.location.pathname.split('/')
        path.pop()
        response && history.push({pathname: path.join('/')+'/redemption', state: {passData: response.data, linkCheck: data ? data : undefined}})
        error && setState({...state, apiError: error.message})
    }


    return (
        <div>
       
            {/* <div className = "headline3 heading ml_24 mt_24">Camera Scanner</div>
            <div className = "Body14R subheading ml_24 mt_24">Scan your Barcode:</div> */}
            <div className = "mt_16">
                {state.loader && <div className="text-center w-50 m_auto mt_80"><CircularProgress /></div>}
                {!state.loader && <QrcodeScanner 
                    onScan  = {handleQRChange}
                    onError = {()=>setState({...state, apiError: 'error getting qr code'})}
                />}
                {/* <div className="error w-40 m_auto mt_10">{state.apiError}</div> */}
            </div>
        </div>
    )
}
