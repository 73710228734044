import React, { Component } from "react";
import PngIcons from "../icons/png.icon";

import * as localForage from 'localforage'

import Loader from '../components/Loader'

const UserContext = React.createContext();

class UserContextComponent extends Component {
  state = {loader: true}

  getActiveBrand = () => {
    const param = window.location.pathname.split('/')[1]
    if(param.length > 10) return param
  }
  
  componentDidMount = async () => {
    const user = await localForage.getItem('user')
    const activeBrand = this.getActiveBrand() || user && user.activeBrand
    const permissions = await localForage.getItem('permissions')
    const activePermission = permissions && permissions.find(permission=>permission.businessId === activeBrand)
    console.log('zoop' , activeBrand);

    this.setState({loader: false, ...user, activePermission})
  }

  setPermissions = ({updatedUser, permissions}) => {
    const activePermission = permissions.find(permission=>permission.businessId === updatedUser.activeBrand)
    this.setState({...updatedUser, activePermission})
    localForage.setItem('permissions', permissions)
  }

  updateUser = (updates, permissions) => {
    const updatedUser = {...this.state, ...updates}
    this.setState(updatedUser)
    localForage.setItem('user', {...updatedUser})
    permissions && this.setPermissions({updatedUser, permissions})
  }



  render() {
    return (
      this.state.loader ? 
      <Loader  width={300} height={300} />
      :
      <UserContext.Provider value={{...this.state, updateUser: this.updateUser}}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export { UserContext, UserContextComponent }