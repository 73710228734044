import React, { useContext } from 'react'
import { useHistory } from 'react-router';

import { useFormik } from 'formik'
import * as localForage from 'localforage'

import { AuthVld } from '../../validation';
import { UserService } from '../../services'

import SignupHeader from '../../components/SignupHeader'
import StepOneSignup from '../../components/auth/signup/StepOneSignup'
import StepTwoSignup from '../../components/auth/signup/StepTwoSignup'
import { UserContext } from '../../context/User.context';
import { AgencyContext } from '../../context/Agency.context';
import Header from '../../components/auth/signup/Header';

export default function UserOnboarding() {

    const history           = useHistory()
    const userContext       = useContext(UserContext)
    const agency            = useContext (AgencyContext);
    const [state, setState] = React.useState({
        page     : 1,
        loader   : false,
        apiError : ''
    })

    const initState = {
        usedFor            : '',
        createdFor         : '',
        dialingCode        : userContext.dialingCode || agency.dialingCode || '',
        phone              : userContext.phone || '',
        jobDescription     : '',
        companyStrength    : '',
        experience         : '',
        walletlyReferences : [],
    }

    const handleSubmit = async () => {
        setState({...state, loader: true})

        let brandRedemptionType = formik.values.brandRedemptionType;

        delete formik.values.brandRedemptionType;

        const localUser = await localForage.getItem('user')
        const platforms = {walletly: {id: localUser._id}}
        const { error, response } = await UserService.Update({payload: {...formik.values, platforms, _id: localUser._id}})
        setState({...state, loader: false})

        if(error) return setState({...state, apiError: error.error})
        const user = await UserService.Get()
        user.response && userContext.updateUser(user.response.data)
        
        if(user.response.data.activeBrand)
        return history.push('/' + user.response.data.activeBrand)
        
        history.push({pathname : '/brand' , state : {brandRedemptionType : brandRedemptionType}})
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : AuthVld.OnBoard,
        isInitialValid   : false
    })

    return (
        <div>
            <div id="UserOnboard">
                <Header />
                    <div className="text-center pb_100" style={{height : '100%'}}> 
                            <div className='row middle mt_32'>
                                <div className="col-12 cardForm onBoardSteps h-100 max-h-100">
                                    <form onSubmit={formik.handleSubmit}>
                                        { state.page === 1 ? 
                                            <StepOneSignup 
                                                state    = {state}
                                                setState = {setState}
                                                formik   = {formik}
                                            /> 
                                            : 
                                            <StepTwoSignup 
                                                state        = {state}
                                                setState     = {setState}
                                                formik       = {formik}
                                                handleSumbit = {handleSubmit}
                                            />
                                        }
                                    </form>
                                </div>
                            </div>
                    </div>
            </div>
        </div>
    )
}
