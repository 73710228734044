import * as yup from 'yup';
import { Campaign, enums, Fields, PassDesign } from '../enums'

const REQ_ERROR = 'This field is required'

const PreBuiltPassDesign = yup.object({
    designName : yup.string().required(REQ_ERROR),
    passType   : yup.string().required(REQ_ERROR)
})

const logoText = yup.object({
    key         : yup.string().required(REQ_ERROR),
    label       : yup.string(),
    value       : yup.string(),
    description : yup.string(),
    reference   : yup.string().required(REQ_ERROR).oneOf(Object.values(Fields.FieldReference))
})

const applePassFields = yup.object({
    key           : yup.string().required(REQ_ERROR),
    label         : yup.string().required(REQ_ERROR),
    value         : yup.string(),
    changeMessage : yup.string().required(REQ_ERROR),
    textAlignment : yup.string().required(REQ_ERROR),
    numberStyle   : yup.string(),
    timeStyle     : yup.string(),
    dateStyle     : yup.string(),
    currencyCode  : yup.string(),
    reference     : yup.string().required(REQ_ERROR).oneOf(Object.values(Fields.FieldReference))
})

const appleRelevanceLocation = yup.object({
    latitude     : yup.number(),
    longitude    : yup.number(),
    relevantText : yup.string(),
    address      : yup.string()
})

const appleRelevanceBeacons = yup.object({
    proximityUUID : yup.string(),
    relevantText  : yup.string()
})

const apple = yup.object({
    model           : yup.string().required(REQ_ERROR).oneOf(Object.values(PassDesign.ApplePassModels)),
    logoText        : logoText,
    headerFields    : yup.array().of(applePassFields).max(1),
    primaryFields   : yup.array().of(applePassFields).max(2),
    secondaryFields : yup.array().of(applePassFields).max(2),
    auxiliaryFields : yup.array().of(applePassFields).max(2),
    backFields      : yup.array().of(applePassFields),
    locations       : yup.array().of(appleRelevanceLocation),
    beacons         : yup.array().of(appleRelevanceBeacons)
})

const updateDesign = yup.object({
    designName     : yup.string().required(REQ_ERROR),
    backgroundColor: yup.string().required(REQ_ERROR),
    fontColor      : yup.string().required(REQ_ERROR),
    qrcode         : yup.boolean().required(REQ_ERROR),
    apple          : apple.required(REQ_ERROR),
    numberOfStamps : yup.mixed(),
    baseUrl        : yup.string(),
    logo           : yup.string(),
    logoFile       : yup.mixed()
                    .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('logo',{
                        is   : value => !value,
                        then : yup.mixed().required(REQ_ERROR)
                    }),
    filledImage     : yup.string(),
    filledImageFile : yup.mixed()
                    .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('logo',{
                        is   : value => !value,
                        then : yup.mixed().required(REQ_ERROR)
                    }),
    unFilledImage    : yup.string(),
    unFilledImageFile: yup.mixed()
                    .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('logo',{
                        is   : value => !value,
                        then : yup.mixed().required(REQ_ERROR)
                    }),
    cover           : yup.string().required(),
    coverFile       : yup.mixed()
                    .test('imageSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('cover',{
                        is   : value => !value,
                        then : yup.mixed().required(REQ_ERROR)
                    }),
})

export {
    PreBuiltPassDesign,
    updateDesign
}