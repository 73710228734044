import React from 'react';
import CustomSelect from '../../../../components/CustomSelect';
import CustomTextField from '../../../../components/CustomTextField';

export default ({setstate, state}) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="pl_20 pt_20 dndflowDIV" style={{height: '100vh', width: '341px'}}>
      <div className="mt_20 mb_50 Heading22M color-neutral100" >Blocks</div>
      <div className="dndnode mb_16 cursorgrab" onDragStart={(event) => {setstate({...state, data: 'visit'}); onDragStart(event, 'default')}} draggable>
        <div className="Heading16M color-neutral100 mb_4">Visit</div>
        <div className="Body14R color-neutral60">Triggers when somebody visits</div>
      </div>
      <div className="dndnode mb_16 cursorgrab" onDragStart={(event) => {setstate({...state, data: 'point'}); onDragStart(event, 'default')}} draggable>
        <div className="Heading16M color-neutral100 mb_4">Point</div>
        <div className="Body14R color-neutral60">Triggers when somebody earn point</div>
      </div>
      <div className="dndnode mb_16 cursorgrab" onDragStart={(event) => {setstate({...state, data: 'deal'}); onDragStart(event, 'default')}} draggable>
        <div className="Heading16M color-neutral100 mb_4">Deal</div>
        <div className="Body14R color-neutral60">Triggers when somebody avail deal</div>
      </div>
    </div>
  );
};