import * as yup from 'yup';
import { enums } from '../enums';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    // productId  : yup.string().required(REQ_ERROR),
    // prefix       : yup.string().min(3),
    
    name         : yup.string().required(REQ_ERROR),
    description  : yup.string(),
    price        : yup.number().required(REQ_ERROR),
    brandLogo    : yup.string(),
    brandLogoFile: yup.mixed()
                    .test('imageSize', 'File is too large, it can be maximum of 5mb.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true)
                    .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                    .when('avatar',{
                        is   : value => !value,
                        then : yup.mixed()
                    }),

})

const RewardRedemptionVld = yup.object({
    RewardName : yup.string().required(REQ_ERROR),
    RewardValue: yup.string().required(REQ_ERROR),
    PointsCost : yup.number().required(REQ_ERROR),
    CWCode     : yup.number().required(REQ_ERROR),
})
const ReferralVld = yup.object({
    ReferrelWillEarn : yup.number().required(REQ_ERROR),
    RefereeWilEarn: yup.number().required(REQ_ERROR),
    ActivityType : yup.string().required(REQ_ERROR),
    Description : yup.string()
})
const BirthdayVld = yup.object({
    PointCost : yup.number().required(REQ_ERROR),
    Description : yup.string()
})
const AddRewardsVld = yup.object().shape({
    rewards: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Name is required').min(1, 'Name cannot be empty'),
        price: yup.string().required('Key is required').min(1, 'Key cannot be empty'),
        description: yup.string().required('URL is required').min(1, 'URL cannot be empty'),
        image: yup.string().trim(),
      })
    ),
  });


export {
    Create,
    RewardRedemptionVld,
    ReferralVld,
    BirthdayVld,
    AddRewardsVld
}