import React, {useContext, useEffect, useState} from 'react';
import {Route, Switch, Redirect, useParams} from "react-router-dom";

import Analytics from '../../pages/loyalty/subpages/Analytics';
import Loyaltypoints from '../../pages/loyalty/subpages/Loyaltypoints';
import Punchpasses from '../../pages/loyalty/subpages/Punchpasses';
import SettingHeader from '../../components/setting/SettingHeader';
import { LayoutContext } from '../../context/Layout.context';
import { CampaignContextComponent } from '../../context/Campaign.context';
import { useHistory } from 'react-router-dom'
import { ColorSchemeCode } from '../../enums/ColorScheme';
import { SvgIcons } from '../../icons';
import CustomButton from '../../components/CustomButton';
import ReactTooltip from 'react-tooltip';
import localforage from 'localforage';
import { CampaignService } from '../../services';
import { BrandContext } from '../../context/Brand.context';
import LoyaltyMain from './LoyaltyMain';
import PassDesignMain from '../passDesign/PassDesignMain';
import Redeemingmain from './subpages/redeemingways/redeemingmain';
import AddRedeemingWays from './subpages/redeemingways/AddRedeemingWays';
import RewardBuilder from './subpages/rewardbuilder/RewardBuilder';
import EarnBuilder from './subpages/rewardbuilder/EarnBuilder';
import RewardBuilderListing from './subpages/rewardbuilder/RewardBuilderListing';

export default function Loyalty(props) {
    const layout                        = useContext(LayoutContext)
    const history                       = useHistory()
    const [tooltipText, setTooltipText] = useState('copy');
    const [campaign, setCampaign]       = useState();
    const brand                         = useContext(BrandContext);

    const handleCopy = (e, selectedCampaign) => {
        e.preventDefault()
        e.stopPropagation()
        const content = selectedCampaign.campaignCode
        navigator.clipboard.writeText(content)
        ReactTooltip.show(document.getElementById('tooltip'+selectedCampaign.campaignCode))
        setTooltipText('copied')
    }

    React.useEffect(async ()=>{

        let campaign  = await localforage.getItem('campaign');
        // if(campaign)setCampaign(campaign);
        if((!campaign)|| campaign.length === 0) {
            let campaignData = await CampaignService.GetQueriedCampaign({query:{brandId: brand._id, delete: false, campaignType: 5}});
            if(campaignData.response){
                campaign = campaignData.response.data[0];
                setCampaign(campaignData.response.data[0]);
                localforage.setItem("campaign", campaignData.response.data[0]);
            }
        }
        else{
            setCampaign(campaign)
        }
    },[])

    React.useEffect(async ()=>{
       
        if(history.location.pathname.split('/')[history.location.pathname.split('/').length - 1] === 'earn') {

        let campaign  = await localforage.getItem('campaign');
        // if(campaign)setCampaign(campaign);
        if((!campaign)|| campaign.length === 0) {
            let campaignData = await CampaignService.GetQueriedCampaign({query:{brandId: brand._id, delete: false, campaignType: 5}});
            if(campaignData.response){
                campaign = campaignData.response.data[0];
                setCampaign(campaignData.response.data[0]);
                localforage.setItem("campaign", campaignData.response.data[0]);
            }
        }
        else{
            setCampaign(campaign)
        }
        console.log('Campign Loyality', campaign);

        }
    },[(history.location.pathname.split('/')[history.location.pathname.split('/').length - 1] === 'earn')])

    return (
        <CampaignContextComponent>
            <div id="loyalty" className="">
                <Switch>    
                    <Route exact path = {props.match.url + '/'} component                 = {LoyaltyMain}/>
                    <Route exact path = {props.match.url + '/redeem'} component           = {Redeemingmain}/>
                    <Route path = {props.match.url + '/reward/earn'} component           = {EarnBuilder}/>
                    {/* <Route path = {props.match.url + '/redeem/earn'} component           = {EarnBuilder}/> */}
                    <Route path       = {props.match.url + '/redeem/add'} component       = {AddRedeemingWays}/>
                    {/* <Route path       = {props.match.url + '/redeem/rewardadd'} component = {RewardBuilder}/> */}
                    <Route exact path = {props.match.url + '/reward'} component           = {RewardBuilderListing}/>
                    {/* <Route path       = {props.match.url + '/reward/add'} component       = {RewardBuilder}/> */}
                    <Route path       = {props.match.url + '/reward/rewardadd'} component       = {RewardBuilder}/>
                    <Route path       = {props.match.url + '/points'} component           = {Loyaltypoints}/>
                    <Route path       = {props.match.url + '/design'} render              = {(props)=> <PassDesignMain {...props} selectedCampaign={campaign}/>}/>
                    <Route path       = {props.match.url + '/stamps'}  component          = {Punchpasses}/>
                    <Route path       = {props.match.url + '/analytics'} component        = {Analytics}/>
    
                    <Redirect path={props.match.url+ '/*'} to={props.match.url+ '/'} />
                    <Redirect path={props.match.url} to={props.match.url+ '/'} />
                </Switch>
            </div>
        </CampaignContextComponent>
    )
}


