import React, { useContext, useState } from 'react'

import localforage, * as localForage from 'localforage'


import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';

import { Options, utils } from '../../../utils';
import { LayoutContext } from '../../../context/Layout.context';
import { BrandService } from '../../../services';
import { SvgIcons } from '../../../icons';
import CustomsModal from '../../../components/CustomModal';
import { CircularProgress, FormControl, FormHelperText, RadioGroup } from '@mui/material';
import CustomButton from '../../../components/CustomButton';
import { Brand } from '../../../enums'
import DeleteModal from '../../../components/DeleteModal';
import { AgencyContext } from '../../../context/Agency.context';
import { showToaster } from '../../../utils/utils';
import CustomRadio from '../../../components/CustomRadio';
import 'react-phone-input-2/lib/style.css'
import CustomSwitch from '../../../components/CustomSwitch';
import { BrandContext } from '../../../context/Brand.context';
import { useFormik } from 'formik';
import { BrandVld } from '../../../validation';
import Location from './Location';



export default function LocationsNew() {


    const brand  = useContext(BrandContext);
    const layout = useContext(LayoutContext);
    
    const initState={
       
        location         : brand.location,
        multipleLocations: brand.multipleLocations || []
    }
    const [state, setState] = useState({
        generalLoader : false,
        apiError      : '',
        brandingLoader: false,
    })

    const formik = useFormik({
        initialValues      : { ...initState },
        validationSchema   : BrandVld.UpdateBrand,
        isInitialValid     : true,
        enableReinitialize : true
    })

   return(

    <>
    <div className='w-100 col-md-12 col-12 pl_0 pr_0 '>
      <Location state={state} setState={setState} formik={formik} brand={brand} initState={initState}/>
    </div>  
    </>
    )

}