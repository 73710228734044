const VenueType = [
   ,'advertising'
   ,'agency',
   ,'amusement park'
   ,'bakery'
   ,'bar'
   ,'beauty salon'
   ,'bicycle store'
   ,'book store'
   ,'cafe'
   ,'clothing store'
   ,'department store'
   ,'electronics store'
   ,'furniture store'
   ,'gym'
   ,'hardware store'
   ,'home goods store'
   ,'laundry'
   ,'marketing'
   ,'meal delivery'
   ,'meal takeaway'
   ,'night club'
   ,'painter'
   ,'pet store'
   ,'real estate agency'
   ,'restaurant'
   ,'shoe store'
   ,'shopping mall'
   ,'store'
   ,'subway station'
   ,'supermarket'
   ,'travel agency'
   ,'university'
   ,'other'
]

module.exports = VenueType