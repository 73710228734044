import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ColorSchemeCode } from '../enums/ColorScheme';

export default function BasicSelect({label, ...props}) {

  return (
    <Box sx={{ minWidth: 120 }} className={props.className}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" sx={{fontSize: '16px'}}>{label}</InputLabel>
        <Select
          labelId  = "demo-simple-select-label"
          id       = "demo-simple-select"
          onChange = {props.onChange}
          label    = {label}
          value    = {props.value}
          ref      = {props.inputRef}
          disabled = {props.disabled}
          name     = {props.name}
          sx       = {{
            fontSize     : '15px',
            fontFamily   : 'Inter',        // set the font size to 16px
            textTransform: 'capitalize',
            '&:hover'    : {
              borderColor: ColorSchemeCode.primary50 // set the border color to red on hover
            },
            '&:focus': {
              borderColor: ColorSchemeCode.primary50  // set the border color to blue on focus
            }
          }}
        >
          {props.options ? props.options.map((element, idx)=><MenuItem className='capitalize' value={element?.value || element.name || element}>{element?.name || element?.value || element }</MenuItem>) 
          : 
          
            <MenuItem>Select</MenuItem>
          }
          
        </Select>
      </FormControl>
    </Box>
  );
}
