import React, {useContext, useEffect, useState} from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomTextField';
import CopyText from '../../../components/CopyText';
import CustomModal from '../../../components/CustomModal';
import CustomTextField from '../../../components/CustomTextField';
import { BrandContext } from '../../../context/Brand.context';
import { guards } from '../../../utils';
import { BrandService } from '../../../services';
import { AgencyContext } from '../../../context/Agency.context';
import { LayoutContext } from '../../../context/Layout.context';
import { BrandVld } from '../../../validation';
import { useFormik } from 'formik';
import { FormControl, FormHelperText, RadioGroup } from '@mui/material';
import CustomRadio from '../../../components/CustomRadio';


export default function Api(props) {

    
    const layout = useContext(LayoutContext)
    useEffect(()=>{
        layout.setLayout({
            // title       : "Api",
            title       : 'Settings',
            button      : "",
            subTitle    : "",
            borderBottom: false,
        });
    },[])
    return (
        <div id="Api">
            <div className="">
                    <SingleComponent/>
            </div>
        </div>
    )
}


const SingleComponent = (props) => {
    const brand     = useContext(BrandContext)

    const [state, setState] = useState({
        open       : false,
        deleteApi  : false,
        regenerate : false
    })

    const initState={
        // appleWalletShow   : brand.appleWalletShow || false,
        inStoreReward: brand.inStoreReward || "false",
        pointStoreOffering: brand.pointStoreOffering || "false",
    }

    const formik = useFormik({
        initialValues      : { ...initState },
        validationSchema   : BrandVld.UpdateBrand,
        isInitialValid     : true,
        enableReinitialize : true
    })

    const handlePublicProfileUpdateFunc = async () => {
        setState({generalLoader: true, apiError: ''})

        // const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+'+formik.values.fullPhone)

        // let logo, cover
        // const user        = await localforage.getItem('user')
        // const desiredPath = `${user._id}/${formik.values.brandName}`

        
        // if(formik.values.brandLogoFile){
        //     logo = await utils.uploadImage({file: formik.values.brandLogoFile, desiredPath})
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        // }
        // if(logo && logo.error) return setState({loader: false, brandingLoader : false, apiError: logo.error.message})
        
        // // uploading cover
        // if(formik.values.brandCoverFile){
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        //     cover = await utils.uploadImage({file: formik.values.brandCoverFile, desiredPath})
        // }
        // if(cover && cover.error) return setState({loader: false, brandingLoader : false, apiError: cover.error.message})

        // const permissions        = await localforage.getItem('permissions')

        // if(formik.values.brandLogoFile){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].logo = logo.response.data;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }

        // if(formik.values.brandName){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].brandName = formik.values.brandName;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }


        // const payload = {
        //     _id: brand._id,
        //     postPaid: formik.values.postPaid == '1' ? true : false
        //     appleWalletShow   : formik.values.appleWalletShow,        
            
        // }
        const payload = {
            _id               : brand._id,
            inStoreReward: formik.values.inStoreReward == "false" ? false : true,
            pointStoreOffering: formik.values.pointStoreOffering == "false" ? false : true,           
        }

        console.log('payload is ', payload);

        // if(logo && logo.response) payload.brandLogo    = logo.response.data
        // if(cover && cover.response) payload.brandCover = cover.response.data

        const { error, response } = await BrandService.Update({toaster: true, payload})
        if(error) return setState({generalLoader: false, brandingLoader : false, apiError: error.error})

        console.log("Business ",response.data);
        brand.updateBrand(response.data)
        return setState({generalLoader: false, apiError: ''})

    }

    return(
        <div className="apiBox ml_4">
            <div className="col-12 pl_0">
                    <div className="Heading22M color-neutral100 mb_4">
                        Get API Key
                    </div>
                    <div className='bar mt_8 mb_16'></div>
                    <div className="Body14R color-neutral60">
                        Beware, if you Refresh API Key all connected API methods will be disabled.
                        Here is the link to Swagger where you can try our API. Help article is available here.
                    </div>
                    <div className="col-md-12 mt_30 col-12 mt_16 pl_0 position-relative pr_0 w-70">
                            <CustomTextField
                                className="w-100 disabled-high-opacity"
                                label="Brand Id"
                                name="brandId"
                                value={brand._id}
                            />
                            <div className="copyBox" style={{ position: "absolute", top: "15px", right: "16px", }}>
                                <CopyText id="brandId" content={brand._id} hideText={true} />
                            </div>
                        </div>

                        <div className="col-md-12 col-12 mt_16 pl_0 position-relative pr_0 w-70">
                        <CustomTextField
                            className="w-100 disabled-high-opacity"
                            label="business Page URL"
                            name="businessPageUrl"

                            value={window.location.hostname + "/page" + window.location.pathname.replace('setting/general', 'businesspage')}
                        // inputProps   = {{tabIndex: '2', onFocus: formik.handleBlur }}
                        // error        = {formik.touched.brandName && formik.errors.brandName}
                        // helperText   = {formik.touched.brandName && formik.errors.brandName ? formik.errors.brandName : ''}
                        />
                        <div className="copyBox" style={{ position: "absolute", top: "15px", right: "16px", }}>
                            <CopyText id="businessPageUrl" content={window.location.hostname + "/page" + window.location.pathname.replace('setting/general', 'businesspage')} hideText={true} />
                        </div>
                    </div>

                <div className="col-md-12 col-12 pl_0 pr_0 w-70">
                        <div className="col-12 col-md-12 pl_0 pr_0 position-relative mt_16">
                            <CustomInput 
                                label       = {"API Key"}
                                placeholder = {"Your api key here!"}
                                className   = "w-100"
                                value       = {brand.apiKey}
                                disabled1    = {true}
                                inputProps  = {{ readOnly: true }}
                            />
                            <div className="copyBox" style={{ position: "absolute", top: "17px", right: "16px" }}>
                                <CopyText content={brand.apiKey} hideText={true} />
                            </div>
                        </div>
                        <div className="regenrateButton pt_24 d-flex space-between w-100">
                            <CustomButton
                                btntext      = {"Regenerate Api Key"}
                                className    = {""}
                                varient      = {'secondary'}
                                onClick      = {() => setState({...state, regenerate: true, deleteApi: false, open: true})}
                            />

                            <CustomButton
                                btntext         = "Delete API Key"
                                varient         = "warningSecondary"
                                onClick         = {() => setState({...state, deleteApi: true,regenerate : false, open: true})}
                            />
                        </div>
                    {/* <div className="buttonDelete mt_24">
                         <div className="Heading16M color-neutral100 mb_4">
                            Delete API Key
                        </div>
                        <div className="Body14R color-neutral60 mb_24">
                            Beware, if you Delete Token all connected API methods will be disabled.
                        </div>
                        <CustomButton
                            btntext         = "Delete API Key"
                            varient         = "warningSecondary"
                            onClick         = {() => setState({...state, deleteApi: true,regenerate : false, open: true})}
                        />

                    </div> */}
                </div>
           
             </div>
        

            {/* <div className="col-md-5 col-12 pl_0">
            
                <div className="col-md-12 col-12 Body14R fs-14 color-tableActionDropdownColor detail">
                
                {
                props.component !== 'delete' ?
                
                    'Beware, if you Refresh API Key all connected API methods will be disabled. \n Here is the link to Swagger where you can try our API. Help article is available here.'
                    
                    :

                    'Beware, if you Delete Token all connected API methods will be disabled.'

                }   
                </div> 
                         
            </div> */}



                <div className='col-md-12    col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8 mt_50'>
                    <div>
                        <div className='Heading22M color-neutral100'>In-Store Reward Type</div>
                <div className="color-propertySectionTextColor color-neutral60 fs-14">
                Choose where your customers will store their loyalty passes and coupons.
                            </div>
                        </div>
                    {/* onClick={handlePublicProfileUpdateFunc}  */}
                    <CustomButton btntext={"Update"} 
                    disabled={state.generalLoader}
                    icon={state.generalLoader && <CircularProgress color='inherit'  size={14} />} onClick={handlePublicProfileUpdateFunc} />
                </div>
                <div className="col-md-12 col-12 mb_10 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                {/* <div className=" col-md-12 col-12 pl_0 pr_0">
                    <div className="bar"></div>
                </div> */}
                <div className="col-12 col-md-12 pl_0 pr_0">
                    <div className="w-70 mt_10">
                        <div className="Heading16M color-neutral100 ">
                            Are you considering implementing a rewards program that is separate from Walletly?
                        </div>
                        {console.log('formik ', formik.values)}
                        <div className="mt_8 ml_11" id='inStore-radio'>
                            <FormControl
                            >
                                <RadioGroup
                                    name="inStoreReward"
                                    onChange={formik.handleChange}

                                    value={formik.values.inStoreReward}
                                >
                                    <CustomRadio
                                        value={"true"}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={"false"}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>

                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="Heading16M color-neutral100" style={{ marginTop: "10px" }}>
                            Would you prefer to disable Auto Redemption on Pass and instead offer manual rewards?
                        </div>
                        <div className='point-two'>This would allow customers to accrue points, but their pass would not be updated until they are eligible for a reward.</div>
                        <div className="mt_8 ml_11" id='inStore-radio2'>
                            <FormControl

                            >
                                <RadioGroup
                                    name="pointStoreOffering"
                                    onChange={formik.handleChange}
                                    value={formik.values.pointStoreOffering}
                                >
                                    <CustomRadio
                                        value={"true"}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={"false"}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>

                                </FormHelperText>
                            </FormControl>
                        </div>
                    </div>
                </div>

            <CustomModal 
                open      = {state.open}
                title     = "Regenerate API-Key"
                onClose   = {() => setState({...state, open: false})}
                component = {<ModalComponent
                    onClose    = {() => setState({...state, open: false})}
                    deleteApi  = {state.deleteApi}
                    regenerate = {state.regenerate}
                    brand      = {brand}
                />}
            />

        </div>
    )
}

function ModalComponent({onClose, deleteApi, regenerate, brand}){
    const [confirmInput, setConfirmInput] = useState()
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => setConfirmInput(e.target.value)

    const handleGenerate = async () => {
        setLoader(true)
        const payload = {_id: brand._id}
        if(deleteApi) payload.deleteApiKey = true
        if(regenerate) payload.apiKey = guards.generateKey()

        const { error, response } = await BrandService.Update({toaster: true, payload})

        if(response) brand.updateBrand(response.data)

        setLoader(false)
        return onClose()
    }

    const agency = useContext(AgencyContext)

    return(
        <div>
            <div className="modalComponent">
                { regenerate && 
                <div className="pb_16">
                    <div className="mt_4 Link14M color-neutral60">
                    Confirm you want to regenerate the Api Key by typing YES below.
                    </div>
                </div> 
                }
                { deleteApi && 
                    <div className="pb_16">
                        <div className="color-neutral100 Heading22M">
                        Delete API Key
                    </div>  
                    <div className="mt_4 Link14M color-neutral60">
                        Confirm you want to delete the Api Key by typing DELETE below.
                    </div>
                    </div>
                    }
                <CustomInput 
                    placeholder = {regenerate ? "YES" : "DELETE"}
                    className   = "w-100"
                    value       = {confirmInput}
                    onChange    = {handleChange}
                />
            </div>
            <div className="float-right modalButton">
                <CustomButton
                    btntext         = {"Cancel"}
                    className       = {"mr_15"}     
                    varient         = {'secondary'}
                    onClick         = {onClose}
                />
                <CustomButton
                    btntext      = {regenerate ? "Generate" : "Delete"}
                    disabled     = {loader || (regenerate ? confirmInput !== 'YES' : confirmInput !== 'DELETE')}
                    icon         = {loader && <CircularProgress size={20} color={'inherit'}/>}
                    varient      = {deleteApi && 'warning'}
                    onClick      = {handleGenerate}
                />
            </div>
        </div>
    )
}