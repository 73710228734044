import { CircularProgress, FormControl, RadioGroup } from '@mui/material';
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomButton from '../../../../components/CustomButton';
import CustomDropDown from '../../../../components/CustomDropDown';
import CustomsModal from '../../../../components/CustomModal';
import CustomRadio from '../../../../components/CustomRadio';
import CustomSwitch from '../../../../components/CustomSwitch';
// import Tags from '../../../../components/CustomTagComponent';
import CustomTextField from '../../../../components/CustomTextField'
import DeleteModal from '../../../../components/DeleteModal';
import Autocomplete from '../../../../components/googleMap/Autocomplete';
import GoogleMap from '../../../../components/googleMap/GoogleMap';
import NewTagComponent from '../../../../components/NewTagComponent';
import ViewDetail from '../../../../components/ViewDetail';
import { BrandContext } from '../../../../context/Brand.context'
import { LayoutContext } from '../../../../context/Layout.context';
import { UserContext } from '../../../../context/User.context'
import { Communication } from '../../../../enums'
import { ColorSchemeCode } from '../../../../enums/ColorScheme';
import { SvgIcons } from '../../../../icons';
import { CommunicationService, PassService } from '../../../../services';
import { ClickOutside, utils } from '../../../../utils';
import { CommunicationVld } from '../../../../validation'

function PushCreate(props) {

    
    const template                = props.location.state || {}
    const brand                   = useContext(BrandContext)
    const layout                  = useContext(LayoutContext)
    const user                    = useContext(UserContext)
    const history                 = useHistory()
    const [viewMore, setViewMore] = useState(false)
    
    useEffect(()=>{
        layout.setLayout({
            title        : template.communicationName?"Update Push":"Create Push",
            backTitles   : ['Communication','Push'],
            button       : '',
            borderBottom : true,
            edit         : false
        });
    },[])

    const  [filterAudience, setFilterAudience] = useState([]);
    const  [filteredOptions, setFilteredOptions] = useState([]);
    const  [deleteModal, setDeleteModal] = useState(false);
    
    
    const [ state, setState ]     = useState({
        apiError     : '',
        processing   : false,
        deleteLoader : false,
        viewMessage  : template.viewMessage || '',
        message      : template.viewMessage || '',
        audience     : '',
        // filteredOptions : []
    })
    
    const initState = {
        communicationName : template.communicationName || 'New Push',
        communicationType : template.communicationType || Communication.CommunicationTypes.PUSH_NOTIFICATION,
        geo               : template.geo || true,
        schedule          : template.schedule || '',
        radio             : 'save',
    }

    // new Date().getFullYear() + '-' + (parseInt(new Date().getMonth())+1) + '-' + new Date().getDate() + 'T' + new Date().getHours() + ':' + new Date().getMinutes()
    
    const handleDelete = async() => {
        setState({...state, deleteLoader: true})
        const payload = {
            _id     : template._id,
            delete  : true,
        }

        const {error, response} = await CommunicationService.Update({toaster: true, payload})
        // if(error) return setState({loader: false, apiError: error.message})
        setState({...state, deleteLoader: false})
        if(response) history.goBack()
                
    }

    // const suggestions = [
    //     {title:"FirstName",value : Communication.CustomFields.FIRSTNAME},
    //     {title:"Email",value : Communication.CustomFields.EMAIL},
    //     {title:"Points",value : Communication.CustomFields.POINTS},
    //     {title:"Promotion",value : Communication.CustomFields.PROMOTION},
    //   ];

    const suggestions = [
        {
          id: Communication.CustomFields.FIRSTNAME,
          display: "First Name",
        },
        {
          id: Communication.CustomFields.LASTNAME,
          display: "Last Name",
        },
        {
          id: Communication.CustomFields.EMAIL,
          display: "Email",
        },
        {
          id: Communication.CustomFields.POINTS,
          display: "Points",
        },
        {
            id: Communication.CustomFields.passUrl,
            display: "Loyalty Card",
        },
        {
            id: Communication.CustomFields.referral,
            display: "Referral",
        },
  ];

    const handleTagChange = (e) => setState({...state, viewMessage : e.target.value, message : e.target.value})
    // setState({...state, viewMessage : e.detail.tagify.DOM.input.textContent, message : e.detail.tagify.state.lastOriginalValueReported})
    const onAddressChange     = (event) => formik.setValues({...formik.values, location: {...formik.values.location, address: event.target.value}})
    const locationSummary     = (location) => formik.setValues({...formik.values, location})

    const getPlace = (e) => {
        const Geocoder = new window.google.maps.Geocoder()
        const placeRequest = {location: {lat: e.latLng.lat(), lng: e.latLng.lng()}}

        Geocoder.geocode(placeRequest, (places, status) => {
            if(status !== window.google.maps.GeocoderStatus.OK) return
            const location = utils.summarisedLocation(places[0])
            formik.setValues({...formik.values, location})            
        })
    }


    const handleSubmit = async() =>{

        const payload = {
            communicationName : formik.values.communicationName,
            // message           : state.message,
            viewMessage       : state.viewMessage,
            brandId           : brand._id,
            communicationType : formik.values.communicationType,
            smsType           : 'draft'
        }

        let newAudience = [];
        filterAudience.forEach((pass)=>{
            newAudience.push(pass.data.passCode);
        })

        if(formik.values.radio === 'send'){
            payload.audience = newAudience
            payload.smsType = 'published'
        }
        if(formik.values.radio === 'schedule'){
            payload.schedule = new Date(formik.values.schedule).valueOf();
            payload.audience = newAudience
            payload.smsType = 'scheduled'

        }

        // console.log('payload ', payload)
        


        setState({...state, processing: true})

        if(formik.values.radio === 'schedule'){
            const { error, response } = await CommunicationService.pushNotification({toaster: true, payload})
            if(error) return setState({...state, apiError: error.message})
        }
        else if(formik.values.radio === 'send'){
            const { error, response } = await CommunicationService.pushNotification({toaster: true, payload})
            if(error) return setState({...state, apiError: error.message})
        }
        else{
            if(!utils.emptyJSON(template)){
                delete payload.communicationType
                payload._id = template._id
                payload.smsType = 'draft'
            }
    
            const { error, response } = utils.emptyJSON(template) ? 
                                            await CommunicationService.pushNotification({toaster: true, payload}) :
                                            await CommunicationService.Update({toaster: true, payload})
            
            if(error) return setState({...state, apiError: error.message})
        }
                                        
        setState({...state, processing: false})
        // history.goBack();
    }

    useEffect(async() => {
      const user = await PassService.GetQueriedPass({query: {brandId: brand._id, delete: false}})
      if(user.response&&user.response.data&&user.response.data.length>0){
          let temp = []
          for (const value of user.response.data) {
            // console.log('value ', value);
            if(value.isWallet && value.passPushToken){
                temp.push({
                    label : value.userData?.firstName,
                    value : value.userId,
                    image : utils.getAvatar({fontSize: '10px',firstName: value.userData?.firstName, lastName: value.userData?.lastName&&value.userData.lastName, id: value.userId, tableName : 'customers', width: '20px', heigth: '20px', bgColor: value.color}),
                    data  : value
                })
            }
          }
          setFilteredOptions([...temp])
          // console.log('temp ', temp)
      }
    }, [])
    

    const formik = useFormik({
        initialValues      : { ...initState },
        onSubmit           : handleSubmit,
        validationSchema   : CommunicationVld.CreateSMS,
    })


  return (
    <div id="SMSDetailScreen" className='pt_40 pb_40 w-50 container'>
        <div className='Heading22M color-neutral100'>PUSH Information</div>
        <div className='bar mb_16 mt_12'></div>
        <CustomTextField 
            label    = "Push Name"
            name     = "communicationName"
            value    = {formik.values.communicationName}
            onChange = {formik.handleChange}
            className='mb_16'
         />
         {/* changed  */}
        {/* <Tags 
            suggestions = {suggestions}
            placeholder = {'Content'}
            onChange    = {handleTagChange}
            value       = {state.message || state.viewMessage}
        /> */}
        <NewTagComponent 
            suggestions = {suggestions}
            placeholder = {'Content'}
            onChange    = {handleTagChange}
            value       = {state.message || state.viewMessage}
        />
        {/* <div className='mt_6 Body14R color-neutral60'>Use <span className='color-neutral100'>“@”</span> to add custom fields like firstname,lastname etc</div> */}
        <div className='mb_52 mt_6 Body14R color-neutral60'>Use <span className='color-neutral100'>“@”</span> to add custom fields like firstname,lastname etc</div>

        <div className='Heading22M color-neutral100'>Manage Audience</div>
        <div className='bar mt_12 mb_12'></div>
        <div className='Heading16M color-neutral100'>Filter Audience</div>
        <div className='mt_6 mb_16 Body14R color-neutral60'>Filter out the audience with whom you want to send this push.</div>
        <CustomDropDown showCheckBox={true}  setFilterData={setFilterAudience} search={true} label={'Audience'}  allFilterShow={true} options={filteredOptions}  className={'w-50'} classNameB={'w-30'} />
        <div className='bar mt_20 mb_20'></div>

        <div className='Heading22M color-neutral100 mt_32'>Scheduling Option</div>
        <div className='bar mt_16 mb_12'></div>

        <FormControl className="w-100" >
            <RadioGroup 
                name     = "radio"
                value    = {formik.values.radio}
                onChange = {formik.handleChange}
                onFocus  = {formik.handleBlur}
            >
                <CustomRadio 
                    value = {"send"}
                    label = {<div className='color-neutral100 Body14R'>Send Now</div>}
                />
                <div className='mt_12 mb_12'>
                    <CustomRadio 
                        value={"save"}
                        label={<div className='color-neutral100 Body14R'>Save as drafts</div>}
                    />
                </div>
                <div className='radiotop'>
                    <CustomRadio 
                        value={"schedule"}
                        label={<div>
                            <div className='color-neutral100 Body14R'>Schedule</div>
                            <div className='color-neutral60 Body14R'>Manually select a date and time in the future to send your push.</div>
                            <CustomTextField position="start" name='schedule' onChange = {formik.handleChange} value={formik.values.schedule} className='w-92 mt_16' type={'datetime-local'} />
                        </div>}
                    />
                </div>
            </RadioGroup>
        </FormControl>

        <div className='bar mt_20 mb_20'></div>

        <div className='d-flex btncolorsms '>
            <CustomButton icon={state.processing ? <CircularProgress size={20} color={'inherit'}/> : ''} onClick={handleSubmit} btntext={formik.values.radio} />
            {template&&template.communicationName&&<div className='position-relative'>
                <CustomButton className={'ml_10'} varient="tertiary" icon={<div className='rotate-right'><SvgIcons.ViewMoreIcon color={ColorSchemeCode.themeColor}/></div>} onClick={()=>setViewMore(!viewMore)} />
                {viewMore &&  <ClickOutside className="position-relative cp" onClick={()=>setViewMore(false)}>
                    <div>
                        <div className="viewdetail cp position-absolute" style={{left : '10px', bottom:'30px', zIndex : 100}}>
                            <ViewDetail items={[{name: 'Delete', function: ()=>setDeleteModal(true)}]}/>
                        </div>
                    </div>
                </ClickOutside>}
            </div>}
        </div>

        <CustomsModal
            open      = { deleteModal }
            minWidth  = {'600px'}
            component = {<DeleteModal icon={state.deleteLoader} title="Push Notification" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
            onClose   = {()=> setDeleteModal(false)}
        />

    </div>
  )
}

export default PushCreate