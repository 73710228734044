import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/venue'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Get = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const VenueService = {
    Create : Handlers.Services(Create),
    Get    : Handlers.Services(Get),
    Update : Handlers.Services(Update),
}

export default VenueService
