import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router';

import CustomButton from '../../../components/CustomButton';
import CustomModal from '../../../components/CustomModal';
import CustomTextField from '../../../components/CustomTextField';
import CustomTextArea from '../../../components/CustomTextArea';
import {ImageModalV2} from '../../../components/ImageModalV2/ImageModalV2';
import LogoUploader from '../../../components/LogoUploader';
import DeleteModal from '../../../components/DeleteModal';

import { LayoutContext } from '../../../context/Layout.context';
import { InventoryVld } from '../../../validation';
import { Options, utils } from '../../../utils';
import { showToaster, capitalizeAll } from '../../../utils/utils';

import { FormikConsumer, useFormik } from 'formik';
import localforage, * as localForage from 'localforage'
import { InventoryService } from '../../../services';
import { CircularProgress } from '@mui/material';
import { BrandContext } from '../../../context/Brand.context';
import { AgencyContext } from '../../../context/Agency.context';


export default function AddInventory({onLoad,onClose, item}) {
    const history = useHistory();

    const [inventoryData, setInventoryData] = useState({
        edit   : item._id ? true : false,
        product: item,
    })
    
    const [state, setstate] = useState({
        apiError    : '',
        loader      : false,
        deleteLoader: false,
        deleteModal : false,
    })
    
    const [imageModal, setImageModal]    = useState(
        {
            logo              : {open: false, imageEvent: '', return: ''},
        })
        
        const layout = useContext(LayoutContext)
        const brand  = useContext(BrandContext)
        const agency = useContext(AgencyContext)
        
  
    const initState = {
        brandLogo    : inventoryData.product && inventoryData.product.image ||'',
        brandLogoFile: '',
        prefix       : inventoryData.product && inventoryData.product.productCode || '',
        name         : inventoryData.product && inventoryData.product.name ||'',
        price        : inventoryData.product && inventoryData.product.price ||'',
        description  : inventoryData.product && inventoryData.product.description || '',
    }

    useEffect(()=>{
        !inventoryData.edit && formik.setValues({...formik.values, prefix : utils.getRandomString(3).toUpperCase()})
    },[])



    const handleSubmit = async() =>{
        setstate({...state, loader: true});

        if(formik.values.brandLogoFile == "" && formik.values.brandLogo == ""){
            showToaster({
                position : 'top-right',
                title    : 'Missing Product Logo',
                message  : 'Product Logo is required',
                severity : 'error'
            })
        }
        else{
            const user = await localForage.getItem('user')
            const desiredPath = `${user._id}/${formik.values.productName}`

            let logo
            if(formik.values.brandLogoFile && !formik.errors.brandLogoFile)
            {
                logo = await utils.uploadImage({file: formik.values.brandLogoFile, desiredPath})
                if(logo.error) return setstate({...state, loader: false, apiError: logo.error.message})
            }

            let payload = {
                name        : formik.values.name.toLowerCase(),
                description : formik.values.description,
                image       : logo && logo.response.data,
                price       : formik.values.price,
                agencyId    : agency._id,
                brandId     : brand._id
            }

            if(!inventoryData.edit){
                payload.prefix = formik.values.prefix.toUpperCase()
                const {response , error} = await InventoryService.Create({toaster: true, payload})
                if(error) setstate({...state, loader: false, apiError: error.error})
                else{
                    let inventory = await localforage.getItem('inventory');
                    if(inventory == null) inventory = [];
                    inventory.push(response.data);
                    localforage.setItem('inventory', inventory);
                    onLoad(true);
                    onClose();
                }
                setstate({...state, loader: false})
            }
            else{
                const {response, error} = await InventoryService.Update({toaster: true, payload: {...payload, _id: inventoryData.product._id}})
                if(error) setstate({...state, loader: false, apiError: error.error})
                else{
                    let inventory = await localForage.getItem('inventory')
                    inventory.map((element, idx)=> {
                        if(element._id === inventoryData.product._id) inventory[idx] = response.data
                    })
                    localForage.setItem('inventory', inventory)
                    onLoad(true);
                    onClose();
                }
                setstate({...state, loader: false})
            }
            
        }
        setstate({...state, loader: false})

    }
    
    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : InventoryVld.Create,
        // isInitialValid   : inventoryData && inventoryData.edit,
        isInitialValid     : false,
        enableReinitialize : true
    })
    
    const handlePriceChange = (e) => {
        if(e.target.value >= 0) {
            formik.setValues({...formik.values, price : e.target.value})
        }
    }

    const handleProductDelete = async() => {
        setstate({...state, deleteLoader: true})
        const {response, error} = await InventoryService.Update({toaster: true, payload: {delete: true, _id: inventoryData.product._id}})
        if(response.data){
            let inventory = await localforage.getItem('inventory');
            let newInventory = inventory.filter((element)=>{
               return element._id !== response.data._id
            })
            localforage.setItem('inventory', newInventory);
        }        
        if(error) setstate({...state, loader: false, apiError: error.error})
        else{ 
            onClose(); 
            onLoad(true);
            setstate({...state, deleteModal : false})
        }
        setstate({...state, deleteLoader: false})
    }

    return (
        <div id="AddInventory">
            <section>
                <form onSubmit={formik.handleSubmit}>
                    <div className="modalComponent">
                        <LogoUploader
                            src       = {formik.values.brandLogoFile ? URL.createObjectURL(formik.values.brandLogoFile) : formik.values.brandLogo}
                            label     = "Product Logo"
                            className = "mt_16 pl_0 col-md-12 mb_16 z-index-100"
                            onChange  = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                            error     = {formik.values.brandLogoFile && formik.errors.brandLogoFile}
                            btntext   = "Upload Logo"
                        />
                        <div className="error">{formik.errors.brandLogoFile}</div>

                        <div>
                            <CustomTextField 
                                label      = "Product ID Prefix"
                                className  = "w-100"
                                name       = "prefix"
                                value      = {formik.values.prefix}
                                disabled   = {true}
                                onChange   = {formik.handleChange}
                                inputProps = {{onFocus: inventoryData.edit && formik.handleBlur, maxLength : 3 }}
                                error      = {formik.touched.prefix && formik.errors.prefix}
                                helperText = {formik.touched.prefix && formik.errors.prefix ? formik.errors.prefix : ''}
                            />
                        </div>

                        <div className="mt_16">
                            <CustomTextField 
                                label      = "Product Name"
                                className  = "w-100"
                                name       = "name"
                                value      = {formik.values.name}
                                onChange   = {formik.handleChange}
                                inputProps = {{onFocus: formik.handleBlur }}
                                error      = {formik.touched.name && formik.errors.name}
                                helperText = {formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                            />
                        </div>

                        <div className="mt_16">
                            <CustomTextField 
                                label      = "Product Price"
                                className  = "w-100"
                                name       = "price"
                                type       = "number"
                                value      = {formik.values.price}
                                inputProps = {{onFocus: formik.handleBlur }}
                                onChange   = {(e)=>handlePriceChange(e)}
                                error      = {formik.touched.price && formik.errors.price}
                                helperText = {formik.touched.price && formik.errors.price ? formik.errors.price : ''}
                                icon       = {<>$</>}
                                position   = "start"
                            />
                        </div>
                        <div className="mt_16 mb_24">
                            <CustomTextArea 
                                label      = "Description"
                                className  = "w-100"
                                name       = "description"
                                value      = {formik.values.description}
                                onChange   = {formik.handleChange}
                                inputProps = {{onFocus: formik.handleBlur }}
                                error      = {formik.touched.description && formik.errors.description}
                                helperText = {formik.touched.description && formik.errors.description ? formik.errors.description : ''}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-flex-end modalButton">
                        <div>
                            {inventoryData.edit && <CustomButton 
                                btntext = {"Delete"}
                                // varient = "warningSecondary"
                                onClick = {()=>setstate({...state, deleteModal : true})}
                            />}
                            <CustomButton 
                                btntext   = {"Cancel"}
                                varient   = "secondary"
                                className = "ml_8"
                                onClick   = {onClose}
                            />
                            <CustomButton 
                                btntext   = {inventoryData.edit ? "Save Changes" : "Add Item"}
                                disabled  = {state.loader || !formik.isValid}
                                onClick   = {handleSubmit}
                                icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                                className = "ml_8"
                            />
                        </div>
                    </div>

                </form>
            </section>

            <CustomModal
                open      = { imageModal.logo.open }
                onClose   = { ()=> setImageModal({...imageModal, logo: {...imageModal.logo, open: false}}) }
                minWidth  = "470px"
                minHeight = "470px"
                padding   = "0px"
                component = { 
                    <ImageModalV2 
                        imageModal    = { imageModal }
                        setImageModal = { setImageModal }
                        image         = { 'logo' }
                        formik        = { formik }
                    />
                }
            />  

            <CustomModal 
                open      = { state.deleteModal }
                minWidth  = {'600px'}
                component = {<DeleteModal icon={state.deleteLoader} title="sub brand" onCancel={()=> setstate({...state, deleteModal : false})} onDelete={handleProductDelete}/>}
                onClose   = {()=> setstate({...state, deleteModal : false})}
            />

        </div>
        
    )
}
