import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import CustomTab from '../../../components/CustomTab';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { SvgIcons } from '../../../icons';

export default ({setstate, state, onLayout, toggle, setToggle}) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const [tab, changeTab] = useState(0)
  const components       = [<Triggers onDragStart={onDragStart} setstate={setstate} state={state} />, <Conditions onDragStart={onDragStart} setstate={setstate} state={state} />, <Actions onDragStart={onDragStart} setstate={setstate} state={state} />]
  const labels           = ['Triggers', 'Conditions', 'Actions']


  return (
    <>
    <div className="xyz" id="mySidebar" style={{height: '100vh'}}>
      <div className="rightCollapse cp" id="collapse" onClick={()=>{
        if(toggle){
          document.getElementById        ("collapse").style.width      = "41px";
          document.getElementById        ("mySidebar").style.width      = "0";
          document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
          document.getElementById        ("flowControl").style.left     = "80px";
          // document.getElementsByClassName('react-flow__controls')[0].style.left = "80px !important"
        }
        else{
          document.getElementById        ("collapse").style.width      = "340px";
          document.getElementById        ("mySidebar").style.width      = "300px";
          document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
          document.getElementById        ("flowControl").style.left     = "380px";
          // document.getElementsByClassName('react-flow__controls')[0].style.left = "380px !important"
        }
        setToggle(!toggle)
      }}>
          <div className="p_8 w-fit-content-content" style={{border: '1px solid '+ColorSchemeCode.OtherBorder, borderLeft: 'none', boxShadow    : '0px 8px 20px rgba(4, 24, 62, 0.08)', borderRadius : '0px 4px 4px 0px'}}>
            <SvgIcons.FlowCollapseIcon />
          </div>
        </div>
      <div className="d-flex space-between align-items-center">
        <div className="m_24 Heading22M color-neutral100" >Blocks</div>
        <div className="d-flex">
          <div>
            <IconButton onClick={()=>onLayout('LR')}>
              <SvgIcons.HorizontalIcon />
            </IconButton>
          </div>
          <div>
            <IconButton onClick={()=>onLayout('TB')}>
              <SvgIcons.VerticalIcon />
            </IconButton>
          </div>
        </div>

      </div>
      <div>
        <CustomTab 
            selectedTab = {tab}
            changeTab   = {changeTab}
            labels      = {labels}
            components  = {components}
            flowbuilder
        /> 
      </div>
      {/* <div className="dndnode mb_16 cursorgrab" onDragStart={(event) => {setstate({...state, data: 'visit'}); onDragStart(event, 'default')}} draggable>
        <div className="Heading16M color-neutral100 mb_4">Visit</div>
        <div className="Body14R color-neutral60">Triggers when somebody visits</div>
      </div>
      <div className="dndnode mb_16 cursorgrab" onDragStart={(event) => {setstate({...state, data: 'point'}); onDragStart(event, 'default')}} draggable>
        <div className="Heading16M color-neutral100 mb_4">Point</div>
        <div className="Body14R color-neutral60">Triggers when somebody earn point</div>
      </div>
      <div className="dndnode mb_16 cursorgrab" onDragStart={(event) => {setstate({...state, data: 'deal'}); onDragStart(event, 'default')}} draggable>
        <div className="Heading16M color-neutral100 mb_4">Deal</div>
        <div className="Body14R color-neutral60">Triggers when somebody avail deal</div>
      </div> */}
    </div>
    </>
  );
};



const Triggers = ({setstate, state, onDragStart}) => {
  return(
    <div>
      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'checkIn', type: 'checkIn'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.CheckInIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">Check In</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>
      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'signUp', type: 'signUp'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.SignUpIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">Sign Up</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>
    </div>
  )
}

const Conditions = ({setstate, state, onDragStart}) => {
  return(
    <div>
      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'visit', type: 'visit'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.VisitIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">On Visit</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>

      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'point', type: 'point'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.PointIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">Points</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>

      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'event', type: 'event'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.EventIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">On Event</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>
      
      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'onetime', type: 'onetime'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.OneTimeIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">One Time</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>
      
      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'expire', type: 'expire'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.ExpirationIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">After Expiration</div>
          <div className="Caption12R color-neutral60">Check if deal has passed it’s expiration date.</div>
        </div>
      </div>
      
      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'action', type: 'action'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.ConditionalActionIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">Conditional Action</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>
    </div>
  )
}

const Actions = ({setstate, state, onDragStart}) => {
  return(
    <div>
      <div className="d-flex m_16 pt_16 pr_12 pb_16 pl_12 borderRadius-4 cursorgrab sidebarHover" onDragStart={(event) => {setstate({...state, data: 'deal', type: 'deal'}); onDragStart(event, 'default')}} draggable>
        <div className="ml_12 mr_12"><SvgIcons.GripIcon /></div>
        <div><SvgIcons.GiveDealIcon /></div>
        <div className="ml_12">
          <div className="Link14M color-neutral100 mb_4">Give Deals</div>
          <div className="Caption12R color-neutral60">Triggers when somebody checks in the store.</div>
        </div>
      </div>
    </div>
  )
}