import React from 'react'
import CustomButton from '../../components/CustomButton'
import { PngIcons } from '../../icons'
import { utils } from '../../utils'
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router-dom'
import localforage from 'localforage';

function LandingPageCongrats() {
    const history = useHistory();
    const params = useParams()

    window.onbeforeunload = function (e) {
        if (e) e.returnValue = history.push('/page/'+params.growthtoolId)
        return history.push('/page/'+params.growthtoolId);
    };

    return (
        <div className="ml_24 mr_24 position-relative" style={{height: '100vh'}}>
            <div className="w-100 middle" style={{height: '80vh'}}>
                <img src={PngIcons.Confetti} alt="congrats" className="middle" height="96px" width="auto" />
                <div className="mt_24 Heading18M color-GeneralBlack text-align-center" style={{width: '270px'}}>{'Congrats '+utils.capitalizeAll(history.location.state.transaction.customerName)+'! you have \n\r been rewarded '+history.location.state.transaction.loyaltyCard.points+' points.'}</div>
            </div>
            <div className="w-100 position-absolute" style={{bottom: '60px'}}>
                <CustomButton btntext="Go to Dashboard" className="w-100" height="48px" onClick={()=>{
                        localforage.removeItem('deals');
                        localforage.removeItem('email');
                        history.push({pathname: '/page/'+params.growthtoolId});
                    }}/>
            </div>
        </div>
    )
}

export default LandingPageCongrats
