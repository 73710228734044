import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams , useLocation} from 'react-router-dom';

import { useFormik } from 'formik'

import axios from 'axios';

import CustomButton from '../../components/CustomButton'
import CustomTextField from '../../components/CustomTextField'
import CustomSelect from '../../components/CustomSelect';
import CircularProgress from '@mui/material/CircularProgress';

import { CampaignService, BrandService, PassService, TransactionService, LocationService, UserService, TierService, EarningWayService } from '../../services'

import { Campaign, CountryCode } from '../../enums'
import { PngIcons, SvgIcons } from '../../icons'
import { getContryCodes, getContryCodes2} from '../../utils/Options';
import {TemplateVld}  from '../../validation'
import {utils} from '../../utils'
import { Button } from '@mui/material';
import { showToaster } from '../../utils/utils';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import Loader from '../../components/Loader';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { AgencyApp, ResponseStatus } from '../../enums/enums';
import { AgencyContext } from '../../context/Agency.context';
import localforage from 'localforage';
import VenueService from '../../services/Venue';
import CoalitionService from '../../services/Coalition.service';

function LandingPageDefault(props) {

    const agency = useContext(AgencyContext);
    const params  = useParams()

    const [state, setState] = useState({
        loader          : false,
        show            : false,
        apiError        : '',
        passCode        : '',
        points          : '',
    })

    const useQueryString = () => {
        const location = useLocation();
        return new URLSearchParams(location.search);
    }

    const queryString = useQueryString();

    const initState = {
        // fullName  : '',
        firstName  : '',
        lastName   : '',
        email      : '',
        dialingCode: '',
        phone      : '',
    }
  
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [selectedBrand, setSelectedBrand]       = useState('');


    const setAddress = async () => {
        const ipAddress = await LocationService.GetIpAddress()
        if(ipAddress.error) return

        const {error, response} = await LocationService.GetLocation({ipAddress: ipAddress.response.ip})
        if(error) return

        const location = {
            lat     : response.latitude,
            lng     : response.longitude,
            address : response.city + ' ' + response.country_name
        }

        return location;
    }

    const [user, setUser]                         = useState();
    const [payloadData, setPayloadData]           = useState();
    const [verificationCode, setVerificationCode] = useState();
    const [fingerprintID, setFingerprintID]       = useState();
    const [loader, setLoader]                     = useState({
        main          : false,
        existing      : false,
        resend        : false,
        disableResend : true
    });

    useEffect(() => {
        setLoader({...loader, main: true})
        if (
            navigator.platform === "iPhone" ||
            navigator.platform === "iPod" ||
            navigator.platform === "iPad" 
        ) { if(AgencyApp[agency._id]) window.location.href = AgencyApp[agency._id]}
        // Initialize an agent at application startup.
        const fpPromise = FingerprintJS.load({ token: 'rpn3S9B4PyOMKgeZWXgL' })

        // Get the visitor identifier when you need it.
        fpPromise
        .then(fp => fp.get())
        .then(async (result) => {
            setFingerprintID(result.visitorId)
            await getUser(result.visitorId)
        })

    }, [])

    

    
    const handleChange = async (e, name) => {
        formik.setValues({...formik.values, [name]: e.target.value})
    }
    
    const getCampaign = async () => {
        const campaignId = params.campaignId
        const query = {_id: campaignId};
        const {error, response} = await CampaignService.GetPublicCampaign({query})
        if(response && response.data) setSelectedCampaign(response.data)
        return error
    }
    
    const getBrand = async () => {
        // const user = await localForage.getItem('user')
        // const query = {_id: user.activeBrand}
        const query = {_id: params.brandId, delete: false}
        const {error, response} = await BrandService.GetPublic({query})
        if(response && response.data) {
            setSelectedBrand(response.data)
            await getData(response.data)
        }
        return error
    }
    
    const getData = async(brand) => {
        const subBrand = await BrandService.GetPublic({query:{_id: queryString.get('childBrandId'), delete: false}})
        const coalition = await CoalitionService.GetPublic({query:{childBrandId: queryString.get('childBrandId'), parentBrandId: subBrand.response.data.parentBrandId, agencyId: agency._id, delete: false}})
        const tiers = await TierService.GetPublic({query:{campaignId: params.campaignId}})
        const earningways = await EarningWayService.GetQueriedEarningWaysPublic({query:{campaignId: params.campaignId, activity: Campaign.Activities.EARNING.SPENDING, brandId: brand.parentBrandId}})
        
        setPayloadData({
            subBrand    : subBrand.response.data,
            coalition   : coalition.response,
            tiers       : tiers.response,
            earningWays : earningways.response
        })
    }
   
    const handleSubmit = async (dataObject) => {
        setState({...state, loader : true})
        let pass = await localforage.getItem('pass');
        let headers;
        if(selectedBrand) headers = {'x-api-key': selectedBrand.apiKey}
        // delete formik.values.VerificationCode
        if(!pass)
        {
            if(!selectedBrand || !selectedCampaign) return {success: false, message: 'missing credentials'}
            const payload = {
                // ...formik.values, 
                // firstName    : dataObject.fullName ? dataObject.fullName.split(" ")[0] : formik.values.fullName.split(" ")[0],
                // lastName     : dataObject.fullName ? dataObject.fullName.split(" ").filter((value,index)=>{if(index!==0) return value + " "}).toString().replace(',',' ') : formik.values.fullName.split(" ").filter((value,index)=>{if(index!==0) return value + " "}).toString().replace(',',' '),
                firstName    : dataObject.firstName || formik.values.firstName,
                lastName     : dataObject.lastName || formik.values.lastName,
                countryCode  : formik.values.dialingCode.split('&')[0],
                dialingCode  : formik.values.dialingCode.split('&')[1],
                phone        : dataObject.phone || formik.values.phone,
                email        : formik.values.email,
                brandId      : selectedBrand._id,
                campaignCode : selectedCampaign.campaignCode,
                fingerPrintId: dataObject.fingerprint && [fingerprintID]
            }

            const {response, error} = await PassService.Create({payload, headers});
            if(error) {
                showToaster({
                    position : 'top-right',
                    title    : error,
                    message  : error,
                    severity : 'error',
                })
                return setState({...state, apiError: error.message, loader: false})
            }
            if(response){ localforage.setItem('pass', response.data); pass = response.data; }
        }

        if(pass) {
            const location = await setAddress()
            let payload = {
                agencyId    : selectedBrand.agency.id,
                childBrandId: queryString.get('childBrandId'),
                passId      : pass._id,
                location    : location,
                payloadData : payloadData
            }

            let SelfTransaction = await TransactionService.SelfTransaction({payload, headers})
            if(SelfTransaction.response) {
                SelfTransaction.response.data.coalition.tiers.map((value, index)=>{ if(value.id === SelfTransaction.response.data.transaction.loyaltyCard.passData.currentTier.currentTierId) SelfTransaction.response.data = {...SelfTransaction.response.data, discount: value} })
                setState({...state, show: true, loader: false, passCode : pass.passCode, points: SelfTransaction.response.data.transaction[SelfTransaction.response.data.transaction.passType].points}); 
                setData(SelfTransaction.response.data); 
                setNext(4);
                setTimeout(() => {
                    setNext(3)
                }, (agency._id === 'DXpIns4NdgQ9IF0uGxvW') ? 2000 * 60 * 60 : 10000 * 60);
            }
            if(SelfTransaction.error) {
                SelfTransaction.error.data.coalition.tiers.map((value, index)=>{ if(value.id === SelfTransaction.error.data.transaction.loyaltyCard.passData.currentTier.currentTierId) SelfTransaction.error.data = {...SelfTransaction.error.data, discount: value} })
                setData(SelfTransaction.error.data); 
                setNext(6);
                setState({...state, loader: false})
            }
        }
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateonChange   : false,
        initialValues    : { ...initState },
        // onSubmit         : handleSubmit,
        validationSchema : TemplateVld.DefaultFormVld,
        isInitialValid   : false
      })
    
    const onLoad = async () => {
        await getBrand();
        await getCampaign();
    }

    const getUser = async(id) => {
        let dialing
        const visit = await axios.get('https://api.fpjs.io/visitors/'+id+'?token=yGDmYeSKRlYrsS8uhijR')
        CountryCode.map((element, idx)=> {
            if(element.name.toLowerCase() === visit.data.visits[0].ipLocation.country.name.toLowerCase() || element.code.toLowerCase() === visit.data.visits[0].ipLocation.country.code.toLowerCase()){
                dialing = element.code+'&'+element.dial_code
            }
        })


        const {response, error} = await UserService.GetByEmail({query:{ fingerPrintId: id }})
        if(error){ 
            setLoader({...loader, main: false})
            formik.setValues({...formik.values, dialingCode: dialing})
        }
        if(response){
            formik.setValues({...formik.values, 
                phone       : response.data[0].phone,
                fullName   : response.data[0].firstName + " " + response.data[0].lastName,
                // firstName   : response.data[0].firstName,
                // lastName    : response.data[0].lastName,
                email       : response.data[0].email,
                dialingCode : dialing
            })
            setNext(1)
            setLoader({...loader, main: false})
        }
    }

    const handleExisting = async () => {
        setLoader({...loader, existing: true})
        const { response, error } = await UserService.FingerPrint({query: {email: formik.values.email, fingerPrintId: fingerprintID}})
        if(error){ 
            setLoader({...loader, existing: false})
        }
        if(response){
            if(response.message === 'user verified'){
                
                formik.setValues({...formik.values, 
                    dialingCode : response.data[0].countryCode+'&'+response.data[0].dialingCode,
                    phone       : response.data[0].phone,
                    // fullName   : response.data[0].firstName + " " + response.data[0].lastName,
                    firstName   : response.data[0].firstName,
                    lastName    : response.data[0].lastName
                })
                await handleSubmit({fingerprint: true, 
                    firstName: response.data[0].firstName, lastName: response.data[0].lastName, 
                    // fullName   : response.data[0].firstName + " " + response.data[0].lastName,
                    phone: response.data[0].phone, dialingCode: response.data[0].countryCode+'&'+response.data[0].dialingCode})
            }
            else {
                formik.setValues({...formik.values, 
                    dialingCode : response.data.countryCode+'&'+response.data.dialingCode,
                    phone       : response.data.phone,
                    fullName   : response.data.firstName + " " + response.data.lastName,
                    // firstName   : response.data.firstName,
                    // lastName    : response.data.lastName
                })
                setUser(response.data)
                showToaster({position: 'bottom-right', title: 'code sent', message:  'code sent' , severity: 'success'})
                setVerificationCode(response.data.verificationCode)
                setNext(2)
                // setTimeout(() => {
                //     setLoader({...loader, disableResend: false})
                // }, 30000);
            }
            setLoader({...loader, existing: false})
        }
    }
 
    useEffect(onLoad, [])

    const [next, setNext]       = useState(0)
    const [seemore, setSeemore] = useState(false)
    const [data, setData]       = useState()

    const monthNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    return (
        <div id="LandingPageDefault" style={{height: '95vh'}}>
            {loader.main ? <Loader width={120} height={120} />
            :
            <div className="" style={{height: '90vh'}}>
            {next===0 &&
                // <form onSubmit={formik.handleSubmit} className="d-flex col-md-8 col-12 middle" style={{height: '90vh'}}>
                <form onSubmit={formik.handleSubmit} className="d-flex col-md-8 col-12 middle" style={{marginTop: '32px', overflow: 'hidden'}}>
                    <div className="profileImage mb_16">
                        {   selectedBrand.brandLogo ?
                            <img src={selectedBrand.brandLogo} width={48} height={48} className="borderRadius-40" alt=""></img> :
                            utils.getAvatar({firstName: selectedBrand.brandName, width: '48px', heigth: '48px'})
                        }
                    </div>
                    <div className="Heading22M color-neutral100 mb_8">Check In</div>
                    <div className="Body14R color-neutral60">Please enter your information</div>
                    <div className="w-60 inputBox mt_48 row">
                        <div className="col-6 pl_0">
                            <CustomTextField 
                                label      = "First Name"
                                className  = "w-100"
                                name       = "firstName"
                                // name       = "fullName"
                                height     = "44px"
                                variant    = "standard"
                                InputProps = {{ disableUnderline: false, style: { fontSize: 16,color: 'color(neutral80)'} }}
                                value      = {formik.values.firstName}
                                onChange   = {(e) => {handleChange(e,'firstName')}}
                                inputProps = {{ onFocus: formik.handleBlur }}
                                error      = {formik.touched.firstName && formik.errors.firstName}
                                helperText = {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
                            />
                        </div>
                        <div className="col-6 pr_0">
                            <CustomTextField 
                                label      = "Last Name"
                                className  = "w-100"
                                name       = "lastName"
                                // name       = "fullName"
                                height     = "44px"
                                variant    = "standard"
                                InputProps = {{ disableUnderline: false, style: { fontSize: 16,color: 'color(neutral80)'} }}
                                value      = {formik.values.lastName}
                                onChange   = {(e) => {handleChange(e,'lastName')}}
                                inputProps = {{ onFocus: formik.handleBlur }}
                                error      = {formik.touched.lastName && formik.errors.lastName}
                                helperText = {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
                            />
                        </div>
                    </div>

                    <div className="mt_24 w-60 inputBox">
                        <CustomTextField 
                            label      = "Email Address"
                            className  = "w-100"
                            height     = "44px"
                            name       = "email"
                            value      = {formik.values.email}
                            onChange   = {(e) => {handleChange(e,'email')}}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            error      = {formik.touched.email && formik.errors.email}
                            helperText = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                        />
                    </div>
                    <div className="mt_24 w-60 d-flex space-between inputBox align-items-center">
                        <div className="w-40">
                            <CustomSelect 
                                label      = "Phone"
                                options    = {getContryCodes2()}
                                name       = "dialingCode"
                                height     = "44px"
                                onChange   = {(e)=>{formik.setValues({...formik.values, dialingCode: e.target.value})}}
                                value      = {formik.values.dialingCode}
                                error      = {formik.touched.dialingCode && formik.errors.dialingCode}
                                helperText = {formik.touched.dialingCode && formik.errors.dialingCode ? formik.errors.dialingCode : ''}
                            />
                        </div>

                        <CustomTextField 
                            className  = "w-55"
                            height     = "44px"
                            name       = "phone"
                            type       = "number"
                            value      = {formik.values.phone}
                            onChange   = {(e) => {handleChange(e,'phone')}}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            // error      = {formik.touched.phone && formik.errors.phone}
                            // helperText = {formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}

                        />
                    </div>
                    {formik.errors.phone && <div className="error mt_8">{formik.errors.phone}</div>}
                    <div className="mt_32 w-60 inputBox d-flex justify-content-right">
                        {/* <CustomButton 
                            onClick = {()=>setNext(1)}
                            btntext = "Existing Customer"
                            varient = "secondary"
                            /> */}
                            <Button 
                                className = "w-100 color-white bg-color-GeneralBlack capitalize"
                                onClick   = {()=>handleSubmit({fingerprint: true})}
                                disabled  = {state.loader}
                                style     = {{height: '48px'}}
                                >{state.loader ? <div className="d-flex align-items-center"><CircularProgress size={20} color={'inherit'}/><div className="ml_6 capitalize Link14M pt_6 pb_6 pl_8 pr_8">Continue</div></div> : <div className="capitalize Link14M pt_6 pb_6 pl_8 pr_8">Continue</div>}</Button>
                        {/* <CustomButton 
                            onClick = {()=>handleSubmit({fingerprint: true})}
                            btntext = "Continue"
                            // onClick = {handleNew}
                            disabled  = {state.loader || !formik.isValid}
                            icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                        /> */}
                    </div>
                </form>
            }    
            {next===1 &&
                <form onSubmit={formik.handleSubmit} className="d-flex col-md-8 col-12 middle" style={{marginTop: '32px', overflow: 'hidden'}}>
                    <div className="profileImage mb_16">
                        {   selectedBrand.brandLogo ?
                            <img src={selectedBrand.brandLogo} width={48} height={48} className="borderRadius-40" alt=""></img> :
                            utils.getAvatar({firstName: selectedBrand.brandName, width: '48px', heigth: '48px'})
                        }
                    </div>
                    <div className="Heading22M color-neutral100 mb_8">Check In</div>
                    <div className="Body14R color-neutral60">Please confirm your email</div>

                    <div className="mt_24 w-60 inputBox">
                        <CustomTextField 
                            label      = "Email Address"
                            height     = "44px"
                            className  = "w-100"
                            name       = "email"
                            value      = {formik.values.email}
                            onChange   = {(e) => {handleChange(e,'email')}}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            error      = {formik.touched.email && formik.errors.email}
                            helperText = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                        />
                    </div>
                    <div className="mt_32 w-60 inputBox d-flex justify-content-right">
                        {/* <CustomButton 
                            onClick={()=>setNext(0)}
                            btntext   = "New Customer"
                            varient   = "secondary"
                        /> */}
                        <Button 
                            className = "w-80 color-white bg-color-GeneralBlack capitalize"
                            onClick   = {handleExisting}
                            style     = {{height: '48px'}}
                            disabled  = {loader.existing}
                        >{loader.existing ? <div className="d-flex align-items-center"><CircularProgress size={20} color={'inherit'}/><div className="ml_6 capitalize Link14M pt_6 pb_6 pl_8 pr_8">Continue</div></div> : <div className="capitalize Link14M pt_6 pb_6 pl_8 pr_8">Continue</div>}</Button>
                        
                        <div className="ml_8 pt_4 pl_4 pb_4 pr_4 fs-14 cp middle borderRadius-6 bg-color-themeColor" style={{color: 'white'}} onClick={async()=>{
                            let pass = await localforage.getItem('pass');
                            const {response, error} = await UserService.UpdatePublic({payload:{_id: pass.userId, deleteFingerPrintId : true}})
                            if(response) {
                                localforage.removeItem('pass')
                                setNext(0)
                            }
                        }}>New User</div>
                        {/* <CustomButton 
                            onClick  = {handleExisting}
                            btntext  = "Continue"
                            disabled = {loader.existing}
                            icon     = {loader.existing && <CircularProgress size={20} color={'inherit'}/>}
                        /> */}
                    </div>
                </form>
            }    
            {/* {next===2 &&
                <form onSubmit={formik.handleSubmit} className="col-md-8 col-12 middle">
                    <div className="profileImage mb_16">
                        {   selectedBrand.brandLogo ?
                            <img src={selectedBrand.brandLogo} width={48} height={48} className="borderRadius-40" alt=""></img> :
                            utils.getAvatar({firstName: selectedBrand.brandName, width: '48px', heigth: '48px'})
                        }
                    </div>
                    <div className="Heading22M color-neutral100 mb_8">Enter verification code</div>
                    <div className="Body14R color-neutral60">Code sent to (Phone number)</div>

                    <div className="mt_24 w-60 inputBox">
                        <CustomTextField 
                            label     = "Verification Code"
                            className = "w-100"
                            name      = "VerificationCode"
                            value     = {formik.values.VerificationCode}
                            onChange  = {(e) => {
                                handleChange(e,'VerificationCode')
                            }}
                            inputProps   = {{ onFocus: formik.handleBlur }}
                            error        = {formik.touched.VerificationCode && formik.errors.VerificationCode}
                            helperText   = {formik.touched.VerificationCode && formik.errors.VerificationCode ? formik.errors.VerificationCode : ''}
                        />
                    </div>
                    <div className="mt_40 w-60 inputBox d-flex space-between">
                        <CustomButton 
                            onClick  = {async()=>{
                                setLoader({...loader, resend: true})
                                const {response, error} = await UserService.ResendCode({query: {dialingCode: formik.values.dialingCode, phone: formik.values.phone}, toaster: true})
                                if (response) setVerificationCode(response.data.verificationCode)
                                setLoader({...loader, resend: false})
                            }}
                            btntext  = "Resend"
                            disabled = {loader.resend || loader.disableResend}
                            icon     = {loader.resend && <CircularProgress size={20} color={'inherit'}/>}
                        />
                        <CustomButton 
                            onClick  = {async()=>{
                                if(formik.values.VerificationCode.toString() === verificationCode.toString()){
                                    await UserService.UpdatePublic({payload: {_id: user._id, fingerPrintId: fingerprintID}})
                                    await handleSubmit({})
                                }
                                else showToaster({position: 'bottom-right', title: 'wrong code', message:  'wrong code' , severity: 'error'})
                            }}
                            btntext  = "Continue"
                            disabled = {state.loader}
                            icon     = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                        />
                        
                    </div>
                </form>
            }     */}
            {next===3 &&
                <div>
                    {/* <div className="middle Link13M" style={{padding: '8px 24px 8px 24px', color: '#428A52', backgroundColor: '#EBF5ED'}}>To redeem these offer go to the cashier and show them your screen.</div> */}
                    <div className="d-flex align-items-center space-between pl_16 pr_16 pb_4 pt_16" style={{backgroundColor: '#F7F7F7'}}>
                        <div className="Heading22M color-GeneralBlack">{"Hi "+utils.capitalizeAll(data.transaction.customerName)+" 👋"}</div>
                        {   selectedBrand.brandLogo ?
                            <img src={selectedBrand.brandLogo} width={32} height={32} className="borderRadius-40" alt=""></img> :
                            utils.getAvatar({firstName: selectedBrand.brandName, width: '32px', heigth: '32px'})
                        }
                    </div>
                    <div className="d-flex align-items-center pl_16 pr_16 pb_16" style={{backgroundColor: '#F7F7F7'}}>
                        <SvgIcons.Point />
                        <div className="Heading18M color-neutral100 ml_8 mr_16">{data.transaction.loyaltyCard.passData.currentPoints+" Points"}</div>
                        <SvgIcons.RefreshIcon height="16px" width="16px"/>
                    </div>
                    <div className="bar"></div>
                    {data.transaction.loyaltyCard.benefits && data.transaction.loyaltyCard.benefits.dealOffer && data.transaction.loyaltyCard.benefits.dealOffer.name && 
                    <div className="p_16">
                        <div className="borderRadius-6 mb_16" style={{ position: 'relative', width: '100%', paddingTop: '58.54%', backgroundImage: "url('"+data.transaction.loyaltyCard.benefits.dealOffer.image+"')",backgroundPosition: 'center', backgroundSize: 'cover', filter: 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.16))'}}></div>
                        {/* <img src={data.transaction.loyaltyCard.benefits.dealOffer.image} alt="deal" height="auto" width="327px" className="borderRadius-6 mb_16 w-100" style={{filter: 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.16))'}} /> */}
                        <div className="Link13M color-neutral60 mb_4">{utils.capitalizeAll(data.subBrand.brandName)}</div>
                        <div className="Heading16M color-neutral100">{data.transaction.loyaltyCard.benefits.dealOffer.name}</div>
                    </div>}
                    <div className="mb_16 pl_16 pr_16 mt_16">
                        <div className="Heading22M color-neutral100">Earn Points</div>
                        <div className="Link13R color-neutral60 mt_4">On every visit you will earn points</div>
                    </div>
                    <div className="pl_16 pr_16 mb_24">
                        <div className="w-100 d-flex space-between" style={{backgroundColor: '#F5F5F5', border: '1px solid #E3E3E3', borderRadius: '4px'}}>
                            <div className="Link13M color-GeneralBlack m_16">{data.pointsPerVisit}</div>
                            <div className="Link13M color-GeneralBlack m_16">/per visit</div>
                        </div>
                    </div>
                    {/* <CustomTextField disabled value={'data.pointsPerVisit'} icon={<div>/per visit</div>} position={"end"} className="w-100 pl_16 pr_16 mb_40" /> */}
                    <div className="mb_16 pl_16 pr_16">
                        <div className="Heading22M color-neutral100">Discounts</div>
                        <div className="Link13R color-neutral60 mt_4">These discounts will apply on your purchase.</div>
                    </div>

                    <div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                        <div className="Link13M color-neutral100">{monthNames[new Date().getDay()-1]}</div>
                        <div className="Link13M color-neutral100">{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[new Date().getDay()]+" % OFF"}</div>
                    </div>
                    <div className="bar"> </div>
                    {
                        seemore && 
                        <>
                            {new Date().getDay() !== 1 &&<><div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                <div className="Link13M "style={{color: '#BFBFBF'}}>Monday</div>
                                <div className="Link13M "style={{color: '#BFBFBF'}}>{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[0]+" % OFF"}</div>
                            </div>
                            <div className="bar"> </div></>}
                            {new Date().getDay() !== 2 &&<><div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                <div className="Link13M " style={{color: '#BFBFBF'}}>Tuesday</div>
                                <div className="Link13M "style={{color: '#BFBFBF'}}>{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[1]+" % OFF"}</div>
                            </div>
                            <div className="bar"> </div></>}
                            {new Date().getDay() !== 3 &&<><div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                <div className="Link13M " style={{color: '#BFBFBF'}}>Wednesday</div>
                                <div className="Link13M " style={{color: '#BFBFBF'}}>{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[2]+" % OFF"}</div>
                            </div>
                            <div className="bar"> </div></>}
                            {new Date().getDay() !== 4 &&<><div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                <div className="Link13M "style={{color: '#BFBFBF'}}>Thursday</div>
                                <div className="Link13M "style={{color: '#BFBFBF'}}>{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[3]+" % OFF"}</div>
                            </div>
                            <div className="bar"> </div></>}
                            {new Date().getDay() !== 5 &&<><div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                <div className="Link13M "style={{color: '#BFBFBF'}}>Friday</div>
                                <div className="Link13M "style={{color: '#BFBFBF'}}>{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[4]+" % OFF"}</div>
                            </div>
                            <div className="bar"> </div></>}
                            {new Date().getDay() !== 6 &&<><div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                <div className="Link13M "style={{color: '#BFBFBF'}}>Saturday</div>
                                <div className="Link13M "style={{color: '#BFBFBF'}}>{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[5]+" % OFF"}</div>
                            </div>
                            <div className="bar"> </div></>}
                            {new Date().getDay() !== 7 &&<><div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                <div className="Link13M "style={{color: '#BFBFBF'}}>Sunday</div>
                                <div className="Link13M "style={{color: '#BFBFBF'}}>{data.discount.benefits.discount.discountValue ? data.discount.benefits.discount.discountValue : data.discount.benefits.discount.customDiscounts[6]+" % OFF"}</div>
                            </div>
                            <div className="bar"> </div></>}
                        </>
                    }
                    <div className="d-flex align-items-center middle mt_8 mb_24" onClick={()=>setSeemore(!seemore)}>
                        <div className="d-flex align-items-center mb_24">
                            <div className="Link13M color-neutral100">{seemore ? 'See Less' : 'See All'}</div>
                            <div className={seemore ? "rotate-right ml_8" : "rotate-left ml_8"}><SvgIcons.ArrowExpand/></div>
                        </div>
                    </div>
                </div>
            }
            {/* show after redeem for 10 minutes */}
            {next === 4 &&
                <div>
                    <div className="d-flex justify-content-end mr_24 mt_24" style={{height: '56px'}}>
                        <div className="timer-wrapper">
                            <CountdownCircleTimer
                            isPlaying
                            duration    = {(agency._id === 'DXpIns4NdgQ9IF0uGxvW') ? 2 * 60 * 60 : 10 * 60}
                            // colors      = {[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                            colors      = {'#459157'}
                            onComplete  = {() => [true, 1000]}
                            size        = {24}
                            strokeWidth = {4}
                            >
                            {/* {renderTime} */}
                            </CountdownCircleTimer>
                        </div>
                    </div>
                    {data.transaction.loyaltyCard.benefits.dealOffer && data.transaction.loyaltyCard.benefits.dealOffer.image && data.transaction.loyaltyCard.benefits.dealOffer.isAvailDeal === false && <div className="ml_16 mr_16 borderRadius-8 mb_16" style={{background: '#F5F5F5', border: '1px solid #E3E3E3', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(155, 155, 155, 0.1)', padding: '12px 16px'}}>
                        <div>
                            {/* <img src={data.transaction.loyaltyCard.benefits.dealOffer.image}
                            alt="deal"
                            width="311px"
                            // height="112px"
                            height="auto"
                            className="borderRadius-4 mb_8"
                            /> */}
                            <div className="borderRadius-4 mb_8" style={{ position: 'relative', width: '100%', paddingTop: '58.54%', backgroundImage: "url('"+data.transaction.loyaltyCard.benefits.dealOffer.image+"')",backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                            <div className="d-flex align-items-center space-between">
                                <div className="Link13M color-GeneralBlack">{data.transaction.loyaltyCard.benefits.dealOffer.name}</div>
                                <Button onClick={()=>setNext(5)} className="Link13M" style={{color: "white", backgroundColor: '#459157', borderRadius: '4px', textTransform: 'capitalize'}}><div className="Link13M">Show</div></Button>
                            </div>
                        </div>
                    </div>}
                    <div className="w-100 middle mt_24">
                        <img src={PngIcons.Confetti} alt="congrats" className="middle" height="96px" width="auto" />
                        <div className="mt_24 Heading18M color-GeneralBlack text-align-center" style={{width: '270px'}}>{'Congrats '+utils.capitalizeAll(data.transaction.customerName)+'! you have \n\r been rewarded '+data.pointsPerVisit+' points.'}</div>
                    </div>
                </div>
            }
            {next === 5 &&
                <div className="w-100">
                    <div className="position-relative">
                        {/* <img src={data.transaction.loyaltyCard.benefits.dealOffer.image} 
                            height="auto"
                            width="100%"
                            style={{background: 'linear-gradient(175.49deg, rgba(0, 0, 0, 0.58) 21.49%, rgba(0, 0, 0, 0) 47.86%)'}}
                        /> */}
                        <div className="" style={{ position: 'relative', width: '100%', paddingTop: '58.54%', backgroundImage: "url('"+data.transaction.loyaltyCard.benefits.dealOffer.image+"')",backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                        <div className="cp" onClick={()=>setNext(4)} style={{position: 'absolute', top: '24px',left: '16px'}}><SvgIcons.CrossIcon color="white"/></div>
                    </div>
                    <div className="middle Link13M" style={{padding: '8px 24px 8px 24px', color: '#428A52', backgroundColor: '#EBF5ED'}}>Show the staff member at the counter when paying to avail this deal.</div>
                    <div style={{fontWeight: '700 !important'}} className="Heading22M mr_16 ml_16 mt_16 color-GeneralBlack">{data.transaction.loyaltyCard.benefits.dealOffer.name}</div>
                    <div className="d-flex mr_16 ml_16 mt_8">
                        <div className="Link13R mr_8" style={{color: '#1863C9'}}>{utils.capitalizeAll(data.subBrand.brandName)}</div>
                        <div className="Link13R" style={{color: 'black'}}>{utils.capitalizeAll(data.subBrand.locationType)}</div>
                    </div>
                    <div className="mt_16 Link13R mr_16 ml_16" style={{color: '#8C8B91', lineHeight: '22px !important'}}>{data.transaction.loyaltyCard.benefits.dealOffer.dealDescription}</div>
                </div>
            }
            {/* show 24 hour error */}
            {next === 6 &&
                <div>
                    <div className="ml_24 mt_24 cp" onClick={()=>setNext(3)}><SvgIcons.CrossIcon color="black"/></div>
                    <div className="w-100 middle" style={{marginTop:'48px'}}>
                    {/* <div className="w-100 middle" style={{height:'80vh'}}> */}
                        <img src={PngIcons.Alarm} alt="congrats" className="middle" height="88px" width="auto" />
                        <div className="ml_24 mr_24 mt_24 Heading20M color-GeneralBlack text-align-center" >You have already check-in, <br/>Please visit us after 24 hour</div>
                    </div>
                </div>
            }
                <div className="col-md-4 col-12 pl_0 pr_0 imageSection">
                    <img className="sideImage" src={PngIcons.landingPageImage} alt=""/>
                </div>
            </div>
            }

        </div>
    )
}



// {/* {state.show &&    
//     <CustomsModal    
//         component = {<CongratulationScreen onClose={()=>setState({...state, show : false})} points = {state.points} passCode  = {state.passCode}/>}
//             open      = {state.show}
//             maxWidth  = {'70%'}
//             minWidth  = {'70%'}
//             onClose   = {()=>setState({...state, show: false})}
//         />
// } */}
const CongratulationScreen = ({onClose, passCode, points}) => {


    const history = useHistory();

    const handleClick = (e) => {
        // onClose()
        // history.push({pathname: `/pass/${passCode}`})
    }

    return(
        <div className="middle">
           <div clasName="text-center">
                <img src={PngIcons.CongratulationsIcon} alt=""/>
           </div>
           <div className="Heading2 color-c000000 mt_16 fs-24">
                {'You’ve just earnt ' + points + ' loyalty points for this check in. Please show this message to a member of staff to redeem your discount.'}
           </div>
           <div className="Body14R color-propertySectionTextColor">
            NOTE: You can earn more points at this business in another 24 hours. Have a wonderful day! - Shire Rewards
           </div>
           <div className="mt_40 w-100">
                <CustomButton 
                    btntext   = {'Okay'}
                    className = {'w-100'}
                    // height    = {'48px'}
                    onClick   = {handleClick}
                />
           </div>
        </div>
    )
}



export default LandingPageDefault
