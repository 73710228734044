import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import QrCode from '../../../../components/QrCode';
import { AgencyContext } from '../../../../context/Agency.context';
import Fade from 'react-reveal/Fade';

function Test() {
    
  const agency = useContext(AgencyContext)
  
  const [qrcode, setQr] = useState({
        size: 200,
        text: 'https://apps.apple.com/pk/app/business-connector/id1609580178',
        typeNumber: 10,
      })

      useEffect(async() => {
        let channel = await localforage.getItem('channel');
        
        setQr({...qrcode, text: (window.location.host.includes('localhost') ? 'http://' : 'https://') + `${(window.location.host === 'api.walletly.ai' || window.location.host.includes(agency.domain)) ? 'catchly.io' : window.location.host}/page/${channel[0]._id}`})
        
      }, [])
      
  return (
    <Fade bottom>
    <div>
        <div className='H24M color-neutral90 mb_8'>Test your business case</div>
        <div className='B16M color-neutral60'>Scan QR from app and test your business.</div>
        <div className='mt_100 d-flex justify-content-center'><div className='qrBoxOnboard p_8 w-fit'><QrCode qrcode={qrcode} /></div></div>
        <div className='H16M color-neutral80 mt_8 text-align-center'>Scan QR to test your business case</div>
    </div>
    </Fade>
  )
}

export default Test