
import React, {useContext, useEffect} from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import FlowTest from '../deal/subpages/FlowTest';
import Listing from './subpages/Listing';
import AddRule from './subpages/AddRule';


export default function Automation(props) {

    // console.log('I m inside automation ', props.match.url)

    return (
        <div>
            <Switch>
                <Route exact path = {props.match.url + '/'}                      component  = {Listing}/>
                <Route path       = {props.match.url + '/createrule'}           component = {AddRule}/>
                <Route path       = {props.match.url + '/addflow'}              component = {FlowTest}/>
                
                <Redirect path       = {props.match.url+ '/*'}               to        = {props.match.url+ '/automation'} />
                <Redirect path       = {props.match.url}                     to        = {props.match.url+ '/automation'} />
                </Switch>
        </div>
    )
}
