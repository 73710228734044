import React, {useState} from 'react'

// import CustomSwitch from '../../../components/CustomSwitch'
import CustomTextField from '../../../components/CustomTextField'
import CustomButton from '../../../components/CustomButton';
import Header from '../../../components/Header'
import { ColorScheme, enums } from '../../../enums'
import SvgIcons from '../../../icons/svg.icon'
import { ColorSchemeCode } from '../../../enums/ColorScheme';

export default function Option1() {
    
    //dummy data 
    const tempObject = [{
        redemptionName : 'Caffè Americano 20% Discount',
        discountPoints : '1000 points',
        campaignName   :  'XYZ Campaign'
    },
    {
        redemptionName : 'Caffè Italiano 30% Discounts',
        discountPoints : '200 points',
        campaignName   : 'ABC Campaign'
    },
    {
        redemptionName : 'Caffè Portugese 10% Discounts',
        discountPoints : '100 points',
        campaignName   : 'CDF Campaign'
    }
]   

    const [tempData, setTempData] = useState(tempObject);

    return (
        <div id='Redemption'>
            <Header 
                className   = {'bg-color-redemptionHeaderColor'}
                title       = {"Redemption"}
                textColor   = {ColorScheme.ColorSchemeCode.cFF005C}
                bgColor     = {'bg-color-redemptionHeaderColor'}
                btnTitle    = {'Create Redemption'}
                description = {`Create Redemption offers for your customers with 3 simple steps.`}
                steps       = {'1. Type your Redemption Offer. \n2. Enter how many points you offer. \n 3. Select the campaign on which you want to offer redemption.'}
            />

            <div className="row ml_20 ">

                <div className="col-8 mt_15">

                <CustomTextField 
                    className   = "searchField"
                    placeholder = 'Search'
                    icon        = {<SvgIcons.SearchIcon/>}
                    position    = 'start'
                    border      = 'none'
                />

                </div>

                <div className="col-4 text-right">

                <CustomButton
                    className       = "mt_30 mr_35"
                    btntext         = "Add Redemption"
                    color           = {ColorSchemeCode.white}
                    fontSize        = {14}
                />

                </div>

            </div>  

         
            
        </div>
    )
}
