import React, {useState, useEffect, useContext} from 'react';

import LoyaltyMainComponent from '../../../../components/loyalty/LoyaltyMainComponent';

import PngIcons from '../../../../icons/png.icon';
import { Campaign } from '../../../../enums';
import { RedeemingWayService } from '../../../../services';
import { Route, Switch } from 'react-router';
import RedeemingWayForm from '../../../../components/loyalty/RedeemingWayForm';
import { BrandContext } from '../../../../context/Brand.context';

export default function Redeempoints({selectedCampaign, ...props}) {

    
    const RedeemPointContent = {
        title   : 'Redeem points',
        subHead : 'Create rewards your customers can redeem with the points they’ve earned. Learn more about ',
        link    : 'how customer redeem points.',
    }

    const brand = useContext(BrandContext)

    const [redeemingWays, setRedeemingWays]         = useState([])
    const [processingElement, setProcessingElement] = useState()
    const [activities, setActivities]               = useState([])

    const updateActiveStatus = async ({e, _id, idx}) => {
        setProcessingElement(idx)
        const status = e.target.value === 'true' // getting true false value in string converting it back to boolean
        const payload = {_id, active: status}
        const {error, response} = await RedeemingWayService.Update({toaster: true, payload})
        if(response){
            redeemingWays[idx] = response.data
            setRedeemingWays([...redeemingWays])
        }
        setProcessingElement('')
    }

    const createPreBuiltRedeemingWays = async () => {
        const payload = {
            brandId    : brand._id,
            campaignId : selectedCampaign._id
        }
        const { error, response } = await RedeemingWayService.CreatePreBuiltRedeemingWays({payload})
        response && setRedeemingWays(response.data)
    }

    const getRedeemingWays = async () => {
        const query = {campaignId: selectedCampaign._id}
        const { error, response } = await RedeemingWayService.GetQueriedEarningWays({query})
        response && setRedeemingWays(response.data)
        error && createPreBuiltRedeemingWays()
    }

    const updateActivities = () => {
        const Activities = [
            {
                key   : Campaign.Activities.REDEEMING.AMOUNT,
                name  : 'Amount Discount',
                icon  : PngIcons.dollarCashIcon,
                exist : redeemingWays.some(way => way.activity === Campaign.Activities.REDEEMING.AMOUNT)
            },
            {
                key   : Campaign.Activities.REDEEMING.PRECENTAGE,
                name  : 'Percentage Off',
                icon  : PngIcons.hundredIcon,
                exist : redeemingWays.some(way => way.activity === Campaign.Activities.REDEEMING.PRECENTAGE)
            },
            {
                key   : Campaign.Activities.REDEEMING.SHIPPING,
                name  : 'Free Shipping',
                icon  : PngIcons.deilveryBoxIcon,
                exist : redeemingWays.some(way => way.activity === Campaign.Activities.REDEEMING.SHIPPING)
            },
            {
                key   : Campaign.Activities.REDEEMING.POS_AMOUNT,
                name  : 'POS Amount Discount',
                icon  : PngIcons.dollarCashIcon,
                exist : redeemingWays.some(way => way.activity === Campaign.Activities.REDEEMING.POS_AMOUNT)
            },
            {
                key   : Campaign.Activities.REDEEMING.POS_PERCENTAGE,
                name  : 'POS Percentage Off',
                icon  : PngIcons.hundredIcon,
                exist : redeemingWays.some(way => way.activity === Campaign.Activities.REDEEMING.POS_PERCENTAGE)
            },
        ]

        setActivities([...Activities])
    }

    useEffect(getRedeemingWays, [selectedCampaign])
    useEffect(updateActivities, [redeemingWays])

    return(
        <div id="RedeemPoints">
            <Switch>
                <Route 
                    exact
                    path={props.match.url + '/'} 
                    component={() => <LoyaltyMainComponent 
                        details            = {RedeemPointContent}
                        selectedCampaign   = {selectedCampaign}
                        list               = {redeemingWays}
                        updateActiveStatus = {updateActiveStatus}
                        processingElement  = {processingElement}
                        activities         = {activities}
                    />}
                />
                <Route path={props.match.url + '/create'} component={RedeemingWayForm} />
                <Route path={props.match.url + '/:_id'} component={RedeemingWayForm} />

                {/* <Redirect path={props.match.url + '/*'} to={props.match.url} /> */}
            </Switch>
            
        </div>
    )
  
}