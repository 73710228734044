import React, { useContext, useEffect, useState } from 'react'
import { PngIcons, SvgIcons } from '../../../../icons'
import { AgencyContext } from '../../../../context/Agency.context';
import CustomButton from '../../../../components/CustomButton.js'
import { CircularProgress } from '@mui/material'
import PageOneSetupWalletly from '../CloudWaitress/PageOneSetupWalletly';
import EarningWays from '../CloudWaitress/EarningWays'
import SetupCloudWaitress from '../CloudWaitress/CustomerRewards/SetupCloudWaitress';
import SetupCloudAnotherpart from '../CloudWaitress/CustomerRewards/SetupCloudAnotherpart';
import ConnectedAccounts from '../CloudWaitress/CustomerRewards/ConnectedAccounts';
import { useFormik } from 'formik';
import { Campaign } from '../../../../enums';
import { useHistory } from 'react-router-dom';
import VisitTriggers from './VisitTriggers';
import CouponStrategy from './CouponStrategy';
import CustomerEngagement from './CustomerEngagement';
import CustomerChannel from './CustomerChannel';
import BC from './BC';
import Test from './Test';
import { Brand } from '../../../../enums';
import localforage from 'localforage'


export function MainSetupAdvanced2() {
    const [selected, setSelected] = useState('Customer opt-in');
    const [index, setIndex] = useState(0);
    const agency = useContext(AgencyContext);
    const history = useHistory();
   

    const communicationChild = {
        active      : false,
        referenceId : ''
    }

    const communication = {
        notification : communicationChild,
        sms          : communicationChild,
        email        : communicationChild,
        chatFlow     : communicationChild
    }


    const initValues = {
        CustomerEngagement: 'visits',
        CouponStrategy: 'One time'
    }

    const formik = useFormik({
        initialValues : {...initValues},
        isInitialValid: false,
    })

    const addNewCWFunc = (index) => {
        setSelected(state?.components[index]?.title);
        setIndex(index)
    }

    const Components = [
        {
            title    : 'Customer opt-in',
            component: <CustomerChannel formik={formik} />,
            image    : <img src={PngIcons.LandingPagePreview} className='revL' height={600} alt="image" />,
        },
        {
            title    : 'Test your business',
            component: <Test formik={formik} />,
            image    : <img className='w-55 revLtwo' src={PngIcons.mobpass} alt="image" />
        },
        {
            title    : 'Business Connector',
            component: <BC formik={formik} />,
            image    : <img className='w-80 revLthree' src={PngIcons.bc} alt="image" />
        },
    ]

    const [state, setState] = useState({
        components: [],
        brandCreated: '',
    })


    const handleNextFunc = () => {
            if(index < 2){
                setSelected(state?.components[index+1]?.title);
                setIndex(index+1)
            }
            else{
                // console.log('Hello Handle Submit Fund')
            }
    }

    useEffect(()=>{
        setState({...state, components : Components})
    },[formik.values, formik.errors])
    const [brand, setBrand] = useState({})
  
    useEffect(()=>{
        onLoad();
      },[])
  
      const onLoad = async() =>{
        let brand = await localforage.getItem('brand');
        setBrand(brand);
      }
    const finishSetup = () =>{
        if(brand.brandType === Brand.BrandTypes.NORMAL && !agency.whiteLabel)
        {
            history.push({pathname: '/'+brand._id + '/setting/billing'});
        }
        else if(brand.brandType === Brand.BrandTypes.NORMAL && agency.whiteLabel)
        {
            history.push({pathname: '/'+brand._id + '/setting/billing'})
        }
        else 
        {
            history.push({pathname: '/'+brand._id, state: {activeBrand: brand}})
        }
    }
    
    return (
        <>
            <div className='w-100 d-flex '>
                <div className='top-header-auth d-flex align-items-center'>
                    <img src={(agency.logo || PngIcons.authLogo)} height="32px" width="auto" className='borderRadius-60 ml_10 mr_10' alt="" />
                    <div className='cp back-text' onClick={()=>{
                        if(index === 0){
                            let url = history.location.pathname.split('/');
                            url.pop();
                            url.push('advance')

                            let newUrl = url.join('/');
                            // console.log('url is', newUrl);

                            history.push(newUrl);   
                        }
                        else{
                            setSelected(state?.components[index-1]?.title);
                            setIndex(index-1)
                        }
                        }}>
                        {index != 0 && "Back"}
                        {/* {index != 0 && <SvgIcons.ArrowRight />} */}
                    </div>
                </div>

                <div className='w-60 d-flex '>
                    <div className='w-100 d-flex mainSU_C1 bg-FFFFFF' id='mainSU_CU'>
                        <div className='w-24 '>

                        <div className='ParentOfDesgn'>
                            <div className='ChildOneOfDesgn'>
                                { state.components?.map((component, idx)=><div className={`${(component.title == selected) ? 'specialRound' : 'simpleRound' }`} ></div>)}
                            </div>
                            <div className='ChildTwoOfDesgn align-flex-start'>
                            { 
                                state.components?.map((component, idx)=>
                                
                                <>
                                <p className={`${(selected == component.title)  ? 'livePara' : 'unlivePara'} text-left ml_32 `}>{component.title}</p>
                                </>
                                )
                            }
                            </div>
                        </div>

                        </div>
                        <div className='w-50  mr_102 mt_124' style={{height: 'calc(100vh - 124px)', overflowX: 'scroll', position: 'relative'}}>
                            {state?.components[index]?.component}
                            {<div className="w-100 gap-12 mt_24 d-flex flex-column bottom-0 position-absolute">
                                <CustomButton
                                    className = "w-100 hpx-52 mb_40"
                                    btntext   = {index===2?"Finish Setup":"Next"}
                                    onClick   = {index===2 ? finishSetup : handleNextFunc}
                                    //   disabled  = {state.loader || !formik.isValid || (selected == 2 && formik.values.brandLogoFile == "")}
                                    icon={
                                        state.loader && <CircularProgress size={20} color={"inherit"} />
                                    }
                                />
                            </div>}

                        </div>

                    </div>

                </div>
                <div className='w-40 d-flex align-items-center vh-100 bg-FCFDFD justify-content-center'>
                    {/* Mobile Images on conditions */}
                    {/* <img className='w-100' src={PngIcons.SetupPhone} /> */}
                     {/* <img className='w-100 mt_124' src={PngIcons.cloudWaitressSideImg} alt="image" /> */}
                    {/* <img src={PngIcons.earningWaysSide} className='w-100 mt_223' alt="image" /> */}
                    {/* <img className='w-100 mt-124' src={PngIcons.cloudWaitressSideImg} alt="image" /> */}
                    {state?.components[index]?.image}
                </div>
            </div>
        </>
    )
}


export default MainSetupAdvanced2;