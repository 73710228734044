import { RadioGroup } from '@mui/material';
import React from 'react'
import CustomRadio from '../../../../components/CustomRadio';
import Fade from 'react-reveal/Fade';

function CustomerEngagement({formik}) {
  return (
    <Fade top>
    <div>
      <h1>CustomerEngagement</h1>

      <form >
        <div className="auth-form">
          <RadioGroup
            // name     = "walletlyUse"
            value    = {formik.values.CustomerEngagement}
            onChange = {(e)=>{
              // console.log('e ', e.target.value, formik.values.CustomerEngagement)
              formik.values.CustomerEngagement = e.target.value;
              formik.setValues({...formik.values})

          }}
            // onFocus  = {formik.handleBlur}
          >

            <div className={formik.values.CustomerEngagement == "visits" ? "w-100 radioParentp selected" : "w-100 radioParentp"}>
              <CustomRadio
                value   = {'visits'}
                label   = {
                  <div className='standAlone '>
                    <h3 className="Body14M mt_11">Increase customer visits Coupon Only</h3>
                    <p className="Body10M">Points are earned based on spending. The more spending, the more points will be gained.</p>
                  </div>
                }
                className="mt_16 "
              />
            </div>

            {console.log('formik ', formik.values)}

            <div className={formik.values.CustomerEngagement == "repeation" ? "w-100 radioParentp selected" : "w-100 radioParentp"}>
              <CustomRadio
                value   = {'repeation'}
                label   = {
                  <div className='standAlone '>
                    <h3 className="Body14M mt_11">Increase customer repeation Coupons & Loyalty</h3>
                    <p className="Body10M">Points are earned based on spending. The more spending, the more points will be gained.</p>
                  </div>
                }
                className="mt_16"
              />
            </div>

          </RadioGroup>
        </div>
      </form>
    </div>
    </Fade>
  )
}

export default CustomerEngagement