import React, { useContext } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import ProfileSetting from '../../pages/userprofile/subpages/ProfileSetting';
import SettingHeader from '../../components/setting/SettingHeader';

export default function Profile(props) {

    return (
        <div id="profile">
                <div className="">
                    <Switch>    
                        <Route 
                            path      = {props.match.url}
                            component = {ProfileSetting}
                        />  
                        <Redirect path={props.match.url+ '/*'} to={props.match.url} />
                        <Redirect path={props.match.url} to={props.match.url} />
                    </Switch>
                </div>
        </div>
    )
}


