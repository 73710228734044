import { CircularProgress } from "@mui/material";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomButton from "../../components/CustomButton";
import { BrandService } from "../../services";
import VipClubService from "../../services/VipClub.service";
import { PngIcons, SvgIcons } from '../../icons';
import Loader from "../../components/Loader";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { BrandContext } from "../../context/Brand.context";


const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

function VipLandingPage({ formik, setCurrent, current }) {

  const params = useParams();
  const [state, setState] = useState({
    loader: true,
    brand: {},
    apiLoader: false,
  })
  const [topSectionImageMap, setTopSectionImageMap] = useState(0)
  const [mycurrent, setmyCurrent] = useState(0)

  const brand = useContext(BrandContext)


  useEffect(() => {
    onLoad();
    console.log("sss", params.brandId);
  }, [])
  const slideRef = useRef(null);

  const onLoad = async () => {
    setState({ ...state, loader: true })
    let query = {
      _id: params.brandId
    }
    console.log('payload ', query);
    const { response, error } = await BrandService.GetPublic({ query });
    if (response) {
      console.log('response ', response)
      setState({ ...state, brand: response.data, loader: false })
    }
    else {
      setState({ ...state, loader: false })
    }
    // setState({...state,loader:false})
  }

  const handleClickFunc = async () => {
    const headers = { 'x-api-key': state.brand.apiKey }
    setState({ ...state, apiLoader: true });
    let payload = {
      brandId: params.brandId,
    }

    // console.log('payload ', payload)

    const checkoutSession = await VipClubService.CheckoutSession({ payload, headers });
    console.log('checkoutSession ', checkoutSession)
    if (checkoutSession.response) {
      // window.open(checkoutSession.response.data, "_blank")
      console.log('url-------');
      let a = checkoutSession.response.data
      window.location.href = a;


    }

    setState({ ...state, apiLoader: false })
  }
  const properties = {
    duration: 2000,
    autoplay: false,
    easing: "ease",
  };



  const BgColor = {
    background: formik?.values?.backgroundColor || state?.brand?.integrations?.vipClub?.backgroundColor
  }
  const slideImages = formik?.values?.topSection?.images || state?.brand?.integrations?.vipClub?.topSection?.images;
  const slideImages2 = formik?.values?.bottomCards || state?.brand?.integrations?.vipClub?.bottomCards;
  console.log('state?.brand', state?.brand)

  {
    console.log('slideImages[topSectionImageMap]', formik, setCurrent, current)
  }
  return (
    <>


      {state.loader ?
        <Loader />
        :
        <div class="Main b " style={BgColor} id={`${formik?.values ? 'inprogress' : 'VipClub'}`}>
          <div className='SubMain b' >
            <div class="firstChild">
              <div style={{ width: '83px', height: '83px', borderRadius: '50%', marginTop: '60px', marginBottom: '30px' }}>
                <img src={formik ? brand.brandLogo : state.brand.brandLogo} style={{ objectFit: 'cover', width: '100%', borderRadius: '50%' }} alt="logo" />
              </div>

              {formik?.values?.headingOne ?
                <h1 class="large-heading ">
                  {formik?.values?.headingOne}
                </h1>
                :
                <h1 class="large-heading">
                  {/* Why Be A Guest.<br /> When You Can Be A <span class="large-heading">Member?</span> */}
                  {state.brand?.integrations?.vipClub?.headingOne}
                </h1>
              }
              <p className="againpara">
                {formik?.values?.subHeadingone || state.brand?.integrations?.vipClub?.subHeadingone}
              </p>

              {formik?.values?.topSection?.description || state.brand?.integrations?.vipClub?.topSection?.description ?
                <div style={{ marginTop: '10px', marginBottom: '10px' }} dangerouslySetInnerHTML={{ __html: formik?.values?.topSection?.description || state.brand?.integrations?.vipClub?.topSection?.description }}>
                </div>
                :
                <p className="para ">
                  Receive a <span class="B-color">FREE PIZZA</span> every month, plus daily specials & other discounts that are only available to members. See our all the benefits below.
                </p>
              }





            </div>

            <div className="joinbtnfirstMain">

              <div onClick={!formik && handleClickFunc} className="firstJoinBtn">
                <p style={{ margin: '0px' }}>Join Now</p>
                <div><SvgIcons.rightwhitearrow /></div>
              </div>
            </div>
            {
              console.log('slideImages', slideImages)
            }
            <div className="topSectionSlider">
              {slideImages?.length > 1 ?
                <>
                  <div className="Sli-top-main">
                    <div className="Sli-top-images">


                      {slideImages[topSectionImageMap] && <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={slideImages[topSectionImageMap] && typeof slideImages[topSectionImageMap] == "string" ? slideImages[topSectionImageMap] : URL.createObjectURL(slideImages[topSectionImageMap])} alt="image" />}
                    </div>
                    <div className="Sli-top-buttons">
                      <button style={{ outline: 'none', border: 'none' }} className="Sli-top-btnOne" onClick={(e) => { e.preventDefault(); setTopSectionImageMap(topSectionImageMap - 1 > -1 ? topSectionImageMap - 1 : slideImages.length - 1) }}><SvgIcons.sliderarrowleft /></button >
                      <button style={{ outline: 'none', border: 'none' }} className="Sli-top-btnTwo" onClick={() => setTopSectionImageMap(topSectionImageMap + 1 <= slideImages.length - 1 ? topSectionImageMap + 1 : 0)}><SvgIcons.sliderarrowright /> </button >
                    </div>
                  </div>
                </>
                :
                slideImages?.length == 1 ?
                  <img width={"100%"} height={'100%'} style={{ objectFit: 'cover' }} src={typeof slideImages[0] == "string" ? slideImages[0] : URL.createObjectURL(slideImages[0])} alt="image" />
                  :
                  <img width={'100%'} src={PngIcons.VipClubimageSpe} alt="image" />
              }
            </div>









            <div className="thirdChild b">
              <div className="subthirdchild">
                <div className="lastDatils">
                  <h1 className="large-heading-spe">
                    {formik?.values?.headingTwo || state.brand?.integrations?.vipClub?.headingTwo}
                  </h1>

                  <div style={{width : '100%'}} dangerouslySetInnerHTML={{ __html: formik?.values?.bottomSection?.description || state.brand?.integrations?.vipClub?.bottomSection?.description }}></div>

                </div>


                <div className="lastImage b">
                  <h1 className="Headingthree ">{formik?.values?.headingThree || state.brand?.integrations?.vipClub?.headingThree || ''}</h1>
                  {slideImages2?.length > 0
                    ?
                    <>
                      <div className="slider-main">
                        <img style={{ width: '100%', height: '262px', objectFit: 'cover', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }} src={typeof slideImages2[formik ? current : mycurrent]?.image == "string" ? slideImages2[formik ? current : mycurrent]?.image : URL.createObjectURL(slideImages2[formik ? current : mycurrent]?.image) || ''} alt="image" />
                        <div className="slider-info">
                          <h1>{slideImages2[formik ? current : mycurrent].title || ''}</h1>
                          <div dangerouslySetInnerHTML={{ __html: slideImages2[formik ? current : mycurrent].description || '' }}></div>

                        </div>
                      </div>

                      <div className="slider-btns">
                        <button style={{ outline: 'none', border: 'none' }} className="slider_button" onClick={(e) => { e.preventDefault(); formik ? setCurrent(current - 1 > -1 ? current - 1 : slideImages2.length - 1) : setmyCurrent(mycurrent - 1 > -1 ? mycurrent - 1 : slideImages2.length - 1) }}><SvgIcons.sliderarrowleft /></button>
                        <div style={{ marginLeft: '20px', marginRight: '20px' }}> {formik?current + 1:mycurrent + 1}/{slideImages2?.length}</div>
                        <button style={{ outline: 'none', border: 'none' }} className="slider_button" onClick={() => formik ? setCurrent(current + 1 <= slideImages2.length - 1 ? current + 1 : 0) : setmyCurrent(mycurrent + 1 <= slideImages2.length - 1 ? mycurrent + 1 : 0)}><SvgIcons.sliderarrowright /> </button>
                      </div>
                    </>
                    :
                   formik? <>
                      <div className="slider-main">
                        <img style={{ width: '100%', height: '262px', objectFit: 'cover', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }} src={PngIcons.cardSample} />
                        <div className="slider-info">
                          <h1>The Best Food in Town</h1>
                          <p>Lorem ipsum dolor sit amet consectetur. Ullamcorper ultrices at turpis ultricies a eget. Imperdiet enim montes dictum turpis et. Tincidunt.</p>
                        </div>
                      </div>


                    </>:<></>

                  }
                </div>

                <CustomButton
                  className="join-button-spe cp"
                  onClick={!formik && handleClickFunc}
                  btntext={!state.apiLoader &&<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}> <p style={{ margin: 0 }}>Join Now</p> <span><SvgIcons.rightwhitearrow /></span></div>}
                  icon={state.apiLoader && <CircularProgress size={16} color="inherit" />}
                />
                <div className="fs-14 cursor-pointer color-primary40 mb_20">
                  {
                    state.brand?.integrations?.vipClub?.privacyPolicy && <a href={state.brand?.integrations?.vipClub?.privacyPolicy} className='p_10 color-neutral70'>Privacy Policy</a>
                  }
                  {
                    state.brand?.integrations?.vipClub?.termsAndConditions && <a href={state.brand?.integrations?.vipClub?.termsAndConditions} className='color-neutral70' style={{ padding: '5px' }}>Terms & Conditions</a>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      }
    </>);

}



export default function VipClub({ formik, setCurrent, current }) {
  const [message, setMessage] = useState("");



  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return (
    <div className="middle">
      {message ?
        <Message message={message} />
        :
        // <ProductDisplay />
        <VipLandingPage formik={formik} setCurrent={setCurrent} current={current} />
      }
    </div>
  )
}

