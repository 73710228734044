import React, { useState, useEffect, useContext, createRef } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";

import CustomCheckBox from "../../../../components/CustomCheckBox";
import CustomTextField from "../../../../components/CustomTextField";
import PageTitle from "../../../../components/setting/SettingPageTitles";

import { TierService } from "../../../../services";
import { ClickOutside, Options } from "../../../../utils";
import { getTierExtraPoint } from "../../../../utils/Options";
import { ChromePicker } from "react-color";
import { TierVld } from "../../../../validation";
import CustomSelect from "../../../../components/CustomSelect";
import { SvgIcons, PngIcons } from "../../../../icons";
import { showToaster } from "../../../../utils/utils";

import localForage from "localforage";
import { CircularProgress, RadioGroup } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import { LayoutContext } from "../../../../context/Layout.context";
import DeleteModal from "../../../../components/DeleteModal";
import CustomsModal from "../../../../components/CustomModal";
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomRadio from "../../../../components/CustomRadio";
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import { Tier } from "../../../../enums";

function AddTier({ selectedCampaign }) {
  const [state, setState] = useState({
    loader: false,
    apiError: "",
    deleteLoader: false,
  });
  const [filled, setFilled] = useState({
    generalInformation: false,
    qualification: false,
    benefits: false,
    expirationDays: false,
  });

  const history = useHistory();

  const tierData = history.location.state;
  const initState = {
    name: tierData ? tierData.name && tierData.name : "",
    minPoints: tierData
      ? !tierData.name &&
        tierData.points &&
        tierData.points.max &&
        tierData.points.max === "-Infinity"
        ? tierData.points.max + 1
        : tierData.pointRange
        ? tierData.pointRange.minPoints && tierData.pointRange.minPoints
        : 0
      : 0,
    maxPoints: tierData
      ? tierData.pointRange && tierData.pointRange.maxPoints
      : "",
    backgroundColor: tierData
      ? tierData.backgroundColor && tierData.backgroundColor
      : "#c5c5c5",
    // fontColor       : tierData ? tierData.fontColor && tierData.fontColor : '#000000',
    discountValue: tierData
      ? tierData.benefits
        ? tierData.benefits.discount.discountValue
        : null
      : null,
    extraPoints: tierData
      ? tierData.benefits
        ? tierData.benefits.points.extraPoints
        : 1
      : 1,
    expirationDays: tierData
      ? tierData.expirationDays && tierData.expirationDays
      : "",

    customDiscounts: tierData
      ? tierData.benefits
        ? tierData.benefits.discount.customDiscounts !== null
          ? tierData.benefits.discount.customDiscounts.length > 0 &&
            tierData.benefits.discount.customDiscounts
          : [0, 0, 0, 0, 0, 0, 0]
        : [0, 0, 0, 0, 0, 0, 0]
      : [0, 0, 0, 0, 0, 0, 0],
    checkDealOffer: true,
    checkDiscount: tierData
      ? tierData.benefits && tierData.benefits.discount
        ? true
        : false
      : false,
    checkCustom: tierData
      ? tierData.benefits && tierData.benefits.discount.discountValue === null
        ? true
        : false
      : false,
    checkExtra: tierData
      ? tierData.benefits && tierData.benefits.points.extraPoints
        ? true
        : true
      : true,
    checkBirthday: false,
    selectedComponent: "General Information",
    pickerBg: false,
    pickerText: false,
  };

  const calcExpirationDateFunc = () => {
    let expirationDate = new Date();
    expirationDate = expirationDate
      .setDate(
        expirationDate.getDate() + parseInt(formik.values.expirationDays)
      )
      .valueOf();
    return expirationDate;
  };

  const handleSubmit = async () => {
    if (
      !tierData.name &&
      tierData.points &&
      tierData.points.max >= formik.values.minPoints
    ) {
      showToaster({
        position: "top-right",
        title: "Points Error",
        message: "Min point must be greater than Max Point",
        severity: "error",
      });
    } else {
      setState({ ...state, loader: true });

      const payload = {
        name: formik.values.name,
        expirationDays: formik.values.expirationDays,
        pointRange: {
          minPoints: formik.values.minPoints,
          maxPoints: formik.values.maxPoints,
        },
        backgroundColor: formik.values.backgroundColor,
        fontColor: "#ffffff",
        benefits: {
          discount: {
            discountValue:
              formik.values.checkCustom === "discount" ||
              formik.values.checkCustom === false
                ? formik.values.discountValue
                : null,
            active: true,
            customDiscounts:
              formik.values.checkCustom == "custom" ||
              formik.values.checkCustom == true
                ? formik.values.customDiscounts
                : null,
          },
          points: {
            extraPoints: formik.values.extraPoints,
            active: true,
          },
        },
        brandId: selectedCampaign.brandId,
        campaignId: selectedCampaign._id,
        expirationDate: calcExpirationDateFunc(),
        active: true,
      };

      if (!tierData.name) {
        const { response, error } = await TierService.Create({
          toaster: true,
          payload,
        });
        if (response) {
          let tiers = await localForage.getItem("tiers");
          if (tiers === null) tiers = [];
          tiers.push(response.data);
          localForage.setItem("tiers", tiers);
        }

        if (error) setState({ ...state, apiError: error, loader: false });
        else {
          history.goBack();
        }
      } else {
        delete payload.active;
        const { response, error } = await TierService.Update({
          toaster: true,
          payload: { ...payload, _id: tierData._id },
        });
        if (response) {
          let tiers = await localForage.getItem("tiers");
          tiers.map((element, idx) => {
            if (element._id === tierData._id) tiers[idx] = response.data;
          });
          localForage.setItem("tiers", tiers);
        }
        if (error) setState({ ...state, apiError: error, loader: false });
        else {
          history.goBack();
        }
      }
      setState({ ...state, loader: false });
    }
  };

  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues: initState,
    validationSchema: TierVld.Create,
    onSubmit: handleSubmit,
  });

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const gradient = [
    {
      color:
        "linear-gradient(103.31deg, #C97C36 0%, #E7BB9C 47.4%, #C6734C 100%)",
    },
    {
      color:
        "linear-gradient(104.58deg, #D6D6D6 13.4%, #F8F8F8 49.17%, #D6D6D6 83.49%)",
    },
    {
      color:
        "linear-gradient(75.23deg, #EBC168 20.99%, #F9DF79 53.51%, #DE9E45 88.14%)",
    },
    {
      color:
        "linear-gradient(104.85deg, #808080 0%, #EEEEEE 50.32%, #808080 100.87%)",
    },
  ];
  const solid = [
    { color: "#CF8964" },
    { color: "#DEDEDE" },
    { color: "#FFDA57" },
    { color: "#8C8C8C" },
  ];

  const handleText = (e, index) => {
    if (e.target.value <= 100 && e.target.value >= 0) {
      formik.values.customDiscounts[index] = parseInt(e.target.value);
      formik.setValues({ ...formik.values });
    }
  };

  const layout = useContext(LayoutContext);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    layout.setLayout({
      expandedBar: false,
      title: "Create Tier",
      // backTitles: ["Loyalty Tiers", "Tiers"],
      borderBottom: true,
      switch : '',
      edit: false,
      // button: (
      //   <div>
      //     {tierData.edit && (
      //       <CustomButton
      //         btntext={"Delete"}
      //         varient="warningSecondary"
      //         onClick={() => setDeleteModal(true)}
      //       />
      //     )}
      //   </div>
      // ),
    });
  }, [state.loader, formik.isValid, formik.values]);

  const handleDelete = async () => {
    setState({ ...state, deleteLoader: true });
    const payload = {
      _id: tierData._id,
      delete: true,
      brandId: tierData.brandId,
      campaignId: tierData.campaignId,
    };
    const { response, error } = await TierService.Update({
      toaster: true,
      payload,
    });
    if (response) {
      let tiers = await localForage.getItem("tiers");
      tiers.map((element, idx) => {
        if (element._id === tierData._id) tiers.splice(idx, 1);
      });

      localForage.setItem("tiers", tiers);

      const location = window.location.pathname.split("/");
      location.pop();
      history.push(location.join("/"));
    }

    setState({ ...state, deleteLoader: false });
  };

  let section1Ref = createRef(null);
  let section2Ref = createRef(null);
  let section3Ref = createRef(null);
  let section4Ref = createRef(null);

  const ComponentLists = [
    {
      title: "General Information",
      filled: filled.generalInformation,
      link: "#section-1",
      ref: section1Ref,
    },
    {
      title: "Qualification Points",
      filled: filled.qualification,
      link: "#section-2",
      ref: section2Ref,
    },
    {
      title: "Benefits",
      filled: filled.benefits,
      link: "#section-3",
      ref: section3Ref,
    },
    {
      title: "Expiration Days",
      filled: filled.expirationDays,
      link: "#section-4",
      ref: section4Ref,
    },
  ];

  const goToSection = (element) => {
    formik.setValues({ ...formik.values, selectedComponent: element.title });
    element.ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    let checkGeneralInfoError,
      checkQualificationError,
      checkBenefitError,
      checkExpirationError;
    if (
      formik.errors.name ||
      formik.errors.backgroundColor ||
      formik.values.name == undefined
    )
      checkGeneralInfoError = false;
    else checkGeneralInfoError = true;

    if (
      formik.errors.minPoints ||
      formik.errors.maxPoints ||
      formik.values.maxPoints == undefined
    )
      checkQualificationError = false;
    else checkQualificationError = true;

    if (
      formik.values.checkCustom == "discount" ||
      formik.values.checkCustom == false
    ) {
      if (
        formik.values.discountValue == null ||
        formik.values.discountValue == ""
      ) {
        checkBenefitError = false;
      } else {
        checkBenefitError = true;
      }
    }
    if (
      formik.values.checkCustom == "custom" ||
      formik.values.checkCustom == true
    ) {
      if (
        formik.values.customDiscounts == null ||
        formik.values.customDiscounts == ""
      ) {
        checkBenefitError = false;
      } else {
        checkBenefitError = true;
      }
    }

    if (
      formik.errors.expirationDays ||
      formik.values.expirationDays == undefined
    )
      checkExpirationError = false;
    else checkExpirationError = true;

    setFilled({
      ...filled,
      generalInformation: checkGeneralInfoError,
      qualification: checkQualificationError,
      benefits: checkBenefitError,
      expirationDays: checkExpirationError,
    });
  }, [formik.errors, formik.values]);

  return (
    <div id="AddTier" className="middle pt_40 pb_40">
      <div className="w-55">
        <form onSubmit={formik.handleSubmit}>
          <div className="Heading22M color-neutral100">
            General Information
          </div>
          <div className="bar mt_16 mb_16"></div>
          <div className="Heading16M color-neutral100">Display Name</div>
          <div className="Body14R color-neutral60 mt_6 mb_16">
            This is the name of the tier that is visible to the customer.
          </div>
          <div className="">
            <div className="w-48">
              <CustomTextField
                className="w-100 opacity-10"
                placeholder="eg. Gold Tier"
                name="name "
                disabled = "true"
                label="Tier Name"
                value={formik.values.name ? formik.values.name : ""}
                onBlur={formik.handleChange}
                error={formik.errors.name ? formik.errors.name : false}
                helperText={formik.errors.name ? formik.errors.name : ""}
              />
            </div>
            <div className="bar mt_20 mb_16"></div>
          </div>
          <div className="">
            <div className="Heading16M color-neutral100">
              Qualifying Points
            </div>
            <div className="Body14R color-neutral60 mt_6">
              Customer will get tier based on these points.
            </div>
            <div className="d-flex w-100 mt_16 space-between">
              <div className="w-48 ">
                <CustomTextField
                  icon={<div>Points</div>}
                  position="end"
                  label="Min Points"
                  name="minPoints"
                  type="number"
                  className="w-100"
                  // disabled    = {!tierData.name ? tierData.points ? tierData.points.max ? true : false : false : false}
                  disabled={true}
                  value={formik.values.minPoints ? formik.values.minPoints : 0}
                  onBlur={formik.handleChange}
                  error={
                    formik.errors.minPoints ? formik.errors.minPoints : false
                  }
                  helperText={
                    formik.errors.minPoints ? formik.errors.minPoints : ""
                  }
                />
              </div>
              <div className="w-48">
                <CustomTextField
                  icon={<div>Points</div>}
                  position="end"
                  type="number"
                  label="Max Points"
                  className="w-100"
                  name="maxPoints"
                  value={formik.values.maxPoints ? formik.values.maxPoints : ""}
                  onBlur={formik.handleChange}
                  error={
                    formik.errors.maxPoints ? formik.errors.maxPoints : false
                  }
                  helperText={
                    formik.errors.maxPoints ? formik.errors.maxPoints : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt_16 mb_16 bar"></div>
          <div className="color-neutral100 Heading16M mb_16">Tier Color</div>
          <div className="d-flex position-relative">
            <div className="d-flex">
              <div className="d-flex">
                {gradient.map((color, index) => (
                  <div className="" key={index}>
                    <div
                      className={
                        formik.values.backgroundColor === color.color
                          ? "colorCircle selectedColor mr_16 cp"
                          : "colorCircle mr_16 cp"
                      }
                      onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          backgroundColor: color.color,
                        })
                      }
                      style={{ background: color.color }}
                    >
                      {/* {color.color === formik.values.brandColor ? <SvgIcons.ColorselectIcon/> : <></>} */}
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex">
                {solid.map((color, index) => (
                  <div className="" key={index}>
                    <div
                      className={
                        formik.values.backgroundColor === color.color
                          ? "colorCircle selectedColor mr_16"
                          : "colorCircle mr_16"
                      }
                      onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          backgroundColor: color.color,
                        })
                      }
                      style={{ background: color.color }}
                    >
                      {/* {color.color === formik.values.brandColor ? <SvgIcons.ColorselectIcon/> : <></>} */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mt_48">
            <div className="Heading22M color-neutral100">Perks & Benefits</div>
            <div className="bar mt_16 mb_16"></div>
            <div className="">
              <div className="Heading16M color-neutral100">Discount Value</div>
            </div>
            <div
              className={` pl_8 ${
                formik.values.checkDealOffer && "expandDiscountTier"
              }  ${
                formik.values.checkCustom == "custom" && "expandHeightCustom"
              } `}
            >
              <RadioGroup
                name="checkCustom"
                onChange={(e) => {
                  formik.values.checkCustom = e.target.value;
                  formik.setValues({ ...formik.values });
                }}
                onFocus={formik.handleBlur}
              >
                <div className="">
                  <div className="d-flex mt_8">
                    <div className="mt_16 mr_4">
                      <CustomRadio
                        value={"discount"}
                        label=""
                        checked={
                          formik.values.checkCustom === "discount" ||
                          formik.values.checkCustom === false
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="w-48">
                      <CustomTextField
                        icon={<div>%</div>}
                        position="end"
                        type="number"
                        label={<div className="Body14M">Discount Value</div>}
                        placeholder="Set Discount"
                        className={`w-100 mb_16 ${
                          formik.values.checkCustom == "checkCustom" ||
                          formik.values.checkCustom == true
                            ? "disabled"
                            : ""
                        }`}
                        name="discountValue"
                        value={formik.values.discountValue}
                        onBlur={formik.handleChange}
                        error={
                          formik.errors.discountValue
                            ? formik.errors.discountValue
                            : false
                        }
                        helperText={
                          formik.errors.discountValue
                            ? formik.errors.discountValue
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <CustomRadio
                      value={"custom"}
                      label={
                        <div>
                          <div className="Body14M">Custom Discount Value</div>
                          <div className="Body14R color-neutral60 ">
                            Set Discount values based on whole week.
                          </div>
                        </div>
                      }
                      checked={
                        formik.values.checkCustom === "custom" ||
                        formik.values.checkCustom === true
                          ? true
                          : false
                      }
                    />
                  </div>
                  {(formik.values.checkCustom === "custom" ||
                    formik.values.checkCustom == true) && (
                    <div className="ml_40 mt_8">
                      {days.map((value, index) => (
                        <div className="">
                          <div className="pb_16 w-48">
                            <CustomTextField
                              value={formik.values.customDiscounts[index]}
                              onChange={(e) => handleText(e, index)}
                              icon={<div>%</div>}
                              className="w-100"
                              position="end"
                              label={value + " Discount"}
                              type="number"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </RadioGroup>
            </div>
            <div className="bar mt_20 mb_16"></div>
            <div className="row m_0 w-100 ">
              <div className="Heading16M color-neutral100">Extra Points</div>
            </div>
            <div>
              {console.log("formik ", formik.values)}
              <div className="w-100 mt_16">
                <RadioGroup
                  name="extraPoints"
                  value={formik.values.extraPoints}
                  onChange={(e) => {
                    formik.values.extraPoints = e.target.value;
                    formik.setValues({ ...formik.values });
                  }}
                  onFocus={formik.handleBlur}
                >
                  <div className="mb_24">
                    <CustomRadio
                      value={"0"}
                      label={
                        <div>
                          <div className="Body14M">Existing Points</div>
                          <div className="Body14R color-neutral60 ">
                            Customer will get default points set in earning
                            ways.
                          </div>
                        </div>
                      }
                      checked={formik.values.extraPoints === "0" ? true : false}
                    />
                  </div>

                  <div className="mb_24">
                    <CustomRadio
                      value={"1"}
                      label={
                        <div className="">
                          <div className="Body14M">Double Points (2x)</div>
                          <div className="Body14R color-neutral60 ">
                            Points will be doubled from the orignal points you
                            set.
                          </div>
                        </div>
                      }
                      checked={formik.values.extraPoints === "1" ? true : false}
                    />
                  </div>

                  <div className="">
                    <CustomRadio
                      value={"2"}
                      label={
                        <div>
                          <div className="Body14M">Triple Points (3x)</div>
                          <div className="Body14R color-neutral60 ">
                            Points will be tripled from the orignal points you
                            set.
                          </div>
                        </div>
                      }
                      checked={formik.values.extraPoints === "2" ? true : false}
                    />
                  </div>
                </RadioGroup>
              </div>
              <div className="bar pt_16 mb_16"></div>
            </div>

            <div className="Heading16M color-neutral100">Expiration</div>
            <div className="Body14R color-neutral60 mb_16">
              Set days for how after how many days tiers will downgrade.
            </div>
            <div className="w-48">
              <CustomTextField
                icon={<div>Days</div>}
                position="end"
                type="number"
                label="Expiration Days"
                className="w-100"
                name="expirationDays"
                value={
                  formik.values.expirationDays
                    ? formik.values.expirationDays
                    : ""
                }
                onBlur={formik.handleChange}
                error={
                  formik.errors.expirationDays
                    ? formik.errors.expirationDays
                    : false
                }
                helperText={
                  formik.errors.expirationDays
                    ? formik.errors.expirationDays
                    : ""
                }
              />
            </div>
          </div>
          <div className="bar mt_16 mb_16"></div>
          <CustomButton
            varient={"primary"}
            btntext={tierData.edit ? "Save changes" : "Create Tier"}
            disabled={state.loader || !formik.isValid}
            icon={
              state.loader && <CircularProgress size={20} color={"inherit"} />
            }
            onClick={handleSubmit}
          />
          <CustomsModal
            open={deleteModal}
            minWidth={"600px"}
            component={
              <DeleteModal
                icon={state.deleteLoader}
                title="tier"
                onCancel={() => setDeleteModal(false)}
                onDelete={handleDelete}
              />
            }
            onClose={() => setDeleteModal(false)}
          />
        </form>
      </div>
    </div>
  );
}

export default AddTier;
