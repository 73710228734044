import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import * as localForage from 'localforage'

import CustomButton from '../../../components/CustomButton'
import Loader from '../../../components/Loader'
import ReactTooltip from 'react-tooltip'
import CustomSwitch from '../../../components/CustomSwitch'
import QRcode from '../../../components/QrCode';
import ConnectionsView from '../subpages/ConnectionsView'

import { SvgIcons } from '../../../icons'

import { AgencyContext } from '../../../context/Agency.context'
import { LayoutContext } from '../../../context/Layout.context'
import { BrandContext } from '../../../context/Brand.context'

import { ColorSchemeCode } from '../../../enums/ColorScheme'
import { CircularProgress, Link, colors } from '@mui/material'

import { BrandService, CampaignService, FlowService, GrowthtoolService } from '../../../services'

import { useFormik } from 'formik';
import { GrowthtoolVld } from '../../../validation'
import { showToaster, uploadImage } from '../../../utils/utils'
import { ColorScheme, GrowthTools } from '../../../enums'
import { ChromePicker } from 'react-color'
import { ClickOutside } from '../../../utils'
import CustomSelect from '../../../components/CustomSelect'
import CustomTextField from '../../../components/CustomTextField'
import env from '../../../config'
import QrCode from '../../../components/QrCode'
import CustomCopytoClip from '../../../components/CustomCopyToClip'
import Editor from '../../../components/QuillEditor'



export default function LandingPageShare() {
    const history = useHistory();
    // 
    const [growthTool, setgrowthTool] = useState(null)

    const brand = useContext(BrandContext);
    const layout = useContext(LayoutContext);

    const onload = async () => {
        console.log('brand',brand);
        if(brand?.referral?.title){
            console.log('asd');
            formik.setValues({...formik.values, 
                referral:{...formik.values.referral,
                    show: brand?.referral?.show || true,
                    title: brand?.referral?.title || '',
                    body: brand?.referral?.body || '',
                    url: brand?.referral?.url || '',
                }
            })
        }

        const campaignData = await CampaignService.GetQueriedCampaign({
            query: { brandId: brand._id, campaignType: 5 },
        });
        console.log('query campaignData', campaignData)

        const query = {
            brandId: brand._id,
            campaignId: campaignData.response.data[0]._id,
        };
        const { error, response } = await GrowthtoolService.GetQueriedGrowthTool({
            query,
        });
        console.log("ERROR", error);
        error && setState({ ...state, apiError: error.message, loader: false });
        if (response) {

            let flag = response.data.some((e) => e.referralUrl === true) || false
            console.log('flagflag', flag)
            if (!flag) {
                let landings = response.data.filter((e) => e.growthtoolType == "landingPage")
                console.log('landings', landings)
                setgrowthTool([landings[0]])
            } else {
                setgrowthTool(response.data.filter((e) => e.referralUrl === true))
            }

        }
    }

    const initState = {
        referral: {
            show: brand?.referral?.show || false,
            title: brand?.referral?.title || '',
            body: brand?.referral?.body || '',
            url: brand?.referral?.url || '',
            
        }
    }
    const [state, setState] = useState({
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
    })

    const formik = useFormik({
        initialValues: { ...initState },
        enableReinitialize: true
    })

    useEffect(() => { onload() }, [])
    console.log('formik', formik.values)

    // 

    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })
        const payload = {
            _id: brand._id,
            referral: {
                show: formik.values.referral.show,
                title: formik.values.referral.title,
                body: formik.values.referral.body,
                url : `https://${window.location.host}/${window.location.href.split('/')[3]}/RefferalPage`
            }
        }

        console.log('payload is ', payload);
        const { error, response } = await BrandService.Update({ toaster: true, payload })
        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })
        console.log("Business ", response.data);
        localForage.setItem('brand',response.data)
        brand.updateBrand(response.data)
        setState({ generalLoader: false, apiError: '' })
        history.goBack()
        return 

    }
    useEffect(() => {
        layout.setLayout({
            borderBottom: true,
            edit        : false,
            title       : "Social Referral Program",
            backTitles  : ['Channels'],
            switch : '',
            button: <>
            <CustomButton btntext={"Update"}
            disabled={state.generalLoader}
            icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={handlePublicProfileUpdateFunc}/>
            </>
        })
    }, [state, formik.values, ])

    return (
        <div id="" >
            {!state.loader ?
            <div className='pt_20 pl_40'>
            

                <div className="h-100 d-flex">
                    <div className="w-70">
                        <Leftpane formik={formik} />
                    </div>
                    <div className=" w-100 " >
                        <div className=" h-fit position-relative">
                            <div id="RefSharePage" style={{ flexDirection: 'column' }} className=" w-100 d-flex justify-content-center align-items-center">
                                <div className=" ">
                                    <img style={{ height:'300px' }} className="" src={"https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg"} />
                                </div>
                                <div className="Heading20B mt_32 w-80 text-align-center">
                                    {formik.values?.referral?.title}
                                </div>
                                <div className="mt_12 B16R justify-content-center w-80 text-align-center" dangerouslySetInnerHTML={{ __html: formik.values?.referral?.body }}>

                                </div>
                                <div className="mt_32 w-70 hpx-45 mainCopy d-flex">
                                    <div className="w-90  big" style={{color: '#B2B7CA'}}>
                                        http://example.com/invite-referral
                                    </div>
                                    <div className="w-15 copy d-flex justify-content-center align-items-center cp">
                                    <SvgIcons.CopyWhite />
                                    </div>
                                </div>
                                <div className="onSocialMedia">
                                    Share on social media
                                </div>

                                <div className="d-flex gap-32 mt_19 mainIcons">
                                    <div className="cp" ><SvgIcons.TwitterX /></div>
                                    <div className="cp" ><SvgIcons.FacebookX /></div>
                                    <div className="cp" ><SvgIcons.LinkdlnX /></div>
                                    <div className="cp" ><SvgIcons.WhatsAppX /></div>
                                    <div className="cp" ><SvgIcons.TweetX /></div>
                                <div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                : <Loader height="32px" width="32px" />}
        </div>
    )
}

const Leftpane = ({formik}) => {

    return (
        <div className="">
            <div className='Heading20M color-neutral100'>Social Referral Program</div>
            <div className="color-propertySectionTextColor color-neutral60 fs-14">Refer a friend and you both earn 10 points when your friend places an order on our website. Use the link below to refer people.            </div>
            <div className="bar mt_12 mb_16"></div>
                <div className=''>
                    <div className="Heading14M color-neutral100 mt_10">Title</div>
                    <div className='mt_6'>
                        <CustomTextField
                            value={formik.values.referral.title}
                            onChange={(e) => formik.setValues({ ...formik.values, referral: { ...formik.values.referral, title: e.target.value } })}
                            placeholder='Enter Title'
                        />
                    </div>

                    <div className="Heading14M color-neutral100 mt_10">Body Text</div>
                    <div className='mt_6'>
                        <Editor
                            value={formik.values.referral.body}
                            onChange={(content, delta, source, editor) => {
                                formik.setValues({ ...formik.values, referral: { ...formik.values.referral, body: content } })
                            }}
                        />
                    </div>
                  



                </div>
        
        </div>
    )
}
