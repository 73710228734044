import * as yup from 'yup';
import { Campaign, enums } from '../enums'

const REQ_ERROR = 'This field is required'

const condition = yup.object().shape({
    name     : yup.string().required(REQ_ERROR),
    field    : yup.string().required(REQ_ERROR),
    operand  : yup.mixed().required(REQ_ERROR),
    value    : yup.mixed().required(REQ_ERROR),
    finished : yup.boolean()
})

const action = yup.object().shape({
    name     : yup.string().required(REQ_ERROR),
    field    : yup.string().required(REQ_ERROR),
    value    : yup.mixed().required(REQ_ERROR),
    finished : yup.boolean()
})

const businessRule = yup.object().shape({
    delete     : yup.boolean().default(false),
    ruleName   : yup.string().required(REQ_ERROR),
    match      : yup.string().oneOf(['any', 'all']).required(REQ_ERROR),
    conditions : yup.array().of(condition),
    actions    : yup.array().of(action)
})

const communicationChild = yup.object({
    active      : yup.boolean(),
    referenceId : yup.string().when('active', {is: value => value === true, then: yup.string().required(REQ_ERROR)})
})

const communication = yup.object({
    notification : communicationChild,
    sms          : communicationChild,
    email        : communicationChild,
    chatFlow     : communicationChild
})

const Create = yup.object({
    // earningName      : yup.string().required(REQ_ERROR),
    delete           : yup.boolean().default(false),
    activity         : yup.string().required().oneOf(Object.values(Campaign.Activities.EARNING)),
    earningType      : yup.string().oneOf(Object.values(Campaign.EarningTypes)),
    approvalType     : yup.string().oneOf(Object.values(Campaign.ApprovalTypes)),
    earningValue     : yup.number(),
    earningValueRange: yup.array(),
    passcodeType     : yup.string().oneOf(Object.values(Campaign.PasscodeTypes)),
    passCode         : yup.string(),
    approvalDuration : yup.number().when('approvalType', { is: value => value === Campaign.ApprovalTypes.AFTER_DURATION, then: yup.number().integer().max(30).required(REQ_ERROR)}),
    businessRules    : yup.array().of(businessRule),
    communication    : communication,
    active           : yup.boolean(),
    icon             : yup.string(),
    iconFile         : yup.mixed()
                        .test('imageSize', 'File too large, it can be maximum of 2mb each.', value => value ? value.size <= enums.Images.ICON_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('icon',{
                            is   : value => !value,
                            then : yup.mixed()
                        })
})

const UpdateEarningWay = yup.object({
    delete           : yup.boolean().default(false),
    activity         : yup.string().required().oneOf(Object.values(Campaign.Activities.EARNING)),
    earningType      : yup.string().oneOf(Object.values(Campaign.EarningTypes)),
    approvalType     : yup.string().oneOf(Object.values(Campaign.ApprovalTypes)),
    earningValue     : yup.number().when('earningType', { is: value => ((value === Campaign.EarningTypes.FIXED) || (value === Campaign.EarningTypes.INCREMENTAL)), then: yup.number().integer().positive("Please Enter Valid points").required(REQ_ERROR)}),
    earningValueRange: yup.array().when('earningType', { is: value => (value === Campaign.EarningTypes.RANGE), then: yup.array().min(1).required(REQ_ERROR)}),
    passcodeType     : yup.string().oneOf(Object.values(Campaign.PasscodeTypes)),
    passCode         : yup.string(),
    approvalDuration : yup.number().when('approvalType', { is: value => value === Campaign.ApprovalTypes.AFTER_DURATION, then: yup.number().integer().max(30).required(REQ_ERROR)}),
    businessRules    : yup.array().of(businessRule),
    communication    : communication,
    active           : yup.boolean(),
    icon             : yup.string(),
    iconFile         : yup.mixed()
                        .test('imageSize', 'File too large, it can be maximum of 2mb each.', value => value ? value.size <= enums.Images.ICON_FILE_SIZE : true)
                        .test('imageType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true)
                        .when('icon',{
                            is   : value => !value,
                            then : yup.mixed()
                        })
})


const onBoardEarningWay = yup.object({
    earningWay : UpdateEarningWay.required(),
})



export {
    Create,
    onBoardEarningWay
}