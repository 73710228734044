import React, {useContext, useEffect, useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';


import CustomTable from '../../../components/customTable/CustomTable';
import PageTitle from '../../../components/setting/SettingPageTitles';
import CustomModal from '../../../components/CustomModal';
import ViewDetail from '../../../components/ViewDetail';
import CustomInput from '../../../components/CustomTextField';
import CustomButton from '../../../components/CustomButton';
import CustomSelect from '../../../components/CustomSelect';
import EmptyScreen from '../../../components/EmptyScreen';

import { SvgIcons } from '../../../icons';
import {ColorSchemeCode} from '../../../enums/ColorScheme';
import ClickOutside from '../../../utils/ClickOutside';
import {getProgramActiveStatus} from '../../../utils/Options'
import CustomTextField from '../../../components/CustomTextField';
import { Brand, User } from '../../../enums';
import { StaffVld } from '../../../validation';
import { Options, utils } from '../../../utils';
import { BrandContext } from '../../../context/Brand.context';
import { BrandService, StaffService, UserService } from '../../../services';
import Loader from '../../../components/Loader';
import { AgencyContext } from '../../../context/Agency.context';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../context/User.context';
import { sub } from 'date-fns/fp';
import env from '../../../config';
import { LayoutContext } from '../../../context/Layout.context';
import {brand} from '../../../enums';
import CustomSearch from '../../../components/CustomSearch';
import  PhoneInput  from 'react-phone-input-2';
import CustomsModal from '../../../components/CustomModal';
import DeleteModal from '../../../components/DeleteModal';
// import ViewDetail from '../../../components/ViewDetail';

export default function Staff() {

    const brand                             = useContext(BrandContext)
    const [loader, setLoader]               = useState(false)
    const [showModal, setShowModal]         = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [staff, setStaff]                 = useState([])
    const [tableData, setTableData]         = useState([])
    const [fetchedData, setFetchedData]     = useState(false)
    const [filteredData, setFilteredData]   = useState();
    const [threeBtn,setthreeBtn]  = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setdeleteLoader]=useState(false);
    const [state, setState] = useState({
        loader   : false,
        apiError : ''
    })


    const getRole = (roles)=>{
        if(roles.includes(User.Roles.ADMIN)) return User.Roles.ADMIN
        if(roles.includes(User.Roles.MARKETER)) return User.Roles.MARKETER
    }

    const getTableList = (list) => {
        return list.map(user=>createData({
            id           : user._id,
            accountName  : user.firstName + ' ' + user.lastName,
            accountEmail : user.email,
            accountRole  : getRole(user.roles),
            subBrand     : user.subBrand,
            actions      : <ActionComponent user={user} staffList={list} updateTable={updateTable} />,
            user         : user
        }))
    }

    const updateTable = (list) => {
        const staffList = getTableList(list)
        fetchedData && setTableData([...staffList])
        fetchedData && setLoader(false)
    }

    const ViewMoreIconClick =(e,index)=>{
        e.stopPropagation();
        e.preventDefault();
        if (threeBtn === index) setthreeBtn();
        else setthreeBtn(index);

    }


    
   


    const getStaff = async () => {
        const query = {brandId: brand._id, delete: false}
        const {error, response} = await StaffService.Get({query})
        if(error) return;
        if(response.data) response.data.map((staf, index)=> { staf = {...staf, subBrand: '-'}; setStaff(prevState => [...prevState, staf])});
    }
    
    const getSubBrand  = async () => {
        const query = { parentBrandId : brand._id, brandType : Brand.BrandTypes.SUB_BRAND, delete: false}
        const {error, response}  = await BrandService.Get({query})
        if(response) setSubBrand(response.data);
        if(response) 
        {
            response.data.map( async (element, idx)=>{
                let query = {brandId: element._id, delete: false}
                const getStaff = await StaffService.Get({query})
                if(getStaff.response)  getStaff.response.data.map((staf, index)=>{staf = {...staf, subBrand: element.brandName}; setStaff(prevState => [...prevState, staf])});
                else return;
            })
        }
    }
    
    const onLoad = async () => {
        setLoader(true);
        setStaff([])
        await getStaff()
        if(brand.brandType === Brand.BrandTypes.SHARED_LOYALITY) await getSubBrand()
        setFetchedData(true);
    }

    useEffect(async() => {
        updateTable(staff)
    }, [staff, fetchedData])

    useEffect(onLoad,[])


    function createData({user, accountName ,accountEmail, accountRole, subBrand, actions, id}) {
        return { 
            props: {
                name     : 'rowProps',
                class    : '',
                id       : id,
                checkbox : false
            },
            accountName: {
                label : accountName,
                class : '',
            }, 
            user: {
                label : user,
                class : '',
            }, 
            accountEmail: {
                label   : accountEmail,
                class   : '',
            },
            accountRole: {
                label   : accountRole,
                class   : '',
                numeric : false,
            },
            subBrand: {
                label   : subBrand,
                class   : '',
            },
            actions: {
                label   : actions,
                class   : 'text-right',
                numeric : false
            } 
        };
    }

    const handleSearch = (e) => {
        const filterd = tableData.filter(user=> 
            user.accountName.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) ||
            user.accountEmail.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())    
        )
        setFilteredData(filterd)
    }

    const updateItem2 = (updatedUser,index) => {
        
        let updatedUserData = staff.filter((element, idx) =>{ 
            return element._id != updatedUser._id
        });
        
          console.log('updatedUserData ', updatedUserData)
        // staff[selectedIndex] = updatedUserData;
        updateTable(updatedUserData)
    }

    const handleDelete =async ()=>{
        
        setdeleteLoader(!deleteLoader);
        if(staff[selectedIndex]?._id){

            const payload = {
                _id: staff[selectedIndex]?._id,
                brandId:brand._id,
                delete: true
              };

            // payload._id = staff[selectedIndex]?._id;
            // payload.brandId = staff[selectedIndex]?.brandId;
            
            const {error, response} = await StaffService.Update({toaster: true, payload});
            console.log('response' , response)
            console.log("response data delete",response.data);
            if(response){
                    response && updateItem2(response.data);
            }
            // setState({...state, loader: false})
        }
        
        

        setState({...state, loader: false})
        // console.log("Load",state.loader);
        setdeleteLoader(!deleteLoader);
        setDeleteModal(!deleteModal);
    }

    const [subBrand, setSubBrand] = useState()
    const layout = useContext(LayoutContext)

    useEffect(()=>{
        layout.setLayout({
            title       : 'Settings',
            switch      : "",
            borderBottom: false,
            button      : '',
        })
    },[])

    const UserImagePart = ({firstName, lastName, image, id, email}) => {
        return(
            <div className="d-flex align-items-center">
                {image && image[0]!=="#" ?
                    <img className="userImagePart" src={image} width="30" /> : 

                    utils.getAvatar({
                        firstName : firstName,
                        lastName  : lastName,
                        id        : id,
                        tableName : 'customers',                        
                        width     : '38px',
                        heigth    : '38px',
                        bgColor   : image
                    })
                }
                &nbsp;&nbsp;
                <div>
                    <div className=" color-neutral90 Heading15M capitalize">{lastName ? firstName+" "+lastName : firstName}</div>
                    <div className='color-neutral70 Link13R'>{utils.capitalize(email)}</div>
                </div>
            </div>
        )
    }

    return (
        <div id="Staff">
            {loader ? <Loader  baseHeight={'70vh'}  width={180} height={180} />
            
            :
                <>
                    <div className='d-flex space-between mb_12 w-100 align-items-center'>
                        <div className='Heading22M color-neutral100'>Manage Members</div>
                        <CustomButton
                            btntext   = "Add Member"
                            onClick={()=>{setShowModal(true);setSelectedIndex('')}}
                        />
                    </div>
                    <div className='bar w-100'></div>     
                    <div className="">
                    {tableData.length > 0 ? <div id="tables">
                        <div className='w-100'>
                        {/* <div className='search d-flex justify-content-right w-100 pb_8  mt_8'>
                            <div className='pt_8 pb_8 cp'><SvgIcons.FilterIcon /></div>
                            <div className='color-neutral60 U14M pl_8 pr_16 pt_8 pb_8 cp'>Filters</div>
                            <CustomSearch 
                                className       = "searchField w-45"
                                placeholder     = 'Search'
                                variant         = "standard"
                                icon            = {<SvgIcons.SearchIcon height="24px" width="24px"/>}
                                position        = 'start'
                                border          = '0px'
                                backgroundColor = '#EAEBF0'
                                onChange        = {handleSearch}
                                InputProps      = {{ startAdornment: <SvgIcons.SearchIcon height="24px" width="24px"/> ,disableUnderline: true, style: { fontSize: 14, paddingLeft: '12px'} }}
                            />
                        </div> */}
                            <div>
                                <div className='d-flex w-100 color-neutral90 Link11M audienceTableGrid overflow-hidden'>
                                    <div className='col-9 pt_16 pb_16 cp ' 
                                    >
                                        {filteredData ? filteredData.length+" MEMBERS" : tableData?tableData.length+ " MEMBERS" : "MEMBERS"}
                                    </div>
                                    <div className='col-3 pt_16 pb_16 text-left cp' >
                                        EMAIL
                                    </div>
                                </div>
                                <div className='bar'></div>

                                <div className='data'>

                                {
                                    filteredData ? 
                                    filteredData.length > 0 && filteredData.map((element,idx)=>(
                                        <>
                                        <div className='d-flex align-items-center w-100 dataRow tableRowHover' 
                                        >
                                            <div className='col-9'>
                                                <UserImagePart email={element.accountRole.label} firstName={element.accountName.label} image={element.user.label.avatar}
                                                />
                                            </div>
                                            <div className='col-3 text-right color-neutral90 Link13R'>
                                                {element.accountEmail.label}
                                            </div>
                                        </div>
                                        <div className='bar'></div>
                                        </>
                                    ))
                                    :
                                    tableData.length > 0 && tableData.map((element,idx)=>(
                                        <>
                                        {/* {console.log('td', element)} */}
                                        <div idx={idx} className='d-flex align-items-center w-100 dataRow tableRowHover' 
                                        onClick={()=>{setShowModal(true); setSelectedIndex(idx)} }
                                        // onClick={()=>{handleSelectedCustomer(element)}}
                                        >
                                            <div className='col-8'>
                                                <UserImagePart email={element?.accountRole?.label} firstName={element?.accountName?.label} image={element?.user?.label?.avatar}
                                                //  customers={filteredCustomers} lastName={element.user.lastName} id={element.user._id} image={element.user.avatar}
                                                 />
                                            </div>
                                            <div className='col-3 text-right color-neutral90 Link13R'>
                                                {element?.accountEmail?.label}
                                            </div>


                                    <div className='col-1'>
                     <ClickOutside

                        onClick={()=>{if (threeBtn == idx) setthreeBtn();}}
                      >
                        <div
                          className="viewMoreIcon float-right rotate-right cp"
                        //   onClick={()=>{setthreeBtn(idx)}}
                        onClick={(e)=>{ViewMoreIconClick(e,idx)}}
                        >
                          <SvgIcons.ViewMoreIcon />
                        </div>

                        {threeBtn == idx && (
                          <div
                            className="viewdetail position-absolute right-0"
                            style={{ top: "10px", zIndex: "1" }}
                          >
                            <ViewDetail
                              items={[
                                {
                                  name: "Edit",
                                  function: (e) => {
                                    setShowModal(true)

                                  },
                                },
                                {
                                  name: "Delete",
                                  function: (e) => {     
                                    setDeleteModal(true);
                                    e.stopPropagation();
                                  },
                                },
                              ]}
                            />
                          </div>
                        )}
                      </ClickOutside>
                                     </div>

                                        </div>
                        
                                    {/* <div className='bar'></div>  */}
                    
                                        </>
                                    ))
                                }

                                    </div>
                            </div>
                        </div>
                    </div>

                    :
                    <Loader  baseHeight={'70vh'}  width={180} height={180} />
                    // <EmptyScreen btnClick={()=>setShowModal(true)} btnTitle={'Add User'} title={'Add User'} />

                    }
                    </div>
                    {
                         <CustomsModal
                         open={deleteModal}
                         minWidth={"600px"}
                         component={
                           <DeleteModal
                             icon={deleteLoader}
                             title="Reward"
                             onCancel={() => setDeleteModal(false)}
                             onDelete={handleDelete}
                           />
                         }
                         onClose={() => setDeleteModal(false)}
                       />
                    }
                    {showModal && 
                        <CustomModal 
                            open      = {showModal}
                            onClose   = {() => {setShowModal(false); setSelectedIndex('')}}
                            maxWidth  = {600}
                            title     = "Add Staff"
                            minWidth  = {600} 
                            component = {<ModalComponent setTableData={setTableData} selectedIndex={selectedIndex} subBrand={subBrand} closeModal={()=>setShowModal(false)} staffList={staff} setStaff={setStaff} updateTable={updateTable} tableData={tableData}/>}
                        />
                    }
                </>
            }
       </div>
    )
}


const ActionComponent = ({user, staffList, updateTable, setStaff,  selectedIndex}) => {

    const brand = useContext(BrandContext);
    const agency = useContext(AgencyContext);

    const [viewMore, setViewMore] = useState(false)
    const [loader, setLoader]     = useState()
    const [loader2, setLoader2]     = useState()

    const ViewMoreIconClickedFunc = (e) => {
        e.stopPropagation()
        setViewMore(!viewMore);
    }

    const removeItem = () => {
        const index = staffList.findIndex(item=>item._id===user._id)
        staffList.splice(index, 1)
        updateTable(staffList)
    }

    const handleDelete = async (e) => {
        e.stopPropagation()
        setLoader(user._id)
        if(user.roles.includes(User.Roles.ADMIN)) return
        const query = {permissionId: user.permissionId}
        const {error, response} = await StaffService.Delete({toaster: true, query})
        response && removeItem()
        setLoader(false)
        setViewMore(false)
    }

    const handleResend = async (e) => {
        e.stopPropagation()
        setLoader2(user._id)

        const payload = {
            email     : user.email,
            role      : user.roles[0],
            userId    : user._id,
            firstName : user.firstName
        }

        if(brand.brandType !== Brand.BrandTypes.SUB_BRAND && brand.integrations && brand.integrations.emailService && brand.integrations.emailService.awsEmail) payload.from = brand.integrations.emailService.awsEmail

        if(agency && agency.whiteLabel) payload.url        = agency.domain
        if(agency && agency.whiteLabel) payload.logo       = agency.logo
        if(agency && agency.whiteLabel) payload.agencyName = agency.agencyName
        
        if(!agency || !agency.whiteLabel) payload.url = env.WALLETLY_DOMAIN
      
        if(user.lastName) payload.lastName = user.lastName


        const {error, response} = await StaffService.ResendEmail({toaster: true, payload})
        setLoader2(false)
        setViewMore(false)
    }

    const viewMoreItems = [
       {
           name       : 'Delete',
           function   : handleDelete,
           disabled   : user.roles.includes(User.Roles.ADMIN),
           processing : loader === user._id
       },
       {
            name       : 'ResendEmail',
            function   : handleResend,
            disabled   : user.verified,
            processing : loader2 === user._id
        },
    ]

    return(
        <div className="d-flex ">
            <div className="cp position-relative ml_16" onClick={(e)=>ViewMoreIconClickedFunc(e)}>
                <SvgIcons.ViewMoreIcon color={ColorSchemeCode.tableActionDropdownColor}/>
                <ClickOutside onClick={()=>setViewMore(false)}>
                    {viewMore &&
                        <div className="viewdetail position-absolute z-index-9">
                            <ViewDetail items={viewMoreItems}/>
                        </div>
                    }
                </ClickOutside>
            </div>
        </div>
    )
}


const ModalComponent = ({closeModal, staffList, setTableData, updateTable, subBrand, selectedIndex, setStaff}) =>{

    const Roles = [User.Roles.ADMIN, User.Roles.MARKETER]

    const agency = useContext(AgencyContext);
    const brand = useContext(BrandContext)

    const [state, setState] = useState({
        loader   : false,
        apiError : ''
    })

    const initState = {
        fullName    : staffList[selectedIndex]?.firstName || '',
        email       : staffList[selectedIndex]?.email || '',
        dialingCode : staffList[selectedIndex]?.dialingCode || agency && agency.whiteLabel ? agency.dialingCode : '',
        phone       : staffList[selectedIndex]?.phone || '',
        subbrand    : '',
        // fullPhone   : staffList[selectedIndex]?.dialingCode + staffList[selectedIndex]?.phone || '',
        role        : staffList[selectedIndex]?.roles[0] || User.Roles.MARKETER,
    }
    
    const addItem = (newUser) => {
        staffList.push(newUser)
        updateTable(staffList)
    }

    const updateItem = (updatedUser,index) => {
        let updatedUserData = {_id : updatedUser._id, firstName : updatedUser.firstName, email : updatedUser.email, dialingCode : updatedUser.dialingCode, roles : [updatedUser.role], phone : updatedUser.phone }
        staffList[selectedIndex] = updatedUserData;
        updateTable(staffList)
    }

    const handleSubmit = async () => {
    
        setState({...state, loader: true})

        // if(formik.values?.fullPhone !=''){
        const fullPhone = utils.getCountryCode(formik.values?.fullPhone.includes('+') ? formik.values?.fullPhone : '+'+formik.values?.fullPhone)
    // }

        let names     = formik.values.fullName.split(' ')
        let firstName = names.shift()
        let lastName  = names.join(' ')

        const payload = {
            firstName,  
            email       : formik.values.email,
            phone       : fullPhone.withoutCountryCode.replace(/\s/g, ''),
            dialingCode : fullPhone.countryCode,
            role        : formik.values.role,
            brandId     : formik.values.subbrand || brand._id 
        }

        if(brand.brandType !== Brand.BrandTypes.SUB_BRAND && brand.integrations && brand.integrations.emailService && brand.integrations.emailService.awsEmail) payload.from = brand.integrations.emailService.awsEmail

        if(agency && agency.whiteLabel) payload.url        = agency.domain
        if(agency && agency.whiteLabel) payload.logo       = agency.logo
        if(agency && agency.whiteLabel) payload.agencyName = agency.agencyName
        
        if(!agency || !agency.whiteLabel) payload.url = env.WALLETLY_DOMAIN

        if(lastName) payload.lastName = lastName


        if(staffList[selectedIndex]?._id){
            payload._id = staffList[selectedIndex]?._id;
            const {error, response} = await StaffService.Update({toaster: true, payload});
            console.log('response' , response)
            console.log("response data",response.data);
            if(response){
                    response && updateItem(response.data,selectedIndex)
            }
            // setState({...state, loader: false})
        }
        else{
            const {error, response} = await StaffService.Create({toaster: true, payload})
            response && addItem(response.data)
        }
        

        setState({...state, loader: false})
        console.log("Load",state.loader);
        closeModal()
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : StaffVld.Create,
        isInitialValid   : false
    })

    
    return(
        <div >
            <form onSubmit={formik.handleSubmit} >
                {            
                <div className="modalComponent">
                    <div className="mt_24">
                        <CustomTextField 
                            className  = "w-100"
                            label      = "Account Name"
                            type       = "text"
                            name       = "fullName"
                            value      = {formik.values.fullName }
                            onChange   = {formik.handleChange}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            error      = {formik.touched.fullName && formik.errors.fullName}
                            helperText = {formik.touched.fullName && formik.errors.fullName ? formik.errors.fullName : ''}
                        />
                    </div>
                    <div className="mt_24">
                        <CustomTextField 
                            className  = "w-100"
                            label      = "Account Email"
                            type       = "text"
                            name       = "email"
                            value      = {formik.values.email}
                            onChange   = {formik.handleChange}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            error      = {formik.touched.email && formik.errors.email}
                            helperText = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                        />
                    </div>
                    {brand.brandType === Brand.BrandTypes.SHARED_LOYALITY&&<div className="mt_24">
                        <CustomSelect 
                            className = "w-100"
                            label     = "Sub Brands"
                            options   = {subBrand && subBrand.map((element) => ({
                                name: element.brandName,
                                value: element._id
                            }))}
                            name      = "subbrand"
                            value     = {formik.values.subbrand}
                            onChange   = {formik.handleChange}
                            error      = {formik.touched.subbrand && formik.errors.subbrand}
                            helperText = {formik.errors.subbrand && formik.errors.subbrand}
                        />
                    </div>}

                    <div className="mt_24">
                        <PhoneInput
                            inputClass  = {'w-100 Body14R phoneInputField'}
                            inputStyle  = {{border: '1px solid '+ColorSchemeCode.OtherBorder, height: '42px'}}
                            value       = {formik.values.fullPhone || ''}
                            // value={formik.values.phone}
                            onChange    = {phone => formik.setValues({...formik.values, fullPhone: phone })}
                            // onChange={formik.handleChange}
                        />
                    </div>

                    <div className="mt_24 customLabel">
                        Role Type
                    </div>

                    <div className="mt_8 d-flex mb_24">
                        {Roles.map((role, idx )=> (
                            <div className={`${idx != 0 && 'ml_8'}`}>
                                <div
                                    // className="cp borderRadius-4 p_8 color-white" 
                                    className={formik.values.role === role ? 
                                        "customfieldBtn borderRadius-6 capitalize Caption12M bg-color-color(neutral20) color-themeColor bg-color- cp p_8"
                                        :
                                        "Caption12M borderRadius-6 capitalize customfieldBtn color-ButtonSecondaryText cp bg-color-bgTextBox p_8"
                                    }
                                    onClick         = {()=>formik.setValues({...formik.values, role})}
                                    style={{
                                        border : formik.values.role === role ? '2px solid '+ColorSchemeCode.themeColor : '2px solid '+ColorSchemeCode.bgTextBox,
                                        backgroundColor : formik.values.role === role && ColorSchemeCode.primary0
                                    }}
                                    // focus           = {formik.values.role === role ? ColorSchemeCode.brandingPrimaryColor : ColorSchemeCode.paragraphInputColor}
                                    // hover           = {formik.values.role === role ? ColorSchemeCode.brandingPrimaryColor : ColorSchemeCode.paragraphInputColor}
                                    // backgroundColor = {formik.values.role === role ? agency.agencyColor : ColorSchemeCode.paragraphInputColor}
                                    // color           = {formik.values.role === role ? agency.agencyColor : ColorSchemeCode.paragraphInputColor}
                                    // focus           = {formik.values.role === role ? agency.agencyColor : ColorSchemeCode.paragraphInputColor}
                                    // hover           = {formik.values.role === role ? agency.agencyColor : ColorSchemeCode.paragraphInputColor}
                                >{role}</div>
                            </div>
                        ))}
                    </div>
                </div>
                // })
            }
                <div className="row justify-flex-end modalButton">
                    <div className="mr_8">
                        <CustomButton 
                        varient         = "secondary"
                        btntext         = "Cancel"
                        onClick         = {closeModal}
                        />
                    </div>
                    <div className="">
                        <CustomButton 
                        btntext  = {staffList[selectedIndex]?._id ? "Update" : "Add"}
                        onClick  = { handleSubmit}
                        disabled = {state.loader || !formik.isValid|| formik.values.fullPhone =='' || formik.values.email =='' ||formik.values.fullName =='' ||formik.values.role == '' }
                        icon     = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                        />
                    </div>
                </div>
            </form>

        </div>
    )
}