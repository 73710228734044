import React from 'react'
import { PngIcons, SvgIcons } from '../../icons'
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router-dom'

function LandingPageRedeem() {
    const history = useHistory();
    const params = useParams()

    window.onbeforeunload = function (e) {
        if (e) e.returnValue = history.push('/page/'+params.growthtoolId)
        return history.push('/page/'+params.growthtoolId);
    };

    return (
        <div>
            <div className="ml_24 mt_24 cp" onClick={()=>history.push({pathname: '/page/'+params.growthtoolId+'/dashboard', state: history.location.state})}><SvgIcons.CrossIcon color="black"/></div>
            <div className="w-100 middle" style={{marginTop:'48px'}}>
            {/* <div className="w-100 middle" style={{height:'80vh'}}> */}
                <img src={PngIcons.Alarm} alt="congrats" className="middle" height="88px" width="auto" />
                <div className="ml_24 mr_24 mt_24 Heading20M color-GeneralBlack text-align-center" >You have already check-in, <br/>Please visit us after 24 hour</div>
            </div>
        </div>
    )
}

export default LandingPageRedeem
