import React, { useContext , useEffect, useState} from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import PageTitle from '../../../components/setting/SettingPageTitles';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomTextField';
import CustomModal from '../../../components/CustomModal';
import { useFormik } from 'formik';
import { UserContext } from '../../../context/User.context';
import LogoUploader from '../../../components/LogoUploader';
import { UserVld } from '../../../validation';
import { UserService } from '../../../services';
import { SvgIcons } from '../../../icons';
import { utils } from '../../../utils';
import CustomsModal from '../../../components/CustomModal';
import ImageModal from '../../../components/ImageModal';
import { LayoutContext } from '../../../context/Layout.context';
import localforage from 'localforage';
import { ImageModalV2 } from '../../../components/ImageModalV2/ImageModalV2';
import CopyText from '../../../components/CopyText';
import CustomTextField from '../../../components/CustomTextField';

export default function Profilesetting() {

    const user = useContext(UserContext)
    // const [showModal, setShowModal] = useState(false)

    const [imageModal, setImageModal]    = useState({
        logo: {open: false, imageEvent: '', return: ''},
    })


    const [state, setState] = useState({
        updateLoader     : false,
        passwordLoader   : false,
        emailLoader      : false,
        uploadImageError : ''
    })

    const initState = {
        fullName      : user.firstName + ' ' + user.lastName,
        email         : user.email,
        logo          : user.avatar,
        agencyLogoFile: ''
        
    }

    const handleSubmit = async () => {
        
 

        let avatar
        const desiredPath = `${user._id}/`
        setState({...state, updateLoader: true})

        if(formik.values.brandLogoFile)
        avatar = await utils.uploadImage({file: formik.values.brandLogoFile, desiredPath})
        if(avatar && avatar.error) return setState({...state, updateLoader: false, uploadImageError: avatar.error.message})
        
        let names     = formik.values.fullName.split(' ')
        let firstName = names.shift()
        let lastName  = names.join(' ')

        const payload = {
            _id       : user._id,
            firstName : firstName,
            lastName  : lastName,
            email     : formik.values.email,
            // password  : formik.values.newPassword
        }

        if(avatar && avatar.response) payload.avatar = avatar.response.data

        const { error, response } = await UserService.Update({toaster: true, payload})
        if(response) localforage.setItem('user', response.data)
        setState({...state, updateLoader: false})
        if(response) user.updateUser(response.data)
        return
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues      : {...initState},
        onSubmit           : handleSubmit,
        validationSchema   : UserVld.Update,
        isInitialValid     : false,
        enableReinitialize : true
    })


    const avatarChange = (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        formik.setValues({...formik.values, profilePhotoFile: file[0]})
    }

    const handleEmailChange = async (e) => {
        if(e.target.value === initState.email || !e.target.value || formik.errors.email) return
        
        setState({...state, emailLoader: true})
        const email = e.target.value
        const query = {email}
        const {error, response} = await UserService.GetByQuery({query})
        if(response) {
            formik.setErrors({email: 'email already exist'})
            return setState({...state, emailLoader: false})
        }
        return setState({...state, emailLoader: false})
    }

    const layout = useContext(LayoutContext)
    useEffect(()=>{
        layout.setLayout({
            title    : "Personal information",
            switch      : "",
            button   : <CustomButton
                            btntext   = "Save Changes"
                            onClick   = {handleSubmit}
                            icon      = {state.updateLoader && <CircularProgress size={20} color={'inherit'}/>}
                            disabled  = {
                                            state.updateLoader ||
                                            formik.errors.profilePhotoFile ||
                                            formik.errors.email ||
                                            formik.errors.fullName
                                        }
                        />
        });
    },[state, formik.isValid, formik.values])
    
    return (
        <div className="profileSetting mt_16 pb_16 pl_40 pr_40">
            <div className="col-12 Body14R color-c6E7191 mt_16 fw-6">Profile photo</div>
            <div className="d-flex">
                <LogoUploader 
                    // src       = {formik.values.profilePhotoFile ? URL.createObjectURL(formik.values.profilePhotoFile) : formik.values.avatar}
                    src   = {formik.values.brandLogoFile && typeof formik.values.brandLogoFile === 'object' ? URL.createObjectURL(formik.values.brandLogoFile) : formik.values.logo}
                    btntext   = "Change Profile"
                    // onChange  = {avatarChange}
                    onChange = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                    error     = {formik.errors.brandLogoFile}
                />
            </div>
            <CustomsModal
                    open      = { imageModal.logo.open }
                    onClose   = { ()=> setImageModal({...imageModal, logo: {...imageModal.logo, open: false}}) }
                    minWidth  = "470px"
                    minHeight = "470px"
                    padding   = "0px"
                    component = { 
                        <ImageModalV2 
                            imageModal    = { imageModal }
                            setImageModal = { setImageModal }
                            image         = { 'logo' }
                            formik        = {formik}
                        />
                    }
            />
                    
            <div className="col-md-7 mt_16">
                <CustomInput
                    className   = "w-100"
                    label       = "Profile Name"
                    placeholder = "Full Name"
                    type        = "text"
                    name        = "fullName"
                    value       = {formik.values.fullName}
                    onChange    = {formik.handleChange}
                    inputProps  = {{ onFocus: formik.handleBlur }}
                    error       = {formik.touched.fullName && formik.errors.fullName}
                    helperText  = {formik.touched.fullName && formik.errors.fullName && formik.errors.fullName}
                />
            </div>
            <div className="col-md-7 mt_16">
                <CustomInput
                    className   = "w-100"
                    label       = "Email Address"
                    placeholder = "Email"
                    type        = "text"
                    name        = "email"
                    value       = {formik.values.email}
                    onChange    = {formik.handleChange}
                    onKeyUp     = {handleEmailChange}
                    icon        = {state.emailLoader ? <CircularProgress size={20} color={'inherit'}/> : !formik.errors.email ? <SvgIcons.Check /> : ''}
                    position    = 'end'
                    inputProps  = {{ onFocus: formik.handleBlur }}
                    error       = {formik.touched.email && formik.errors.email}
                    helperText  = {formik.touched.email && formik.errors.email && formik.errors.email}
                />
            </div>
          
            <div className="col-md-7 mt_16 position-relative">
                <CustomTextField 
                    className    = "w-100 disabled"
                    label        = "User Id"
                    // name         = "brandId"
                    value        = {user.idKey}
                    // inputProps   = {{tabIndex: '2', onFocus: formik.handleBlur }}
                    // error        = {formik.touched.brandName && formik.errors.brandName}
                    // helperText   = {formik.touched.brandName && formik.errors.brandName ? formik.errors.brandName : ''}
                />
                <div style={{position: 'absolute', top: '10px', right: '30px'}}>
                    <CopyText content={user.idKey} hideText={true}/>
                </div>
            </div> 
           
        </div>
    )
}


const ConfirmPasswordComponent = ({loader, formik}) => {
    return(
        <div className="confirmPasswordModal text-center">
            <div className="row">
                <div className="col-12 color-textfieldColor fw-6">
                    Please Confirm Changes
                </div>
                <div className="Body14R col-12 mt_16">
                    Please confirm the changes by entering your password.
                </div>
                <div className="col-12">
                    <CustomInput
                        placeholder = "Confirm Password"
                        className   = "w-100"
                        type        = "password"
                        name        = "password"
                        value       = {formik.values.password}
                        onChange    = {formik.handleChange}
                        inputProps  = {{ onFocus: formik.handleBlur }}
                        error       = {formik.touched.password && formik.errors.password}
                        helperText  = {formik.touched.password && formik.errors.password && formik.errors.password}
                    />
                </div>
                <div className="col-12">
                    <CustomButton
                        btntext   = "Confirm"
                        className = "fw-5 fs-14 borderRadius-10 mt_16 w-65"
                        // height    = {"42px"}
                        disabled  = {loader || !formik.isValid}
                        onClick = {() => formik.submitForm()}
                        icon = {loader && <CircularProgress size={20} color={'inherit'}/>}
                    />

                </div>
            </div>
        </div>
    )
}