import React, {useContext, useEffect} from 'react'
import {Route, Switch, Redirect, useHistory} from "react-router-dom";

import SettingHeader from '../../components/setting/SettingHeader';

import Listing from './subpages/Listing';
import AddGiveaway from './subpages/AddGiveaway';

import { LayoutContext } from '../../context/Layout.context';

export default function Giveaway(props) {
    const layout = useContext(LayoutContext)

    return (
        <div id="Inventory" >
            <div>
           
            </div>

            <div   className  = "">
               <Switch>
                    <Route path       = {props.match.url + '/listing'} component = {Listing}/>
                    <Route exact path = {props.match.url + '/add'}     component = {AddGiveaway}/>

                    <Redirect path={props.match.url+ '/*'} to={props.match.url+ '/listing'} />
                    <Redirect path={props.match.url} to={props.match.url+ '/listing'} />
               </Switch>
            </div>
        </div>
    )
}
