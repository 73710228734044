import { CircularProgress } from '@mui/material'
import React, { useContext, useState } from 'react'
import CustomButton from '../../components/CustomButton'
import CustomTextField from '../../components/CustomTextField'
import { AgencyContext } from '../../context/Agency.context'
import { PngIcons } from '../../icons'
import { PassService } from '../../services'

function CurrentPoint() {
    const [email, setEmail]     = useState('')
    const [brandId, setBrandId] = useState('')
    const [data, setData]       = useState()
    const [loader, setLoader]   = useState(false)
    const [next, setNext]       = useState(0)
    const agency                = useContext(AgencyContext);
    return (
        // <div className="middle" style={{height:'90vh'}}>
            // <CustomTextField 
            //     label    = {"email"}
            //     value    = {email}
            //     type     = {"email"}
            //     onChange = {(e)=>setEmail(e.target.value)}
            // />

            // <CustomButton 
            //     btntext="Confirm"
            //     onClick={async()=>{
            //         const data = await PassService.GetPublicPoints({query: {email: email}})
            //         if(data && data.response && data.response.currentPoints) setData(data.response.currentPoints)
            //     }}
            // />

        //     <div>
        //         {
        //             data && data
        //         }
        //     </div>
        // </div>
        <div className="position-relative">
      <div id='Auth' className="middle">
          <div className="cardForm text-center" style={{justifyContent: 'center'}}>
                {next === 0 && <><div>
                    <img src={(agency.logo || PngIcons.WalletlyShadedLogo)} height="60px" width="60px" className='borderRadius-60' alt="" />
                    <div className="Heading22M color-neutral100">{"Point Information"}</div>  
                    <div className="mt_16 Body16R color-Paragaph text-center">Please enter your email to know your points status.</div>
                </div>
                    <div className="w-100 text-center mt_100">
                        <CustomTextField 
                            label     = {"email"}
                            value     = {email}
                            type      = {"email"}
                            className = "w-100 mb_24"
                            onChange  = {(e)=>setEmail(e.target.value)}
                        />
                        <CustomTextField 
                            label     = {"Brand ID"}
                            value     = {brandId}
                            className = "w-100 mb_24"
                            onChange  = {(e)=>setBrandId(e.target.value)}
                        />

                        <div>
                            <CustomButton 
                                btntext   = "Confirm"
                                className = "w-100"
                                height    = "40px"
                                disabled  = {loader}
                                icon      = {loader && <CircularProgress size={20} color={'inherit'}/>}
                                onClick   = {async()=>{
                                    setLoader(true)
                                    const data = await PassService.GetPublicPoints({query: {brandId: brandId,email: email.toLocaleLowerCase(), delete: false}, toaster: true})
                                    if(data && data.response && data.response.data && data.response.data.currentPoints) setData(data.response.data.currentPoints)
                                    if(data && data.response && data.response.data && data.response.data.currentPoints) setNext(1)
                                    setLoader(false)
                                }}
                            />
                        </div>
                    
                    </div></>}
                {next === 1 && <div>
                    <div style={{minWidth: '500px'}}></div>
                    <div style={{
                        padding      : '16px 32px 16px 24px',
                        background   : 'linear-gradient(90deg, #00C6FF 0%, #0072FF 100%)',
                        boxShadow    : '0px 12px 24px -14px #70B1FF',
                        borderRadius : '4px'
                    }}>
                        <div className="color-white Link14M mb_24 text-align-left">Loyalty Card</div>
                        <div className="d-flex mb_40 align-items-center">
                            <div className="Heading22M color-white mr_8" style={{fontSize: '40px', lineHeight: '66px'}}>{data && data}</div>
                            <div className="Heading22M color-white mr_8" style={{fontSize: '24px', lineHeight: '39px'}}>{"pts"}</div>
                        </div>
                    </div>
                    {/* <div className="color-neutral100 Heading22M mb_24">{data && data+" Points"}</div> */}
                    <CustomButton className="w-100 mt_24" height="40px" btntext="Back" varient="secondary" onClick={()=>setNext(0)} />
                </div>}
          </div>
          
          <div className="absoluteMiddle backgroundCircle">
          </div>
      </div>
    </div>
    )
}

export default CurrentPoint
