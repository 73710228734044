import React, {useContext, useState, useEffect} from 'react'

import { Campaign } from "../../enums";

import Loader from "../../components/Loader";
import PassDesigner from './PassDesigner';

import { BrandContext } from '../../context/Brand.context';
import { PassDesignService } from "../../services";
import { LayoutContext } from '../../context/Layout.context';


export default function PassDesignMain({selectedCampaign}) {
    console.log('sdsdlk;sd',selectedCampaign);
    const brand  = useContext(BrandContext)
    const layout = useContext(LayoutContext)

    const [loader, setLoader] = useState(true)
    const [design, setDesign] = useState()

    const createPreBuiltPassDesign = async () => {
        const payload = {
            brandId      : brand._id,
            campaignId   : selectedCampaign?._id,
            campaignType : Campaign.CampaignTypes.LOYALTY_CARD
        }
        console.log('selectedCampaignpayload',payload);


        const { error, response } = await PassDesignService.CreatePreBuiltPassDesign({payload})
        if(response) {
        console.log('prebuildresponse',response);
            setDesign(response.data)
            setLoader(false)
        }else{
        console.log('prebuildreserror',error);

        }
    }

    useEffect(()=>{
        setTimeout(() => {
            layout.setLayout({
                title: 'Pass Design',
                borderBottom : true,
                switch      : "",
                button: <></>,
                edit         : false,
            })
        }, 100);
    },[])

    const getCampaignDesign = async () => {
        console.log('selectedCampaign',selectedCampaign);
        const query = {campaignId: selectedCampaign?._id}
        console.log('query Campaign' , query);
        const {error, response} = await PassDesignService.GetQueriedPassDesign({query})
        error && createPreBuiltPassDesign()
        if(response){
            setDesign(response.data[0])
            setLoader(false)
        }
    }

    const unMount = () => layout.setLayout({expandedBar: true, header: true, scroll: false})

    const onLoad = () => {
        layout.setLayout({expandedBar: false, header: false, scroll : true})
        return unMount
    }

    useEffect(onLoad, [])
    useEffect(getCampaignDesign, [selectedCampaign])




    return (
        <>
            {loader && <Loader width={180} height={180}/>}
            {!loader && <PassDesigner design={design}/>}
        </>
    )
}
