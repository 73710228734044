import * as yup from 'yup';
import { enums } from '../enums';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    title       : yup.string().required(REQ_ERROR),
    description : yup.string().required(REQ_ERROR),
    color       : yup.string(),
    background  : yup.string(),
    // link        : yup.string().matches(/^(?!\-)(xn--)?(?:[a-zA-Z\u3040-\uFFEF\d\-]{0,62}[a-zA-Z\u3040-\uFFEF\d]\.){1,126}(?!\d+)[a-zA-Z\u3040-\uFFEF\d\-]{1,63}$/,'Please check if the URL is correct, you may have added "https://" at the start of the link.'),
    link        : yup.string().matches(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,'Enter correct url'),
    media       : yup.mixed()
                        .test('coverSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true),
    // link        : yup.string()
    //             .matches(
    //                 /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //                 'Enter correct url!'
    //             ),

})


export {
    Create,
}