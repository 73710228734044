import { CircularProgress } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton';
import { AgencyContext } from '../../../context/Agency.context';
import { BrandContext } from '../../../context/Brand.context';
import { LayoutContext } from '../../../context/Layout.context';
import { AgencyService, BrandService, UtilsService } from '../../../services';
import { BrandVld } from '../../../validation';
import { useFormik } from 'formik';
import { ClickOutside, utils } from '../../../utils';
import { Brand, enums } from '../../../enums';
import { CardElement } from '@stripe/react-stripe-js';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import CustomsModal from '../../../components/CustomModal';
import CustomTextField from '../../../components/CustomTextField';
import CustomInput from '../../../components/CustomTextField';
import { PngIcons, SvgIcons } from '../../../icons';
import { showToaster } from '../../../utils/utils';
import CustomTable from '../../../components/customTable/CustomTableAgency';
import Loader from '../../../components/Loader';

function Billing() {
    const agency   = useContext(AgencyContext)
    const brand    = useContext(BrandContext)
    const layout   = useContext(LayoutContext)
    const stripe   = useStripe()
    const elements = useElements()
    const [loader, setLoader] = useState(false)
    const [modal, setModal]   = useState(false)
    const [state, setState] = useState({
        loader    : false,
        cardError : '',
        plans     : null,
        active    : ''
    })


    const [editCardElement, setEditCardElement] = useState(false)
    const StripeCardElementStyle = {
        base: {
            fontSize        : '15px',
            fontFamily      : "Inter",
            fontWeight      : '400',
            color           :  ColorSchemeCode.textFieldActiveColor,
            '::placeholder' : {
                color : '#aab7c4',
            },
        },
        invalid: {
            color : '#9e2146',
        }
    }

    const handleSubmit = async () => {
        setState({...state, loader: true, cardError: ''})
        if(!stripe || !elements) return setState({...state, loader: false, cardError: 'stripe not loaded'})

        const cardElement = elements.getElement(CardElement)
        console.log('in cardElement',cardElement);

        
        let stripePayment = {}
        if(cardElement) 
        console.log('in cardElement',cardElement);
        stripePayment = await stripe.createPaymentMethod({
            type : 'card',
            card : cardElement,
        })

        console.log('in stripePayment',stripePayment);

        if(stripePayment.error) return setState({...state, loader: false, cardError: stripePayment.error.message})

        const payload = {
            _id            : brand._id,
            stripeCustomer : {
                email         : formik.values.email,
                companyName   : formik.values.companyName,
                paymentMethod : stripePayment.paymentMethod
            }
        }

        const updateBrand = await BrandService.Update({toaster: true, payload})
        setState({...state, loader: false})
        if(updateBrand.response) brand.updateBrand(updateBrand.response.data)
    }

    const initState = {
        email       : brand.stripeCustomer && brand.stripeCustomer.email ? brand.stripeCustomer.email             : brand.email,
        companyName : brand.stripeCustomer && brand.stripeCustomer.companyName ? brand.stripeCustomer.companyName : brand.brandName,
        coupon: "i60bEPjN"
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : BrandVld.Billing,
    })


    const [discounted, setDiscounted] = useState()

    useEffect(async() => {
      setTextLoader(true)
      formik.setValues({...formik.values, coupon: "i60bEPjN"})
      const {response, error} = await AgencyService.StripeCoupon({query: {couponCode: "i60bEPjN"}})
      response && response.data && response.data.length>0 && setDiscounted(parseInt((response.data[0].couponData.amount_off.toString()).slice(0, -2)))
      error && setDiscounted()
      setTextLoader(false)
    }, [])
    
    const [textLoader, setTextLoader] = useState(false)

    const handleCouponCode = async(e) => {
        setTextLoader(true)
        formik.setValues({...formik.values, coupon: e.target.value})
        const {response, error} = await AgencyService.StripeCoupon({query: {couponCode: e.target.value}})
        response && response.data && response.data.length>0 && setDiscounted(parseInt((response.data[0].couponData.amount_off.toString()).slice(0, -2)))
        error && setDiscounted()
        setTextLoader(false)
    }

    const handlePlanSelection = async () => {
        setLoader(true)
        await handleSubmit()

        const payload = {
            _id      : brand._id,
            planName : state.active === "PAY AS YOU GO" ? Brand.Plans.PAY_AS_YOU_GO : state.active === "UNLIMITED" ? Brand.Plans.UNLIMITED : '',
            version  : 1
        }
        if(formik.values.coupon) payload.couponId = formik.values.coupon

        // console.log('paylod', payload);
        const { error, response } = (!brand.subscription || utils.emptyJSON(brand.subscription)) ? 
                                    await BrandService.CreateSubscription({toaster: true, payload}) :
                                    await BrandService.UpdateSubscription({toaster: true, payload})
        console.log('response.data',response.data);
        response && brand.updateBrand(response.data)
        setLoader(false)
        return
        
    }

    const [billingDetail, setBillingDetail] = useState(false)
    const [changePlan, setChangePlan]       = useState(false)

    // React.useEffect(() => {
    //     layout.setLayout({
    //         title: 'Settings',
    //         borderBottom: false,
    //         switch: "",
    //         button: ""
    //     })
    // }, [])


    return (
        <div id="Billing">

            {((!brand.subscription || utils.emptyJSON(brand.subscription) || changePlan ) && !billingDetail)&&
            <div className="d-flex space-between">
                <div className="Heading22M color-neutral100 mb_24">{changePlan ? "Change Plan" : "Select Plan"}</div>
                    {changePlan&&<CustomButton btntext={'Cancel'} onClick={()=>setChangePlan(false)} />}
            </div>}
            
            {brand.subscription && !utils.emptyJSON(brand.subscription) && !billingDetail && !changePlan &&
            <div className="">
                <div className="Heading22M color-neutral100 col-12 pl_0 pr_0 col-md-12">Membership & Billing</div>
                <div className="mt_16 bar col-12 col-md-12 mb_24"></div>
            </div>}
            
            {((!brand.subscription || utils.emptyJSON(brand.subscription) || changePlan ) && !billingDetail)&&
            <>
            <div className="d-flex mb_24">
                <div className="borderRadius-6 cp" style={{border: state.active === "UNLIMITED" ? '3px solid #2F80ED' : '3px solid #E5E5E5', width: '300px'}} onClick={()=>setState({...state, active: "UNLIMITED"})}>
                    <div className={state.active === "UNLIMITED" ? "bg-color-themeColor pt_24 color-GeneralBlack Heading20M text-align-center" : "bg-color-cF7F7F7 pt_24 color-GeneralBlack Heading20M text-align-center"}>{agency.whiteLabel ? "$499/month" : "$199/month"}</div>
                    <div className={state.active === "UNLIMITED" ? "bg-color-themeColor pt_16 pb_16 color-GeneralBlack Link14M text-align-center" : "bg-color-cF7F7F7 pt_16 pb_16 color-GeneralBlack Link14M text-align-center"}>UNLIMTED</div>
                    <div className="m_16">
                        <ul className="Body16R color-neutral100" style={{lineHeight: '40px'}}>
                            <li>Unlimited Subscribers</li>
                            <li>Unlimited Integrations</li>
                            <li>Unlimited Card Templates</li>
                            <li>Unlimited Push Notification</li>
                            <li>Unlimited Geo Notification</li>
                            <li>Unlimited Campaigns</li>
                            <li>Training and Videos</li>
                        </ul>
                    </div>
                </div>
                {/* {!agency.whiteLabel && <div className="borderRadius-6 ml_24 cp" style={{border: state.active === "PAY AS YOU GO" ? '3px solid #2F80ED' : '3px solid #E5E5E5', width: '300px'}} onClick={()=>setState({...state, active: "PAY AS YOU GO"})}>
                    <div className={state.active === "PAY AS YOU GO" ? "bg-color-themeColor pt_24 color-GeneralBlack Heading20M text-align-center" : "bg-color-cF7F7F7 pt_24 color-GeneralBlack Heading20M text-align-center"}>$15/month</div>
                    <div className={state.active === "PAY AS YOU GO" ? "bg-color-themeColor pt_16 pb_16 color-GeneralBlack Link14M text-align-center" : "bg-color-cF7F7F7 pt_16 pb_16 color-GeneralBlack Link14M text-align-center"}>PAY AS YOU GO</div>
                    <div className="m_16">
                        <ul className="Body16R color-neutral100" style={{lineHeight: '40px'}}>
                            <li>500 Subscribers</li>
                            <li>Unlimited Integrations</li>
                            <li>Unlimited Card Templates</li>
                            <li>Unlimited Push Notification</li>
                            <li>Unlimited Geo Notification</li>
                            <li>Unlimited Campaigns</li>
                            <li>Training and Videos</li>
                        </ul>
                    </div>
                </div>} */}
            </div>

            <CustomButton disabled={!state.active} btntext={'Confirm Plan'} onClick={()=>setModal(true)} />
            </>
            }

            {brand.subscription && !utils.emptyJSON(brand.subscription) && !billingDetail && !changePlan &&
            
                <BillingPlanDetails 
                    agency             = {agency}
                    setEditCardElement = {setEditCardElement}
                    editCardElement    = {editCardElement}
                    setBillingDetail   = {setBillingDetail}
                    setChangePlan      = {setChangePlan}
                />
            }

            {billingDetail&&
            <div>
                <div className="d-flex space-between">
                    <div className="Heading22M color-neutral100 mb_24">Membership & Billing</div>
                    <CustomButton varient="secondary" btntext={'Cancel'} onClick={()=>setBillingDetail(false)} />
                </div>
                <div className="Link14M color-neutral100">Your Membership</div>
                <div className="p_16 bg-color-cF7F7F7 mb_8 mt_8 col-6" style={{border: '1px solid #E6E6E6'}}>
                    <div className="Body14R color-neutral60">Your Plan</div>
                    <div className="mb_24 d-flex"><div className="Heading16M color-GeneralBlack mr_4">{utils.capitalizeAll(brand.subscription.planName)}</div><div className="Body14R color-neutral60"> for ${brand.subscription.amount}{'/' + brand.subscription.interval}</div></div>
                    <div className="Body14R color-neutral60">Your next billing month</div>
                    <div className="Body16R color-neutral100">8 Febuary 2022</div>
                </div>
                <div className="col-6 color-neutral60 Caption12R mb_24">Membership fees are billed at the beginning of each period and may take a few days after the billing date to appear on your account.</div>

                <BillingHistory />
            </div>
            }


            {modal&&<CustomsModal 
                component = {<div>

            <div className="billingSetting">
            <div className="Heading22M color-neutral100 mb_24">Set up your credit or debit card</div>
            <div className="row">
                
                <div className="col-12 ">
                    {console.log('editCardElement',editCardElement)}
                    <div className="stripeCardLabel">Your Card</div>
                    { brand.stripeCustomer && brand.stripeCustomer.paymentMethodId &&
                    <>
                        <CardDetail 
                            brand       = {brand}
                            editCard    = {editCardElement}
                            setEditCard = {setEditCardElement}
                            edit        = {true}
                        /> 
                    </>
                    }
                    { (!brand.stripeCustomer || brand.stripeCustomer && !brand.stripeCustomer.paymentMethodId || editCardElement) && 
                        <CardElement
                            className = 'stripeCardElement'
                            options   = {{style: StripeCardElementStyle}}
                            // onChange  = {(e) => // console.log(e.target)}
                        />
                    }
                    
                    <div className="error">{state.cardError}</div>
                </div>
                <div className="col-12 mt_32">
                    <CustomInput
                        label       = "Email Address"
                        placeholder = "abc@xyz.com"
                        className   = "w-100"
                        name        = "email"
                        value       = {formik.values.email}
                        onChange    = {formik.handleChange}
                        inputProps  = {{ onFocus: formik.handleBlur }}
                        error       = {formik.touched.email && formik.errors.email}
                        helperText  = {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                    />
                </div>
                <div className="col-12 mt_32 companyName">
                    <CustomInput
                        label       = "Company Name"
                        placeholder = "Your company name"
                        className   = "w-100"
                        name        = "companyName"
                        value       = {formik.values.companyName}
                        onChange    = {formik.handleChange}
                        inputProps  = {{ onFocus: formik.handleBlur }}
                        error       = {formik.touched.companyName && formik.errors.companyName}
                        helperText  = {formik.touched.companyName && formik.errors.companyName && formik.errors.companyName}
                    />
                </div>
            </div>
            </div>



                    
                    <div className="d-flex p_16 bg-color-bgLightSecondary borderRadius-4 mb_16 mt_24 space-between">
                        <div className="Body16R color-neutral100">{state.active === "UNLIMITED" ? Brand.Plans.UNLIMITED : (state.active === "PAY AS YOU GO" ? Brand.Plans.PAY_AS_YOU_GO : '')}</div>
                        <div className="d-flex align-items-center">
                            {discounted && <div className="Heading16M color-neutral100 mr_8">{state.active === "PAY AS YOU GO" ? "$"+(parseInt(15)-discounted) : (agency.whiteLabel ? "$"+(parseInt(499)-discounted) : "$"+(parseInt(199)-discounted)) }</div>}
                            {<div className="Body16R color-neutral60" style={{textDecorationLine: discounted && 'line-through'}}>{state.active === "PAY AS YOU GO" ? "$15" : (agency.whiteLabel ? "$499" : "$199")}</div>}
                        </div>
                    </div>
                    <div className="d-flex space-between gap-16 mb_24">
                        <CustomButton className="w-50" btntext="Discount Code" varient={"secondary"}/>
                        <CustomTextField label="Coupon" name="coupon" value={formik.values.coupon || 'i60bEPjN'} 
                        className="w-100"
                        onChange={handleCouponCode}
                        icon={textLoader ? <CircularProgress size={20} color={'inherit'}/> : discounted ? <SvgIcons.TickIconSub/> : ''}
                        position={"end"}
                        // onChange={(e)=>formik.setValues({...formik.values, coupon: e.target.value})}
                         />
                    </div>
                    <div className="d-flex justify-content-right">
                        <CustomButton btntext="Cancel" varient="secondary"
                            onClick={()=>setModal(false)}
                        />
                        <CustomButton btntext="Confirm" className="ml_24"
                            disabled        = {loader}
                            icon            = {loader && <CircularProgress size={20} color={'inherit'}/>}
                            onClick={async()=>{
                                await handlePlanSelection();
                                setModal(false)
                            }}
                        />
                    </div>
                </div>} 
                open      = {modal}
                minWidth  = {'500px'}
            />}
        </div>
    )
}

const BillingPlanBox = (props) => {
    return(
        //this is the gray color button box which consists of button 
        <div className="container ">
            <div className="row">
                <div className={"col-12 bg-color-cF6F6F9 billingPlanBox d-flex"}>
                    <div className="col-8 col-md-7 pl_0">
                        <div className="Body14R fw-600 color-neutral80">
                            Current Billing Plan
                        </div>
                        <div className="Body14R fw-300 color-themeColor">
                            Learn more about pricing
                        </div>
                    </div>

                    <div className={`text-capitalize text-center fw-6 color-white tag ${props.btntext === Brand.Plans.UNLIMITED && 'bg-color-unlimitedPlanColor col-4'} ${props.btntext === Brand.Plans.PAY_AS_YOU_GO && 'bg-color-themeColor col-4'} ${props.btntext === 'Basic' && 'bg-color-c14142B col-2'}`}>
                        {props.btntext}
                    </div>
                </div>
            </div>
        </div>
    )
}

const SubscriptionDetails = ({brand, setChangePlan}) => {

    const [loader, setLoader] = useState(false)

    const SubscriptionCancelStatus = async () => {
        setLoader(true)

        const payload = {
            _id    : brand._id,
            cancel : !brand.subscription.canceledAtPeriodEnd
        }

        const {error, response} = await BrandService.UpdateSubscription({toaster: true, payload})
        response && brand.updateBrand(response.data)
        setLoader(false)
    }

    const agency = useContext(AgencyContext)

    return (
        <>

            <div className="">
                <div className="Heading22M color-neutral100 mb_24">Plan Details</div>
                <div className="d-flex space-between align-items-center">
                    <div className="borderRadius-4 color-neutral100 Heading16M p_12 w-fit-content" style={{border: '2px solid #000000'}}>{utils.capitalizeAll(brand.subscription.planName)}</div>
                    <div className="color-themeColor Link14M cp" onClick={()=>setChangePlan(true)}>Change Plan</div>
                </div>
            </div>
            {   brand.subscription.canceledAt &&
                <div className="col-12 d-flex mt_40 pl_0 pr_0">
                    <div className="subtitle1 text-left col-6 pl_0 pr_0">Canceled At:</div>
                    <div className="subtitle1 text-right col-6 pr_0 color-error">{utils.getDate((brand.subscription.canceledAt+86400)*1000, true)}</div>
                </div>
            }
            
            <div className="mt_24 color-themeColor fs-14">
                {  !brand.subscription.canceledAtPeriodEnd &&
                    <CustomButton 
                        btntext         = {'Cancel Subscription'}
                        backgroundColor = {ColorSchemeCode.white}
                        color           = {ColorSchemeCode.brandingPrimaryColor}
                        // color           = {agency.agencyColor}
                        varient  = "warningSecondary"
                        fontSize = {'14px'}
                        hover    = {ColorSchemeCode.white}
                        focus    = {ColorSchemeCode.white}
                        onClick  = {SubscriptionCancelStatus}
                        disabled = {loader}
                        icon     = {loader && <CircularProgress className="align-middle" size={20} color={'inherit'}/>}
                    />
                }
                {brand.subscription.canceledAtPeriodEnd && <div className="mt_15">Pending Cancelation</div>}
            </div>

        </>
    )
}

const Expired = ({brand}) => {

    const [loader, setLoader] = useState(false)

    const RenewSubscription = async () => {
        setLoader(true)

        // if no card detail found
        if(!brand.stripeCustomer || brand.stripeCustomer && !brand.stripeCustomer.paymentMethodId){
            // changeTab(1)
            showToaster({
                position : 'top-right',
                title    : 'Card Details',
                message  : 'Please provide your card details',
                severity : 'info'
            })
            return setLoader(false)          
        } 

        const payload = {
            _id      : brand._id,
            planName : brand.subscription.planName,
            version  : 1
        }

        // const { error, response } = await BrandService.UpdateSubscription({toaster: true, payload})
        const { error, response } = await BrandService.RenewSubscription({toaster: true, payload})
        setLoader(false)

        response && brand.updateBrand(response.data)
    }

    return (
        <>
            <div className="col-12 mt_24 d-flex pl_0 pr_0">
                <div className="subtitle1 color-neutral80 text-left fw-6 col-6">Billing Plan</div>
                <div className="subtitle1 color-c14142B text-right fw-6 col-6 text-uppercase">{brand.subscription.status}</div>
            </div> 
        
            <div className="col-12 mt_16 pl_0 pr_0">
                <CustomButton 
                    btntext         = {'Renew Subscription'}
                    className       = "col-12"
                    fontSize        = {'14px'}
                    borderRadius    = {'12px'}
                    color           = {ColorSchemeCode.white}
                    hover           = {ColorSchemeCode.returnToProHover}
                    backgroundColor = {ColorSchemeCode.returnToPro}
                    onClick         = {RenewSubscription}
                    disabled        = {loader}
                    icon            = {loader && <CircularProgress className="align-middle" size={20} color={'inherit'}/>}
                />
            </div>
        </>
    )
}

const BillingPlanDetails = ({editCardElement, setEditCardElement, setBillingDetail, setChangePlan}) => {

    const brand  = useContext(BrandContext)
    const agency = useContext(AgencyContext)

    const unlimitedPlan = [
        'Unlimited Subscribers',
        'Unlimited Integrations',
        'Unlimited Card Templates',
        'Unlimited Push Notification',
        'Unlimited Geo Notification',
        'Unlimited Campaigns',
        'Training and Videos',
    ]

    return(
        <div className="container ml_1">
            <div className="proPlan row">
                
                <div className="col-md-6 col-12 pl_0 pr_0">

                    { brand.stripeCustomer && brand.stripeCustomer.paymentMethodId &&
                    <>
                        <div className="d-flex align-items-center space-between"><CardDetail 
                            brand       = {brand}
                            editCard    = {editCardElement}
                            setEditCard = {setEditCardElement}
                            edit        = {false}
                        />
                        <div className="color-themeColor Link14M cp" onClick={()=>setBillingDetail(true)}>Billing Details</div>
                        </div>
                        <div className="Body14R color-GeneralBlack">{"Your next billing date will be "+utils.getDate(brand.subscription.currentPeriodEnd*1000)}</div>
                        </>
                        
                    }

                    {/* brand.subscription.planName */}

                    
                    {/* <BillingPlanBox  btntext={brand.subscription.status === 'active' ? brand.subscription.planName : 'Basic'}/> */}
                    
                    { brand.subscription.status === 'active' && <SubscriptionDetails setChangePlan={setChangePlan} brand={brand} />}

                    { brand.subscription.status !== 'active' && <Expired brand={brand} />}

                </div>
               
               {/* {brand.subscription.planName === Brand.Plans.PAY_AS_YOU_GO  && brand.subscription.status === 'active' &&  //if users selects pro, so there will be a plan box shown on right side! which says unlimited plan
                    <div className="col-12 col-md-5 unlimitedCard card">
                        <BillingDetails 
                            title          = {Brand.Plans.UNLIMITED}
                            hover          = {ColorSchemeCode.unlimitedPlanColorHover}
                            color          = {ColorSchemeCode.white}
                            details        = {unlimitedPlan}
                            btntext        = {'Subscribe to Unlimited'}
                        />  
                    </div>
               } */}
            </div>

            {/* <div className="row">

                <div className="col-6 pl_0 pr_0 mt_32">
                    <div className="d-flex">
                        <div className={" col-6 companyName"}>
                            <div className="Body14R color-placeholder">Company name</div>
                            <div className="Body14R color-neutral80">{brand.brandName}</div>
                        </div>

                        <div className="col-6">
                            <div className="ml_auto w-fit-content">
                                <div className="Body14R color-placeholder">Additional Info</div>
                                <div className="Body14R color-neutral80 ">Not Specified</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt_56 d-flex">
                    <div className="col-6 fs-14 fw-5 pr_0 pl_0">
                        <div className="cp color-themeColor"> View Billing History</div>
                    </div>
                </div>

            </div> */}
        </div>
    )
}

const BillingHistory = () => {

    const brand = useContext(BrandContext)

    const [state, setState] = useState({
        loader    : true,
        apiError  : '',
        invoices  : [],
        tableData : []
    })

    const onLoad = async () => {
        
        const query = {referenceId: 'V2Brands%26'+brand._id} // %26 is the code for & symbol
        const {error, response} = await UtilsService.GetInvoices({query})
        
        if(error) return setState({...state, loader: false, apiError: error.error})

        const tableData = response.data.map((invoice, index) => createData({
            serial : index+1,
            date   : utils.getDate(invoice.created),
            amount : '$ ' + invoice.amountPaid/100,
            status : invoice.status,
            pdf    : invoice.pdf,
            id     : invoice._id
        }))

        setState({...state, loader: false, invoices: [...response.data], tableData: [...tableData]})

    }

    useEffect(onLoad, [])

    function handleColFunc(){
        // console.log('function for col call');
    }
    
    function createData({serial, date, amount, status, pdf, id}) {
        return { 
            props: {
                name     : 'rowProps',
                class    : '',
                id       : id,
                checkbox : false
            },
            serial: {
                label : serial,
                class : '',
                fn    : handleColFunc
            }, 
            date: {
                label   : date,
                class   : '',
                numeric : false
            },
            amount: {
                label   : amount,
                class   : '',
                numeric : false
            },
            status: {
                label   : status,
                class   : '',
                numeric : false
            },
            pdf: {
                label   : 'View Invoice',
                link    : pdf,
                class   : '',
                numeric : false
            } 
        };
    }

    const tableHeadings =  [
        { id: 'serial', label: '' },
        { id: 'date', label: 'Date' },
        { id: 'amount', label: 'Amount' },
        { id: 'status', label: 'Status' },
        { id: 'pdf', label: '' },

        // { id: 'avatar', label: 'logo' },
        // { id: 'name', numeric: false, disablePadding: false, label: 'brand' },
    ]

    return (
        <div className="billingHistory mt_24">
            <div className="row">
                <div className="col-12 p_16 borderRadius-16 bg-color-bgTextBox">
                    {state.loader && <Loader baseHeight={'inherit'} width={180} height={180} />}
                    {!state.loader && state.apiError && <div className="error">{state.apiError}</div>}
                    
                    {!state.loader && !state.apiError && 
                        <CustomTable 
                            dataRows     = {state.tableData}
                            headerRow    = {tableHeadings}
                            tableShadow  = {'none'}
                            tableHeight  = {'500px'}
                            stickyHeader = {true}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

const CardDetail = ({edit, brand: {stripeCustomer, ...brand}, editCard, setEditCard}) => {
    const [showCardSettings, setShowCardSettings] = useState(false)
    const [state, setState] = useState({
        loader: false
    })

    const deleteCard = async () => {
        if(state.loader) return
        setState({...state, loader: true})
        const payload = {
            _id        : brand._id,
            deleteCard : true
        }
        const { error, response } = await BrandService.Update({toaster: true, payload})
        setState({...state, loader: false})
        if(response) return brand.updateBrand(response.data)
    }

    return (
        <div className="d-flex align-items-center">

            <div className="mr_16">
                <img src={PngIcons.PaymentCard[stripeCustomer.card.brand] || PngIcons.PaymentCard.default} width="40px" alt="card"/>
            </div>

            <div>
                <div className="d-flex">
                    {/* <div className="color-textfieldColor fs-16 fw-4 text-capitalize">{stripeCustomer.card.brand} card</div> */}
                    <div className="Heading16M color-GeneralBlack align-items-center">
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber ml_8">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber ml_8">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber">{edit&&'*'}</span>
                        <span className="creditCardNumber mr_8">{edit&&'*'}</span>
                        {stripeCustomer.card.last4}
                    </div>
                </div>
                {/* <div>Expires {enums.Months[stripeCustomer.card.exp_month]} {stripeCustomer.card.exp_year}</div> */}
            </div>
            {edit&&<div className="ml_auto d-flex">
                <div className="mr_16">
                    <ClickOutside onClick={() => setShowCardSettings(false)}>
                        <div onClick={() => {setShowCardSettings(!showCardSettings); setEditCard(false)}}>
                            <SvgIcons.ViewMoreIcon color={ColorSchemeCode.tableActionDropdownColor} />
                        </div>
                        { showCardSettings &&
                            <div className="cardOption dropdown-box" style={{right: '-10px'}}>
                                <div className="cp d-flex p_8" onClick={deleteCard}>
                                    {   state.loader ?
                                        <CircularProgress className={"mr_8 align-middle"} size={20} color={'inherit'}/> :
                                        <span className="mr_8 align-text-bottom"><SvgIcons.DeleteIcon color={ColorSchemeCode.textfieldColor}/></span>
                                    }
                                    <div>Delete</div>
                                </div>
                            </div>
                        }
                    </ClickOutside>
                </div>

                <div onClick={()=>{setEditCard(!editCard); setShowCardSettings(false)}}><SvgIcons.EditIcon color={ColorSchemeCode.tableActionDropdownColor}/></div>
            </div>}
        
        </div>
    )
}

export default Billing
