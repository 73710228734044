import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/services/emailService'

const VerifySES = async ({payload}) => await axios.post(env.API_URL+service+'/verifySES',payload, {headers: await MainService.getTokenHeader()})


const EmailServices = {
    VerifySES : Handlers.Services(VerifySES),
}

export default EmailServices;
