
import React, { useContext, useEffect, useRef, useState } from "react";

import Graph from "../../../components/Graph";

import { LayoutContext } from '../../../context/Layout.context'
import { AnalyticService, CustomFieldService, DealService, GrowthtoolService, TransactionService, BrandService, PassService, LocationService, UserService } from "../../../services";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import localforage from "localforage";
import { Months } from "../../../enums/enums";
import { AgencyContext } from "../../../context/Agency.context";
import { Campaign } from "../../../enums";
import { BrandContext } from "../../../context/Brand.context";
import { PngIcons, SvgIcons } from "../../../icons";
import Loader from "../../../components/Loader";
import { NumericFormat } from 'react-number-format';
import { utils } from "../../../utils";
import CustomButton from "../../../components/CustomButton";
import { CircularProgress } from "@mui/material";
import CustomTextField from '../../../components/CustomTextField';
import CustomDatePicker from "../../../components/CustomDatePicker";
import DatePicker from "react-datepicker";
import CustomSelect from "../../../components/CustomSelectCopy";
import { Analytics } from "@mui/icons-material";
import CustomsModal from "../../../components/CustomModal";
import { ColorSchemeCode } from "../../../enums/ColorScheme";
import DeleteModal from "../../../components/DeleteModal";
import { PassVld } from "../../../validation";
import CampaignsDropDown from "../../../components/CampaignsDropDown";
import PhoneInput from 'react-phone-input-2'
import CopyText from "../../../components/CopyText";
import { useFormik } from "formik";
import TransactionHistory from "../../walletscan/subpages/TransactionHistory";
import { ClickOutside } from "../../../utils";
import { Brand, Fields, GrowthTools, PassDesign, User } from "../../../enums";


export default function Home() {

  const layout = useContext(LayoutContext);
  const brand = useContext(BrandContext);
  const [analytics, setAnalytics] = useState(null)
  const [loader, setLoader] = useState(false)
  const [susbscriberField, setSubscriberField] = useState([]);
  const [customFields, setCustomFields] = useState();

  const [show, setShow] = useState(false);
  const [customerData, setCustomerData] = useState();
  const [filteredCustomers, setFilteredCustomers] = useState();
  const [fields, setFields] = useState([]);


  const [graphData, setGraphData] = useState({
    audience: [],
    audienceLabels: [],
    audienceTotal: 0,
    revenue: [],
    revenueLabels: [],
    revenueTotal: 0,
    points: [],
    pointsLabels: [],
    pointsTotal: 0,
  })

  useEffect(() => {
    layout.setLayout({
      expandedBar: false,
      title: 'Dashboard',
      switch: "",
      subTitle: '',
      showCollapseIcon: false,
      showLogo: false,
      borderBottom: false,
      height: '56px',
      button: (<></>
      )
    })
  }, [])


  const getDataAudience = {
    labels: graphData.audienceLabels,
    datasets: [
      {
        data: graphData.audience,
        borderWidth: 3,
        tension: 0.3,
        label: "Audience",
        borderColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#00FFF0");
          gradient.addColorStop(1, "#0083FE");
          return gradient;
        },
        backgroundColor: "rgba(0, 140, 253, 0.1)",
      },
    ],
  };

  useEffect(() => {
    onLoadFunc();
  }, [])

  const setGraphDataFuncAudience = (analytics) => {
    console.log('analytics', analytics);
    let labels = [];
    let data = [];
    let totalAudience = 0
    let totalData = []

    let analyticsAudience = []
    analytics?.audienceData && Object.entries(analytics?.audienceData).forEach((data, idx) => {
      let splitData = data[0].split('-')
      let a = [`${splitData[2]}/${splitData[0] < 10 ? '0' + splitData[0] : splitData[0]}/${splitData[1]}`, data[1]]
      analyticsAudience.push(a)
    })

    let sortedDate = analyticsAudience.sort((a, b) => {
      console.log('a ->', a[0])
      console.log('b ->', b)
      return new Date(a[0]) - new Date(b[0]);
    });

    if (Object.keys(sortedDate).length > 0) {
      let currentDate = new Date(sortedDate[0][0]);

      console.log('currentDate', currentDate)
      let endDate = new Date(); // Use the current date as the end date
      console.log('endDate', endDate)

      while (currentDate <= endDate) {
        labels.push(`${Months[currentDate.getMonth() + 1]}-${currentDate.getFullYear()}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }



      let dataIndex = 0;
      for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if (dataIndex < sortedDate.length) {
          const dataEntryDate = new Date(sortedDate[dataIndex][0]);

          const dataMonth = dataEntryDate.getMonth();
          const dataYear = dataEntryDate.getFullYear();

          if (label === `${Months[dataMonth + 1]}-${dataYear}`) {
            totalAudience += sortedDate[dataIndex][1]
            totalData.push(sortedDate[dataIndex][1])
            data.push(totalAudience);
            dataIndex++;
          } else {
            data.push(totalAudience);
            totalData.push(0)
          }
        } else {
          data.push(totalAudience);
          totalData.push(0)
        }
      }


      if (labels.length > 12) {
        labels = labels.slice(-12);
        data = data.slice(-12);
      }

      else if (labels.length > 6 && labels.length < 12) {
        labels = labels.slice(-12);
        data = data.slice(-12);
      }

      return [labels, data, totalData];
    }
    else {
      return [labels, data, totalData];
    }
  };

  const setGraphDataFuncPoints = (analytics) => {
    let labels = [];
    let data = [];
    let totalPoints = 0
    let totalData = []

    let analyticsPoints = []
    analytics?.pointsData && Object.entries(analytics?.pointsData).forEach((data, idx) => {
      let splitData = data[0].split('-')
      let a = [`${splitData[2]}/${splitData[0] < 10 ? '0' + splitData[0] : splitData[0]}/${splitData[1]}`, data[1]]
      analyticsPoints.push(a)
    })

    let sortedDate = analyticsPoints.sort((a, b) => {
      console.log('a ->', a[0])
      console.log('b ->', b)
      return new Date(a[0]) - new Date(b[0]);
    });

    if (Object.keys(sortedDate).length > 0) {
      // const [month, day, year] = sortedDate[0][0].split('-');
      // sortedDate[0][0] = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      let currentDate = new Date(sortedDate[0][0]);
      // //console.log('foool',currentDate);
      let endDate = new Date(); // Use the current date as the end date

      while (currentDate <= endDate) {
        labels.push(`${Months[currentDate.getMonth() + 1]}-${currentDate.getFullYear()}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      // //console.log('labels: ', labels);

      let dataIndex = 0;
      for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if (dataIndex < sortedDate.length) {
          const dataEntryDate = new Date(sortedDate[dataIndex][0]);
          const dataMonth = dataEntryDate.getMonth();
          const dataYear = dataEntryDate.getFullYear();

          if (label === `${Months[dataMonth + 1]}-${dataYear}`) {
            totalPoints += sortedDate[dataIndex][1]
            totalData.push(sortedDate[dataIndex][1])
            data.push(totalPoints);
            dataIndex++;
          } else {
            data.push(totalPoints);
            totalData.push(0);
          }
        } else {
          data.push(totalPoints);
          totalData.push(0);
        }
      }

      //console.log('data: ', data);

      if (labels.length > 12) {
        labels = labels.slice(-12);
        data = data.slice(-12);
      }

      else if (labels.length > 6 && labels.length < 12) {
        labels = labels.slice(-12);
        data = data.slice(-12);
      }

      return [labels, data, totalData];
    }
    else {
      return [labels, data, totalData];
    }
  };

  const setGraphDataFuncRevenue = (analytics) => {
    let labels = [];
    let data = [];
    let totalRevenue = 0
    let totalData = []

    let analyticsRevenue = []
    analytics?.revenueData&&Object.entries(analytics?.revenueData).forEach((data, idx) => {
      let splitData = data[0].split('-')
      let a = [`${splitData[2]}/${splitData[0] < 10 ? '0' + splitData[0] : splitData[0]}/${splitData[1]}`, data[1]]
      analyticsRevenue.push(a)
    })

    let sortedDate = analyticsRevenue.sort((a, b) => {
      console.log('a ->', a[0])
      console.log('b ->', b)
      return new Date(a[0]) - new Date(b[0]);
    });

    if (Object.keys(sortedDate).length > 0) {
      // const [month, day, year] = sortedDate[0][0].split('-');
      // sortedDate[0][0] = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      //console.log('setGraphDataFuncAudience',sortedDate[0][0]);

      let currentDate = new Date(sortedDate[0][0]);
      let endDate = new Date(); // Use the current date as the end date

      while (currentDate <= endDate) {
        labels.push(`${Months[currentDate.getMonth() + 1]}-${currentDate.getFullYear()}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      //console.log('labels: ', labels);

      let dataIndex = 0;
      for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if (dataIndex < sortedDate.length) {
          const dataEntryDate = new Date(sortedDate[dataIndex][0]);
          const dataMonth = dataEntryDate.getMonth();
          const dataYear = dataEntryDate.getFullYear();

          if (label === `${Months[dataMonth + 1]}-${dataYear}`) {
            totalRevenue += sortedDate[dataIndex][1]
            totalData.push(sortedDate[dataIndex][1])
            data.push(totalRevenue);
            dataIndex++;
          } else {
            data.push(totalRevenue);
            totalData.push(0)
          }
        } else {
          data.push(totalRevenue);
          totalData.push(0)

        }
      }

      //console.log('zee: ', totalData);

      if (labels.length > 12) {
        labels = labels.slice(-12);
        data = data.slice(-12);
      }

      else if (labels.length > 6 && labels.length < 12) {
        labels = labels.slice(-12);
        data = data.slice(-12);
      }

      return [labels, data, totalData];
    }
    else {
      return [labels, data, totalData];
    }
  };

  const onLoadFunc = async () => {
    setLoader(true)
    console.log('BBA', brand);
    const query = {
      brandId: brand._id,
    };
    console.log('rqueryAnalytics ', query);

    let GetAnalytics = await localforage.getItem('GetAnalytics')
    console.log('GetAnalyticsGetAnalytics',GetAnalytics);

    if(GetAnalytics?.analytics && GetAnalytics?.analytics?.length>0 && GetAnalytics.analytics[0].brandId && brand._id !== GetAnalytics.analytics[0].brandId) await localforage.removeItem('GetAnalytics')
    
    GetAnalytics = await localforage.getItem('GetAnalytics')

    if(!GetAnalytics){
      const { response, error } = await AnalyticService.GetAnalytics({ query });
      if (response?.data) {
        localforage.setItem('GetAnalytics',response.data)
        console.log('responseOne ', response.data);
        GetAnalytics = response.data
      }
    }

    if (GetAnalytics?.transactions) GetAnalytics.analytics[0].totalTransactions = GetAnalytics.transactions.length
    if (GetAnalytics?.unusedPoints) GetAnalytics.analytics[0].unusedPoints = GetAnalytics.unusedPoints
    if (GetAnalytics?.loyaltyMembers) GetAnalytics.analytics[0].loyaltyMembers = GetAnalytics.loyaltyMembers
    if (GetAnalytics?.usedPoints) GetAnalytics.analytics[0].usedPoints = GetAnalytics.usedPoints
    if (GetAnalytics?.pointsUsers) GetAnalytics.analytics[0].pointsUsers = GetAnalytics.pointsUsers
    setAnalytics(GetAnalytics?.analytics[0]);
    const [audienceLabels, audienceData, audienceTotal] = setGraphDataFuncAudience(GetAnalytics?.analytics[0])
    const [pointsLabels, pointsData, pointsTotal] = setGraphDataFuncPoints(GetAnalytics?.analytics[0])
    const [revenueLabels, revenueData, revenueTotal] = setGraphDataFuncRevenue(GetAnalytics?.analytics[0])

    const totalFunc = (data) => {
      let total = 0;
      data.forEach((singleData) => {
        total += singleData
      })
      return total
    }

    setGraphData({ ...graphData, revenueTotal: totalFunc(revenueTotal), audienceTotal: totalFunc(audienceTotal), pointsTotal: totalFunc(pointsTotal), revenue: revenueData, revenueLabels: revenueLabels, points: pointsData, pointsLabels: pointsLabels, audience: audienceData, audienceLabels: audienceLabels })








    setLoader(false)
    next()
    
  }
  
  
  async function next(){
    const query = {
      brandId: brand._id,
    };
    const { response, error } = await AnalyticService.GetAnalytics({ query });
    if (response?.data) {
      localforage.setItem('GetAnalytics',response.data)
      console.log('responseOne ', response.data);
  
      if (response.data?.transactions) response.data.analytics[0].totalTransactions = response.data?.transactions.length
      if (response.data?.unusedPoints) response.data.analytics[0].unusedPoints = response?.data.unusedPoints
      if (response.data?.loyaltyMembers) response.data.analytics[0].loyaltyMembers = response?.data.loyaltyMembers
      if (response.data?.usedPoints) response.data.analytics[0].usedPoints = response.data?.usedPoints
      if (response.data?.pointsUsers) response.data.analytics[0].pointsUsers = response.data?.pointsUsers
      setAnalytics(response.data?.analytics[0]);
      const [audienceLabels, audienceData, audienceTotal] = setGraphDataFuncAudience(response.data?.analytics[0])
      //console.log('audienceLabels',response.data[0]);
      const [pointsLabels, pointsData, pointsTotal] = setGraphDataFuncPoints(response.data?.analytics[0])
      const [revenueLabels, revenueData, revenueTotal] = setGraphDataFuncRevenue(response.data?.analytics[0])
  
      const totalFunc = (data) => {
        let total = 0;
        data.forEach((singleData) => {
          total += singleData
        })
        return total
      }
  
      setGraphData({ ...graphData, revenueTotal: totalFunc(revenueTotal), audienceTotal: totalFunc(audienceTotal), pointsTotal: totalFunc(pointsTotal), revenue: revenueData, revenueLabels: revenueLabels, points: pointsData, pointsLabels: pointsLabels, audience: audienceData, audienceLabels: audienceLabels })
    }
    else {
      console.log('responsed error', error)
    }
    
  }

  // const totalPoints = (parseInt(analytics?.usedPoints) || 0) + (parseInt(analytics?.unusedPoints) || 0);
  // const audience = graphData?.audienceTotal || 1; // Default to 1 to avoid division by zero

  // const formatValue = (value) => {
  //   return value % 1 === 0 ? value.toString() : value.toFixed(2);
  // };


  // console.log('analytics',analytics);
  
  // const data = [
  //   { title: "Audience", value: graphData?.audienceTotal || 0, change: "1.5%" },
  //   { title: "Total Spent", value: formatValue(analytics?.totalRevenue || 0) + ' $', change: "2.2%" },
  //   { title: "Avg. Spent Per Member", value: '$ ' + formatValue((analytics?.totalRevenue / analytics?.totalAudience) || 0), change: "-2.2%" },
  //   { title: "Avg. Spent Per Visit", value: '$ ' + formatValue((analytics?.totalRevenue / analytics?.totalTransactions) || 0), change: "1.0%" },
  //   { title: "Earned Points", value: ((parseInt(analytics?.usedPoints) || 0)+(parseInt(analytics?.unusedPoints) || 0))+" pts", change: "1.0%" },
  //   { title: "Redeemed Points", value: parseInt(analytics?.usedPoints) || 0+" pts", change: "2.2%" },
  //   { title: "Unused Points", value: (analytics?.unusedPoints || 0 )+" pts", change: "-2.2%" },
  //   { title: "Avg. Points Per Customer", value: formatValue(totalPoints / audience)+" pts", change: "1.5%" },
  // ];


  const totalPoints = (parseInt(analytics?.usedPoints) || 0) + (parseInt(analytics?.unusedPoints) || 0);
  const audience = graphData?.audienceTotal || 1; // Default to 1 to avoid division by zero

  // Updated formatValue function to format numbers with commas but no decimals
  const formatValue = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0  // Ensures no decimals are displayed
    });
    return formatter.format(value);
  };

  console.log('analytics', analytics);

  const data = [
    { title: "Audience", value: formatValue(graphData?.audienceTotal || 0), change: "1.5%" },
    { title: "Total Spent", value:'$ '+ formatValue(analytics?.totalRevenue || 0), change: "2.2%" },
    { title: "Avg. Spent Per Member", value: '$ ' + formatValue((analytics?.totalRevenue / analytics?.totalAudience) || 0), change: "-2.2%" },
    { title: "Avg. Spent Per Visit", value: '$ ' + formatValue((analytics?.totalRevenue / analytics?.totalTransactions) || 0), change: "1.0%" },
    { title: "Earned Points", value: formatValue(totalPoints) + " pts", change: "1.0%" },
    { title: "Redeemed Points", value: formatValue(parseInt(analytics?.usedPoints) || 0) + " pts", change: "2.2%" },
    { title: "Unused Points", value: formatValue(analytics?.unusedPoints || 0) + " pts", change: "-2.2%" },
    { title: "Avg. Points Per Customer", value: formatValue(totalPoints / audience) + " pts", change: "1.5%" },
  ];


  // This data array now contains values formatted as whole numbers with commas as needed.



  return (
    <div  
    // className="bg-color-neutral0"
    >
      {loader ?
        <div>
          <Loader baseHeight={'calc(100vh - 70px)'} width={'100px'} />
        </div>
        :
        <>
          {/* <div className="statsBox mt_8">
            {console.log('summary',summary)}
            

            {summary.map((detail) =>

              <div className="singleBox pr_16">
                <p className="Body14R color-Heading">{detail.title}</p>

                <p className="Heading24B color-Heading mt_4">
                  {
                    // detail.figure.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                    detail.figure
                  }
                </p>
                {detail.extraFields && detail.extraFields.map((details) =>
                  <div className="detailsMain">
                    <div className="details-title">{details.title}</div>
                    <div className="details-value">{details.value}</div>
                  </div>

                )
                }

              </div>
            )}
          </div> */}



          {analytics?.totalAudience > 0 ?
            <>
            <div className="graphSection mt_20 mb_40">
              <div className="d-flex space-between mb_16">
                <div className="rightSection">
                  <p className="Heading16M color-Heading">
                    Audience
                  </p>
                  <p className="Heading32M color-Heading d-flex align-items-center">
                    {graphData?.audienceTotal.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) || 0}
                    {/* <div className="PlusPercentage ml_8">+100%</div> */}
                  </p>
                </div>
              </div>
              {
                console.log('getDataAudience', getDataAudience)
              }
              <Graph type="line" data={getDataAudience} />
            </div>
            
            </>

            :

            <div className="position-relative graphBox">
              <img
                className="graphImage"
                src={PngIcons.GraphBackground}
                alt=""
              />{" "}
              <div className="absoluteMiddle color-c000000 ">
                <div className="color-c000000 fs-32 text-center">
                  No Customers
                </div>
                <div className="fs-16 text-center">
                  When you start getting new customers, It'll show up
                  here
                </div>
              </div>
            </div>

          }

            <div id="HomeBox">
              <div className="grid-container">
                {data.map((item, index) => (
                  <div key={index} className="grid-item d-flex space-between">
                    <div>
                      <div className="B12R color-neutral70">{item.title}</div>
                      <p className="Body24B">{item.value}</p>
                    </div>
                    {/* <span className={`change ${item.change.startsWith("-") ? "negative" : "positive"}`}>
                      {item.change}
                    </span> */}
                  </div>
                ))}
              </div>
            </div>
          {/* {activeMembers && activeMembers.length > 0 ?

            <div className="graphSection mt_40 mb_40">
              <div className="d-flex space-between mb_16">
                <div className="rightSection">
                  <p className="Heading16M color-Heading">
                    Active Members
                  </p>
                  
                </div>
              </div>
              <div className="ActiveMembersMain">

                <div className="ActiveMembersMain-header d-flex w-100">
                  <div className=" w-50">CUSTOMERS NAME</div>
                  <div className=" w-25"> POINTS</div>
                  <div className=" w-25">CREATED</div>
                </div>

                <div className="ActiveMembersMain-Down">

                  {

                    activeMembers && activeMembers.map((element, IDX) =>
                      <div onClick={() => handleSelectedCustomer(element)} className="AM-Main d-flex align-items-center pt_16 pb_16">
                        <div className="AM-Info w-50">
                          <div className="AM-Info ">
                            <UserImagePart
                              color={element.color || '#000000'}
                              email={element.user.email}
                              firstName={element.user.firstName}
                              // customers={filteredCustomers}
                              lastName={element.user.lastName}
                            />
                          </div>
                        </div>
                        <div className="AM-Points w-25">
                          {element.pass.loyaltyCard.totalPoints} PTS
                        </div>
                        <div className="AM-Created w-25" >
                          {new Date(element.pass.created).toDateString()}
                        </div>
                      </div>)
                  }

                </div>
              </div>
            </div>

            :

            <div className="position-relative graphBox graphBox">
              <img
                className="graphImage"
                src={PngIcons.GraphBackground}
                alt=""
              />{" "}
              <div className="absoluteMiddle color-c000000 ">
                <div className="color-c000000 fs-32 text-center">
                  No Active Members
                </div>
                <div className="fs-16 text-center">
                  When you start getting new customers, It'll show up
                  here
                </div>
              </div>
            </div>

          } */}
          {/* {analytics?.totalAudience > 0 ?

            <div className="graphSection mt_40 mb_40">
              <div className="d-flex space-between mb_16">
                <div className="rightSection">
                  <p className="Heading16M color-Heading">
                    Total Audience
                  </p>
                  <p className="Heading32M color-Heading">
                    {graphData?.audienceTotal.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) || 0}
                  </p>
                </div>
              </div>
              {
                console.log('getDataAudience', getDataAudience)
              }
              <Graph type="line" data={getDataAudience} />
            </div>

            :

            <div className="position-relative graphBox graphBox">
              <img
                className="graphImage"
                src={PngIcons.GraphBackground}
                alt=""
              />{" "}
              <div className="absoluteMiddle color-c000000 ">
                <div className="color-c000000 fs-32 text-center">
                  No Audience
                </div>
                <div className="fs-16 text-center">
                  When you start getting new customers, It'll show up
                  here
                </div>
              </div>
            </div>

          } */}

          {/* {analytics?.totalRevenue > 0 ?
            <div className="graphSection mt_40 mb_40">
              <div className="d-flex space-between mb_16">
                <div className="rightSection">
                  <p className="Heading16M color-Heading">
                    Total Revenue
                  </p>
                  <p className="Heading32M color-Heading">
                    {
                      (brand?.currency?.symbol || '$') + " " + parseInt(analytics?.totalRevenue || 0)?.toLocaleString()
                    }
                  </p>
                </div>
              </div>
              {
                console.log('getRevenueData', getRevenueData)
              }
              <Graph type="line" data={getRevenueData} />
            </div>

            :

            <div className="position-relative graphBox graphBox">
              <img
                className="graphImage"
                src={PngIcons.GraphBackground}
                alt=""
              />{" "}
              <div className="absoluteMiddle color-c000000 ">
                <div className="color-c000000 fs-32 text-center">
                  No Revenue
                </div>
                <div className="fs-16 text-center">
                  When you start earning revenue, It'll show up
                  here
                </div>
              </div>
            </div>

          } */}

          {/* {analytics?.totalPoints > 0 ?

            <div className="graphSection mt_40 mb_40">
              <div className="d-flex space-between mb_16">
                <div className="rightSection">
                  <p className="Heading16M color-Heading">
                    Total Points
                  </p>
                  <p className="Heading32M color-Heading">
                    {graphData?.pointsTotal.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) || 0}
                  </p>
                </div>
              </div>
              {
                console.log('getDataPoints', getDataPoints)
              }
              <Graph type="line" data={getDataPoints} />
            </div>

            :

            <div className="position-relative graphBox graphBox">
              <img
                className="graphImage"
                src={PngIcons.GraphBackground}
                alt=""
              />{" "}
              <div className="absoluteMiddle color-c000000 ">
                <div className="color-c000000 fs-32 text-center">
                  No Audience
                </div>
                <div className="fs-16 text-center">
                  When you start getting new customers, It'll show up
                  here
                </div>
              </div>
            </div>

          } */}

        </>}

      <CustomsModal
        open={show}
        minWidth={"85vw"}
        maxHeight={"85vh"}
        minHeight={"85vh"}
        padding={"0px"}
        component={
          <CustomerModal
            // onLoadCall={onLoadCall}
            fields={fields}
            setFields={setFields}
            customFields={customFields}
            setCustomFields={setCustomFields}
            susbscriberField={susbscriberField}
            setSubscriberField={setSubscriberField}
            onClose={() => setShow(false)}
            show={show}
            setShow={setShow}
            // customer={selectedCustomer}
            filteredCustomers={filteredCustomers}
            setFilteredCustomers={setFilteredCustomers}
            customerData={customerData}
            setCustomerData={setCustomerData}
          />
        }
        onClose={() => setShow(false)}
      />

      {/* <CustomsModal
        open={reportModelOpen}
        minWidth={"600px"}
        component={
          <ReportModal
            onCancel={() => setReportModelOpen(false)}
            deals={deals}
            reportDates={reportDates}
            setReportDates={setReportDates}
            onReport={getReport}
          />
        }
        onClose={() => setReportModelOpen(false)}
      /> */}
      {/* <CustomsModal
        open={reportResultModelOpen}
        minWidth={"600px"}
        component={
          <ReporResulttModal
            onCancel={() => setReportResultModelOpen(false)}
            deals={deals}
            reportDates={reportDates}
            setReportDates={setReportDates}
            onReport={getReport}
          />
        }
        onClose={() => setReportResultModelOpen(false)}
      /> */}
    </div>
  )
}



const CustomerModal = ({
  customer,
  // onLoadCall,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  show,
  setShow,
  onClose,
  fields,
  susbscriberField,
  setSubscriberField,
  setCustomFields,
  customFields,
  setFields,
}) => {
  const [selected, setSelected] = useState("customer");
  const [customerUpdated, setCustomerUpdated] = useState(customer);
  const [loader, setLoader] = useState(false);

  useEffect(async () => {
    setLoader(true);
    const { response, error } = await TransactionService.GetQueriedTransactions({ query: { passId: customer.pass._id, delete: false } })
    console.log('responseAgainandAgain', response, error);

    if (response && response.success) {
      setCustomerUpdated({ ...customer, transaction: response.data });
    }
    setLoader(false);
  }, [])


  const UserImagePart = ({ firstName, lastName, image, id }) => {
    return (
      <div className="text-center mt_16">
        <div className="d-flex justify-content-center">
          {image ? (
            <img className="userImagePart" src={image} width="30" />
          ) : (
            utils.getAvatar({
              firstName: firstName,
              lastName: lastName,
              id: id,
              tableName: "customers",
              customers: customer,
              width: "48px",
              heigth: "48px",
              bgColor: ColorSchemeCode.themeColor,
            })
          )}
        </div>
        <div className="middle fs-14 color-neutral100 fw-5 capitalize mt_8">
          {firstName + " " + lastName}
        </div>
      </div>
    );
  };


  let components = {
    customer: (
      <Customer
        // onLoadCall={onLoadCall}
        fields={fields}
        setFields={setFields}
        customFields={customFields}
        setCustomFields={setCustomFields}
        susbscriberField={susbscriberField}
        setSubscriberField={setSubscriberField}
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
      />
    ),
    status: (
      <Status
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
        show={show}
        setShow={setShow}
      />
    ),
    transaction: (
      <Transaction
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
      />
    ),
    setting: (
      <Setting
        customer={customerUpdated}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        customerData={customerData}
        setCustomerData={setCustomerData}
        show={show}
        setShow={setShow}
      />
    ),
  };

  return (
    <div className="w-100 row position-relative" id="audienceModal">
      {(loader) ? <Loader /> :
        <>
          <div className="col-4 col-xl-2 col-lg-3 col-md-3  bg-color-bgLightSecondary">
            <UserImagePart
              firstName={customer.user.firstName}
              customers={customer}
              lastName={customer.user.lastName}
              id={customer.user._id}
              image={customer.user.avatar}
            />
            <div className="mt_54 fs-14">
              <div
                className={
                  selected === "customer"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("customer");
                }}
              >
                Customer Overview
              </div>
              <div
                className={
                  selected === "status"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("status");
                }}
              >
                Pass Status
              </div>
              <div
                className={
                  selected === "transaction"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("transaction");
                }}
              >
                Transaction History
              </div>
              <div
                className={
                  selected === "setting"
                    ? "link active fw-5"
                    : "link color-neutral60"
                }
                onClick={() => {
                  setSelected("setting");
                }}
              >
                Setting
              </div>
            </div>
          </div>

          <div className="crossIcon cp z-index-100" onClick={onClose}>
            <SvgIcons.CrossIcon />
          </div>

          <div className="col-8 col-xl-10 col-lg-9 col-md-9">
            {components[selected]}
          </div>
        </>}
    </div>
  );
};

const Status = ({
  customer,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  show,
  setShow,
}) => {
  const brand = useContext(BrandContext);
  const agency = useContext(AgencyContext);

  const [downloadQrLoader, setDownloadQrLoader] = useState();
  const [qrLoader, setQrLoader] = useState();
  const [editLoader, setEditloader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [sendEmailLoader, setSendEmailLoader] = useState(false);

  const [text, setText] = useState(customer.pass.loyaltyCard.currentPoints);

  const qrcode = {
    size: 200,
    text: customer.pass.passCode,
    color: "#2F80ED",
  };

  const [state, setState] = useState({
    points: [
      {
        "Pass Code": customer.pass.passCode,
      },
      {
        "Point Summary": customer.pass.loyaltyCard.currentPoints,
      },
      {
        "Total Spending":
          brand?.currency?.symbol +
          " " +
          customer.pass.loyaltyCard.totalSpending.toFixed(2),
      },
      {
        "Total Visits":
          customer.pass.loyaltyCard && customer.pass.loyaltyCard.redeemCount
            ? customer.pass.loyaltyCard.redeemCount
            : "-",
      },
      {
        "Current Tier":
          customer.pass.loyaltyCard.currentTier &&
            customer.pass.loyaltyCard.currentTier.currentTierName
            ? customer.pass.loyaltyCard.currentTier.currentTierName
            : "",
      },
      {
        "Pass Status":
          customer.pass.isWallet ? 'Subscribed' : 'Un-Subscribed'
      },
    ],
  });

  const setAddress = async () => {
    const ipAddress = await LocationService.GetIpAddress();
    if (ipAddress.error) return;

    const { error, response } = await LocationService.GetLocation({
      ipAddress: ipAddress.response.ip,
    });
    if (error) return;

    const location = {
      lat: response.latitude,
      lng: response.longitude,
      address: response.city + " " + response.country_name,
    };

    return location;
  };

  const transaction = async () => {
    setEditloader(true);
    const location = await setAddress();
    console.log('SelfSingleLoyalityTransaction', location);
    console.log('agency', agency);
    let payload = {
      agencyId: agency._id,
      passId: customer.pass._id,
      location: location,
      brandId: brand._id,
      points:
        parseInt(text) < parseInt(customer.pass.loyaltyCard.currentPoints)
          ? parseInt(customer.pass.loyaltyCard.currentPoints) - parseInt(text)
          : parseInt(text) - parseInt(customer.pass.loyaltyCard.currentPoints),
      subtract:
        parseInt(text) < parseInt(customer.pass.loyaltyCard.currentPoints) &&
        true,
    };
    console.log('11', payload);
    let SelfSingleLoyalityTransaction = await TransactionService.SelfSingleLoyalityTransaction({
      toaster: true,
      payload,
    });
    console.log('SelfSingleLoyalityTransactionx', SelfSingleLoyalityTransaction)
    if (SelfSingleLoyalityTransaction.response.success) {
      customerData.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          // console.log('in 1', customerData[idx]);
          customerData[idx].pass.loyaltyCard = SelfSingleLoyalityTransaction.response.data.transaction.loyaltyCard.passData;
        }
      });
      // console.log('1');
      setCustomerData(customerData);
      filteredCustomers.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          filteredCustomers[idx].pass.loyaltyCard = SelfSingleLoyalityTransaction.response.data.transaction.loyaltyCard.passData;
        }
      });
      // console.log('2');
      setFilteredCustomers(filteredCustomers);
    }
    // console.log('12');
    localforage.setItem("customersAudience", customerData);
    setEdit(false);
    setEditloader(false);
  };



  return (
    <div id="info" className="pt_32 pl_60 pr_60">
      <div className="color-neutral100 fs-18 fw-5">Pass Status</div>
      <div className="bar mt_12"></div>
      {/* <div className="mt_16">
                    <div className="qrCard" style={{width: '220px'}}>

                        <div className="qrBox">
                            <QRCode qrcode={qrcode}/>

                            <div className="overlayBox"></div>

                            <div className="absoluteMiddle downloadButtonBox">
                                <div className="mt_16 downloadBtn middle" onClick   = {SendQrCode}>
                                    {sendEmailLoader ? <CircularProgress size={20} color={'inherit'}/> : <SvgIcons.EmailIcon color={ColorSchemeCode.Heading}/>}
                                </div>
                               
                                <div className="mt_16 downloadBtn middle" onClick   = {downloadQr}  >
                                    {downloadQrLoader ? <CircularProgress size={16} color={'inherit'}/> : <SvgIcons.DownloadIcon width={22} height={22} color={ColorSchemeCode.Heading}/>}
                                  
                                </div>
                            </div>
                        </div>


                    </div>
                </div> */}

      <div className="mt_32 pb_30">
        <div className="row">
          <div className="color-neutral100 fs-18 fw-5 col-8">Status</div>
          <div className="col-4 edit text-left">
            {!edit ? (
              <CustomButton
                btntext={"Edit Points"}
                onClick={() => {
                  setEdit(!edit);
                }}
                varient="tertiary"
                icon={
                  <SvgIcons.EditIcon
                    height={"18"}
                    width={"18"}
                    color={"#3770FB"}
                  />
                }
              />
            ) : (
              <div className="d-flex justify-content-end">
                <CustomButton
                  btntext={"Cancel"}
                  varient={"secondary"}
                  disabled={editLoader}
                  onClick={() => {
                    setEdit(false);
                  }}
                  className={"mr_8"}
                />
                <CustomButton
                  varient={"primary"}
                  btntext={"Save Changes"}
                  disabled={editLoader}
                  onClick={() => {
                    transaction();
                  }}
                  className={"fs-14 fw-5"}
                  icon={
                    editLoader ? (
                      <CircularProgress size={16} color={"inherit"} />
                    ) : (
                      ""
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>

        <div className="bar mt_12"></div>

        <div className="">
          <div className="componentBox w-100">
            {state?.points.map((element, idx) => (
              <div key={idx} className="topHeaderSection row">
                <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                  {Object.keys(element)}
                </div>
                <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                  {idx === 1 ? (
                    <div className="d-flex">
                      {edit ? (
                        <CustomTextField
                          type="number"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          icon={<div>Points</div>}
                          position="end"
                          top={14}
                          left={33}

                        />
                      ) : (
                        <div>{text}</div>
                      )}
                    </div>
                  ) : idx !== 0 ? (
                    <div className="d-flex">
                      {idx == 1 && (
                        <span
                          className="mr_8"
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                        ></span>
                      )}
                      {Object.values(element)}
                    </div>
                  ) : (
                    <span className="copyPass">
                      <CopyText content={Object.values(element)} />
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Customer = ({
  customer,
  // onLoadCall,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  fields,
  susbscriberField,
  setSubscriberField,
  setCustomFields,
  customFields,
  setFields,
}) => {
  const brand = useContext(BrandContext);



  const [editLoader, setEditloader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(false);

  const [selectedName, setSelectedName] = useState(
    customer.pass.userData &&
      customer.pass.userData.customFields &&
      customer.pass.userData.customFields.assignedTo
      ? customer.pass.userData.customFields.assignedTo.name
      : undefined
  );


  const [firstName, setFirstName] = useState(customer.user.firstName);
  const [lastName, setLastName] = useState(customer.user.lastName);
  const [dateOfBirth, setdateOfBirth] = useState(customer.pass.birthday);
  const [refferedBy, setRefferedBy] = useState();
  const ref = useRef();
  const [phone, setPhone] = useState(
    customer.user.phone || customer.user.chatBotPhone
  );
  const [dialingCode, setDialingCode] = useState(customer.user.dialingCode);

  const [selected, setSelected] = useState(
    customer.pass.userData &&
      customer.pass.userData.customFields &&
      customer.pass.userData.customFields.assignedTo
      ? customer.pass.userData.customFields.assignedTo._id
      : undefined
  );

  const [selectedCustomField, setSelectedCustomField] = useState(false);

  useEffect(() => {
    let array = [];
    customFields &&
      customFields.length > 0 &&
      customFields.map((element, idx) => {
        if (
          customer.pass.userData &&
          customer.pass.userData.customFields &&
          customer.pass.userData.customFields[element.key]
        ) {
          array.push({
            ...element,
            value: customer.pass.userData.customFields[element.key],
          });
        } else {
          array.push({ ...element, value: "-" });
        }
      });
    setCustomFields(array);
  }, []);
  const [state, setState] = useState();


  const updateUser = async () => {
    // console.log()
    setEditloader(true);
    let payload1 = {
      _id: customer.user._id,
      firstName: firstName,
      lastName: lastName,
      // birthday: new Date(dateOfBirth),
      birthday: dateOfBirth,
      brandId: brand._id
    };


    console.log('dateOfBirth',dateOfBirth);
    if (phone) payload1.phone = phone;
    if (dialingCode) payload1.dialingCode = dialingCode;

    if (selected || selectedCustomField) {
      let data = fields.filter((element, idx) => {
        if (element._id === selected) return true;
      })[0];

      const headers = { "x-api-key": brand.apiKey };
      let payload = { passId: customer.pass._id, customFields: {} };
      if (selected) {
        payload.customFields = {
          assignedTo: {
            name: data.key,
            email: data.email,
            dialingCode: data.dialingCode,
            phone: data.phoneNo,
            _id: data._id,
          },
        };
      }

      if (customFields) {
        for (const field of customFields) {
          payload.customFields[field.key] = field.value;
        }
      }


      const passUpdate = await PassService.Update({ payload, headers });
      console.log('passUpdate', passUpdate, payload);
      if (selected) setSelected(data._id);
      if (selected) setSelectedName(data.key);
      setEdit(false);
      setEditloader(false);
      setSelectedCustomField(false);
      // onLoadCall();
    }
    console.log('payload1USer', payload1);

    const Updates = await UserService.Update({
      toaster: true,
      payload: payload1,
    });

    if (Updates.response) {
      console.log('Updates', Updates, payload1);
      customerData?.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          customerData[idx].user.firstName = firstName;
          customerData[idx].user.lastName = lastName;
          customerData[idx].user.dialingCode = dialingCode;
          customerData[idx].user.phone = phone;
          customerData[idx].user.birthday = dateOfBirth;
        }
      });
      setCustomerData(customerData);
      filteredCustomers?.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          filteredCustomers[idx].user.firstName = firstName;
          filteredCustomers[idx].user.lastName = lastName;
          filteredCustomers[idx].user.dialingCode = dialingCode;
          filteredCustomers[idx].user.phone = phone;
          filteredCustomers[idx].user.birthday = dateOfBirth;

        }
      });
      setFilteredCustomers(filteredCustomers);
    }
    localforage.setItem("customersAudience", customerData);
    setEdit(false);
    setEditloader(false);
  };

  const onLoad = async () => {
    console.log('mycustomer',customer)
    const customers = await localforage.getItem('customersAudience');
    let found = customers?.find((currentCustomer) => {
      return currentCustomer.pass._id == customer.pass.referredBy
    })
    // console.log('found ', found);
    // console.log('customer ', customer);
    setState({
      profile: [
        {
          "First Name": customer.user.firstName,
        },
        {
          "Last Name": customer.user.lastName || "-",
        },
        {
          Phone: customer.user.dialingCode
            ? customer.user.dialingCode + " " + customer.user.phone
            : "N/A",
        },
        {
          "Email Address": customer.user.email || "-",
        },
        {
          DOB: customer.pass.birthday ? new Date(customer.pass.birthday).getDate() + "-" + (new Date(customer.pass.birthday).getMonth() + 1) + '-' + new Date(customer.pass.birthday).getFullYear() : "-",
        },
        {
          "Assigned To": '-',
        },
        {
          "Referral": customer.user.referralCode ? customer.user.referralCode : '-',
        },
        {
          "Referred By": found ? found.pass.userData.firstName + " " + found.pass.userData.lastName : "-",
        },
      ],
      brandInfo: [
        {
          "Subscribed Brand": brand.brandName,
        },
        {
          Status: customer.pass.delete ? "Un active" : "Active",
        },
        {
          Tags: "-",
        },
        {
          "Subscription Date": new Date(customer.pass.created).toDateString(),
        },
      ],
    })


  }

  useEffect(onLoad, [])

  // console.log('refferedBy ', customer);

  return (
    <div id="info" className="pt_32 pl_60 pr_60">
      {loader ? (
        <Loader />
      ) : (
        <>
       
          <div div className="row">
            <div className="color-neutral100 fs-18 fw-5 col-8 pt_8">Profile</div>
            <div className="col-4 edit text-left">
              {!edit ? (
                <CustomButton
                  style={{ marginLeft: "80px" }}
                  varient={"tertiary"}
                  btntext={"Edit Profile"}
                  onClick={() => {
                    setEdit(!edit);
                  }}
                  icon={
                    <SvgIcons.EditIcon
                      height={"18"}
                      width={"18"}
                      color={"#3770FB"}
                    />
                  }
                />
              ) : (
                <div className="d-flex justify-content-end">
                  <CustomButton
                    btntext={"Cancel"}
                    varient={"secondary"}
                    disabled={editLoader}
                    onClick={() => {
                      setEdit(false);
                    }}
                    className={"mr_8"}
                  />

                  <CustomButton
                    varient={"primary"}
                    btntext={"Save Changes"}
                    disabled={editLoader}
                    onClick={() => {
                      updateUser();
                    }}
                    className={"fs-14 fw-5 w-100"}
                    icon={
                      editLoader ? (
                        <CircularProgress size={16} color={"inherit"} />
                      ) : (
                        ""
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="bar mt_12"></div>
         
          <div>
            <div className="componentBox w-100">
              {state?.profile.map((element, idx) => (
               
                <div key={idx} className="topHeaderSection row">
                  
                  <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                    {Object.keys(element)}
                  </div>
                  <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                    {idx === 0 ? (
                      <div className="d-flex">
                        {edit ? (
                          <CustomTextField
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        ) : (
                          <div>{firstName}</div>
                        )}
                      </div>
                    ) : idx === 1 ? (
                      <div className="d-flex">
                        {edit ? (
                          <CustomTextField
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        ) : (
                          <div>{lastName}</div>
                        )}
                      </div>
                    )
                      : idx === 4 ? (
                        <div className="d-flex">
                          {edit ? (
                            // <CustomTextField
                            //   value={dateOfBirth}
                            //   onChange={(e) => setdateOfBirth(e.target.value)}
                            // />
                            <DatePicker
                              onChange={(e) => {
                                setdateOfBirth(new Date(e).valueOf())
                              }}
                              selected={dateOfBirth && new Date(dateOfBirth)}

                              icon="fa fa-calendar"
                              style={{ padding: '12.5px 14px' }}
                              className="pt_12 pr_14 pb_12 pl_14"
                            />
                          ) : (
                            <div>{dateOfBirth ? new Date(dateOfBirth).getDate() + "-" + (new Date(dateOfBirth).getMonth() + 1) + '-' + new Date(dateOfBirth).getFullYear() : '-'}</div>
                          )}
                        </div>
                      ) :
                        idx === 2 ? (
                          <div className="d-flex">
                            {edit ? (
                              <div className="row">
                                <div className="col-3">
                                  <CustomTextField
                                    value={dialingCode}
                                    onChange={(e) => setDialingCode(e.target.value)}
                                  />
                                </div>{" "}
                                <div className="col-9">
                                  <CustomTextField
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />{" "}
                                </div>
                              </div>
                            ) : (
                              <div>
                                {customer.user.dialingCode
                                  ? customer.user.dialingCode +
                                  " " +
                                  customer.user.phone
                                  : customer.user.chatBotPhone
                                    ? customer.user.chatBotPhone
                                    : "N/A"}
                              </div>
                            )}
                          </div>
                        ) : idx === 5 ? (
                          <div className="d-flex">
                            {edit ? (
                              <div>
                                <select
                                  className="color-neutral100"
                                  value={selected}
                                  onChange={(e) => {
                                    setSelected(e.target.value);
                                  }}
                                >
                                  <option>none</option>
                                  {fields.map((element, idx) => (
                                    <option value={element._id}>
                                      {element.key}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <div>{selectedName || "-"}</div>
                            )}
                          </div>
                        ) : (
                          <>{console.log('Object.values(element)', Object.values(element))}
                            <div className="d-flex">{Object.values(element)}</div></>
                        )}
                  </div>
                </div>
              ))}
              {susbscriberField.length > 0 &&
                susbscriberField.map((element, idx) => (
                  <div key={idx} className="topHeaderSection row">
                    <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                      {element.label}
                    </div>
                    <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                      {edit ? (
                        element.moreValues ? (
                          <select
                            onChange={(e) => {
                              customFields[idx].value = e.target.value;
                              setSelectedCustomField(true);
                            }}
                            value={customFields[idx].value}
                          >
                            <option value={element.value}>
                              {element.value}
                            </option>
                            {element.moreValues.map((item, idx) => (
                              <option value={item.value}>{item.value}</option>
                            ))}
                          </select>
                        ) : (
                          <CustomTextField
                            value={customFields[idx].value}
                            onChange={(e) => {
                              let fieldArray = [];
                              customFields.map((field, index) => {
                                if (index === idx) {
                                  fieldArray.push({
                                    ...field,
                                    value: e.target.value,
                                  });
                                } else {
                                  fieldArray.push(field);
                                }
                              });
                              setCustomFields(fieldArray);
                              setSelectedCustomField(true);
                            }}
                          />
                        )
                      ) : (
                        <div>
                          {(customFields && customFields[idx].value) || "-"}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="color-neutral100 fs-18 fw-5 mt_32">Brand Info</div>
          <div className="bar mt_12"></div>
          <div className="">
            <div className="componentBox w-100">
              {state?.brandInfo.map((element, idx) => (
                <div key={idx} className="topHeaderSection row">
                  <div className="col-7 align-self-center pl_0 color-caption opacity-10 Body14R">
                    {Object.keys(element)}
                  </div>
                  <div className="color-neutral100 col-5 fs-14 fw-5 align-self-center ">
                    {idx === 1 ? (
                      <div
                        className={
                          Object.values(element)[0] === "Active"
                            ? "active"
                            : "inactive"
                        }
                      >
                        {Object.values(element)}
                      </div>
                    ) : (
                      <div className="d-flex">{Object.values(element)}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Transaction = ({ customer }) => {
  return (
    <div id="transaction" className="pt_32 pl_60 pr_60">
      <div className="color-neutral100 fs-18 fw-5 pt_8">Transactions</div>
      <div className="bar mt_12"></div>
      <TransactionHistory customer={customer} />,
    </div>
  );
};

const AddCustomerComponent = ({
  onClose,
  getTransactions,
  setTransactions,
  setPasses,
  getPass,
  show,
  setShow,
  setFilteredCustomer,
  selected,
  setSelected,
  campaigns,
  setCustomers,
  update,
  setUpdate
}) => {
  const agency = useContext(AgencyContext);
  console.log('selected', selected);

  const initState = {
    firstName: "",
    email: "",
    lastName: "",
    dialingCode: "+92",
    phone: "",
    birthday: "",
  };

  const [loader, setLoader] = useState(false);
  const brand = useContext(BrandContext);

  const handleSubmit = async () => {
    setLoader(true);
    const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+' + formik.values.fullPhone)

    console.log('fullPhone ', fullPhone);

    let payload = {
      firstName: formik.values.firstName,
      email: formik.values.email,
      campaignCode: selected.campaign.campaignCode,
      brandId: selected.campaign.brandId,
      lastName: formik.values.lastName,
      phone: fullPhone.withoutCountryCode.replace(/\s/g, ''),
      dialingCode: fullPhone.countryCode,
      birthday: new Date(formik.values.birthday).valueOf(),
    };
    console.log('payload ', payload);

    if (formik.values.birthday) payload.birthday = new Date(formik.values.birthday).valueOf()

    const headers = { "x-api-key": brand.apiKey };

    const { response, error } = await PassService.Create({
      toaster: true,
      payload,
      headers,
    });
    if (response) {
      console.log('response.data', response.data);
      const query = {
        _id:
          brand.brandType === Brand.BrandTypes.SUB_BRAND
            ? brand.parentBrandId
            : brand._id,
        userId: response.data.userId,
        delete: false,
      };
      const subscriber = await BrandService.GetSubscribers({ query });
      if (subscriber.response) {
        let customers = await localforage.getItem("totalcustomers");
        customers.push(subscriber.response.data[0]);
        // localForage.setItem('customers', customers)
        setCustomers(customers);
        setFilteredCustomer(customers);

        let pass = await getPass(subscriber.response.data[0]);
        setPasses((prevState) => [...prevState, pass]);
        let transaction =
          pass &&
          (await getTransactions(subscriber.response.data[0], pass._id));
        setTransactions((prevState) => [
          ...prevState,
          { [pass._id]: transaction },
        ]);
      }
      setShow({ ...show, addCustomer: false, showAddContact: false });
    } else {
      console.log('err', error);
    }

    setLoader(false);
    setUpdate(true)

  };





  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: PassVld.Create,
  });
  console.log("AAAA", formik.values)
  return (
    <div className="addCustomerComponent" id="customModal">
      <form>
        <div className="Heading22R color-neutral80">Add Customer</div>
        <div className="customLabel mt_16">Select Campaign</div>
        <div className="campaign-dropdown w-100 mt_0 position-relative mt_8">
          <ClickOutside
            onClick={(e) =>
              show.addCustomerDropDown &&
              setShow({ ...show, addCustomerDropDown: false })
            }
          >
            <div
              onClick={() =>
                setShow({
                  ...show,
                  addCustomerDropDown: !show.addCustomerDropDown,
                })
              }
            >
              <span className="fs-16" style={{ color: '#525252' }}>{selected.campaign.campaignName}</span>
              {
                <span className="float-right">
                  <SvgIcons.DropDownTriangleIcon
                    height="12px"
                    width="10px"
                    color={ColorSchemeCode.c828282}
                  />
                </span>
              }
            </div>
            {show.addCustomerDropDown && (
              <CampaignsDropDown
                title={"Campaigns"}
                campaignList={campaigns}
                onSelect={(campaign) => {
                  setSelected({ ...selected, campaign });
                  setShow({ ...show, addCustomerDropDown: false });
                }}
              />
            )}
          </ClickOutside>
        </div>
        <div className="mt_32 w-100">
          <CustomTextField
            label="First Name"
            className="w-100"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && formik.errors.firstName}
            helperText={
              formik.touched.firstName &&
              formik.errors.firstName &&
              formik.errors.firstName
            }
          />
        </div>
        <div className="mt_29 w-100">
          <CustomTextField
            label="Last Name"
            className="w-100"
            labelPaddingLeft="0px"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            error={formik.touched.lastName && formik.errors.lastName}
            helperText={
              formik.touched.lastName &&
              formik.errors.lastName &&
              formik.errors.lastName
            }
          />
        </div>
        <div className="phoneNumber d-flex mt_21" >
          <div className="w-100 pl_0 " id="PhoneInputField input">
            <label
              className="mb_4 fs-12 fw-5 control-label"
              style={{
                color: "rgb(75, 78, 91)",
                letterSpacing: "0.1px",
                transition: "all 0.2s ease-in-out 0s",
              }}
            >
              Phone
            </label>
            {console.log('formik ', formik.values)}
            <PhoneInput

              inputClass={'w-100 Body14R phoneInputField'}
              inputStyle={{ border: '1px solid ' + ColorSchemeCode.OtherBorder, height: '42px' }}
              value={formik.values.fullPhone || ''}
              onChange={

                phone => formik.setValues({ ...formik.values, fullPhone: phone })

              }
            />
          </div>
        </div>
        <div className="mt_29 w-100">
          <CustomTextField
            label="Email Address"
            className="w-100"
            labelPaddingLeft="0px"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            error={formik.touched.email && formik.errors.email}
            helperText={
              formik.touched.email && formik.errors.email && formik.errors.email
            }
          />
        </div>
        <div className="mt_21 w-100">
          <div className="customLabel">Birthday</div>
          {/* <CustomDatePicker  */}
          <CustomTextField
            fontSize="14px"
            className="w-100 date-icon-ssp"
            type="date"
            name="birthday"
            value={formik.values.birthday}
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            error={formik.touched.birthday && formik.errors.birthday}
            helperText={
              formik.touched.birthday &&
              formik.errors.birthday &&
              formik.errors.birthday
            }
          />
        </div>
        <div className="d-flex justify-flex-end mt_32">
          <div className="mr_8">
            <CustomButton
              varient="secondary"
              btntext="Cancel"
              onClick={onClose}
            />
          </div>
          <div>
            <CustomButton
              varient="primary"
              btntext="Add"
              onClick={handleSubmit}
              disabled={formik.values.firstName == '' || formik.values.firstName == false || formik.values.email == '' || formik.values.birthday == '' || formik.values.lastName == '' || formik.values.fullPhone == ''}
              icon={loader && <CircularProgress size={20} color={"inherit"} />}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const Setting = ({
  customer,
  filteredCustomers,
  setFilteredCustomers,
  customerData,
  setCustomerData,
  show,
  setShow,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleDelete = async () => {
    setDeleteLoader(true);
    const { error, response } = await PassService.DeletePass({
      payload: { passId: customer.pass._id, delete: false },
    });

    if (response) {
      customerData.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          customerData.splice(idx, 1);
        }
      });
      setCustomerData(customerData);
      filteredCustomers.map((element, idx) => {
        if (element.pass._id === customer.pass._id) {
          filteredCustomers.splice(idx, 1);
        }
      });
      setFilteredCustomers(filteredCustomers);
    }
    localforage.setItem("customersAudience", customerData);
    setDeleteLoader(false);
    setDeleteModal(false);
    setShow(false);
  };

  return (
    <div id="settingComponentAudience">
      <div className="buttonDelete">
        <div className="Heading16M color-neutral100 mb_4">Delete Contact</div>
        <div className="Body14R color-neutral60">
          Once you delete your contact, there is no going back. Please be
          certain.
        </div>
        <div className="bar mt_12 mb_24"></div>
        <CustomButton
          btntext={"Delete this contact"}
          varient="warningSecondary"
          onClick={() => {
            setDeleteModal(true);
          }}
        />

        <CustomsModal
          open={deleteModal}
          minWidth={"600px"}
          component={
            <DeleteModal
              icon={deleteLoader}
              title="Subscriber"
              onCancel={() => setDeleteModal(false)}
              onDelete={handleDelete}
            />
          }
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};