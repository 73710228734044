import React, { useState, useEffect } from "react";
import Notfound from "../components/Notfound";
import { AgencyService } from "../services";
import { enums } from '../enums'
import env from "../config";
import { ColorSchemeCode } from "../enums/ColorScheme";
import { utils } from "../utils";
import {SvgIcons} from '../icons'

const AgencyContext = React.createContext();

function withAgency(Component){
    function Agency(props){
        const [state, setState] = useState({
            processing : true,
            apiError   : '',
            whiteLabel : false,
            ...enums.Agency
        })
        //hello world

        const getAgency = async (query) => {
            const { error, response } = await AgencyService.GetPublic({query});

            if(error) return setState({...state, processing: false, apiError: error.error})

            if(response) return setState({...state, processing: false, ...response.data[0], whiteLabel: true})
        }

        const checkAgency = async () => {
            // console.log('env.AGENCY.default', env.AGENCY.default);
            if(env.AGENCY.default) return getAgency({domain: env.AGENCY.domain})

            const hostname = window.location.hostname
            if(env.WALLETLY_DOMAIN === hostname || env.WALLETLY_DOMAIN2 === hostname) {
                return setState({...state, processing: false})}

            let query = {}
            if(env.WALLETLY_USINESS_DOMAIN && hostname.includes(env.WALLETLY_USINESS_DOMAIN)) query.agencyName = hostname.split('.')[0]
            else query.domain = hostname

            console.log('query agency', query)
            return getAgency(query)
        }

        const onLoad = () => checkAgency()

        useEffect(onLoad, [])
    
        const updateAgency = (updates) => setState({...state, ...updates})
    
        return(
            <>
            {state.processing && <div className="absoluteMiddle">
                <Loader />
                {/* Please wait while we're getting ready... */}
                </div>}
            {state.error && <div className="middle"><Notfound /></div>}
            {
                !state.processing && !state.error &&
                <AgencyContext.Provider value={{...state, updateAgency: updateAgency}}>
                    <Component {...props} />
                </AgencyContext.Provider>
            }
            </>
        )
    }

    return Agency
}

const Loader = ({baseWidth, baseHeight, height, width, state }) => {
    return (
        <div className="position-relative loaderOuterPage" style={{height: baseHeight || '100vh', width: baseWidth || '100%'}}>
            <div className="absoluteMiddle">
                <img src={state && state.loader} width={width} height={height} />
            </div>
      </div> 
    )
}


export { withAgency, AgencyContext }