import React, { useContext, useEffect, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import { useFormik } from 'formik';

import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';

import GoogleMap from '../../../components/googleMap/GoogleMap';
import Autocomplete from '../../../components/googleMap/Autocomplete';

import { ClickOutside, Options, utils } from '../../../utils';

import { BrandContext } from '../../../context/Brand.context';
import { LayoutContext } from '../../../context/Layout.context';
import { BrandVld } from '../../../validation';
import { BrandService } from '../../../services';
import { SvgIcons } from '../../../icons';
import CustomButton from '../../../components/CustomButton';
import { AgencyContext } from '../../../context/Agency.context';
import 'react-phone-input-2/lib/style.css'
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import ViewDetail from '../../../components/ViewDetail';
import LocationAutoCompleteField from '../../../components/LocationAutoCompleteField';


export default function Location({ formik, initState, brand, state, setState }) {

    const layout = useContext(LayoutContext)

    React.useEffect(() => {
        layout.setLayout({
            title: 'Settings',
            borderBottom: false,
            switch: "",
            button: ""
        })
    }, [formik.values, formik.errors])



    return (
        <div id='General'>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <BrandLocation formik={formik} brand={brand} initState={initState} />
                </div>
            </form>
        </div>
    )
}

const BrandLocation = ({ formik, initState, brand }) => {

    const onAddressChange = (event) => formik.setValues({ ...formik.values, location: { ...formik.values.location, address: event.target.value } },console.log('pool',event))
    const onCityChange = (event) => formik.setValues({ ...formik.values, location: { ...formik.values.location, city: event.target.value } })
    const onCountryChange = (event) => formik.setValues({ ...formik.values, location: { ...formik.values.location, country: event.target.value } })
    const onCountryCodeChange = (event) => formik.setValues({ ...formik.values, location: { ...formik.values.location, countryCode: event.target.value } })
    const onStateChange = (event) => formik.setValues({ ...formik.values, location: { ...formik.values.location, state: event.target.value } })
    const locationSummary = (location) => {
        const currency = utils.getCurrency({location: location})
        const selectedTimezone = utils.getTimezone({location: location})
        formik.setValues({ ...formik.values, location: {...location, timezone : selectedTimezone},  currency: currency,   })
    }

    const onTimezoneChange = (event) => formik.setValues({ ...formik.values, location: { ...formik.values.location, timezone: event.target.value } })
    const onCurrencyChange = (event) => formik.setValues({ ...formik.values, location: { ...formik.values.location, code: event.target.value } })

    const [show, setShow] = useState(false);
    const [selectedIndex, setSelectedInde] = useState(false);

    console.log('zee ', formik.values)

    const AddNewLocationFunc = () => {
        let newLocation = {
            location: {
                address: "",
                city: "",
                state: "",
                countryCode: "",
                country: "",
                timezone:""
            },
            currency : {}
        }
        formik.setValues({ ...formik.values, multipleLocations: [...formik.values.multipleLocations, newLocation]});
    }

    const getPlace = (e) => {

        const Geocoder = new window.google.maps.Geocoder()
        const placeRequest = { location: { lat: e.latLng.lat(), lng: e.latLng.lng() } }

        Geocoder.geocode(placeRequest, (places, status) => {
            if (status !== window.google.maps.GeocoderStatus.OK) return
            const location = utils.summarisedLocation(places[0])
            formik.setValues({ ...formik.values, location })
            
        })
        
    }

    const handleRemoveFunc = () => {
        // console.log('zee ')
        let currentLocations = formik.values.multipleLocations.filter((value, idx) => {
            return idx != selectedIndex
        })
        formik.setValues({ ...formik.values, multipleLocations: currentLocations })
    }

    const viewMoreItems = [
        {
            name: 'Remove this location',
            function: handleRemoveFunc,
        },
    ]

    const [state, setState] = useState({
        generalLoader: false,
        apiError: ''
    })

    const handleSubmit = async () => {
        setState({ loader: true, apiError: '' })

        const payload = {
            _id: brand._id,
            location: formik.values.location,
            multipleLocations: formik.values.multipleLocations
        }

        console.log('payload is ', payload);

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        if (error) return setState({ loader: false, apiError: error.error })

        brand.updateBrand(response.data)
        return setState({ loader: false, apiError: '' })
    }


    return (
        <div className="brandSetting">
            <div className='col-md-12   col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                <div className='Heading22M color-neutral100'>Locations</div>
                <CustomButton
                    btntext={"Update"}
                    type="submit"
                    disabled={state.loader}
                    icon={state.loader && <CircularProgress size={20} color={'inherit'} />}
                    onClick={handleSubmit}
                />
            </div>
            <div className=" col-md-12 col-12 pl_0 pr_0 mb_11">
                <div className="bar"></div>
            </div>
            <div className="w-70">
                <div className="w-100">
                    <div className='Heading16M color-heading '>Location 01</div>
                    <div className="col-md-12 col-12 pl_2 mt_16 pr_0">
                        <Autocomplete
                            className       = "w-100"
                            variant         = "standard"
                            label           = "Location"
                            name            = "address"
                            value           = {formik.values.location ? formik.values.location.address : ''}
                            onChange        = {onAddressChange}
                            InputProps      = {{ tabIndex: '6', disableUnderline: false, style: { fontSize: 16, color: 'color(neutral80)' } }}
                            locationSummary = {locationSummary}
                            error           = {formik.errors.location && !formik.values.location ? formik.errors.location.address : false}
                            helperText      = {formik.errors.location && !formik.values.location ? formik.errors.location.address : ''}
                        />
                    </div>

                    <div className="col-12 col-md-12 pr_2 pl_0 mapBox mt_16">
                        <GoogleMap
                            width     = {"100%"}
                            height    = {"100%"}
                            zoom      = {formik.values.location && formik.values.location.lat ? 15 : 4}
                            draggable = {true}
                            position  = {formik.values.location ? { lat: formik.values.location.lat, lng: formik.values.location.lng } : ''}
                            dragEnd   = {getPlace}
                            className = {'borderRadius-4'}
                        />
                    </div>
                    {console.log('formik ', formik.values)}
                    <div className="d-flex mt_15 col-md-12 pl_2 pr_0">

                        <div className="col-md-6 col-6 pl_0">
                            <LocationAutoCompleteField
                                className   = "w-100"
                                label       = "City"
                                placeholder = "Your brand city"
                                name        = "city"
                                value       = {formik.values.location ? formik.values.location.city : ''}
                                onChange    = {onCityChange}
                                error       = {formik.errors.location && formik.values.location && !formik.values.location.city ? formik.errors.location.city : false}
                                helperText  = {formik.errors.location && formik.values.location && !formik.values.location.city ? formik.errors.location.city : ''}
                            />
                        </div>

                        <div className="col-md-6 col-6 pl_0 pr_0">
                            <LocationAutoCompleteField
                                className   = "w-100"
                                label       = "Country"
                                placeholder = "Your country"
                                name        = "country"
                                value       = {formik.values.location ? formik.values.location.country : ''}
                                onChange    = {onCountryChange}
                                error       = {formik.errors.location && formik.values.location && !formik.values.location.country ? formik.errors.location.country : false}
                                helperText  = {formik.errors.location && formik.values.location && !formik.values.location.country ? formik.errors.location.country : ''}
                            />
                        </div>
                    </div>

                    <div className="d-flex mt_15 col-md-12 pl_2 pr_0 ">

                        <div className="col-md-6 col-6 pl_2">
                            <LocationAutoCompleteField
                                className   = "w-100"
                                label       = "Country Code"
                                placeholder = "Your country code"
                                name        = "countryCode"
                                value       = {formik.values.location ? formik.values.location.countryCode : ''}
                                onChange    = {onCountryCodeChange}
                                error       = {formik.errors.location && formik.values.location && !formik.values.location.countryCode ? formik.errors.location.countryCode : false}
                                helperText  = {formik.errors.location && formik.values.location && !formik.values.location.countryCode ? formik.errors.location.countryCode : ''}
                            />
                        </div>
                        <div className="col-md-6 col-6 pl_2 pr_0">
                            <CustomTextField
                                className   = "w-100"
                                label       = "State"
                                placeholder = "Your state"
                                name        = "state"
                                inputProps  = {{ tabIndex: '10' }}
                                value       = {formik.values.location ? formik.values.location.state : ''}
                                onChange    = {onStateChange}
                                error       = {formik.errors.location && formik.values.location && !formik.values.location.state ? formik.errors.location.state : false}
                                helperText  = {formik.errors.location && formik.values.location && !formik.values.location.state ? formik.errors.location.state : ''}
                            />
                        </div>

                    </div>
                    <div className="d-flex mt_15 col-md-12 pl_2 pr_0 mb_56">

                        <div className="col-md-6 col-6 pl_2">
                        <CustomSelect
                          className = "w-100"
                          options   = {Options?.getTimezones()}
                          label     = "Timezone Offset"
                          value     = {formik.values?.location.timezone || formik.values.location.timezone || ''}
                          onChange  = {(e)=>onTimezoneChange(e)}
                        />
                        </div>
                        
                        <div className="col-md-6 col-6 pl_2 pr_0">
                        <CustomSelect
                            className = "w-100"
                            options   = {Options?.getCurrencyCodes()}
                            label     = "Currency"
                            onChange  = {(e)=>onCurrencyChange(e)}
                            value     = {formik.values?.currency?.code || formik.values.location.code || ''}
                        />
                        </div>
                    </div>
                </div>

                <div className='w-100'>
                    {formik.values.multipleLocations.map((location, idx) => <div>
                        <div className='d-flex space-between w-100'>
                            <div className='Heading16M color-heading mt_8'>{`Location 0${idx + 2}`}</div>
                            <div className='cp position-relative pt_8' onClick={(e) => { setShow(!show); setSelectedInde(idx); }}>
                                <span className=''><SvgIcons.ViewMoreIcon /></span>
                                {(idx == selectedIndex) && show && <div className='position-absolute z-index-10'><ViewDetail items={viewMoreItems} /></div>}
                            </div>
                        </div>
                        <div className="col-md-12 col-12 pl_2 mt_16 pr_0">
                            <Autocomplete
                                className="w-100"
                                variant="standard"
                                label="Location"
                                name="address"
                                value={formik.values.multipleLocations[idx] ? formik.values.multipleLocations[idx].location.address : ''}
                                onChange={(event) => { console.log('event ', event.target.values); formik.values.multipleLocations[idx].location.address = event.target.value; formik.setValues({ ...formik.values }) }}
                                InputProps={{ tabIndex: '6', disableUnderline: false, style: { fontSize: 16, color: 'color(neutral80)' } }}
                                
                                locationSummary={(location) => { formik.values.multipleLocations[idx].location = location; formik.setValues({ ...formik.values }) 
                                let currency = utils.getCurrency({location: location})
                                let selectedTimezone = utils.getTimezone({location: location})
                                formik.values.multipleLocations[idx].location = {...location, timezone : selectedTimezone}; 
                                formik.values.multipleLocations[idx].currency = currency;
                                formik.setValues({ ...formik.values }) 
                            }}
                            />
                        </div>

                        <div className="col-12 col-md-12 pr_2 pl_0 mapBox mt_16">
                            <GoogleMap
                                width={"100%"}
                                height={"100%"}
                                zoom={formik.values.location && formik.values.location.lat ? 15 : 4}
                                draggable={true}
                                position={{ lat: formik.values.multipleLocations[idx].location.lat, lng: formik.values.multipleLocations[idx].location.lng }}
                                dragEnd={getPlace}
                                className={'borderRadius-4'}
                            />
                        </div>

                        <div className="d-flex mt_15 col-md-12 pl_2 pr_0">

                            <div className="col-md-6 col-6 pl_0">
                                <LocationAutoCompleteField
                                    className   = "w-100"
                                    label       = "City"
                                    placeholder = "Your brand city"
                                    name        = "city"
                                    value       = {formik.values.multipleLocations[idx] ? formik.values.multipleLocations[idx].location.city : ''}
                                    onChange    = {(event) => { formik.values.multipleLocations[idx].location.city = event.target.value; formik.setValues({ ...formik.values }) }}
                                  // error       = {formik.errors.location && formik.values.location && !formik.values.location.city ? formik.errors.location.city : false}
                                  // helperText  = {formik.errors.location && formik.values.location && !formik.values.location.city ? formik.errors.location.city : ''}
                                />
                            </div>

                            <div className="col-md-6 col-6 pl_0 pr_0">
                                <LocationAutoCompleteField
                                    className   = "w-100"
                                    label       = "Country"
                                    placeholder = "Your country"
                                    name        = "country"
                                    value       = {formik.values.multipleLocations[idx] ? formik.values.multipleLocations[idx].location.country : ''}
                                    onChange    = {(event) => { formik.values.multipleLocations[idx].location.country = event.target.value; formik.setValues({ ...formik.values }) }}
                                // error       = {formik.errors.location && formik.values.location && !formik.values.location.country ? formik.errors.location.country : false}
                                // helperText  = {formik.errors.location && formik.values.location && !formik.values.location.country ? formik.errors.location.country : ''}
                                />
                            </div>
                        </div>

                        <div className="d-flex mt_15 col-md-12 pl_2 pr_0 ">
                            <div className="col-md-6 col-6 pl_2">
                                <LocationAutoCompleteField
                                    className   = "w-100"
                                    label       = "Country Code"
                                    placeholder = "Your country code"
                                    name        = "countryCode"
                                    value       = {formik.values.multipleLocations[idx] ? formik.values.multipleLocations[idx].location.countryCode : ''}
                                    onChange    = {(event) => { formik.values.multipleLocations[idx].location.countryCode = event.target.value; formik.setValues({ ...formik.values }) }}
                                // error       = {formik.errors.location && formik.values.location && !formik.values.location.countryCode ? formik.errors.location.countryCode : false}
                                // helperText  = {formik.errors.location && formik.values.location && !formik.values.location.countryCode ? formik.errors.location.countryCode : ''}
                                />
                            </div>
                            <div className="col-md-6 col-6 pl_2 pr_0">
                                <LocationAutoCompleteField
                                    className   = "w-100"
                                    label       = "State"
                                    placeholder = "Your state"
                                    name        = "state"
                                    inputProps  = {{ tabIndex: '10' }}
                                    value       = {formik.values.multipleLocations[idx] ? formik.values.multipleLocations[idx].location.state : ''}
                                    onChange    = {(event) => { formik.values.multipleLocations[idx].location.state = event.target.value; formik.setValues({ ...formik.values }) }}
                                // error        = {formik.errors.location && formik.values.location && !formik.values.location.state ? formik.errors.location.state : false}
                                // helperText   = {formik.errors.location && formik.values.location && !formik.values.location.state ? formik.errors.location.state : ''}
                                />
                            </div>
                        </div>
                        <div className="d-flex mt_15 col-md-12 pl_2 pr_0 mb_56">

                        <div className="d-flex mt_15 col-md-12 pl_2 pr_0 mb_56">

                            <div className="col-md-6 col-6 pl_2">
                            <CustomSelect
                                className="w-100"
                                options={Options?.getTimezones()}
                                label="Timezone Offset"
                                onChange    = {(event) => { formik.values.multipleLocations[idx].location.timezone = event.target.value; formik.setValues({ ...formik.values }) }}
                                value      = {formik.values?.multipleLocations[idx]?.location?.timezone || ''}
                                
                            />
                            </div>

                            <div className="col-md-6 col-6 pl_2 pr_0">
                            <CustomSelect
                                className = "w-100"
                                options   = {Options?.getCurrencyCodes()}
                                label     = "Currency"
                                onChange  = {(event) => { console.log('1e', event.target.value); formik.values.multipleLocations[idx].location.code = event.target.value; formik.setValues({ ...formik.values }) }}
                                value     = {formik.values?.multipleLocations[idx]?.location.code || ''}
                            />
                            </div>
                            </div>

                    </div>

                    </div>)}




                </div>
                <div className='w-70'>
                    <CustomButton
                        className = {"mb-32"}
                        varient   = "tertiary"
                        icon      = {<SvgIcons.Add color={ColorSchemeCode.primary50} />}
                        btntext   = {"Add new location"}
                        onClick   = {AddNewLocationFunc}
                    />
                </div>
            </div>
        </div>
    )

}