import React, {useState, useEffect, useContext} from 'react'
import SaveCloseButtons from '../../../components/Save&CloseButton'
import { useFormik } from 'formik';
import CustomAccordion from '../../../components/CustomAccordion';
import {useHistory, useParams} from 'react-router-dom';
import CustomTextField from '../../../components/CustomTextField';
import CustomCheckBox from '../../../components/CustomCheckBox';
import { SvgIcons } from '../../../icons';
import CustomRadio from '../../../components/CustomRadio';
import { FormHelperText, InputLabel, RadioGroup } from '@mui/material';
import CustomSwitch from '../../../components/CustomSwitch';
import CustomTagFeild from '../../../components/CustomTagFeild';
import { Campaign, enums } from '../../../enums';
import { BrandContext } from '../../../context/Brand.context';
import { CampaignContext } from '../../../context/Campaign.context';
import { CampVld } from '../../../validation';
import { BrandService, CampaignService } from '../../../services';
import Campaigns from '../Campaigns';


export default function UpdateCoupon(props) {

    const brand    = useContext(BrandContext)
    const campaign = useContext(CampaignContext)

    const history = useHistory()

    
    const [stateFormik, setstateFormik] = useState({processing: false})
    const [state, setState] = useState({
        accordians : [],
    })
    const [show, setShow] = useState({
        programType  : false,
        programSize  : false,
        timeframe    : false,
        discountValue: false
    })
    
    
    let start_date,start_time
    if(props.history.location.state.detail.startCampaign){
        const date = new Date(props.history.location.state.detail.startCampaign)
        const mm   = (date.getMonth()+1).toString().padStart(2, "0")
        const dd   = date.getDate()
        const yyyy = date.getFullYear()

        start_date = yyyy+'-'+mm+"-"+dd

        const hours = date.getHours()
        const minutes = date.getMinutes()

        start_time = hours+":"+minutes
    }
    
    let end_date,end_time
    if(props.history.location.state.detail.endCampaign){
        const date = new Date(props.history.location.state.detail.endCampaign)
        const mm   = (date.getMonth()+1).toString().padStart(2, "0")
        const dd   = date.getDate()
        const yyyy = date.getFullYear()

        end_date = yyyy+'-'+mm+"-"+dd

        const hours = date.getHours()
        const minutes = date.getMinutes()

        end_time = hours+":"+minutes
    }

    const props_detail = props.history.location.state.detail;


    const initState = {
        _id         : props.history.location.state._id,
        campaignName: props.history.location.state.campaignName || 'My Coupon',
        campaignType: props.history.location.state.campaignType || '',
        unlimited   : false,                                                      //
        detail      : {
            isFixedAmount   : false,
            codeType        : props_detail.codeType,
            uniquePasses    : props_detail.uniquePasses,
            redemptionLimit : props_detail.redemptionLimit,
            passLimit       : props_detail.passLimit,
            startDate       : start_date,                      //
            endDate         : end_date,                        //  
            startTime       : start_time,                      //  
            endTime         : end_time,                        //
            startCampaign   : props_detail.startCampaign,
            endCampaign     : props_detail.endCampaign,
            expiryValidation: props_detail.expiryValidation,
            couponLife      : props_detail.couponLife,
            daysValidation  : props_detail.daysValidation,
            validDays       : props_detail.validDays,          // auto fill not working
            discountValue   : props_detail.discountValue,
            discountDeal    : props_detail.discountDeal || '',
        }
    } 

    const handleSubmit = async() =>{
        setstateFormik({processing: true})

        formik.setValues({...formik.values, detail: {...formik.values.detail, 
            startCampaign: new Date(formik.values.detail.startDate.toString() + " " + formik.values.detail.startTime.toString()).valueOf(),
            endCampaign: new Date(formik.values.detail.endDate.toString() + " " + formik.values.detail.endTime.toString()).valueOf()}})

        let payload = formik.values

        delete payload.unlimited
        delete payload.detail.startDate
        delete payload.detail.startTime
        delete payload.detail.endDate
        delete payload.detail.endTime

        if(formik.values.campaignType !== Campaign.CampaignTypes.GIVEAWAY_COUPON)
        payload.detail.discountDeal = null
        else
        payload.detail.discountValue = null

        const {error, response} = await CampaignService.UpdateCampaign({payload})
        if(error) return

        let selectedCampaignObj = {};
        selectedCampaignObj[response.data.campaignType] = response.data._id

        const brandUpdates = {_id: brand._id, selectedCampaign: selectedCampaignObj, campaignType: response.data.campaignType}
        const updateBrand = await BrandService.Update({payload: brandUpdates})

        updateBrand.response && brand.updateBrand(updateBrand.response.data)


        const location = window.location.pathname.split('/')
        location.pop()
        history.push({
            pathname: location.join('/') + '/coupon/detail',
            state   : response.data
        })
        if(!formik.values.detail.expiryValidation) formik.setValues({...formik.values, detail:{...formik.values.detail, couponLife: ''}})
        if(!formik.values.detail.daysValidation) formik.setValues({...formik.values, detail:{...formik.values.detail, validDays: []}})

        await CampaignService.UpdateCampaign({toaster: true, payload})

        setstateFormik({processing: false})

    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues   : initState,
        onSubmit        : handleSubmit,
        validationSchema: CampVld.Coupon,
    })

    useEffect(()=>{
        setState({...state, accordians : Allaccordians})

    },[])   // eslint-disable-line react-hooks/exhaustive-deps

    const DiscountValueSideField = [
        {
            value: <div className="d-flex"><div className="fw-7 mr_10"></div>{formik.values.detail.discountValue}</div>
        },
    ]

    const TimeframeSideField = [
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">Start Date/Time:</div>{formik.values.detail.startDate + " " + formik.values.detail.startTime}</div>
        },
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">End Date/Time:</div>{formik.values.detail.endDate + " " + formik.values.detail.endTime}</div>
        },
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">Coupon Lifetime:</div>{formik.values.detail.couponLife}</div>
        },
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">Active only on:</div>{formik.values.detail.validDays}</div>
        },
    ]
    
    const ProgramTypeSideField = [
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">Program Type:</div>{formik.values.campaignType}</div>
        },
    ]
    
    const ProgramSizeSideField = [
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">Program Size:</div>{formik.values.detail.codeType}</div>
        },
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">Code redemption limit:</div>{formik.values.detail.redemptionLimit}</div>
        },
        {
            value: <div className="d-flex"><div className="fw-7 mr_10">Code count:</div>{formik.values.detail.passLimit}</div>
        },
    ]
 
    const Allaccordians = [
        {
            title        : "Program Type",
            expanded     : false,
            details      : <ProgramType formik={formik} />,
            show         : false,
            sideField    : ProgramTypeSideField,
        },
        {
            title        : "Program Size",
            expanded     : false,
            details      : <ProgramSize formik={formik} />,
            show         : false,
            sideField    : ProgramSizeSideField,
        },
        {
            title        : "Timeframe",
            expanded     : false,
            details      : <Timeframe formik={formik} />,
            show         : false,
            sideField    : TimeframeSideField,
        },
        {
            title        : "Discount Value",
            expanded     : false,
            details      : <DiscountValue formik={formik} />,
            show         : false,
            sideField    : DiscountValueSideField,
        },
    ]
    

    return (
        <div id="Coupons">
            <form onSubmit={formik.handleSubmit}>
                <div className="mr_9 mt_24 mb_24 ml_24">
                    <SaveCloseButtons formik={formik} state={stateFormik} name={'campaignName'} placeholder={'SMS Name'} />
                    <FormHelperText>
                        {formik.touched.campaignName && formik.errors.campaignName && formik.errors.campaignName}
                    </FormHelperText>
                </div>

                <CustomAccordion
                    className    = "mb_24"
                    title        = {'Program Type'}
                    formik       = {formik}
                    expanded     = {show.programType}
                    handleChange = {()=> setShow({...show, programType: !show.programType})}
                    details      = {<ProgramType formik={formik} />}
                    Sidefields   = {ProgramTypeSideField}
                />
                <CustomAccordion
                    className    = "mb_24"
                    title        = {'Program Size'}
                    formik       = {formik}
                    expanded     = {show.programSize}
                    handleChange = {()=> setShow({...show, programSize: !show.programSize})}
                    details      = {<ProgramSize formik={formik} />}
                    Sidefields   = {ProgramSizeSideField}
                />
                <CustomAccordion
                    className    = "mb_24"
                    title        = {'Timeframe'}
                    formik       = {formik}
                    expanded     = {show.timeframe}
                    handleChange = {()=> setShow({...show, timeframe: !show.timeframe})}
                    details      = {<Timeframe formik={formik} />}
                    Sidefields   = {TimeframeSideField}
                />
                <CustomAccordion
                    className    = "mb_24"
                    title        = {'Discount Value'}
                    formik       = {formik}
                    expanded     = {show.discountValue}
                    handleChange = {()=> setShow({...show, discountValue: !show.discountValue})}
                    details      = {<DiscountValue formik={formik} brand={brand} />}
                    Sidefields   = {DiscountValueSideField}
                />

            </form>
        </div>
    )
}


const DiscountValue = ({formik, brand}) => {


    return (
        <div id="DiscountValue" className="col">
            <div className="w-35 mt_16">
                { formik.values.campaignType !== Campaign.CampaignTypes.GIVEAWAY_COUPON ?
                <CustomTextField
                    className   = "w-100"
                    type        = {"number"}
                    placeholder = {formik.values.campaignType === Campaign.CampaignTypes.AMOUNT_COUPON ? "Discount Value" : formik.values.campaignType === Campaign.CampaignTypes.PERCENT_COUPON ? "Percentage Value" : formik.values.campaignType === Campaign.CampaignTypes.AMOUNT_COUPON && formik.values.detail.isFixedAmount ? 'Fixed Amount Value' : ''}
                    label       = {formik.values.campaignType === Campaign.CampaignTypes.AMOUNT_COUPON ? "Discount Value" : formik.values.campaignType === Campaign.CampaignTypes.PERCENT_COUPON ? "Percentage Value" : formik.values.campaignType === Campaign.CampaignTypes.AMOUNT_COUPON && formik.values.detail.isFixedAmount ? 'Fixed Amount Value' : ''}
                    icon        = {formik.values.campaignType === Campaign.CampaignTypes.PERCENT_COUPON ? <div>%</div> : <div>{brand.currency.symbol}</div>}
                    position    = {formik.values.campaignType === Campaign.CampaignTypes.GIVEAWAY_COUPON ? '' : formik.values.campaignType === Campaign.CampaignTypes.PERCENT_COUPON ? "end" : "start"}
                    name        = "detail.discountValue"
                    value       = {formik.values.detail && formik.values.detail.discountValue}
                    onChange    = {formik.handleChange}
                    inputProps  = {{onFocus: formik.handleBlur}}
                    error       = {formik.touched.detail && formik.touched.detail.discountValue && formik.errors.detail &&formik.errors.detail.discountValue}
                    helperText  = {formik.touched.detail && formik.touched.detail.discountValue && formik.errors.detail && formik.errors.detail.discountValue}
                />

                :
                
                <CustomTextField
                    className   = "w-100"
                    type        = {"text"}
                    placeholder = {"Deal"}
                    label       = {"Deal"}
                    icon        = {''}
                    position    = {''}
                    name        = "detail.discountDeal"
                    value       = {formik.values.detail && formik.values.detail.discountDeal}
                    onChange    = {formik.handleChange}
                    inputProps  = {{onFocus: formik.handleBlur}}
                    error       = {formik.touched.detail && formik.touched.detail.discountDeal && formik.errors.detail &&formik.errors.detail.discountDeal}
                    helperText  = {formik.touched.detail && formik.touched.detail.discountDeal && formik.errors.detail && formik.errors.detail.discountDeal}
                />
                }
            </div>
        </div>
    )
}

const Timeframe = ({formik}) => {
    
    const Days = {
        '0': 'Sunday',
        '1': 'Monday',
        '2': 'Tuesday',
        '3': 'Wednesday',
        '4': 'Thursday',
        '5': 'Friday',
        '6': 'Saturday',
    }

    const [validDays, setValidDays] = useState()

    useEffect(() => {
        formik.setValues({...formik.values, detail: {...formik.values.detail, validDays: validDays}})
    }, [validDays])

    return (
        <div id="Timeframe" className="col">
            <div className="fw-6 fs-16 color-black mb_16">Campaign Start</div>
            <div className="d-flex">
                <div className="w-35 ">
                    <CustomTextField
                        className   = "w-100"
                        type        = "date"
                        placeholder = "Start Date"
                        label       = "Start Date"
                        name        = "detail.startDate"
                        value       = {formik.values.detail && formik.values.detail.startDate}
                        onChange    = {formik.handleChange}
                        inputProps  = {{onFocus: formik.handleBlur}}
                        error       = {formik.touched.detail && formik.touched.detail.startDate && formik.errors.detail && formik.errors.detail.startDate}
                        helperText  = {formik.touched.detail && formik.touched.detail.startDate && formik.errors.detail && formik.errors.detail.startDate}
                    />
                </div>
                <div className="w-35 ml_24">
                    <CustomTextField
                        className   = "w-100"
                        type        = "time"
                        placeholder = "Start Time"
                        label       = "Start Time"
                        name        = "detail.startTime"
                        value       = {formik.values.detail && formik.values.detail.startTime}
                        onChange    = {formik.handleChange}
                        inputProps  = {{onFocus: formik.handleBlur}}
                        error       = {formik.touched.detail && formik.touched.detail.startTime && formik.errors.detail && formik.errors.detail.startTime}
                        helperText  = {formik.touched.detail && formik.touched.detail.startTime && formik.errors.detail && formik.errors.detail.startTime}
                    />
                </div>
            </div>

            <div className="mt_16 fw-6 fs-16 color-black mb_16">Campaign End</div>
            <div className="d-flex">
                <div className="w-35 ">
                    <CustomTextField
                        className   = "w-100"
                        type        = "date"
                        placeholder = "End Date"
                        label       = "End Date"
                        name        = "detail.endDate"
                        value       = {formik.values.detail && formik.values.detail.endDate}
                        onChange    = {formik.handleChange}
                        inputProps  = {{onFocus: formik.handleBlur}}
                        error       = {formik.touched.detail && formik.touched.detail.endDate && formik.errors.detail && formik.errors.detail.endDate}
                        helperText  = {formik.touched.detail && formik.touched.detail.endDate && formik.errors.detail && formik.errors.detail.endDate}
                    />
                </div>
                <div className="w-35 ml_24">
                    <CustomTextField
                        className   = "w-100"
                        type        = "time"
                        placeholder = "End Time"
                        label       = "End Time"
                        name        = "detail.endTime"
                        value       = {formik.values.detail && formik.values.detail.endTime}
                        onChange    = {formik.handleChange}
                        inputProps  = {{onFocus: formik.handleBlur}}
                        error       = {formik.touched.detail && formik.touched.detail.endTime && formik.errors.detail && formik.errors.detail.endTime}
                        helperText  = {formik.touched.detail && formik.touched.detail.endTime && formik.errors.detail && formik.errors.detail.endTime}
                    />
                </div>
            </div>
            <div className="mt_32 mb_16"><CustomCheckBox inputProps  = {{onFocus: formik.handleBlur}} value={formik.values.detail.expiryValidation === true ? true : false} onChange={()=> formik.setValues({...formik.values, detail: {...formik.values.detail, expiryValidation:!formik.values.detail.expiryValidation}})} label="Coupon valid in this timeframe only"/></div>
            <FormHelperText>
                {formik.touched.detail && formik.touched.detail.expiryValidation && formik.errors.detail && formik.errors.detail.expiryValidation}
            </FormHelperText>
            {
                formik.values.detail.expiryValidation === true &&
                <div className="w-35">
                    <CustomTextField
                        className   = "w-100"
                        type        = "number"
                        placeholder = "Coupon Lifetime"
                        label       = "Coupon Lifetime"
                        icon        = {<div>Days</div>}
                        position    = "end"
                        name        = "detail.couponLife"
                        inputProps  = {{onFocus: formik.handleBlur}}
                        value       = {formik.values.detail && formik.values.detail.couponLife}
                        onChange    = {formik.handleChange}
                        error       = {formik.touched.detail && formik.touched.detail.couponLife && formik.errors.detail && formik.errors.detail.couponLife}
                        helperText  = {formik.touched.detail && formik.touched.detail.couponLife && formik.errors.detail && formik.errors.detail.couponLife}
                    />
                </div>
            }
            <div className="mt_24 mb_16"><CustomCheckBox inputProps  = {{onFocus: formik.handleBlur}} value={formik.values.detail.daysValidation === true ? true : false } onChange={()=> formik.setValues({...formik.values, detail: {...formik.values.detail, daysValidation:!formik.values.detail.daysValidation}})} label="Valid for particular days only"/></div>
            <FormHelperText>
                {formik.touched.detail && formik.touched.detail.daysValidation && formik.errors.detail && formik.errors.detail.daysValidation}
            </FormHelperText>
            {
                formik.values.detail.daysValidation === true &&
                <div className='w-35'>
                    <CustomTagFeild fixedOptions={formik.values.detail.validDays} inputProps  = {{onFocus: formik.handleBlur}} validDays={validDays} setValidDays={setValidDays} options={Object.values(Days)} placeholder="Active only on"/>
                    <FormHelperText>
                        {formik.touched.detail && formik.touched.detail.validDays && formik.errors.detail && formik.errors.detail.validDays}
                    </FormHelperText>
                </div>
            }
        </div>
    )
}

const ProgramSize = ({formik}) => {

    return (
        <div id="ProgramSize" className="col">
            <RadioGroup name="detail.codeType" inputProps  = {{onFocus: formik.handleBlur}} value={formik.values.detail.codeType} onChange={(e)=> formik.setValues({...formik.values, detail: {...formik.values.detail, codeType: e.target.value}})}>
                <div className="d-flex">
                    <CustomRadio 
                        label     = "Bulk codes"
                        className = "mr_24"
                        value     = "Bulk codes"
                        checked   = {formik.values.detail.codeType === "Bulk codes" ? true : false}
                        />
                    <CustomRadio 
                        label = "Standalone code"
                        value = "Standalone code"
                        checked   = {formik.values.detail.codeType === "Standalone code" ? true : false}
                    />
                </div>
                <FormHelperText>
                    {formik.touched.detail && formik.touched.detail.codeType && formik.errors.detail && formik.errors.detail.codeType}
                </FormHelperText>
            </RadioGroup>

            <div className="d-flex mt_32 mb_32">
                <CustomSwitch
                    inputProps = {{onFocus: formik.handleBlur}}
                    onChange   = {()=> formik.setValues({...formik.values, detail: {...formik.values.detail, uniquePasses:!formik.values.detail.uniquePasses}})}
                    checked    = {formik.values.detail && formik.values.detail.uniquePasses === true ? true : false}
                />
                <div className="fs-14 fw-4 color-placeholder ml_8 middle">
                    Customers will be allowed to join campaign only once
                </div>
            </div>
            <FormHelperText>
                {formik.touched.detail && formik.touched.detail.uniquePasses && formik.errors.detail && formik.errors.detail.uniquePasses}
            </FormHelperText>

            <div className="d-flex">
                <div className="w-35">
                    <CustomTextField
                        className   = "w-100"
                        type        = "number"
                        placeholder = "Code redemption limit"
                        label       = "Code redemption limit"
                        name        = "detail.redemptionLimit"
                        value       = {formik.values.detail && formik.values.detail.redemptionLimit}
                        onChange    = {formik.handleChange}
                        disabled    = {formik.values.detail.redemptionLimit === 'Unlimited' ? true : false}
                        inputProps  = {{onFocus: formik.handleBlur}}
                        error       = {formik.touched.detail && formik.touched.detail.redemptionLimit && formik.errors.detail && formik.errors.detail.redemptionLimit}
                        helperText  = {formik.touched.detail && formik.touched.detail.redemptionLimit && formik.errors.detail && formik.errors.detail.redemptionLimit}
                    />
                </div>
                <div className="ml_16 middle mt_35"><CustomCheckBox inputProps  = {{onFocus: formik.handleBlur}} value={formik.values.detail.redemptionLimit === 'Unlimited' ? true : formik.values.unlimited} 
                    onChange={
                        ()=> {
                            if (formik.values.unlimited === true) {
                                formik.setValues({...formik.values, unlimited: !formik.values.unlimited, detail: {...formik.values.detail, redemptionLimit:''}})
                            }
                            else{
                                formik.setValues({...formik.values, unlimited: !formik.values.unlimited, detail: {...formik.values.detail, redemptionLimit:'Unlimited'}})
                            }
                        }
                    } label="Unlimited"/>
                </div>
            </div>
            <FormHelperText>
                {formik.touched.unlimited && formik.errors && formik.errors.unlimited}
            </FormHelperText>
            
            <div className="w-35 mt_16">
                <CustomTextField
                    className   = "w-100"
                    type        = "number"
                    placeholder = "Code count"
                    label       = "Code count"
                    name        = "detail.passLimit"
                    value       = {formik.values.detail && formik.values.detail.passLimit}
                    onChange    = {formik.handleChange}
                    inputProps  = {{onFocus: formik.handleBlur}}
                    error       = {formik.touched.detail && formik.touched.detail.passLimit && formik.errors.detail && formik.errors.detail.passLimit}
                    helperText  = {formik.touched.detail && formik.touched.detail.passLimit && formik.errors.detail && formik.errors.detail.passLimit}
                />
            </div>
        </div>
    )
}

const ProgramType = ({formik}) => {

    const cards = [
        {
            svg  : <SvgIcons.PercentSolid />,
            head : 'Percentage',
            value: Campaign.CampaignTypes.PERCENT_COUPON,
            isFixed: false,
            body : 'Publish digital gift cards as unique one-off codes aimed at particular customers or launch a public, reusable gift card certificates that act as digital wallets'
        },
        {
            svg : <SvgIcons.DollarSolid />,
            head: 'Discount Coupon',
            value: Campaign.CampaignTypes.AMOUNT_COUPON,
            isFixed: false,
            body: 'Issue one-off, personalised discount codes for personal use (e.g. HELLO4543) or publish a public fixed-code voucher across your messaging channels (e.g. BlackFriday19)'
        },
        {
            svg : <SvgIcons.DollarSolid />,
            head: 'Fixed Amount Coupon',
            isFixed: true,
            value: Campaign.CampaignTypes.AMOUNT_COUPON,
            body: 'Issue one-off, personalised discount codes for personal use (e.g. HELLO4543) or publish a public fixed-code voucher across your messaging channels (e.g. BlackFriday19)'
        },
        {
            svg : <SvgIcons.GiftSolid />,
            isFixed: false,
            head: 'Giveaway',
            value: Campaign.CampaignTypes.GIVEAWAY_COUPON,
            body: 'Publish digital gift cards as unique one-off codes aimed at particular customers or launch a public, reusable gift card certificates that act as digital wallets'
        },
        {
            svg : <SvgIcons.GiftSolid />,
            isFixed: false,
            head: 'GiftCard',
            value: Campaign.CampaignTypes.GIFTCARD_COUPON,
            body: 'Publish digital gift cards as unique one-off codes aimed at particular customers or launch a public, reusable gift card certificates that act as digital wallets'
        },
    ]

    const handleCard = (e,value) => {
        if (value.head === Campaign.CampaignTypes.FIXED_AMOUNT){
            formik.setValues({...formik.values, campaignType: value.value, detail: {...formik.values.detail, isFixedAmount: true}})
        }
        else{
            formik.setValues({...formik.values, campaignType: value.value, detail: {...formik.values.detail, isFixedAmount: false}})
        }
    }

    return (
        <div id="ProgramType" className="ml_32 col row" >
            {
                cards.map((value, index)=> (
                    <>
                    <div className={formik.values.campaignType === value.value && formik.values.detail.isFixedAmount === value.isFixed ? "cardActive col-3 mt_20 p_16 mr_32 cp" : "card col-3 mt_20 p_16 mr_32 cp"} onClick={(e)=>handleCard(e,value)}>
                        <div className="middle">{value.svg}</div>
                        <div className="mt_16">
                            <div className="fs-14 fw-6 color-black mb_4">{value.head}</div>
                            <div className="fs-12 fw-4 color-placeholder">{value.body}</div>
                        </div>
                    </div>
                    </>
                ))
            }
            <FormHelperText>
                {formik.touched.campaignType && formik.touched.campaignType && formik.errors.campaignType && formik.errors.campaignType}
            </FormHelperText>
        </div>
    )
}
