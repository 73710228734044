import React from 'react'
import Fade from 'react-reveal/Fade';

function CustomerChannel() {
  return (
    <Fade top>
    <div className='middle' style={{height: 'calc(100vh - 200px)'}}>
        <div className='H24M color-neutral90 mb_24 text-align-center'>Here's a preview of your Opt-In Page</div>
        <div className='B16M color-neutral60 text-align-center'>To edit the labels, you can visit the Growth Tools section in Walletly for the page editing.</div>
    </div>
    </Fade>
  )
}

export default CustomerChannel