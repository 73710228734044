 import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';

import CustomSearch from '../../../components/CustomSearch';

import ViewDetail from '../../../components/ViewDetail';

import { ColorSchemeCode } from '../../../enums/ColorScheme';
import SvgIcons from '../../../icons/svg.icon';
import { ClickOutside } from '../../../utils';
import { CommunicationService } from '../../../services';


export default function CommunicationList({title, btntext, response, tableHeadings, state, setState, handleEditFunc, ...props}){
    const [view, setView] = useState({
        details : false,
        selectedIndex : '',
    })
    const history = useHistory()

    const goToCreateEmail = () => {
        history.push(props.match.url + '/sms/create')
    }

    const [filteredData, setFilteredData] = useState();
    const [data, setData] = useState({
        published: 0,
        scheduled: 0,
        draft   : 0,
    });


    const handleEventSearchFunc = (e) => {
        const SearchResult = response.filter(element => 
        {
            return element.props.communicationName.toLowerCase().includes(e.target.value.toLowerCase())
        });
        setFilteredData([...SearchResult])
    }

    const MessageTypes = [
        {
            title   : "published",
        },
        {
            title : "scheduled",
        },
        {
            title : "draft",
        },
    ]

    const [selectedType, setSelectedType] = useState(MessageTypes[0].title);

    const handleSelectType = (title) => {
        setSelectedType(title);
        if(title == MessageTypes[0].title){
            const SearchResult = response.filter(element => 
                {
                    return element.template.template.smsType == MessageTypes[0].title
                });
            setFilteredData([...SearchResult])
        }
        else if(title == MessageTypes[1].title){
            const SearchResult = response.filter(element => 
                {
                    return element.template.template.smsType == MessageTypes[1].title
                });
            setFilteredData([...SearchResult])
        }
        else if(title == MessageTypes[2].title){
            const SearchResult = response.filter(element => 
                {
                    return (!element.template.template.smsType) || (element.template.template.smsType == MessageTypes[2].title)
                });
            setFilteredData([...SearchResult])
        }
    }

    useEffect(()=>{
        let published, scheduled, draft;
        published = scheduled = draft = 0
        for(let element of response){
            if(element.template?.template?.smsType == MessageTypes[0].title){
                published = published + 1
            }
            else if(element.template?.template?.smsType == MessageTypes[1].title){
                scheduled = scheduled + 1;
            }
            else if((!element.template.template.smsType) || element.template?.template?.smsType == MessageTypes[2].title){
                draft = draft + 1;
            }
        }
        handleSelectType(MessageTypes[0].title)
        setData({published : published,scheduled: scheduled, draft : draft })
    },[response])

    // console.log('filteredData ', filteredData)

    const handleDeleteFunc = async() =>{
        let payload = {
            _id : filteredData[view.selectedIndex].props.id,
            delete : true,
        }
        // console.log('payload ', payload , filteredData[view.selectedIndex])
        const {error , response} = await CommunicationService.Update({toaster: true, payload})
        if(response){
            let filteredMessages = filteredData.filter((message)=>{
                return message.props.id != filteredData[view.selectedIndex].props.id
            })
            setFilteredData([...filteredMessages])
        }
    }

    const viewMoreItems = [
        {
            name    : 'Delete',
            function: handleDeleteFunc,
        },
    ]

    
    return(
            <div id='communicationList'>
                <div className="d-flex pb_8 border-bottom">
                    {MessageTypes.map((element,idx)=>
                    <div className={`capitalize pt_8 d-flex pb_8 pr_8 pl_8 mr_8 cp borderRadius-6 transition  ${selectedType == element.title ? 'bg-color-primary0 Link14M' : 'color-neutral80 Link14M'} `} onClick={()=>handleSelectType(element.title)}>
                        <div className={`${selectedType == element.title && 'color-primary50' }`}>
                            {element.title}
                        </div>
                        <div className='color-neutral80 ml_8'>
                            ({data[element.title]})
                        </div>
                    </div>
                    )}
                </div>
                <div className='search d-flex justify-content-right w-100 pb_8 pt_8'>
                <div className='pt_8 pb_8 cp'><SvgIcons.FilterIcon /></div>
                <div className='color-neutral60 U14M pl_8 pr_16 pt_8 pb_8 cp'>Filters</div>
                <CustomSearch 
                    placeholder    = {'Search'}
                    className      = {'w-100 searchField'}
                    position       = {'start'}
                    height         = "36px"
                    onChange       = {handleEventSearchFunc}
                    disableEndIcon = {true}
                />
                </div>
            <div>
                {/* {console.log('filter data ', filteredData)} */}
                <div className='bar'></div>
                <div className='d-flex w-100 color-neutral90 Link12M audienceTableGrid overflow-hidden'>
                    <div className='col-5 pt_16 pb_16 cp ' 
                    >
                        {filteredData ? filteredData.length+" SMS" : response ? response.length+" SMS": "SMS"}
                    </div>
                    <div className='col-3 pt_16 text-center pb_16 cp Link11M'>
                        AUDIENCE
                    </div>
                    <div className='col-2 pt_16 text-center pb_16 cp Link11M'>
                        {selectedType == MessageTypes[1].title ? 'SCHEDULED' : 'CREATED'} 
                    </div>
                </div>
                <div className='bar'></div>

                <div className='data'>

                {
                    filteredData ? filteredData.length > 0 && filteredData.map((element,idx)=>(
                        <>

                        <div className='d-flex align-items-center w-100 pt_16 pb_16  tableRowHover' 
                            onClick={()=>{((!element.template.template.smsType) || element.template.template.smsType == MessageTypes[2].title) && element.actions.label.props.handleEditFunc(element)}}
                        >
                            <div className="col-5">
                                <div className='Heading15M color-neutral100'>
                                    {element.name.label}
                                </div>
                                <div className="Link13R color-neutral70 text-ellipsis--2">
                                    {element.template.template.viewMessage}
                                </div>
                            </div>
                            <div className='col-3 text-center color-neutral90 Link13R'>
                                {(element.template.template?.audience?.length || '0') + ' targeted' }
                            </div>
                            <div className='col-2  text-center color-neutral90 Link13R'>
                                {new Date(element.template.template.created).toDateString()}
                            </div>
                            <div className='col-2 cp text-center color-neutral90 Link13R'  onClick={()=>setView({...view, details : !view.details, selectedIndex : idx})}>
                            <SvgIcons.ViewMoreIcon color={ColorSchemeCode.tableActionDropdownColor}/>
                                {/* <ClickOutside onClick={()=>setView({...view, details : false})}> */}
                                    {view.details && view.selectedIndex == idx &&
                                        <div className="viewdetail position-absolute">
                                            <ViewDetail items={viewMoreItems}/>
                                        </div>
                                    }
                                {/* </ClickOutside> */}
                            </div>
                        </div>
                        <div className='bar'></div>
                        </>
                    ))
                    :
                    response && response.length > 0 && response.map((element,idx)=>(
                        <>
                        <div className='d-flex align-items-center w-100 dataRowSmall tableRowHover' 
                        onClick={()=>{((!element.template.template.smsType) || element.template.template.smsType == MessageTypes[2].title) && element.actions.label.props.handleEditFunc(element)}}
                        >
                          <div className="col-5">
                                <div className='Heading15M color-neutral100'>
                                    {element.name.label}
                                </div>
                                <div className="Link13R color-neutral70 text-ellipsis--2">
                                    {element.template.template.viewMessage}
                                </div>
                            </div>
                            <div className='col-3 text-center color-neutral90 Link13R'>
                                {(element.template.template?.audience?.length || '0') + ' targeted' }
                            </div>
                            <div className='col-2 text-center  color-neutral90 Link13R'>
                                {element.template.template?.geolocation || 'Deactivated' }
                            </div>
                            <div className='col-2  text-center color-neutral90 Link13R'>
                                {selectedType == MessageTypes[1].title  ? (new Date(element.template.template?.schedule)?.toDateString() || 'Not-Scheduled') : new Date(element.template.template.created).toDateString()}
                            </div>
                        </div>
                        <div className='bar'></div>
                        </>
                    ))
                }

                            </div>
                    </div>
            </div>        
    )
}



const ActionComponent = ({viewDetailsFunc, handleDeleteFunc, handleEditFunc}) => {

    const [viewMore, setViewMore] = useState(false);

    const ViewMoreIconClickedFunc = () => {
        setViewMore(!viewMore);
    }

    const viewMoreItems = [
       {
           name     : 'Delete',
           function : handleDeleteFunc,
       },
       {
           name     : 'View more',
           function : viewDetailsFunc,
       }
    ]

    return(
        <div className="d-flex">
            <div className="cp" onClick={ViewMoreIconClickedFunc}>
                <SvgIcons.ViewMoreIcon color={ColorSchemeCode.tableActionDropdownColor}/>
                <ClickOutside onClick={()=>setViewMore(false)}>
                    {viewMore &&
                        <div className="viewdetail position-absolute">
                            <ViewDetail items={viewMoreItems}/>
                        </div>
                    }
                </ClickOutside>
            </div>
            <div className="ml_20 cp" onClick={handleEditFunc}>
                <SvgIcons.EditIcon color={ColorSchemeCode.tableActionDropdownColor}/>
            </div>
        </div>
    )
}

