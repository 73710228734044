import React, {  useState , useContext, useEffect}from 'react'

import CustomTextField from "../../components/CustomTextField";
import CustomTextArea from '../../components/CustomTextArea';
import CustomButton from "../../components/CustomButton";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSelect from '../../components/CustomSelect';

import { AgencyContext } from '../../context/Agency.context';
import { BrandService, PermissionService } from '../../services';

import { SvgIcons } from '../../icons';
import { Options } from '../../utils';
import { Brand, User } from '../../enums';
import { useFormik } from "formik";
import localforage from 'localforage';
import { UserContext } from '../../context/User.context';
import { BusinessType } from '../../enums/enums';
import { FormControl, FormHelperText, RadioGroup } from '@mui/material';
import { ColorSchemeCode } from '../../enums/ColorScheme';
import CustomRadio from '../../components/CustomRadio';
import VenueType from '../../enums/VenueType';


export default  function About({formik  , setBarndNameError}){

    const agency = useContext(AgencyContext);
    // const User   = useContext(UserContext);


    const [sharedBrandCheck, setSharedBrandCheck] = useState(false);
    const [demoCheck, setDemoCheck]               = useState(false);
    const [masterAdminCheck, setMasterAdminCheck] = useState(false);
    const [loyaltyTypeName, setLoyaltyTypeName]   = useState(formik.values.brandType ? (formik.values.brandType === Brand.BrandTypes.NORMAL ? 'Small Business' : formik.values.brandType === Brand.BrandTypes.DEMO ? 'Demo' : formik.values.brandType === Brand.BrandTypes.SHARED_LOYALITY ? 'Shared  Loyalty Provider' : '') : 'Small Business');

    async function handleSubmit() {
    }

    const [state, setState] = useState({
      apiError       : "",
      loader         : false,
      brandNameLoader: false,
    });

    const [show, setShow] =  useState({
      brandNameIcon    : false,
      expandLoyaltyType: false,
    })
    
    const [brandNameError, setBrandNameError]     = useState('')

    const validateBrandNameFunc = async(e) => {
      formik.setValues({...formik.values, brandName : e.target.value})
      setState({...state, brandNameLoader : true})
      const query = { brandName: e.target.value , delete: false}

      const { error, response } = await BrandService.Get({query})
      if(response){
        setBarndNameError(true)
         // console.log('brand found')
          setBrandNameError('Brand Name already exist')
          setShow({...show, brandNameIcon : false});
      }
      else{
          setBarndNameError(false)
          setShow({...show, brandNameIcon : true});
          setBrandNameError('');
      }
      setState({...state, brandNameLoader : false})
    }


    const serviceName = [
      {serviceName: 'Small Business', value: Brand.BrandTypes.NORMAL, disable: false, detail : 'Run a single loyalty program.'},
      {serviceName: 'Demo', value: Brand.BrandTypes.DEMO, disable: demoCheck || !agency.whiteLabel, detail : 'Create a demo business for trial period to experience how walletly works.'},
      {serviceName: 'Shared  Loyalty Provider', value: Brand.BrandTypes.SHARED_LOYALITY, disable: (!masterAdminCheck || (masterAdminCheck && sharedBrandCheck)) , detail: 'The concept of shared loyalty is that many unrelated businesss join together in a partnership, which they may not be able to partner more traditionally.'}, 
      // {serviceName: 'Shared  Loyalty Provider', value: Brand.BrandTypes.SHARED_LOYALITY , detail: 'The concept of shared loyalty is that many unrelated businesss join together in a partnership, which they may not be able to partner more traditionally.'}, 
    ]
    

    useEffect(async() => {

      console.log(formik.values.brandName);
      
      setState({...state, brandNameLoader : true})
      const query = { brandName: formik.values.brandName , delete: false}

      const { error, response } = await BrandService.Get({query})
      if(response){
        setBarndNameError(true)
         // console.log('brand found')
          setBrandNameError('Brand Name already exist')
          setShow({...show, brandNameIcon : false});
      }
      else{
          setBarndNameError(false)
          setShow({...show, brandNameIcon : true});
          setBrandNameError('');
      }
      setState({...state, brandNameLoader : false})
    }, [])
    

    const onLoad = async () => {
      // validateBrandNameFunc()
      setShow(true)
      const user = await localforage.getItem('user');
      let query = {userId : user._id, businessType: BusinessType.AGENCY, roles : User.Roles.MASTER_ADMIN}
      const permissions = await PermissionService.GetQueriedPermissions({query})
      let check = false;
      console.log('agency',agency);
      if(user.roles.includes(User.Roles.MASTER_ADMIN) && permissions.response.data[0].businessId === agency._id){check = true; setMasterAdminCheck(true);console.log("Master Admin Created");}
      if(agency&&agency.agencyUse&&agency.agencyUse==='partner') { setMasterAdminCheck(false);}
      
      if(check || masterAdminCheck){
          query = {createdBy: user._id, brandType: Brand.BrandTypes.SHARED_LOYALITY, delete: false}
          const {error, response} = await BrandService.Get({query});
          if(response) {setSharedBrandCheck(true);console.log("Alredy Created");}
          else  {setSharedBrandCheck(false); }
      }

      query = {createdBy: user._id, brandType: Brand.BrandTypes.DEMO, delete: false}
      const {error, response} = await BrandService.Get({query})
      if(response)  {setDemoCheck(true)}
      else {setDemoCheck(false)}

    }

    useEffect(onLoad, [])


    const selectServiceType = async (e, value) => {
      e.preventDefault()
      setBrandNameError('') 
      setLoyaltyTypeName(value.serviceName);
      setShow({...show, expandLoyaltyType : false})

      if(value.value === Brand.BrandTypes.NORMAL) {return formik.setValues({...formik.values, brandType: value.value});}

      const user = await localforage.getItem('user'); 
      
      let query
      if(value.value === Brand.BrandTypes.SHARED_LOYALITY)
      {
          query = {createdBy: user._id, brandType: Brand.BrandTypes.SHARED_LOYALITY, delete: false}
          const {error, response} = await BrandService.Get({query})
          if(agency.whiteLabel && response && response.data[0].agency && response.data[0].agency.id === agency._id) return setBrandNameError('Shared Loyalty brand already exist')
          if(response && !agency.whiteLabel) return setBrandNameError('Shared Loyalty brand already exist')
      }

      if(value.value === Brand.BrandTypes.DEMO)
      {
          query = {createdBy: user._id, brandType: Brand.BrandTypes.DEMO, delete: false}
          const {error, response} = await BrandService.Get({query})
          if(response) return setBrandNameError('You already have Demo account')
      }

      return formik.setValues({...formik.values, brandType: value.value})
      // nextScreen();
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
      <div>
         {/* <h1 >Let's Get Started</h1>
            <p className='specialp '>This will help you setup your brand.</p> */}

        <form onSubmit={formik.handleSubmit} className=''>
        <div className="auth-form" style={{gap: '10px'}}>

          <div className="w-100 ">
             <CustomTextField 
                label       = {(Object.keys(params).length > 0)?"Restaurant Name*":"business Name*"}
                type        = "text"
                name        = "brandName"
                placeholder = "Business Name"
                value       = {formik.values.brandName}
                error       = {formik.errors.brandName}
                helperText  = {brandNameError || ''}
                onBlur      = {validateBrandNameFunc}
                icon        = {(formik.values.brandName&&formik.values.brandName!=="")?(<div>{state.brandNameLoader && !brandNameError ? <CircularProgress size={14} color={'inherit'}/> : !state.brandNameLoader && !brandNameError && show.brandNameIcon ? <SvgIcons.CircularTickIcon/> : ''}</div>):''}
                position    = {'end'}
            />
          </div>

          {/* <div className="position-relative w-100">
                <div className="customLabel mb_4">
                    Business Type*
                </div>
                <div className="selectDropdown cp d-flex space-between" onClick={()=>setShow({...show, expandLoyaltyType : !show.expandLoyaltyType})} >
                    <div>
                        {loyaltyTypeName}
                    </div>
                    <div>
                        <SvgIcons.DropDownTriangleIcon color={ColorSchemeCode.Heading} />
                    </div> 
                </div>
                {console.log('formik ', formik.values.brandType)}
                <div className={`servicesOuterBox z-index-10 w-100 position-absolute expandable cp ${show.expandLoyaltyType && 'expand'}`}>
                  {
                    console.log('serviceName',serviceName)
                  }
                    {serviceName.map((value,index)=> 
                        <div className={`singleLoyaltyType cp ${value.disable && 'disabled'} ${!value.disable && value.value == formik.values.brandType && 'activeCheckout'}`} onClick={(e) => selectServiceType(e, value)}>
                            <div className="Heading16M color-neutral100">
                                {value.serviceName}
                            </div>
                            <div className="color-neutral60 Body14R mt_8">
                                {value.detail}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {formik.values.brandType == Brand.BrandTypes.SHARED_LOYALITY && <FormControl className='mt_16' error = {formik.touched.loyaltyConnectionBrand && formik.errors.loyaltyConnectionBrand}>
                <div className="Heading20M color-neutral100">
                    Would you like to connect with Loyalty/ Reward Program?
                </div>

                <div className='mt_16'>

                </div>
                <RadioGroup
                    name     = "loyaltyConnectionBrand"
                    value    = {formik.values.loyaltyConnectionBrand}
                    onChange = {formik.handleChange}
                    onFocus  = {formik.handleBlur}
                    className= {'d-flex flex-row' }
                >
                    <div>
                        <CustomRadio 
                            value = {'true'}
                            label = "Yes"
                        />
                    </div>
                    <div className="ml_32">
                        <CustomRadio 
                            value={'false'}
                            label="No"
                        />
                    </div>
                </RadioGroup>
                <FormHelperText>
                    {formik.touched.loyaltyConnectionBrand && formik.errors.loyaltyConnectionBrand ? formik.errors.loyaltyConnectionBrand : ''}
                </FormHelperText>
            </FormControl>}
          
           <div className="categoryBox mt_8 w-100">
                <CustomSelect 
                    label      = {'Category*'}
                    options    = {VenueType}
                    name       = "businessService"
                    value      = {formik.values.businessService}
                    onChange   = {(e)=>formik.setValues({...formik.values, businessService : e.target.value})}
                    error      = {formik.touched.businessService && formik.errors.businessService}
                    helperText = {formik.errors.businessService && formik.errors.businessService}
                />
          </div> */}

          {/* <div className="d-flex space-between w-100 " style={{marginTop:"0px"}}>
            <div className="w-30 categoryBox ">
              <CustomTextField
                  label       = "Country Code*"
                  type        = "text"
                  name        = "dialingCode"
                  placeholder = ""
                  // style={{lineHeight:"0px"}}
                  className   = "w-100 code-onboard"
                  // padding     = "14px 16px"
                  onChange    = {formik.handleChange}
                  value       = {formik.values.dialingCode}
                  // error       = {formik.touched.dialingCode && formik.errors.dialingCode}
                  // helperText  = {
                  //   formik.touched.dialingCode && formik.errors.dialingCode
                  //     ? formik.errors.dialingCode
                  //     : ""
                  // }

                  // inputProps={{ onFocus: formik.handleBlur }}
                  error={formik.touched.dialingCode && formik.errors.dialingCode}
                  helperText={formik.touched.dialingCode && formik.errors.dialingCode ? formik.errors.dialingCode : ''}
                />
            </div>

{console.log('formik.values',formik.values)}

            <div className="w-65">
              <CustomTextField
                label       = "Phone*"
                type        = "number"
                name        = "phone" 
                placeholder = "Phone"
                className   = "w-100"
                onChange    = {formik.handleChange}
                padding     = "24px 16px"
                value       = {formik.values.phone}
                error       = {formik.touched.phone && formik.errors.phone}
                helperText  = {
                  formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone
                    : ""
                }
              />
            </div>
          </div> */}
          {/* <div className="description w-100 " style={{marginTop:"12px"}}>
              <CustomTextArea 
                  label        = {'Description'}
                  name         = 'description'
                  placeholder  = "Tell us about your brand?"
                  className    = "w-100"
                  onChange     = {formik.handleChange}
                  error        = {formik.touched.description && formik.errors.description}
                  helperText   = {formik.touched.description && formik.errors.description ? formik.errors.description : ''}
                  defaultValue = {formik.values.description}
                  onBlur       = {formik.handleChange}
              />
          </div> */}
          </div>
         </form>
      </div>
    )
  }
