import { Checkbox, CircularProgress } from '@mui/material';
import localforage from 'localforage';
import React, {useContext, useEffect, useState} from 'react';

import {useParams} from 'react-router-dom'
import CustomButton from '../../components/CustomButton';
import CustomCheckBox from '../../components/CustomCheckBox';
import CustomsModal from '../../components/CustomModal';
import CustomTextField from '../../components/CustomTextField';
import Loader from '../../components/Loader';
import { BrandContext } from '../../context/Brand.context';
import { Transactions } from '../../enums';
import { SvgIcons } from '../../icons';
import { BrandService, DealService, FlowService, PassService, RedeemingWayService, TransactionService, UserService } from '../../services';
import {useLocation} from 'react-router-dom'
import { showToaster } from '../../utils/utils';

export default function BusinessCheckout(props) {

    // const params = useParams();
    const brand  = useContext(BrandContext);
    const location = useLocation();
    const QueryParams = new URLSearchParams(location.search);
    const params = { 
        condition      : QueryParams.get('condition'),
        conditionValue : QueryParams.get('conditionValue'),
        dealid         : QueryParams.get('dealId'),
        flowId         : QueryParams.get('flowId'),
        subtract       : QueryParams.get('subtract'),
        passcode       : QueryParams.get('passCode'),
        brandId        : QueryParams.get('brandId'),
        dealUniqueId   : QueryParams.get('dealUniqueId')
    }

    if(QueryParams.get('condition')==='event'){
        params.startdate = QueryParams.get('startdate')
        params.enddate   = QueryParams.get('enddate')
    }
    const [state, setState] = useState({
        passData      : '',
        brandData     : '',
        dealData      : '',
        userId        : '',
        spendingAmount: '',
        loader        : false,
        dealid        : params.dealid ? params.dealid: undefined,
        apiLoader     : false,
        apiError      : '',
        condition     : params.condition,
        conditionValue: params.conditionValue,
        userData      : '',
        remember      : '',
        logined       : false
    })

    const [show, setShow] = useState({
        confirmationModal : false
    })
    
    const [error, setError] = useState(false)
    const [tags, setTags] = useState()

    const [type, settype] = useState('password')

    const onLoad = async() =>{
        setState({...state, loader : true})
        // setState({...state, loader : true});
        const userId = await localforage.getItem('userId')
        const brand = await getBrand();
        const pass  = await getPass({apiKey: brand.apiKey, brandId: brand._id});
        let deal  = await getDeal();
        const user  = await getUser(pass.userId);
        const flow  = await getFlow();
        let payload = {brandId: brand._id, passId: pass._id, flowIds: [params.flowId]}
        // if(params.dealid && !params.flowId) payload.cloudwaitress = true
        // if(params.flowId) payload.flowIds = [params.flowId]
        const flows = await TransactionService.GetFlows({payload});
        // console.log('flows', flows);
        if(params.dealid&&flows&&flows.response){
            for (const value of flows.response.data) {
                if(params.condition==='event'){
                    if((deal.name.toLowerCase() === value.deal.toLowerCase()) && (value.condition === params.condition) && (value.conditionValue.event === params.conditionValue) && (value.conditionValue.date === parseInt(params.startdate)) && (value.conditionValue.enddate === parseInt(params.enddate)) && (value.subtract === parseInt(params.subtract)) && (value.valid===true)){
                        // console.log('inn');
                        if(value.dealeligible&&value.dealredeem&&value.dealexpire) setTags({dealeligible:value.dealeligible,dealredeem:value.dealredeem,dealexpire:value.dealexpire})
                        setError(false); 
                        break;
                    }
                    else setError(true)
                }
                else{
                    // if(deal.name.toLowerCase() === value.deal.toLowerCase() && value.condition === params.condition && (params.conditionValue === null ?  value.conditionValue === params.conditionValue : value.conditionValue === parseInt(params.conditionValue)) && value.subtract === parseInt(params.subtract) && value.valid===true){
                    if(deal.name.toLowerCase() === value.deal.toLowerCase() && value.condition === params.condition && (params.conditionValue === null || value.conditionValue === null || params.conditionValue === 'null' || value.conditionValue === 'null' ? 'a'==='a' : value.conditionValue === parseInt(params.conditionValue)) && value.subtract === parseInt(params.subtract) && value.valid===true){
                        if(value.dealeligible&&value.dealredeem&&value.dealexpire) setTags({dealeligible:value.dealeligible,dealredeem:value.dealredeem,dealexpire:value.dealexpire})
                        setError(false); 
                        break;
                    }
                    else setError(true)
                }
            }
        }
        if(params.dealUniqueId &&  !deal){
            for (const value of flows.response.data) {
                // console.log('value.uniqueId', value.uniqueId)
                if(value.uniqueId === params.dealUniqueId && value.valid){
                    setState({...state, dealData: value});
                    deal = value;
                }
            }
        }

        if(userId) {
            setState({...state ,userId: userId, remember: true, logined: true, passData : pass , brandData: brand, dealData : deal , userData : user, flow: flow})
        } 
        else
        {
            setState({...state , passData : pass , brandData: brand, dealData : deal , userData : user, flow: flow})
        }
    }

    useEffect(onLoad,[])

    const  getDeal = async() =>{
        const query = {_id: params.dealid}
        const {response, error} = await DealService.Get({query});
        if(error) {
            const redeemWay = await RedeemingWayService.GetQueriedEarningWays({query : {_id: params.dealid}})
            if(redeemWay.response) {
                setState({...state, dealData : redeemWay.response.data[0]});
                return  redeemWay.response.data[0]
            }
            else return;
        }
        else{
            setState({...state, dealData : response.data[0]})
            return  response.data[0]
        }

    }

    const getFlow = async () => {
        const {response, error} = await FlowService.Get({query: {_id: params.flowId}})
        if(response) {
            return response.data[0]
        }
    
    }

    const getBrand = async () => {
        const {response, error} = await BrandService.GetPublic({query: {_id: params.brandId}})
        if(response) {
            return response.data
        }
    
    }
 
    const getPass = async ({apiKey, brandId}) => {
        const query = {passCode : params.passcode, brandId: brandId, delete: false}
        const headers = {'x-api-key': apiKey}
        const {error, response} = await PassService.GetBusinessCheckoutPass({query, headers})
        if(error) return
        else{
            return  response.data[0]
        }
    }


    const getUser = async(userId) =>{
        const query = {_id : userId}
        const {error, response} = await UserService.GetByEmail({query})
        if(response){
            return response.data
        }
    }

    const eyeClick = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
          x.type = "text";
          settype('password')
        } else {
          x.type = "password";
          settype('text')
        }
    }

    const handleChange = async (e, name) => {
        if(name == "spendingAmount"){
            if(e.target.value > 0) setState({...state, [name] : e.target.value});
            else setState({...state, [name] : ''});
        }
        else{
            setState({...state, [name] : e.target.value})
        }
    }



    const handleSubmitFunc = async (e)  =>{
        e.preventDefault();
        setState({...state, apiLoader : true, apiError : ''})
        // setShow({...show, confirmationModal : true})

        if(error) return showToaster({position: 'bottom-right', title: 'Title Error', message:  'Invalid or Already availed deal' , severity: 'error'})

        if(state.remember && state.userId)
        {
            localforage.setItem('userId', state.userId)
        } 

        const payload = {
            passCode      : params.passcode,
            idKey         : state.userId,
            discountType  : 1,
            discountValue : 0,
            flowId        : [params.flowId],
        }
        // console.log('state.dealData', state.dealData);
        if(params.dealid || params.dealUniqueId){
            payload.transactionType = Transactions.TransactionTypes.GIVEAWAY
            payload.availDealOffer  = true
            payload.pointUsed       = state.dealData.subtract
            payload.amount          = state.spendingAmount?parseInt(state.spendingAmount):''
            payload.dealOffer       = {
                _id            : state.dealData.id,
                name           : state.dealData.deal,
                image          : state.dealData.image,
                description    : state.dealData.description,
                condition      : params.condition || state.dealData.condition || undefined,
                conditionValue : params.conditionValue,
                date           : params.startdate,
                enddate        : params.enddate
            }
            if(tags&&tags.dealeligible&&tags.dealredeem&&tags.dealexpire){
                payload.dealOffer.dealeligible = tags.dealeligible
                payload.dealOffer.dealredeem   = tags.dealredeem
                payload.dealOffer.dealexpire   = tags.dealexpire
            }
        }
        else {
            payload.amount          = parseInt(state.spendingAmount)
            payload.transactionType = Transactions.TransactionTypes.AMOUNT
        }


        // console.log('payload', payload);

        const transaction = await TransactionService.BusinessCheckout({payload, toaster: true})
        if(transaction.response) {
            if(state.remember && state.userId) setState({...state, apiError : '', apiLoader: false , spendingAmount : '', userId : state.userId, logined: true, remember: true})
            else setState({...state, apiError : '', apiLoader: false , spendingAmount : '', userId : ''})
            localforage.removeItem('deals');
            localforage.removeItem('email');
        }
        else {
            setState({...state, apiError : transaction.error.error, apiLoader: false})
        }
        // onLoad()
        // setShow({...show, confirmationModal : false} )
    }

  return (
      <div>
        {console.log('deal', state.dealData, error)}
        {!state.loader && state.brandData != "" ? 
        <div id="BusinessComponent" >
            { params.dealUniqueId && !state.dealData ?     
                <div className="ml_16 middle Heading16M color-neutral100 capitalize">
                    This deal is not valid now
                </div>
            :
                <>
                    <div className="brandNameTitle d-flex">
                        <div >
                            <img className="brandLogo" src={state.brandData.brandLogo} height="32" width="32" alt="" />
                        </div>
                        <div className="ml_16 middle Heading16M color-neutral100 capitalize">
                            {state.brandData.brandName}
                        </div>
                    </div> 

                    <form className="row">
                        <div className={(state.userId && state.remember && state.logined) ? 'd-none' : "col-md-12"}>
                            <CustomTextField 
                                label     = "Enter User Id"
                                className = "inputTextField col-12"
                                type      = "password"
                                name      = "password"
                                id        = "password"
                                value     = {state.userId}
                                onChange  = {(e) => {handleChange(e,'userId')}}
                                //  error        = {formik.touched.password && formik.errors.password}
                                //  helperText   = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                                icon         = {<div className={"cp"} onClick={eyeClick}>{type !== 'password' ? <SvgIcons.EyeIconPassword/> : <SvgIcons.CrossEyeIcon/>}</div>}
                                position     = "end"
                                required     = {true}
                            
                            />
                        </div>

                        <div className={(state.userId && state.remember && state.logined) ? 'd-none' : "col-md-12"}>
                            <CustomCheckBox 
                                    value={state.remember} 
                                    onChange={()=>{
                                        setState({...state, remember : !state.remember})
                                    }
                                } className="fs-14 color-neutral100" label={"Remember Me"} />
                        </div>
                        {console.log('params', params)}
                        {!params.dealid && !params.dealUniqueId ? <div className="col-md-12 mt_40">
                            <div className="Body14R color-neutral60 mb_16">
                                Enter amount how much amount customer is spending:
                            </div>
                            <CustomTextField 
                                label     = "Enter Customer Spending"
                                className = "inputTextField col-12"
                                value     = {state.spendingAmount}
                                onChange  = {(e) => {handleChange(e,'spendingAmount')}}
                                // error        = {state.apiError}
                                //  helperText   = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                                icon         = {<div>$</div>}
                                position     = "start"
                                required     = {true}
                                type         = "number"

                            />
                            {/* {state.apiError != "" && <div className="error">{state.apiError}</div>} */}
                        </div> 
                        :  
                        <div className="w-100">
                            <div className="mt_40 col-md-12 d-flex">
                                <div className="w-60">
                                        <div className="">
                                            <div className="capitalize Heading16M color-neutral100" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace:'nowrap'}}>{state.dealData.name}</div>
                                            <div className="Caption12M color-neutral60 verticalEllipses mt_4">{state.dealData.description}</div>
                                        </div>
                                </div>
                                <div className="w-40 text-right pt_16">
                                    <img className="oject-fit-cover" height="102" width="102" alt="" src={state.dealData.image}/>
                                </div>
                            </div>
                            <div className="col-md-12" style={{marginTop :'-16px'}}>
                                {params.condition === 'visit'?
                                    <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                        <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{params.conditionValue + ' ' + params.condition} </div>
                                    </div>
                                    
                                    :
                                    !params.dealUniqueId  ? 
                                    <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                        <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{params.conditionValue + ' ' + params.condition}</div>
                                    </div>

                                    : 
                                    <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                        {
                                            state.dealData && state.dealData.condition && state.dealData.condition.length > 0  && state.dealData.condition.map((element, idx)=>(
                                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>
                                                    {element.condition + ' ' + element.conditionValue[0] + ' ' + element.conditionValue[1]}
                                                </div>
                                            ))
                                        }
                                    </div>

                                }
                            </div>


                            {/* {state&&state.flow&&state.flow.triggerOption&&state.flow.triggerOption==="Event Base"?<></>: */}
                            {<div className="col-md-12 mt_40">
                            <div className="Body14R color-neutral60 mb_16">
                                Enter amount how much amount customer is spending:
                            </div>
                                <CustomTextField 
                                    label     = "Enter Customer Spending"
                                    className = "inputTextField col-12"
                                    value     = {state.spendingAmount}
                                    onChange  = {(e) => {handleChange(e,'spendingAmount')}}
                                    // error        = {state.apiError}
                                    //  helperText   = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                                    icon         = {<div>$</div>}
                                    position     = "start"
                                    required     = {true}
                                    type         = "number"

                                />
                                {/* {state.apiError != "" && <div className="error">{state.apiError}</div>} */}
                            </div> }
                        </div>
                        }
                        {error&&<div className="error">This deal is not valid.</div>}
                        <div className="customButton col-md-12">
                            <CustomButton 
                                className = "w-100 borderRadius-0 position-fixed l-0 b-0"
                                btntext   = "Continue"
                                height    = "54px"
                                // type      = "submit"
                                disabled  = {error || 
                                    state.userId.length != 6 || ((!state.dealData)  && state.spendingAmount != 0 || state.spendingAmount != "") && false }
                                onClick   = {()=>setShow({...show, confirmationModal : true})}
                            />
                        </div>

                        <CustomsModal 
                            component = {<ConfirmationComponent 
                                            onClose          = {()=>setShow({...show, confirmationModal : false})}
                                            state            = {state}
                                            params           = {params}
                                            handleSubmitFunc = {handleSubmitFunc}
                            />}
                            open      = {show.confirmationModal}
                            onClose   = {()=>setShow({...show, confirmationModal : false})}
                            minWidth  = {'100%'}
                        />
                    </form>
                </>
            }
        </div> :  <Loader height="32" width="32" />}
      </div>
  )
}

const ConfirmationComponent = ({state, onClose, handleSubmitFunc, params}) => {


    return(
        <div className="confirmationComponent">
            <div className="Heading18M color-neutral100 text-center mb_16">
                Confirm Transaction
            </div>
            <div className="d-flex pl_8 pr_8 pt_8 pb_8 Body14R color-neutral100 d-flex space-between">
                <div>
                    Customer Name
                </div>
                <div className="capitalize">
                    {`${state.userData.firstName} ${state.userData.lastName}` }
                </div>
            </div>
            {!state.dealData ? <div className="mt_12 d-flex pl_8 pr_8 pt_8 pb_8 Body14R color-neutral100 d-flex space-between">
                <div>
                    Spending 
                </div>
                <div>
                    $ {state.spendingAmount}
                </div>
            </div> 

            :

            <div>
                <div className="mt_40 d-flex pl_8 pr_8 pt_8 pb_8 ">
                    <div className="w-60">
                            <div className="">
                                <div className="capitalize Heading16M color-neutral100" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace:'nowrap'}}>{state.dealData.name}</div>
                                <div className="Caption12M color-neutral60 verticalEllipses mt_4">{state.dealData.description}</div>
                            </div>
                    </div>
                    <div className="w-40 text-right pt_16">
                        <img className="oject-fit-cover" height="102" width="102" alt="" src={state.dealData.image}/>
                    </div>
                </div>
                <div className="" style={{marginTop :'-16px'}}>
                    {state.condition === 'visit'?
                        <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                            <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{state.conditionValue + ' ' + state.condition} </div>
                        </div>
                        
                        :
                        !params.dealUniqueId  ? 
                        <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                            <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{params.conditionValue + ' ' + params.condition}</div>
                        </div>

                        : 
                        <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                            {
                                state.dealData && state.dealData.condition && state.dealData.condition.length > 0  && state.dealData.condition.map((element, idx)=>(
                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>
                                        {element.condition + ' ' + element.conditionValue[0] + ' ' + element.conditionValue[1]}
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>

        }
            <div className="mt_40 d-flex space-between">
                <div className="mr_8" style={{width : '48%'}}>
                    <CustomButton 
                        btntext   = "Cancel"
                        height    = "50px"
                        width     = "100%"
                        className = "w-100"
                        varient   = "secondary"
                        onClick   = {onClose}
                    />
                </div>
                <div style={{width : '48%'}}>
                    <CustomButton 
                        btntext   = "Confirm"
                        height    = "50px"
                        width     = "100%"
                        className = "w-100"
                        onClick   = {handleSubmitFunc}
                        icon      = {state.apiLoader && <CircularProgress size={20} color={'inherit'}/>}
                    />
                </div>

            </div>
        </div>
    )
}
