import React from 'react';
import { makeStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ColorSchemeCode } from '../enums/ColorScheme';

const CustomFormControlLabel = makeStyles({
    root: {
        margin: 0
    },
    label: {
      fontFamily   : 'Inter',
      fontStyle    : 'normal',
      fontWeight   : '400',
      fontSize     : '14px',
      lineHeight   : '22px',
      letterSpacing: '0.1px',
      color        : ColorSchemeCode.neutral100
    },
})


export default function CustomRadio({value, label, styles, checked, className,size}) {
    return(
      <div id="CustomRadio" className="d-flex flex-column">
        <FormControlLabel 
          value   = {value}
          label   = {label}
          control = {<Radio size={size} className='mr_8' style={{padding : '9px !important'}} checked={checked}/>}
        />
        
      </div>
    )
}

