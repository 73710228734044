import axios from 'axios'
import { MainService } from '.'
import { Handlers, utils } from '../utils'
import env from '../config'
const service = '/v4/deal'

const Create = async ({payload, headers}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Get = async({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GetBrandDeals = async ({payload}) => await axios.post(env.API_URL+service+'/getBrandDeals', payload)


const DealService = {
    Create        : Handlers.Services(Create),
    Get           : Handlers.Services(Get),
    Update        : Handlers.Services(Update),
    GetBrandDeals : Handlers.Services(GetBrandDeals),
}

export default DealService
