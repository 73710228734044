import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";

import * as localForage from "localforage";

import { AuthVld } from "../../validation";
import { AuthService, UserService } from "../../services";
import { PermissionService } from "../../services";

import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import { BusinessType } from "../../enums/enums";
import { UserContext } from "../../context/User.context";
import { Brand, User } from "../../enums";
import { AgencyContext } from "../../context/Agency.context";

function ForgetPassword({ setTitle }) {
  // const history     = useHistory()
  // const userContext = useContext(UserContext)
  const agency = useContext(AgencyContext);

  const [state, setState] = React.useState({
    apiError: "",
    loader: false,
  });

  const initState = {
    email: "",
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: AuthVld.ForgetPassword,
    isInitialValid: false,
  });

  async function handleSubmit() {
    setState({ ...state, loader: true });
    const payload = { email: formik.values.email, awsEmail : agency?.awsEmail || "" };
    await AuthService.ForgetPassword({ toaster: true, payload });
    setState({ ...state, loader: false });
  }

  useEffect(() => {
    setTitle("Forgot Password");
  }, []);

  return (
    <>
      <form onSubmit  = {formik.handleSubmit}>
      <div  className = "input">
      <div  className = "mt_24 Heading24M color-Heading text-center w-100">
             Forgot Passowrd
          </div>
          <div className="mt_40 text-left col-12">
            <CustomTextField
              label       = "Email address"
              className   = "inputTextField"
              type        = "text"
              placeholder = "Email"
              name        = "email"
              value       = {formik.values.email}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
              error       = {formik.touched.email && formik.errors.email}
              helperText  = {
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
              autoFocus={true}
            />
          </div>

          {/* <div className="mt_40 text-left col-12">
            <CustomTextField 
              label       = "Password"
              className   = "inputTextField col-12"
              type        = "password"
              placeholder = "Password"
              name        = "password"
              value       = {formik.values.password}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
              error       = {formik.touched.password && formik.errors.password}
              helperText  = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
            />
          </div> */}

          <div className="col-12">
            <CustomButton
              varient   = "primary"
              className = "fs-14 mt_32 w-100 "//hpx-52
              btntext   = "Submit"
              type      = "submit"
              disabled  = {state.loader || !formik.isValid}
              icon      = {
                state.loader && <CircularProgress size={20} color={"inherit"} />
              }
            />
          </div>
          {state.apiError && <div className={"error"}>{state.apiError}</div>}
        </div>
      </form>
    </>
  );
}

export default ForgetPassword;
