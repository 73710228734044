import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ColorSchemeCode } from '../enums/ColorScheme';

export default function TextFieldSizes(props) {

 
  return (
    <Box
      component="form"
      className='position-relative'
      autoComplete='on'
      sx={{
        '& .MuiTextField-root': {
          m     : 1,
          width : '100%',
          margin: 0,
          padding: 0,
          fontFamily: 'Inter',

        },
        '& .MuiInputLabel-root':{
          fontSize  : '16px',
          fontFamily: 'Inter',
          color     : '#525252',
          lineHeight: '16px'
        },
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: props.helperText ? ColorSchemeCode.danger30 : ColorSchemeCode.primary50,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: props.helperText ? ColorSchemeCode.danger30 : ColorSchemeCode.primary50,
          },
        },
        '& .MuiInputBase-root': {
          fontSize     : (props.fontSize || 16),
          padding: 0,
          fontFamily: 'Inter',
          paddingLeft : (props.paddingLeft && (props.paddingLeft + " !important")),
          paddingRight : (props.paddingRight && (props.paddingRight + " !important"))


        },
      }}
    >
      <TextField
        label        = {props.label}
        id           = {`${props.id}`}
        variant      = "outlined"
        defaultValue = {props.value || props.defaultValue}
        ref          = {props.inputRef}
        multiline={props.multiline}
        rows={props.rows}
        maxRows={props.maxRows || 3}
        name         = {props.name}
        type         = {props.type || "text"}
        disabled     = {props.disabled || props.disabled1 }
        autoComplete = {props.autocomplete ? props.autocomplete : "on"}
        helperText   = {props.helperText}
        error        = {props.error}
        focused      = {props.focused}
        placeholder= {props.placeholder ||""}
        inputProps   = {props.inputProps}
        className    = {`${props.className} ${props.disabled && 'disabled'}`}
        onChange={(e) => {
          if (props.type !== 'number') {
            props.onChange ? props.onChange(e) : props.onBlur(e);
            return;
          }
       
          if(e.target.value == "-") e.target.value = ""
          const value = e.target.value.trim();
          if (/^\d+(\.\d+)?$/.test(value) && parseFloat(value) > 0) {
            props.onChange ? props.onChange(e) : props.onBlur(e);
          }
          else {
            e.target.value = ''; // clear invalid input
            // props.onChange ? props.onChange(e) : props.onBlur(e);
          }
        }}
        onBlur={(e) => {
          if (props.type !== 'number') {
            props.onChange ? props.onChange(e) : props.onBlur(e);
            return;
          }
          if(e.target.value == "-") e.target.value = ""
          const value = e.target.value.trim();
          if (/^\d+(\.\d+)?$/.test(value) && parseFloat(value) > 0) {
            props.onChange ? props.onChange(e) : props.onBlur(e);
          } else {
            e.target.value = ''; // clear invalid input
          }
        }}
        onKeyDown    = {(event)=>{
          
          if (event.key === 'Enter') {
            event.preventDefault();
            props.onSubmit();
          }
        }}
      />
      {props.icon && props.position == "start" && (
        <div
          className={`position-absolute Body14R `}
          style={{ width : props.widthIcon?props.widthIcon : '50px' , left: props.left ? `${props.left}px`: 9,right: props.right ? `${props.right}px`:16, top: props.top ? `${props.top}px` :12}}
        >
          {props.icon}
        </div>
      )}


      {props.icon && props.position == "end" && (
        <div
          className={`position-absolute Body14R`}
          style={{ right: props.left ? `${props.left}px`:16, top: props.top ? `${props.top}px` :14  }}
        >
          {props.icon}
        </div>
      )}
    </Box>
  );   
 
}