import React, {useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import CustomTextField from '../../../components/CustomTextField';
import CustomButton from '../../../components/CustomButton';
import CustomModal from '../../../components/CustomModal';

import ClickOutside from '../../../utils/ClickOutside';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { Campaign, PassDesign, Transactions, Brand } from '../../../enums';
import { TransactionVld } from '../../../validation';
import { useFormik } from 'formik';
import { CampaignService, LocationService, DealService, RedeemingWayService, TierService, TransactionService, EarningWayService } from '../../../services';
import { UserContext } from '../../../context/User.context';
import { BrandContext } from '../../../context/Brand.context';
import { LayoutContext } from '../../../context/Layout.context';
import { PngIcons, SvgIcons } from '../../../icons';
import CoalitionService from '../../../services/Coalition.service';
import Loader from '../../../components/Loader';

import Skeleton from 'react-loading-skeleton';
import localforage from 'localforage';



export default function Redemption(props) {

    const history = useHistory();

    
    const [mainLoader, setMainLoader] = useState(false);
    const [finalData, setFinalData]   = useState([]);
    let   linkCheck                   = props.location && props.location.state ? props.location.state.linkCheck : undefined;
    const brand                       = useContext(BrandContext)
    const pass                        = props.passData || props.location.state.passData;
    // const history = useHistory()
    const user    = useContext(UserContext)


    const [state, setState] = useState({
        loader             : false,
        apiError           : '',
        redeemingWay       : '',
        couponCampaign     : '',
        show               : false,
        currentTier        : '',
        dealOffer          : '',
        availDealOffer     : false,
        updatePoints       : pass[pass.passType].currentPoints,
        stampPoints        : pass[pass.passType].currentStamps,
        remainingCredit    : pass[pass.passType].totalSpending,
        coalitionId        : '',
        currentDisplayPoint: '',
        dealIndex          : '',
        linkCheck          : linkCheck
    })

    const initState = {
        amount          : '',
        location        : '',
        transactionType : Transactions.TransactionTypes.AMOUNT,
        pointUsed       : 0,
        discountValue   : 0,
        discountDeal    : '',
        discountType    : 1,
    }

    const handleSubmit = async () => {
        setState({...state, loader: true, apiError: ''})
        const payload = {
            ...formik.values,
            passId     : pass._id,
            employerId : user._id
        }
        
        if(brand.brandType === Brand.BrandTypes.SUB_BRAND || brand.brandType === Brand.BrandTypes.SHARED_LOYALITY) {payload.coalitionId = state.coalitionId; payload.childBrandId = brand._id};
        if(brand.brandType === Brand.BrandTypes.SUB_BRAND && state.dealOffer) {payload.dealOffer = state.dealOffer; payload.availDealOffer = state.availDealOffer}
        if(brand.brandType === Brand.BrandTypes.NORMAL && state.dealOffer) {payload.dealOffer = state.dealOffer; payload.availDealOffer= true;}
        if(state.couponCampaign.detail && state.couponCampaign.campaignType === Campaign.CampaignTypes.AMOUNT_COUPON && state.couponCampaign.detail.isFixedAmount)
        payload.transactionType = Transactions.TransactionTypes.FIXED_AMOUNT
        
        
        const {error, response} = await TransactionService.Create({toaster: true, payload})

        setState({...state, loader: false})
        if(error) return setState({...state, apiError: error.error})
        
        if(response && formik.values.transactionType === Transactions.TransactionTypes.BONUS) formik.setValues({...formik.values, transactionType: Transactions.TransactionTypes.BONUS, points: ''})

        if(response.data)
        {
            localforage.removeItem('deals');
            props.getNewRedemption && props.getNewRedemption({data : {...response.data}, customerName: response.data.customerName, created: new Date().valueOf()});
            setState({...state, show: true})
            pass[pass.passType] = response.data[response.data.passType].passData;
            setState({...state,updatePoints: response.data[response.data.passType].passData.currentPoints})
        }

        if(response.renew) return setState({...state, show: true})
        
        // window.location.reload();
        // const path = window.location.pathname.split('/')
        // path.pop()
        // history.push(path.join('/')+'/barcodescanner')
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : TransactionVld.Create,
        isInitialValid   : false
    })


    const setAddress = async () => {
        const ipAddress = await LocationService.GetIpAddress()
        if(ipAddress.error) return

        const {error, response} = await LocationService.GetLocation({ipAddress: ipAddress.response.ip})
        if(error) return

        const location = {
            lat     : response.latitude,
            lng     : response.longitude,
            address : response.city + ' ' + response.country_name
        }

        formik.setValues({...formik.values, location})
        return location;
    }

    const getRedeemingWay = async (location) => {
        const query = {campaignId: pass.campaignId, activity: Campaign.Activities.REDEEMING.AMOUNT}
        const {error, response} = await RedeemingWayService.GetQueriedEarningWays({query})
        response && setState({...state, redeemingWay: response.data[0]})
        formik.setValues({...formik.values,location: location})
    }

    const getCampaigns = async (location) => {
        const query = {_id: pass.campaignId}
        const {error, response} = await CampaignService.GetQueriedCampaign({query})
        response && setState({...state, couponCampaign: response.data[0]})
        if(response.data[0].campaignType !== 3)
        formik.setValues({...formik.values,location: location, discountValue: response.data[0].detail.discountValue, discountType: response.data[0].campaignType})
        else
        formik.setValues({...formik.values,location: location, discountDeal: response.data[0].detail.discountDeal, discountType: response.data[0].campaignType})
    }

    const getCoalition = async () => {
        const query = {childBrandId :  brand._id}
        const {error, response} = await CoalitionService.Get({query})
        return response.data[0]
        }

    const getTiers = async () => {
        let coalition, coalitionId;
        const query = {campaignId: pass.campaignId}
        const {error, response} = await TierService.Get({query})
        if(brand.brandType === Brand.BrandTypes.SUB_BRAND || brand.brandType === Brand.BrandTypes.SHARED_LOYALITY ) coalition = await getCoalition()

        if(coalition)  coalitionId = coalition._id
    
        let currentPoints = pass[pass.passType].currentPoints;
        response.data.map(async (element, idx)=> {
            if(element.pointRange.minPoints <= currentPoints && currentPoints <= element.pointRange.maxPoints )
            {
                let data = {...element}, checkDeal;
                coalition && coalition.tiers.length >= 3 && coalition.tiers.map((tier,idx )=>{
                    if(tier.id == element._id) 
                    {
                        data = {...element, benefits: tier.benefits}
                    }
                })

                let query = {brandId : data.brandId, childBrandId: brand._id, passId : pass._id, delete: false}
                const transaction = await TransactionService.GetQueriedTransactions({query})
                transaction.response && transaction.response.data.map((trans, idx)=> {
                    if(trans.transactionType !== Transactions.TransactionTypes.SIGNUP_POINT)
                    {
                        if(trans[pass.passType].benefits.dealOffer) checkDeal = trans[pass.passType].benefits.dealOffer
                    }
                })

                if(transaction.error || !checkDeal)  setState({...state, currentTier: data, coalitionId: coalitionId, dealOffer: brand.dealOffer})
                else setState({...state, currentTier: data, coalitionId: coalitionId})
            }
        })

    }

    const getFlows = async ()=> {
        let payload = {brandId: brand._id, passId: pass._id, triggerType: 'checkIn'}
        const flows = await TransactionService.GetFlows({payload});
        let flowDeals = flows.response.data;
        const query = {brandId: brand._id, delete: false}
        const {response, error} = await DealService.Get({query});
        let dealsData = [];
        const deals = await localforage.getItem('deals');
        response && response.data.map((element, idx)=>{
            flowDeals.map((deal, idx)=>{
                if(element.name === deal.deal)
                {  
                    if(deals && deals.length > 0)
                    {
                        let checkDeal = deals.filter((d, idx)=>{ if(d.name === element.name) return true;})
                        if(!checkDeal || checkDeal.length === 0) dealsData.push({...deal, ...element, avail: false, startDate: new Date(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
                    }
                    else
                    {
                        dealsData.push({...deal, ...element, avail: false, startDate: new Date().valueOf(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
                    }
                }
            })
        })

        
        let dataSorting;
        if(deals && deals.length ) dataSorting = [...deals,...dealsData];
        else dataSorting = [...dealsData];
        
        dataSorting.sort(function(a, b) {
            var keyA = a.conditionValue; var keyB = b.conditionValue;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });       

        setFinalData([...dataSorting])
        localforage.setItem('deals', [...dataSorting]);
    }

    const onLoad = async () => {
        setMainLoader(true);
        localforage.removeItem('deals');
        let deals = await localforage.getItem('deals');
        setState({...state, currentDisplayPoint: '', apiError : ''})
        if(!pass) return history.push('/')
        const location = await setAddress()
        if(pass.passType === PassDesign.PassTypes.LOYALTY_CARD) 
        {
            if(brand.brandType === Brand.BrandTypes.NORMAL) await getRedeemingWay(location)
            await getTiers()
        }
        if(brand.brandType === Brand.BrandTypes.NORMAL) await getFlows();
        if(pass.passType === PassDesign.PassTypes.COUPON) await getCampaigns(location)
        setMainLoader(false);
    }

    useEffect(onLoad, [state.updatePoints])


    const layout = useContext(LayoutContext)
    useEffect(()=>{
        layout.setLayout({
            title     : "Barcode Scanner",
            button    : "",
            backTitles: [],
            switch      : "",
        });
    },[mainLoader])

    return (
        
        <div id="Redemption" className={`${((state.value?.dealOffer && JSON.stringify(state.value.dealOffer) === JSON.stringify({}) || (finalData && finalData.length <= 0))) && 'fullWidth'} middle`}>
            {
                !mainLoader ?
                <form onSubmit={formik.handleSubmit}>
                    <div className="redemptionCard middle">
                        <div className={`${pass.passType} cardDisplay text-left`}>
                            <div className="d-flex space-between">
                                <div className="color-white fs-14 fw-5 text-capitalize">{pass.passType}</div>
                                <div className="color-white fs-10 pl_8 middle pr_8 fw-5 text-capitalize borderRadius-32 bg-color-cBBC3E1">{state.currentTier.name}</div>
                            </div>
                            <div className="color-white pointBox">
                                <div className="fs-32 fw-6">
                                    {state.currentDisplayPoint || state.updatePoints}
                                    {state.stampPoints}
                                    {(state.couponCampaign && state.couponCampaign.campaignType === Campaign.CampaignTypes.AMOUNT_COUPON) ? 
                                            brand.currency && brand.currency.symbol + ' ' + state.couponCampaign.detail.discountValue 
                                     : (state.couponCampaign && state.couponCampaign.campaignType === Campaign.CampaignTypes.PERCENT_COUPON) 
                                            ? state.couponCampaign.detail.discountValue + '%'  : ''}

                                    {(state.couponCampaign && state.couponCampaign.campaignType === Campaign.CampaignTypes.GIVEAWAY_COUPON) ?
                                            state.couponCampaign.detail.discountDeal    
                                     :''}

                                    {(state.couponCampaign && state.couponCampaign.campaignType === Campaign.CampaignTypes.GIFTCARD_COUPON) ?
                                            brand.currency && brand.currency.symbol + ' ' + state.remainingCredit 
                                     :''}

                                     
                                </div>
                                <div className="fs-24 ml_8">{pass.passType === PassDesign.PassTypes.LOYALTY_CARD && 'POINTS'}</div>
                                <div className="fs-24 ml_8">{pass.passType === PassDesign.PassTypes.STAMP_CARD && 'STAMPS'}</div>
                                <div className="fs-24 ml_8">{pass.passType === PassDesign.PassTypes.TICKET && 'PEOPLE ALLOWED'}</div>
                                <div className="fs-24 ml_8">{pass.passType === PassDesign.PassTypes.COUPON ? (state.couponCampaign.detail && !state.couponCampaign.detail.isFixedAmount) ? (state.couponCampaign<=4) ? 'OFF' : 'Gift' : 'Fixed Amount' : ''}</div>
                            </div>
                            <div className="color-white fs-14 fw-5">{pass.user.firstName + ' ' + pass.user.lastName}</div>
                        </div>
                        <div className="mt_48 w-100 position-relative">
                            <CustomTextField 
                                type             = "number"
                                label            = "Please Enter Full Amount"
                                borderRadius     = {'4px'}
                                placeholder      = {'Enter Amount'}
                                className        = {'w-100'}
                                // paddingLeft      = {'55px'}
                                height           = {'62px'}
                                icon             = {<div>{brand.currency && brand.currency.symbol}</div>}
                                position         = "start"
                                placeholderColor = {ColorSchemeCode.textFieldActiveColor}
                                name             = "amount"
                                value            = {formik.values.amount}
                                onChange         = {formik.handleChange}
                                inputProps       = {{ onBlur: formik.handleBlur }}
                                error            = {formik.touched.amount && formik.errors.amount}
                                helperText       = {formik.touched.amount && formik.errors.amount && formik.errors.amount}
                            />  
                            {/* <div className="dollarIcon color-textFieldActiveColor">{brand.currency && brand.currency.symbol}</div> */}
                        </div>
                        
                        {pass.passType === PassDesign.PassTypes.LOYALTY_CARD  && 
                            <LoyaltyCard 
                                formik       = {formik}
                                state        = {{value: {...state}, setState}}
                                pass         = {pass}
                                setState     = {state}
                                finalData    = {finalData}
                                setFinalData = {setFinalData}
                            />
                        }

                        {pass.passType === PassDesign.PassTypes.STAMP_CARD  && 
                            <StampCard 
                                formik = {formik}
                                state  = {{value: {...state}, setState}}
                                pass   = {pass}
                            />
                        }

                        {pass.passType === PassDesign.PassTypes.COUPON  &&  state.couponCampaign.campaignType <=2 &&
                            <CouponCard 
                                formik = {formik}
                                campaign = {state.couponCampaign}
                                state  = {{value: {...state}, setState}}
                                pass   = {pass}
                            />
                        }
                        
                        {state.show &&    
                            <CustomModal    
                                component = {<CongratulationScreen onClose={()=>setState({...state, show : false})}/>}
                                    open      = {state.show}
                                    minWidth  = {'500px'}
                                    onClose   = {()=>setState({...state, show: false})}
                                />
                        }

                        <div className="bottomButton w-100 mt_8">
                            <CustomButton 
                                // varient         = "secondary"
                                color           = {ColorSchemeCode.white}
                                backgroundColor = {ColorSchemeCode.c000000}
                                hover           = {ColorSchemeCode.c000000}
                                focus           = {ColorSchemeCode.c000000}
                                className       = {'w-100'}
                                borderRadius    = {'4px'}
                                height          = {'56px'}
                                btntext         = {'Redeem'}
                                type            = "submit"
                                disabled        = {state.loader || !formik.isValid || formik.values.transactionType === Transactions.TransactionTypes.BONUS}
                                icon            = {state.loader && formik.values.transactionType !== Transactions.TransactionTypes.BONUS && <CircularProgress size={20} color={'inherit'}/>}
                            />
                        </div>
                        <div className="error">{state.apiError}</div>
                    </div>
                </form>
                :   
                <>
                     <div className="redemptionCard middle">
                        <div className="Heading22R color-selectButtonColor">
                          <Skeleton height={40} width={240}/>
                        </div> 
                        <div className='mt_16'>
                        <Skeleton width={336} height={180}/>
                        </div>
                        <div className="mt_38 w-100 position-relative">
                             <Skeleton height={50}/>
                        </div>
                        <div className="bottomButton w-100 mt_60">
                            <Skeleton height={25}/>
                        </div>
                        <div className="bottomButton w-100 mt_20">
                            <Skeleton height={25}/>
                        </div>
                        <div className="bottomButton w-100 mt_80">
                            <Skeleton height={40}/>
                        </div>
                    </div>
                </>
            }                 
        </div>
    )
}





const CouponCard = ({state, formik, pass, campaign}) => {

    const brand = useContext(BrandContext)

    return (
        <div className="w-100 mt_8 mb_8">
            
            <div className="d-flex mt_10 align-items-center">
                <div className="pt_10 color-textFieldActiveColor">Dicount Amount</div>
                <div className="ml_auto w-fit-content color-textFieldActiveColor">{formik.values.discountValue ? brand.currency && brand.currency.symbol + ' ' + formik.values.discountValue : '-'}</div>
            </div>
            <div className="d-flex mt_10 align-items-center">
                <div className="pt_10 color-textFieldActiveColor">Payable Amount</div>
                <div className="ml_auto w-fit-content color-textFieldActiveColor">{
                    (campaign.campaignType == Campaign.CampaignTypes.PERCENT_COUPON) ? brand.currency && brand.currency.symbol + ' ' +  (formik.values.amount - (formik.values.amount * (formik.values.discountValue/100)))
                            :  (campaign.campaignType  == Campaign.CampaignTypes.AMOUNT_COUPON && !campaign.detail.isFixedAmount) ? formik.values.amount - formik.values.discountValue : formik.values.amount}
                </div>
            </div>
            
        
        </div>
    )
}



const StampCard = ({state, formik, pass}) => {

    const brand = useContext(BrandContext)

    return (
        <div className="w-100 mt_8 mb_8">
             
        </div>
    )
}




const LoyaltyCard = ({state, setState, formik, setFinalData, pass, finalData}) => {
    const brand = useContext(BrandContext);

    const [show, setShow] = useState({
        modal    : false,
        discount : false
    })

    const handleModalOpen = () => {
        formik.setValues({...formik.values, transactionType: Transactions.TransactionTypes.BONUS})
        setShow({...show, modal: true})
    }

    const handleModalClose = () => {
        formik.setValues({...formik.values, transactionType: Transactions.TransactionTypes.AMOUNT})
        setShow({...show, modal: false})
    }

    const getIncreamentalDiscount = (pointUsed) => {
        const pointCost     = state.value.redeemingWay.cost
        const discountValue = state.value.redeemingWay.redeemingValue
        const perPointDiscount = discountValue / pointCost

        return perPointDiscount * pointUsed
    }

    const handleDiscountPointChange = (e) => {

        if(formik.values.amount < state.value.redeemingWay.minPurchaseAmount || pass[pass.passType].currentPoints < formik.values.pointUsed) return

        let discountValue = 0

        if(state.value.redeemingWay.redeemingType === Campaign.RedeemingTypes.INCREMENTAL) discountValue = getIncreamentalDiscount(formik.values.pointUsed)

        if(discountValue > formik.values.amount) return

        formik.setValues({...formik.values, pointUsed: formik.values.pointUsed, discountValue})
        state.setState({...state.value, currentDisplayPoint: pass[pass.passType].currentPoints - formik.values.pointUsed})
    }

    const showGiveAway = () => {
        setShow({...show, discount: true})
        formik.setValues({...formik.values, transactionType: Transactions.TransactionTypes.GIVEAWAY})
    }

    const closeGiveAway = () => {
        setShow({...show, discount: false})
        formik.setValues({...formik.values, pointUsed: 0, discountValue: 0, transactionType: Transactions.TransactionTypes.AMOUNT})
    }
  


    return (

        <div className="d-flex w-100">
            <div className="w-100 mt_8 mb_8">
                <div className="d-flex align-items-center">
                    <div className="mt_24 color-textFieldActiveColor">Spend Points</div>
                    {!show.discount && 
                        <div 
                            onClick={showGiveAway} 
                            className={`mt_24 cp fs-13 fw-6 ml_auto ${formik.values.amount < state.value.redeemingWay.minPurchaseAmount && 'disabled'} color-themeColor`}>
                                Add Discount Points
                        </div>
                    }
                    {show.discount && <div className="ml_auto d-flex align-items-center w-30">
                        <CustomTextField 
                            type             = "number"
                            borderRadius     = {'4px'}
                            placeholder      = {'e.g 10'}
                            className        = {`w-65 ${formik.values.amount < state.value.redeemingWay.minPurchaseAmount && 'disabled'}`}
                            height           = {'40px'}
                            placeholderColor = {ColorSchemeCode.textFieldActiveColor}
                            name             = "pointUsed"
                            value            = {formik.values.pointUsed}
                            onChange         = {formik.handleChange}
                            inputProps       = {{ onBlur: formik.handleBlur }}
                            error            = {formik.touched.pointUsed && formik.errors.pointUsed}
                            helperText       = {formik.touched.pointUsed && formik.errors.pointUsed && formik.errors.pointUsed}
                        /> 
                        
                        &nbsp; <div onClick={closeGiveAway} className="close-btn middle">x</div>&nbsp;&nbsp;
                        <div onClick={handleDiscountPointChange} className="close-btn middle">✓</div>
                    </div>}
                </div>
                <div className="d-flex w-100">
                    <div className="mt_24 color-textFieldActiveColor">
                        Bonus Points
                    </div>
                    <div 
                        onClick   = {handleModalOpen}
                        className = {`mt_24 cp fs-13 fw-6 ml_auto color-themeColor`}>
                            Add Bonus Points
                    </div>
                </div>
                {formik.values.amount < state.value.redeemingWay.minPurchaseAmount && 
                    <div className="fs-12">
                        Your amount is {brand.currency && brand.currency.symbol + ' ' + (state.value.redeemingWay.minPurchaseAmount - formik.values.amount)} less to avail discount
                    </div>
                }

                {show.discount && <div className="d-flex mt_10 align-items-center">
                    <div className="pt_10 color-textFieldActiveColor">Dicount Amount</div>
                    <div className="ml_auto w-fit-content color-textFieldActiveColor">{formik.values.discountValue ? brand.currency && brand.currency.symbol + ' ' + formik.values.discountValue : '-'}</div>
                </div>}

                <div className="d-flex mt_24 align-items-center">
                    <div className="color-textFieldActiveColor">Payable Amount</div>
                    { state.value.availDealOffer ? 
                        <div className="ml_auto w-fit-content color-textFieldActiveColor">
                            {brand.currency && brand.currency.symbol + ' ' +  formik.values.amount}
                        </div> 
                    :
                        <div className="ml_auto w-fit-content color-textFieldActiveColor">
                            {brand.currency && brand.currency.symbol + ' ' +  
                            state && state.value.currentTier && state.value.currentTier.benefits.discount.discountValue !== null ? 
                            ((formik.values.amount - (formik.values.amount/100 * state.value.currentTier.benefits.discount.discountValue)) - formik.values.discountValue) 
                            : 
                            state && state.value.currentTier && state.value.currentTier.benefits.discount.customDiscounts !== null ? 
                            ((formik.values.amount - (formik.values.amount/100 * state.value.currentTier.benefits.discount.customDiscounts[new Date().getDay()])) - formik.values.discountValue) 
                            :
                            (formik.values.amount - formik.values.discountValue)
                            }
                        </div>
                    }
                </div>
                {show.modal &&    
                    <CustomModal    
                        component = {<AddPointsModal state={state} formik={formik} closeModal={handleModalClose}/>}
                        open      = {show.modal}
                        title     = "Add Bonus Point"
                        onClose   = {handleModalClose}
                        minWidth  = {'500px'}
                    />
                }
            </div>
            {!state.value.linkCheck ?  <>
            {(state.value.dealOffer && JSON.stringify(state.value.dealOffer) !== JSON.stringify({}) || (finalData && finalData.length > 0)) && <div className="dealListLeftSide">
                <div className="Heading16M color-neutral100">
                    Qualifying Deals
                </div>
                {state.value.dealOffer && JSON.stringify(state.value.dealOffer) !== JSON.stringify({}) && <AvailComponent formik={formik} state={state.value} setState={state.setState}/>}
                {finalData && finalData.length > 0 && 
                    <AvailComponent formik={formik} state={state.value} finalData={finalData} setFinalData={setFinalData} setState={state.setState}/>}
                {/* <div className="mr_5 mt_48 w-100">
                    <CustomButton 
                        varient         = "secondary"
                        className       = {'w-100'}
                        btntext         = {'Add Bonus Point'}
                        // color           = {ColorSchemeCode.c000000}
                        // backgroundColor = {ColorSchemeCode.white}
                        // hover           = {ColorSchemeCode.widgetNavBackgroundColor}
                        // focus           = {ColorSchemeCode.redeemButtonFocusColor}
                        // width           = {'100%'}
                        // border          = {'1px solid ' + ColorSchemeCode.borderColor}
                        height          = {'56px'}
                        onClick         = {handleModalOpen}
                    />
                </div> */}
            </div>} </>
            : ''}

        </div>
    )
}


const AddPointsModal = ({closeModal, state, formik}) => {
    return(
        <div className="bonusComponent">
            <div className="pointsInput modalComponent w-100 mt_32">
                <CustomTextField
                    placeholder  = "10 Points"
                    label        = "Points"
                    className    = "w-100"
                    borderRadius = "4px"
                    name         = "points"
                    value        = {formik.values.points}
                    onChange     = {formik.handleChange}
                    inputProps   = {{ onBlur: formik.handleBlur }}
                    error        = {formik.touched.points && formik.errors.points}
                    helperText   = {formik.touched.points && formik.errors.points && formik.errors.points}
                />
            </div>
            <div className="d-flex justify-flex-end mt_48 modalButton">
                <div>
                    <CustomButton 
                        varient         = "secondary"
                        btntext         = {'Cancel'}
                        width           = {'120px'}
                        backgroundColor = {ColorSchemeCode.white}
                        color           = {ColorSchemeCode.c000000}
                        focus           = {ColorSchemeCode.white}
                        hover           = {ColorSchemeCode.white}
                        onClick         = {closeModal}
                    />
                </div>
                <div className="ml_8">
                    <CustomButton 
                        btntext         = {'Add'}
                        width           = {'120px'}
                        backgroundColor = {ColorSchemeCode.c000000}
                        hover           = {ColorSchemeCode.c000000}
                        focus           = {ColorSchemeCode.c000000}
                        color           = {ColorSchemeCode.white}
                        onClick         = {formik.handleSubmit}
                        disabled        = {state.value.loader || !formik.isValid}
                        icon            = {state.value.loader && <CircularProgress size={20} color={'inherit'}/>}
                    />
                </div>
            </div>
        </div>
    )
}


const CongratulationScreen = ({onClose}) => {

    const history = useHistory();

    const handleClick = (e) => {
        const path = window.location.pathname.split('/')
        path.pop()
        history.push(path.join('/')+'/barcodescanner')
    }

    return(
        <div className="middle">
           <div clasName="text-center">
                <img src={PngIcons.CongratulationsIcon} alt=""/>
           </div>
           <div className="Heading2 color-c000000 mt_16 fs-24">
                Your stamps are completed
           </div>
           <div className="Body14R color-propertySectionTextColor">
                Your all stamps have been used, they will be renewed.
           </div>
           <div className="mt_40 w-100">
                <CustomButton 
                    btntext   = {'Okay'}
                    className = {'w-100'}
                    // height    = {'48px'}
                    onClick   = {handleClick}
                />
           </div>
        </div>
    )
}

const AvailComponent = ({state, setState, formik, setFinalData, finalData}) => {
    const brand = useContext(BrandContext);

    const availDeal = async (element, select) => {
        let data = [];
        for (const value of finalData) { 
            if(value.uniqueId === element.uniqueId && select) data.push({...value, avail : true});
            else if(value.uniqueId === element.uniqueId && !select) data.push({...value, avail : false});
            else data.push(value);
        }


        setFinalData(data);
        if(element.subtract) formik.setValues({...formik.values, pointUsed : element.subtract});
        localforage.removeItem('deals');
        localforage.setItem('deals', data);

        // let query = {brandId: brand._id, activity: Campaign.Activities.EARNING.CHECKIN};
        // const earningWays = await EarningWayService.GetQueriedEarningWays({query})
        // if(earningWays.error) return setState({...state, apiError : 'No CheckIn Warning Ways found'});
       
    }

    return(
        <>
         {
             (finalData && finalData.length > 0 && brand.brandType === Brand.BrandTypes.NORMAL) ? 
             finalData.map((element, idx)=>(
                 // (!element.avail) ? 
                 <div className={`availComponent d-flex bg-color-cF6F6F9 ${idx === state.dealIndex && 'activeDeal'} ${(!element.valid) ? 'disabled' : 'cp'} `} onClick = {()=>{setState({...state,dealOffer: { _id : element._id, name: element.name, image: element.image, description: element.description}, dealIndex : idx}); availDeal(element, true);}}>
                        <div className="d-flex w-100 space-between">
                            <div className="w-60">
                                <div className="Heading16M text-left fw-5 capitalize">
                                    {element.name}
                                </div>
                                <div className="fs-12 text-left mt_10 mb_8 dealDescription">
                                    {element.description}
                                </div>
                                { element.condition === 'visit'?
                                    <div className="d-flex w-fit-content" style={{backgroundColor: '#FFE8E0',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                        <SvgIcons.VisitDealIcon />
                                        <div className="Caption12M ml_8" style={{color: '#222940'}}>{element.conditionValue + ' ' + element.condition} </div>
                                    </div>
                                    :
                                    <div className="d-flex w-fit-content" style={{backgroundColor: '#FFF1C5',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                        <SvgIcons.PointDealIcon />
                                        <div className="Caption12M ml_8" style={{color: '#222940'}}>{element.conditionValue + ' ' + element.condition}</div>
                                    </div>
                                }
                            </div>
                            <div className="w-35 mr_8 text-right">
                                <img className="availImage mr_8 object-fit-cover" src={element.image} alt=""/>
                            </div>
                        </div>
                        {/* <div className='text-center w-10'>
                            {!element.avail ?
                            
                            <button 
                                className      = {!element.valid || state.dealOffer ? "availBtn disabled" :  'availBtn'}
                                onClick        = {()=>{setState({...state,dealOffer: { _id : element._id, name: element.name, image: element.image, description: element.description}}); availDeal(element, true);}}
                                disabled       = {!element.valid}
                            >
                                Add
                            </button>

                            :
                                <div onClick={()=>{setState({...state, dealOffer : ''}); availDeal(element,false)}}>
                                    <SvgIcons.DeclineIcon/>
                                </div>
                            }
                        </div>   */}
                    </div>

                    // : ''
                ))

                : (brand.brandType !== Brand.BrandTypes.NORMAL) ?
                <div className={`availComponent d-flex ${state.availDealOffer && 'bg-color-cF6F6F9'}`}>
                    <div className="d-flex w-100 space-between">
                        <div className="w-60">
                            <div className="Heading16M text-left fw-5 capitalize">
                                {state.dealOffer.name}
                            </div>
                            <div className="fs-12 text-left mt_10 mb_8 dealDescription">
                                {state.dealOffer.description}
                            </div>
                        </div>
                            <div className="w-35 mr_8 text-center">
                                <img className="availImage mr_8" src={state.dealOffer.image} alt=""/>
                            </div>
                    </div>

                    {/* <div className='text-center w-10'>
                        {!state.availDealOffer ?
                        
                        <button 
                            className      = "availBtn"
                            onClick        = {()=>setState({...state, availDealOffer : true})}
                        >
                            Add
                        </button>

                        :
                            <div onClick={()=>setState({...state, availDealOffer : false})}>
                                <SvgIcons.DeclineIcon/>
                            </div>
                        }
                    </div>   */}
                </div>
            :''
         }
        </>
    )
}