import React, { useEffect, useState } from 'react'
import CustomButton from '../../../../../components/CustomButton';
import ViewDetail from '../../../../../components/ViewDetail'
import { PngIcons, SvgIcons } from '../../../../../icons'
import { ClickOutside } from '../../../../../utils';
import Fade from 'react-reveal/Fade';

export default function ConnectedAccounts({formik, addNewCWFunc , setloader , Loader}) {


  const [viewMore, setViewMore]     = useState(false);
  const [selectedCW, setSelectedCW] = useState();


    const ViewMoreIconClickedFunc = (e) => {
        e.stopPropagation()
        setViewMore(!viewMore);
    }

    const handleDeleteFunc = (e) =>{
        // console.log('hee')
        e.stopPropagation();
        formik.values.cloudwaitress.splice(selectedCW, 1);
        formik.setValues({...formik.values});
        setViewMore(false);

    }
    useEffect(()=>{
      setloader(false)
    },[])


    const viewMoreItems = [
       {
           name    : 'Delete',
           function: (e)=>handleDeleteFunc(e),
           icon    : <SvgIcons.DeleteIcon/>
       },
    ]

    const AddFunc = async() =>{
      let newCloudWaitress = {
          name     : '',
          key      : '',
          url      : '',
          pabblyurl: '',
      }
      formik.values.cloudwaitress = [...formik.values.cloudwaitress, newCloudWaitress]
      await formik.setValues({...formik.values, selectedCWIndex : formik.values.cloudwaitress.length - 1})
      addNewCWFunc(2);
    }

    const UpdateCWFunc = async(index) =>{
      await formik.setValues({...formik.values, selectedCWIndex : index})
      addNewCWFunc(2);
    }


 

  return (
    <Fade top>
    <div id="ConnectedAccounts ">
      <h1 className='Heading24M'>Connected</h1>
      {formik.values.cloudwaitress.map((cloudWaitress, idx)=><div className='w-100 borderSpecial hpx-72 d-flex align-items-center justify-content-space-between cp' onClick={()=>UpdateCWFunc(idx)}>
        <div className='w-10 '>
          <img className='hpx-38 wpx-38' src={PngIcons.MediaBlock}  />
        </div>
        <div className='w-80 pl_10 connecedMain'>
          <h5>CloudWaitress</h5>
          <p>{cloudWaitress.name}</p>
        </div>
        <div onClick={(e)=>{e.stopPropagation(); setViewMore(true); setSelectedCW(idx);}}  className='w-10 d-flex justify-flex-end position-relative cp'>
            <div className='cp'><SvgIcons.Threedots/></div>
            {viewMore && idx == selectedCW &&<ClickOutside onClick={(e)=>{setViewMore(false); setSelectedCW("")}}>
              <div className="viewdetail position-absolute z-index-9 r-0">
                  <ViewDetail items={viewMoreItems}/>
              </div>
            </ClickOutside>}
        </div>
      </div>)}

      <div className='w-100 mt_32 d-flex justify-content-center'>
        <CustomButton
          btntext  = {"Add New Resturant"}
          icon     = <SvgIcons.Add/>
          varient  = "tertiary"
          onClick  = {AddFunc}
        /> 
      </div>
    </div>
    </Fade>
  )
}
