import React, { useContext, useEffect } from 'react'
import { AgencyContext } from '../../context/Agency.context'
import { AgencyPolicyService } from '../../services'

function Terms() {
    const agency = useContext(AgencyContext)
    useEffect(async() => {
      const data = await AgencyPolicyService.Get({query: {agencyId: agency._id}})
      // console.log(data);
      if(data&&data.response&&data.response.data&&data.response.data[0]&&data.response.data[0].termCondition){
        document.getElementById("terms").innerHTML = data.response.data[0].termCondition
      }
    }, [])
  return (
    <div class = "page mt_24">
        <div class = "container">
            <div class = "_card">
                <div id="terms"></div>
            </div>
        </div>
    </div>

  )
}

export default Terms