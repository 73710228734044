import React, {useContext, useEffect, useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Field, useFormik } from 'formik'

import CustomModal from '../../../components/CustomModal';
import EmptyScreen from '../../../components/EmptyScreen';


import { SvgIcons } from '../../../icons';
import CustomButton from '../../../components/CustomButton';

import {ColorSchemeCode} from '../../../enums/ColorScheme'

import CustomTextArea from '../../../components/CustomTextArea';

import CustomTextField from '../../../components/CustomTextField';
import { CustomFieldVld, TagVld } from '../../../validation';

import Loader from '../../../components/Loader';
import { BrandService, CustomFieldService } from '../../../services';

import CustomSelect from '../../../components/CustomSelect';

import * as localForage from 'localforage'
import { ClickOutside, Options, utils } from '../../../utils';
import { Brand, Fields } from '../../../enums';
import { AgencyContext } from '../../../context/Agency.context';
import { BrandContext } from '../../../context/Brand.context';
import CustomsModal from '../../../components/CustomModal';
import { FormHelperText, IconButton } from '@mui/material';
import CustomImageDrop from '../../../components/CustomImageDrop';
import { LayoutContext } from '../../../context/Layout.context';
import DeleteModal from '../../../components/DeleteModal';
import { ImageModalV2 } from '../../../components/ImageModalV2/ImageModalV2';
import CustomTableV2 from '../../../components/CustomTableV2';
import CustomSearch from '../../../components/CustomSearch';

export default function Tags(props) {

    const brand = useContext(BrandContext)

    const [showModal, setShowModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [subBrands, setSubBrands] = useState([]);
    const [loader, setLoader]       = useState(false);

    const [filteredField, setFilteredField] = useState([]);
    const [deleteTag, setDeleteTag]         = useState({});
    const [deleteModal, setDeleteModal]     = useState(false);
    const [show, setShow]                   = useState({
        filter : false,
    });

    const pushArr = (arr, body) => {
        arr.forEach((v,index) => {
            body.push({
                id              : v._id,
                value           : v,
                type            : v.type,
                customFieldType : v.customFieldType,
                key             : v.key,
                created         : new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
            })
        });

        return body
    }

    const onLoad = async () => {

        setLoader(true)

        const user = await localForage.getItem('user')
        const query = { brandId: user.activeBrand, delete: false}

        const getAllFields = await CustomFieldService.GetQueriedCustomFields({query})
console.log('getAllFields',getAllFields);
        if(getAllFields.response) {

            let body = []
            let arr = []
            arr = pushArr(getAllFields.response.data, body) 
            setTableData(arr)
            setFilteredField(arr)
        }
        
        const subBrands = await BrandService.Get({query: { parentBrandId: brand._id, brandType: Brand.BrandTypes.SUB_BRAND , delete: false}})
        if(subBrands.response){
            setSubBrands(subBrands.response.data)
        }

        setLoader(false)
    }

    useEffect(onLoad, [])

    const handleClick = () => setShowModal(true);

    const updateList = async(newField) => {
        await onLoad()
    }

    const layout = useContext(LayoutContext)
    useEffect(()=>{
        layout.setLayout({
            title       : "Settings",
            backTitles  : [],
            borderBottom: false,
            switch      : "",
            button      : ""
        });
    },[])

   
    const handleSearch = (e) => {
        let fields = tableData;
        let filteredFields = fields.filter(item =>
            item.label.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredField(filteredFields);
    }  

    const handleDelete = async () => {
        setLoader(true);

        const payload = {
            _id         : deleteTag.id,
            // tagName     : deleteTag.tagName,
            delete      : true,
        }

        const { error, response } = await CustomFieldService.UpdateCustomField({payload, toaster: true})
        if(response) await onLoad()
        setDeleteModal(false);
        setLoader(false);
    }

    return (
        <div id="customfield">
            {loader && <Loader baseHeight={'70vh'} width={180} height={180}/>}

            {!loader && <div>
                {console.log('tableData',tableData)}
                
            {tableData.length < 1 ? 
                <EmptyScreen btnClick={handleClick} title={'Custom Field'} btnTitle={'Add Custom Field'} /> :
            
            <div className="position-relative" >
                <div className='col-md-12 mt_24  col-12 pl_0 pr_0 d-flex align-items-center space-between'>
                    <div className='Heading22M color-neutral100'>Custom Fields</div>
                        <CustomButton 
                            btntext  = {"Add Custom Field"}
                            onClick  = {handleClick}
                        />
                </div>
                <div className="col-md-12 col-12 mt_16 pl_0 pr_0">  
                    <div className="bar"></div>
                </div>
                <div style={{justifyContent : 'flex-end '}} className="col-12 col-md-12 d-flex  pl_0 pr_0">
                <div className="col-4 col-md-4 pl_0 pr_0">
                    <CustomSearch
                        className     = "w-100 mt_16 float-right"
                        placeholder   = "Search"
                        position      = {'start'}
                        onChange      = {(e)=>handleSearch(e)}
                        onClickFilter = {()=>setShow({...show, showFiter : !show.showFiter})}
                        filterShow    = {show.showFiter}
                    />
                </div>
                </div>


                <div className="position-relative row">
                        <div className='bar mt_24'></div>
                        <div className="headerRow row fs-12 mt_16 mb_16 fw-5 ml-0 mr-0 w-100">
                            <div className='col-3 Body12M color-Paragraph pt-16 pb-16 pl-24'>
                                Custom Field Type
                            </div>
                            <div className='col-3 Body12M color-Paragraph pt-16 pb-16 text-center d-flex justify-content-center align-items-center'>
                                Key
                            </div>
                            <div className='col-2 Body12M text-center color-Paragraph pt-16 pb-16 d-flex justify-content-center align-items-center'>
                                Type 
                            </div>
                            <div className='col-2 Body12M text-center color-Paragraph pt-16 pb-16 d-flex justify-content-center align-items-center'>
                                Created 
                            </div>
                            <div className='col-2 Body12M text-center color-Paragraph pt-16 pb-16 d-flex justify-content-center align-items-center'>
                                Action 
                            </div>
                        </div>
                        <div className='bar'></div>
                        <div className='tableBody w-100'>
                            {filteredField.map((field, idx)=>
                                <div key={idx} className='row ml-0 mr-0 fs-14 capitalize d-flex align-items-center bodyRow cp d-flex color-neutral90 Link13R align-items-center w-100 dataRowSmall tableRowHover pl_10'  
                                onClick= {()=>showModal(true)}
                                >
                                    <div className="col-3 d-flex " >
                                        <div className = "Link13M color-Heading">
                                            {field.customFieldType}
                                        </div>
                                    </div>
                                     <div className="col-3 pt-16 pb-16 color-Heading color-Heading middle">
                                        <div className="Link13R color-Heading">
                                             {field.key}
                                        </div>
                                    </div>
                                    <div className="col-2 pt-16 pb-16 Link13R  text-center color-Heading color-Heading middle">
                                        {field.type}
                                    </div>
                                    <div className="col-2 pt-16 pb-16 Link13R  text-center color-Heading color-Heading middle">
                                       {new Date(field.created).getDate() + ' ' + (new Date(field.created).getMonth())+1 + '-' + new Date(field.created).getFullYear()}
                                    </div>
                                    {
                                        console.log('field',field)
                                    }
                                    <div className="col-2 pt-16 pb-16 Link13R  text-center color-Heading color-Heading middle">
                                        <span onClick={()=>{setDeleteTag({id : field.id}); setDeleteModal(true) } }><SvgIcons.DeleteIcon/></span>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
            </div>}
            </div>}

            <CustomModal 
                open      = { deleteModal }
                minWidth  = {'600px'}
                component = {<DeleteModal icon={loader} title="Custom Field" onDelete={handleDelete} onCancel={()=> setDeleteModal(false)}/>}
                onClose   = {()=> setDeleteModal(false)}
            />

            {showModal && 
                <CustomModal 
                    open      = {showModal}
                    onClose   = {() => setShowModal(false)}
                    maxWidth  = {600}
                    minWidth  = {600} 
                    title     = "Add Customfield"
                    component = {<ModalComponent subBrands={subBrands} closeModal={()=>setShowModal(false)} updateList={updateList} onLoad={onLoad}/>}
                />
            }
        </div>
    )
}

const ModalComponent = ({closeModal, updateList, subBrands, onLoad}) => {

    const agency = useContext(AgencyContext)
    const brand = useContext(BrandContext);

    const [tagNameError, setTagNameError] = useState('')
    const [loader, setLoader]             = useState(false)
    const [color, setColor]               = useState('')
    
    const [imageModal, setImageModal] = useState({
        logo  : {open: false, imageEvent: '', return: ''},
        cover : {open: false, imageEvent: '', return: ''}
    })

    const [values, setValues] = useState([]);
    const [idxCount, setIdxCount] = useState(0);
    const [assignedCustomField, setAssignedCustomField] = useState({
        customFieldType : Fields.CustomFiedlTypes.ASSIGNED_TO,
        key             : '',
        email           : '',
        dialingCode     : '',
        phoneNo         : ''

    })
  
    const initState = {
        customFieldType : Fields.CustomFiedlTypes.SUBSCRIBER_FIELD,
        key             : '',
        subBrands       : '',
        value           : '',
        type            : Fields.FieldTypes.IMAGE_URL,
        
        brandCover      : '',
        brandCoverFile  : '',
          
        label           : '',
        description     : '',

    }

    const types = [
        {
            name: 'Subscriber Field',
            value: 'subscriber'
        },
        {
            name: 'Brand Field',
            value: 'brand'
        },
        {
            name: 'Assigned To',
            value: 'assigned'
        },
    ]

    if(brand.brandType === Brand.BrandTypes.SHARED_LOYALITY)
    {
        types.push(
            {
                name: 'Sub Brand Field',
                value: 'subBrand'
            }
        )
    }

    const handleSubmit = async () => {
        setTagNameError('')
        setLoader(true)
        
        let cover
        const user        = await localForage.getItem('user')
        const desiredPath = `${user._id}/${formik.values.key}`
        
        if(formik.values.brandCoverFile){
            cover = await utils.uploadImage({file: formik.values.brandCoverFile, desiredPath})
            if(cover && cover.error) return // console.log(cover.error.message)
        }

        const query = { brandId: user.activeBrand, key: formik.values.key, delete: false }

        const { error, response } = await CustomFieldService.GetQueriedCustomFields({query})

        if(response) {
            setLoader(false)
            return setTagNameError('Key already exist')
        }
        
        let payload
        payload = {
            agencyId: agency._id,
            brandId : user.activeBrand,
            image   : cover ? cover.response.data : '',
            ...formik.values
        }

        
        delete payload.brandCover
        delete payload.brandCoverFile

        if(formik.values.customFieldType !== Fields.CustomFiedlTypes.SUB_BRAND_FIELD){
            delete payload.subBrands
            delete payload.image
        }
        if(formik.values.customFieldType === Fields.CustomFiedlTypes.SUB_BRAND_FIELD){
            if(formik.values.type !== Fields.FieldTypes.IMAGE_URL) delete payload.image
            delete payload.label
            delete payload.description
            
            if(payload.type !== Fields.FieldTypes.URL) payload.childBrandId = brand._id
            else payload.childBrandId = payload.subBrands
            delete payload.subBrands
            
            if(payload.value === "") payload.value = payload.value
            else{
                if(payload.value.includes("https://")) payload.value = payload.value
                else payload.value = "https://" + payload.value
            }
        }

        if(formik.values.type === Fields.FieldTypes.DATE) payload.value = new Date(formik.values.value).valueOf()

        if(formik.values.customFieldType === Fields.CustomFiedlTypes.SUBSCRIBER_FIELD && values.length > 0){
            payload.moreValues = values;
        }

        // console.log('payload ', payload)

        const createCustomField = await CustomFieldService.Create({payload, toaster: true})
        if(createCustomField.response && createCustomField.response.success){
            // updateList(createCustomField.response.data)
            await onLoad()
            setLoader(false)
            closeModal()
        }
        setLoader(false)

    }

    const handleSubmitAssign = async () => {
        setTagNameError('')
        setLoader(true)
        
        
        let payload
        payload = {
            brandId  : brand._id,
            ...assignedCustomField
        }

        
        const createCustomField = await CustomFieldService.Create({payload, toaster: true})
        // console.log('createCustomField', createCustomField);
        if(createCustomField.response && createCustomField.response.success){
            await onLoad()
            setLoader(false)
            closeModal()
        }
        setLoader(false)

    }


    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : CustomFieldVld.CustomFieldVld,
        isInitialValid   : false
    })

    const handleClickType = (field) => formik.setValues({...formik.values, customFieldType: field.value})

    useEffect(() => {
        const color = utils.hexTohsl(agency.agencyColor)
        setColor("hsl("+color.h+","+color.s+"%,"+color.l+"%,"+0.1+")")
    }, [agency])


    return ( 
        <div className='w-100 modal-cutomfield'>
         <form onSubmit={formik.handleSubmit}>
            <div>
            {       
                formik.values.customFieldType === Fields.CustomFiedlTypes.ASSIGNED_TO ? 
                    <div className='w-100'>
                        <div className="modalComponent">
                        <div className='Link14M color-neutral100 mt_16'>Select Custom Field Type:</div>
                            <div className='row mt_10 w-100'>
                            {types.map((field)=>(
                                <div
                                    onClick={()=> handleClickType(field)} 
                                    className={(field.value === formik.values.customFieldType) ?'customfieldBtn selectedType Caption12M color-themeColor bg-color-color(neutral20) p_8 ml_16 mt_8': 'Caption12M customfieldBtn color-ButtonSecondaryText bg-color-bgTextBox p_8 ml_16 mt_8'} 
                                    style={{border: (field.value === formik.values.customFieldType) ? '2px solid '+ColorSchemeCode.themeColor : '2px solid '+ColorSchemeCode.bgTextBox}}
                                    >
                                        {field.name}
                                </div>
                            ))}
                            </div>
                            <div className='w-100 mt_16'>
                                    <CustomTextField 
                                        className    = "w-100"
                                        label        = "Assigne Title"
                                        borderRadius = {'12px'}
                                        name         = "key"
                                        type         = "text"
                                        value        = {assignedCustomField.key}
                                        onChange     = {(e)=>{setAssignedCustomField({...assignedCustomField, key : e.target.value})}}
                                    />
                            </div>
                            <div className='w-100 mt_16'>
                                    <CustomTextField 
                                        className    = "w-100"
                                        label        = "Assigne Email"
                                        borderRadius = {'12px'}
                                        name         = "email"
                                        type         = "email"
                                        value        = {assignedCustomField.email}
                                        onChange     = {(e)=>{setAssignedCustomField({...assignedCustomField, email : e.target.value})}}
                                    />
                            </div>
                            <div className='d-flex mt_16 ml_0 mr_0 mb_24' style={{marginLeft : '0px !important', marginRight: '0px !important'}}>
                                <div className='w-35' style={{paddingLeft : '0px !important', paddingRight: '0px !important'}}>
                                    <CustomTextField 
                                        className    = "w-100"
                                        label        = "Assigne Dialing code"
                                        borderRadius = {'12px'}
                                        name         = "dialingCode"
                                        type         = "text"
                                        value        = {assignedCustomField.dialingCode}
                                        onChange     = {(e)=>{setAssignedCustomField({...assignedCustomField, dialingCode : e.target.value})}}
                                    />
                                </div>
                                <div className='w-65 ml_8 pl_0 pr_0' style={{paddingLeft : '0px !important', paddingRight: '0px !important'}}>
                                    <CustomTextField 
                                        className    = "w-100"
                                        label        = "Assigne Phone"
                                        borderRadius = {'12px'}
                                        name         = "phone"
                                        type         = "text"
                                        value        = {assignedCustomField.phoneNo}
                                        onChange     = {(e)=>{setAssignedCustomField({...assignedCustomField, phoneNo : e.target.value})}}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-flex-end modalButton'>
                            <div className="mr_16">
                                <CustomButton 
                                    btntext      = {'Cancel'}
                                    varient      = {'secondary'}
                                    onClick      = {closeModal}
                                />
                            </div>
                            <CustomButton 
                                btntext      = {'Create'}
                                type         = 'button'
                                fontSize     = {'14px'}
                                borderRadius = {"14px"}
                                onClick      = {()=>{handleSubmitAssign()}}
                                icon         = {loader && <CircularProgress size={20} color={'inherit'}/>}
                            />
                        </div>
                    </div> 
                : 
                    <div className='w-100'> 
                        <div className="modalComponent">
                            <div className='Link14M color-neutral100 mt_16'>Select Custom Field Type:</div>
                            <div className='row mt_10 w-100'>
                            {types.map((field)=>(
                                <div
                                    onClick={()=> handleClickType(field)} 
                                    className={(field.value === formik.values.customFieldType) ?'customfieldBtn selectedType Caption12M color-themeColor bg-color-color(neutral20) p_8 ml_16 mt_8': 'Caption12M customfieldBtn color-ButtonSecondaryText bg-color-bgTextBox p_8 ml_16 mt_8'} 
                                    style={{border: (field.value === formik.values.customFieldType) ? '2px solid '+ColorSchemeCode.themeColor : '2px solid '+ColorSchemeCode.bgTextBox}}
                                    >
                                        {field.name}
                                </div>
                            ))}
                            </div>
                            <div className='w-100 mt_16 '>
                                    <CustomSelect 
                                        className    = "w-100"
                                        label        = "Field Type"
                                        options      = {formik.values.customFieldType === Fields.CustomFiedlTypes.SUB_BRAND_FIELD ? Options.getFieldTypesSUBBRAND() : Options.getFieldTypes()}
                                        name         = "type"
                                        value        = {formik.values.type}
                                        onChange     = {(e)=>formik.setValues({...formik.values, type: e.target.value})}
                                        error        = {formik.touched.type && formik.errors.type}
                                        helperText   = {formik.touched.type && formik.errors.type && formik.errors.type}/>
                            </div>

                            {formik.values.customFieldType === Fields.CustomFiedlTypes.SUB_BRAND_FIELD &&  formik.values.type === Fields.FieldTypes.URL &&
                            <div className='w-100 mt_16'>
                                <CustomSelect 
                                    className    = "w-100"
                                    label        = "Sub Brands"
                                    options      = {subBrands.map((value) => ({
                                        name : value.brandName,
                                        value: value._id
                                    }))}
                                    name         = "subBrands"
                                    value        = {formik.values.subBrands}
                                    onChange     = {(e)=>formik.setValues({...formik.values, subBrands: e.target.value})}
                                    error        = {formik.touched.subBrands && formik.errors.subBrands}
                                    helperText   = {formik.touched.subBrands && formik.errors.subBrands && formik.errors.subBrands}/>
                            </div>}
                            <div className='w-100 mt_16'>
                                <CustomTextField 
                                        className    = "w-100"
                                        label        = "Field Title"
                                        borderRadius = {'12px'}
                                        name         = "key"
                                        value        = {formik.values.key}
                                        onChange     = {formik.handleChange}
                                        error        = {formik.touched.key && formik.errors.key}
                                        helperText   = {formik.touched.key && formik.errors.key ? formik.errors.key : ''}/>
                            </div>
                            {formik.values.customFieldType !== Fields.CustomFiedlTypes.SUB_BRAND_FIELD && <div className='w-100 mt_16'>
                                <CustomTextField 
                                        className    = "w-100"
                                        label        = "Field Label"
                                        borderRadius = {'12px'}
                                        name         = "label"
                                        value        = {formik.values.label}
                                        onChange     = {formik.handleChange}
                                        inputProps   = {{ onFocus: formik.handleBlur }}
                                        error        = {formik.touched.label && formik.errors.label}
                                        helperText   = {formik.touched.label && formik.errors.label && formik.errors.label}/>
                            </div>}
                        
                            <div className='w-100 mt_16'>
                                {/* {formik.values.type !== 'boolean' ?  */}
                                    <CustomTextField 
                                        className    = "w-100 mb_16"
                                        label        = "Value"
                                        borderRadius = {'12px'}
                                        name         = "value"
                                        type         = {formik.values.customFieldType !== Fields.CustomFiedlTypes.SUB_BRAND_FIELD && formik.values.type}
                                        value        = {formik.values.value}
                                        onChange     = {formik.handleChange}
                                        inputProps   = {{ onFocus: formik.handleBlur }}
                                        error        = {formik.touched.value && formik.errors.value}
                                        helperText   = {formik.touched.value && formik.errors.value && formik.errors.value}/>
                                    
                                    {
                                    formik.values.customFieldType === Fields.CustomFiedlTypes.SUBSCRIBER_FIELD && values.map((element, idx)=>(
                                            <CustomTextField 
                                                className    = "w-100 mb_16"
                                                label        = {"Value " + (idx+2)}
                                                borderRadius = {'12px'}
                                                type         = {formik.values.customFieldType !== Fields.CustomFiedlTypes.SUB_BRAND_FIELD && formik.values.type}
                                                value        = {values[idx].value}
                                                onChange     = {(e)=>{values[idx].value = e.target.value;}}/>
                                        ))
                                    }
                                    {console.log('values', values, idxCount)}
                                    { formik.values.customFieldType === Fields.CustomFiedlTypes.SUBSCRIBER_FIELD && <div className='color-neutral100 text-center mt_16 cp' onClick={()=>{setValues([...values,{idx: idxCount}]); setIdxCount(idxCount + 1)}}>Add More</div>}
                            </div>

                            {formik.values.customFieldType === Fields.CustomFiedlTypes.SUB_BRAND_FIELD && formik.values.type === Fields.FieldTypes.IMAGE_URL && <div className="w-100">
                                <div className="col-12 col-md-12 caption2 fs-12 mt_16 pl_0">
                                    <span className="Link14M color-neutral100">Field Image </span>
                                </div>
                                <CustomImageDrop
                                    onChange       = {(e)=> {setImageModal({...imageModal, cover: {...imageModal.cover, open: true, imageEvent: e}}) }}
                                    isImage        = {(formik.values.brandCover || formik.values.brandCoverFile) && !formik.errors.brandCoverFile}
                                    imageUrl       = {formik.values.brandCoverFile ? URL.createObjectURL(formik.values.brandCoverFile) : formik.values.brandCover}
                                    uploadError    = {formik.errors.brandCoverFile}
                                    className      = "bg-color-cF6F6F9"
                                    text           = {
                                        <div className="">
                                        <div className="Body14R title"> Drop or click to upload image</div>
                                        <div className="mb_0">
                                            <span className="Link13M text-center subHeading">Recommended image size: 205 x 80 </span>
                                        </div>
                                        </div>
                                    }
                                />
                            </div>}

                            <CustomsModal
                                open      = { imageModal.cover.open }
                                onClose   = { ()=> setImageModal({...imageModal, cover: {...imageModal.cover, open: false}}) }
                                minWidth  = "470px"
                                minHeight = "470px"
                                padding   = "0px"
                                component = { 
                                    <ImageModalV2 
                                        imageModal    = { imageModal }
                                        setImageModal = { setImageModal }
                                        image         = { 'customField' }
                                        type          = { 'customField' }
                                        formik        = { formik }
                                    />
                                }
                            />    
                            {formik.values.customFieldType !== Fields.CustomFiedlTypes.SUB_BRAND_FIELD && <div className='w-100'>
                                <CustomTextArea
                                        classes      = "w-100"
                                        label        = "Description"
                                        rows         = {5}
                                        name         = "description"
                                        defaultValue = {formik.values.description}
                                        // onBlur       = {formik.handleChange}
                                        onChange       = {formik.handleChange}
                                        error        = {formik.touched.description && formik.errors.description}
                                        helperText   = {formik.touched.description && formik.errors.description && formik.errors.description}
                                    />
                            </div>}
                        </div> 

                        <div className='d-flex justify-flex-end modalButton'>
                            <div className="mr_16">
                                <CustomButton 
                                    btntext      = {'Cancel'}
                                    varient      = {'secondary'}
                                    onClick      = {closeModal}
                                />
                            </div>
                            <CustomButton 
                                btntext      = {'Create'}
                                type         = 'submit'
                                fontSize     = {'14px'}
                                borderRadius = {"14px"}
                                // height       = {'45px'}
                                disabled     = {!formik.isValid}
                                icon         = {loader && <CircularProgress size={20} color={'inherit'}/>}
                            />
                        </div>
                    </div>
            }

             
            </div>

            <div className='row color-redemptionHeaderColor pl_16 mt_16'>
                {formik.errors.customFieldType ? formik.errors.customFieldType : ''}
                {tagNameError}
            </div>
         </form>
        </div>
    )
}
