import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ColorSchemeCode } from '../enums/ColorScheme';
import { FormHelperText } from '@material-ui/core';

export default function TextFieldSizes(props) {
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': {
          m     : 1,
          width : '100%',
          margin: 0,
          padding: 0,
          fontFamily: 'Inter',

        },
        '& .MuiInputLabel-root':{
          fontSize  : '16px',
          fontFamily: 'Inter',
          color     : '#525252',
          lineHeight: '16px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: ColorSchemeCode.primary50,
        },
        '& .MuiInputBase-root': {
          fontSize     : 16,
          padding: 0,
          fontFamily: 'Inter',

        },
        focusedIcon: {
          "& svg": {
            color: ColorSchemeCode.primary50,
          },
        },
        error: {
          color: ColorSchemeCode.danger30,
          fontSize: 14,
          fontWeight: 500,
        },
      }}
    >
      <TextField
        label        = {props.label}
        id           = {`${props.id}`}
        variant      = "outlined"
        value        = {props.value || props.defaultValue}
        ref          = {props.inputRef}
        name         = {props.name}
        type         = {props.type || "text"}
        onChange     = {props.onChange || props.onBlur}
        disabled     = {props.disabled}
        autoComplete = "on"
        placeholder  = 'Search'
        onKeyUp      = {props.onKeyUp}
        helperText   = {props.helperText}
        error        = {props.error}
        inputProps   = {props.inputProps}
        className    = {`${props.className}`}
        onFocus      = {() => setIsFocused(true)}
        onBlur       = {() => setIsFocused(false)}
      />
      {props.icon && props.position == "start" && (
        <div
          className={`position-absolute Body14R`}
          style={{ left: 16, top: props.top ? props.top : 40 }}
        >
          {props.icon}
        </div>
      )}
      {props.icon && props.position == "end" && (
        <div
          className={`position-absolute Body14R`}
          style={{ right:props.right? props.right : 16, top: props.top ? props.top : 14 }}
        >
          {props.icon}
        </div>
      )}
    </Box>
  );
}
