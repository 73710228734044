import React, {useContext, useEffect, useState} from 'react'

import Graph from '../../../components/Graph';

import CustomDateRange from '../../../components/CustomDateRange'
import ClickOutside from '../../../utils/ClickOutside';
import CustomTable from '../../../components/customTable/CustomTable'



import SvgIcons from '../../../icons/svg.icon';
import {ColorSchemeCode} from '../../../enums/ColorScheme';
import CustomButton from '../../../components/CustomButton';
import { AgencyContext } from '../../../context/Agency.context';
import { LayoutContext } from '../../../context/Layout.context';

export default function Analytics() {

    const agency = useContext(AgencyContext)
    const layout = useContext(LayoutContext)

    const [tableData, setTableData] = useState([]);

     useEffect(()=>{
        setTableData(tierResponse)
    },[])

    const tierResponse =  [
        {
            tiersName  : 'Bronze',
            Customers  : '31',
        },
        {
            tiersName  : 'Silver',
            Customers  : '61',
        },
        {
            tiersName  : 'Gold',
            Customers  : '5',
        },
     ]

    const tableHeadings =  [
        { id: 'serial', label: '' },
        { id: 'tiersName', label: 'Tiers Name' },
        { id: 'customers', label: 'Customers' },
    ]

    const tableDataa = tableData.map((tier, index) => createData({
        id          : index,
        serial      : index+1,
        tierName    : tier.tiersName,
        customers   : tier.Customers,
    }))


    function createData({serial, tierName ,customers, id}) {
        return { 
            props: {
                name     : 'rowProps',
                class    : '',
                id       : id,
                checkbox : false
            },
            serial: {
                label: serial,
                class: 'pl_28',
            },
            tierName: {
                label : tierName,
                class : '',
            }, 
            customers: {
                label : customers,
                class : 'pl_28 fw-6',
            }, 
        };
    }

    const respose = {
        analyticsDetails : [
            {
                label : 'Active Points',
                value : '-9173'
            },
            {
                label : 'Issued Points',
                value : '43792'
            },
            {
                label : 'Burned Points',
                value : '4324'
            },
            {
                label : 'Expired Points',
                value : '982103'
            },
            {
                label : 'Pending Points',
                value : '0'
            },
            {
                label : 'Spending (total)',
                value : '23232 EUR'
            },
        ],
        graphDetails     : [
            {
                label : 'New Members',
                data  : [20,30,34,35,38,23,26,27,31,50,15],
            },
            {
                label : 'Issued Points',
                data  : [20,30,34,35,38,23,26,27,31,50,15],
            },
            {
                label : 'Burned Points',
                data  : [20,30,34,35,38,23,26,27,31,50,15],
            },
            {
                label : 'Pending Points',
                data  : [20,30,34,35,38,23,26,27,31,50,15],
            },
            {
                label : 'Expired and expiring',
                data  : [20,30,34,35,38,23,26,27,31,50,15],
            },
            

        ]
    }

    const [state, setState] = useState({
        analyticsDetails : respose.analyticsDetails,
        graphDetails     : respose.graphDetails,
    })


    const onLoad = () => layout.setLayout({expandedBar: true, header: true})

    useEffect(onLoad, [])


    return (
        <div id="Analytics">
            <div className="Heading22R color-c000000">
              Analytics
            </div>
            <div className="grid-container">
                {state.analyticsDetails.map((item, index)=>
                    <div key={index} className="grid-item">
                        <div className="fs-14 color-propertySectionTextColor">
                            {item.label}
                        </div>
                        <div className="Heading2 fs-24 fw-5 mt_8 color-c000000">
                            {item.value}
                        </div>
                        {item.daysList && 
                        <div className="daysSection d-flex"> 
                            {item.daysList.map((element, idx)=>
                            <div>
                                <div key={idx} className="Body14R color-c2F80ED fw-5">
                                    {element.day}
                                </div>
                                <div className="Body14R fw-6">
                                    {element.value} 
                                </div>   

                            </div>
                            )}
                        </div>}   
                    </div>
                )}
            </div>
            <div className="graph-container">
                <div>
            {state.graphDetails.map((graph, indx)=>
                <div key={indx}>
                    <GraphComponent data={graph.data} label={graph.label}/>
                </div>
            )}
            <div className="mt_16 tableDesign">
                <div className="Heading22R color-c000000">
                    Tiers
                </div>
                <CustomTable
                    dataRows     = {tableDataa}
                    headerRow    = {tableHeadings}
                    tableShadow  = {'none'}
                    tableHeight  = {'500px'}
                    stickyHeader = {true}
                />
            </div>
            </div>
            </div>
        </div>
    )
}


const GraphComponent = ({label, data}) => {

    const LineGraphData = {
        labels:  ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov', 'Dec'],
        datasets:[
            {
                data: data,
                borderWidth: 1.5,
                label:"New Members",
                borderColor: '#4AD991',
                backgroundColor: 'rgba(74, 217, 145, 0.148239) 50%, rgba(255, 255, 255, 0.176942)',
            }
        ],
    }

    return (
        <div className="graphComponent">
            <div className="graphHeader row ml_0 mr_0">
                <div className="col-6 middle align-items-left">
                    <div className="subtitle1 color-c000000 fw-6">
                        {label}
                    </div>
                </div>
                <div className="col-6 graphViewButtons">
                    <div className="perDayButton mr_24">
                        <CustomButton 
                            btntext = "Per Day"
                            // height  = "31px"
                        />
                    </div>
                    <div className="perMonthButton mr_24">
                        <CustomButton 
                            btntext = "Per Month"
                            // height  = "31px"

                        />
                    </div>
                    <div className="peryearButton">
                        <CustomButton 
                            btntext = "Per Year"
                            // height  = "31px"

                        />
                    </div>
                </div>
            </div>
           
            <div className="row">
                    <div className="col-12 graph">
                    <Graph type="line" data={LineGraphData} />
                    </div>
                </div>
            </div>    
    )
}
