import React, { useEffect, useState ,useRef } from 'react'
import { useFormik } from "formik";
import CustomTextField from "../../../../components/CustomTextField";
import CustomTextArea from '../../../../components/CustomTextArea';
import CustomRadio from "../../../../components/CustomRadio";
import { CircularProgress, RadioGroup, Radio } from "@mui/material";
import { Campaign } from '../../../../enums';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import CustomButton from '../../../../components/CustomButton';
import { SvgIcons } from '../../../../icons';
import { ColorSchemeCode } from '../../../../enums/ColorScheme';
import localforage from 'localforage';
import Fade from 'react-reveal/Fade';

export function EarningWays({formik}) {
    const inputRef = useRef(null);
    const [status, setStatus] = useState(false)

    const [state, setState] = React.useState({
        apiError: "",
        loader: false,
        
    });
console.log('EWAY',formik.values);
    const removingEarningValue = (element, idx) => {
        const values = formik.values.earningWay.earningValueRange.filter((element, index)=>{
           return  index != idx
        })
console.log('values',values);


        formik.setValues({...formik.values , earningWay : {...formik.values.earningWay, earningValueRange : values}})
    }

    const addPointRangesFunc = () => {
        let newRange = {
            minValue : 0,
            maxValue : 0,
            points   : 0,
        }
        formik.setValues({...formik.values , earningWay : {...formik.values.earningWay, earningValueRange : [...formik.values.earningWay.earningValueRange , newRange]}})
    }

    useEffect(()=>{
        onLoadFunc();
       
    },[])


   

    const onLoadFunc = async() =>{
        let earningWays = await localforage.getItem('earningways');
        if(earningWays){
            formik.setValues({...formik.values , loader : true})
            // console.log('earningWays ', earningWays)
            let filteredEarningWay = earningWays.find((earningway)=>{
                return earningway.activity == "spending"
            })
    
            formik.setValues({...formik.values, loader: false, earningWay : {...formik.values.earningWay, ...filteredEarningWay}})
        }
        setStatus(!status)
        
    }
    

    

    return (
        <Fade top>
        <div id="EarningWayOnboard">
            <h1 className='Heading24M'>Define how Customer will earn points.</h1>

            <form onSubmit={formik.handleSubmit} >
                <div className="auth-form borders" >

                    <RadioGroup
                        name     = "earningWay.earningType"
                        value    = {formik.values?.earningWay?.earningType}
                        onChange = {(e)=>{formik.values.earningWay.earningValueRange = []; formik.values.earningWay.earningType = e.target.value; formik.setValues({...formik.values})}}
                        onFocus  = {formik.handleBlur}
                    >

                        <div  className={formik.values?.earningWay?.earningType == Campaign.EarningTypes.INCREMENTAL ? "w-100 radioParent selected " : "w-100 radioParent "}>
                            
                            <CustomRadio
                            
                                value={Campaign.EarningTypes.INCREMENTAL}
                                label={
                                    <div className='standAlone'>
                                        <h3 className="Body14M">Points increment</h3>
                                        <p className="Body10M">Points are earned based on spending. The more spending, the more points will be gained, demonstrating a direct correlation. The value of each point can be set, for example, with 1 point equaling 1 dollar.</p>
                                    </div>
                                }
                                className="mt_16"
                            />
                        </div>
                        {
                            (formik.values?.earningWay?.earningType == Campaign.EarningTypes.INCREMENTAL) && 
                            <div>
                           
                                <div className="w-100 mt_20">
                                    <CustomTextField
                                        className   = "w-100"
                                        label       = {"Customers earn points for every one dollar spent"}
                                        name        = "earningWay.earningValue"
                                        type        = "number"
                                        background  = '#F5F7FC'
                                        focused = {true}
                                        inputProps={{min:0 , pattern:"[0-9]+" ,  inputmode:"numeric"}}
                                        value       = {formik.values?.earningWay?.earningValue}
                                        onChange    = {formik.handleChange}
                                        // onChange={(e)=>{
                                        //     if(e.target.value >=0){
                                        //         console.log('greater')
                                        //         formik.setValues({...formik.values, earningWay : {...formik.values.earningWay, earningValue : e.target.value}})
                                        //     }
                                        //     else{
                                        //         console.log('less')
                                        //         formik.setValues({...formik.values, earningWay : {...formik.values.earningWay, earningValue : ''}})
                                        //         // e.target.disabled;
                                        //     }
                                        // }}
                                        error       = {formik.touched?.earningWay?.earningValue && formik.errors?.earningWay?.earningValue}
                                        // helperText  = {formik.touched?.earningWay?.earningValue && formik.errors?.earningWay?.earningValue && formik.errors?.earningWay?.earningValue}
                                       helperText={formik.errors?.earningWay?.earningValue}
                                        position    = 'end'
                                        paddingRight = '50px'
                                        icon         = {<div className='wpx-50 mb_0 ml_0 mr_0 d-flex'>Points</div>}
                                    />
                                </div>

                                <div className="description w-100 mt_20">
                                    <CustomTextArea
                                        label       = {'Description (optional)'}
                                        name        = 'earningWay.description'
                                        placeholder = "Enter Short Description about the product?"
                                        className   = "w-100"
                                        background  = '#F5F7FC'
                                        value       = {formik.values?.earningWay?.description}
                                        onChange    = {formik.handleChange}
                                        onBlur      = {formik.handleChange}
                                    />
                                </div>
                            </div>
                        }


                        <div className={formik.values?.earningWay?.earningType == Campaign.EarningTypes.FIXED ? "w-100 radioParent selected" : "w-100 radioParent "}>
                            <CustomRadio
                                value={Campaign.EarningTypes.FIXED}
                                label={
                                    <div className='standAlone'>
                                        <h3 className="Body14M">Fixed points</h3>
                                        <p className="Body10M">customers earn one point for spending a minimum dollar value on their purchases. This means that if a customer spends at least the minimum dollar amount specified by the loyalty program, they will earn one point.</p>
                                    </div>
                                }
                                className="mt_16"
                            />
                        </div>
                        {
                            (formik.values?.earningWay?.earningType == Campaign.EarningTypes.FIXED) && 
                            <div>
                                <div className="w-100 mt_20">
                                    <CustomTextField
                                        label      = "To earn one point customers must spend at least"
                                        type       = "number"
                                        name ="earningWay.earningValue"
                                        value= {formik?.values?.earningWay?.earningValue}
                                        className  = "w-100 mb_20"
                                        onChange   = {formik.handleChange}
                                        padding    = "24px 16px"
                                        inputProps={{min:0}}
                                        background = '#F5F7FC'
                                        position    = 'end'
                                        paddingRight = '50px'
                                        icon        = {<div className='wpx-50 mb_0 ml_0 mr_0 d-flex'>Points</div>}
                                        error      = {formik.touched?.earningWay?.earningValue && formik.errors?.earningWay?.earningValue}
                                        helperText = {formik.touched?.earningWay?.earningValue && formik.errors?.earningWay?.earningValue && formik.errors?.earningWay?.earningValue}
                                       
                                    />
                                </div>

                                <CustomTextArea
                                        label       = {'Description (optional)'}
                                        name        = 'earningWay.description'
                                        placeholder = "Enter Short Description about the product?"
                                        className   = "w-100"
                                        background  = '#F5F7FC'
                                        value       = {formik.values?.earningWay?.description}
                                        onChange    = {formik.handleChange}
                                        onBlur      = {formik.handleChange}
                                    />
                            </div>
                            

                        }
                        <div style={{paddingLeft:"20px"}} className={formik.values?.earningWay?.earningType == Campaign.EarningTypes.RANGE ? "w-100 radioParent selected mb_5" : "w-100 radioParent mb_5"}>
                            <CustomRadio
                                value={Campaign.EarningTypes.RANGE}
                                // checked = {defaultCheck}
                                label={
                                    <div className='standAlone'>
                                        <h3 className="Body14M">Range bucket</h3>
                                        <p className="Body10M">customers earn one point when they make a purchase that falls within a specific spending range set by the loyalty program. The range of spending required to earn a point can vary depending on the program,</p>
                                    </div>
                                }
                                className="mt_16 "
                            />
                        </div>
                        <div className="position-relative">
                                {formik.values.earningWay.earningValueRange.map((element, idx)=><div className="singleEarningRange w-100">
                                    <div className="position-relative">
                                        {idx > 0 && <div className="cp Body14R color-neutral60 position-absolute" style={{right: 0, top: 0}} onClick={()=>removingEarningValue(element, idx)}>
                                            Remove 
                                        </div>}
                                    </div>
                                    <div className="d-flex" >
                                        <div style={{width: 100}}>

                                            <CustomTextField 
                                                label       = "Min"
                                                icon        = {<div className=''>$</div>}
                                                position    = "end"
                                                name        = {`earningWay.earningValueRange.[${idx}].minValue`}
                                                type        = "number"
                                                width       = "w-100"
                                                onChange    = {formik.handleChange}
                                                value       = {formik.values.earningWay.earningValueRange[idx].minValue}
                                            />
                                        </div>
                                        <div className='mr_3 ml_3' style={{marginTop: '-5px', marginLeft: '-6px'}}>
                                            <CustomCheckBox 
                                                className = " align-self-center"
                                                value     = {formik.values.earningWay.earningValueRange[idx].check}
                                                onChange  = {(e)=>{
                                                    if(formik.values.earningWay.earningValueRange[idx].check === true || formik.values.earningWay.earningValueRange[idx].check === 'true'){
                                                        formik.values.earningWay.earningValueRange[idx].maxValue = ''
                                                        formik.values.earningWay.earningValueRange[idx].check = false
                                                    }
                                                    else formik.values.earningWay.earningValueRange[idx].check = true
                                                    
                                                    formik.setValues({...formik.values})
                                                }}
                                            />
                                            <div className="text-center mt_6 color-neutral100">-</div>
                                        </div>
                                        <div className='mr_40' style={{width: 100}}>
                                            <CustomTextField 
                                                label     = "Max"
                                                icon        = {<div className=''>$</div>}
                                                position  = "end"
                                                width     = "100px"
                                                name      = {`earningWay.earningValueRange.[${idx}].maxValue`}
                                                type      = "number"
                                                className = {idx===0?(formik.values.earningWay.earningValueRange[idx].check ? "mr_8" : "mr_8 disabled"):(formik.values.earningWay.earningValueRange[idx].check ? "mr_8" : "mr_8 disabled")}
                                                onChange  = {formik.handleChange}
                                                value     = {formik.values.earningWay.earningValueRange[idx].maxValue}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex mt_8 wrapRemove">
                                       <div className="Body14R color-neutral60 mt_0">Points awarded:</div>
                                        <input 
                                                type      = "number"
                                                name      = {`earningWay.earningValueRange.[${idx}].points`}
                                                onChange  = {formik.handleChange}
                                                value     = {formik.values.earningWay.earningValueRange[idx].points ? formik.values.earningWay.earningValueRange[idx].points : 0}
                                                className = "outline-none underline  Body14R border-none color-neutral100 ml_5 background-inherit"
                                        />
                                        {/* <div className="ml_4 color-neutral100 Body14R">Points</div> */}
                                    </div>
                                </div>)}
                                {formik.errors?.earningWay?.earningValueRange && <p className='error'>
                                    Please add a range from below
                                </p>}
                                {formik.values?.earningWay?.earningType == Campaign.EarningTypes.RANGE  && <div className="mt_8">
                                    <CustomButton className="w-100 mb_20"
                                        btntext = "Add Range"
                                        icon    = {<SvgIcons.Add color={ColorSchemeCode.primary50}/>}
                                        varient = "tertiary"
                                        onClick = {addPointRangesFunc}
                                        size = "l"
                                    />
                                </div>}
                            </div>

                        {
                            (formik.values?.earningWay?.earningType == Campaign.EarningTypes.RANGE) && 
                            <div>
                                 <CustomTextArea
                                        label       = {'Description (optional)'}
                                        name        = 'earningWay.description'
                                        placeholder = "Enter Short Description about the product?"
                                        className   = "w-100"
                                        background  = '#F5F7FC'
                                        value       = {formik.values?.earningWay?.description}
                                        onChange    = {formik.handleChange}
                                        onBlur      = {formik.handleChange}
                                    />
                            </div>
                            

                        }

                    </RadioGroup>
                </div>
            </form>
        </div>
        </Fade>
    )
}


export default EarningWays










