import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/services/chatrace'

const GetPageInfo = async ({query}) => await axios.get(env.API_URL+service+'/getInfo?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetCustomFields = async ({query}) => await axios.get(env.API_URL+service+'/getCustomFields?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetFlows = async ({query}) => await axios.get(env.API_URL+service+'/getFlows?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const UchatService = {
    GetPageInfo     : Handlers.Services(GetPageInfo),
    GetCustomFields : Handlers.Services(GetCustomFields),
    GetFlows        : Handlers.Services(GetFlows),
}



export default UchatService
