const TransactionTypes = {
    AMOUNT         : 'amount',
    BONUS          : 'bonus',
    GIVEAWAY       : 'giveaway',
    FIXED_AMOUNT   : 'fixed amount',
    SIGNUP_POINT   : 'signupPoint'
}

const Transactions = {
    TransactionTypes
}

export default Transactions