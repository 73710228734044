import React, { useContext } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import SettingHeader from '../../components/setting/SettingHeader';
import { LayoutContext } from '../../context/Layout.context';
import EmailMain from './subpages/Email/EmailMain';
import Create from './subpages/Notification/Create';
import FlowTest from './subpages/Notification/FlowTest';
import FlowUpdate from './subpages/Notification/FlowUpdate.js';
import Table from './subpages/Notification/Table.js';
import Listing from './subpages/Notification/Listing';
import SMSList from './subpages/SMS/SMSList';
import SMSCreate from './subpages/SMS/CreateSMS';
import CommunicationDashboard from './subpages/CommunicationDashboard';
import Automation from '../automation/Automation';
import PushCreate from './subpages/Notification/PushCreate';
import PushNotificationList from './subpages/Notification/PushNotificationList';



export default function Setting(props) {
    return (
        <div id="communication">
                <div>
           
                </div>
                <div className="component">
                    <Switch>    
                        {/* <Route path={props.match.url + '/email'} component={EmailMain}/>
                        <Route path={props.match.url + '/sms'} component={SMSMain}/> */}
                        {/* <Route path={props.match.url + '/notification'} component={NotificationMain}/> */}
                        <Route exact path = {props.match.url + '/'} component                    = {CommunicationDashboard}/>
                        <Route exact path = {props.match.url + '/notification/create'} component = {Create}/>
                        <Route exact path = {props.match.url + '/notification'} component        = {Listing}/>
                        <Route exact path = {props.match.url + '/push/create'} component         = {PushCreate}/>
                        <Route exact path = {props.match.url + '/push/create/:id'} component     = {PushCreate}/>
                        <Route exact path = {props.match.url + '/push'} component                = {()=><PushNotificationList {...props} />}/>
                        <Route path       = {props.match.url + '/automation'} component          = {Automation}/>
                        <Route exact path = {props.match.url + '/flowtest'} component            = {FlowTest}/>
                        <Route exact path = {props.match.url + '/flowupdate'} component          = {FlowUpdate}/>
                        <Route exact path = {props.match.url + '/table'} component               = {Table}/>
                        <Route exact path = {props.match.url + '/sms'} render                    = {()=><SMSList {...props} />}/>
                        <Route exact path = {props.match.url + '/sms/create'} component          = {SMSCreate}/>
                        <Route exact path = {props.match.url + '/sms/create/:id'} component      = {SMSCreate}/>
                        
                        

                        {/* <Redirect path = {props.match.url+ '/*'} to = {props.match.url+ '/'} /> */}
                        {/* <Redirect path = {props.match.url} to       = {props.match.url+ '/'} /> */}
                    </Switch>
                </div>
        </div>
    )
}


