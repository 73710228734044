import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FitContentInput from '../../../../components/FitContentInput'
import { AgencyContext } from '../../../../context/Agency.context'
import { ColorSchemeCode } from '../../../../enums/ColorScheme'
import { utils } from '../../../../utils'
import { TwitterPicker, CirclePicker } from 'react-color';
import CustomTextField from '../../../../components/CustomTextField'
import CustomSelect from '../../../../components/CustomSelect'
import { SvgIcons } from '../../../../icons'
import CustomButton from '../../../../components/CustomButton'
import CustomsModal from '../../../../components/CustomModal'
import FromModal from './FromModal'
import CustomMenu from '../../../../components/CustomMenu'
import { CircularProgress, Collapse, TextField } from '@mui/material'
import SheduleNotifcationModal from './SheduleNotifcationModal'
import { UserContext } from '../../../../context/User.context'
import { BrandContext } from '../../../../context/Brand.context'
import { CampaignContext } from '../../../../context/Campaign.context'
import {LayoutContext} from '../../../../context/Layout.context';
import Autocomplete from '@mui/material/Autocomplete';
import { BrandService, CampaignService, NotificationService, PassService, TierService, TransactionService } from '../../../../services'
import { Brand, PassDesign } from '../../../../enums'
import { NotificationVld } from '../../../../validation'
import ClickOutside from '../../../../utils/ClickOutside';
import { showToaster } from '../../../../utils/utils';
import localforage from 'localforage';

import CustomTextArea from '../../../../components/CustomTextArea';

import { useHistory } from 'react-router-dom'
import Select from "react-select";
import { PassTypes } from '../../../../utils/Options'

function Create() {

    const agency   = useContext(AgencyContext)
    const brand    = useContext(BrandContext)
    const campaign = useContext(CampaignContext)
    const user     = useContext(UserContext)

    const history = useHistory()

    const [color, setColor] = React.useState('')
    const [state, setState] = React.useState({
        from        : [],
        to          : [],
        fromOpen    : false,
        sheduleOpen : false,
        menuOpen    : false,
        dropdown    : false,
        dropdownOne : false,
        dropdownTwo : false,
        menuEvent   : '',
        fromArr     : [],
        fromAgency  : '',
        sendnow     : true
    })
    const [subBrand, setSubBrand] = useState()
    const [subBrandAvatar, setSubBrandAvatar] = useState()
    const [subBrandId, setSubBrandId] = useState()
    const [editStatus, setEditStatus] = useState()
    const [backgroundImagePosition, setBackgroundImagePosition] = useState('');
    const layout = useContext(LayoutContext)

    const [filteredCustomers, setFilteredCustomer] = useState()
    const [customers, setCustomers]                = useState([])
    const [campaigns, setCampaigns]                = useState([])
    const [passes, setPasses]                      = useState([])
    const [tiers, setTiers]                        = useState([])
    const [transactions, setTransactions]          = useState([])
    const [selected, setSelected]                  = useState({
        customer       : '',
        campaign       : {},
        activeCustomer : ''
    })

    


    const initState = {
        title         : 'Notification Title',
        description   : '',
        color         : '#FFFFFF',
        background    : '#00B1FF',
        link          : '',
        timezone      : '',
        media         : '',
        toggle        : false,
        tiers         : [],
        tierId        : [],
        subscribedDate: [],
        scheduleDate  : '',
        shedule       : '',
        from          : '',

        loader   : false,
        apiError : ''
    }

    
    const handleSubmit = async() => {
        // console.log('1');
        setState({...state, loader: true})
    
        let media = ''
        const desiredPath = `${user._id}/${formik.values.title}`
        // console.log('2');

        if(formik.values.media && typeof formik.values.media === 'object'){
            media = await utils.uploadImage({file: formik.values.media, desiredPath})
            if(media && media.response)
            if(media && media.error) return setState({...state, loader: false, apiError: media.error.message})
        }

        let selectedId = ''
        let selectedName = ''

        // console.log('3');


        if(subBrandId && subBrandId.length>0){
            subBrandId.map((value, index)=>{
                if(formik.values.from === subBrand[index].name){
                    selectedId   = value
                    selectedName = subBrand[index].name
                } 
            })
        }


        // console.log('4');

        const payload = {
            agencyId    : agency._id,
            brandId     : selectedId === agency._id ? brand._id : selectedId,
            brandName   : selectedId === agency._id ? brand.brandName : selectedName,
            campaignId  : campaigns[0]._id,
            imageUrl    : media && media.response && media.response.data,
            linkUrl     : formik.values.link === "" ? 
                          formik.values.link :
                          (formik.values.link.includes("https://") || formik.values.link.includes("http://")) ? formik.values.link : "https://" + formik.values.link,
            // formik.values.link === "" ? "" : "https://"+formik.values.link,
            description  : formik.values.description,
            sendTo       : [],                          //
            title        : layout.elements.editTitle,         //
            displayTitle : formik.values.title,         //

            foregroundColor : formik.values.color,
            backgroundColor : formik.values.background,          

            // timezone       : '',
            // tiers          : [],
            // subscribedDate : [],


            // shedule        : '',
        }

        // console.log('5')

        if(!agency._id) delete payload.agencyId
        if(brand.brandType != Brand.BrandTypes.SHARED_LOYALITY) payload.brandId = brand._id; payload.brandName = brand.brandName
        if(!agency && payload.agencyId) delete payload.agencyId;
       
        // console.log('6')

        if(!state.sendnow) payload.scheduleDate       = new Date(formik.values.scheduleDate).valueOf()
        if(!state.sendnow) payload.scheduleDateString = new Date(formik.values.scheduleDate)

        // console.log('7')
        if(filteredCustomers && filteredCustomers.length > 0) filteredCustomers.map((element, idx)=>{ payload.sendTo.push(element.userId)})
        else customers.map((element, idx)=>{ payload.sendTo.push(element.userId)})


        // console.log('payload', payload);
        
        const { response, error } = await NotificationService.Create({toaster:true, payload})
        setState({...state, loader: false})
        if(response){
            
            setState({...state, loader: false})
            const location = window.location.pathname.split('/')
            location.pop()
            // history.push(location.join('/') + '/notification')
            history.push({
                pathname: location.join('/') + '/notification',
                state: 'reload'
            })
        }
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues    : initState,
        onSubmit         : handleSubmit,
        validationSchema : NotificationVld.Create,
        // isInitialValid   : venueData && venueData.edit
    })


    useEffect(() => {
        const color = utils.hexTohsl(agency.agencyColor)
        setColor("hsl("+color.h+","+color.s+"%,"+color.l+"%,"+0.1+")")
    }, [agency])

    const handleChange = (e) =>{
        setState({...state, menuEvent: e.currentTarget, menuOpen: true})
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [data, setData] = React.useState({
        selectAll: false,
        agency: [
            {
                name  : 'Shire Rewards',
                url   : 'https://images.unsplash.com/photo-1633372705137-681fdf6ceb7c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=362&q=80',
                check : true
            }
        ],
        subBrands: [
            {
                name  : 'Starbucks',
                url   : 'https://images.unsplash.com/photo-1633373336822-7bd3c52dc15f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=387&q=80',
                check : true
            },
            {
                name  : 'Mcdonalds',
                url   : 'https://images.unsplash.com/photo-1633404599765-b11f68e5f2ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80',
                check : false
            },
            {
                name  : 'Dominos',
                url   : 'https://images.unsplash.com/photo-1633401601315-c678fc282822?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=387&q=80',
                check : false
            },
            {
                name  : 'Burger King',
                url   : 'https://images.unsplash.com/photo-1633114128174-2f8aa49759b0?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80',
                check : false
            },
            {
                name  : 'Dunkin Donuts',
                url   : 'https://images.unsplash.com/photo-1633261280581-5b09a7c88f50?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=951&q=80',
                check : false
            }
        ]
    })

    const [result, setResult] = React.useState(data.subBrands)

    useEffect(() => {
        let tempArr = []
        for (const i of result) {
            if(i.check) tempArr.push(i.name)
        }
        setState({...state, fromArr: tempArr})
        
        if(tempArr.length === result.length) setData({...data, selectAll: true}) 
        else setData({...data, selectAll: false})
        
    }, [result])

    const getPass = async () => {
        const query = {brandId: (brand.brandType === Brand.BrandTypes.SUB_BRAND) ? brand.parentBrandId : brand._id, delete: false}
        const {error, response} = await PassService.GetQueriedPass({query})
        // console.log('response',response, response?.data?.length);
        response && setCustomers(response.data)
        if(error) return
        return response.data[0]
    }

    const getCampaigns = async () => {
        const query = {
            brandId      : (brand.brandType === Brand.BrandTypes.SUB_BRAND) ? brand.parentBrandId : brand._id,
            campaignType : 5
        }
        const {error, response} = await CampaignService.GetQueriedCampaign({query})
        if(error) return;
        setCampaigns(response.data)
        setSelected({...selected, campaign: response.data[0]})
        await getTiers(response.data[0]);

    }

    const getTiers = async (campaign) => {
        const query = {brandId :  (brand.brandType === Brand.BrandTypes.SUB_BRAND) ? brand.parentBrandId : brand._id, campaignId : campaign._id, delete : false }
        const {error, response} = await TierService.Get({query})
        if(error) return;
        setTiers(response.data);
    }

    const getSubBrand = async () => {

        let arr = []
        let arr1 = []
        const query = { parentBrandId: brand._id, brandType: Brand.BrandTypes.SUB_BRAND , delete: false}
        const { response, error } = await BrandService.Get({query})

        // if(response) setSubBrand(response.data)
        if(response){
            response.data.map((value, index)=>{
                arr.push({name: value.brandName, image: value.brandLogo})
                arr1.push(value._id)
            })
        }
        arr.push({name: agency.agencyName, image: agency.logo})
        arr1.push(agency._id)

        

        setSubBrand([...arr])
        let options = []
        arr.map((v,i)=> (
            options.push({value: v.name, label: <div className="d-flex align-items-center"><img className="borderRadius-50 mr_8" height="24px" width="24px" src={v.image}></img><div className="fs-14 fw-4 color-c000000">{v.name}</div></div>})
        ))
        setAutoComplete({...agency, options: options})
        setSubBrandId([...arr1])

    }
    
    const onLoad = async () => {
        if(brand.brandType !== 'normal') getSubBrand()
        getPass()
        getCampaigns()
    }

    useEffect(onLoad, [])

    const handleFilterTier = (value, index) => {
            let tempCustomers = [];
            let tiersSelected = formik.values.tierId.length > 0 ? formik.values.tierId : [];
            if(!(formik.values.tierId.includes(value._id))) tiersSelected.push(value._id)
            if(index || index === 0) tiersSelected.splice(index, 1);
            formik.setValues({...formik.values, tiers: [...formik.values.tiers, value.name]}); 
            if(!(formik.values.tierId.includes(value._id))) formik.setValues({...formik.values, tierId: [...formik.values.tierId, value._id]}); 

            tiersSelected && tiersSelected.length > 0 && tiersSelected.forEach(element => { 
                customers.map((cust, idx)=>{
                    passes.map((pass, idx)=>{
                        if(pass && pass[pass.passType].currentTier && pass[pass.passType].currentTier.currentTierId === element && pass.userId === cust.user._id)
                        {
                            tempCustomers.push(cust)
                        }
                    })
                })
            });

            // console.log('tempCustomers 1', tempCustomers)
            setFilteredCustomer(tempCustomers)
    }

    const handleFilterDate = (value, index) => {
        // console.log('values', index, value);
        let selectedDate = new Date(value).valueOf();
        let date         = new Date(selectedDate).getDate()
        let month        = new Date(selectedDate).getMonth()
        let year         = new Date(selectedDate).getFullYear()

        // console.log('data', date, month, year);

        formik.setValues({...formik.values, subscribedDate: [value]})
        let tempCustomers = [];
        let filterArray = filteredCustomers && filteredCustomers.length > 0 ? filteredCustomers : customers

        if(index || index === 0 ) {
            customers.map((cust, idx)=>{
                   tempCustomers.push(cust)
            })
            // console.log('tempCustomers 2', tempCustomers)
            setFilteredCustomer(tempCustomers)
        }
        else 
        {
            filterArray.map((cust, idx)=>{
                // console.log('new Date(cust.created)', new Date(cust.created).getDate(), new Date(cust.created).getMonth() ,  new Date(cust.created).getFullYear())
               if(new Date(cust.created).getDate() >= date && new Date(cust.created).getMonth() >= month && new Date(cust.created).getFullYear() >= year) 
               {
                   tempCustomers.push(cust)
               }
            })
            // console.log('tempCustomers 3', tempCustomers)
            setFilteredCustomer(tempCustomers)
        }

    }

    const BackgroundColor = [
        {color: '#05080C'},
        {color: '#F1247E'},
        {color: '#00B1FF'},
        {color: '#FAB431'},
        {color: '#5F64D5'},
        {color: '#C12D63'},
        {color: '#256164'},
        {color: '#C41B1C'},
        {color: 'linear-gradient(167.95deg, #00F5A0 0%, #00D9F5 100.02%)'}, 
        {color: 'linear-gradient(167.98deg, #152331 0.02%, #2E4D6B 0.03%, #000000 99.98%)'},
        {color: 'linear-gradient(167.95deg, #EC008C -0.02%, #FC6767 84.24%)'},
        {color: 'linear-gradient(167.95deg, #00C6FF 0%, #0072FF 100.02%)'},
        {color: 'linear-gradient(167.95deg, #FDC830 0%, #F37335 100.02%)'},
        {color: 'linear-gradient(167.95deg, #4E54C8 0%, #8F94FB 100.02%)'},
        {color: 'linear-gradient(167.95deg, color(neutral90) 0%, #DD1818 100.02%)'},
        {color: 'linear-gradient(167.95deg, #FFAFBD 0%, #FFC3A0 100.02%)'},
        {color: 'linear-gradient(167.95deg, #CC2B5E 0%, #753A88 100.02%)'},
        {color: 'linear-gradient(167.95deg, #134E5E 0%, #71B280 100.02%)'},
        {color: 'linear-gradient(90deg, #E52D27 0%, #B31217 100%)'},
        {color: agency.agencyColor ? agency.agencyColor : '#00DCE9'},
    ]

    const [autoComplete, setAutoComplete] = useState({
        itemtitle: "",
        multi: false,
        multiValue: [],
        options: []
      })

    const onTitleChange = (e, value) => {
        setAutoComplete({ [e.target.name]: e.target.value });
        setAutoComplete({ multiValue: e.target.value });
    }
      
    const handleOnChange = (value) => {
        // setAutoComplete({ multiValue: value });
        formik.setValues({...formik.values, from: value.value})

        subBrand.map((v, i) => {
            if(v.name === value.value) setSubBrandAvatar(v.image)
        })
    }

    useEffect(()=>{
        if(formik.values.title == "" && !editStatus) { 
            showToaster({position: 'bottom-right', title: 'Title Error', message:  'Title cannot be empty' , severity: 'error'})
            formik.setValues({...formik.values, title : 'Notification Title'})
        }
    },[editStatus])

    const handleSizeCheck = () => {
      const image = document.getElementById('backgroundImage')
      if(image != null){
        image.onload=()=>{
            var width = image.width;
            var height = image.height;
            // if(height == 100 && backgroundImagePosition == ""){
            //     setBackgroundImagePosition('vertical')
            // }
            if(height > width){
                setBackgroundImagePosition('vertical')
            }
            else if(width >= height && height != 100){
                setBackgroundImagePosition('horizontal')
            }
            
          }
      }
    }

    useEffect(()=>{
        handleSizeCheck();
    },[formik.values.media])


    useEffect(()=>{
        layout.setLayout({
            showCollapseIcon: false,
            edit            : true,
            borderBottom    : true,
            placeholder     : 'story',
            backTitles  : ['Communication', 'Stories'],
            button          : <div className="d-flex align-items-center">
                            <CustomButton
                                onClick={()=>{
                                    const location = window.location.pathname.split('/')
                                    location.pop()
                                    history.push(location.join('/'))
                                }}
                                varient="secondary" 
                                btntext={"Cancel"}
                            />
                            <CustomButton
                                disabled = {state.loader}
                                icon     = {state.loader ? <CircularProgress size={20} color={'inherit'}/>: <SvgIcons.SendIcon color={'white'}/>}
                                btntext  = {state.sendnow ? "Send Now" : "Schedule"} className = "ml_4"
                                onClick={handleSubmit}
                            />
                            <CustomButton
                                onClick   = {handleChange}
                                icon      = {<SvgIcons.ArrowUnExpand color={ColorSchemeCode.white}/>}
                                className = "ml_4"
                            />
                        </div>
        });
    },[state, campaigns, state.processing,layout.elements.editTitle])


    useEffect(()=>{
        layout.setLayout({
            editTitle : "Stories Title"
        })
    },[])


    return (
        <div id="Notifications">
            {console.log('setCustomers', customers)}
            <form onSubmit={formik.handleSubmit}>
            <div className="d-flex">
                <div className="col-4 pl_0 pr_0 colDiv">
                    <div className="colDivScroll">
                        {/* <div className="pr_16 middle align-items-start leftHeaderPart">
                            <FitContentInput 
                                // name      = "title"
                                onClick       = {()=>formik.setValues({...formik.values,toggle: true})}
                                value         = {formik.values.title}
                                onChange      = {(value)=>{formik.setValues({...formik.values, title: value, toggle: true} )}}
                                icon          = {{color: ColorSchemeCode.neutral80, class: 'pt_3', width: '20px', height: '20px'}}
                                setEditStatus = {setEditStatus}
                            />
                        </div>  */}
                        {/* <div className="error" style={{marginTop: '-16px'}}>{formik.errors.title}</div> */}
                        <div className="ml_16 d-flex align-items-center">
                            <div className="fs-14 color-c000000">From:</div>
                            
                            {/* <div className="autocomplete ml_8 w-100">
                                <Autocomplete
                                    name      = "from"
                                    value     = {formik.values.from}
                                    onChange={(event, newValue) => {
                                        // newValue ? formik.setValues({...formik.values, from: newValue.name}) : formik.setValues({...formik.values, from: ''})
                                        newValue ? formik.setValues({...formik.values, from: newValue}) : formik.setValues({...formik.values, from: ''})
                                    }}
                                    // options={[data.agency[0], ...data.subBrands]}
                                    options={subBrand ? subBrand : []}
                                    getOptionLabel={(option) => option.name || option}
                                    className="fs-14 fw-4 color-c000000"
                                    renderInput={(params) => (
                                        <TextField
                                         {...params} variant="standard" placeholder="Select Brand or Agency..." className="border-none w-100 fs-14 fw-4 color-c000000" />
                                    )}
                                />


                            </div> */}

                            {brand.brandType === Brand.BrandTypes.SHARED_LOYALITY ? <div className="w-100 autocomplete z-index-100">
                                <Select
                                    className="w-80"
                                    multi={autoComplete.multi}
                                    options={autoComplete.options}
                                    onChange={(e)=>handleOnChange(e)}
                                    value={autoComplete.multiValue}
                                    showNewOptionAtTop={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary25: ColorSchemeCode.bgBlueSecondary,
                                          primary: "#9CBCE8",
                                        //   primary25: agency.agencyColor ? color : ColorSchemeCode.cF6F9FE,
                                        //   primary: agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                        },
                                      })}
                                />
                            </div>  
                            
                            :

                            <div className="ml_8">{agency?.agencyName || brand.brandName}</div>
                        
                        
                        }
                            
                            {/* {data.agency[0].check && <div className="p_6 borderRadius-40 fs-14 fw-5 ml_8 cp" 
                                onClick={()=>setState({...state, fromOpen: true})}
                                style={{
                                    color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                    backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                                    }}>{ 
                                            data.agency[0].name
                                    }</div>}
                            {state && state.fromArr.length > 0 && <div className="p_6 borderRadius-40 fs-14 fw-5 ml_8 cp" 
                                onClick={()=>setState({...state, fromOpen: true})}
                                style={{
                                    color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                    backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                                    }}>{
                                        state.fromArr.length > 2 ? 
                                        state.fromArr[0]+", "+state.fromArr[1]+" and "+(state.fromArr.length-2)+" more"
                                        :
                                        state.fromArr.map((value, index)=>(
                                            state.fromArr.length > 1 ?
                                            index === 0 ? value : ", "+value
                                            :
                                            value
                                        ))
                                    }</div>}
                            
                            {!(state.fromArr.length > 0) && !(data.agency[0].check) && <div className="p_6 borderRadius-40 fs-14 fw-5 ml_8 cp" 
                                onClick={()=>setState({...state, fromOpen: true})}
                                style={{
                                    color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                    backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                                    }}>{
                                        <SvgIcons.Add/>
                                    }</div>} */}
                        </div>
                        {/* {formik.values.from && <div className="p_6 borderRadius-40 fs-14 fw-5 ml_8 cp w-fit-content-content" 
                            style={{
                                color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                                }}>{ 
                                        formik.values.from
                                }</div>} */}
                        <div className="bar mt_16 mb_16"></div>
                        <div className="ml_16 d-flex align-items-center space-between mt_32 mr_16">
                            <div className="d-flex">
                                <div className="fs-14 color-c000000">To:</div>
                                <div className="fs-14 color-c7A869A ml_8">{
                                (filteredCustomers && filteredCustomers.length>0) ? 
                                filteredCustomers.length : 
                                'All ' || customers.length
                                } Customers</div>
                            </div>
                            
                            <div className="w-45">
                            
                            <div className="position-relative text-right">
                                <CustomButton btntext="Add Condition" icon={<SvgIcons.PlusIcon/>} onClick={()=>setState({...state, dropdown: !(state.dropdown) , dropdownOne : false, dropdownTwo: false})} />

                                <ClickOutside className="" onClick={()=>{setState({...state, dropdown : false})}}>
                                {
                                    state.dropdown &&
                                    <div className="border p_8 z-index-1 position-absolute bg-color-white w-100 mt_3  borderRadius-4">
                                        {/* <div onClick={()=>setState({...state, dropdownOne: !(state.dropdownOne)})} className="d-flex space-between cp fs-14 color-c000000 mb_12">Tiers {state.dropdownOne ? <div><SvgIcons.ArrowUnExpand /></div> : <div className="rotate-right"><SvgIcons.ArrowUnExpand /></div>}</div> */}
                                        
                                        {!state.dropdownOne && !state.dropdownTwo && <div onClick={()=>setState({...state, dropdownOne: !(state.dropdownOne)})} className="d-flex space-between cp fs-14 color-c000000 mb_12">Tiers</div>}

                                        {
                                                tiers && tiers.length > 0 && state.dropdownOne && tiers.map((value, index)=>(
                                                    <div className="d-flex space-between ml_8 mb_8 fs-14 cp color-c000000" 
                                                    onClick={()=>{
                                                        if(!(formik.values.tiers.includes(value.name))){
                                                            handleFilterTier(value)
                                                        }
                                                        else{
                                                            setState({...state, dropdown: false, dropdownOne: false})
                                                        }
                                                    }
                                                }>
                                                        {value.name}
                                                        {formik.values.tiers.includes(value.name) && <SvgIcons.IconTick/>}
                                                    </div>
                                                ))
                                            }

                                        {/* <Collapse in={state.dropdownOne} timeout="auto" unmountOnExit>
                                            {
                                                tiers && tiers.length > 0 && tiers.map((value, index)=>(
                                                    <div className="d-flex space-between ml_8 mb_8 fs-14 cp color-c000000" 
                                                    onClick={()=>{
                                                        if(!(formik.values.tiers.includes(value.name))){
                                                            handleFilterTier(value)
                                                        }
                                                        else{
                                                            setState({...state, dropdown: false, dropdownOne: false})
                                                        }
                                                    }
                                                }>
                                                        {value.name}
                                                        {formik.values.tiers.includes(value.name) && <SvgIcons.IconTick/>}
                                                    </div>
                                                ))
                                            }
                                        </Collapse> */}
                                        
                                        {/* <div onClick={()=>setState({...state, dropdownTwo: !(state.dropdownTwo)})} className="d-flex space-between cp fs-14 color-c000000 ">Subscribed {state.dropdownTwo ? <div><SvgIcons.ArrowUnExpand /></div> : <div className="rotate-right"><SvgIcons.ArrowUnExpand /></div>}</div> */}
                                        
                                        {!state.dropdownTwo && !state.dropdownOne && <div onClick={()=>setState({...state, dropdownTwo: !(state.dropdownTwo)})} className="d-flex space-between cp fs-14 color-c000000 ">Subscribed</div>}
                                        
                                        {state.dropdownTwo && <div>
                                            <CustomTextField
                                                    className = "w-100"
                                                    type      = "date"
                                                    name      = "subscribedDate"
                                                    value     = {formik.values.subscribedDate}
                                                    onChange  = {(e)=>{
                                                        if(!(formik.values.subscribedDate.includes(e.target.value))){
                                                            handleFilterDate(e.target.value)
                                                        }
                                                        else{
                                                            setState({...state, dropdown: false, dropdownOne: false})
                                                        }
                                                    }}
                                                    // onChange    = {formik.handleChange}
                                                    // onChange    = {(e)=> {
                                                    //     !(formik.values.subscribedDate.includes(e.target.value)) && formik.setValues({...formik.values, subscribedDate: [...formik.values.subscribedDate, e.target.value]}); setState({...state, dropdown: false, dropdownTwo: false})}}
                                                    inputProps  = {{ onFocus: formik.handleBlur }}
                                                    error       = {formik.touched.subscribedDate && formik.errors.subscribedDate}
                                                    helperText  = {formik.touched.subscribedDate && formik.errors.subscribedDate ? formik.errors.subscribedDate : ''}
                                                />
                                                {/* <DateRangePicker onEvent={handleEvent} onCallback={handleCallback}>
                                                    <input />
                                                </DateRangePicker> */}
                                            </div>}
                                        
                                        {/* <Collapse in={state.dropdownTwo} timeout="auto" unmountOnExit>
                                            <CustomTextField
                                                className = "w-100"
                                                type      = "date"
                                                name      = "subscribedDate"
                                                value     = {formik.values.subscribedDate}
                                                onChange  = {(e)=>{
                                                    if(!(formik.values.subscribedDate.includes(e.target.value))){
                                                        handleFilterDate(e.target.value)
                                                    }
                                                    else{
                                                        setState({...state, dropdown: false, dropdownOne: false})
                                                    }
                                                }}
                                                // onChange    = {formik.handleChange}
                                                // onChange    = {(e)=> {
                                                //     !(formik.values.subscribedDate.includes(e.target.value)) && formik.setValues({...formik.values, subscribedDate: [...formik.values.subscribedDate, e.target.value]}); setState({...state, dropdown: false, dropdownTwo: false})}}
                                                inputProps  = {{ onFocus: formik.handleBlur }}
                                                error       = {formik.touched.subscribedDate && formik.errors.subscribedDate}
                                                helperText  = {formik.touched.subscribedDate && formik.errors.subscribedDate ? formik.errors.subscribedDate : ''}
                                            />
                                            {/* <DateRangePicker onEvent={handleEvent} onCallback={handleCallback}>
                                                <input />
                                            </DateRangePicker> */}
                                        {/* </Collapse> } */}
                                    </div>
                                }
                                </ClickOutside>
                            </div>

                            
                            </div>

                            
                            
                        </div>
                        
                        <div className="d-flex flex-wrap mt_8">
                            {formik.values.subscribedDate.length>0 && formik.values.subscribedDate.map((value, index)=>(
                            <div className="d-flex p_6 borderRadius-40 w-fit-content-content fs-14 fw-5 ml_8 mb_8 cp" 
                                style={{
                                    color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                    backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                                    // }}>{"Subscribed date after "+value}
                                    }}>{"Subscribed date after "+ new Date(value).getDate() + " " + monthNames[new Date(value).getMonth()] + " " + new Date(value).getFullYear()}
                                    <div onClick={()=>{
                                        handleFilterDate(value, index)
                                        formik.values.subscribedDate.splice(index, 1); 
                                        formik.setValues({...formik.values})
                                    }} 
                                    className="ml_10 cp"><SvgIcons.CrossIcon color={agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED}/></div>
                                    </div>
                            ))}
                            {formik.values.tiers.length>0 && formik.values.tiers.map((value, index)=> (
                            <div className="d-flex p_6 borderRadius-40 w-fit-content-content fs-14 fw-5 ml_8 mb_8 cp" 
                                style={{
                                    color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                    backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                                    }}>{"Tier is "+value}
                                    <div onClick={()=>{
                                        handleFilterTier({_id: formik.values.tierId[index], name: formik.values.tiers[index]}, index )
                                        formik.values.tiers.splice(index, 1); 
                                        formik.values.tierId.splice(index, 1); 
                                        formik.setValues({...formik.values}); 
                                    }} 
                                    className="ml_10 cp"><SvgIcons.CrossIcon color={agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED}/></div>
                                    </div>
                            ))}
                        </div>

                        <div className="bar mt_16 mb_16"></div>
                        <div className="ml_16 mr_16 mb_16 w-95 pr_16">
                            <CustomTextArea 
                                minHeight  = '150px'
                                maxHeight  = '150px'
                                name       = "description"
                                label      = "description"
                                width      = "100%"
                                rows       = "5"
                                classes    = "w-100"
                                onChange   = {formik.handleChange}
                                onClick    = {()=>formik.setValues({...formik.values,toggle: false})}
                                value      = {formik.values.description}
                                error      = {formik.touched.description && formik.errors.description}
                                helperText = {formik.errors.description && formik.errors.description}
                                // style       = {{outline : agency.agencyColor && '1px solid ' + agency.agencyColor }}
                                // classes     = "CustomTextarea resize-none borderRadius-4 p_12 w-100 fs-14"
                            />
                            {/* <div className="error">{formik.errors.description}</div> */}
                        </div>
                        {/* <div className="m_16">
                            <div className="borderRadius-4 p_12 w-100 border color-c7A869A fs-14">Text Color<CirclePicker color={formik.values.color} colors={[ agency.agencyColor ? agency.agencyColor : '#00DCE9', '#05080C', '#F1247E', '#00B1FF', '#FAB431', '#5F64D5', '#C12D63', '#256164','#C41B1C']} onChange={(e)=>formik.setValues({...formik.values, color: e.hex})} className="w-80 colorpicker" triangle="hide" /></div>
                        </div> */}
                        <div className="borderRadius-4 p_12 border color-c7A869A fs-14  ml_16 mr_16">
                                Background
                                <div className="backgroundColorBox">
                                    {/* <div className="borderRadius-4 p_12 w-100 border color-c7A869A fs-14">Background<CirclePicker colors={[ agency.agencyColor ? agency.agencyColor : '#00DCE9', '#F5F5F5' ,'#05080C', '#F1247E', '#00B1FF', '#FAB431', '#5F64D5', '#C12D63', '#256164','#C41B1C']}  color={formik.values.background} onChange={(e)=>formik.setValues({...formik.values, background: e.hex})} className="w-100 colorpicker" triangle="hide" /></div> */}
                                    {BackgroundColor.map((element, idx)=><div onClick={(e)=>formik.setValues({...formik.values, background: element.color})} key={idx} className={`circle borderRadius-50 mb_16 cp ${formik.values.background == element.color && ' activeColor'}`} style={{width: '30px', height:'30px', background: element.color}}>
                                    </div>)}
                                </div>
                        </div>
                        <div className="bar mt_16 mb_16"></div>
                        <div className="ml_16 d-flex">
                            <div className="fs-14 fw-5 color-c000000">Media</div>
                            <div className="fs-14 fw-5 color-c7A869A ml_5">(Optional)</div>
                        </div>
                        <div className="fs-14 mb_16 ml_16 color-c7A869A mb_16">Share images through your notfications.</div>
                        {
                            formik.values.media === '' ? 
                            <label htmlFor = "coverUpload" className="ml_16 cp d-flex borderRadius-4 imageBG align-items-center pl_12 pt_8 pb_8 pr_12">
                                <div><SvgIcons.Image/></div>
                                <div className="ml_10 fs-14 fw-4 color-c000000">Add Image</div>
                            </label>
                            :
                            <div className="ml_16 d-flex imageBG p_8 borderRadius-4">
                                {
                                    typeof formik.values.media === 'object' ?
                                    <img className={'imageUpload'} src={URL.createObjectURL(formik.values.media)} alt="img"/> 
                                    :
                                    <img className={'imageUpload'} src={formik.values.media} alt="img"/> 
                                }
                                <div className="middle ml_10 cp" onClick={()=>formik.setValues({...formik.values, media: ''})}><SvgIcons.CrossIcon/></div>
                            </div>
                        }
                        <input 
                            id       = "coverUpload"
                            style    = {{display:'none'}}
                            type     = "file"
                            onChange = {(e)=>formik.setValues({...formik.values, media: e.target.files[0]})}
                        />
                        <div className="error">{formik.errors.media}</div>
                        <div className="bar mt_16 mb_16"></div>
                        <div className="ml_16 d-flex mb_16">
                            <div className="fs-14 fw-5 color-c000000">Link</div>
                            <div className="fs-14 fw-5 color-c7A869A ml_5">(Optional)</div>
                        </div>
                        <div className="mb_36"><CustomTextField
                            link
                            // icon        = {<><SvgIcons.Website/> <div className="ml_4">https://</div></>}
                            position    = "start"
                            className   = "w-90 ml_16"
                            paddingLeft = "0"
                            name        = "link"
                            value       = {formik.values.link}
                            // onChange   = {formik.handleChange}
                            onChange   = {(e)=>{
                                // e.target.value.includes("http://www"||"https://www") 
                                // e.target.value.includes("www") 
                                // www.asdas.com
                                // https://www
                                formik.setValues({...formik.values, link: e.target.value})
                            }}
                            inputProps = {{ onFocus: formik.handleBlur }}
                            error      = {formik.touched.link && formik.errors.link}
                            helperText = {formik.touched.link && formik.errors.link ? formik.errors.link : ''}
                        /></div>
                        {/* <div className="bar mt_16 mb_16"></div>
                        <div className="disabled ml_16 mb_16 fs-14 fw-5 color-c000000">Timezone</div>
                        <div><CustomSelect
                            name       = "timezone"
                            value      = {formik.values.timezone}
                            onChange   = {formik.handleChange}
                            error      = {formik.touched.timezone && formik.errors.timezone}
                            helperText = {formik.errors.timezone && formik.errors.timezone}
                            options={
                                <>
                                    <option value={'Send all at same time'}>{'Send all at same time'}</option>
                                    <option value={'Time Travel'}>{'Time Travel'}</option>
                                </>
                            }
                        className="ml_16 w-70 disabled"/></div>
                        <div className="disabled ml_16 mt_8 mr_16 fs-12 color-c7A869A">
                            {
                                formik.values.timezone ===  "Time Travel" ?
                                "Message will be delivered at the same daytime according to the user’s time zone."
                                :
                                "All messages will be sent at the same time and will be delivered as soon as possible."
                            }
                            </div> */}
                            
                    </div>
                    {/* <div className="bar"></div> */}
                    {/* <div className="buttonBox">
                        <div className="float-right d-flex align-items-center">
                            <CustomButton
                            onClick={()=>{
                                const location = window.location.pathname.split('/')
                                location.pop()
                                history.push(location.join('/') + '/notification')
                            }}
                            varient="secondary" 
                            btntext={"Cancel"} className="mt_10 ml_auto mr_4"/>
                            <CustomButton
                            disabled  = {state.loader}
                            icon      = {state.loader ? <CircularProgress size={20} color={'inherit'}/>: <SvgIcons.SendIcon color={'white'}/>}
                            type="submit" 
                            btntext={state.sendnow ? "Send Now" : "Schedule"} className="mt_10 ml_auto"/>
                            <div className=" p_8 arrowColor mt_10 ml_10 middle cp" onClick={handleChange}><SvgIcons.ArrowUnExpand/></div>
                        </div>
                    </div> */}

                    <ClickOutside className="" onClick={()=>setState({...state, menuOpen: false})}>
                        <CustomMenu 
                            menuEvent={state.menuEvent} 
                            open={state.menuOpen} 
                            handleClose={()=>setState({...state, menuOpen: false})}
                            items={[
                                {
                                    name: <>{state.sendnow ? <div className="color-c000000 fs-14" > <SvgIcons.TimeIcon color="#000000"/> Schedule Notification</div> : <div className="color-c000000 fs-14">Send Now</div>}</>,
                                    onClick: ()=>{
                                        if(state.sendnow) setState({...state, menuOpen: false, sheduleOpen: true})
                                        else setState({...state, menuOpen: false, sendnow: true})
                                    }
                                }
                            ]}
                        />
                    </ClickOutside>
                </div>

                <div className="col middle bg-color-cF7F7F7 rightDiv">
                    {
                        
                            formik.values.toggle ?
                            <div className="notiDiv middle">
                                <div className="border borderRadius-8 notiDivWidth bg-color-white">
                                    <div className="d-flex align-items-center mb_8">
                                        <div >
                                            {subBrandAvatar != undefined ? <img className="circle" src={subBrandAvatar} alt=""/> : <div className="circle border bg-color-white"></div>}
                                        </div>
                                        <div className="fs-14 ml_5 color-c000000" 
                                        // style={{color: utils.invertColor(formik.values.background,{ black: 'black', white: 'white' })}}
                                        >{formik.values.from ? utils.capitalize(formik.values.from) : "Brand Name"}</div>
                                    </div>
                                    <div className="mb_4 fs-14 fw-7 color-c000000 word-break ellipses">{formik.values.title}</div>
                                    <div className="fs-12 fw-4 color-c000000 ellipses ">{formik.values.description === '' ? 'Start Typing...' : formik.values.description && formik.values.description.length > 25 ? formik.values.description.slice(0, 25)+"..." : formik.values.description}</div>
                                </div>
                            </div>                    
                            :
                            <>
                            {formik.values.media === '' ?
                            <div className="mobView borderRadius-16 p_24" style={{background: formik.values.background}}>
                                <div className="d-flex align-items-center">
                                    <div >
                                    {subBrandAvatar != undefined ? <img className="circle" src={subBrandAvatar} alt=""/> : <div className="circle border bg-color-white"></div>}

                                        {/* {agency.logo && <img className="circle" src={formik.values.} alt=""/>} */}
                                    </div>
                                    <div className="fs-14 ml_5 color-white" 
                                    // style={{color: formik.values.background.includes('#') ? utils.invertColor(formik.values.background,{ black: 'black', white: 'white' }) : ''}}
                                    >
                                        {formik.values.from ? utils.capitalize(formik.values.from) : "Brand Name"}</div>
                                </div>
                                <div className="description middle fs-16 fw-6 word-break" style={{color: formik.values.color}}>
                                    {formik.values.description ? formik.values.description.length > 150 ? formik.values.description.slice(0, 150)+"..." : formik.values.description : 'Start Typing...'}
                                </div>
                            </div>
                            :
                            <div className="mobView borderRadius-16 position-relative" >
                                <img className={`backgroundImage ${backgroundImagePosition == 'horizontal' && 'blurBg'}`} height={backgroundImagePosition == 'horizontal' ? '100%' : '100%'} width="auto"  id="backgroundImage" src={
                                    typeof formik.values.media === 'object' ?
                                    URL.createObjectURL(formik.values.media)
                                    : formik.values.media
                                } alt="" />
                                <div className="d-flex align-items-center">
                                    <div 
                                    >
                                      {subBrandAvatar != undefined ? <img className="circle" src={subBrandAvatar} alt=""/> : <div className="circle border bg-color-white"></div>}
                                    </div>
                                    <div className="fs-14 ml_5 color-white" >{formik.values.from ? utils.capitalize(formik.values.from) : "Brand Name"}</div>
                                </div>
                                <div className="description middle fs-16 fw-6" >
                                    {backgroundImagePosition == 'horizontal' && <div>
                                    {
                                        typeof formik.values.media === 'object' ?
                                        <img className={'imageMobView '} src={URL.createObjectURL(formik.values.media)} alt="img"/> 
                                        :
                                        <img className={'imageMobView'} src={formik.values.media} alt="img"/> 
                                    }
                                    </div>}
                                </div>
                                    {(formik.values.link || formik.values.description) != "" && <div className="color-white overlayCustom middle pl_8 pb_24 fs-12 pr_12 pt_16 word-break pl_12">
                                        {formik.values.link && <a className="color-white fs-12 fw-5" href={formik.values.link}>Visit Link</a>}
                                        {formik.values.description && <div className="fs-12">{formik.values.description.length > 100 ? formik.values.description.slice(0, 100)+"..." : formik.values.description}</div>}
                                    </div>}
                            </div>}
                            </>
                    }
                    {/* {
                        // formik.values.media === "" &&
                        <div className="p_8 cp borderRadius-4 bellIcon" onClick={()=>formik.setValues({...formik.values, toggle: !formik.values.toggle})} style={{border: formik.values.toggle ? '1px solid #2F80ED':'1px solid #DFE1E6'}}><SvgIcons.BellIcon color={formik.values.toggle ? '#2F80ED':'#DFE1E6'}/></div>
                    } */}
                </div>
            </div>











            <CustomsModal 
                component = {<FromModal state={state} setState={setState} data={data} setData={setData} result={result} setResult={setResult}/>}
                open      = {state.fromOpen}
                onClose   = {()=>setState({...state, fromOpen: false})}
                minWidth  = {'498px'}
            />
            
            
            
            <CustomsModal 
                component = {<SheduleNotifcationModal state={state} setState={setState} formik={formik} />}
                open      = {state.sheduleOpen}
                onClose   = {()=>setState({...state, sheduleOpen: false})}
                minWidth  = {'498px'}
            />
            </form>
        </div>
    )
}

export default Create
