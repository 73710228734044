import React, { useContext, useEffect, useState } from 'react'
import { PngIcons, SvgIcons } from '../../../../icons'
import { AgencyContext } from '../../../../context/Agency.context';
import CustomButton from '../../../../components/CustomButton.js'
import { Brand } from '../../../../enums';
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import localforage from 'localforage'
import { GrowthtoolService } from '../../../../services';
import { useParams } from 'react-router-dom';

export function SetupCompleted() {
   
    const [chkAnoSec, setchkAnoSec] = useState();
    const [brand, setBrand] = useState({})
    const [cwLoader,setcwLoader]=useState(false);
    const history = useHistory();
    const agency = useContext(AgencyContext);
    const params = useParams();
    useEffect(()=>{
        onLoad();
      },[])
  


      const onLoad = async() =>{
        let brand = await localforage.getItem('brand');
        setBrand(brand);
      }

     

      const handleSubmit = async () => {
        setcwLoader(true);
        let campaign =  await localforage.getItem("campaign");
        let brandd = await localforage.getItem("brand");

        let payload2 = {
            brandId           : brandd._id,
            active            :true,
            campaignId        : campaign._id,
            growthtoolType    : 'api',
            flowId            : [""],
            growthtoolName    : 'New Channel',
            points            : false,
            referral          : false
            // landingPage       : {
            //     labels: {
            //         firstName: 'First Name',
            //         lastName: 'Last Name',
            //         email: 'Email',
            //         code: 'Code',
            //         phone: 'Phone Number',
            //         dateOfBirth: 'Date of birth',
            //         referral: 'Referral'
            //     }
            // }
        }

        console.log('payload2 ', payload2)

        const gt = await GrowthtoolService.CreateGrowthTool({payload: payload2})
        console.log('gtss',gt);
        if(gt.response){
            localforage.setItem('channel', [gt.response.data])
        }

        setcwLoader(false);

        
        if(brand.brandType === Brand.BrandTypes.NORMAL && !agency.whiteLabel)
        {
            history.push({pathname: '/'+brand._id + '/setting/billing'});
        }
        else if(brand.brandType === Brand.BrandTypes.NORMAL && agency.whiteLabel)
        {
            history.push({pathname: '/'+brand._id + '/setting/billing'})
        }
        else 
        {
            history.push({pathname: '/'+brand._id, state: {activeBrand: brand}})
        }
  }

    return (
        <>
            <div className='w-100 d-flex '>
                <div className={'top-header-auth d-flex align-items-center'}>
                    <img src={(agency.logo || PngIcons.authLogo)} height="32px" width="auto" className='borderRadius-60 ml_10 ' alt="" />
                </div>

                <div className='w-60 d-flex bg-FFFFFF'>
                    <div className='w-100 d-flex mainSU_C1 bg-FFFFFF' id='mainSU_CU'>
                        <div className={ 'w-20'}>
                                {/* <div className='ParentOfDesgn hpx-76'>
                                <div className='ChildOneOfDesgn hpx-76'>
                                    {state?.components?.map((component, idx) => idx <= state?.components.length && <div className={`${(component.title == selected) ? 'specialRound' : 'simpleRound'}`} ></div>)}
                                </div>
                                <div className='ChildTwoOfDesgn hpx-76 align-flex-start'>
                                    {
                                        state?.components?.map((component, idx) =>
                                            idx <= state?.components.length &&
                                            <>
                                                <p className={`${(selected == component.title) ? 'livePara' : 'unlivePara'} text-left ml_28 `}>{component.title}</p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                             */}
                            

                        </div>
                        <div className='w-50  mr_102 d-flex align-items-center revLthree'>

                            <div id="setUpCompleted">  
                            <div className='w-100 d-flex justify-content-center align-items-center mb_40'>
                                <div className='wpx-80 mr_35 '><img src={PngIcons.MediaBlock} className='w-100' alt='image' /></div>
                                <div><SvgIcons.DoubleArrow/></div>
                                <div className='wpx-80 ml_35'><img src={PngIcons.ManychatLogo1} className='w-100' alt='image' /></div>
                            </div>
                            <h1 className='Heading24M text-align-center'>
                                Setup Completed!
                            </h1>
                            <p className='Body16M color-neutral60 text-align-center'>
                                Place your first test order with CloudWaitress
                            </p>
                            <div className="w-100 gap-12 mt_44 d-flex flex-column">
                                <CustomButton
                                    varient   = "primary"
                                    className = "w-100 hpx-52"
                                    btntext   = "Open Cloudwaitress"
                                    onClick   = {handleSubmit}
                                    disabled  = {cwLoader}
                                    icon={cwLoader && <CircularProgress size={14} color='inherit'/>}
                                  
                                />
                            </div>
                            </div>
                           
                            
                        </div>
                    </div>
                </div>
                <div className= { 'w-40 vh-100 bg-FCFDFD d-flex justify-content-flex-end align-items-center'}>
                <img src={PngIcons.setupComp} className='w-80 revLfour  ' alt="image" />
                </div>
            </div>
        </>
    )
}


export default SetupCompleted