import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { LayoutContext } from "../../../../context/Layout.context";
import { PngIcons, SvgIcons } from "../../../../icons";
import localforage from "localforage";
import { CampaignService, CommunicationService, DealService, EarningWayService, FlowService } from "../../../../services";
import { BrandContext } from "../../../../context/Brand.context";
import { ClickOutside, Options, utils } from "../../../../utils";
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import ViewDetail from "../../../../components/ViewDetail";
import $ from 'jquery'; 
import InputContentEditable from "../../../../components/InputContentEditable";
import { AgencyContext } from "../../../../context/Agency.context";
import { CircularProgress } from "@mui/material";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import localForage from 'localforage'
import DeleteModal from "../../../../components/DeleteModal";
import CustomsModal from "../../../../components/CustomModal";
import CustomTextField from '../../../../components/CustomTextField';
import CustomModal from '../../../../components/CustomModal';
import { Campaign } from "../../../../enums";
import Loader from "../../../../components/Loader";
import CustomTextArea from '../../../../components/CustomTextArea';
import { makeStyles, withStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        // marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: '4px',
      padding: '2px',
      position: 'relative',
    //   backgroundColor: theme.palette.background.paper,
    //   border: '1px solid #ced4da',
      fontSize: 14,
      fontWeight: 500,
    //   padding: '10px 26px 10px 12px',
    //   transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        backgroundColor: '#e5e8f5 !important',
        textDecoration: 'none !important',
        transition: 'background-color 0.2s ease-in-out'
        // borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
      '&:focus': {
        backgroundColor: '#e5e8f5 !important',
        textDecoration: 'none !important',
        transition: 'background-color 0.2s ease-in-out'
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => ({
    margin: {
    //   margin: theme.spacing(1),
    },
  }));

function RewardBuilder() {

    const classes = useStyles();

    const history  = useHistory();
    const layout   = useContext(LayoutContext);
    const brand    = useContext(BrandContext);
    const agency   = useContext(AgencyContext);

    const [name, setName] = useState("Automation Name");
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    
    const [btnloader, setBtnloader] = useState(false);

    const [sms, setSms] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const [addtrigger, setAddtrigger] = useState(false)
    const [idx, setIdx] = useState('')
    const [viewMore, setViewMore] = useState(false)
    
    const [addtrigger1, setAddtrigger1] = useState(false)
    const [viewMore1, setViewMore1] = useState(false)
    const [viewMore2, setViewMore2] = useState(false)
    
    const [show, setShow] = useState({
        modal    : false,
        value    : '',
        title    : '',
        key      : '',
        dealModal : false,
        onChange : ()=>console.log("")
    })

    const [dropIdx, setDropIdx] = useState('')

    const [dropdownTrigger, setDropdownTrigger] = useState([
        {
            name        : 'All Customer',
            key         : 'allCustomer',
            img         : PngIcons.RewardBuilder.checkin,
            description : 'It will trigger for all customer not depending on any condition.'
        },
        {
            name        : 'One Time Deal',
            key         : 'oneTimeDeal',
            img         : PngIcons.RewardBuilder.oneTimeDeal,
            description : 'It will trigger deal will be rewarded to customer only one time.'
        },
        {
            name        : 'Customer Tag',
            key         : 'customerTag',
            img         : PngIcons.RewardBuilder.tagCustomer,
            description : 'It triggers when a customer has the tag you tagged them. e.g., Loyal Customer'
        },
        {
            name        : 'Customer Type',
            key         : 'customerType',
            img         : PngIcons.RewardBuilder.customerType2,
            description : 'It triggers when a customer has the tag you tagged them. e.g., Favorite Customer'
        },
        {
            name        : 'Customer Inactivity',
            key         : 'customerInactivity',
            img         : PngIcons.RewardBuilder.customerInactivity,
            description : 'It triggers when a customer has no activity for a certain period.'
        },
        {
            name        : 'Reward Status',
            key         : 'dealStatus',
            img         : PngIcons.RewardBuilder.dealStatus,
            description : 'It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)'
        },
        {
            name        : 'Event',
            key         : 'event',
            img         : PngIcons.RewardBuilder.event,
            description : 'It triggers when a specific event occurs.'
        },
        {
            name        : 'Points',
            key         : 'point',
            img         : PngIcons.RewardBuilder.points,
            description : 'It triggers when a specific amount of points are achieved.'
        },
        {
            name        : 'Visits',
            key         : 'visit',
            img         : PngIcons.RewardBuilder.visits,
            description : 'It triggers when a customer has a certain amount of visits to your brand.'
        },
        {
            name        : 'Referral reward for sender',
            key         : 'referral',
            img         : PngIcons.RewardBuilder.oneTimeDeal,
            description : 'It triggers when a customer Reffered numbers of customer'
        },
        {
            name        : 'Referral reward for receiver',
            key         : 'referrallogin',
            img         : PngIcons.RewardBuilder.checkin,
            description : 'It triggers when a customer create pass using referral'
        },
    ])
    const [dropdownAction, setDropdownAction] = useState([
        {
            name        : 'Add Tag',
            key         : 'addTag',
            img         : PngIcons.RewardBuilder.addTag,
            description : 'Description'
        },
        {
            name        : 'Give Points',
            key         : 'givePoint',
            img         : PngIcons.RewardBuilder.addPoints,
            description : 'Description'
        },
        {
            name        : 'Deduct Points',
            key         : 'deductPoint',
            img         : PngIcons.RewardBuilder.deductPoints,
            description : 'Description'
        },
        {
            name        : 'Customer Type',
            key         : 'customerType',
            img         : PngIcons.RewardBuilder.customerType,
            description : 'It triggers when a customer has the tag you tagged them. e.g., Loyal Customer'
        },
        {
            name        : 'Reward',
            key         : 'rewardDeal',
            img         : PngIcons.RewardBuilder.rewardDeal,
            description : 'Description'
        },
        {
            name        : 'Remove Tag',
            key         : 'removeTag',
            img         : PngIcons.RewardBuilder.removeTag,
            description : 'Description'
        },
        {
            name        : 'Send SMS',
            key         : 'sendSms',
            img         : PngIcons.RewardBuilder.sendSMS,
            description : 'Description'
        },
    ])

    const [finalstate, setFinalstate] = useState(history.location.state?.flow || [{state: [], state1: []}])

    console.log('history.location.state',history.location.state);

    const onLoad = async () => {
        // const smsApi = await CommunicationService.GetQueriedCommunication({ query: { delete: false, brandId: brand._id, smsType: 'draft', communicationType: 'sms' } });
        // if(smsApi.response) setSms(smsApi.response.data)
    
        let deal = await localforage.getItem("deal");
        if ((!deal) || deal.length === 0) {
        const query = { delete: false, brandId: brand._id };
        const { error, response } = await DealService.Get({ query });
        if (response) deal = response.data;
        }
    
        let temp = [];
        if (deal && deal.length > 0)
        for (const value of deal) {
            temp.push({
            label: value.name,
            value: value._id,
            image: <img src={value.image} width="20px" heigth="20px" />,
            data: value,
            });
        }
        setFilteredOptions(temp);

    }

    useEffect(onLoad, []);
   
    useEffect(() => {
        layout.setLayout({
        
        edit        : true,
        placeholder : "Automation",
        borderBottom: true,
        switch : '',
        // backTitles: ["Loyalty Suite", "Reward Builder"],

        button: <>
        {history.location.state?._id&&<CustomButton 
                varient="warningSecondary" btntext = {"Delete"} className="mr-4" onClick = {()=>{
                setDeleteModal(true)
            }} />}
        <CustomButton 
            icon    = {btnloader && <CircularProgress size={20} color={'inherit'}/>}
            varient = {"primary"} btntext = {"Save Changes"} onClick = {async()=>{
            setBtnloader(true)
            const payload = {
                name     : layout.elements.editTitle || name || 'demo',
                brandId  : brand._id,
                agencyId : agency._id,
                flowType : 'new',
                flow     : finalstate
            }
            // console.log('payload',payload)
            if(history.location.state?._id){
                payload._id = history.location.state?._id
                const {response, error} = await FlowService.Update({payload})
                let temp = [response.data]
                // console.log('response',response, error);
                let flow = await localForage.getItem('flow');
                if(flow && flow.length > 0){
                    temp = []
                    for (const value of flow) {
                        if(value._id === history.location.state?._id) temp.push(response.data)
                        else temp.push(value)
                    }
                }
                localForage.setItem('flow', temp);
            }
            else{
                // console.log('create');
                const {response, error} = await FlowService.Create({payload})
                // console.log(response, error);
                let temp = [response.data]
                let flow = await localForage.getItem('flow');
                if(flow){
                    flow.push({...response.data, created: new Date().valueOf()})
                }
                else{
                    flow = []
                    flow.push({...response.data, created: new Date().valueOf()})
                }
                // if(flow && flow.length > 0){
                //     temp = []
                //     for (const value of flow) {
                //         if(value._id === history.location.state?._id) temp.push(response.data)
                //         else temp.push(value)
                //     }
                // }
                localForage.setItem('flow', flow);
            }
            setBtnloader(false)
            
            const location = window.location.pathname.split('/')
            location.pop()
            history.push(location.join('/'))

        }} />
        
        </>
        ,
        });
    }, [layout.elements.editTitle, finalstate, btnloader, deleteModal, deleteLoader, window.location.pathname]);


        useEffect(()=>{
                layout.setLayout({
                    editTitle : history?.location?.state?.name || ''
                })
            },[])


            const deleteFlowItem = (index) => {
                // const newData = finalstate.map(item => ({
                //   ...item,
                //   flow: item.flow.filter((_, i) => i !== index)
                // }));
                const newFlow = finalstate.filter((_, i) => i !== index);
                setFinalstate(newFlow);
                // setFinalstate(newData);
              };

    const handleDelete = async() => {
        setDeleteLoader(true)
        let temp = []
        if(finalstate.length === 1){
            await FlowService.Update({payload : {_id: history.location.state?._id, brandId: brand._id, agencyId: agency._id, delete: true}})
            let flow = await localForage.getItem('flow');
            if(flow && flow.length > 0){
                flow.map((value, index)=>{
                    if(history.location.state?._id!==value._id) temp.push(value)
                })
            }
            localForage.setItem('flow', temp);
        }
        else{
            console.log('elseee');
            const newFlow = finalstate.filter((_, i) => i !== history.location.state.index);
            console.log('newFlow',newFlow);
            setFinalstate(newFlow);
            // deleteFlowItem(history.location.state.index)
            const a= await FlowService.Update({payload : {_id: history.location.state?._id, brandId: brand._id, agencyId: agency._id, flow: newFlow}})
            console.log('aa',a);
            
            // localForage.removeItem('flow', [{...history.location.state, flow: newFlow}]);
            localForage.setItem('flow', [{...history.location.state, flow: newFlow}]);
        }

        setViewMore(false)
        setDeleteLoader(false)
        setDeleteModal(false)

        const location = window.location.pathname.split('/')
        location.pop()
        history.push(location.join('/'))
    }
    const deleteFunc = (index, i, state) => {
        if(state){
            finalstate[i].state.splice(index, 1)
            setViewMore(false)
            setIdx('')
            setFinalstate([...finalstate])
        }
        else{
            finalstate[i].state1.splice(index, 1)
            setViewMore1(false)
            setIdx('')
            setFinalstate([...finalstate])
        }
    }

    const func = (value, key) => {
        // console.log('====================================');
        // console.log('abc',value, 'key', key);
        // console.log('====================================');
        [key] = value
        setFinalstate([...finalstate])
    }

    const [innerLoader, setInnerLoader] = useState(true)
    const [earning, setEarning] = useState()

    useEffect(async() => {
        const query = { 
            brandId      : brand._id,
            campaignType : 5
        }
        const campaign = await CampaignService.GetQueriedCampaign({query})
        // console.log('====================================');
        // console.log('campaign',campaign);
        // console.log('====================================');
        let selectedLoyalityEarningWays;
        selectedLoyalityEarningWays = await localForage.getItem('selectedLoyalityEarningWays');
        if(!selectedLoyalityEarningWays || selectedLoyalityEarningWays[0].campaignId !== campaign.response.data[0]._id)
        {
            const query = {campaignId: campaign.response.data[0]._id}
            if(campaign.response.data[0]._id) 
            {
                const { error, response } = await EarningWayService.GetQueriedEarningWays({query})
                // console.log('respones', response);
                let temp = []
                temp.push(response.data.filter((e)=>e.activity===Campaign.Activities.EARNING.CHECKIN)[0])
                temp.push(response.data.filter((e)=>e.activity===Campaign.Activities.EARNING.SIGNUP)[0])
                temp.push(response.data.filter((e)=>e.activity===Campaign.Activities.EARNING.BIRTHDAY)[0])
                temp.push(response.data.filter((e)=>e.activity===Campaign.Activities.EARNING.SPENDING)[0])
                if(response) localForage.setItem('selectedLoyalityEarningWays', temp)
                if(response) selectedLoyalityEarningWays = response.data
                if(response) setEarning(response.data.filter((e)=>e.activity===Campaign.Activities.EARNING.SPENDING)[0])

            }
        }
        // console.log('selectedLoyalityEarningWays',selectedLoyalityEarningWays);
        let temp = []
        if(selectedLoyalityEarningWays){
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.CHECKIN)[0])
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.SIGNUP)[0])
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.BIRTHDAY)[0])
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.SPENDING)[0])
            setEarning(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.SPENDING)[0])
            // console.log('temp-',temp);
        }
        setInnerLoader(false)
    }, [])
    

    
  return (
    <>
    {innerLoader?<Loader />
    :
    <div id="RewardBuilder" className="pt_16 pr_40 pb_16 pl_40">
        <CustomsModal 
            open      = { deleteModal }
            minWidth  = {'600px'}
            component = {<DeleteModal icon={deleteLoader} title="Deal" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
            onClose   = {()=> setDeleteModal(false)}
        />
        {console.log('finalstate',finalstate)}
        {console.log('history.location.state.index',history.location.state)}

        {finalstate.length>0&&finalstate.map((v,i)=>((i===history.location.state.index||history.location.state?.index===history?.location?.state?.flow?.length)&&<><div className="">
            <div className={`d-flex w-100 gap-24 ${!finalstate[i]?.expand ? 'expanded' : 'unExpanded'}`}>
                <div className=" w-50 position-relative">
                    {
                        v?.state?.length>0&&v.state.map((value, index)=>(
                            <>
                            {value!==null&&<div className="childBox position-relative ">
                                <div className="w-100">
                                    <div className="w-100 align-items-center d-flex space-between mb_8">
                                        <div className="w-100 align-items-center d-flex gap-8">
                                            <img src={value.img} height={20} width={20} />
                                            <div className="d-flex space-between hpx-24 align-items-center"><div className="Body14M color-neutral70">{
                                                value.name === "Deal Status" ? 
                                                "Reward Status" :
                                                value.name === "Visits" ? 
                                                "When customer visit":
                                                value.name === "Points" ? 
                                                "When points":
                                                value.name === "One Time Deal" ? 
                                                "Offer customer one time reward":
                                                value.name === "All Customer" ? 
                                                "When all Customer are selected":
                                                value.name}</div>
                                        </div>

                                        </div>
                                        <div className="cp showcross svg-hover" 
                                        onClick={()=>deleteFunc(index, i, true)
                                        // {setIdx(index); setViewMore(!viewMore)}
                                        } ><SvgIcons.CrossIcon /></div>
                                    </div>
                                    <div className="w-100">
                                        {
                                            value.name === 'Event' &&
                                            <div className="d-flex subTextBox align-items-center">
                                                <div className="B14M color-neutral70">is</div>
                                                <div className="B14M color-neutral80 underline">
                                                    <select
                                                        style={{backgroundColor: 'inherit'}}
                                                        className = {'border-none focusactive underline cp'}
                                                        onChange  = {(e)=>{finalstate[i].state[index].festival = e.target.value; setFinalstate([...finalstate])}}
                                                        value     = {finalstate[i].state[index].festival}
                                                    >
                                                        {Options.getFestivals()}
                                                    </select>
                                                </div>
                                                {finalstate[i].state[index].festival === 'Custom Date'&&<div className=" mainfocusactive wpx-230 cp h-fit-content B14M color-neutral80 underline">
                                                    
                                                    {/* <DateRangePicker 
                                                    
                                                        onCallback={(start, end, label)=>{
                                                            
                                                            finalstate[i].state[index].eventStart = new Date(start._d).valueOf(); 
                                                            finalstate[i].state[index].eventEnd = new Date(end._d).valueOf(); 
                                                            setFinalstate([...finalstate])}
                                                        }>
                                                        <input type="text" className="cp form-control color-neutral80 underline shadow-none border-none" style={{fontSize: '14px', fontWeight: '500'}} />
                                                    </DateRangePicker> */}
                                                </div>}
                                            </div>
                                        }
                                        {
                                            value.name === 'Points' &&
                                            <div className="d-flex align-items-center subTextBox">
                                                <div className="B14M color-neutral70">When points are</div>
                                                <div className="B14M color-neutral80 underline">
                                                    <select
                                                        style={{backgroundColor: 'inherit'}}
                                                        className = {
                                                            finalstate[i].state[index].condition === 'equal' ? "border-none underline focusactive wpx-50" :
                                                            finalstate[i].state[index].condition === 'lessThan' ? "border-none underline focusactive wpx-85" :
                                                            finalstate[i].state[index].condition === 'moreThan' ? "border-none underline focusactive wpx-92" :
                                                            "border-none focusactive underline"}
                                                        onChange  = {(e)=>{finalstate[i].state[index].condition = e.target.value; setFinalstate([...finalstate])}}
                                                        value     = {finalstate[i].state[index].condition}
                                                    >
                                                        <option value={"equal"}>{"equal"}</option>
                                                        <option value={"lessThan"}>{"less than"}</option>
                                                        <option value={"moreThan"}>{"more than"}</option>
                                                        <option value={"lessThanEqual"}>{"less than equal"}</option>
                                                        <option value={"moreThanEqual"}>{"more than equal"}</option>
                                                    </select>
                                                </div>
                                                <div className="ml_2 cp mainfocusactive d-flex B14M color-neutral80 underline border-none" 
                                                onClick={()=>setShow({modal: true, title: 'Points', value: finalstate[i].state[index].points || '0', key: 'finalstate[i].state[index].points', onChange : (e)=>{finalstate[i].state[index].points = e.target.value; setFinalstate([...finalstate])}})}
                                                >
                                                    <div>{finalstate[i].state[index].points || '0'}</div>
                                                    {/* <InputContentEditable value={finalstate[i].state[index].points || '0'} onChange = {(e)=>{finalstate[i].state[index].points = e; setFinalstate([...finalstate])}} /> */}
                                                    <div>&nbsp;</div>
                                                    <div>Points</div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            value.name === 'Referral reward for sender' &&
                                            <div className="d-flex align-items-center subTextBox ">
                                              
                                                <div className="ml_2 mainfocusactive d-flex B14M color-neutral80 underline border-none cp"
                                                onClick={()=>setShow({modal: true, title: 'Customer Invites', value: finalstate[i].state[index].customer || '0', key: 'finalstate[i].state[index].customer', onChange: (e)=>{finalstate[i].state[index].customer = e.target.value; setFinalstate([...finalstate])}})}
                                                >
                                                    <div>{finalstate[i].state[index].customer || '0'}</div>
                                                    {/* <InputContentEditable value={finalstate[i].state[index].customer || '0'} onChange = {(e)=>{finalstate[i].state[index].customer = e; setFinalstate([...finalstate])}} /> */}
                                                    <div>&nbsp;</div>
                                                    <div>Invites</div>
                                                </div>
                                                <div className="B14M color-neutral70">When receiver perform</div>
                                                <div className="B14M color-neutral80 underline">
                                                    <select
                                                        style={{backgroundColor: 'inherit'}}
                                                        className = {"border-none focusactive underline"}
                                                        onChange  = {(e)=>{finalstate[i].state[index].type = e.target.value; setFinalstate([...finalstate])}}
                                                        value     = {finalstate[i].state[index].type}
                                                    >
                                                        <option value={'signup'}>{'signup'}</option>
                                                        <option value={'activity'}>{'activity'}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        {
                                            value.name === 'Visits' &&
                                            <div className="d-flex align-items-center subTextBox">
                                                <div className=" B14M color-neutral70">When customer visit</div>
                                                <div className="B14M color-neutral80 underline">
                                                    <select
                                                        style={{backgroundColor: 'inherit'}}
                                                        className = {
                                                            finalstate[i].state[index].condition === 'equal' ? "border-none underline focusactive wpx-50" :
                                                            finalstate[i].state[index].condition === 'lessThan' ? "border-none underline focusactive wpx-85" :
                                                            finalstate[i].state[index].condition === 'moreThan' ? "border-none underline focusactive wpx-92" :
                                                            "border-none focusactive underline"}
                                                        onChange  = {(e)=>{finalstate[i].state[index].condition = e.target.value; setFinalstate([...finalstate])}}
                                                        value     = {finalstate[i].state[index].condition}
                                                    >
                                                        <option value={"equal"}>{"equal"}</option>
                                                        <option value={"lessThan"}>{"less than"}</option>
                                                        <option value={"moreThan"}>{"more than"}</option>
                                                        <option value={"lessThanEqual"}>{"less than equal"}</option>
                                                        <option value={"moreThanEqual"}>{"more than equal"}</option>
                                                    </select>
                                                </div>
                                                <div className="ml_2 cp d-flex mainfocusactive Body14M color-neutral80 underline border-none"
                                                onClick={()=>setShow({modal: true, title: 'Visits', value: finalstate[i].state[index].visits || '0', key: 'finalstate[i].state[index].visits', onChange: (e)=>{finalstate[i].state[index].visits = e.target.value; setFinalstate([...finalstate])}})}
                                                >
                                                    <div>{finalstate[i].state[index].visits || '0'}</div>
                                                    {/* <InputContentEditable value={finalstate[i].state[index].visits || '0'} onChange = {(e)=>{finalstate[i].state[index].visits = e; setFinalstate([...finalstate])}} /> */}
                                                    <div>&nbsp;</div>
                                                    <div>Visits</div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            value.name === 'Customer Inactivity' &&
                                            <div className="d-flex subTextBox align-items-center">
                                                <div className="B14M color-neutral70">is</div>
                                                <div className="B14M color-neutral80 underline">
                                                    <select
                                                        style={{backgroundColor: 'inherit'}}
                                                        className = {
                                                            finalstate[i].state[index].condition === 'equal' ? "border-none underline focusactive wpx-50" :
                                                            finalstate[i].state[index].condition === 'lessThan' ? "border-none underline focusactive wpx-85" :
                                                            finalstate[i].state[index].condition === 'moreThan' ? "border-none underline focusactive wpx-92" :
                                                            "border-none underline focusactive"}
                                                        onChange  = {(e)=>{finalstate[i].state[index].condition = e.target.value; setFinalstate([...finalstate])}}
                                                        value     = {finalstate[i].state[index].condition}
                                                    >
                                                        <option value={"equal"}>{"equal"}</option>
                                                        <option value={"lessThan"}>{"less than"}</option>
                                                        <option value={"moreThan"}>{"more than"}</option>
                                                        <option value={"lessThanEqual"}>{"less than equal"}</option>
                                                        <option value={"moreThanEqual"}>{"more than equal"}</option>
                                                    </select>
                                                </div>
                                                <div className="ml_2 cp d-flex mainfocusactive B14M color-neutral80 underline border-none"
                                                onClick={()=>setShow({modal: true, title: 'Inactivity', value: finalstate[i].state[index].days || '0', key: 'finalstate[i].state[index].days', onChange: (e)=>{finalstate[i].state[index].days = e.target.value; setFinalstate([...finalstate])}})}
                                                >
                                                    {/* <InputContentEditable value={finalstate[i].state[index].days || '0'} onChange = {(e)=>{finalstate[i].state[index].days = e; setFinalstate([...finalstate])}} /> */}
                                                    <div>{finalstate[i].state[index].days || '0'}</div>
                                                    <div>&nbsp;</div>
                                                    <div>Days</div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            (value.name === 'Reward Status' || value.name === 'Deal Status') &&
                                            <div className="d-flex space-between subTextBox align-items-center flex-wrap-wrap">
                                                <div className="d-flex align-items-center">
                                                    <div className="B14M color-neutral70">of</div>
                                                    <div className="B14M color-neutral80 underline">
                                                
                                                        <select
                                                            className = "focusactive border-none underline"
                                                            // style={{width: '100%'}}
                                                            onChange  = {(e)=>{
                                                                // console.log('html1',e.target.value);
                                                                // console.log('html2',e.target.selectedOptions[0].label);
                                                                // // console.log('html',e.target.innerHTML);
                                                                // // console.log('html text',e.target.selectedOptions[0].label);
                                                                finalstate[i].state[index].deal = e.target.value; 
                                                                finalstate[i].state[index].dealname = e.target.selectedOptions[0].label; 
                                                                setFinalstate([...finalstate])}}
                                                            value     = {finalstate[i].state[index].deal}
                                                        >
                                                            {
                                                                filteredOptions&&filteredOptions.length>0&&filteredOptions.map((val, ind)=> (
                                                                    <option value={val.value}>{val.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="B14M color-neutral70">is</div>
                                                    <div className="B14M color-neutral80 underline">
                                                        <select
                                                            style={{backgroundColor: 'inherit'}}
                                                            className = "focusactive border-none underline"
                                                            onChange  = {(e)=>{finalstate[i].state[index].status = e.target.value; setFinalstate([...finalstate])}}
                                                            value     = {finalstate[i].state[index].status}
                                                        >
                                                            <option value={"Assigned"}>{"Assigned"}</option>
                                                            <option value={"Redeemed"}>{"Redeemed"}</option>
                                                            <option value={"Expired"}>{"Expired"}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className=" cp dealViewIcon" onClick={()=>setShow({...show, dealModal : true, selectedDeal :finalstate[i].state[index] })}><SvgIcons.EyeIcon/></div>
                                            </div>
                                        }
                                        <CustomModal 
                                            component       = {<DealModalComponent filteredOptions={filteredOptions} onClose={()=>setShow({...show, dealModal : false})}  deal={show.selectedDeal} />}
                                            open            = {show.dealModal}
                                            boxShadow       = {false}
                                            minWidth        = "600px"
                                            backgroundColor = 'rgba(0, 0, 0, 0.2)'
                                            onClose         = {()=>setShow({...show, dealModal : false})}
                                        />
                                        {
                                            value.name === 'Customer Tag' &&
                                            <div className="d-flex subTextBox align-items-center">
                                                <div className="mainfocusactive d-flex B14M color-neutral80 underline border-none cp"
                                                onClick={()=>setShow({modal: true, title: 'Tag', value: finalstate[i].state[index].customerTag || 'Enter customer tag', key: 'finalstate[i].state[index].customerTag', onChange: (e)=>{finalstate[i].state[index].customerTag = e.target.value; setFinalstate([...finalstate])}})}
                                                >
                                                    <div className="B14M color-neutral80">{finalstate[i].state[index].customerTag || 'Enter customer tag'}</div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            value.name === 'Customer Type' &&
                                            <div className="d-flex subTextBox align-items-center">
                                                <div className="B14M color-neutral70">is</div>
                                                <div className="B14M color-neutral80 underline">
                                                    <select
                                                        style={{backgroundColor: 'inherit'}}
                                                        className = "focusactive border-none underline"
                                                        onChange  = {(e)=>{finalstate[i].state[index].customerType = e.target.value; setFinalstate([...finalstate])}}
                                                        value     = {finalstate[i].state[index].customerType}
                                                    >
                                                        <option value={"loyal"}>{"Loyal"}</option>
                                                        <option value={"walkIn"}>{"Walk-In"}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        {
                                            value.name === 'All Customer' &&
                                            <div className="d-flex subTextBox align-items-center">
                                                <div className="pt_2 pb_2 pr_2 d-flex B14M color-neutral70 border-none">
                                                    When all Customer are selected
                                                </div>
                                            </div>
                                        }
                                        {
                                            value.name === 'Referral reward for receiver' &&
                                            <div className="d-flex subTextBox align-items-center">
                                                <div className="B14M color-neutral70">is true When receiver perform</div>
                                                <div className="B14M color-neutral80 underline">
                                                    <select
                                                        style={{backgroundColor: 'inherit'}}
                                                        className = {"border-none focusactive underline"}
                                                        onChange  = {(e)=>{finalstate[i].state[index].type = e.target.value; setFinalstate([...finalstate])}}
                                                        value     = {finalstate[i].state[index].type}
                                                    >
                                                        <option value={'signup'}>{'signup'}</option>
                                                        <option value={'activity'}>{'activity'}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        {
                                            value.name === 'One Time Deal' &&
                                            <div className="d-flex subTextBox align-items-center">
                                                <div className="pt_2 pb_2 pr_2 d-flex B14M color-neutral70 border-none">
                                                    Offer customer one time reward Coupon
                                                </div>
                                            </div>
                                        }





                                        {/* <ClickOutside onClick={()=>setViewMore(false)}> */}
                                            {viewMore &&
                                            index == idx  &&
                                            dropIdx === i &&
                                                <div className="childbox-dropdown-trigger">
                                                    <ViewDetail items={[
                                                        {
                                                            name    : 'Delete',
                                                            function:  ()=>deleteFunc(index, i, true),
                                                            icon: <SvgIcons.DeleteIcon/>,
                                                        }
                                                        // {
                                                        //     name    : 'View',
                                                        //     function: handleSelectedCustomer
                                                        // }
                                                    ]}/>
                                                </div>
                                            }
                                        {/* </ClickOutside> */}
                                    </div>
                                </div>
                            </div>}
                            {index!==v.state.length-1&&<div className="text-align-center B16M mt_12 mb_12 color-neutral50">and</div>}
                            </>
                        ))
                    }
                    <div className='w-100 hpx-48 trigger1 mt_24' onClick={()=>{setAddtrigger(!addtrigger); setDropIdx(i)}} varient='tertiary'>Add Trigger</div>
                    {addtrigger&& dropIdx === i &&<ClickOutside onClick={(e)=>{
                        // e.preventDefault();
                        setAddtrigger(false)
                        e.stopPropagation()
                    }} >
                        <div className="p_8 mt_5 dropDownActions w-100 bg-color-white h-fit-content scroll borderRadius-4 ">
                            {
                                dropdownTrigger.map((value, index)=> (
                                    <div className="d-flex p_6 gap-16 cp hoverclass borderRadius-6" onClick={()=>{
                                        // console.log('filteredOptions[0]',filteredOptions[0]);
                                        if(value.name === 'Deal Status'){ // && filteredOptions&&filteredOptions.length===1
                                            finalstate[i].state.push({...value, deal: filteredOptions[0].value, dealname: filteredOptions[0].label, status: 'Assigned'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Customer Inactivity'){
                                            finalstate[i].state.push({...value, condition: 'equal'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Customer Type'){
                                            finalstate[i].state.push({...value, customerType: 'loyal'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Event'){
                                            finalstate[i].state.push({...value, festival: 'Custom Date'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Visits'){
                                            finalstate[i].state.push({...value, condition: 'equal'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Points'){
                                            finalstate[i].state.push({...value, condition: 'equal'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Referral reward for sender'){
                                            finalstate[i].state.push({...value, type: 'signup'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Referral reward for receiver'){
                                            finalstate[i].state.push({...value, type: 'signup'})
                                            setAddtrigger(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else{
                                            setAddtrigger(false)
                                            finalstate[i].state.push(value)
                                            setFinalstate([...finalstate])
                                        }
                                        
                                    }}>
                                        {/* <img className="borderRadius-4 border p_8 " src={value.img} width={32} height={32} /> */}
                                        {/* <div className="borderRadius-4 border h-fit-content" style={{padding: '4px'}}>
                                            <img  src={value.img} width={24} height={24} />
                                        </div>
                                        <div>
                                            <div className="Body12M lh-20 color-neutral90">{value.name}</div>
                                            <div className="Body12R lh-16 color-neutral60 ">{value.description}</div>
                                        </div> */}
                                        <div className=" d-flex gap-16">
                                            <img  src={value.img} width={20} height={20} />
                                            <div className="B14M lh-20 color-neutral90">{value.name}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </ClickOutside>}
                    <div className="mt_24">&nbsp;</div>
                </div>
                <div className=" w-50 position-relative">
                    <div className={v?.state1?.length>0?"childBoxAction":""}>
                        {
                            v?.state1?.length>0&&v.state1.map((value, index)=>(
                                value!==null&&<div className="childBox2 position-relative mb_16">
                                    <div className="">
                                        
                                        <div className="w-100">
                                            <div className="d-flex space-between hpx-24 align-items-center mb_8">
                                                <div className="d-flex align-items-center">
                                                <div className="p_6 h-fit-content">
                                                    <img src={value.img} height={20} width={20} />
                                                </div>
                                                <div className="Body14M color-neutral60">{(value.name==="Reward Deal"||value.name==="Reward")?"Reward":value.name}</div>
                                                </div>
                                                <div className="cp more-option showcross svg-hover" 
                                            onClick={()=>deleteFunc(index, i, false)
                                            } ><SvgIcons.CrossIcon  /></div></div>
                                            {
                                                (value.name === 'Reward Deal' || value.name === 'Reward') &&
                                                <div className="subTextBox">
                                                    <div className="d-flex space-between align-items-center">
                                                        <div className="d-flex gap-5 align-items-center">
                                                            <div className=" cp dealViewIcon" onClick={()=>setShow({...show, dealModal : true, selectedDeal :finalstate[i].state1[index] })}><SvgIcons.EyeIcon/></div>
                                                            <CustomModal 
                                                                component       = {<DealModalComponent filteredOptions={filteredOptions} onClose={()=>setShow({...show, dealModal : false})}  deal={show.selectedDeal} />}
                                                                open            = {show.dealModal}
                                                                boxShadow       = {false}
                                                                minWidth        = "600px"
                                                                backgroundColor = 'rgba(0, 0, 0, 0.2)'
                                                                onClose         = {()=>setShow({...show, dealModal : false})}
                                                            />
                                                            <div className="w-60 scroll B14M color-neutral80 underline">
                                                                <select
                                                                    className = "border-none focusactive underline"
                                                                    style={{width: '100%'}}
                                                                    onChange  = {(e)=>{
                                                                        // console.log('html',e.target.innerHTML);
                                                                        // console.log('html text',e.target.selectedOptions[0].label);
                                                                        finalstate[i].state1[index].rewardDeal = e.target.value; 
                                                                        finalstate[i].state1[index].dealname = e.target.selectedOptions[0].label; 
                                                                        setFinalstate([...finalstate])}}
                                                                    value     = {finalstate[i].state1[index].rewardDeal}
                                                                >
                                                                    {
                                                                        filteredOptions&&filteredOptions.length>0&&filteredOptions.map((val, ind)=> (
                                                                            <option value={val.value}>{val.label}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="Body14R pt_2 pb_2 pr_2 color-neutral70">Expiration: </div>
                                                            <div className="Body14M color-neutral70 underline">
                                                                <select
                                                                    style={{backgroundColor: 'inherit'}}
                                                                    className = "focusactive border-none underline"
                                                                    onChange  = {(e)=>{finalstate[i].state1[index].dealExpiration = e.target.value; setFinalstate([...finalstate])}}
                                                                    value     = {finalstate[i].state1[index].dealExpiration}
                                                                >
                                                                    <option value={"none"}>{"none"}</option>
                                                                    <option value={"30"}>{"30 Days"}</option>
                                                                    <option value={"60"}>{"60 Days"}</option>
                                                                    <option value={"90"}>{"90 Days"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                value.name === 'Customer Type' &&
                                                <div className="d-flex subTextBox align-items-center">
                                                    <div className="B14M color-neutral80 underline">
                                                        <select
                                                            style={{backgroundColor: 'inherit'}}
                                                            className = "focusactive border-none underline"
                                                            onChange  = {(e)=>{finalstate[i].state1[index].customerType = e.target.value; setFinalstate([...finalstate])}}
                                                            value     = {finalstate[i].state1[index].customerType}
                                                        >
                                                            <option value={"loyal"}>{"Loyal"}</option>
                                                            <option value={"walkIn"}>{"Walk-In"}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                value.name === 'Give Points' &&
                                                <div className="d-flex subTextBox align-items-center">
                                                    <div className=" d-flex cp mainfocusactive B14M color-neutral80 underline border-none"
                                                    onClick={()=>setShow({modal: true, title: 'Points', value: finalstate[i].state1[index].givePoints || 0, key: 'finalstate[i].state1[index].givePoints', onChange: (e)=>{finalstate[i].state1[index].givePoints = e.target.value; setFinalstate([...finalstate])}})}
                                                    >
                                                        <div>{finalstate[i].state1[index].givePoints || 0}</div>
                                                        <div>&nbsp;</div>
                                                        <div>Points</div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                value.name === 'Deduct Points' &&
                                                <div className="d-flex subTextBox align-items-center ">
                                                    <div className="d-flex cp mainfocusactive B14M color-neutral80 underline border-none"
                                                    onClick={()=>setShow({modal: true, title: 'Deduct Points', value: finalstate[i].state1[index].deductPoints || 0, key: 'finalstate[i].state1[index].deductPoints', onChange: (e)=>{finalstate[i].state1[index].deductPoints = e.target.value; setFinalstate([...finalstate])}})}
                                                    >
                                                        <div>{finalstate[i].state1[index].deductPoints || 0}</div>
                                                        <div>&nbsp;</div>
                                                        <div>Points</div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                value.name === 'Send SMS' &&
                                                <>
                                                {console.log('finalstate[i].state1[index].sendSms ', finalstate[i].state1[index].sendSms)}
                                                <div className="d-flex space-between subTextBox">
                                                    <div className="B14M color-neutral80 underline">
                                                        <select
                                                            style={{backgroundColor: 'inherit'}}
                                                            className="focusactive border-none underline"
                                                            value     = {finalstate[i].state1[index].sendSms}
                                                            onChange  = {(e)=>{
                                                                finalstate[i].state1[index].sendSms = e.target.value
                                                                setFinalstate([...finalstate])
                                                            }}
                                                        >
                                                            {
                                                                sms&&sms.length>0&&sms.map((aaa, ind)=> (
                                                                    <option value={aaa._id}>{aaa.communicationName}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                <div className="d-flex align-items-center mt_-5">
                                                    <div className="Body14R pt_2 pb_2 pr_2 color-neutral70">Delay: </div>
                                                    <div className="Body14M color-neutral70 underline">
                                                        <select
                                                            className = "focusactive border-none underline"
                                                            onChange  = {(e)=>{finalstate[i].state1[index].delay = e.target.value; setFinalstate([...finalstate])}}
                                                            value     = {finalstate[i].state1[index].delay}
                                                            style={{backgroundColor: 'inherit'}}
                                                        >
                                                            <option value={"none"}>{"none"}</option>
                                                            <option value={"1"}>{"1 Days"}</option>
                                                            <option value={"2"}>{"2 Days"}</option>
                                                            <option value={"3"}>{"3 Days"}</option>
                                                            <option value={"4"}>{"4 Days"}</option>
                                                            <option value={"5"}>{"5 Days"}</option>
                                                            <option value={"6"}>{"6 Days"}</option>
                                                            <option value={"7"}>{"7 Days"}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                </div>
                                                </>
                                            }
                                            {
                                                value.name === 'Add Tag' &&
                                                <div className="d-flex subTextBox align-items-center">
                                                    <div className=" mainfocusactive d-flex B14M color-neutral80 underline border-none cp"
                                                    onClick={()=>setShow({modal: true, title: 'Tag', value: finalstate[i].state1[index].addTag || 0, key: 'finalstate[i].state1[index].addTag', onChange: (e)=>{finalstate[i].state1[index].addTag = e.target.value; setFinalstate([...finalstate])}})}
                                                    >
                                                        <div>{finalstate[i].state1[index].addTag || 'Enter tag name'}</div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                value.name === 'Remove Tag' &&
                                                <div className="d-flex subTextBox align-items-center ">
                                                    <div className="cp mainfocusactive d-flex B14M color-neutral80 underline border-none"
                                                    onClick={()=>setShow({modal: true, title: 'Tag', value: finalstate[i].state1[index].removeTag || 0, key: 'finalstate[i].state1[index].removeTag', onChange: (e)=>{finalstate[i].state1[index].removeTag = e.target.value; setFinalstate([...finalstate])}})}
                                                    >
                                                        <div>{finalstate[i].state1[index].removeTag || 'Enter tag name'}</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                     <div className='w-100 mt_24 hpx-48 trigger2' onClick={()=>{setAddtrigger1(!addtrigger1); setDropIdx(i)}} varient='tertiary'>Add Action</div>
                    {addtrigger1&& dropIdx === i &&<ClickOutside onClick={(e)=>{
                        setAddtrigger1(false)
                        e.stopPropagation()
                    }} >
                        <div className="p_8 mt_5 dropDownActions w-100 bg-color-white h-fit-content scroll borderRadius-4 ">
                            {
                                dropdownAction.map((value, ii)=> (
                                    <div className="d-flex p_6 gap-16 cp hoverclass borderRadius-6 align-items-center" onClick={()=>{
                                        if(value.name === 'Send SMS' && sms&&sms.length>0){
                                            finalstate[i].state1.push({...value, sendSms: sms[0]._id})
                                            setAddtrigger1(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Reward Deal' || value.name === 'Reward'){ // && filteredOptions&&filteredOptions.length===1
                                            // console.log('filteredOptions',filteredOptions[0]);
                                            finalstate[i].state1.push({...value, rewardDeal: filteredOptions[0].value, dealname: filteredOptions[0].label, uniqueId: utils.getRandomString(12)})
                                            setAddtrigger1(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else if(value.name === 'Customer Type'){
                                            finalstate[i].state1.push({...value, customerType: 'loyal'})
                                            setAddtrigger1(false)
                                            setFinalstate([...finalstate])
                                        }
                                        else{
                                            setAddtrigger1(false)
                                            finalstate[i].state1.push(value)
                                            setFinalstate([...finalstate])
                                        }
                                        
                                    }}>
                                        <div className=" d-flex gap-16">
                                            <img  src={value.img} width={20} height={20} />
                                            <div className="B14M lh-20 color-neutral90">{value.name}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </ClickOutside>}
                    <div className="mt_24">&nbsp;</div>
                </div>
            </div>
                </div>
            </>
        ))}

        <CustomModal
            component = {<EditComponent func={func} setShow={setShow} show={show} finalstate={finalstate} setFinalstate={setFinalstate} />}
            open      = {show.modal}
            minWidth  = {'450px'}
            padding   = "16px"
        />
    </div>}
    </>
  );
}

const EditComponent = ({setShow , show, func}) => {

    const handleConfirm = () => {
        setShow({...show, modal: false})
    }

    return(
        <div className="">
            <div className='Heading18M color-neutral100 mb_24 capitalize'>
                {show.title} title
            </div>
            <CustomTextField
                onChange    = {(e)=>{
                    show.onChange(e)
                    show.value = e.target.value
                    func(e.target.value, show.key)
                }}
                value       = {show.value}
                placeholder = {`Enter ${show.title}`}
                label       = {show.title}
            />
            <div className="d-flex justify-flex-end mt_16">
                <CustomButton
                    btntext = {'Confirm'}
                    onClick = {handleConfirm}
                    // disabled= {layout.elements.editTitle.length < 1 && true}
                />
            </div>
        </div>
    )
}

export default RewardBuilder;


const DealModalComponent = ({deal, onClose, filteredOptions}) =>{
    // console.log('deal ', deal);
    // console.log('deal ', filteredOptions);
    const [val, setVal] = useState('')
    useEffect(() => {
      for (const value of filteredOptions) {
        if(deal?.rewardDeal === value.value || deal?.deal === value.value) setVal(value.data)
      }
    }, [deal])
    
    return(
        <div className="">
            <div className="d-flex mb_24 space-between">
                <h3 className="H22M color-neutral100">
                    Deal Details
                </h3>
                <div className="cp" onClick={()=>onClose()}><SvgIcons.CloseIcon/></div>

            </div>
            <div className="d-flex gap-16">
                <div className="dealImageBox ">
                    <img className="border borderRadius-4 object-fit-cover" src={val?.image} alt="" width={165} height={132} />
                </div>
                <div>
                    <div className="B12M color-neutral80 mb_6">Deal Name</div>
                    <div className="B14M color-neutral100 mb_16">{deal.dealname}</div>
                    <div className="B12M color-neutral80 mb_6">Description</div>
                    <div className="B12R color-neutral50">{val?.description}</div>
                </div>

            </div>
            {/* <div className="mt_16 d-flex justify-flex-end">
                <CustomButton 
                    btntext={"Close"}
                    onClick={()=>onClose()}
                    varient="secondary"
                />
            </div> */}
        </div>
    )
}