import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';

import CustomTab from '../../../components/CustomTab';
import CustomText from '../../../components/CustomTextArea';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import PageTitle from '../../../components/setting/SettingPageTitles';


export default function Policy(props) {

    const labels = [
        'GDR Data Policy',
        'Refund & Cancellation Policy'
    ]

    const components = [
        <DataPoilcy/>,
        <RefundCancellation/>
    ]

    return (
        <div id="Policy">
            <PageTitle title={"Policy"}/>
                <div className="col-12 mt_24">
                        <CustomTab  labels={labels}  components={components}/>
                </div>
    </div>
    )
}

const DataPoilcy = () => {
    return ( 
        <div className="dataPolicy">
           <div className="row">
                <div className="col-12">
                    <CustomText 
                        width = {'100%'}
                        borderRadius= {'20px'}
                        padding = {'12px 12px 12px 16px'}
                        placeholder = {'Paste Refund & Cancellation Policy here'}
                        border = {'1px solid ' + ColorSchemeCode.c828282}
                    />
                </div>
            </div>
        </div>
    )
}

const RefundCancellation = () =>{
    return(
        <div className="refundCancellation">
            <div className="row">
                <div className="col-12">
                    <CustomText 
                        width = {'100%'}
                        borderRadius= {'20px'}
                        padding = {'12px 12px 12px 16px'}
                        placeholder = {'Paste GDPR Policy here'}
                        border = {'1px solid ' + ColorSchemeCode.c828282}
                    />
                </div>
            </div>

        </div>
    )
}