import axios from 'axios'
import { Handlers, utils } from '../utils'
import MainService from './Main.service'
import env from '../config'
const service  = '/v4/customfield'

const Create = async({payload}) => await axios.post(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})

const GetQueriedCustomFields = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const UpdateCustomField = async ({payload}) => await axios.put(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})


const CustomFieldService = {
    Create                 : Handlers.Services(Create),
    GetQueriedCustomFields : Handlers.Services(GetQueriedCustomFields),
    UpdateCustomField      : Handlers.Services(UpdateCustomField)
}

export default CustomFieldService
