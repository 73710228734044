import React, {useState} from 'react'

import ViewDetail from '../../../components/ViewDetail';

import SvgIcons from '../../../icons/svg.icon';

import { ColorSchemeCode } from '../../../enums/ColorScheme';

import { ClickOutside } from '../../../utils';

// 
// 
const Actions = ({viewDetailsFunc, handleDeleteFunc, handleEditFunc}) => {

    const [viewMore, setViewMore] = useState(false);

    const ViewMoreIconClickedFunc = () => {
        setViewMore(!viewMore);
    }

    const viewMoreItems = [
       {
           name     : 'Delete',
           function : handleDeleteFunc,
       },
       {
           name     : 'View more',
           function : viewDetailsFunc,
       }
    ]

    return(
        <div className="d-flex">
            <div className="cp" onClick={ViewMoreIconClickedFunc}>
                <SvgIcons.ViewMoreIcon color={ColorSchemeCode.tableActionDropdownColor}/>
                <ClickOutside onClick={()=>setViewMore(false)}>
                    {viewMore &&
                        <div className="viewdetail position-absolute">
                            <ViewDetail items={viewMoreItems}/>
                        </div>
                    }
                </ClickOutside>
            </div>
            <div className="ml_20 cp" onClick={handleEditFunc}>
                <SvgIcons.EditIcon color={ColorSchemeCode.tableActionDropdownColor}/>
            </div>
        </div>
    )
}

export default Actions
