import { useEffect, useState, useContext } from "react"
import CustomCopytoClip from "../../../components/CustomCopyToClip"
import { PngIcons, SvgIcons } from "../../../icons"
import { BrandContext } from "../../../context/Brand.context";
import {
    BrandService,
    CampaignService,
    GrowthtoolService,
} from "../../../services";
import localforage from "localforage";
const RefferalPage = () => {
    const [campaign, setCampaign] = useState(null)
    const [growthTool, setgrowthTool] = useState(null)
    const [brandz, setBrandz] = useState(null)
    const [state, setState] = useState(null)



    const onload = async () => {
        // let brand = await localforage.getItem('brand')
        const brandQuery = { _id: window.location.href.split("/")[window.location.href.split("/").length - 2], delete: false }
        let brand = await BrandService.GetPublic({ query: brandQuery })
        if (brand.response.success) setBrandz(brand.response.data)
        console.log('query brand', brand)
        const campaignData = await CampaignService.GetPublicCampaign({
            query: { brandId: brand.response.data._id, campaignType: 5 },
        });
        console.log('query campaignData', campaignData)

        setCampaign(campaignData.response.data[0]);
        const query = {
            brandId: brand.response.data._id,
            campaignId: campaignData.response.data[0]._id,
            growthtoolType : "landingPage"
        };
        const { error, response } = await GrowthtoolService.GetPublicGrowthTool({
            query,
        });
        console.log("ERROR", error);
        error && setState({ ...state, apiError: error.message, loader: false });
        if (response) {

            let flag = response.data.some((e) => e.referralUrl === true) || false
    
            if (!flag) {
                let landings = response.data.filter((e) => e.growthtoolType == "landingPage")
                console.log('landings', landings)
                setgrowthTool([landings[0]])
            } else {
                setgrowthTool(response.data.filter((e) => e.referralUrl === true))
            }

        }
    }
    const handleTwitterShareClick = () => {
        const sharedLink = growthTool ? `https://catchly.io/page/${growthTool[0]._id}/${window.location.href.split("/")[window.location.href.split("/").length - 1]}` : ''

        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(sharedLink)}`, '_blank');
    };
    const handleLinkedInShareClick = () => {
        const sharedLink = growthTool ? `https://catchly.io/page/${growthTool[0]._id}/${window.location.href.split("/")[window.location.href.split("/").length - 1]}` : '';

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(sharedLink)}`, '_blank');
    };
    const handleShareFacebookClick = () => {
        const sharedLink = growthTool ? `https://catchly.io/page/${growthTool[0]._id}/${window.location.href.split("/")[window.location.href.split("/").length - 1]}` : ''
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharedLink)}`, '_blank');
    };
    const handleWhatsAppShareClick = () => {
        const dataToShare = growthTool ? `https://catchly.io/page/${growthTool[0]._id}/${window.location.href.split("/")[window.location.href.split("/").length - 1]}` : ''
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(dataToShare)} `, '_blank');
    };
    const handleEmailClick = () => {
        const emailAddress = 'Your@email.com';
        const subject = 'Refferal URL';
        const message = growthTool ? `https://catchly.io/page/${growthTool[0]._id}/${window.location.href.split("/")[window.location.href.split("/").length - 1]}` : ''

        const mailtoLink = `mailto:${encodeURIComponent(emailAddress)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;

        window.location.href = mailtoLink;
    };



    useEffect(() => {
        onload()
    }, [])


    return (
        <>
            {
                brandz?.referral?.show &&
                <div id="RefSharePage" style={{ flexDirection: 'column' }} className="vh-100 w-100 d-flex justify-content-center align-items-center">
                    <div className="wpx-65 hpx-65">
                        <img style={{ borderRadius: '50%' }} className="w-100 h-100" src={brandz.brandLogo || PngIcons.ActiveChat} />
                    </div>
                    <div className="bigH mt_32">
                        {brandz?.referral?.title}
                    </div>
                    <div className="mt_12 myhp d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: brandz?.referral?.body }}>

                    </div>
                    <div className="mt_32 wpx-482 hpx-55 mainCopy d-flex">
                        <div className="w-85  big">

                            {growthTool && `https://catchly.io/page/${growthTool[0]._id}/${window.location.href.split("/")[window.location.href.split("/").length - 1]}`}
                        </div>
                        <div className="w-15 copy d-flex justify-content-center align-items-center cp">
                            <CustomCopytoClip
                                data={growthTool && `https://catchly.io/page/${growthTool[0]._id}/${window.location.href.split("/")[window.location.href.split("/").length - 1]}`}
                                icon={<SvgIcons.CopyWhite />}
                            />

                        </div>
                    </div>
                    <div className="onSocialMedia">
                        Share on social media
                    </div>

                    <div className="d-flex gap-32 mt_19 mainIcons">
                        <div className="cp" onClick={() => handleTwitterShareClick()}><SvgIcons.TwitterX /></div>
                        <div className="cp" onClick={() => handleShareFacebookClick()}><SvgIcons.FacebookX /></div>
                        <div className="cp" onClick={() => handleLinkedInShareClick()}><SvgIcons.LinkdlnX /></div>
                        {/* <div className="cp" onClick={() => handleMessengerShareClick()}><SvgIcons.MessengerX /></div> */}
                        <div className="cp" onClick={() => handleWhatsAppShareClick()}><SvgIcons.WhatsAppX /></div>
                        <div className="cp" onClick={() => handleEmailClick()}><SvgIcons.TweetX /></div>
                    </div>
                </div>
            }
        </>


    )

}

export default RefferalPage