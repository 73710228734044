import { RadioGroup } from '@mui/material'
import localforage from 'localforage'
import React, { useEffect, useState } from 'react'
import CustomCheckBox from '../../../../components/CustomCheckBox'
import CustomRadio from '../../../../components/CustomRadio'
import CustomSelect from '../../../../components/CustomSelect'
import CustomTextField from '../../../../components/CustomTextField'
import { SvgIcons } from '../../../../icons'
import { DealService } from '../../../../services'
import { utils } from '../../../../utils'
import Fade from 'react-reveal/Fade';

function VisitTriggers({formik}) {
    const [brand, setBrand] = useState({});
    const [loader, setLoader] = useState(true);
    const [EW, setEW] = useState({});
    const [state, setstate] = useState({
        filteredDeals : [],
        rewardRedemption             : 
          {
              costingPoint: '',
              cwCode      : '',
              name        : '',
              rewardValue : '',
          }
      });
    useEffect(async () => {
    
        let brandData = await localforage.getItem('brand');
        setBrand(brandData);
    
        // let earningways = await localforage.getItem("selectedLoyalityEarningWays");
        let earningways = await localforage.getItem("earningways");
        for (const value of earningways) {
            if(value?.activity === 'spending') setEW(value)
        }

        let deal = await localforage.getItem("deal");
        if (deal === null || deal.length === 0) {
          const query = { delete: false, brandId: brandData._id };
          const { error, response } = await DealService.Get({ query });
          if (response) deal = response.data;
        }
    
        let temp = [];
        if (deal && deal.length > 0)
          for (const value of deal) {
            temp.push({
              label: value.name,
              value: value._id,
              image: <img src={value.image} width="20px" heigth="20px" />,
              data: value,
            });
          }
          setstate({...state, filteredDeals : temp})

          setLoader(false)
      }, []);
// console.log('STATE',state.filteredDeals.map(value=>({'name':value.label , 'value' : value.value})));
    const dealListOptions = () => {
        return state?.filteredDeals?.length>0 ?  state.filteredDeals.map(value=>({'name':value.label , 'value' : value.value})) : []
        // (
        //     <>
        //         {/* <option value="">None</option> */}
        //         <option disabled>Select</option>
        //         {state?.filteredDeals?.length>0 && state.filteredDeals?.map((value)=>(
        //             <option className='Capitalize' value={value.value}>{utils.capitalize(value.data.name)}</option>
        //         ))}
        //     </>
        // )
      }
      // console.log('state.filteredDeals ', dealListOptions)

  return ( !loader &&
    <Fade left>
    <div>
        <h1>Formation</h1>

        <div className='mt_32 Heading18M color-neutral90 mb_16 underline'>Setup reward for visitors</div>
        <div>
            <div className='B14M color-neutral80 mb_8'>Please select first reward.</div>
            <CustomSelect onChange={(e)=>formik.setValues({...formik.values, reward1: e.target.value})} value={formik.values?.reward1} 
            options  = {dealListOptions()} 
            />
        </div>
        {formik.values.CouponStrategy !== 'One time'&&<div>
            <div className='B14M color-neutral80 mb_8 mt_24'>Please select second reward, this will show upon redeeming first reward.</div>
            <CustomSelect 
            options  = {dealListOptions()} 
            onChange={(e)=>formik.setValues({...formik.values, reward2: e.target.value})} value={formik.values?.reward2}/>
        </div>}
        {formik.values.CouponStrategy !== 'One time'&&formik.values.CouponStrategy !== '2 Coupons'&&<div>
            <div className='B14M color-neutral80 mb_8 mt_24'>Please select third reward, this will show upon redeeming second reward.</div>
            <CustomSelect onChange={(e)=>formik.setValues({...formik.values, reward3: e.target.value})} value={formik.values?.reward3} 
            options  = {dealListOptions()} 
            />
        </div>}
        {formik.values.CustomerEngagement == "repeation"&&<div>
            <div className='B14M color-neutral80 mb_8 mt_24'>Following loyalty program will start upon redeeming third reward</div>
            <div className='p_16 borderRadius-6 border-primary50 disabled'>
                <CustomRadio 
                    value={"User.CreatedFor.CLIENT"} checked
                    label={<div className=''>
                        <div className='B14M color-neutral80'>{"Points "+utils.capitalize(EW?.earningType)}</div>
                        <div className='B10M mt_6 color-neutral50'>Points are earned based on spending. The more spending, the more points will be gained, demonstrating a direct correlation. The value of each point can be set, for example, with 1 point equaling 1 dollar.</div>
                    </div>}
                />
            </div>
            {EW?.earningType!=="range"&&<CustomTextField 
                className   = "mt_24 disabled" disabled
                type        = "number"
                placeholder = "Point Cost"
                value       = {EW?.earningValue}
                icon        = {<div className='mt_24 disabled'>{EW?.earningType==="incremental"?"Points":"Amount"}</div>}
                position    = 'end'
                paddingRight={30}
            
            />}
            {
                // console.log('EW',EW)
            }
            {EW?.earningType==='range'&&
                <div className="position-relative disabled mt_16">
                {EW?.earningValueRange.map((element, idx)=><div className="signelEarningRange w-100 mb_16">
                    <div className="d-flex showArrow justify-content-center " >
                        <div style={{width: 100}}>
                            <CustomTextField 
                                icon     = "$"
                                position = "start"
                                top      = "10px"
                                type     = "number"
                                width    = "w-100"
                                value       = {EW?.earningValueRange[idx].minValue}
                            />
                        </div>
                        <div className="text-center ml_5 mr_5 align-self-center color-neutral100">-</div>
                        <div className='mr_40 showArrow' style={{width: 100}}>
                            <CustomTextField 
                                icon      = "$"
                                position  = "start"
                                top       = "10px"
                                width     = "100px"
                                type      = "number"
                                className = {idx===0?(EW?.earningValueRange[idx].check ? "mr_8" : "mr_8"):(EW?.earningValueRange[idx].check ? "mr_8" : "mr_8")}
                                value     = {EW?.earningValueRange[idx].maxValue}
                            />
                        </div>
                    </div>
                    <div className="d-flex mt_8 wrapRemove justify-content-center">
                       <div className="Body14R color-neutral60 ">Points awarded:</div>
                        <input 
                                type      = "number"
                                value     = {EW?.earningValueRange[idx].points ? EW?.earningValueRange[idx].points : 0}
                                className = "outline-none border-none color-neutral100 B14M ml_5 underline background-inherit"
                        />
                    
                    </div>
                </div>)}
            </div>
            }
            <div className='B14M color-neutral80 mb_8 mt_24'>Please select reward</div>
            <CustomSelect options  = {dealListOptions()} onChange={(e)=>formik.setValues({...formik.values, rewardPoints: e.target.value})} value={formik.values?.rewardPoints} />
            <CustomTextField 
                className   = "mt_24"
                type        = "number"
                placeholder = "Point Cost"
                // icon        = {<div className='mr_20'>Points</div>}
                name        = "points"
                icon        = {<div className='mt_24 pr_15'>Points</div>}
                // icon        = {"Points"}
                value       = {formik.values.points}
                // paddingLeft={"40px"}
                onChange    = {formik.handleChange}
                position    = 'end'
                // paddingRight={50}
               
                />
        </div>}

        <div className='mt_40 Heading18M color-neutral90 mb_16 underline'>Do you want to referral?</div>
        <RadioGroup name="referral" className='mb_40' value = {formik.values.referral} onChange = {formik.handleChange}>
            <div className={"border pl_16 pt_10 pb_5 mb_16"}>
                <CustomRadio 
                    value = "Yes"
                    label = {<div className='B14M color-neutral80'>Yes</div>}
                />
            </div>
            <div className={"border pl_16 pt_10 pb_5"}>
                <CustomRadio 
                    value={"No"}
                    label = {<div className='B14M color-neutral80'>No</div>}
                />
            </div>
        </RadioGroup>
        {formik.values.referral === 'Yes'&&<div>
            <CustomTextField 
                className   = "mt_24"
                type        = "number"
                label       = "Number of Invite*"
                placeholder = "Number of Invite*"
                name        = "invites"
                value       = {formik.values.invites}
                onChange    = {formik.handleChange}
            />
            <div className='mb_16 mt_16'>
                <CustomSelect name        = "type"
                value       = {formik.values.type}
                onChange    = {formik.handleChange} label={'Referral Type*'} 
                options={[
                    {
                      name : 'Sign Up',
                      value : 'signup'
                    },
                    {
                      name : 'Activity',
                      value : 'activity'
                    }
                  ]
                    // <>
                    // <option value={'signup'}>Sign Up</option>
                    // <option value={'activity'}>Activity</option>
                    // </>
                } />
            </div>
            <CustomSelect label={'Sender Reward*'} options  = {dealListOptions()} onChange={(e)=>formik.setValues({...formik.values, rewardSender: e.target.value})} value={formik.values?.rewardSender}/>
            <div className='mb_16 mt_16'>
                <CustomSelect label={'Receiver Reward*'} options  = {dealListOptions()} onChange={(e)=>formik.setValues({...formik.values, rewardReceiver: e.target.value})} value={formik.values?.rewardReceiver} />
            </div>
            </div>}
        
    </div>
    </Fade>
  )
}

export default VisitTriggers