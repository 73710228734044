import * as React from 'react';
import {
  DataGrid,
  // GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import EmptyScreen from './EmptyScreen';
import Loader from './Loader';
import { SvgIcons } from '../icons';
import { ColorSchemeCode } from '../enums/ColorScheme';
import { IconButton } from '@mui/material';
import localforage from 'localforage';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function CustomTableV2(props) {
  
  const [check, setCheck] = React.useState()
  const [loader, setLoader] = React.useState(true);

  setTimeout(() => {

    if(document.getElementById("CustomTableV2") && document.getElementById("CustomTableV2").getElementsByClassName('MuiDataGrid-window') && document.getElementById("CustomTableV2").getElementsByClassName('MuiDataGrid-window')[0])
      document.getElementById("CustomTableV2").getElementsByClassName('MuiDataGrid-window')[0].onscroll = (event) => {
        var scrolled = document.getElementById("CustomTableV2").getElementsByClassName('MuiDataGrid-window')[0].scrollTop;
        var position = document.getElementById("CustomTableV2").getElementsByClassName('MuiDataGrid-window')[0].offsetTop;

        if(scrolled > position ){
          document.getElementById("CustomTableV2").getElementsByClassName('MuiDataGrid-columnsContainer')[0].style.boxShadow = '0px 4px 4px rgba(100, 121, 143, 0.12)'
        }
        else{
          document.getElementById("CustomTableV2").getElementsByClassName('MuiDataGrid-columnsContainer')[0].style.boxShadow = ''
        }
    };
  }, 200);

  return (
  
    <div id="CustomTableV2" className="position-relative" style={{ height: props.height || 500, width: '100%' }}>
      <DataGrid
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={{
          toolbarDensity      : '',
          toolbarDensityLabel : '',
          toolbarExport       : '',
          toolbarExportLabel  : '',
        }}
        rows       = {props.rows}
        onRowClick = {props.onRowClick}
        className  = "cp"
        width      = {'100%'}
        columns    = {props.columns}
        // GridUpdateAction = 'delete'
        disableSelectionOnClick
        pageSize           = {props.pageSize || 10}
        rowsPerPageOptions = {[props.pageSize || 10]}
        checkboxSelection  = {props.checkbox || false}
        disableColumnMenu
        // components={{
        //   Toolbar    : GridToolbar,
        // }}
        onSelectionModelChange={(ids) => {
          setCheck(ids)
          if(props.deleteAll) props.setSelectedRows(ids)
        }}
      />  
      {/* <div className="position-absolute cp deleteIcon display" style={{top: '19px', left: '100px'}}> */}
        {props.deleteAll && check && check.length > 0 && <IconButton className="position-absolute cp deleteIcon display" style={{top: '8px', right: '35px'}} onClick={props.handleDelete}>
          <SvgIcons.DeleteIcon height="18" width="auto" color={ColorSchemeCode.Paragraph}/>
        </IconButton>}
      {/* </div> */}
      {props.rows && props.rows.length <= 0 && <div className="position-absolute noData">
        <EmptyScreen
            title        = {props.title}
            showBtn      = {false}
            // otherCaption = {`No ${props.title} found according to your current filter`}
          />
      </div>}
    </div>
  );
}