import React, { useContext, useEffect, useState } from 'react'
import CustomCheckBox from '../../../../components/CustomCheckBox'
import CustomTextField from '../../../../components/CustomTextField'
import { AgencyContext } from '../../../../context/Agency.context'
import { ColorSchemeCode } from '../../../../enums/ColorScheme'
import { SvgIcons } from '../../../../icons'
import { utils } from '../../../../utils'

function FromModal({state, setState, data, setData, result, setResult}) {
    const agency = useContext(AgencyContext)
    const [color, setColor] = useState('')
    

    const handleSearch = (e) => {
        const result = data.subBrands.filter(item =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setResult(result)
    }  

    useEffect(() => {
        const color = utils.hexTohsl(agency.agencyColor)
        setColor("hsl("+color.h+","+color.s+"%,"+color.l+"%,"+0.1+")")
    }, [agency])


    return (
        <div id="FromModal">
            <div className="d-flex align-items-center justify-content-space-between">
                <div className="fs-18 fw-6 color-c000000">From:</div>
                <div className="crossicon middle cp" onClick={()=>setState({...state, fromOpen: false})}><SvgIcons.CrossIcon/></div>
            </div>
            <CustomTextField onChange={(e)=>handleSearch(e)} placeholder="Search" border="0px"/>
            <div className="d-flex flex-wrap">
                {
                    data.agency[0].check && 
                    <div className="d-flex p_6 borderRadius-40 w-fit-content-content fs-14 fw-5 ml_8 mt_8" 
                        style={{
                            color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                            backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                            }}>{data.agency[0].name}
                            <div onClick={()=>{
                                data.agency[0].check = false
                                setData({...data})
                            }} 
                        className="ml_10 cp"><SvgIcons.CrossIcon color={agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED}/></div>
                    </div>
                }
                {
                    data.subBrands.map((value, index)=>(
                        value.check && 
                        <div className="d-flex p_6 borderRadius-40 w-fit-content-content fs-14 fw-5 ml_8 mt_8" 
                            style={{
                                color           : agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED,
                                backgroundColor : agency.agencyColor ? color : ColorSchemeCode.cF6F9FE
                                }}>{value.name}
                                <div onClick={()=>{
                                    result.map((v,i)=>{
                                        if(v.name === value.name){
                                            result[i].check = false
                                            setResult([...result])
                                        }
                                    })
                                }} 
                            className="ml_10 cp"><SvgIcons.CrossIcon color={agency.agencyColor ? agency.agencyColor : ColorSchemeCode.c2F80ED}/></div>
                        </div>
                    ))
                }
            </div>

            <div className="bar mt_8 mb_24" ></div>

            <div className="fs-14 fw-5 color-c000000 mb_16">Agency</div>
            <div className="d-flex justify-content-space-between">
                <div className="d-flex">
                    <img className="listicon mr_8" src={data.agency[0].url} alt="url"></img>
                    <div className="fs-14 color-c000000">{data.agency[0].name}</div>
                </div>
                <CustomCheckBox value={data.agency[0].check} onChange={()=>{
                    data.agency[0].check = !(data.agency[0].check)
                    setData({...data})
                }} />
            </div>
            <div className="mt_32">
                <div className="d-flex align-items-center justify-content-space-between fs-14 fw-5 color-c000000 mb_16">
                    Sub Brands
                <CustomCheckBox value={data.selectAll} onChange={()=>{
                    data.selectAll = !(data.selectAll)
                    result.forEach((value, index)=>( result[index].check = data.selectAll ))
                    setResult([...result])
                }} />
                </div>
                {
                    result.map((value, index)=>(
                        <div className="d-flex justify-content-space-between mb_16">
                            <div className="d-flex">
                                <img className="listicon mr_8" src={value.url} alt="url"></img>
                                <div className="fs-14 color-c000000">{value.name}</div>
                            </div>
                            <CustomCheckBox value={value.check} onChange={()=>{
                                result[index].check = !(result[index].check)
                                setResult([...result])
                            }} />
                            </div>
                    ))
                }
            </div>
        </div>
    )
}

export default FromModal
