import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
const service  = '/v4/services/vipClub'

const CheckoutSession = async ({payload, headers}) => await axios.post(env.API_URL+service + '/checkoutsession' , payload, {headers})

const VipClubService = {
    CheckoutSession     : Handlers.Services(CheckoutSession),
}

export default VipClubService
