import React, { useContext, useEffect, useState } from 'react'
import CustomDateRange from '../../../components/CustomDateRange'
import CustomSearch from '../../../components/CustomSearch'
import CustomTableV2 from '../../../components/CustomTableV2'
import Loader from '../../../components/Loader'
import { BrandContext } from '../../../context/Brand.context'
import { LayoutContext } from '../../../context/Layout.context'
import { SvgIcons } from '../../../icons'
import { LogsService } from '../../../services'
import { ClickOutside } from '../../../utils'

function Logs() {
    const [loader, setLoader] = useState(true)
    const [data, setData]     = useState([])
    const [result, setResult] = useState([])
    const layout              = useContext(LayoutContext)
    const brand               = useContext(BrandContext)
    const [filteredData , setFilteredData] = useState([]);
    useEffect(()=>{
        layout.setLayout({
            title       : "Settings",
            backTitles: [],
            switch      : "",
            button      : '',
            borderBottom: false,
        });
    },[])
    useEffect(async() => {
        let arr = []
        let body = []
      const logsData = await LogsService.Get({query: {delete: false, brandId: brand._id}})
      if(logsData.response&&logsData.response.success&&logsData.response.data&&logsData.response.data.length>0){
        setData(logsData.response.data)
        arr = pushArr(logsData.response.data, body)
    }
    setResult(arr)
    setFilteredData(arr)
    setLoader(false)
}, [])

console.log("Stuff",data);
    const handleUserSearch = (e) => {
        // console.log('result length', result.length);
        let filtereddata = result.filter((element, idx) => {
                // console.log('element?.activity?.collection ', element , e.target.value)
                return element?.collection?.toLowerCase()?.includes(e.target.value.toLowerCase())
        });
        setFilteredData(filtereddata);
      };

    const columns = [
        { field: 'collection', headerName: 'Collection', flex: 4 },
        { field: 'action', headerName: 'Action', flex: 4 },
        { field: 'created', headerName: 'Created', flex: 4 },
    ];


    const pushArr = (arr, body) => {
        arr.forEach((v,index) => {
            body.push({
                id         : v._id,
                value      : v,
                collection : v.activity.collection,
                action     : v.activity.type,
                created    : new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
            })
        });

        return body
    }

    const handleDateChange = (ranges) => {
        let startDate =  new Date(ranges.selection.startDate).valueOf();
        let endDate   =  new Date(ranges.selection.endDate).valueOf();
        let arr     = []
        let body    = []
        let tempArr = []
        if(data&&data.length>0)
        for (const value of data) {
            if(value.created >= startDate && value.created <= endDate){
                tempArr.push(value)
            }
        }
        if(tempArr.length>0) arr = pushArr(tempArr, body)

        setResult(arr)
        setFilteredData(arr)
        setLoader(false)
    }

      return (
    <div className="">
        {
            loader ?
            <Loader />
            :
            <>
            <div className='d-flex space-between mb_12 w-100 align-items-center'>
                        <div className='Heading22M color-neutral100'>Logs</div>
                    </div>
                    <div className='bar w-100'></div>   
            <div className='search d-flex justify-content-right w-100 pb_16  mt_16'>
                <CustomSearch 
                    placeholder    = {'Search'}
                    className      = {'w-100 float-right'}
                    position       = {'start'}
                    height         = "36px"
                    onChange       = {handleUserSearch}
                />
            </div>
            <div className='w-100'>
                <div className='bar'></div>
                <div className='d-flex w-100 color-neutral90 Link11M audienceTableGrid overflow-hidden'>
                    <div className='col-5 pt_16 pb_16 cp '>
                        {filteredData ? filteredData.length+" LOGS" : "LOGS"}
                    </div>
                    <div className='col-4 pt_16 pb_16 cp'>
                        ACTION
                    </div>
                    <div className='col-3 text-right pt_16 pb_16 cp'>
                        TIMESTAMP
                    </div>
                </div>
                <div className='bar'></div>

                <div className='data' style={{height: 'calc(100vh - 230px)', overflowY: 'scroll'}}>

                {
                    filteredData && filteredData.length > 0 && filteredData.map((element,idx)=>(
                        <>
                        <div className='d-flex color-neutral90 Link13R align-items-center w-100 dataRowSmall tableRowHover' >
                            <div className='col-5 '>
                            {element.collection}
                            </div>
                            <div className='col-4 '>
                            {element.action}
                            </div>
                            <div className='col-3 text-right'>
                                {element.created}
                            </div>
                        </div>
                        <div className='bar'></div>
                        </>
                    ))
                }

                    </div>
            </div>
                {/* <div className="col-9">
                    <div style={{top: '20px', right: '20px'}} className={`subscribedDate z-index-1 position-absolute Body14R color-neutral60 cp d-flex ${showDate && 'isActiveDate'}`} onClick={()=>setShowDate(!showDate)}>
                        <>
                            <SvgIcons.FilterSearch/>
                        </>
                    </div>
                    <ClickOutside onClick={()=>setShowDate(false)}>
                        <div style={{top: '20px', right: '20px'}} className="position-absolute z-index-1 dateBox ">
                            {showDate && <CustomDateRange onChange={handleDateChange}/> }
                        </div>
                    </ClickOutside>
                </div> */}
                {/* <div>
                    <CustomTableV2
                        pageSize      = {50}
                        height        = {'calc(100vh - 120px)'}
                        checkbox      = {false}
                        rows          = {result}
                        title         = {'Logs'}
                        columns       = {columns}
                        deleteAll     = {false}
                        handleRefresh = {()=>console.log('refresh clicked')}
                        onRowClick    = {(value)=>console.log('row clicked', value)}
                        />
                </div> */}
            </>
        }
    </div>
  )
}

export default Logs