import { RadioGroup } from '@mui/material';
import React from 'react'
import CustomRadio from '../../../../components/CustomRadio';
import Fade from 'react-reveal/Fade';

function CouponStrategy({formik}) {
  return (
    <Fade bottom>
    <div>
      <h1>CouponStrategy</h1>

      <form >
        <div className="auth-form">
          <RadioGroup
            // name     = "walletlyUse"
            value    = {formik.values.CouponStrategy}
            onChange = {(e)=>{
              // console.log('e ', e.target.value, formik.values.CouponStrategy)
              formik.values.CouponStrategy = e.target.value;
              formik.setValues({...formik.values})

          }}
            // onFocus  = {formik.handleBlur}
          >

            <div className={formik.values.CouponStrategy == "One time" ? "w-100 radioParentp selected" : "w-100 radioParentp"}>
              <CustomRadio
                value   = {'One time'}
                label   = {
                  <div className='standAlone '>
                    <h3 className="Body14M mt_11">One time Coupon only</h3>
                    <p className="Body10M">Points are earned based on spending. The more spending, the more points will be gained.</p>
                  </div>
                }
                className="mt_16 "
              />
            </div>

            {console.log('formik ', formik.values)}

            <div className={formik.values.CouponStrategy == "2 Coupons" ? "w-100 radioParentp selected" : "w-100 radioParentp"}>
              <CustomRadio
                value   = {'2 Coupons'}
                label   = {
                  <div className='standAlone '>
                    <h3 className="Body14M mt_11">2 Coupons</h3>
                    <p className="Body10M">Points are earned based on spending. The more spending, the more points will be gained.</p>
                  </div>
                }
                className="mt_16"
              />
            </div>
            <div className={formik.values.CouponStrategy == "3 Coupons" ? "w-100 radioParentp selected" : "w-100 radioParentp"}>
              <CustomRadio
                value   = {'3 Coupons'}
                label   = {
                  <div className='standAlone '>
                    <h3 className="Body14M mt_11">3 Coupons</h3>
                    <p className="Body10M">Points are earned based on spending. The more spending, the more points will be gained.</p>
                  </div>
                }
                className="mt_16"
              />
            </div>

          </RadioGroup>
        </div>
      </form>
    </div>
    </Fade>
  )
}

export default CouponStrategy