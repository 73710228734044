import React, { createRef, useContext ,useRef,useState } from 'react'
import { AgencyContext } from '../../context/Agency.context';
import { useFormik } from "formik";
import { BrandVld } from '../../validation';
import LogoUploader from '../../components/LogoUploader';
import CustomButton from "../../components/CustomButton";
import CircularProgress from "@mui/material/CircularProgress";
import {PngIcons, SvgIcons} from '../../icons'
import CustomsModal from '../../components/CustomModal';
import { ImageModalV2 } from '../../components/ImageModalV2/ImageModalV2';
import CustomImageDrop from '../../components/CustomImageDrop';
import { FormHelperText } from '@mui/material';
import { ColorSchemeCode } from '../../enums/ColorScheme';


export function Branding ({formik}) {

  const [imageModal, setImageModal]           = useState({
    logo  : {open: false, imageEvent: '', return: ''},
    cover : {open: false, imageEvent: '', return: ''}
  })

    const logoRef  = useRef();
    const coverRef = useRef();


    const dragOver  = (e) => e.preventDefault();
    const dragEnter = (e) => e.preventDefault();
    const dragLeave = (e) => e.preventDefault();
    
    

    return (
        <div>
        <h3 className='mt_25 mb_15'>Add your branding.</h3>

       <form onSubmit={formik.handleSubmit}>
        <div >
            <div className='w-100 mb_5 border position-relative borderRadius-4 text-center cp' style={{height : '150px', background:"#f5f7fc"}}>
              {formik.values.brandLogoFile && <div onClick={(e)=>{e.stopPropagation(); formik.setValues({...formik.values, brandLogoFile : ''})}} className="position-absolute cp removeIcon"><SvgIcons.CrossIcon color={ColorSchemeCode.white}/></div>}
              <label htmlFor="imageUpload" className="contents col-12 p-0 middle h-100  text-center" >
                  <div className={(formik.values.brandLogo || formik.values.brandLogoFile) && !formik.errors.brandLogoFile ? "subheading dropImageCircle cp image text-center" : "subheading dropImageCircle cp text-center"}>
                      {(formik.values.brandLogo || formik.values.brandLogoFile) && !formik.errors.brandLogoFile && 
                      <img 
                          src          = {formik.values.brandLogoFile ? URL.createObjectURL(formik.values.brandLogoFile) : formik.values.brandLogo}
                          className    = "insideImage"
                          alt          = "new"
                          style={{zIndex: 0}}
                      />}
                      <span 
                          className    = {(formik.values.brandLogo || formik.values.brandLogoFile) && !formik.errors.brandLogoFile ?
                                              "imageUpload cp fs-14 color-Secondary remove" : "fs-14 cp color-Secondary imageUpload"}
                          onDragOver  = {dragOver}
                          onDragEnter = {dragEnter}
                          onDragLeave = {dragLeave}
                          onDrop      = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                      >
                          <div className="mt_2">
                              <label htmlFor="imageUpload" className="mb_0">
                                  <div className="cp">
                                      <SvgIcons.ImageDropIcon/>
                                  </div>
                                  <div className="color-neutral100 Link14M fs-14 cp mt_8 logoHeading">Add business logo*</div>
                              </label>
                              <div className="Link13R color-neutral60 mt_8">
                                  or drag or drop
                              </div>
                              <input
                                  id      = "imageUpload"
                                  style   = {{display:'none'}}
                                  type    = "file"
                                  ref     = {logoRef}
                                  onClick = {(e)=>{
                                    if (e.target.files[0]) {
                                        logoRef.current.value = "";
                                    }
                                  }}
                                  onChange = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                                  // onChange = {fileChange}
                              />
                          </div>
                      </span>
                  </div>

                  <CustomsModal
                      open      = { imageModal.logo.open }
                      minWidth  = "470px"
                      minHeight = "470px"
                      padding   = "0px"
                      component = { 
                          <ImageModalV2 
                              imageModal    = { imageModal }
                              setImageModal = { setImageModal }
                              image         = { 'logo' }
                              formik        = { formik }
                          />
                      }
                  />

              </label>
              {formik.values.brandLogoFile && formik.errors.brandLogoFile && <div className="mx-auto error">{formik.errors.brandLogoFile}</div>}
            </div>

            <div style={{height: '160px',background:""}} className="w-100">
              <div className="w-100 position-relative">
                  {formik.values.brandCoverFile && <div onClick={(e)=>{e.stopPropagation(); formik.setValues({...formik.values, brandCoverFile : ''})}} className="position-absolute cp removeIcon mt_12 mr_4"><SvgIcons.CrossIcon color={ColorSchemeCode.white}/></div>}
                  <CustomImageDrop 
                      imageUrl = {formik.values.brandCoverFile ? URL.createObjectURL(formik.values.brandCoverFile) : formik.values.brandCover}
                      onChange = {(e)=> {setImageModal({...imageModal, cover: {...imageModal.cover, open: true, imageEvent: e}});}}
                      formik   = {formik}
                      ref      = {coverRef}
                      isImage  = {formik.values.brandCoverFile || formik.values.brandCover}
                     
                      text     = {<>
                                  <div>
                                      <SvgIcons.ImageDropIcon/>
                                  </div>
                          <div className="color-neutral100 Link14M fs-14 cp mt_8 logoHeading">Add business cover image </div>
                          <div className="Link13R color-neutral60 mt_8">
                                      or drag or drop
                              </div>
                          </>
                      }
                  />  
                  <FormHelperText>{formik.touched.brandCoverFile && formik.errors.brandCoverFile ? formik.errors.brandCoverFile : ''}</FormHelperText>
              </div>

          <CustomsModal
              open      = { imageModal.cover.open }
              onClose   = { ()=> setImageModal({...imageModal, cover: {...imageModal.cover, open: false}}) }
              minWidth  = "470px"
              minHeight = "470px"
              padding   = "0px"
              component = { 
                  <ImageModalV2 
                      imageModal    = { imageModal }
                      setImageModal = { setImageModal }
                      image         = { 'cover' }
                      type         = { 'cover' }
                      formik        = { formik }
                  />
              }
          />
          
            </div> 
          </div>
        </form>
     </div>
    )
  }

export default Branding