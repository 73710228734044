import React from 'react'
import SettingHeader from '../../../components/setting/SettingHeader'

function RuleBuilder(props) {
    return (
        <div id="RuleBuilder">
        </div>
    )
}

export default RuleBuilder
