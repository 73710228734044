import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/tier'

const PreBuild = async ({payload}) => await axios.post(env.API_URL+service+'/prebuild', payload, {headers: await MainService.getTokenHeader()})

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Get = async ({query}) => await axios.get(env.API_URL+service+'/?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetPublic = async ({query}) => await axios.get(env.API_URL+service+'/public?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetValidation = async ({query}) => await axios.get(env.API_URL+service+'/tiervalidation?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

// const Delete = async({payload}) => await axios.put(env.API_URL+service+'/delete',payload, {headers: await MainService.getTokenHeader()})

const TierService = {
    Create        : Handlers.Services(Create),
    Get           : Handlers.Services(Get),
    GetPublic     : Handlers.Services(GetPublic),
    GetValidation : Handlers.Services(GetValidation),
    Update        : Handlers.Services(Update),
    PreBuild      : Handlers.Services(PreBuild),
    // Delete   : Handlers.Services(Delete),
}

export default TierService
