import React, { useState } from "react";

const LayoutContext = React.createContext();

function withLayout(Component){
    function Layout(props){
        const [elements, setElements] = useState({
            title       : '',
            button      : '',
            mainPage    : '',
            edit        : false,
            scrollShadow: false,
            placeholder : '',
            borderBottom: false,
            switch      : '',
            backTitles  : []
        })
    
        const setLayout = (updates) => setElements({...elements, ...updates})
    
        return(
            <LayoutContext.Provider value={{elements, setLayout}}>
                <Component {...props} />
            </LayoutContext.Provider>
        )
    }

    return Layout
}


export { withLayout, LayoutContext }