import React, {useContext, useEffect} from 'react'

import CustomTable from '../../../../components/customTable/CustomTable';
import { BrandContext } from '../../../../context/Brand.context';
import { Campaign, Transactions } from '../../../../enums';
import { utils } from '../../../../utils';

export default function Coupon({customer}) {
    const brand = useContext(BrandContext)

    const [tableData, setTableData] = React.useState([])

    const onLoad = () => {
        if(!customer.transactions) return 
        const rows = customer.transactions.map(transaction=>createData({
            amount          : transaction.coupon.amount,
            timestamp       : utils.getDate(transaction.created, true),
            discountAmount  : (transaction.coupon.discountType === Campaign.CampaignTypes.AMOUNT_COUPON && transaction.transactionType !== Transactions.TransactionTypes.FIXED_AMOUNT) 
                                ? transaction.coupon.amount - transaction.coupon.discountValue 
                                : (transaction.coupon.discountType === Campaign.CampaignTypes.AMOUNT_COUPON && transaction.transactionType === Transactions.TransactionTypes.FIXED_AMOUNT ) 
                                    ? transaction.coupon.discountValue 
                                    :(transaction.coupon.discountType === Campaign.CampaignTypes.PERCENT_COUPON) 
                                        ?(transaction.coupon.amount - (transaction.coupon.amount * (transaction.coupon.discountValue/100)))
                                        : '-',
            discountValue   : transaction.coupon.discountValue || transaction.coupon.discountDeal,
            discountType    : transaction.coupon.discountValue ? transaction.coupon.discountType === Campaign.CampaignTypes.AMOUNT_COUPON ? '$' : '%' : 'N/A',
            id              : transaction._id
        }))
        setTableData([...rows])
    }

    useEffect(onLoad,[])

    const tableHeadings =  [
        { id: 'amount', label: 'Actual Amount' },
        { id: 'discountAmount', label: 'Discounted Amount' },
        { id: 'discountValue', label: 'Discount Value' },
        { id: 'discountType', label: 'Discount Type'},
        { id: 'timestamp', label: 'Timestamp', class: 'pl_20 float-right' },
    ]


    function createData({amount, discountAmount, discountValue, discountType, timestamp, id}) {
        return { 
            props: {
                name     : 'rowProps',
                class    : '',
                id       : id,
                checkbox : false
            },
            amount: {
                label : amount ? `${amount} ${brand.currency && brand.currency.symbol}` : 'N/A',
                class : '',
            }, 
            discountAmount: {
                label   : discountAmount && discountAmount !== '-' ? `${discountAmount} ${brand.currency && brand.currency.symbol}` : 'N/A',
                class   : '',
            },
            discountValue: {
                label   : discountValue,
                class   : '',
            },
            discountType: {
                label   : discountType,
                class   : '',
            },
            timestamp: {
                label   : timestamp,
                class   : 'text-right pr_40',
            } 
        };
    }

    

    return (
        <div id="TransactionHistory" className="pr_32 mt_32">
             <div className="borderRadius-4">
                <CustomTable
                    dataRows     = {tableData}
                    headerRow    = {tableHeadings}
                    tableShadow  = {'none'}
                    tableHeight  = {'500px'}
                    stickyHeader = {true}
                />
            </div>
        </div>
    )
}
