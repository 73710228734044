import React, { Component } from "react";
import Loader from "../components/Loader";
import Notfound from "../components/Notfound";

import { BrandService, CampaignService } from "../services";

const CampaignContext = React.createContext();

class CampaignContextComponent extends Component {
  state = {}

  componentDidMount = async () => {
  }

  updateCampaign = (updates) => this.setState({...this.state, ...updates})

  render() {
    return (
      <CampaignContext.Provider value={{...this.state, updateCampaign: this.updateCampaign}}>
          {this.props.children}
      </CampaignContext.Provider>
    )
  }
}

export { CampaignContext, CampaignContextComponent }