import React, { useState, useEffect } from 'react'
import SettingHeader from '../../../components/setting/SettingHeader'
import CustomTable from '../../../components/customTable/CustomTable'
import CustomInput from '../../../components/CustomTextField';
import { SvgIcons } from '../../../icons';
import CustomButton from '../../../components/CustomButton';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { ClickOutside } from '../../../utils';
import ViewDetail from '../../../components/ViewDetail';
import CustomSwitch from '../../../components/CustomSwitch';

export default function BusinessUsers(props) {

    const [tableData, setTableData] = useState([]);

     useEffect(()=>{
        setTableData(response)
    },[])

    const response =  [
        {
            fullname  : 'Marvin',
            email     : 'marvin@gmail.com',
            phone     : '03323531249',
            createdAt : 'a year ago',
            permission: '',
            invite    : 'Active',
            action    : '',
        },
        {
            fullname  : 'Marvin',
            email     : 'marvin@gmail.com',
            phone     : '03323531249',
            createdAt : 'a year ago',
            permission: '',
            invite    : 'Resend',
            action    : '',
        },
        {
            fullname  : 'Marvin',
            email     : 'marvin@gmail.com',
            phone     : '03323531249',
            createdAt : 'a year ago',
            permission: '',
            invite    : 'Resend',
            action    : '',
        },
     ]

    const tableHeadings =  [
        { id: 'serial', label: <input className="cp" type="checkbox"/> },
        { id: 'fullname', label: 'Full Name' },
        { id: 'email', label: 'Email' },
        { id: 'phone', label: 'Phone' },
        { id: 'createdAt', label: 'Created At' },
        { id: 'permission', label: 'Permission' },
        { id: 'invite', label: 'Invite' },
        { id: 'actions', label: 'Actions' },
    ]

    const tableDataa = tableData.map((businessUserDetail, index) => createData({
        serial    : <input onClick={(e)=>e.stopPropagation()} className="cp" type="checkbox"/>,
        fullname  : businessUserDetail.fullname,
        email     : businessUserDetail.email,
        phone     : businessUserDetail.phone,
        createdAt : businessUserDetail.createdAt,
        permission: <CustomSwitch onClick={(e) => e.stopPropagation()}/>,
        invite    : <div className="d-flex invite">
            {businessUserDetail.invite === "Active" && <div className="mr_4 ml_4"><SvgIcons.IconTick width="8"/></div> }
            {businessUserDetail.invite}</div>,
        actions   : <ActionComponent handleDeleteFunc={() => handleDeleteFunc(businessUserDetail)}/>,
    }))

    function createData({serial,fullname ,email, phone, createdAt, permission, invite, actions, id}) {
        return { 
            props: {
                name     : 'rowProps',
                class    : '',
                id       : id,
                checkbox : false
            },
            serial: {
                label: serial,
                class: '',
            },
            fullname: {
                label : fullname,
                class : '',
            }, 
            email: {
                label   : email,
                class   : '',
                numeric : false
            },
            phone: {
                label   : phone,
                class   : '',
                numeric : false
            },
            createdAt: {
                label   : createdAt,
                class   : '',
                numeric : false,
            },
            permission: {
                label   : permission,
                class   : '',
                numeric : false,
            },
            invite: {
                label   : invite,
                class   : 'text-center',
                numeric : false,
            },
            actions: {
                label   : actions,
                class   : 'text-right',
                numeric : false
            } 
        };
    }


    const handleDeleteFunc = () => {
    }

    const handleClick = () => {
        // console.log("Add User Click");
    }
    return (
        <div id="business-users">
       
            
            <div className = "headline3 heading ml_24 mt_24">Customer Info</div>
            <div className="main-div m_24 pb_16 bg-color-cF6F6F9 borderRadius-16">
                <div className="d-flex main-div-header pt_7 mb_16">
                    <div className="col-9 d-flex">
                        <CustomInput
                            className   = "borderRadius-10 col-8 bg-color-tableSearchBoxColor"
                            placeholder = 'Search Participants'
                            variant     = "standard"
                            icon        = {<SvgIcons.SearchIcon height="24px" width="24px"/>}
                            position    = 'start'
                            border      = '0px'
                            backgroundColor = '#EAEBF0'
                            InputProps  = {{ startAdornment: <SvgIcons.SearchIcon height="24px" width="24px"/> ,disableUnderline: true, style: { fontSize: 14, paddingLeft: '12px'} }}/>
                                        
                    <div className="subheading buttonText  ml_16 borderRadius-12 cp color-c828282 invite-button">Invite All</div>
                    </div>
                    <div className="customButton text-right mt_9 col-3">
                            <CustomButton 
                                btntext      = {'Add User'}
                                onClick      = {handleClick}
                                fontSize     = {'14px'}
                                borderRadius = {"14px"}
                                // height       = {'45px'}
                            />
                    </div> 
                </div>
                <div className="ml_16 mr_16">
                    <CustomTable
                        dataRows     = {tableDataa}
                        headerRow    = {tableHeadings}
                        tableShadow  = {'none'}
                        tableHeight  = {'500px'}
                        stickyHeader = {true}
                    />
                </div>
            </div>
        </div>
    )
}

const ActionComponent = ({handleDeleteFunc}) => {

    const [viewMore, setViewMore] = useState(false);

    const ViewMoreIconClickedFunc = (e) => {
        e.stopPropagation()
        setViewMore(!viewMore);
    }

    const viewMoreItems = [
       {
           name    : 'Delete',
           function: handleDeleteFunc,
       },
    ]

    return(
        <div className="d-flex ">
            <div className="cp position-relative" onClick={(e)=>ViewMoreIconClickedFunc(e)}>
                <SvgIcons.ViewMoreIcon color={ColorSchemeCode.tableActionDropdownColor}/>
                <ClickOutside onClick={()=>setViewMore(false)}>
                    {viewMore &&
                        <div className="viewdetail position-absolute z-index-9">
                            <ViewDetail items={viewMoreItems}/>
                        </div>
                    }
                </ClickOutside>
            </div>
        </div>
    )
}
