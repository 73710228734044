import React, {useContext, useEffect, useState} from 'react'

import * as localForage from 'localforage'

import SvgIcons from '../../icons/svg.icon';

import CustomFieldsDropDown from '../../components/CustomFieldsDropDown';
import CustomSwitch from '../../components/CustomSwitch';
import CustomTextArea from '../../components/CustomTextArea';

import ClickOutside from '../../utils/ClickOutside';
import {ColorSchemeCode} from '../../enums/ColorScheme';
import { PngIcons } from '../../icons';
import { Fields } from '../../enums';
import { BrandContext } from '../../context/Brand.context';
import { utils } from '../../utils';
import DraggableComponent from '../../components/DraggableComponent';
import { AgencyContext } from '../../context/Agency.context';


export default function ApplePass({preview, passView, formik, focused}){
    return(
        <div 
            id="Apple" 
            onClick={(e)=>{e.stopPropagation();focused.setFocus({body: true})}} 
            className={`flip-card ${focused.items.body && !preview && 'outline-active'} ${passView === 'back' ? 'flipped' : ''}`}>
            {/* {passViewType === 'event' && <div className={passView === 'front' && "cardTopCircle"}></div>}   */}
            <div className='flip-card-inner'>
                {/* {passViewType === 'coupan' && <div className="cardTopBorder" style={passView === 'front' ? borderTopPatternColor : {}}></div>}  */}
                    <div className='flip-card-front'> 
                        <FrontViewPass 
                            preview = {preview}
                            formik   = {formik}
                            focused  = {focused}
                        />
                    </div>        
                    <div className='flip-card-back'>
                        <BackViewPass 
                            preview = {preview}
                            formik  = {formik}
                            focused = {focused}
                        />   
                    </div>
                    {/* {passViewType === 'coupan' &&<div className="cardBottomBorder" style={passView === 'front' ? borderBottomPatternColor : {} }></div>} */}
            </div>
        </div>
    )
}


// ------------ FRONT ------------- //

const Field = ({formik, focused, preview, className, property, position, selectedField}) => {

    const [fieldList, setFieldList] = useState({})

    const handleFocus = (e) => {
        e.stopPropagation()
        focused.setFocus({[property]: true, index: position})
    }

    const updateSelectedField = async(field) => {
        fieldList[property+position] && setFieldList({[property+position]: false})
        
        const updates = formik.values.apple[property]

        updates[position] = {...field, changeMessage: 'field updated %@', textAlignment: 'PKTextAlignmentLeft'}

        const apple = {...formik.values.apple, [property]: updates}

        formik.setValues({...formik.values, apple})
        
    }

    return (
        <div 
            className = {`${className} ${formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property][position] && 'error-outline'} ${focused.items[property] && focused.items.index === position && !preview && 'outline-active'} ${selectedField.textAlignment}`}
            onClick   = {handleFocus}
        >
            <div className="d-flex flex-column fw-5">
                <input 
                    type        = "text"
                    className   = {`fs-12 frontInputText pl_0 ${preview && 'cd'} ${!preview && 'underline'} ${!selectedField.key && 'placeholder'}`}
                    placeholder = {''}
                    name        = {`apple.${property}[${position}].label`}
                    value       = {selectedField.label || 'label'}
                    onChange    = {formik.handleChange}
                />
                <ClickOutside onClick={()=>fieldList[property+position] && setFieldList({[property+position]: false})}>
                    <div className={`fs-16 cp ${preview && 'cd pe-none'} ${!selectedField.key && 'placeholder'}`}>
                        {selectedField.key ? '%'+selectedField.key+'%' : 'key'}
                        {!preview && 
                            <span 
                                onClick={()=>setFieldList({[property+position]: !fieldList[property+position]})} 
                                className="ml_5 w-90"
                            >
                                <SvgIcons.DropDownTriangleIcon 
                                    height="12px" 
                                    width="10px" 
                                    color={selectedField.key ? formik.values.fontColor : '#a7a7a7'}
                                />
                            </span>
                        }
                    </div>
                        
                    {fieldList[property+position] && 
                        <div className="w-50">
                            <CustomFieldsDropDown 
                                title       = {'Select Fields'}
                                selectValue = {updateSelectedField}
                            />
                        </div>
                    }
                </ClickOutside>
            </div>
        </div>
    )
}

const Logo = ({formik, focused, preview}) => {

    return (
        <div className={`cardLogo logo ${focused.items.logo && !preview && 'outline-active'} ${preview && 'preview'}`}>
            <img className = "cardLogo"
                 onClick   = {(e)=>{e.stopPropagation();focused.setFocus({logo: true})}}
                 src       = {formik.values.logoFile ? URL.createObjectURL(formik.values.logoFile) : formik.values.logo}
                 width     = "35px"
                 height    = "35px"
                 alt       = ""
            />
        </div>
    )
}

const LogoText = ({preview, formik, focused}) => {

    const [fieldList, setFieldList] = useState(false)

    const selectedField = async(field) => {
        const apple = {...formik.values.apple, logoText: {...field}}
        formik.setValues({...formik.values, apple})
    console.log('ff',field);

    }


    return (
        <ClickOutside onClick={()=>setFieldList(false)}>
            {/*  ${!preview && 'underline'} */}
            <div 
                onClick={(e)=>{e.stopPropagation(); focused.setFocus({logoText: true})}} 
                className={`caption pt_7 opacity-10 fs-16 mt_3 ml_4 cp logoText ${formik.errors.apple && formik.errors.apple.logoText && 'error-outline'}  ${focused.items.logoText && !preview && 'outline-active'} ${preview && 'preview cd'}`}
            >
                <div>
                    <span>{formik.values.apple.logoText.key ? '%'+formik.values.apple.logoText.key+'%' : 'key'}</span>
                    {!preview && <span onClick={()=>setFieldList(!fieldList)} className="ml_5 w-90"><SvgIcons.DropDownTriangleIcon height="12px" width="10px" color={formik.values.fontColor}/></span>}
                </div>
                    {fieldList && !preview &&
                        <div className="w-50 designDropDown">
                            <CustomFieldsDropDown 
                                title       = {'Select Fields'}
                                selectValue = {selectedField}
                            />
                        </div>
                    }
            </div>
        </ClickOutside>
    )
}

const HeaderFields = ({preview, formik, focused}) => {

    return (
        <>
            {formik.values.apple.headerFields.map((headerField, idx) => (
                <Field 
                    key           = {idx}
                    formik        = {formik}
                    focused       = {focused}
                    preview       = {preview}
                    className     = {`rightPart w-50 pl_0 d-flex headerFields flex-column pr_0 pt_5 word-break text-right ${preview && 'preview'}`}
                    selectedField = {headerField}
                    property      = {'headerFields'}
                    position      = {idx}
                />
            ))}
        </>
    )
}

const Cover = ({formik, focused, preview}) => {

    let numbersOfStampPerLine = [];
    let numberOfLines = [];
    let imageSize   = '';

    if(formik.values.numberOfStamps){
        let length = parseInt(formik.values.numberOfStamps);

            if (length <= 10) {
              imageSize = 40
              numberOfLines = Array(2).fill(0).map((x, i) => i)
              if (length == 6)
                numbersOfStampPerLine = Array(3).fill(0).map((x, i) => i);
              else if (length == 8)
                numbersOfStampPerLine = Array(4).fill(0).map((x, i) => i);
              else if (length == 10)
                numbersOfStampPerLine = Array(5).fill(0).map((x, i) => i);
            }

            else if (length <= 18) {
              imageSize = 25
              numberOfLines = Array(2).fill(0).map((x, i) => i)
              if (length == 12)
                numbersOfStampPerLine = Array(6).fill(0).map((x, i) => i);
              else if (length == 14)
                numbersOfStampPerLine = Array(7).fill(0).map((x, i) => i);
              else if (length == 18) {
                numberOfLines = Array(3).fill(0).map((x, i) => i)
                numbersOfStampPerLine = Array(6).fill(0).map((x, i) => i);
              }
            }

            else if (length <= 30) {
              imageSize = 25
              numberOfLines = Array(3).fill(0).map((x, i) => i)
              if (length == 21)
                numbersOfStampPerLine = Array(7).fill(0).map((x, i) => i);
              else if (length == 24)
                numbersOfStampPerLine = Array(8).fill(0).map((x, i) => i);
              else if (length == 30)
                numbersOfStampPerLine = Array(10).fill(0).map((x, i) => i);
        
            }
            
    }

    return (
        <div className={`position-relative cover ${focused.items.cover && !preview && 'outline-active'} ${preview && 'preview'}`} onClick={(e)=>{e.stopPropagation();focused.setFocus({cover: true})}} >
            <div className={`cardCover mt_8`}>
                <img 
                    src={formik.values.coverFile ? URL.createObjectURL(formik.values.coverFile) : formik.values.cover} 
                    alt="" 
                    height="150px" 
                    width="100%"
                />
            </div>
            <div className="stampImageCover">
                {
                    numberOfLines.map((outerIndex)=>
                        <div className="singleRow">
                            {
                                numbersOfStampPerLine.map((innerIndex)=>
                                <div className="middle">
                                    
                                    {outerIndex == 0 && innerIndex < 2 ?
                                    
                                        <img height={imageSize} width={imageSize} src={formik.values.filledImageFile ? URL.createObjectURL(formik.values.filledImageFile) : formik.values.filledImage} />
                                    :
                                        <img height={imageSize} width={imageSize} src={formik.values.unFilledImageFile ? URL.createObjectURL(formik.values.unFilledImageFile) : formik.values.unFilledImage} />

                                    }
                                    
                                </div>
                                )
                            }
                        </div>
                    )
                }

            </div>
        </div>
    )
}

const SecondaryFields = ({preview, formik, focused}) => {

    const [addAuxiliaryField, setAddAuxiliaryField] = useState(false)


    return (
        <div 
            onMouseEnter={()=>setAddAuxiliaryField(true)} 
            onMouseLeave={()=>setAddAuxiliaryField(false)} 
            className={`d-flex pl_8 pr_8 space-between position-relative ${!formik.values.apple.auxiliaryFields && 'secondaryFields'}`}
        >
            {formik.values.apple.secondaryFields && formik.values.apple.secondaryFields.map((secondaryField, idx) => (
                <Field 
                    formik        = {formik}
                    focused       = {focused}
                    preview       = {preview}
                    className     = {`w-50 cardContentEdit mt_14 overline2 mt_8 secondaryField ${preview && 'preview'}`}
                    selectedField = {secondaryField}
                    property      = {'secondaryFields'}
                    position      = {idx}
                />
            ))}
            {formik.values.apple.auxiliaryFields.length === 0 && <div className={`addBtn ${addAuxiliaryField && 'add'}`} onClick={()=>formik.setValues({...formik.values, apple:{...formik.values.apple, auxiliaryFields: [{}, {}]}})} >+</div>}

        </div>
    )
}

const AuxiliaryFields = ({preview, formik, focused}) => {

    return (
        <div className="d-flex space-between pl_8 pr_8">
             {formik.values.apple.auxiliaryFields && formik.values.apple.auxiliaryFields.map((auxiliaryField, idx) => (
                <Field 
                    formik        = {formik}
                    focused       = {focused}
                    preview       = {preview}
                    className     = {`cardContentEdit mt_14 overline2 mt_8 secondaryField ${preview && 'preview'}`}
                    selectedField = {auxiliaryField}
                    property      = {'auxiliaryFields'}
                    position      = {idx}
                />
            ))}
        </div>
    )
}

const QrCode = ({formik, focused, preview}) => {
    return (
        <div 
            onClick={(e)=>{e.stopPropagation(); focused.setFocus({qrcode: true})}} 
            className={`qrCode mt_14 text-center ${!formik.values.qrcode && 'qr-placeholder'} ${focused.items.qrcode && !preview && 'outline-active'} ${preview && 'preview'}`}
        >
            {formik.values.qrcode && <img src={PngIcons.Barcode} height="150px" width="150px" alt=""/>}
        </div>
    )
}

const FrontViewPass = ({preview, formik, focused}) => {

    return(
        <div id="customPass">
            <div className="singleCardEdit" style={{backgroundColor : formik.values.backgroundColor, color : formik.values.fontColor}}>
                <div className="cardTopEdit d-flex space-between">
                    <div className="leftPart d-flex w-50">
                        <Logo formik={formik} focused={focused} preview={preview} />

                        <LogoText formik={formik} preview={preview} focused={focused}/>
                    </div>
                    <HeaderFields preview={preview} formik={formik} focused={focused} />
                </div>
                
                <Cover formik={formik} focused={focused} preview={preview} />

                <SecondaryFields preview={preview} formik={formik} focused={focused} />

                <AuxiliaryFields preview={preview} formik={formik} focused={focused} />
               
                <QrCode formik={formik} focused={focused}  preview={preview} />
            </div>
        </div>
    )
}

// ------------ BACK ------------- //

const BackHeader = () => {
    const agency = useContext(AgencyContext)
    return (
        <>
            <div className="singlePassRow d-flex space-between mt_16">
                <div className="Body14R pt_5">
                    Automatic Update
                </div>
                <CustomSwitch 
                    backgroundColor = {agency.agencyColor}
                    checked         = {true}
                />
            </div>
            <div className="singlePassRow d-flex space-between mt_16">
                <div className="Body14R pt_5">
                    Suggest based on time or location
                </div>
                <CustomSwitch 
                    backgroundColor = {agency.agencyColor}
                    checked         = {true}
                />
            </div>
            <div className="middleBox color-neutral80 mt_24 text-center fs-14 d-flex flex-column justify-content-center" >
                Show based on time or loaction 
            </div>
        </>
    )
}

const BackFields = ({formik, focused, preview, backField, idx}) => {

    const [fieldList, setFieldList] = useState({})

    const [addBackField, setAddBackField] = useState(false)

    const updateSelectedField = async(field) => {
        
        const backFields = formik.values.apple.backFields
        console.log( 'backFields',backFields[idx]);
        backFields[idx] = {...field, changeMessage: 'field updated %@', textAlignment: 'PKTextAlignmentLeft'}

        const apple = {...formik.values.apple, backFields}

        formik.setValues({...formik.values, apple})
        
    }

    const handleDetailChange = (e) => {
        backField.value = e.target.value
        formik.setValues({...formik.values})
    }

    const addNewBackField = (idx) => {
        const backFields = formik.values.apple.backFields


        backFields.splice(idx+1, 0, {})

        const apple = {...formik.values.apple, backFields}
        formik.setValues({...formik.values, apple})
    }

    return(
        <>
        <div 
            onMouseEnter = {()=>setAddBackField(true)}
            onMouseLeave = {()=>setAddBackField(false)}
            onClick      = {(e)=>{e.stopPropagation(); focused.setFocus({backFields: true, index: idx})}}
            className    = {`singlePassRow mt_24 backFields position-relative ${preview && 'preview'} ${focused.items.index === idx && !preview && 'outline-active'} ${backField.textAlignment || 'text-left'}`}
        >
            <ClickOutside onClick={()=>fieldList[idx] && setFieldList({[idx]: false})}>
                <div 
                    onClick={()=>setFieldList({[idx]: !fieldList[idx]})}
                    className={`fs-16 subtitle1 fw-5 fs-16 text-capitalize cp ${preview && 'cd pe-none'} ${!backField.label && 'placeholder'}`}
                >
                    {backField.label || 'label'}

                    {!preview && 
                        <span className="ml_5 w-90">
                            <SvgIcons.DropDownTriangleIcon 
                                height="12px" 
                                width="10px" 
                                color={backField.label ? formik.values.fontColor : '#a7a7a7'}
                            />
                        </span>
                    }
                </div>
                    
                {fieldList[idx] && 
                    <div className="w-50 color-textfieldColor">
                        <CustomFieldsDropDown 
                            title       = {'Select Fields'}
                            selectValue = {updateSelectedField}
                        />
                    </div>
                }
            </ClickOutside>
            
            
            <div className="textArea">
                <CustomTextArea 
                    classes     = {`col-12 border-none color-c828282 ${backField.textAlignment} resize-none p_0 fs-14 ${preview && 'cd pe-none'} ${!backField.value && 'placeholder'}`}
                    rows        = "1"
                    placeholder = {'add details here'}
                    value       = {backField.value || ''}
                    onChange    = {handleDetailChange}
                />
            </div>


            <div className="bar mt_16"></div>

            {<div className={`addBtn ${addBackField && 'add'}`} onClick={()=>addNewBackField(idx)}>+</div>}
        </div>
        </>
    )
}

const BackViewPass = ({formik, focused, preview}) =>{

    const handleListUpdate = (list) => {
        const apple = {...formik.values.apple, backFields: list}
        formik.setValues({...formik.values, apple})
    }
    
    return(
        <div id="customPass">
            <div className="singleCardEdit" style={{backgroundColor : ColorSchemeCode.white}}>
                <BackHeader />

                {formik.values.apple.backFields.length > 0 && <DraggableComponent 
                    list          = {formik.values.apple.backFields}
                    ItemComponent = {({item, idx}) => <BackFields formik={formik} focused={focused} preview= {preview} backField={item} idx={idx} />}
                    setList       = {handleListUpdate}
                />}
            </div>
        </div>
    )
}