import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../../components/CustomButton'
import { CircularProgress } from '@material-ui/core'
import CustomTextField from '../../components/CustomTextField';
import CopyText from '../../components/CopyText';
import { SvgIcons } from '../../icons';
import { useFormik } from 'formik'
import { PassService } from '../../services';
import CustomDropDown from '../../components/CustomDropDown';
import CustomSelect from '../../components/CustomSelect';
import { Options, utils } from '../../utils';
import axios from 'axios';
import env from '../../config';
// import { AgencyContext } from '../../context/Agency.context'
// import { AgencyPolicyService } from '../../services'

function Tier() {


    const [updatetier, setUpdatedtier] = useState(false);



    // const initState = {
    //     Tier: {
    //        email:"",
    //        agencyid:"",
    //        tierid:"",
    //        agencyapikey:"",

    //     }
    // }
    const [state, setState] = useState({
        generalLoader: false,
        apiError: '',
    
    })

    // const formik = useFormik({
    //     initialValues: { ...initState },
    //     enableReinitialize: true
    // })
    // console.log('formik',formik);


    const formik = useFormik({
        initialValues: {
            email:"" || "kyleedeanw@iinet.net.au",
            agencyId:""||"UA95bkhlgEIkHuEBUCXq",
            tierId:""||"CrqtUpmGdeKlIYegdow1",
            agencyapikey:"4744bac2-4e4a321a-7feb7eca",
      
        },


    });
    useEffect(() => {
    
    
    }, [formik.values])


    // const handleSubmit = async () => {


    //     formik.setValues({...formik.values, loader: true});
    //     const payload = {
    //         email: formik.values.email,
    //         agencyid: formik.values.agencyId,
    //         tierid: formik.values.tierId,
    //         //agencyapikey: formik.values.agencyapikey,
    //     };
    
    //     const headers = { 'agency-api-key': formik.values.agencyapikey }; 
    //     console.log('payload is ', payload);
    //     console.log('headers', headers);
    
    //     try {
    //         const { error, response } = await PassService.UpdateTierPass({ toaster: true, payload, headers });
    //         console.log('new payload is ', payload);
    //         console.log('new headers', headers);
    //         if (error) {
    //             console.error('Error:', error);
    //             setState({ generalLoader: false, apiError: error.error });
    //         } else {
    //             if (response) {
    //                 console.log('response', response);
    //                 setUpdatedtier(!updatetier);
    //             } else {
    //                 console.error('Invalid response:', response);
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         setState({ generalLoader: false, apiError: 'An error occurred while processing your request.' });
    //     }
    // };
    

    const handleSubmit = async () => {
        formik.setValues({ ...formik.values });
        const payload = {
            email: "ladylewishall@gmail.com",
            // email: formik.values.email,
            // agencyid: formik.values.agencyId,
            agencyId: 'UA95bkhlgEIkHuEBUCXq',
            // tierid: formik.values.tierId,
            tierId: 'CrqtUpmGdeKlIYegdow1',
            //agencyapikey: formik.values.agencyapikey,
        };



        // Example API endpoint
        const API_URL = 'https://api.walletly.ai/api/v4/pass/updateTierPass';
        // const API_URL = 'http://localhost:8080/v4/pass/updateTierPass';

        // Axios configuration with authorization header
        const axiosConfig = {
        headers: {
            'agency-api-key': '4744bac2-4e4a321a-7feb7eca',
            'Content-Type': 'application/json'
        }
        };

        // Making a GET request using Axios
        axios.put(API_URL, payload,axiosConfig)
        .then(response => {
            // Handle successful response
            console.log('Response:', response.data);
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
        });
    
        // const headers = {
        //     'Content-Type': 'application/json',
        //     'agency-api-key': formik.values.agencyapikey
        // };
    
        // console.log('payload is ', payload);
        // console.log('headers', headers);
    
        // try {
        //     const response = await fetch(  env.API_URL  + '/v4/pass/updateTierPass', {
        //         method: 'PUT',
        //         headers: headers,
        //         body: JSON.stringify(payload)
        //     });
    
        //     const responseData = await response.json();
    
        //     console.log('second payload is ', payload);
        //     console.log('second headers', headers);
        //     console.log('API response:', responseData);
    
        //     if (responseData.success) {
        //         console.log('Pass updated successfully');
        //         setUpdatedtier(!updatetier);
        //     } else {
        //         console.error('Pass update failed:', responseData.message);
        //     }
        // } catch (error) {
        //     console.error('Error:', error);
        // }
    };
    
    


    return (
        <>
            <div id='Tier'>

                <div style={{ margin: 'auto', width: '33%', paddingTop: '100px', paddingBottom: '100px' }}>


                    {!updatetier ?
                        <>

                            <div className='heading'>Update Customer Tiers

                                <div className='paragraph'>Enter details to get tiers updated</div>



                            </div>

                            <div className="Heading14M color-neutral100 mt_16">Email Address</div>
                            <div className='mt_6'>
                                <CustomTextField
                            //name="Tier.email" 
                                    value={formik.values.email}
                                    // onChange={(e) => formik.setValues({ ...formik.values, Tier: { ...formik.values.Tier, email: e.target.value } })}
                                    onChange={(e) => formik.setValues({ ...formik.values, email: e.target.value })}
                                    //onChange={handleChange}
                                    placeholder='user@domain.com'
                                />
                            </div>

                            <div className="Heading14M color-neutral100 mt_16">Agency ID</div>
                            <div className='mt_6'>
                                <CustomTextField
                                //name="Tier.agencyid"
                                     //value={formik.values.agencyId}
                                    //  onChange={(e) => formik.setValues({ ...formik.values, Tier: { ...formik.values.Tier, agencyid: e.target.value } })}
                                    onChange={(e) => formik.setValues({ ...formik.values, agencyId: e.target.value })}
                                     //onChange={handleChange}
                                    placeholder='80f58812-379d66d0-7eb7ff30'
                                />
                            </div>


                            <div className="Heading14M color-neutral100 mt_16">Tier ID</div>
                            <div className='mt_6'>
                                {/* <CustomTextField
                               
                               value={formik.values.tierId}
                               //onChange={handleChange}
                               //onChange={(e) => formik.setValues({ ...formik.values, Tier: { ...formik.values.Tier, tierid: e.target.value } })}
                               onChange={(e) => formik.setValues({ ...formik.values, tierId: e.target.value })}
                              placeholder='80f58812-379d66d0-7eb7ff30'
                                /> */}
                             <CustomSelect
                                              options={Options.Tierid()}
                                              value={formik.values.tierId}
                                              onChange={(e) => formik.setValues({ ...formik.values, tierId: e.target.value })}
                                            //value={formik.values.walletApp ? formik.values.walletApp.apple : ''}
                                            //onChange={(e) => formik.setValues({ ...formik.values, walletApp: { ...formik.values.walletApp, apple: e.target.value } })}
                                            inputProps={{ tabIndex: '13' }}
                                        />
                            </div>




                            <div className="Heading14M color-neutral100 mb_16 mt_16 ">
                                Agency API Key
                            </div>

                            <div className="position-relative">
                                <CustomTextField
                            

                                    placeholder="80f58812-379d66d0-7eb7ff30"
                                    className="w-100 "

                                value={formik.values.agencyapikey}
                                onChange={(e) => formik.setValues({ ...formik.values, agencyapikey: e.target.value })}
                            
                                //onChange={(e) => formik.setValues({ ...formik.values, Tier: { ...formik.values.Tier, agencyapikey: e.target.value } })}
                               
                                />
                                <div style={{ position: "absolute", top: "17px", right: "16px" }}>
                                    {/* <CopyText hideText={true} /> */}
                                </div>
                            </div>


                            <div className='mt_32 '>
                                <CustomButton onClick={() => handleSubmit()} btntext={"Submit"}
                                    className={"w-100 hpx-52"}
                                //disabled={state.generalLoader}
                                />
                            </div>

                        </>
                        : <>

                            <div >
                                <div>
                                    <div style={{ margin: 'auto' }} className='wpx-128 hpx-128 d-flex '>
                                        <SvgIcons.hamsubmit />
                                    </div>

                                    <div style={{ margin: 'auto', justifyContent: 'center' }} className='heading mt_25 mb_32 d-flex'>Updated Successfully!</div>

                                    <div className='mt_136 '>
                                        <CustomButton btntext={"Update Another"}
                                            className={"w-100 hpx-52"}
                                            onClick={() => handleSubmit()}
                                        //disabled={state.generalLoader}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}

                </div>

            </div>
        </>

    )
}

export default Tier