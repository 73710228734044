import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import CustomButton from '../../components/CustomButton'
import { BrandContext } from '../../context/Brand.context'
import { LayoutContext } from '../../context/Layout.context'
import { Brand } from '../../enums'
import { PngIcons, SvgIcons } from '../../icons'
import { RedeemingWayService, FlowService, EarningWayService, TierService, CampaignService, BrandService } from '../../services'
import localforage from 'localforage';

function LoyaltyMain(props) {

    const layout = useContext(LayoutContext)
    const history = useHistory();

    const brand = useContext(BrandContext);

    const [loader, setLoader] = useState({
        mainLoader : false
    })

    const initValues=[
        {
            icon        : PngIcons.starIcon,
            action      : "actions",
            heading     : "Earning Ways",
            description : "Create ways your customers can earn points when they join, share, and engage with your brand.",
            onClick     : props.match.url+'/points'
        },
        {
            icon        : PngIcons.presentIcon,
            action      : "rewards",
            // heading     : "Loyalty Builder",
            heading     : "Launch Pad",
            // disable     : disable.redeemingWays,
            description : "Create rewards your customers can redeem with the points they’ve earned.",
            onClick     : props.match.url+'/redeem'
        },
        {
            icon        : PngIcons.designIcon,
            heading     : "Pass Design",
            description : "Create rewards your customers can redeem with the points they’ve earned..",
            onClick     : props.match.url+'/design'
        },
        {
            icon        : PngIcons.crownIcon,
            action      : "tiers",
            heading     : "Loyalty Tiers",
            description : "Create loyalty tiers to reward your most loyal customers while increasing your average customer  lifetime value.",
            onClick     : props.match.url+'/points/tier'
        },
        {
            icon        : PngIcons.builderIcon,
            show     : (brand.brandType === 'sharedLoyality' ? false : true) ,
            action      : "sub-brands",
            heading     : "Sub Brands",
            description : "Create loyalty tiers to reward your most loyal customers while increasing your average customer  lifetime value.",
            onClick     : props.match.url+'/points/subbrands'
        },
    ]

    const [state, setstate] = useState(initValues);

    const tempways = []
    const temptiers = []

    // useEffect(async() => {
    //     // setLoader({...loader, mainLoader : true})
    //     let loyaltyData = await localforage.getItem('loyaltyData');
    //     if(loyaltyData){
    //         setstate([...loyaltyData])
    //         // setLoader({...loader, mainLoader : false})
    //     }

    //     let campaign = await localforage.getItem('campaign');
    //     if((!campaign) || campaign.length === 0){
    //         const ccampaign = await CampaignService.GetPublicCampaign({query:{brandId: brand._id, delete: false}})
    //         // console.log('campaign',ccampaign);
    //         if(ccampaign.response?.data?.length>0) campaign = ccampaign.response.data
    //     }
    //     let ways = await localforage.getItem('selectedLoyalityEarningWays');
    //     if((!ways) || ways.length === 0){
    //         const wways = await EarningWayService.GetQueriedEarningWaysPublic({query: {campaignId: campaign[0]._id ,brandId: brand._id}})
    //         // console.log('ways',wways);
    //         if(wways.response?.data?.length>0) ways = wways.response.data
    //     }
    //     let tiers = await localforage.getItem('tiers');
    //     if((!tiers) || tiers.length === 0){
    //         const ttiers = await TierService.GetPublic({query: {campaignId: campaign[0]._id, brandId: brand._id, delete: false}})
    //         // console.log('tiers',ttiers);
    //         if(ttiers.response?.data?.length>0) tiers = ttiers.response.data
    //     }

    //     if(ways)
    //     for (const value of ways) {
    //         if(value.active) tempways.push(value)
    //     }
        
    //     if(tiers)
    //     for (const value of tiers) {
    //         if(value.active) temptiers.push(value)
    //     }
      
    //     const final = {
    //       ways: tempways.length,
    //       tiers: temptiers.length
    //     }
        
    //     if(brand.brandType === 'sharedLoyality'){
    //         let subBrand = await localforage.getItem('subBrands');
    //         if((!subBrand) || subBrand.length === 0){
    //             const ssubbrand = await BrandService.Get({query:{delete: false, parentBrandId: brand._id, brandType : Brand.BrandTypes.SUB_BRAND}})
    //             // console.log('subbrand',ssubbrand);
    //             if(ssubbrand.response?.data?.length>0) subBrand = ssubbrand.response.data
    //         }
    //         final.subBrand = subBrand
    //     }
    //     let flow = await RedeemingWayService.GetQueriedEarningWays({query:{brandId: brand._id, delete: false}})
    //     if(flow.response?.data?.length>0) flow = flow.response.data
    //     else{
    //         flow = await localforage.getItem('flow');
    //         if((!flow) || flow.length === 0){
    //             const flowss = await FlowService.Get({query: {brandId: brand._id, delete: false, flowType : 'new'}})
    //             if(flowss.response?.data?.length>0){
    //                 flow = flowss.response.data
    //             }
    //         }
    //     }

    //     final.flows = flow

    //   // console.log('final',final);
    //   if(final.ways) state[0].action = final.ways+" actions"

    //   if(final.tiers) state[2].action = "tiers ON"
    //   else state[2].action = "tiers OFF"
      
    //   if(final.subBrand) state[4].action = final.subBrand.length+" sub-brands"
    //   if(final.flows) state[3].action = final.flows.length+" rewards"

    //   setstate([...state])
    //   // console.log('state ', state);
    //   await localforage.setItem('loyaltyData', state)
    // //   setLoader({...loader, mainLoader : false})

    // }, [])
    

    useEffect(()=>{
        layout.setLayout({
            borderBottom: false,
            edit        : false,
            backTitles  : [],
            title       : "Loyalty Suite",
            switch : '',
            button      : <div></div>
        })
        // onLoad();
    },[])

    // const onLoad = async() => {
    //     // setLoader({...loader, mainLoader : true})
    //     // const query               = {brandId: brand._id, delete: false}
    //     // let   redeemingWaysCheck  = false;
    //     // let   loyaltyBuilderCheck = false;
    //     // const redeemingWays       = await RedeemingWayService.GetQueriedEarningWays({query})
    //     // if(redeemingWays?.response) loyaltyBuilderCheck= true
    //     // else loyaltyBuilderCheck= false
        
    //     // const flow = await FlowService.Get({query: {brandId: brand._id, delete: false, flowType : 'new'}})
    //     // if(flow.response) redeemingWaysCheck = true
    //     // else redeemingWaysCheck = false
    //     // setDisable({redeemingWays : redeemingWaysCheck , loyaltyBuilder : loyaltyBuilderCheck})
    //     // setLoader({...loader, mainLoader : false})
    // }

    // // useEffect(()=>{
    // //     setstate(initValues)
    // //     // console.log('disable ', disable)
    // // },[disable.loyaltyBuilder, disable.redeemingWays])
    console.log('beeeeeeep');


    return (
        <div className="pt_16 pr_40 pl_40 pb_10 " style={{height: 'calc(100vh - 72px)', overflowY: 'scroll'}}>
            {
            loader.mainLoader ? 
                <div className="grid">
                    {[1,2,3,4].map(()=>
                       <span className='position-relative'>
                        <Skeleton height={290} width={"100%"} className="pl-20 pr-20 pt-20 pb-20">
                        </Skeleton> 
                        <Skeleton height={48} width={48} className="position-absolute" circle={true} style={{background: '#BDC3C6', left: 20, top: 20}} />
                        <Skeleton height={24} width={60} className="position-absolute borderRadius-50" style={{background: '#BDC3C6', right: 20, top: 28}} />
                        <Skeleton height={24} width={160} className="position-absolute " style={{background: '#BDC3C6', left: 20, top: 90}} />
                        <Skeleton height={24} width={"85%"} className="position-absolute " style={{background: '#BDC3C6', left: 20, top: 130}} />
                        <Skeleton height={24} width={"85%"} className="position-absolute " style={{background: '#BDC3C6', left: 20, top: 156}} />
                        <Skeleton height={24} width={"85%"} className="position-absolute " style={{background: '#BDC3C6', left: 20, top: 182}} />
                       </span>
                    )}
                </div>
            :
            
            <div className='grid'>
                {
                    state?.map((value, index)=>(
                        <div className={`${value.show ?  'd-none' : 'd-flex flex-column space-between'} ${loader.mainLoader && 'position-relative'}`} style={{boxShadow: (value.heading==="Refferal"||value.heading==="Launch Pad")&& 'none'}}>
                        {/* <div className={`${value.show ?  'd-none' : 'd-flex flex-column space-between'} ${loader.mainLoader && 'position-relative'}`} style={{boxShadow: (value.heading==="Refferal"||value.heading==="Loyalty Builder")&& 'none'}}> */}
                            <div>
                                <div className='mb_16 d-flex space-between'>
                                    <img src={value.icon} alt="" />
                                </div>
                                <div className='color-neutral100 Heading16M mb_12'>{value.heading}</div>
                                <div className='color-neutral70 Body14R mb_40'>{value.description}</div>
                            </div>
                            <div>
                                {(value.heading!=="Refferal" && !value.disable)&&<CustomButton size="l" className="w-100 cp" btntext = "View All" varient = "secondary" onClick={()=>history.push(value.onClick)} />}
                            </div>
                        </div>
                    ))
                }
            </div>}
        </div>
    )
    }

export default LoyaltyMain