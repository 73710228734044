import React from 'react'

export default function LocationAutoCompleteField(props) {

  return (
    <div   id           = "AutocompleteLocation"   class = "input-container">
    <div   class        = {  props.value != ''? "mdc-text-field" : ''}>
    <input class        = "mdc-text-field__input" type   = "text" id = "my-text-field"
           value        = {props.value || props.defaultValue}
           name         = {props.name}
           ref          = {props.inputRef}
           onChange     = {props.onChange || props.onBlur}
           onFocus      = {props.onFocus }
           onBlur       = {props.onBlur}
           onKeyUp      = {props.onKeyUp}
           disabled     = {props.disabled}
           placeholder  = {props.value == ''&& props.label }
           autoComplete = {"on"}
           checkOne     = {props.checkOne}
           style        = {{color : props.color ? props.color : '#212529'}}
          {...props.inputProps}
        />
        
        {
         props.value && <label class="mdc-f cc loating-label fs-16 fw-4" for="my-text-field">{props.label || 'Address*'}</label>
        }
        
        {props.error && <p class="error fs-10">{props.error}</p>}
      </div>
    </div>
  )
}




// import * as React from 'react';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import { ColorSchemeCode } from '../enums/ColorScheme';

// export default function LocationAutoCompleteField(props) {

//   return (
//     <Box
//       component="form"
//       className='position-relative'
//       sx={{
//         '& .MuiTextField-root': {
//           m     : 1,
//           width : '100%',
//           margin: 0,
//           padding: 0,
//           fontFamily: 'Inter',

//         },
//         '& .MuiInputLabel-root':{
//           fontSize  : '16px',
//           fontFamily: 'Inter',
//           color     : '#525252',
//           lineHeight: '16px'
//         },
//         '& .MuiOutlinedInput-root': {
//           '&:hover .MuiOutlinedInput-notchedOutline': {
//             borderColor: props.helperText ? ColorSchemeCode.danger30 : ColorSchemeCode.primary50,
//           },
//           '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//             borderColor: props.helperText ? ColorSchemeCode.danger30 : ColorSchemeCode.primary50,
//           },
//         },
//         '& .MuiInputBase-root': {
//           fontSize     : 16,
//           padding: 0,
//           fontFamily: 'Inter',
//           paddingLeft : (props.paddingLeft && (props.paddingLeft + " !important")),
//           paddingRight : (props.paddingRight && (props.paddingRight + " !important"))


//         },
//       }}
//     >
//       <TextField
//       value        = {props.value || props.defaultValue}
//       name         = {props.name}
//       ref          = {props.inputRef}
//       onChange     = {props.onChange || props.onBlur}
//       onKeyUp      = {props.onKeyUp}
//       disabled     = {props.disabled}
//       placeholder  = ''
//       autoComplete = {"on"}
//       // className    = 'mdc-text-field__input'
//       label        = {props.label || 'Address*'}
//       style        = {{color : props.color ? props.color : '#212529'}}
//       {...props.inputProps}
        
//       />
//       {props.icon && props.position == "start" && (
//         <div
//           className={`position-absolute Body14R wpx-50`}
//           style={{ left: props.left ? `${props.left}px`: 9,right: props.right ? `${props.right}px`:16, top: props.top ? `${props.top}px` :14}}
//         >
//           {props.icon}
//         </div>
//       )}


//       {props.icon && props.position == "end" && (
//         <div
//           className={`position-absolute Body14R`}
//           style={{ right: props.left ? `${props.left}px`:16, top: props.top ? `${props.top}px` :14  }}
//         >
//           {props.icon}
//         </div>
//       )}
//     </Box>
//   );   
 
// }
