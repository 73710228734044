import React, { useState, useRef, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
} from 'react-flow-renderer';
import Sidebar from './Sidebar';
import './updatenode.css';
import CustomSelect from '../../../../components/CustomSelect';
import CustomTextField from '../../../../components/CustomTextField';
import { SvgIcons } from '../../../../icons';

const initialElements = [
    {
      id       : '1',
      type     : 'input',
      // data     : { label: {key: 'Check In', value: 'start'} },
      data     : { 
        // label: 'Check In', 
        value: 'start' },
      position : { x: 250, y: 5 },
      style:{
        backgroundImage    : 'url("https://cdn-icons-png.flaticon.com/512/484/484134.png")',
        height             : '100px',
        width              : '100px',
        backgroundPosition : 'center',
        backgroundRepeat   : 'no-repeat',
        backgroundSize     : 'contain',
        border             : 'none',
        backgroundColor    : 'white'
      }
    }
  ];
  
  let id = 0;
  const getId = () => `dndnode_${id++}`;
function FlowTest() {
    const [state, setstate] = useState({
        data     : '',
        selected : 'Deal',
        deal     : 'Deal1',
        point    : 0,
        visit    : 0,
    })
    
    const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState(initialElements);
  const onConnect = (params) => setElements((els) => addEdge(params, els));
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode = {
      id: getId(),
      type,
      position,
      style:{
        backgroundImage    : state.data === 'visit' ? 'url("https://www.pngfind.com/pngs/m/202-2023258_travel-icon-png-blue-transparent-png.png")' : state.data === 'point' ? 'url("https://thumbs.dreamstime.com/b/loyalty-star-tags-line-icon-bonus-points-discount-program-vector-symbol-quality-design-element-linear-style-editable-stroke-149480696.jpg")' : state.data === 'deal' ? 'url("https://cdn1.iconfinder.com/data/icons/shopping-and-commerce-1-8/134/45-512.png")' : '',
        height             : '100px',
        width              : '100px',
        backgroundPosition : 'center',
        backgroundRepeat   : 'no-repeat',
        backgroundSize     : 'contain',
        border             : 'none',
        backgroundColor    : 'white'
      },
      data: { 
          // label: {
          //   key:
          //     state.data === 'deal' ? 
          //     "Deal: "
          //     :
          //     state.data === 'point' ? 
          //     "Point: "
          //     :
          //     state.data === 'visit' ? 
          //     "Visit: "
          //     : 
          //     '',
          //   value: 
          //     state.data === 'deal' ? 
          //     state.deal
          //     :
          //     state.data === 'point' ? 
          //     state.point
          //     :
          //     state.data === 'visit' ? 
          //     state.visit
          //     : 
          //     ''
          // },
          // label: 
          //     state.data === 'deal' ? 
          //     "Deal: "
          //     :
          //     state.data === 'point' ? 
          //     "Point: "
          //     :
          //     state.data === 'visit' ? 
          //     "Visit: "
          //     : 
          //     ''
          // ,
            value:
              state.data === 'deal' ? 
              state.deal
              :
              state.data === 'point' ? 
              state.point
              :
              state.data === 'visit' ? 
              state.visit
              : 
              ''
          
          //   state.data === 'deal' ? 
          //   "Deal: "
          //   :
          //   state.data === 'point' ? 
          //   "Point: "
          //   :
          //   state.data === 'visit' ? 
          //   "Visit: "
          //   : 
          //   '',
            // value: 
            //   state.data === 'deal' ? 
            //   state.deal
            //   :
            //   state.data === 'point' ? 
            //   state.point
            //   :
            //   state.data === 'visit' ? 
            //   state.visit
            //   : 
            //   ''
        },
    };

    setElements((es) => es.concat(newNode));
  };



  /////





  const [nodeName, setNodeName] = useState('');
  const [selected, setSelected] = useState();
  const [nodeBg, setNodeBg] = useState('#eee');
  const [nodeHidden, setNodeHidden] = useState(false);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selected) {
          // it's important that you create a new object here in order to notify react flow about the change
          el.data = {
            ...el.data,
            value: nodeName,
          };
        }

        return el;
      })
    );
  }, [nodeName, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selected) {
          // it's important that you create a new object here in order to notify react flow about the change
          el.style = { ...el.style, backgroundColor: nodeBg };
        }

        return el;
      })
    );
  }, [nodeBg, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selected || el.id === 'e1-2') {
          // when you update a simple type you can just update the value
          el.isHidden = nodeHidden;
        }

        return el;
      })
    );
  }, [nodeHidden, setElements]);

const [sidebar, setsidebar] = useState(false)

    return (
        <div className="dndflow">
          {console.log(elements)}
      <ReactFlowProvider>
      <Sidebar setstate={setstate} state={state}/>
        <div className="reactflow-wrapper w-100" ref={reactFlowWrapper}>
          <ReactFlow
            elements={elements}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onElementClick={(e)=>{
              // console.log(e);
              setsidebar(true)
              setSelected(e.target.dataset.id)
              elements.map((value,index)=>{
                if(value.id === e.target.dataset.id) setNodeName (value.data.value)
              })
              // setNodeName (e.target.innerText)
            }}
          >
            <Controls />

            {sidebar && 
            <div className="pl_20 pt_20 updateDIV" style={{height: '100vh', width: '341px'}}>
            <div className="d-flex space-between align-items-center">
              <div className="mt_20 mb_50 Heading22M color-neutral100" >Properties</div>
              <div className="cp" onClick={()=>setsidebar(false)}><SvgIcons.CloseIcon/></div>
            </div>
            <div className="mb_16">
              <div className="Heading16M color-neutral100 mb_4">Enter Value</div>
              <div className="">
                <CustomTextField
                  value={nodeName} 
                  onChange={(evt) => setNodeName(evt.target.value)}
                />
              </div>
            </div>
            {/* <label>label:</label>
            <input value={nodeName} onChange={(evt) => setNodeName(evt.target.value)} /> */}

            {/* <label className="updatenode__bglabel">background:</label>
            <input value={nodeBg} onChange={(evt) => setNodeBg(evt.target.value)} /> */}

            {/* <div className="updatenode__checkboxwrapper">
              <label>hidden:</label>
              <input type="checkbox" checked={nodeHidden} onChange={(evt) => setNodeHidden(evt.target.checked)} />
            </div> */}
          </div>}
          </ReactFlow>
        </div>
        
      </ReactFlowProvider>
    </div>
    )
}

export default FlowTest
