import React, { useEffect } from 'react'
import { TransactionService } from '../../services'
import { useParams, useHistory } from 'react-router-dom'
import Loader from '../../components/Loader';

function ShortUrl() {
    const params = useParams();
    const history = useHistory();
    // console.log('params',params);
    useEffect(async() => {
      const url = await TransactionService.ShortUrl({query: {passCode: params.passCode}})
      
      if(url&&url.response&&url.response.data&&url.response.data.orignalUrl){
          // console.log(url);
          window.location.href = url.response.data.orignalUrl
      }

    }, [])
    
  return (
    <div><Loader /></div>
  )
}

export default ShortUrl