import React, {useEffect, useState} from 'react'
import Alert from '@mui/material/Alert';
import { useParams, useHistory } from 'react-router-dom';
import { PngIcons, SvgIcons } from '../../icons';
import {  BrandService,  GrowthtoolService,  PassService } from '../../services';
import Loader from '../../components/Loader';
import QRkjua from '../../components/QR-kjua/qrcode.kjua';
import { enums, GrowthTools, PassDesign } from '../../enums';
import env from '../../config'
import MobileDetect from 'mobile-detect'
import { CircularProgress } from '@mui/material';


const AppleDevices = ['MacIntel', 'iPhone', 'iPod', 'iPad', 'Macintosh']

export default function Pass() {
      
      const params = useParams()

      const [front, setFront]                       = React.useState(true)
      const [loader, setLoader]                     = React.useState(true)
      const [state, setState]                       = React.useState({})
      const [pass, setPass]                         = React.useState({})
      const [passData, setPassData]                 = React.useState({});
      const [brand, setBrand]                       = React.useState({})
      const [growthTool, setGrowthTool]             = React.useState({})
      const [coverUrl, setCoverUrl]                 = React.useState();
      const [device, setDevice]                     = React.useState(enums.DeviceType.ANDRIOD)
      const history                                 = useHistory();
      const [growthToolLoader, setGrowthToolLoader] = React.useState(true)
      const [downloadLoader, setDownloadLoader]     = useState(false);
      const [time, setTime]                         = useState(false)

      // console.log('time ', time)

      const getPass = async () => {
            let pass, brandData;
            let growthToolId;
            
            if((AppleDevices.includes(navigator.platform)) || (new MobileDetect(window.navigator.userAgent).os() === 'AndroidOS')) {
                  // console.log('params.passCode ', params.passCode)
                  pass = await PassService.GetPassByCode({payload: {passCode: params.passCode}});
                  // console.log('pass ', pass);
                  setPassData(pass.response.data[0]);
                  const brandQuery = {_id: pass.response.data[0].brandId, delete: false}
                  brandData = await BrandService.GetPublic({query: brandQuery})
                  if(brandData.response && brandData.response.data) setBrand(brandData.response.data)
                  if(pass.response){
                        if(pass.response.data[0].growthToolId) growthToolId = pass.response.data[0].growthToolId;
                        else {
                              const growthTool = await GrowthtoolService.GetPublicGrowthTool({query:{growthtoolType: GrowthTools.GrowhttoolTypes.LANDING_PAGE, brandId: pass.response.data[0].brandId}})
                              if(growthTool.response){
                                    growthToolId = growthTool.response.data[0]._id
                                    setGrowthTool(growthTool.response.data[0])
                              }
                        }
                        setLoader(false)
                        if(new MobileDetect(window.navigator.userAgent).os() === 'iOS') {
                              // console.log('here')
                              setDownloadLoader(false)
                              setLoader(false)
                              setGrowthToolLoader(false)
                              window.location.href = `${env.API_URL}/v4/pass/pkpass?brandId=${pass.response.data[0].brandId}&passCode=${pass.response.data[0].passCode}&growthToolId=${growthToolId}`
                              return;
                        }
                        else if(new MobileDetect(window.navigator.userAgent).os() === 'AndroidOS') {
                              // console.log('here2')
                              setDownloadLoader(false)
                              setLoader(false)
                              setGrowthToolLoader(false)
                              window.location.href = `${env.API_URL}/v4/services/google/loyaltCard/${pass.response.data[0]._id}/${pass.response.data[0].passDesignId}/${pass.response.data[0].campaignId}/${growthToolId}`
                              return;
                        }
                  }
            }

            
            const query = {passCode: params.passCode, designType: PassDesign.DesignTypes.APPLE}
            // console.log('query ' , query)
            const {error, response} = await PassService.GetPassJSON({query})
            console.log('response here!', response, error);
            await getGrowthtool({pass: response.data})
            if(error) return

            response && setPass(response.data[PassDesign.DesignTypes.APPLE])
            setState(response.data)

            if(response.data.stampCard && response.data.stampCard.currentStamps)
            setCoverUrl(env.STAMP_COVER_URL + response.data.passDesignId + '/' + response.data.stampCard.currentStamps)
      
            setLoader(false)
            AppleDevices.includes(navigator.platform) && setDevice(enums.DeviceType.APPLE)
      }

      const getGrowthtool = async ({pass}) => { 
            setGrowthToolLoader(true)
            let query = {
                  _id           : pass.growthToolId,
                  growthtoolType: pass.growthToolType == "cloudwaitress" ? GrowthTools.GrowhttoolTypes.API : pass.growthToolType,
                  brandId       : pass.brandId
            }
            // console.log('query ', query)
            if(pass.growthToolId) {
                  delete query.growthtoolType
                  delete query.brandId
            }
            else delete query._id
            // console.log('query afer', query)
            let Growthtool = await GrowthtoolService.GetPublicGrowthTool({query});
            // console.log('Growthtool ', Growthtool)
            setGrowthTool(Growthtool.response.data[0])
            setGrowthToolLoader(false)
      }
        
      const handleSubmit1 = async () =>{
            setDownloadLoader(true)
            // console.log('passData --> ', passData)
            if(brand){
                  if(brand&&brand.agency&&brand.agency.id&&brand.agency.id === 'UA95bkhlgEIkHuEBUCXq'){
                        // console.log('sdhhgsdsd');
                        history.push({
                        pathname: passData.growthToolId+'/thanks', 
                        // pathname: params.growthtoolId+'/thanks', 
                        state: {
                              brand: brand,
                              name: passData.userData.firstName
                        }})
                        setDownloadLoader(false)
                  }
                  else{
                        if((new MobileDetect(window.navigator.userAgent).os() === 'iOS') || navigator.platform.includes('MacIntel')) {
                        window.location.href = `${env.API_URL}/v4/pass/pkpass?brandId=${brand._id}&passCode=${passData.passCode}&growthToolId=${passData?.growthToolId || growthTool?._id}`
                        setDownloadLoader(false)
                        setTime(true)

                        setTimeout(() => { setTime(false)
                        
                        }, 15000);
                        if(growthTool?.landingPage?.redirectUrl){
                              window.open(growthTool.landingPage.redirectUrl, '_blank');
                              return;
                        }

                        }
                        else if((new MobileDetect(window.navigator.userAgent).os() === 'AndroidOS')) {
                        setDownloadLoader(false)

                        setTime(true)

                        setTimeout(() => { 
                              setTime(false)
                        
                        }, 15000);
                        window.location.href = `${env.API_URL}/v4/services/google/loyaltCard/${passData._id}/${passData.passDesignId}/${passData.campaignId}/${passData.growthToolId || growthTool?._id}`
                        if(growthTool?.landingPage?.redirectUrl){
                              window.open(growthTool.landingPage.redirectUrl, '_blank');
                              return;
                        }

                        }

                  }
      
            }

      }
    
      const onLoad = async() => {await getPass();}

      React.useEffect(onLoad, [])

      return (
      <div id='growthtoolsPass'>
        {(loader) ? <Loader/>  :
            <div className="mainBody">
                  <div className='Heading24M text-center'>Your card is ready</div>
                  <div className='Heading24M text-center mb_16'>Install into your e-wallet</div>
                  <div className='margin'>
                  <div className="background">
                  <div className="smartphone">
                  <div className="smallboxes ">
                  <div className="smallboxes inside1">
                        <div className="smallboxes inside1">
                        <div className="smallboxes inside1" />
                        <div className="smallboxes inside1" />
                        <div className="smallboxes inside1" />
                        </div>
                        <div className="smallboxes inside1" />
                        <div className="smallboxes inside1">
                        <div className='image'>
                              <SvgIcons.AppleWalletIcon/>                
                        </div>          
                        </div>
                  </div>
                  <div className="smallboxes inside1"></div>
                  <div className="smallboxes inside1"></div>
                  </div>
                  <div className="smallboxes "></div>
                  <div className="smallboxes "></div>
                  </div>
            </div>
                  </div>
                  <div className="middle">
                        <div onClick={handleSubmit1} className={`cp DownloadButton Heading18M text-center ${(growthToolLoader || loader || downloadLoader) && 'disabled'}`}>
                              {(growthToolLoader || loader || downloadLoader) ? <CircularProgress size={17} /> :  'Open in Wallet'}
                        </div>
                  </div>
            </div>
            }

            {time && <div className={`classes.root mt_30`} >
                        
                        <Alert severity="info">Please wait for 15 seconds, Your pass is downloading...</Alert>
                        
              </div>
                    
                }
            </div>
      );
}