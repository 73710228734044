// import * as ct from 'countries-and-timezones'
import ct from 'countries-and-timezones'

import {Moreoptions, PointExpiry, PurchaseTypes, PassVariables, RedemptionType} from '../enums/enums';
import {Brand, Campaign, CountryCode, Currencies, enums, Fields, PassDesign} from '../enums';
import { PngIcons, SvgIcons } from '../icons';
import VenueType from '../enums/VenueType';
import Tier from '../enums/Tier'
import { MenuItem } from '@material-ui/core';



const getNumberOfStamps = () => {
    const numbers = [6, 8, 10, 12, 14, 18, 21, 24, 30]
    return (
        <>
            {/* <option value="">None</option> */}
            {numbers.map((number)=>(
                <option value={number}>{number}</option>
            ))}
        </>
    )
}

const getFestivals = () => {
    const numbers = [
        {name: "Custom Date",month: '',date: ''},
        {name: "Week",month: '',date: ''},
        {name: "Birth Day",month: '',date: ''},
        {name: "New Year's Day",month: '1',date: '1'},
        {name: "Groundhog Day",month: '2',date: '2'},
        {name: "Valentine's Day",month: '2',date: '14'},
        {name: "St. Patrick's Day",month: '3',date: '17'},
        {name: "Earth Day",month: '4',date: '22'},
        {name: "Halloween",month: '10',date: '31'},
        {name: "Christmas Day",month: '12',date: '25'},
        {name: "New Year's Eve",month: '12',date: '31'},
        {name: "Month",month: '',date: ''},
        {name: "Weekly",month: '',date: ''},
        {name: "Monthly",month: '',date: ''},
    ]
    return (
        <>
            {numbers.map((number)=>(
                <option value={number.name}>{number.name}</option>
            ))}
        </>
    )
}

const getColor = () => {
    const color = [
        {
            name: 'Bronze Gradient',
            className : "linear-gradient(103.31deg, #C97C36 0%, #E7BB9C 47.4%, #C6734C 100%)"
        },
        {
            name: 'Silver Gradient',
            className : "linear-gradient(104.58deg, #D6D6D6 13.4%, #F8F8F8 49.17%, #D6D6D6 83.49%)"
        },
        {
            name: 'Gold Gradient',
            className : "linear-gradient(75.23deg, #EBC168 20.99%, #F9DF79 53.51%, #DE9E45 88.14%)"
        },
        {
            name: 'Platinum Gradient',
            className : "linear-gradient(104.85deg, #808080 0%, #EEEEEE 50.32%, #808080 100.87%)"
        },
        {
            name: 'Bronze Solid',
            className : "#CF8964"
        },
        {
            name: 'Silver Solid',
            className : "#DEDEDE"
        },
        {
            name: 'Gold Solid',
            className : "#FFDA57"
        },
        {
            name: 'Platinum Solid',
            className : "#8C8C8C"
        },
    ]
    return(
        <>
            {/* <option value="">None</option> */}
            {
                color.map((value,index)=>(
                    <option style={{background: value.className}} value={index}>
                        {/* <span style={{borderRadius: '50%', height: '24px', width: '24px', background: value.className}}></span> */}
                            {value.name}
                    </option>
                ))
            }
        </>
    )
}

const getContryCodes = () => {
    CountryCode.sort((a, b) => {
        if(a.code < b.code) return -1;
        if(a.code > b.code) return 1;
        return 0;
    })
    // console.log('CountryCode ', CountryCode)
    return CountryCode
}



const getContryCodes2 = () => {
    CountryCode.sort((a, b) => {
        if(a.code < b.code) return -1;
        if(a.code > b.code) return 1;
        return 0;
    })
    return CountryCode 
    // (
    //     <>
    //         {/* <option value="">None</option> */}
    //         {CountryCode.map((value)=>(
    //             <option value={value.code+'&'+value.dial_code}>{value.code + value.dial_code + ' ' + value.flag}</option>
    //         ))}
    //     </>
    // )
}

const getVenueType = () => {
    return VenueType
}

const Programstatus = [
    {
        status : 'Active',
        value  : true
    },
    {
        status : 'Inactive',
        value  : false
    }
];

const getCategories = () => {

    const Categories = [
        'resturant',
        'convenience store',
        'grocery store',
        'specialty food store',
        'liquour store',
        'florist',
        'pharmacy',
    ]
    
    return (
        <>
            {Categories.map((value)=>(
                <option value={value}>{value.charAt(0).toUpperCase() + value.slice(1)}</option>
            ))}
        </>
    )
}

const _sortAscending = (arr) => {
    arr.sort((a, b) => {
        if(a.code < b.code) return -1;
        if(a.code > b.code) return 1;
        return 0;
    })
}

const getCurrencyCodes = () => {
    _sortAscending(Currencies)      // key
   
    return Currencies
}
  

const getTimezones = () => {

    let arr = []

    Object.values(ct.getAllTimezones()).forEach((timezone)=>{
        arr.push(timezone.name)
    })

    return arr

}

const getTierExtraPoint = () => {

    let extraPoints = [
        {
            name : 'Existing Point on purchase',
            value : 1,
        },
        {
            name : 'Double Point on purchase',
            value : 2,
        },
        {
            value : 3,
            name : 'Triple Point on purchase',
        }
    ]

    return extraPoints
}






const getProgramActiveStatus = () => {
    return(
        <>
            {Programstatus.map((value)=>(
                <option value={value.value}>{value.status}</option>
            ))}
        </>
    )
}

const getMoreOptions = () => {
    return(
        <>
        {Moreoptions.map((value)=>(
            <option value={value.value}>{value.name}</option>
        ))}
    </>
    )
}

const getProgramApprovaltypes = () => {
    const Approvaltypes = [
        {
            name    : 'Immediatley',
            value   : Campaign.ApprovalTypes.IMMEDIATELY
        },
        {
            name    : 'Manually',
            value   : Campaign.ApprovalTypes.MANUALLY
        },
        {
            name    : 'After a fixed duration',
            value   : Campaign.ApprovalTypes.AFTER_DURATION
        }
    
    ]
    return Approvaltypes
       
}

const getOperands = () => {
    const Operands = [
        {
            name  : 'None',
            value : ''
        },
        {
            name  : 'Greater Than',
            value : enums.Operands.GREATER
        },
        {
            name  : 'Lesser Than',
            value : enums.Operands.LESSER
        },
        {
            name  : 'Equals To',
            value : enums.Operands.EQUAL
        },
        {
            name  : 'Not Equals To',
            value : enums.Operands.NOT_EQUAL
        }
    ]

    return Operands
}

const getRuleSelectType = () => {
    const RuleSelectType = [
        {
            name  : 'None',
            value : ''
        },
        {
            name : 'Match Any',
            value: 'any'
        },
        {
            name : 'Match All',
            value: 'all',
        },
    ]

    return(
        <>
            {/* {RuleSelectType.map((value)=>(
                <option value={value.value}>{value.name}</option>
            ))} */}
            {RuleSelectType}
        </>
    )
}

const getPointExpiry = () => {
    return (
        <>
            {PointExpiry.map((value)=>(
                <option value={value.value}>{value.name}</option>
            ))}

        </>
    )
}

const getPurchasesTypes = () => {
    return (
        <>
            {PurchaseTypes.map((value)=>(
                <option value={value.value}>{value.name}</option>
            ))}

        </>
    )
}

const getPassVariables = () => {
    return (
        <>
            {PassVariables.map((value)=>(
                <option value={value.value}>{value.name}</option>
            ))}

        </>
    )
}

const PassTypes = () => {
        
    const passTypes = [
        {
            name  : 'None',
            value : ''
        },
        {
            name  : 'Coupon',
            value : PassDesign.PassTypes.COUPON
        },
        {
            name  : 'Ticket',
            value : PassDesign.PassTypes.TICKET
        },
        {
            name  : 'Loyalty Card',
            value : PassDesign.PassTypes.LOYALTY_CARD
        },
        {
            name  : 'Stamp Card',
            value : PassDesign.PassTypes.STAMP_CARD
        },
        {
            name  : 'Membership Card',
            value : PassDesign.PassTypes.MEMBERSHIP_CARD
        }
    ]

    return(
        <>
            {passTypes.map((type, idx)=>(
                <option key={idx} value={type.value}>{type.name}</option>
            ))}
        </>
    )
}

const appleCodesFormat = () => {
    const formats = [
        {
            name  : 'None',
            value : ''
        },
        {
            name  : 'QR Code',
            value : 'PKBarcodeFormatQR'
        },
        {
            name     : 'Barcode PDF417',
            value    : 'PKBarcodeFormatPDF417',
            disabled : true
        }
    ]

    return formats
    // (
    //     <>
    //         {formats.map((type, idx)=> <option disabled={type.disabled} key={idx} value={type.value}>{type.name}</option>)}
    //     </>
    // )
}

const textAlignment = () => {
     return[{
        name : 'Right',
        value : 'PKTextAlignmentRight'
     },
    {
        name:'Left',
        value : 'PKTextAlignmentLeft'
    }]
    // (
    //     <>
    //         {/* <option value=''>None</option> */}
    //         <option value='PKTextAlignmentRight'>Right</option>
    //         <option value='PKTextAlignmentLeft'>Left</option>
    //     </>
    // )
}

const getQrModeTypes = () => {
    return (
        <>
            <option value='text'>Text</option>
            <option value='number'>Number</option>
        </>
    )
}

const getCampaignOptions = (campaigns) => (
    <>
        {/* <option value="">None</option> */}
        {campaigns.map(campaign => (<option value={campaign._id}>{campaign.campaignName}</option>))}
    </>
)

const widgetBtnPositions = () => {
    // value will be clockwise [top, right, bottom, left] seperated with point
    const positions = [
        {
            name  : 'Top Left',
            value : '20px...40px'
        },
        {
            name  : 'Top Right',
            value : '20px.40px..'
        },
        {
            name  : 'Bottom Left',
            value : '..20px.40px'
        },
        {
            name  : 'Bottom Right',
            value : '.40px.20px.'
        },
    ]

    return (
        <>
            {/* <option value="">None</option> */}
            {positions.map((position, idx) => (<option key={idx} value={position.value}>{position.name}</option>))}
        </>
    )
}


const getFieldTypes = () => {
    
    const FieldTypes = [
        {
            name    : "text",
            value   : "text"
        },
        {
            name    : "number",
            value   : "number"
        },
        {
            name   : "date",
            value  : "date"
        },
        {
            name    : "boolean",
            value   : "boolean"
        }
    ]

    return FieldTypes
}

const getFieldTypesSUBBRAND = () => {
    return [Fields.FieldTypes.URL, Fields.FieldTypes.IMAGE_URL]
}

const getBooleanType = () => {
    const BooleanTypes = [
        {
            name    : "true",
            value   :  true
        },
        {
            name    : "false",
            value   :  false
        },
    ]

    return (
        <>
            {/* <option value="">None</option> */}
            {BooleanTypes.map((value)=>(
                <option value={value.value}>{value.name}</option>
            ))}
        </>
    )
}

const FontFamily = () => {

    const fontFamilies = [
        {value : 'Roboto'},
        {value : 'Open Sans'},
        {value : 'Lato'},
        {value : 'Oswald'},
        {value : 'Raleway'},
        {value : "Inter"},
        {value : 'Ubuntu'},
        {value : 'Rubik'},
        {value : 'Inter'},
        {value : 'Playfair Display'},
        {value : 'Roboto Slab'},
        {value : 'Merriweather'},
        {value : 'Lora'},
        {value : 'Crimson Text'},
        {value : 'Nunito'},
        {value : 'Comfortaa'},
        {value : 'NanumGothic'},
        {value : 'Bebas Neue'},
        {value : 'Lobster'},
        {value : 'Fredoka One'},
        {value : 'Monoton'},
        {value : 'Fugaz One'},
    ]

    return (
        <>
            {/* <option value="">None</option> */}
            {fontFamilies.map((item)=>(
                <option value={item.value}>{item.value}</option>
            ))}
        </>
    )

}

const FontWeight = () => {
    const fontWeights = [
        {
            name  : 'Thin',
            value : 100
        },
        {
            name  : 'Extra Light',
            value : 200
        },
        {
            name  : 'Light',
            value : 300
        },
        {
            name  : 'Regular',
            value : 400
        },
        {
            name  : 'Medium',
            value : 500
        },
        {
            name  : 'Semi Bold',
            value : 600
        },
        {
            name  : 'Bold',
            value : 700
        },
        {
            name  : 'Extra Bold',
            value : 800
        },
        {
            name  : 'Heavy',
            value : 900
        },
    ]

    return fontWeights
    // (
        // <>
        //     {/* <option value="">None</option> */}
        //     {fontWeights.map((value)=>(
        //         <option value={value.value}>{value.name}</option>
        //     ))}
        // </>
    // )
}

const BorderStyles = () => {
    const borderStyles = [
        {
            icon : '⎯⎯⎯⎯⎯⎯⎯',
            name  : 'Solid',
            value : 'solid'
        },
        {
            icon : '‒ ‒ ‒',
            name  : 'Dashed',
            value : 'dashed'
        },
    ]

    return (
        <>
            {/* <option value="none">None</option> */}
            {borderStyles.map((item)=>(
                <option value={item.value}>{item.icon + ' ' + item.name}</option>
            ))}
        </>
    )
}

const SubmitActions = () => {
    const actions = [
        { name: 'Create Pass', value: 'createPass'}
    ]
    return (
        <>
            {/* <option value="none">None</option> */}
            {actions.map((item)=>(
                <option value={item.value}>{item.name}</option>
            ))}
        </>
    )
}

const InputTypes = () => {
    const options = [
        {name: 'date', value: 'date'},
        {name: 'checkbox', value: 'checkbox'},
        {name: 'number', value: 'number'},
        {name: 'password', value: 'password'},
        {name: 'email', value: 'email'},
        {name: 'text', value: 'text'},
        {name: 'radio', value: 'radio'},
        {name: 'phone', value: 'tel'},
        {name: 'time', value: 'time'},
    ]

    return options
    // (
    //     <>
    //         {/* <option value="">None</option> */}
    //         {options.map((item)=>(
    //             <option value={item.value}>{item.name}</option>
    //         ))}
    //     </>
    // )
}

const Tierid= () => {
    const options = [
        {
            name : 'Silver',
            value: 'CrqtUpmGdeKlIYegdow1',
            
        },
        {
            name : 'Gold',
            value: 'EdWDVbJND17nNpihVRgQ',

        },
        {
            name : 'Platinum',
            value: 'PC7u1Lw1zT4nAzjCZfOU',

        }
    ]

        return options
}





const ApplePassRedeemTypes = () => {
    const options = [
        {
            name : 'Apple Wallet',
            value: PassDesign.DesignTypes.APPLE,
           
        },
        {
            name : 'None',
            value: PassDesign.DesignTypes.BEASTY,
            
        }
    ]

        return options
}


const AndroidPassRedeemTypes = () => {
    const options = [
        {
            name : 'Beasty App',
            value: PassDesign.DesignTypes.BEASTY,
            icon : PngIcons.BeastyPassIcon
        },
        {
            name : 'GPay',
            value: PassDesign.DesignTypes.GOOGLE,
            icon : PngIcons.GooglePassIcon,
        },
        {
            name : 'Wallet Passes',
            value: PassDesign.DesignTypes.WALLETPASSES,
            icon : PngIcons.WalletPassesIcon,
        }
    ]

        return options
        
}

const getMonthOptions = () => {

    const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    return Months
}

const getRedemptionType = () => {
    
    let redemptionTypes = [
        {
            name : 'Remote Points',
            value : RedemptionType.SELF
        },
        {
            name : 'In Person',
            value : RedemptionType.CASHIER
        },

    ]

    return redemptionTypes
}

const getBrandTypes = () => {

    return(
        <>  
            <option value = {Brand.BrandTypes.DEMO}>{"Demo"}</option>
            <option value = {Brand.BrandTypes.NORMAL}>{"Small Businesses"}</option>
            <option value = {Brand.BrandTypes.SHARED_LOYALITY}>{"Shared Loyalty"}</option>
        </>
    )
}

const getEarningTypes = () => {

    const types = [
        {
            name : 'Increment of points (recommended)',
            value : Campaign.EarningTypes.INCREMENTAL,
        },
        {
            name : 'Fixed amount of points',
            value : Campaign.EarningTypes.FIXED,
        },
        {
            name : 'Range Bucket',
            value : Campaign.EarningTypes.RANGE,
        },
    ]

    return(
        <>
        {types.map((element, idx)=>
            <option value={element.value}>{element.name}</option>
        )}
        </>
    )
}

export {
    getContryCodes,
    getCurrencyCodes,
    getTimezones,
    getProgramActiveStatus,
    getMoreOptions,
    getProgramApprovaltypes,
    getOperands,
    getRuleSelectType,
    getPointExpiry,
    getPurchasesTypes,
    getPassVariables,
    PassTypes,
    appleCodesFormat,
    textAlignment,
    getQrModeTypes,
    getCampaignOptions,
    widgetBtnPositions,
    getFieldTypes,
    getBooleanType,
    FontFamily,
    FontWeight,
    BorderStyles,
    SubmitActions,
    InputTypes,
    getTierExtraPoint,
    getColor,
    getVenueType,
    getRedemptionType,
    getNumberOfStamps,
    AndroidPassRedeemTypes,
    ApplePassRedeemTypes,
    getMonthOptions,
    getFieldTypesSUBBRAND,
    getEarningTypes,
    getCategories,
    getFestivals,
    getContryCodes2,
    getBrandTypes,
    Tierid
}
