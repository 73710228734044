import React, { useEffect, useState } from 'react'
import { SvgIcons } from '../../icons'
import { utils } from '../../utils'
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router-dom'

function LandingPageDashboard() {
    const history = useHistory();
    const data = history.location.state
   
    const [finalData, setFinalData] = useState([]);
    const params = useParams()
    const [seemoreDeals, setSeemoreDeals] = useState(false)


    window.onbeforeunload = function (e) {
        if (e) e.returnValue = history.push('/page/'+params.growthtoolId)
        return history.push('/page/'+params.growthtoolId);
    };
   
   
    // const onLoad = async()=> {
    //     const query = {brandId: history.location.state.brand._id, delete: false}
    //     const {response, error} = await DealService.Get({query});
    //     let dealsData = [];
    //     const deals = await localforage.getItem('deals');
    //     response && response.data.map((element, idx)=>{
    //         data.deals.map((deal, idx)=>{
    //             if(element.name === deal.deal)
    //             {  
    //                 if(deals && deals.length > 0)
    //                 {
    //                     let checkDeal = deals.filter((d, idx)=>{ if(d.name === element.name) return true;})
    //                     if(!checkDeal || checkDeal.length === 0) dealsData.push({...deal, ...element, avail: false, startDate: new Date(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
    //                 }
    //                 else
    //                 {
    //                     dealsData.push({...deal, ...element, avail: false, startDate: new Date().valueOf(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
    //                 }
    //             }
    //         })
    //     })
        
    //     if(deals && deals.length > 0) setFinalData([...deals,...dealsData]);
    //     else setFinalData([...dealsData])
    //     if(dealsData && dealsData.length > 0 && deals && deals.length > 0 ) localforage.setItem('deals', [...deals,...dealsData]);
    //     else if(dealsData && dealsData.length > 0) localforage.setItem('deals', [...dealsData]);
    //     localforage.setItem('transaction', data.transaction);
    //     localforage.setItem('brand', data.brand);
    // }

    // useEffect(onLoad, [])


   
    return (
        <div>
                <div>
                    <div className="d-flex align-items-center space-between pl_16 pr_16 pb_4 pt_16" style={{backgroundColor: '#F7F7F7'}}>
                        <div className="Heading22M color-GeneralBlack">{"Hi "+utils.capitalizeAll(data.transaction.customerName)+" 👋"}</div>
                        {   
                        // selectedBrand.brandLogo ?
                        //     <img src={selectedBrand.brandLogo} width={32} height={32} className="borderRadius-40" alt=""></img> :

                            utils.getAvatar({firstName: 'john', width: '32px', heigth: '32px'})
                        }
                    </div>
                    <div className="d-flex align-items-center pl_16 pr_16 pb_16" style={{backgroundColor: '#F7F7F7'}}>
                        <SvgIcons.Point />
                        <div className="Heading18M color-neutral100 ml_8 mr_16">{data.transaction.loyaltyCard.passData.currentPoints+" Points"}</div>
                        <SvgIcons.RefreshIcon height="16px" width="16px"/>
                    </div>
                    <div className="Heading22M color-neutral100 mb_4 pl_16 pr_16 mt_16">Deals</div>
                    <div className="Link13R color-neutral60 mb_16 pl_16 pr_16">You can avail these exciting deals.</div>
                    
                    <div>
                    
                    </div>
                
                    <div className="mb_16 pl_16 pr_16 mt_16">
                        <div className="Heading22M color-neutral100">Earn Points</div>
                        <div className="Link13R color-neutral60 mt_4">On every visit you will earn points</div>
                    </div>
                    <div className="pl_16 pr_16 mb_24">
                        <div className="w-100 d-flex space-between" style={{backgroundColor: '#F5F5F5', border: '1px solid #E3E3E3', borderRadius: '4px'}}>
                            <div className="Link13M color-GeneralBlack m_16">{data.pointsPerVisit}</div>
                            <div className="Link13M color-GeneralBlack m_16">/per visit</div>
                        </div>
                    </div>
                    <div className="mb_32 pl_16 pr_16 mt_16">
                        <div className="Heading22M color-neutral100">Qualifying Points</div>
                        <div className="Link13R color-neutral60 mt_4">These are the qualifying points for all tiers.</div>
                    </div>
                    <div>
                        {
                            history.location.state.tiers && history.location.state.tiers.length > 0 &&
                            history.location.state.tiers.map((value, index)=>(
                                value._id === data.transaction.loyaltyCard.passData.currentTier.currentTierId &&
                                <div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                    <div className={"Link13M color-neutral100"}>{value.name}</div>
                                    <div className={"Link13M color-neutral100"}>{value.pointRange.minPoints+"-"+value.pointRange.maxPoints+" pts"}</div>
                                </div>
                            ))
                        }
                        {
                            history.location.state.tiers && history.location.state.tiers.length > 0 &&
                            history.location.state.tiers.map((value, index)=>(
                                value._id !== data.transaction.loyaltyCard.passData.currentTier.currentTierId &&
                                <div style={{padding: '12px 16px 12px 16px'}} className="d-flex align-items-center space-between">
                                    <div className={"Link13M color-neutral60"}>{value.name}</div>
                                    <div className={"Link13M color-neutral60"}>{value.pointRange.minPoints+"-"+value.pointRange.maxPoints+" pts"}</div>
                                </div>
                            ))
                        }
                    </div>
                   
                </div>
        </div>
    )
}

export default LandingPageDashboard
