// import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router";
// import CustomTab from "../../../components/CustomTab";
// // import Flow from '../../pages/subpages/Flow'
// import { LayoutContext } from "../../../context/Layout.context";
// import CustomSearch from "../../../components/CustomSearch";
// import { SvgIcons } from "../../../icons";
// import CustomButton from "../../../components/CustomButton";
// import AutomationService from "../../../services/Automation.service";
// import { BrandContext } from "../../../context/Brand.context";
// import localForage from "localforage";
// import { IconButton } from "@mui/material";
// import { ColorSchemeCode } from "../../../enums/ColorScheme";
// import Loader from "../../../components/Loader";
// import CustomTableV2 from "../../../components/CustomTableV2";
// import DeleteModal from "../../../components/DeleteModal";
// import CustomsModal from "../../../components/CustomModal";
// import { DealService } from "../../../services";
// import { showToaster } from "../../../utils/utils";
// import { RedemptionType } from "../../../enums/enums";

// export default function Listing() {
//   const [tab, changeTab] = useState(0);
//   const [deals, setDeals] = useState([]);
//   const [show, setShow] = useState({
//     showFilter: false,
//   });

//   const history = useHistory();
//   const layout = useContext(LayoutContext);
//   const brand = useContext(BrandContext);

//   const [labels, setLabels] = useState(["Automations"]);
//   const [components, setComponents] = useState([
//     // <Flow hideSearch={true}/>,
//     <Automation history={history} />,
//   ]);
//   // const labels = ['Flows','Automations']

//   // const components = [
//   //     <Flow hideSearch={true}/>,
//   //     <Automation history={history} result={result} setResult={setResult} filtered={filtered} setFiltered={setFiltered} />,
//   // ]

//   useEffect(() => {
//     // if(brand.brandRedemptionType === RedemptionType.ORDER){
//     //     setComponents(components.splice(0,1))
//     //     setLabels(labels.splice(0,1))
//     // }
//   }, []);

//   const handleSearch = (e) => {
//     // const results = result.filter(item =>
//     //     item.Name.toLowerCase().includes(e.target.value.toLowerCase())
//     // );
//     // setFiltered(results)
//   };

//   useEffect(() => {
//     layout.setLayout({
//       title: "Automation",
//       mainPage: "Communication",
//       borderBottom: true,
//       edit: false,
//       backTitles: ["Communication"],
//       button: (
//         <CustomButton
//           btntext={"Add Rules"}
//           varient={"primary"}
//           onClick={handleHeaderButtonFunc}
//         />
//       ),
//     });
//   }, []);

//   const handleHeaderButtonFunc = () => {
//     history.push({ pathname: history.location.pathname + "/createrule" });
//   };

//   return (
//     <div id="AutomationLisitng" className="pt_16 pl_40 pr_40 pb_16">
//       <div className="">
//         <CustomSearch
//           className="w-50"
//           placeholder="Search"
//           icon={<SvgIcons.SearchIcon />}
//           position={"start"}
//           onChange={(e) => handleSearch(e)}
//           onClickFilter={() => setShow({ ...show, showFiter: !show.showFiter })}
//           filterShow={show.showFiter}
//         />
//       </div>
//       <div className="mt_32">
//         <CustomTab
//           selectedTab={tab}
//           changeTab={changeTab}
//           labels={labels}
//           components={components}
//         />
//       </div>
//     </div>
//   );
// }

// const Automation = ({ history }) => {
//   const [result, setResult] = useState([]);
//   const [filtered, setFiltered] = useState([]);

//   // useEffect(()=>{
//   //     layout.setLayout({
//   //         title           : "Automation",
//   //         showCollapseIcon: false,
//   //         expandedBar     : false,
//   //         button          : <CustomButton
//   //                         btntext      = {'Add Automation'}
//   //                         onClick      = {()=>history.push({pathname : history.location.pathname + '/createrule'})}
//   //                     />
//   //     });
//   // },[])

//   const brand = useContext(BrandContext);

//   const [loader, setLoader] = useState(true);

//   const pushArr = (arr, body) => {
//     arr &&
//       arr.forEach((v, index) => {
//         body.push({
//           id: v._id,
//           value: v,
//           Name: v.name,
//           Trigger: v.trigger.length,
//           Action: v.action.length,
//           Created:
//             new Date(v.created).getDate() +
//             "-" +
//             (new Date(v.created).getMonth() + 1) +
//             "-" +
//             new Date(v.created).getFullYear(),
//         });
//       });
//     return body;
//   };

//   const onLoad = async (refresh) => {
//     setLoader(true);
//     let body = [];
//     let arr = [];
//     let automation = await localForage.getItem("automation");
//     if (
//       !automation ||
//       automation.length === 0 ||
//       refresh ||
//       (history.location.state && history.location.state.reload)
//     ) {
//       const { response, error } = await AutomationService.Get({
//         query: { brandId: brand._id, delete: false },
//       });
//       if (response) {
//         localForage.setItem("automation", response.data);
//         automation = response.data;
//       }
//     }
//     arr = await pushArr(automation, body);
//     setResult(arr);
//     setFiltered(arr);
//     setLoader(false);
//     return;
//   };

//   useEffect(onLoad, []);

//   const columns = [
//     { field: "Name", headerName: "Name", flex: 1 },
//     { field: "Trigger", headerName: "Trigger", flex: 1 },
//     { field: "Action", headerName: "Action", flex: 1 },
//     { field: "Created", headerName: "Created", flex: 1 },
//     {
//       field: "",
//       headerName: "",
//       sortable: false,
//       flex: 1,
//       align: "right",
//       disableClickEventBubbling: true,
//       renderCell: (params) => {
//         return (
//           <div className="d-flex justify-content-between align-items-center cp ">
//             <IconButton
//               className="deleteIcon"
//               onClick={async (e) => {
//                 e.preventDefault();
//                 e.stopPropagation();
//                 let payload = {
//                   name: params.row.value.name,
//                   brandId: params.row.value.brandId,
//                   trigger: params.row.value.trigger,
//                   action: params.row.value.action,
//                 };
//                 setLoader({ ...loader, mainFlow: true });
//                 const { response, error } = await AutomationService.Create({
//                   toaster: true,
//                   payload,
//                 });
//                 if (response) onLoad(true);
//               }}
//             >
//               <SvgIcons.CopyIcon color={ColorSchemeCode.Paragraph} />
//             </IconButton>
//           </div>
//         );
//       },
//     },
//   ];

//   const [deleteModal, setDeleteModal] = useState(false);
//   const [deleteLoader, setDeleteLoader] = useState(false);
//   const [selectedRows, setSelectedRows] = useState();

//   const handleDelete = async () => {
//     setDeleteLoader(true);
//     const { response, error } = await AutomationService.Update({
//       payload: { deleteAll: selectedRows, brandId: brand._id },
//     });
//     if (response) await onLoad(true);
//     setDeleteLoader(false);
//     setDeleteModal(false);
//   };

//   return (
//     <div>
//       {loader ? (
//         <Loader width={180} height={180} />
//       ) : (
//         <CustomTableV2
//           pageSize={50}
//           height={"calc(100vh - 140px)"}
//           checkbox={true}
//           rows={filtered || result}
//           title={"Automation"}
//           columns={columns}
//           deleteAll={true}
//           onRowClick={async (value) => {
//             history.push({
//               pathname: history.location.pathname + "/createrule",
//               state: { edit: true, ...value.row.value },
//             });
//           }}
//           setSelectedRows={setSelectedRows}
//           handleDelete={() => setDeleteModal(true)}
//         />
//       )}

//       <CustomsModal
//         open={deleteModal}
//         minWidth={"600px"}
//         component={
//           <DeleteModal
//             icon={deleteLoader}
//             title="Automation"
//             onCancel={() => setDeleteModal(false)}
//             onDelete={handleDelete}
//           />
//         }
//         onClose={() => setDeleteModal(false)}
//       />
//     </div>
//   );
// };

import React from 'react'

export default function Listing() {
  return (
    <div>Listing</div>
  )
}
