import localforage from 'localforage';
import React, { useEffect, useState } from 'react'
import CustomButton from '../../components/CustomButton';
import CustomCheckBox from '../../components/CustomCheckBox';
import CustomsModal from '../../components/CustomModal';
import CustomTextField from '../../components/CustomTextField';
import { SvgIcons } from '../../icons';
import { BrandService, TransactionService } from '../../services';
import {useLocation} from 'react-router-dom'
import { CircularProgress } from '@mui/material';


export default function BusinessCheckoutRedeemGift() {

    const [state, setState] = useState({
        brandData        : '',
        userId           : '',
        remember         : false,
        logined          : false,
        confirmationModal: false,
        passCode         : '',
        apiLoader        : '',
        apiError         : ''
    });


    const location = useLocation();
    const QueryParams = new URLSearchParams(location.search);
    const params = { 
        brandId        : QueryParams.get('brandId')
    }

    const [show, setShow] = useState({
        confirmationModal : false
    })


    const [error, setError] = useState(false)

    const [type, settype] = useState('password')


    const onLoad = async() =>{
        setState({...state, loader : true})
        // setState({...state, loader : true});
        const userId = await localforage.getItem('userId')
        const brand  = await getBrand();
        if(userId) {
            setState({...state ,userId: userId, remember: true, logined: true , brandData: brand})
        } 
        else
        {
            setState({...state , brandData: brand})
        }
    }

    useEffect(onLoad,[])


    const getBrand = async () => {
        const {response, error} = await BrandService.GetPublic({query: {_id: params.brandId}})
        if(response) {
            return response.data
        }
    
    }

    const eyeClick = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
          x.type = "text";
          settype('password')
        } else {
          x.type = "password";
          settype('text')
        }
    }

    const handleChange = async (e, name) => {
        setState({...state, [name] : e.target.value});
    }


    const handleSubmitFunc = async (e)  =>{
        e.preventDefault();
        setState({...state, apiLoader : true, apiError : ''})
        // setShow({...show, confirmationModal : true})

        if(state.remember && state.userId)
        {
            localforage.setItem('userId', state.userId)
        } 

        const payload = {
            passCode      : state.passCode,
            idKey         : state.userId,
        }

    

        const {response, error} = await TransactionService.GiftCardRedemptionBusiness({payload, toaster: true})
        if(response) {
            if(state.remember && state.userId) setState({...state, apiError : '', apiLoader: false , userId : state.userId, logined: true, remember: true})
            else setState({...state, apiError : '', apiLoader: false , userId : ''})
        }
        else {
            setState({...state, apiError : error.error, apiLoader: false})
        }
        
        setShow({...show, confirmationModal : false})
    }

  return (
    <div>
        {!state.loader && state.brandData != "" ? 

         <div id="BusinessComponent" >
                <div className="brandNameTitle d-flex">
                    <div >
                        <img className="brandLogo" src={state.brandData.brandLogo} height="32" width="32" alt="" />
                    </div>
                    <div className="ml_16 middle Heading16M color-neutral100 capitalize">
                        {state.brandData.brandName}
                    </div>
                </div> 

                <form className="row">
                    <div className={(state.userId && state.remember && state.logined) ? 'd-none' : "col-md-12"}>
                        <CustomTextField 
                            label     = "Enter User Id"
                            className = "inputTextField col-12"
                            type      = "password"
                            name      = "password"
                            id        = "password"
                            value     = {state.userId}
                            onChange  = {(e) => {handleChange(e,'userId')}}
                            //  error        = {formik.touched.password && formik.errors.password}
                            //  helperText   = {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                            icon         = {<div className={"cp"} onClick={eyeClick}>{type !== 'password' ? <SvgIcons.EyeIconPassword/> : <SvgIcons.CrossEyeIcon/>}</div>}
                            position     = "end"
                            required     = {true}
                        
                        />
                    </div>

                    <div className={(state.userId && state.remember && state.logined) ? 'd-none' : "col-md-12"}>
                          <CustomCheckBox 
                                value={state.remember} 
                                onChange={()=>{
                                    setState({...state, remember : !state.remember})
                                }
                            } className="fs-14 color-neutral100" label={"Remember Me"} />
                    </div>

                     <div className="col-md-12 mt_40">
                        <div className="Body14R color-neutral60 mb_16">
                            Enter passCode of customer :
                        </div>
                        <CustomTextField 
                             label     = "Enter Pass Code"
                             className = "inputTextField col-12"
                             name      = "passCode"
                             id        = "passCode"
                             value     = {state.passCode}
                             onChange  = {(e) => {handleChange(e,'passCode')}}
                             position  = "start"
                             required  = {true}

                        />
                        {state.apiError != "" && <div className="error">{state.apiError}</div>}
                    </div> 
                  
                    <div className="customButton col-md-12">
                        <CustomButton 
                            className = "w-100 borderRadius-0 position-fixed l-0 b-0"
                            btntext   = "Continue"
                            height    = "54px"
                            disabled  = {error || 
                                (state.userId.length != 6 || state.passCode.length != 8) }
                            onClick   = {()=>setShow({...show, confirmationModal : true})}
                        />
                    </div>

                    <CustomsModal 
                        component = {<ConfirmationComponent 
                                        onClose          = {()=>setShow({...show, confirmationModal : false})}
                                        state            = {state}
                                        handleSubmitFunc = {handleSubmitFunc}
                        />}
                        open      = {show.confirmationModal}
                        onClose   = {()=>setShow({...show, confirmationModal : false})}
                        minWidth  = {'100%'}
                    />
                </form>
            </div> 
           : "" }
    </div>
  )
}

const ConfirmationComponent = ({state, onClose, handleSubmitFunc}) => {


    return(
        <div className="confirmationComponent">
            <div className="Heading18M color-neutral100 text-center mb_16">
                Confirm Transaction
            </div>
            Please confirm to redeem this pass
            <br/>
            <div className="mt_40 d-flex space-between">
                <div className="mr_8" style={{width : '48%'}}>
                    <CustomButton 
                        btntext   = "Cancel"
                        height    = "50px"
                        width     = "100%"
                        className = "w-100"
                        varient   = "secondary"
                        onClick   = {onClose}
                    />
                </div>
                <div style={{width : '48%'}}>
                    <CustomButton 
                        btntext   = "Confirm"
                        height    = "50px"
                        width     = "100%"
                        className = "w-100"
                        onClick   = {handleSubmitFunc}
                        icon      = {state.apiLoader && <CircularProgress size={20} color={'inherit'}/>}
                    />
                </div>

            </div>
        </div>
    )
}
