import localforage from 'localforage'
import React, { useEffect } from 'react'
import env from '../../config';
import { BrandService, PassService } from '../../services';

export default function LightspeedRedirect() {
    useEffect(async ()=>{

      const brand = await localforage.getItem('brand');

      if(window.location.search && brand.integrations && brand.integrations.lightspeed){
          const queryParams = new URLSearchParams(window.location.search)
          const code        = queryParams.get("code")
          const state       = queryParams.get("state")

          let payload = {
              code          : code,
              client_id     : brand.integrations.lightspeed.client_id,
              client_secret : brand.integrations.lightspeed.client_secret,
              grant_type    : "authorization_code",
              redirect_uri  : env.SOCKET_URL + '/page/lightspeed'
          }
          // console.log('payload', payload);
          if(!brand.integrations.access_token)
          {
              const {response, error} = await PassService.Redirect({payload});
              let payload1 = {
                  access_token  : response?.data?.token_type + ' ' + response?.data?.access_token,
                  refresh_token : response?.data?.refresh_token,
              }      
              if(response)
              {                    
                  const companies = await PassService.GetCompaines({query: {token : payload1.access_token}});
                  // console.log('companies', companies);
                  if(companies.response) {
                      payload1.company_id = companies.response.data.id;
                      payload = {_id: brand._id, integrations: {}}
                    
                      payload.integrations.lightspeed = {...brand.integrations.lightspeed, ...payload1};
                      const { error, response } = await BrandService.Update({toaster: true, payload});
                      const webhook = await BrandService.LightSpeed({toaster: true, payload:{brandId: brand._id, client_id: brand.integrations.lightspeed.client_id, client_secret: brand.integrations.lightspeed.client_secret, company_id: companies.response.data.id, access_token: payload1.access_token}})
                      return window.location.href = env.SOCKET_URL + '/' + brand._id+'/setting/integration'
                  }
              }
          }


      }
      else {
          return;
      }
  },[])
  return (
    <div>LightSpeed Redirect</div>
  )
}
