import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import * as localForage from 'localforage'

import CustomButton from '../../../components/CustomButton'
import Loader from '../../../components/Loader'
import ReactTooltip from 'react-tooltip'
import CustomSwitch from '../../../components/CustomSwitch'
import QRcode from '../../../components/QrCode';
import ConnectionsView from '../subpages/ConnectionsView'

import { SvgIcons } from '../../../icons'

import { AgencyContext } from '../../../context/Agency.context'
import { LayoutContext } from '../../../context/Layout.context'
import { BrandContext } from '../../../context/Brand.context'

import { ColorSchemeCode } from '../../../enums/ColorScheme'
import { CircularProgress, Link, colors } from '@mui/material'

import { CampaignService, FlowService, GrowthtoolService } from '../../../services'

import { useFormik } from 'formik';
import { GrowthtoolVld } from '../../../validation'
import { showToaster, uploadImage } from '../../../utils/utils'
import { ColorScheme, GrowthTools } from '../../../enums'
import { ChromePicker } from 'react-color'
import { ClickOutside } from '../../../utils'
import CustomSelect from '../../../components/CustomSelect'
import CustomTextField from '../../../components/CustomTextField'
import env from '../../../config'



export default function SignUpPopUp() {
    const [flow, setFlow] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})
    const [loader, setLoader] = useState(false);

    const brand = useContext(BrandContext)
    const layout = useContext(LayoutContext)
    const agency = useContext(AgencyContext)
    const history = useHistory();

    const [growthtool, setGrowthtool] = useState(history.location.state)
    const [selection, setSelection] = useState('desktop')

    console.log('growthtool ', history.location.state.signUpPopUp)

    const [state, setState] = useState({
        processing: false,
        apiError: '',
    })

    const onLoad = async () => {
        setLoader(true)
        layout.setLayout({ editTitle: formik.values.growthtoolName || "" })

        const { response, error } = await FlowService.Get({ query: { brandId: brand._id, flowType: 'new', delete: false } })
        if (response) {
            let arr2 = [];
            const flowList = response.data.forEach((element, idx) => {
                arr2.push(element);
            })
            setFlow(arr2);
            if (arr2.length == 1) formik.setValues({ ...formik.values, flow: formik.values.flow == "" && arr2[0]._id })
            if (arr2.length == 0) {
                showToaster({ position: 'bottom-right', title: 'No Flows!', message: 'Please create Landingpage flows first', severity: 'error' })
            }
        }
        setLoader(false)
    }

    const handleCampaigns = (campaigns) => {
        setCampaigns([...campaigns])
        const campaign = campaigns.find(campaign => campaign._id === formik.values.campaignId)
        setSelectedCampaign(campaign || {})
    }

    const CallServices = async () => {
        const query = { brandId: brand._id, campaignType: 5 }
        const { error, response } = await CampaignService.GetQueriedCampaign({ query })
        response && handleCampaigns(response.data)
    }

    useEffect(() => {
        layout.setLayout({
            borderBottom: true
        })
        onLoad();
        CallServices();
    }, [])

    const initState = {
        flow             : history.location.state && history.location.state.flowId ? history.location.state.flowId[0]                                                                                                                                  : '',
        growthtoolName   : history.location.state && history.location.state.growthtoolName ? history.location.state.growthtoolName                                                                                                                     : '',
        growthtoolType   : history.location.state && history.location.state.growthtoolType ? history.location.state.growthtoolType                                                                                                                     : '',
        flowName         : '',
        redirectUrlCheck : history.location.state && history.location.state.popup && history.location.state.popup.redirectUrl ? true                                                                                                       : false,
        cover            : history.location.state && history.location.state.popup && history.location.state.popup.cover ? history.location.state.popup.cover                                                                         : "",
        points           : history.location.state && history.location.state.points ? history.location.state.points                                                                                                                                     : false,
        disableClaimPoint: history.location.state && history.location.state.disableClaimPoint ? history.location.state.disableClaimPoint                                                                                                               : false,
        showPass         : history.location.state && history.location.state?.popup?.showPass ? history.location.state?.popup?.showPass                                                                                                     : true,
        redirectUrl      : history.location.state && history.location.state.popup && history.location.state.popup.redirectUrl ? history.location.state.popup.redirectUrl                                                             : "",
        primaryColor     : history.location.state && history.location.state.popup && history.location.state.popup.primaryColor ? history.location.state.popup.primaryColor                                                           : '#B2831E',
        backgroundColor  : history.location.state && history.location.state.popup && history.location.state.popup.backgroundColor ? history.location.state.popup.backgroundColor                                                     : '#1E1904',
        theme            : history.location.state && history.location.state.popup && history.location.state.popup.theme ? history.location.state.popup.theme                                                                         : 'dark',
        heading          : history.location.state && history.location.state.popup && history.location.state.popup.heading ? history.location.state.popup.heading                                                                     : 'Sign Up And Earn',
        mainheading      : history.location.state && history.location.state.popup && history.location.state.popup.mainheading ? history.location.state.popup.mainheading                                                             : '100 Points',
        description      : history.location.state && history.location.state.popup && history.location.state.popup.description ? history.location.state.popup.description                                                             : 'Caffé Saverios, o mejor conocido como el "Saveritos",',
        firstName        : history.location.state && history.location.state.popup && history.location.state.popup.labels && history.location.state.popup.labels.firstName ? history.location.state.popup.labels.firstName      : 'First Name',
        lastName         : history.location.state && history.location.state.popup && history.location.state.popup.labels && history.location.state.popup.labels.lastName ? history.location.state.popup.labels.lastName        : 'Last Name',
        email            : history.location.state && history.location.state.popup && history.location.state.popup.labels && history.location.state.popup.labels.email ? history.location.state.popup.labels.email              : 'Email',
        code             : history.location.state && history.location.state.popup && history.location.state.popup.labels && history.location.state.popup.labels.code ? history.location.state.popup.labels.code                : 'Code',
        phone            : history.location.state && history.location.state.popup && history.location.state.popup.labels && history.location.state.popup.labels.phone ? history.location.state.popup.labels.phone              : 'Phone Number',
        dateOfBirth      : history.location.state && history  .location.state.popup && history.location.state.popup.labels && history.location.state.popup.labels.dateOfBirth ? history.location.state.popup.labels.dateOfBirth: 'Date of birth',
        referral         : history.location.state && history.location.state.popup && history.location.state.popup.labels && history.location.state.popup.labels.referral ? history.location.state.signUpPopUp.labels.referral        : 'Referral',
    }

    const formik = useFormik({
        initialValues: { ...initState },
        isInitialValid: true,
        enableReinitialize: true
    })

    const setFlowName = () => {
        flow.filter((element, idx) => {
            (element._id == formik.values.flow) && formik.setValues({ ...formik.values, flowName: element.name })
        })
    }

    useEffect(() => {
        setFlowName();
    }, [formik.values.flow, flow])

    const handleSubmit = async () => {
        console.log('submit ')
        setState({ ...state, processing: true })

        let cover
        const user = await localForage.getItem('user')
        const desiredPath = `${user._id}/${brand.brandName}`


        if (typeof formik.values.cover != "string") {
            let coverUpload = await uploadImage({ file: formik.values.cover, desiredPath })
            cover = coverUpload.response.data;
        }
        else {
            cover = formik.values.cover
        }

        if (cover && cover.error) return setState({ loader: false, apiError: cover.error.message })

        const payload = {
            _id: growthtool._id,
            campaignId: campaigns[0]._id,
            flowId: flow.length === 1 ? [flow[0]._id] : (formik.values.flow === '' && flow.length > 0) ? [flow[0]._id] : [formik.values.flow],
            growthtoolName: layout.elements.editTitle,
            points: formik.values.points,
            disableClaimPoint: formik.values.disableClaimPoint.toString(),
            popup: {
                script         : formik.values.script,
                primaryColor   : formik.values.primaryColor,
                theme          : formik.values.theme,
                backgroundColor: formik.values.backgroundColor,
                heading        : formik.values.heading,
                mainheading    : formik.values.mainheading,
                description    : formik.values.description,
                cover: cover,
                labels: {
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                    email: formik.values.email,
                    code: formik.values.code,
                    phone: formik.values.phone,
                }
            }
        }

        console.log('pylloadzz,', payload);
        // console.log('formik,', formik.values);
        // console.log('====================================');
        // console.log('update gt', payload);
        // console.log('====================================');
        const { error, response } = await GrowthtoolService.UpdateGrowthTool({ toaster: true, payload })
        setState({ ...state, processing: false })
        if (error) return setState({ ...state, processing: false, apiError: error.message })

        const location = window.location.pathname.split('/')
        location.pop()
        location.pop()
        console.log('location -> ', location.join('/'))
        history.push(location.join('/'))
        return
    }

    useEffect(()=>{
        layout.setLayout({
            edit        : true,
            placeholder : "Signup Popup",
            borderBottom: true,
            backTitles  : ['Channels'],
            switch      : "",
            button      : 
                <CustomButton
                    btntext  = {'Publish & close'}
                    type     = {'submit'}
                    onClick  = {handleSubmit}
                    disabled = {!formik.isValid}
                    icon     = {state.processing && <CircularProgress size={20} color={'inherit'}/>}
                />
        })
    },[campaigns, formik.values, growthtool ,state.processing,layout.elements.editTitle])



    useEffect(()=>{
        layout.setLayout({
            editTitle : growthtool.growthtoolName || ''
        })
    },[])

    // console.log('formik ', formik.values)

    return (
        <div id="PopUpGrowthTool" >
            {!loader ?
                <div className="h-100 d-flex">
                    <div className="w-32">
                        <Leftpane growthtool={growthtool} flow={flow} formik={formik} />
                    </div>
                    <div className="flowSection w-100 bg-color-neutral0" >
                        <div style={{ background: '#ffffff' }} className='d-flex justify-content-center cp pt_12 pb_12 border-bottom'>
                            <div className={`tabButton Link13R transition w-15 text-center ${selection == 'desktop' && 'selectedTabButton Link13M'}`} onClick={() => setSelection('desktop')}>
                                <SvgIcons.Laptope color={selection == 'desktop' ? '#2960EC' : '#84889B'} /> Desktop
                            </div>
                            <div className={`tabButton Link13R transition w-15 text-center  ${selection == 'mobile' && 'selectedTabButton Link13M'} `} onClick={() => setSelection('mobile')}>
                                <SvgIcons.Mobilee color={selection == 'mobile' ? '#2960EC' : '#84889B'} /> Mobile
                            </div>
                        </div>

                        {selection == 'desktop' ?

                            <>
                                <div className="landingPageBox mt_20 mb_20 d-flex  h-fit  position-relative" style={{ background: formik.values.backgroundColor, borderRadius: '10px', overflow: 'hidden' , width : '48.125vw' }}>
                                    {formik.values.cover && <div style={{ width: '50%' }}><img className="object-fit-cover" src={(typeof formik.values.cover != "string") ? URL.createObjectURL(formik.values.cover) : formik.values.cover} width="100%" height="100%" /></div>}
                                    <div className="landingPageBottomBox" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: `${formik.values.cover ? '50%' : '90%'}`}}>
                                        <h1 className={`Heading12S ${formik.values.cover ? 'text-left' : 'text-center'}`} style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, fontSize: '16px' }}>{formik.values.heading}</h1>
                                        <h1 className={`headingH40M  ${formik.values.cover ? 'text-left' : 'text-center'}`} style={{ color: formik.values.primaryColor, fontSize: '40px', fontWeight: '700' }}>{formik.values.mainheading}</h1>
                                        <h3 className={`Body14R  ${formik.values.cover ? 'text-left' : 'text-center'}`} style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral70 : ColorSchemeCode.neutral40 }}>{formik.values.description}</h3>

                                        <div className="mt_24">
                                            <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.firstName} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                            <div
                                                className='w-100 Body16R borderRadius-6 bg-color-inherit outline-none'
                                                style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}
                                            >
                                                {formik.values.firstName}
                                            </div>
                                        </div>
                                        <div className="mt_16">
                                            <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.lastName} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                            <div
                                                className='w-100 Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                            >
                                                {formik.values.lastName}
                                            </div>
                                        </div>
                                        <div className="mt_16">
                                            <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.email} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                            <div
                                                className='w-100  Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                            >{formik.values.email}
                                            </div>
                                        </div>
                                        {/* <div className="mt_16">
                                            <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.dateOfBirth} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                            <div
                                                className='w-100  Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                            >{formik.values.dateOfBirth}
                                            </div>
                                        </div> */}
                                        <div className="mt_16 d-flex gap-12">
                                            <div className="w-30">
                                                <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.code} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                                <div
                                                    className='Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                    style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                                >{brand.dialingCode}
                                                </div>
                                            </div>
                                            <div className="w-70">
                                                <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.phone} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                                <div
                                                    className='Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                    style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}
                                                >{formik.values.phone}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="mt_16">
                                            <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.referral} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                            <div
                                                className='w-100  Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                            >{formik.values.referral}
                                            </div>
                                        </div> */}
                                        <div className="mt_40">
                                            <div className="submitBtnLandingPage Body16M borderRadius-6 text-center" style={{ background: formik.values.primaryColor, padding: '16px 24px', color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>
                                                Submit
                                            </div>
                                        </div>
                                        <div className='mt_7'>
                                            <p className='fs-14 color-D6DAE9' style={{ color: '#D6DAE9', fontWeight: 400, textAlign: 'center' }}>By clicking submit, you agree to our <p style={{ color: '#B2831E', cursor: 'pointer' }}>Terms & Conditions</p></p>
                                        </div>
                                    </div>
                                </div>

                            </>


                            :



                            <div className=" mt_20 mb_20 h-fit position-relative" style={{ background: formik.values.backgroundColor, width: '28.125vw', margin: '0 auto', borderRadius: '10px' }}>
                                {formik.values.cover && <img className="object-fit-cover" src={(typeof formik.values.cover != "string") ? URL.createObjectURL(formik.values.cover) : formik.values.cover} style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} width="100%" height="180px" />}
                                <div className="landingPageBottomBox" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                                    <h1 className="Heading12S text-center" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, fontSize: '16px' }}>{formik.values.heading}</h1>
                                    <h1 className="headingH40M text-center" style={{ color: formik.values.primaryColor, fontSize: '40px', fontWeight: '700' }}>{formik.values.mainheading}</h1>
                                    <h3 className="Body14R text-center" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral70 : ColorSchemeCode.neutral40 }}>{formik.values.description}</h3>

                                    <div className="mt_24">
                                        <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.firstName} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                        <div
                                            className='w-100 Body16R borderRadius-6 bg-color-inherit outline-none'
                                            style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}
                                        >
                                            {formik.values.firstName}
                                        </div>
                                    </div>
                                    <div className="mt_16">
                                        <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.lastName} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                        <div
                                            className='w-100 Body16R borderRadius-6 bg-color-inherit  outline-none'
                                            style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                        >
                                            {formik.values.lastName}
                                        </div>
                                    </div>
                                    <div className="mt_16">
                                        <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.email} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                        <div
                                            className='w-100  Body16R borderRadius-6 bg-color-inherit  outline-none'
                                            style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                        >{formik.values.email}
                                        </div>
                                    </div>
                                   
                                    <div className="mt_16 d-flex gap-12">
                                        <div className="w-30">
                                            <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.code} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                            <div
                                                className='Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}

                                            >{brand.dialingCode}
                                            </div>
                                        </div>
                                        <div className="w-70">
                                            <p className="Link14M mb_8" style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>{formik.values.phone} <span style={{ color: formik.values.primaryColor }}> *</span></p>
                                            <div
                                                className='Body16R borderRadius-6 bg-color-inherit  outline-none'
                                                style={{ color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0, padding: '8px 16px', border: `1px solid ${formik.values.theme == 'light' ? ColorSchemeCode.neutral20 : ColorSchemeCode.neutral70}` }}
                                            >{formik.values.phone}
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="mt_40">
                                        <div className="submitBtnLandingPage Body16M borderRadius-6 text-center" style={{ background: formik.values.primaryColor, padding: '16px 24px', color: formik.values.theme == 'light' ? ColorSchemeCode.neutral100 : ColorSchemeCode.neutral0 }}>
                                            Submit
                                        </div>
                                    </div>
                                    <div className='mt_7'>
                                        <p className='fs-14 color-D6DAE9' style={{ color: '#D6DAE9', fontWeight: 400, textAlign: 'center' }}>By clicking submit, you agree to our <p style={{ color: '#B2831E', cursor: 'pointer' }}>Terms & Conditions</p></p>
                                    </div>
                                </div>
                            </div>}


                    </div>
                </div>
                : <Loader height="32px" width="32px" />}
        </div>
    )
}

const Leftpane = ({ formik, flow, growthtool }) => {

    const brand = useContext(BrandContext);
    const agency = useContext(AgencyContext);
    const [selectedSection, setSelectedSection] = useState('styling')
    const [tooltipText, setTooltipText] = useState('copy')
    const [show, setShow] = useState({
        primaryColorPallette: false,
        backgroundColorPallette: false,

    })
    const [content, setContent] = useState()


    useEffect(() => {
        if (growthtool) {
            const getContent = {
                [GrowthTools.GrowhttoolTypes.WIDGET]: () => `<script src=${growthtool.widget.widgetUrl} ></script>`,
                [GrowthTools.GrowhttoolTypes.LANDING_PAGE]: () => (window.location.host.includes('localhost') ? 'http://' : 'https://') + `${(window.location.host === 'api.walletly.ai' || window.location.host.includes(agency.domain)) ? 'catchly.io' : window.location.host}/page/${growthtool._id}`,
                // [GrowthTools.GrowhttoolTypes.LANDING_PAGE]: () => (window.location.host.includes('localhost') ? 'http://' : 'https://') + `${(window.location.host === 'api.walletly.ai' || window.location.host.includes(agency.domain)) ? 'yonga.app' : window.location.host}/page/${growthtool._id}`,
                [GrowthTools.GrowhttoolTypes.POS]: () => (window.location.host.includes('localhost') ? 'http://' : 'https://') + `${window.location.host}/page/${growthtool._id}`,
                [GrowthTools.GrowhttoolTypes.POPUP]: () => (window.location.host.includes('localhost') ? 'http://' : 'https://') + `${window.location.host}/page/${growthtool._id}`
            }
            let url = getContent[growthtool.growthtoolType]();
            // console.log('url is ', url);
            setContent(url);
        }
    }, [growthtool])

    const handleCopy = () => {
        const element = document.getElementById('content')
        const r = document.createRange()
        r.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(r)
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        ReactTooltip.show(document.getElementById('tooltip'))
        setTooltipText('copied')
    }

    const hiddenFileInput = React.useRef(null);
    const hiddenFileInputCover = React.useRef(null);


    const handleClick = (event, key) => {
        if (key == 'cover') {
            hiddenFileInputCover.current.click();
        }
        else {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (event, key) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            if (key == 'cover') formik.setValues({ ...formik.values, cover: fileUploaded })
            else formik.setValues({ ...formik.values, background: fileUploaded })
        }
    };

    return (
        <div className="leftSectionWidget">
            <div className='d-flex cp pt_12 pb_12 border-bottom'>
                <div className={`tabButton Link13R transition w-50 text-center ${selectedSection == 'styling' && 'selectedTabButton Link13M'}`} onClick={() => setSelectedSection('styling')}>
                    Styling
                </div>
                <div className={`tabButton Link13R transition w-50 text-center  ${selectedSection == 'setting' && 'selectedTabButton Link13M'} `} onClick={() => setSelectedSection('setting')}>
                    Setting
                </div>
            </div>
            <div className="tabBody">
                {
                    selectedSection == 'setting' ?
                        <div id='insideComponent'>
                            {/* Script Section */}
                            <div className="border-bottom pt_8 pb_8">
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Url
                                </div>
                                <div id="CopyText">
                                    <div className="w-100">
                                        <div onClick={handleCopy} id="tooltip" className="cp w-100" data-for={'tooltipCopy'} data-tip={tooltipText} >
                                            <input
                                                onClick={handleCopy}
                                                id="content"
                                                className='panelDropdown Link11R w-100 cp transition'
                                                value={content}
                                            />
                                            {console.log(' conetnet ', content)}
                                        </div>
                                    </div>
                                    {<ReactTooltip
                                        backgroundColor={ColorSchemeCode.GeneralBlack}
                                        className="opacity-8 p_4 borderRadius-6"
                                        id="tooltipCopy"
                                        getContent={() => tooltipText} />
                                    }
                                </div>

                            </div>

                            <div className="border-bottom pt_8 pb_8 ">
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Connect Flow
                                </div>

                                <div className='d-flex align-items-center gap-12'>
                                    <div className="Link11R color-neutral70 w-30">
                                        Flow
                                    </div>
                                    <select
                                        className={`panelDropdown panelSelect Link11R w-70 transition ${flow.length == 0 && 'disabled'}`}
                                        value={formik.values.flow}
                                        placeholder="Flow"
                                        onChange={(event) => formik.setValues({ ...formik.values, flow: event.target.value })}
                                    >
                                        {flow && flow.length > 0 && flow.map((value) => (
                                            <option value={value._id}>{value.name}</option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                            {/* Configuration Section */}
                            <div className="border-bottom pt_8 pb_8">
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Configuration
                                </div>

                                <div className="d-flex space-between pt_8 pb_8">
                                    <div className="Link11R color-neutral70">Hide Points</div>
                                    <CustomSwitch
                                        checked={formik.values.points}
                                        onChange={(e) => formik.setValues({ ...formik.values, points: e.target.checked })}
                                    />
                                </div>

                                <div className="d-flex space-between pt_8 pb_8">
                                    <div className="LinkL11R color-neutral70">Claim points</div>
                                    <CustomSwitch
                                        checked={formik.values.disableClaimPoint == "true" ? true : false}
                                        onChange={(e) => formik.setValues({ ...formik.values, disableClaimPoint: e.target.checked ? "true" : "false" })}
                                    />
                                </div>

                                <div className="d-flex space-between pt_8 pb_8">
                                    <div className="LinkL11R color-neutral70">Show apple/google pass</div>
                                    <CustomSwitch
                                        checked={formik.values.showPass == "true" ? true : false}
                                        onChange={(e) => formik.setValues({ ...formik.values, showPass: e.target.checked ? "true" : "false" })}
                                    />
                                </div>

                                <div className="d-flex space-between pt_8 pb_8">
                                    <div className="LinkL11R color-neutral70">Redirect Url</div>
                                    <CustomSwitch
                                        checked={formik.values.redirectUrlCheck}
                                        onChange={(e) => formik.setValues({ ...formik.values, redirectUrlCheck: e.target.checked ? true : false })}
                                    />
                                </div>
                                {formik.values.redirectUrlCheck && <input
                                    className="border-none outline-none w-100 pl_8"
                                    placeholder='Enter Url Link'
                                    name='redirectUrl'
                                    onChange={formik.handleChange}
                                    value={formik.values.redirectUrl}
                                />}

                            </div>
                        </div>

                        :
                        <div className="pt_8 pb_8 insideComponent">
                            <div className="border-bottom">
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Theme
                                </div>
                                <div className='d-flex gap-12 align-items-center'>
                                    <div className="Link11R panelLabel">Theme</div>
                                    <select
                                        className="panelDropdown panelSelect Link11R w-70 transition"
                                        value={formik.values.theme}
                                        name="theme"
                                        onChange={formik.handleChange}
                                    >
                                        <option value={'light'} className="color-neutral100 capitalize">Light</option>
                                        <option value={'dark'} className="color-neutral100 capitalize">Dark</option>
                                    </select>
                                </div>

                                {/* Primary Background */}
                                <div className='d-flex gap-12 align-items-center'>
                                    <div className="Link11R panelLabel">Primary</div>
                                    <ClickOutside className="panelDropdown panelSelect Link11R w-70 transition " onClick={() => setShow({ ...show, primaryColorPallette: false })}>
                                        <div className='d-flex align-items-center cp position-relative' onClick={() => setShow({ ...show, primaryColorPallette: !show.primaryColorPallette })}>
                                            <div className="colorIcon" style={{ background: formik.values.primaryColor }}></div>
                                            <div className="color-neutral100 Link11R ml_8 upperCase ">{formik.values.primaryColor}</div>
                                            {show.primaryColorPallette &&
                                                <ChromePicker
                                                    className="pallette position-absolute z-index-1"
                                                    color={formik.values.primaryColor}
                                                    onChange={(e) => formik.setValues({ ...formik.values, primaryColor: e.hex })}
                                                />
                                            }
                                        </div>
                                    </ClickOutside>
                                </div>

                                <div className='d-flex gap-12 align-items-center mb_16'>
                                    <div className="Link11R panelLabel">Background</div>
                                    <ClickOutside className="panelDropdown panelSelect Link11R w-70 transition " onClick={() => setShow({ ...show, backgroundColorPallette: false })}>
                                        <div className='d-flex align-items-center cp position-relative' onClick={() => setShow({ ...show, backgroundColorPallette: !show.backgroundColorPallette })}>
                                            <div className="colorIcon" style={{ background: formik.values.backgroundColor }}></div>
                                            <div className="color-neutral100 Link11R ml_8 upperCase ">{formik.values.backgroundColor}</div>
                                            {show.backgroundColorPallette &&
                                                <ChromePicker
                                                    className="pallette position-absolute z-index-1"
                                                    color={formik.values.backgroundColor}
                                                    onChange={(e) => formik.setValues({ ...formik.values, backgroundColor: e.hex })}
                                                />
                                            }
                                        </div>
                                    </ClickOutside>
                                </div>

                            </div>

                            <div className="border-bottom">

                                {formik.values.background && <div className='mt_16 border-bottom d-flex space-between align-items-center pb_16 cp' >
                                    <img class="objec-fit-contain" src={(typeof formik.values.background != "string") ? URL.createObjectURL(formik.values.background) : formik.values.background} height="32px" width="32px" alt="" />
                                    <div className='mr_12' onClick={() => { formik.setValues({ ...formik.values, background: '' }); hiddenFileInput.current.value = "" }}>
                                        <SvgIcons.CrossIcon />
                                    </div>
                                </div>}
                                <div className='mt_16 d-flex space-between align-items-center pb_16 cp' >
                                    <div className="Link12M color-neutral100 ">
                                        Cover Image
                                    </div>
                                    <div className='mr_8' onClick={(e) => handleClick(e, 'cover')}>
                                        <SvgIcons.Add />
                                    </div>
                                    <input
                                        type="file"
                                        className="d-none"
                                        accept="image/*"
                                        ref={hiddenFileInputCover}
                                        onChange={(e) => handleChange(e, 'cover')}
                                    />
                                </div>
                                {formik.values.cover && <div className='mt_16 border-bottom d-flex space-between align-items-center pb_16 cp' >
                                    <img class="objec-fit-contain" src={(typeof formik.values.cover != "string") ? URL.createObjectURL(formik.values.cover) : formik.values.cover} height="32px" width="32px" alt="" />
                                    <div className='mr_12' onClick={() => { formik.setValues({ ...formik.values, cover: '' }); hiddenFileInputCover.current.value = "" }}>
                                        <SvgIcons.CrossIcon />
                                    </div>
                                </div>}

                            </div>

                            <div className='mt_16 border-bottom'>
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Text
                                </div>

                                <div className='d-flex gap-12 align-items-center mb_16'>
                                    <div className="Link11R panelLabel">Heading</div>
                                    <input
                                        name="heading"
                                        onChange={formik.handleChange}
                                        value={formik.values.heading}
                                        className="outline-none border-none underline Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>
                                <div className='d-flex gap-12 align-items-center mb_16'>
                                    <div className="Link11R panelLabel"> Main Heading</div>
                                    <input
                                        name="mainheading"
                                        onChange={formik.handleChange}
                                        value={formik.values.mainheading}
                                        className="outline-none border-none underline Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>

                                <div className='d-flex gap-12 mb_16'>
                                    <div className="Link11R panelLabel">Description</div>
                                    <textarea
                                        name="description"
                                        onChange={formik.handleChange}
                                        rows="3"
                                        value={formik.values.description}
                                        className="descriptionBox outline-none underline border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>
                            </div>



                        </div>
                }
            </div>
        </div>
    )
}
