// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import { ColorSchemeCode } from '../enums/ColorScheme';

// const CustomFormControlLabel = makeStyles({
//   root: {
//       margin: 0,
//       backgroundColor : 'white !important',
//   },
//   label: {
//     fontSize   : props=> props.fontSize || '10px',                       //Body14R
//     fontWeight : 400,                          //Body14R
//     lineHeight : '16px',                       //Body14R
//     fontFamily : 'Graphik',                    //Body14R
//     color      : props=> props.Color || ColorSchemeCode.neutral90
//   },
// })

// export default function CustomCheckBox({label, value, onChange, name, className, ...props}) {
//     const classes = CustomFormControlLabel(props)

//     return (
//       <div id="CustomCheckBox">
//         <FormControlLabel 
//             className = {className}
//             classes   = {classes}
//             label     = {label}
//             control   = {<Checkbox style={{backgroundColor : 'white !important'}} checked={value} onChange={onChange} name={name || label} />}
//         />
//       </div>
//     );
// }
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CustomCheckBox({label, value, onChange, name, className, ...props}) {
      // const classes = CustomFormControlLabel(props)
  
  return (
    <FormGroup>
      <FormControlLabel  
              className = {`${className} mr_0 ml_0`}
              // classes   = {classes}
              label     = {label}
              control   = {<Checkbox style={{backgroundColor : 'white !important'}} checked={value} onChange={onChange} name={name || label}/> }
            />
    </FormGroup>
  );
}