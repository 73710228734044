import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/analytics'

const GetBrandAnalytics = async ({query}) => await axios.get(env.API_URL+service+'/brand?'+utils.getQueryString(query))
const GetActiveMembers = async ({query}) => await axios.get(env.API_URL+service+'/getActiveMembers?'+utils.getQueryString(query))
const GetAnalytics = async ({query}) => await axios.get(env.API_URL+service+'/?'+utils.getQueryString(query))

const AnalyticService = {
    GetBrandAnalytics : Handlers.Services(GetBrandAnalytics),
    GetActiveMembers  : Handlers.Services(GetActiveMembers),
    GetAnalytics      : Handlers.Services(GetAnalytics),
}

export default AnalyticService
