import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  isNode,
  updateEdge
} from 'react-flow-renderer';
import Sidebar from './Sidebar';
import './dnd.css';
import CustomTextField from '../../../components/CustomTextField';
import { SvgIcons } from '../../../icons';
import { showToaster } from '../../../utils/utils';
import CustomSelect from '../../../components/CustomSelect';
import { utils } from '../../../utils';
import dagre from 'dagre';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { LayoutContext } from '../../../context/Layout.context';
import localForage from 'localforage'
import CustomButton from '../../../components/CustomButton';
import FitContentInput from '../../../components/FitContentInput';
import { AgencyContext } from '../../../context/Agency.context';
import { BrandContext } from '../../../context/Brand.context';
import { FlowService } from '../../../services';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteModal from '../../../components/DeleteModal';
import CustomsModal from '../../../components/CustomModal';
import { CircularProgress, IconButton } from '@mui/material';
import ConnectionLine from './ConnectionLine';
import { RedemptionType } from '../../../enums/enums';
import { getRedemptionType } from '../../../utils/Options';
import ReactTooltip from 'react-tooltip';
import { DateRange } from 'react-date-range';
import CustomSwitch from '../../../components/CustomSwitch';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 400;
const nodeHeight = 200;

const getLayoutedElements = (elements, direction = 'TB', center) => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      if(center === true)
      {
        el.position = {
          x: 600,
          y: 100,
        };
      }
      else{
        // console.log('elements-------',el);
        if(el.data.customType === 'action'){
          el.position = {
            x: nodeWithPosition.x - 195 / 2 + Math.random() / 1000,
            y: nodeWithPosition.y - 80 / 2,
          };
        }
        else if(el.data.customType === 'deal'){
          el.position = {
            x: nodeWithPosition.x - 400 / 2 + Math.random() / 1000,
            y: nodeWithPosition.y - 300 / 2,
          };
        }
        else{
          el.position = {
            x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
            y: nodeWithPosition.y - nodeHeight / 2,
          };
        }
      }
    }

    return el;
  });
};

const initialElements = [
    {
      id         : '1',
      type       : 'input',
      data     : { 
        customType : 'checkIn',
        label      : 
        <div>
        <div className="d-flex m_12 align-items-center">
          <div><SvgIcons.CheckInIcon/></div>
          <div className="Heading16M color-neutral100 ml_16">Check In</div>
        </div>
        <div className="bar"></div>
        <div className="d-flex mt_12 mr_16 mb_12 ml_16 pt_12 pr_16 pb_12 pl_16 align-items-center borderRadius-8" style={{backgroundColor: '#E2FAEC'}}>
          <div><SvgIcons.QrIcon/></div>
          <div className="Caption12R ml_12">When customers check-ins through <span className="Caption12M underline">Qr scan.</span></div>
        </div>
        </div>,
        value: 'checkIn' },
      position : { x: 600, y: 100 },
      style:{
        height       : 'max-content',
        width        : 'max-content',
        border       : 'none',
        width        : '360px',
        boxShadow    : '0px 8px 20px rgba(4, 24, 62, 0.08)',
        borderRadius : '8px',
        padding      : '0'
      }
    },
    
];

// const layoutedElements = getLayoutedElements(initialElements,'TB',true);


let id = 0;
const getId = () => `node_${utils.getRandomString(3)}_${id++}`;
function FlowTest() {
  const [loader, setloader]             = useState(false)
  const [loaderButton, setloaderButton] = useState(false)
  const [toggle, setToggle]             = useState(true);
  const [nameModal, setNameModal]       = useState(false);
  const history                         = useHistory();
  // const [elements, setElements] = useState([]);
  const [deal, setDeal] = useState([])
  
  const flowData = history.location.state
  // const [elements, setElements] = useState(flowData ? parse(flowData).arr : initialElements);
  const [elements, setElements] = useState();

  useEffect(() => {
    setTimeout(() => {
      if(flowData && flowData.edit){
        if(document.getElementById("collapse")) document.getElementById("collapse").style.width   = "41px";
        if(document.getElementById("mySidebar")) document.getElementById("mySidebar").style.width = "0";
        if(document.getElementsByClassName('react-flow__controls') && document.getElementsByClassName('react-flow__controls')[0]) {
          document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
          document.getElementById("flowControl").style.left    = "80px";
        }
      }
    }, 100);
  }, [])

  useEffect(() => {
    let flowDataArr = []
    if(flowData && flowData.edit){
      setToggle(false)
      // if(document.getElementById("mySidebar")) document.getElementById("mySidebar").style.width = "0";
      // if(document.getElementsByClassName('react-flow__controls') && document.getElementsByClassName('react-flow__controls')[0]) {
      //   document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
      //   document.getElementById("flowControl").style.left    = "80px";
      // }
      // document.getElementsByClassName('react-flow__controls')[0].style.left = "80px !important"
      setFlowName({...flowName, name: flowData.name})
      flowData.flow.map((value, index)=>{
          // // console.log('value',value);
          if(value.data.customType === 'action')
          {
            if(value.data.value === 'no'){
              flowDataArr.push({
                  ...value,
                  data: {...value.data, 
                  label : <div className = "color-white middle h-inherit Heading16M">NO</div>
                  }
              })
            }
            if(value.data.value === 'yes'){
              flowDataArr.push({
                  ...value,
                  data: {...value.data, 
                  label : <div className = "middle color-white Heading16M h-inherit">YES</div>
                  }
              })
            }
          }
          if(value.data.customType === 'signUp')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.SignUpIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">Sign Up</div>
              </div>
              <div className="bar"></div>
              <div className="d-flex mt_12 mr_16 mb_12 ml_16 pt_12 pr_16 pb_12 pl_16 align-items-center borderRadius-8" style={{backgroundColor: '#E2FAEC'}}>
                  <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{value.data.value.platform||'None'+" ."}</span></div>
                  {/* <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{brand.brandRedemptionType === RedemptionType.ORDER ? 'Cloudwaitress' : value.data.value.platform||'Manychat'+" ."}</span></div> */}
              </div>
              </div>
              }
          })
          }
          if(value.data.customType === 'checkIn')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.CheckInIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">Check In</div>
              </div>
              <div className="bar"></div>
              <div className="d-flex mt_12 mr_16 mb_12 ml_16 pt_12 pr_16 pb_12 pl_16 align-items-center borderRadius-8" style={{backgroundColor: '#E2FAEC'}}>
                  <div><SvgIcons.QrIcon/></div>
                  <div className="Caption12R ml_12">When customers check-ins through <span className="Caption12M underline">Qr scan.</span></div>
              </div>
              </div>
              }
          })
          }
          if(value.data.customType === 'visit')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.VisitIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">On Visit</div>
              </div>
              <div className="bar"></div>
              <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visits for the <span className="Caption12M underline">{utils.getNumberWithOrdinal(value.data.value.visit)+" Time."}</span></div>
              </div>
              }
          })
          }
          if(value.data.customType === 'point')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.PointIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">Points</div>
              </div>
              <div className="bar"></div>
              <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer reached <span className="Caption12M underline">{(value.data.value.point)+" Points."}</span></div>
              </div>
              }
          })
          }
          if(value.data.customType === 'deal')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.GiveDealIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">Give Deal</div>
              </div>
              <div className="bar"></div>
              <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_10 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#E8EFF7'}}>Give <span className="Caption12M underline">{value.data.value.deal} </span>to customer.</div>
              {value.data.value.image&&<img style={{pointerEvents: "none"}} className="borderRadius-4 pl_12 pr_12 pb_16" width="100%" height="auto" src={value.data.value.image} />}
              </div>
              }
          })
          }
          if(value.data.customType === 'event')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.EventIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">On Event</div>
              </div>
              <div className="bar"></div>
              <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visit on <span className="Caption12M underline">{value.data.value.event}</span> ({value.data.value.date + " - " + value.data.value.enddate})</div>
              </div>
              }
          })
          }
          if(value.data.customType === 'onetime')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.OneTimeIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">One Time</div>
              </div>
              <div className="bar"></div>
              <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Redeem this deal only one time. </div>
              </div>
              }
          })
          }
          if(value.data.customType === 'expire')
          {
          flowDataArr.push({
              ...value,
              data: {...value.data, 
              label      : 
              <div>
              <div className="d-flex m_12 align-items-center">
                  <div><SvgIcons.ExpirationIcon/></div>
                  <div className="Heading16M color-neutral100 ml_16">After Expiration</div>
              </div>
              <div className="bar"></div>
              <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>When deal expires after expiration days set on connected deal. </div>
              </div>
              }
          })
          }
          if(value.type === 'smoothstep') flowDataArr.push(value)
      })
      setElements(flowDataArr)
      setNodeName({...nodeName}) //, promotion: flowData.triggerOption || 'Visit & Spending'
    }
    else {
      setElements(initialElements)
      setNameModal(true)
      setNodeName({...nodeName}) //, promotion: 'Visit & Spending'
    }
  }, [])
  
  const onLayout = useCallback(
    (direction) => {
      // console.log('elementselementselements',elements);
      const layoutedElements = getLayoutedElements(elements, direction, false);
      setElements(layoutedElements);
    },
    [elements]
  );


  const [state, setstate] = useState({
        type     : '',
        data     : '',
        selected : '',
        deal     : '',
        point    : 0,
        visit    : 0,
        event    : '',
        onetime  : 1,
        expire  : '',
    })
    
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const onConnect = (params) => {
    // console.log('params',params);
    
    setElements((els) => addEdge({...params, arrowHeadType: 'arrowclosed',type: 'smoothstep',style: { stroke: ColorSchemeCode.themeColor, strokeWidth: 2 }, animated: true}, els));
  }
  const onElementsRemove = (elementsToRemove) =>{
    if(elementsToRemove[0].data.customType === "signUp" || elementsToRemove[0].data.customType === "checkIn"){
      showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot delete trigger.' , severity: 'error'})
    }
    else{
      setsidebar(false)
      setElements((els) => removeElements(elementsToRemove, els))
    }
  }

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onEdgeUpdate = (oldEdge, newConnection) => setElements((els) => updateEdge(oldEdge, newConnection, els));

  const onDrop = (event) => {
    let check = true
    event.preventDefault();
    if(elements&&elements.length>0){
      for (const value of elements) {
        if(value.data&&value.data.customType&&(value.data.customType==="onetime"||value.data.customType==="expire")) check = false
      }
    }

    if(state.data === 'checkIn' || state.data === 'signUp'){
      showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot add another Check In trigger.' , severity: 'error'})
    }
    // else if (nodeName.promotion === 'Event Base' && (state.data === 'point' || state.data === 'onetime' || state.data === 'visit' || state.data === 'action')){ // Visit & Spending
    //   showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot add this loyality trigger in promotions.' , severity: 'error'})
    // }
    // else if ((nodeName.promotion === 'Visit & Spending'||nodeName.promotion === ''||!nodeName.promotion) && state.data === 'event'){ // Visit & Spending
    //   showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot add this promotions trigger in loyality.' , severity: 'error'})
    // }
    else if((state.data === 'onetime' || state.data === 'event' || state.data === 'visit' || state.data === 'action') && cloudwaitress==='cloudwaitress'){
    // else if((state.data === 'event' || state.data === 'visit' || state.data === 'action') && brand.brandRedemptionType === RedemptionType.ORDER){
      showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot add this trigger.' , severity: 'error'})
    }
    // else if(state.data === 'onetime' && !check){
    //   showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot add another One Time trigger.' , severity: 'error'})
    // }
    else{
      if(state.data === 'action'){
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type            = event.dataTransfer.getData('application/reactflow');
        const position        = reactFlowInstance.project({ x: event.clientX - reactFlowBounds.left, y: event.clientY - reactFlowBounds.top, });
        const newNode = {
          id: getId(),
          // id: '2',
          type,
          position,
          style      : { backgroundColor: state.data === 'action' && '#23C96F', height: state.data === 'action' ? '44px' : 'max-content', border: 'none', width: state.data === 'action' ? '156px' : '360px', boxShadow: '0px 8px 20px rgba(4, 24, 62, 0.08)', borderRadius: '8px', padding: '0' },
          data       : { 
            customType : state.type,
            label      : 
                <div className="middle color-white Heading16M h-inherit">YES</div>
              ,
              value: 'yes'
          },
        };

        // console.log('newNode',newNode);

        
        // const reactFlowBounds1 = reactFlowWrapper.current.getBoundingClientRect();
        // const type1            = event.dataTransfer.getData('application/reactflow');
        // const position1        = reactFlowInstance.project({ x: event.clientX - reactFlowBounds.left + 200, y: event.clientY - reactFlowBounds.top, });

        
        const newNode1 = {
          id: getId()+'1',
          // id: '2',
          type,
          position: {x: position.x + 200, y: position.y},
          style      : { backgroundColor: state.data === 'action' && 'color(danger30)', height: state.data === 'action' ? '44px' : 'max-content', border: 'none', width: state.data === 'action' ? '156px' : '360px', boxShadow: '0px 8px 20px rgba(4, 24, 62, 0.08)', borderRadius: '8px', padding: '0' },
          data       : { 
            customType : state.type,
            label      : 
            // <div className="position-relative middle h-inherit">
              <div className="color-white middle h-inherit Heading16M">NO</div>
            //   <IconButton className="position-absolute" style={{top: '-30px', right: '-10px'}} onClick={()=>console.log(selected)}>
            //       <SvgIcons.DeleteIcon color={ColorSchemeCode.Paragraph}/>
            //   </IconButton>
            // </div>
              ,
              value: 'no'
          },
        };
        // newNode1.position.x = newNode1.position.x + 300
        // newNode1.position.y = newNode1.position.y + 300
        setElements((es) => es.concat(newNode, newNode1));
      }
      else{
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type            = event.dataTransfer.getData('application/reactflow');
        const position        = reactFlowInstance.project({ x: event.clientX - reactFlowBounds.left, y: event.clientY - reactFlowBounds.top, });
  
        const newNode = {
          id: getId(),
          // id: '2',
          type,
          position,
          style      : { backgroundColor: state.data === 'action' && '#23C96F', height: state.data === 'action' ? '44px' : 'max-content', border: 'none', width: state.data === 'action' ? '156px' : '360px', boxShadow: '0px 8px 20px rgba(4, 24, 62, 0.08)', borderRadius: '8px', padding: '0' },
          data       : { 
            customType : state.type,
            label      : 
                  state.data === 'deal' ? 
                  // position-relative h-inherit
                  <div className="">
                  <div className="d-flex m_12 align-items-center">
                    <div><SvgIcons.GiveDealIcon/></div>
                    <div className="Heading16M color-neutral100 ml_16">Give Deal</div>
                  </div>
                  <div className="bar"></div>
                  <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_10 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#E8EFF7'}}>Give <span className="Caption12M underline">___________________ </span>to customer.</div>
                  {/* {selectedDeal.image&&<img style={{pointerEvents: "none"}} className="borderRadius-4 pl_12 pr_12 pb_16" width="100%" height="auto" src={selectedDeal.image} />} */}
                  {/* <IconButton className="position-absolute" style={{top: '-30px', right: '-10px'}} onClick={()=>console.log(selected)}>
                    <SvgIcons.DeleteIcon color={ColorSchemeCode.Paragraph}/>
                  </IconButton> */}
                  </div>
                  :
                  state.data === 'point' ? 
                    // position-relative h-inherit
                  <div className="">
                  <div className="d-flex m_12 align-items-center">
                    <div><SvgIcons.PointIcon/></div>
                    <div className="Heading16M color-neutral100 ml_16">Points</div>
                  </div>
                  <div className="bar"></div>
                  <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer reached <span className="Caption12M underline">{state.point+" Points."}</span></div>
                  {/* <IconButton className="position-absolute" style={{top: '-30px', right: '-10px'}} onClick={()=>console.log(selected)}>
                    <SvgIcons.DeleteIcon color={ColorSchemeCode.Paragraph}/>
                  </IconButton>
                  <IconButton className="position-absolute" style={{top: '-30px', right: '10px'}}>
                    <SvgIcons.CopyIcon color={ColorSchemeCode.Paragraph}/>
                  </IconButton> */}
                  </div>
                  :
                  state.data === 'visit' ? 
                  // position-relative middle h-inherit
                  <div className="">
                  <div className="d-flex m_12 align-items-center">
                    <div><SvgIcons.VisitIcon/></div>
                    <div className="Heading16M color-neutral100 ml_16">On Visit</div>
                  </div>
                  <div className="bar"></div>
                  <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visits for the <span className="Caption12M underline">{utils.getNumberWithOrdinal(state.visit)+" Time."}</span></div>
                  {/* <IconButton className="position-absolute" style={{top: '-30px', right: '-10px'}} onClick={()=>console.log(selected)}>
                    <SvgIcons.DeleteIcon color={ColorSchemeCode.Paragraph}/>
                  </IconButton> */}
                  </div>
                  : 
                  state.data === 'event' ? 
                  // position-relative middle h-inherit
                  <div className="">
                  <div className="d-flex m_12 align-items-center">
                    <div><SvgIcons.EventIcon/></div>
                    <div className="Heading16M color-neutral100 ml_16">On Event</div>
                  </div>
                  <div className="bar"></div>
                  <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visit on <span className="Caption12M underline">Event Name.</span> (--/--/----) - (--/--/----)</div>
                  {/* <IconButton className="position-absolute" style={{top: '-30px', right: '-10px'}} onClick={()=>console.log(selected)}>
                    <SvgIcons.DeleteIcon color={ColorSchemeCode.Paragraph}/>
                  </IconButton> */}
                  </div>
                  : 
                  state.data === 'onetime' ? 
                  // position-relative middle h-inherit
                  <div className="">
                  <div className="d-flex m_12 align-items-center">
                    <div><SvgIcons.OneTimeIcon/></div>
                    <div className="Heading16M color-neutral100 ml_16">One Time</div>
                  </div>
                  <div className="bar"></div>
                  <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Redeem this deal only one time.</div>
                  {/* <IconButton className="position-absolute" style={{top: '-30px', right: '-10px'}} onClick={()=>console.log(selected)}>
                    <SvgIcons.DeleteIcon color={ColorSchemeCode.Paragraph}/>
                  </IconButton> */}
                  </div>
                  : 
                  state.data === 'expire' ? 
                  // position-relative middle h-inherit
                  <div className="">
                  <div className="d-flex m_12 align-items-center">
                    <div><SvgIcons.ExpirationIcon/></div>
                    <div className="Heading16M color-neutral100 ml_16">After Expiration</div>
                  </div>
                  <div className="bar"></div>
                  <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>When deal expires after expiration days set on connected deal.</div>
                  {/* <IconButton className="position-absolute" style={{top: '-30px', right: '-10px'}} onClick={()=>console.log(selected)}>
                    <SvgIcons.DeleteIcon color={ColorSchemeCode.Paragraph}/>
                  </IconButton> */}
                  </div>
                  : 
                  ''
              ,
              value: state.data === 'deal' ? state.deal : state.data === 'point' ? state.point : state.data === 'visit' ? state.visit : state.data === 'event' ? state.event : state.data === 'onetime' ? state.onetime : state.data === 'expire' ? state.expire : ''
          },
        };
        setElements((es) => es.concat(newNode));
      }
    }
  };

  const [nodeName, setNodeName] = useState('');
  const [selected, setSelected] = useState();
  const [selectedType, setSelectedType] = useState();
  const [cloudwaitress, setcloudwaitress] = useState();
  const [selectedDeal, setSelectedDeal] = useState({
    image : '',
    name  : ''
  });

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selected) {
          // it's important that you create a new object here in order to notify react flow about the change
          el.data = {
            ...el.data,
            value: nodeName,
          };
        }

        return el;
      })
    );
  }, [nodeName, setElements]);

  const [deleteLoader, setDeleteLoader] = useState(false)
  const [sidebar, setsidebar] = useState(false)
  const [editStatus, setEditStatus] = useState(false)
  const [flowName, setFlowName] = useState({
    toggle: false,
    name: 'Flow Name'
  })
  const layout = useContext(LayoutContext)
  const agency = useContext(AgencyContext)
  const brand = useContext(BrandContext)

  useEffect(()=>{
    if(flowName.name == "" && !editStatus) { 
        showToaster({position: 'bottom-right', title: 'Title Error', message:  'Title cannot be empty' , severity: 'error'})
        setFlowName({...flowName, name : 'Flow Name'})
    }
  },[editStatus])

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    layout.setLayout({
      backTitles      : ['Loyalty Suite', 'Flows'],
      title           : 
      // <div className="d-flex">
        // <div className="cp mr_8" onClick={()=> {
        //   if(toggle){
        //     document.getElementById        ("mySidebar").style.width      = "0";
        //     document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
        //     document.getElementById        ("flowControl").style.left     = "80px";
        //     // document.getElementsByClassName('react-flow__controls')[0].style.left = "80px !important"
        //   }
        //   else{
        //     document.getElementById        ("mySidebar").style.width      = "300px";
        //     document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
        //     document.getElementById        ("flowControl").style.left     = "380px";
        //     // document.getElementsByClassName('react-flow__controls')[0].style.left = "380px !important"
        //   }
        // setToggle(!toggle)
        // }}><SvgIcons.HeaderCollapseIcon/></div>
        <FitContentInput 
              // name      = "title"
              onClick       = {()=>setFlowName({...flowName,toggle: true})}
              placeholder   = "Flow Name"
              value         = {flowName.name}
              onChange      = {(value)=>setFlowName({...flowName, name: value, toggle: true} )}
              icon          = {{color: ColorSchemeCode.neutral80, class: 'pt_3', width: '20px', height: '20px'}}
              setEditStatus = {setEditStatus}
              border
              />
              ,
      borderBottom    : true,
              // </div>
              // button: <></>
      button     : <div>
              {flowData && flowData.edit && <CustomButton 
                  btntext = {"Delete"}
                  varient = "warningSecondary"
                  onClick = {()=>setDeleteModal(true)}
                  
              />}
              <CustomButton 
                  btntext   = {"Cancel"}
                  varient   = "secondary"
                  className = "ml_8"
                  onClick   = {()=>{
                    const location = window.location.pathname.split('/')
                    location.pop()
                    history.push({pathname : location.join('/') 
                    // , state : {tab : 2}
                  })
                    // history.push({
                    //   pathname: location.join('/'),
                    //   state: {flowReload: true}
                    // })
                  }}
              />
              <CustomButton 
                  btntext   = {"Save changes"}
                  disabled  = {loaderButton}
                  icon      = {loaderButton && <CircularProgress size={20} color={'inherit'}/>}
                  onClick   = {async()=>{
                    setloaderButton(true)
                    let tempId = []
                    let tempObj = []
                    let relationArr = []
                    elements.map((value, index)=> {
                      if(value.type === "smoothstep"){
                        // // console.log('value',value);
                        relationArr.push({before: value.source, after:value.target})
                        if(!tempId.includes(value.source)) tempId.push(value.source)
                        if(!tempId.includes(value.target)) tempId.push(value.target)
                      }
                    })
                    elements.map((value, index)=> {
                      if(tempId.includes(value.id)) tempObj.push(value)
                    })
                    // console.log('=========');
                    // console.log(elements);
                    // console.log('=========');
                    
                    // let tempfirstdeal = []
                    // for (const el of elements) {
                    //   if(el.data&&el.data.customType&&el.data.customType==='deal'&&el.data.value.firstdeal&&el.data.value.firstdeal===true) tempfirstdeal.push(1)
                    // }
                    // if(tempfirstdeal.length>1) setloaderButton(false)
                    // if(tempfirstdeal.length>1) showToaster({position: 'bottom-right', title: 'Title Error', message:  'First Deal cannot be multiples' , severity: 'error'})
                    // if(tempfirstdeal.length>1) return

                    let triggerType = ''
                    let obj = []
                    const date = new Date()
                    tempObj.map((value, index)=> {
                      if(value.data.customType === 'checkIn' || value.data.customType === 'signUp') triggerType = value.data.customType
                      // if(value.data.customType !== 'deal' && value.data.customType !== 'checkIn' && value.data.customType !== 'signp'){
                
                        if(value.data.value.valid === 30) date.setDate(date.getDate()+30)
                        if(value.data.value.valid === 60) date.setDate(date.getDate()+60)
                        if(value.data.value.valid === 90) date.setDate(date.getDate()+90)

                        obj.push({
                          field   : value.data.customType,
                          operand : '==',
                          // value   : value.data.value,
                          value   : value.data.customType === 'checkIn' ?
                                      value.data.value.trigger : 
                                      value.data.customType === 'signUp' ?
                                      // brand.brandRedemptionType === RedemptionType.ORDER ? 'cloudwaitress' : value.data.value.platform : 
                                      cloudwaitress === 'cloudwaitress' ? 'cloudwaitress' : value.data.value.platform : 
                                      value.data.customType === 'visit' ? parseInt(value.data.value.visit) : value.data.customType === 'point' ? 
                                      parseInt(value.data.value.point) : 
                                      value.data.customType === 'deal' ? 
                                      {
                                        days            : value.data.value.days ? parseInt(value.data.value.days)                                        : 0,
                                        subtract        : value.data.value.subtract ? parseInt(value.data.value.subtract)                                : 0,
                                        valid           : (value.data.value.valid === undefined || value.data.value.valid === "unlimited") ? "unlimited" : Date.parse(date),
                                        deal            : value.data.value.deal,
                                        image           : value.data.value.image,
                                        code            : value.data.value.code&&value.data.value.code,
                                        discountedPrice : value.data.value.discountedPrice&&value.data.value.discountedPrice,
                                        totalPrice      : value.data.value.totalPrice&&value.data.value.totalPrice,
                                        percentOff      : value.data.value.percentOff&&value.data.value.percentOff,
                                        id              : value.data.value.id&&value.data.value.id,
                                        recurring       : value.data.value.recurring&&value.data.value.recurring,
                                        notification    : value.data.value.notification&&value.data.value.notification,
                                        dealeligible    : value.data.value.dealeligible&&value.data.value.dealeligible,
                                        dealredeem      : value.data.value.dealredeem&&value.data.value.dealredeem,
                                        dealexpire      : value.data.value.dealexpire&&value.data.value.dealexpire,
                                        // firstdeal       : value.data.value.firstdeal&&value.data.value.firstdeal,
                                      }
                                      :
                                      value.data.customType === 'event' ? 
                                      {
                                        date    : Date.parse(value.data.value.date),
                                        enddate : Date.parse(value.data.value.enddate),
                                        event   : value.data.value.event,
                                      } 
                                      :
                                      value.data.customType === 'action' ?
                                      value.data.value
                                      :
                                      value.data.customType === 'onetime' ?
                                      parseInt(value.data.value)
                                      :
                                      value.data.customType === 'expire' ?
                                      value.data.value
                                      : '' ,
                          id      : value.id
                          // before  : '',
                          // after   : ''
                          // deal    : tempObj[index+1].data.customType === 'deal' ? {dealLifeTime: tempObj[index+1].data.value.days, subtractPoints: tempObj[index+1].data.value.subtract, validDays: tempObj[index+1].data.value.valid, name: tempObj[index+1].data.value.deal} : {}
                        })
                      // }
                    })

                    let finalObj = []
                    
                    
                    obj.map((value, index)=>{
                      relationArr.map((element, idx)=> {
                        if(value.id === element.before){
                          if(value.after && value.after.length > 0) finalObj.push({...value, after: [...value.after, element.after]})
                          else finalObj.push({...value, after: [element.after]})
                        }
                        if(value.id === element.after){
                          if(value.before && value.before.length > 0) finalObj.push({...value, before: [...value.before, element.before]})
                          else finalObj.push({...value, before: [element.before]})
                        }
                      })
                    })
                    
                    
                    
                    finalObj.map((element, idx)=>{
                      if(!element.after) finalObj[idx].after = [];
                      if(!element.before) finalObj[idx].before = [];
                    })
                    
                    const arrayHashmap = finalObj.reduce((obj, item) => {
                      obj[item.id] ? obj[item.id].after.push(...item.after) : (obj[item.id] = { ...item });
                      obj[item.id] ? obj[item.id].before.push(...item.before) : (obj[item.id] = { ...item });
                      return obj;
                    }, {});
                    
                    const mergedArray = Object.values(arrayHashmap);

                    mergedArray.map((value, index)=>{
                      let x = value.before.filter((v,i) => value.before.indexOf(v) === i)
                      mergedArray[index].before = x
                    })
                    
                    // // console.log(mergedArray);

                    // finalObj.map((value, index)=>{
                    //   finalObj.map((element, idx)=>{
                    //     if(value.id === element.id && index !== idx && idx > index)
                    //     {
                    //       if(element.after === undefined) {
                    //         finalObjV2.push({...value,...element, after: value.after })
                    //       }
                    //       if(value.after === undefined){
                    //         finalObjV2.push({...value,...element, after: element.after })
                    //       }
                    //       if(value.after !== undefined && element.after === undefined){
                    //         finalObjV2.push({...value,...element, after: [value.after, element.after] })
                    //       }
                    //     }
                    //   })
                    // })

                    
                    // let finalObjV3 = []
                    
                    // finalObjV2.map((value, index)=>{
                    //   if(!value.after || !value.before) finalObjV3.push(value)
                    // })
                    
                    // const arrayHashmap = finalObjV2.reduce((obj, item) => {
                    //   obj[item.id] ? obj[item.id].after.push(...item.after) : (obj[item.id] = { ...item });
                    //   return obj;
                    // }, {});
                    
                    // const finalObjV4 = Object.values(arrayHashmap);

                    // let finalObjV5 = [...finalObjV3, ...finalObjV4]
                    

                    // finalObj.map((value, index)=>{
                    //   var __FOUND = finalObjV2.find(function(post, index) {
                    //     if(post.id == value.id)
                    //       return true;
                    //   });
                    //   if(__FOUND === undefined){
                    //     if(!finalObjV2.includes(value)) finalObjV2.push(value)
                    //   }
                    // })
                    
                    
                    

                    let payloadElements = []
                    elements.map((value, index)=> {
                      payloadElements.push({...value, data:{...value.data, label: ''}})
                    })
                    

                    let payload = {
                      name          : flowName.name,
                      flow          : payloadElements,
                      agencyId      : agency._id,
                      brandId       : brand._id,
                      triggerType   : triggerType,
                      // triggerOption : nodeName.promotion || 'Visit & Spending',
                      conditions    : mergedArray
                    }

                    let node1 = payloadElements.filter((element, idx)=>{
                      if(element.id === '1' && element.data.value.platform === 'app') return true
                    })
                    // console.log('flowData', flowData);
                    // // console.log('Nodename', nodeName);
                    if(triggerType==="checkIn"){
                      if(nodeName.redemptionType) payload.redemptionType = nodeName.redemptionType
                      else{
                        if(!flowData || (flowData && !flowData.edit)) payload.redemptionType = RedemptionType.SELF
                          else payload.redemptionType = flowData.redemptionType
                      }
                    }
                      // payload.redemptionType = (nodeName.redemptionType ? nodeName.redemptionType : (!flowData || !flowData.edit) ? RedemptionType.SELF : flowData.redemptionType)
                    // console.log('flowData ', flowData);
                      if(triggerType==="signUp" && node1.length > 0) payload.redemptionType = (nodeName.redemptionType ? nodeName.redemptionType : (!flowData?.edit) ? RedemptionType.SELF : flowData.redemptionType)
                    
                    // console.log('payload',payload);

                    if(flowData && flowData.edit){
                      payload._id = flowData._id
                      const {response, error} = await FlowService.Update({toaster: true, payload})
                      if(response){
                        let flow = await localForage.getItem('flow')
                        flow.map((element, idx)=> {
                            if(element._id === flowData._id) flow[idx] = response.data
                        })
                        localForage.setItem('flow', flow)
                        const location = window.location.pathname.split('/')
                        location.pop()
                        history.push({
                          pathname: location.join('/'),
                          state: {reload: true}
                        })
                      }
                    }
                    else{
                      // // console.log('2');
                      const {response, error} = await FlowService.Create({toaster: true, payload})
                      if(response){
                        let flow = await localForage.getItem('flow');
                        if(flow === undefined || flow === null ) flow = []
                        flow.push(response.data);
                        localForage.setItem('flow', flow);
                        
                        const location = window.location.pathname.split('/')
                        location.pop()
                        // history.push({
                        //   pathname: location.join('/'),
                        //   state: {reload: true}
                        // })
                        history.push({pathname : location.join('/') , state : {
                          // tab : 2, 
                          reload : true}})
                      }
                    }
                    setloaderButton(false)
                  }}
                  className = "ml_8"
              />
          </div>
    })
  }, [editStatus, flowName, elements, loaderButton]) //

  useEffect(async() => {
    let deal = await localForage.getItem('deal');
    setDeal(deal)
  }, [])

  const handleDelete = async() => {
    setDeleteLoader(true)
    // let tempId = []
    // let tempObj = []
    // elements.map((value, index)=> {
    //   if(value.type === "smoothstep"){
    //     if(!tempId.includes(value.source)) tempId.push(value.source)
    //     if(!tempId.includes(value.target)) tempId.push(value.target)
    //   }
    // })
    // elements.map((value, index)=> {
    //   if(tempId.includes(value.id)) tempObj.push(value)
    // })

    // let obj = []
    // tempObj.map((value, index)=> {
    //   if(value.data.customType !== 'deal' && value.data.customType !== 'checkIn' && value.data.customType !== 'signUp'){
    //     obj.push({
    //       field   : value.data.customType,
    //       operand : '==',
    //       value   : value.data.value,
    //       deal    : tempObj[index+1].data.customType === 'deal' ? {dealLifeTime: tempObj[index+1].data.value.days, subtractPoints: tempObj[index+1].data.value.subtract, validDays: tempObj[index+1].data.value.valid, name: tempObj[index+1].data.value.deal} : {}
    //     })
    //   }
    // })

    // // const data = stringify(elements)
    // // const datadata = parse(data)
    // let payloadElements = []
    // elements.map((value, index)=> {
    //   payloadElements.push({...value, data:{...value.data, label: ''}})
    // })
    

    let payload = {
      _id    : flowData._id,
      delete : true
      // name        : flowName.name,
      // flow        : payloadElements,
      // agencyId    : agency._id,
      // brandId     : brand._id,
      // triggerType : tempObj[0].data.customType,
      // conditions  : obj
    }

    // payload._id       = flowData._id
    // payload.delete    = true
    const {response, error} = await FlowService.Update({toaster: true, payload})
    if(response){
      setDeleteLoader(true)
      setDeleteModal(false)
      const location = window.location.pathname.split('/')
      location.pop()
      history.push({
        pathname: location.join('/'),
        state: {reload: true}
      })
    }
    if(error) {
      setDeleteLoader(true)
      setDeleteModal(false)
    }
  }

  // useEffect(() => {
  //   if(brand.brandRedemptionType === RedemptionType.ORDER){
  //     setNodeName({...nodeName, trigger: 'signUp', platform:'cloudwaitress' })
  //     setSelectedType('signUp')
  //     setElements((els) =>
  //       els.map((el) => {
  //         if (el.id === '1') {
  //           el = {
  //             ...el,
  //             value : 'signUp',
  //             data : {
  //               ...el.data,
  //               customType : 'signUp',
  //               label: 
  //               <div>
  //               <div className="d-flex m_12 align-items-center">
  //                 <div><SvgIcons.SignUpIcon/></div>
  //                 <div className="Heading16M color-neutral100 ml_16">Sign Up</div>
  //               </div>
  //               <div className="bar"></div>
  //               <div className="d-flex mt_12 mr_16 mb_12 ml_16 pt_12 pr_16 pb_12 pl_16 align-items-center borderRadius-8" style={{backgroundColor: '#E2FAEC'}}>
  //                 {/* <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{brand.brandRedemptionType === RedemptionType.ORDER ? 'Cloudwaitress' : nodeName.platform||'cloudwaitress'+" ."}</span></div> */}
  //                 <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{nodeName.platform||'cloudwaitress'+" ."}</span></div>
  //               </div>
  //               </div>
  //               ,
  //             }
  //           }
  //         }
  //         return el;
  //       })
  //     );
  //   }
  // }, [])

    return (
        <div className="dndflow w-100" id="FlowBuilder" style={{height: 'calc(100vh - 84px)'}}>
          {/* {console.log('nodeName.promotion',nodeName.promotion)} */}
          {console.log('elements',elements)}
          {/* {console.log('nodeName',nodeName)} */}
          {/* <div className="controls middle " style={{zIndex: '1000', position: 'fixed'}}>
          <button onClick={() => onLayout('TB')}>vertical layout</button>
          <button onClick={() => onLayout('LR')}>horizontal layout</button>
        </div> */}
      {loader ? '' : <ReactFlowProvider>
        <Sidebar toggle={toggle} setToggle={setToggle} onLayout={onLayout} setstate={setstate} state={state}/>
        <div className="reactflow-wrapper w-100" ref={reactFlowWrapper}>
          <ReactFlow
            id                = "reactflow"
            minZoom           = {0.1}
            elements          = {elements}
            getConnectedEdges = {(value)=>console.log('getConnectedEdges',value)}
            onConnect         = {onConnect}
            onElementsRemove  = {onElementsRemove}
            onLoad            = {onLoad}
            onEdgeUpdate      = {onEdgeUpdate}
            arrowHeadColor    = {ColorSchemeCode.themeColor}
            onDrop            = {onDrop}
            // connectionLineStyle = {{stroke: ColorSchemeCode.GeneralBlack, strokeWidth: 2 }}
            onDragOver          = {onDragOver}
            // onMoveEnd={(flowTransform)=>console.log(flowTransform)}
            // onEleme
            onElementClick   = {(e, element)=>{
              // // console.log('element',element);
              // onElementsRemove(element)
              // console.log('Hello')


              if(element.type !== 'smoothstep' || element.data.customType !== 'onetime'){
                if(element.data.customType === "onetime" || element.data.customType === "expire") setsidebar(false)
                else setsidebar(true)

                setSelected(element.id)
                element.data.customType && setSelectedType(element.data.customType)
                elements.map((value,index)=>{
                  if(value.id === element.id) setNodeName ({...value.data.value}) //, promotion: nodeName.promotion
                  // if(value.id === element.id) setNodeName (value.data.value)
                })
              }
              // setNodeName (e.target.innerText)


            }}
          >
            <Controls onFitView={(e)=>{
              if(toggle){
                document.getElementById        ("collapse").style.width      = "41px";
                document.getElementById        ("mySidebar").style.width      = "0";
                document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
                document.getElementById        ("flowControl").style.left     = "80px";
                // document.getElementsByClassName('react-flow__controls')[0].style.left = "80px !important"
              }
              else{
                document.getElementById        ("collapse").style.width      = "340px";
                document.getElementById        ("mySidebar").style.width      = "300px";
                document.getElementsByClassName('react-flow__controls')[0].id = "flowControl"
                document.getElementById        ("flowControl").style.left     = "380px";
                // document.getElementsByClassName('react-flow__controls')[0].style.left = "380px !important"
              }
              setToggle(!toggle)
            }} />

            {sidebar && selectedType !== 'action' &&
            <div className="abc" style={{height: 'calc(100vh - 60px)',overflow: 'auto', width: '300px'}}>
            <div className="d-flex space-between align-items-center">
              <div className="mr_24 ml_24 mt_24 Heading22M color-neutral100" >Properties</div>
              <div className="cp deleteIcon mr_24" onClick={()=>setsidebar(false)}><SvgIcons.CloseIcon/></div>
            </div>
            {
              selectedType === 'checkIn' || selectedType === 'signUp' ? 
              <div>


                <div className="mr_12 ml_12">
                  <div className='bar mt_16 mb_16'></div>
                  <CustomSelect 
                    backgroundColor={ColorSchemeCode.textFieldBackground}
                    className = {"w-100"}
                    // className = {brand.brandRedemptionType === RedemptionType.ORDER ? "w-100 disabled" : "w-100"}
                    options   = {[
                      {
                        name : 'Check In',
                        value : 'Check In'
                      },
                      {
                        name : 'Sign Up',
                        value : 'Sign Up'
                      },

                    ]
                    // <><option value={"checkIn"}>{"Check In"}</option><option value={"signUp"}>{"Sign Up"}</option></>
                  }
                    name      = "method"
                    label     = "Method"
                    // value     = {brand.brandRedemptionType === RedemptionType.ORDER ? 'signUp' : nodeName.trigger || 'checkIn'}
                    value     = {nodeName.trigger || 'checkIn'}
                    onChange  = {(evt) => {
                      setNodeName({...nodeName, trigger: evt.target.value})
                      if(evt.target.value === 'signUp') setSelectedType('signUp')
                      if(evt.target.value === 'checkIn') setSelectedType('checkIn')
                      setElements((els) =>
                      els.map((el) => {
                        if (el.id === selected) {
                          if(evt.target.value === 'signUp'){
                            el = {
                              ...el,
                              value : 'signUp',
                              data : {
                                ...el.data,
                                customType : 'signUp',
                                label: 
                                <div>
                                <div className="d-flex m_12 align-items-center">
                                  <div><SvgIcons.SignUpIcon/></div>
                                  <div className="Heading16M color-neutral100 ml_16">Sign Up</div>
                                </div>
                                <div className="bar"></div>
                                <div className="d-flex mt_12 mr_16 mb_12 ml_16 pt_12 pr_16 pb_12 pl_16 align-items-center borderRadius-8" style={{backgroundColor: '#E2FAEC'}}>
                                  {/* <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{brand.brandRedemptionType === RedemptionType.ORDER ? 'Cloudwaitress' : nodeName.platform||'Manychat'+" ."}</span></div> */}
                                  <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{nodeName.platform||'Manychat'+" ."}</span></div>
                                </div>
                                </div>
                                ,
                              }
                            }
                          }
                          if(evt.target.value === 'checkIn'){
                            el = {
                              ...el,
                              value : 'checkIn',
                              data : {
                                ...el.data,
                                customType : 'checkIn',
                                label: 
                                <div>
                                <div className="d-flex m_12 align-items-center">
                                  <div><SvgIcons.CheckInIcon/></div>
                                  <div className="Heading16M color-neutral100 ml_16">Check In</div>
                                </div>
                                <div className="bar"></div>
                                <div className="d-flex mt_12 mr_16 mb_12 ml_16 pt_12 pr_16 pb_12 pl_16 align-items-center borderRadius-8" style={{backgroundColor: '#E2FAEC'}}>
                                  <div><SvgIcons.QrIcon/></div>
                                  <div className="Caption12R ml_12">When customers check-ins through <span className="Caption12M underline">Qr scan.</span></div>
                                </div>
                                </div>
                              }
                            }
                          }
                        }
                        return el;
                      })
                    );
                    }}
                  />
                  <div className='bar mt_16 mb_16'></div>
                </div>

                
                <div 
                className='ml_12 mr_12'
                // className="mt_24 mr_12 mb_24 ml_12 pt_24 pr_16 pb_24 pl_16 borderRadius-8" style={{border: '1px solid #DADCE0'}}
                >
                  <div className='mb_12 color-neutral60 Caption12M'>EARN AND REDEEM TYPE</div>
                  
                  {/* {
                    selectedType === 'signUp' 
                      ?
                      <div className="Caption12R color-neutral100 mb_24">{"Check if customer sign up platform is "}<span className="Caption12M color-neutral100">{nodeName.platform||'None'+" ."}</span></div>
                      // <div className="Caption12R color-neutral100 mb_24">{"Check if customer sign up platform is "}<span className="Caption12M color-neutral100">{brand.brandRedemptionType === RedemptionType.ORDER ? 'Cloudwaitress' : nodeName.platform||'Manychat'+" ."}</span></div>
                      :
                      <div className="Caption12R color-neutral100 mb_24">When customers check-ins through <span className="Caption12M color-neutral100">QR Scan.</span></div>
                  } */}
                  {
                    selectedType === 'signUp' 
                      ? 
                      <>
                      {/* [
                        {
                          name : 'None',
                          value: ''
                        },
                        {
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },{
                          name : '',
                          value: ''
                        },
                      ] */}
                      <div id="up" data-for={'up'} data-tip={'up'}>
                      <CustomSelect 
                        backgroundColor={ColorSchemeCode.textFieldBackground}
                        className = {"w-100"}
                        // className = {brand.brandRedemptionType === RedemptionType.ORDER ? "w-100 disabled" : "w-100"}
                        options   = {<>
                        <option disabled value={"None"}>{"None"}</option>
                        <option value={"Manychat"}>{"Manychat"}</option>
                        {/* <option value={"SilferBot"}>{"SilferBot"}</option> */}
                        <option value={"chatrace"}>{"chatrace"}</option>
                        <option value={"uChat"}>{"uChat"}</option>
                        <option value={"app"}>{"app"}</option>
                        <option value={"pos"}>{"pos"}</option>
                        <option value={"widget"}>{"widget"}</option>
                        <option value={"ghl"}>{"ghl"}</option>
                        <option value={"cloudwaitress"}>{"cloudwaitress"}</option></>
                        }
                        name      = "platform"
                        label     = "platform"
                        value     = {nodeName.platform || 'None'}
                        // value     = {brand.brandRedemptionType === RedemptionType.ORDER ? 'cloudwaitress' : nodeName.platform || 'Manychat'}
                        onChange  = {(evt) => {
                          if(evt.target.value==='cloudwaitress'){
                            setcloudwaitress('cloudwaitress')
                            for (const iterator of elements) {
                              if(iterator.type === 'default' && (iterator.data.customType === 'visit' || iterator.data.customType === 'onetime' || iterator.data.customType === 'expire' || iterator.data.customType === 'event' || iterator.data.customType === 'action')) return showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot select this platform.' , severity: 'error'})
                            }
                          }
                          else setcloudwaitress()
                          setNodeName({...nodeName, platform: evt.target.value})
                          setElements((els) =>
                          els.map((el) => {
                            if (el.id === selected) {
                              el.data = {
                                ...el.data,
                                label: 
                                <div>
                                <div className="d-flex m_12 align-items-center">
                                  <div><SvgIcons.SignUpIcon/></div>
                                  <div className="Heading16M color-neutral100 ml_16">Sign Up</div>
                                </div>
                                <div className="bar"></div>
                                <div className="d-flex mt_12 mr_16 mb_12 ml_16 pt_12 pr_16 pb_12 pl_16 align-items-center borderRadius-8" style={{backgroundColor: '#E2FAEC'}}>
                                  <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{evt.target.value+" ."}</span></div>
                                  {/* <div className="Caption12R ml_12">{"Check if customer sign up platform is "}<span className="Caption12M underline">{brand.brandRedemptionType === RedemptionType.ORDER ? 'Cloudwaitress' : evt.target.value+" ."}</span></div> */}
                                </div>
                                </div>
                                ,
                              };
                            }

                            return el;
                          })
                        );
                        }}
                      />
                      </div>
                      <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="up" getContent={()=>"Select a platform by which customers will sign up."} effect='solid' place="left"/>
                      </>
                      :
                      <>
                      <div id="qr" data-for={'qr'} data-tip={'qr'}>
                      <CustomSelect 
                        backgroundColor={ColorSchemeCode.textFieldBackground}
                        className = "w-100"
                        options   = {<><option value={"QR Scan"}>{"QR Scan"}</option><option value={""}>{"None"}</option></>}
                        name      = "method"
                        label     = "Method"
                        value     = {"QR Scan"}
                        // onChange  = {(evt) => setNodeName(evt.target.value)}
                      />
                      </div>
                      <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="qr" getContent={()=>"Select check in type through which customer can check in."} effect='solid' place="left"/>
                      </>
                  }

                </div>
                {/* //check in earning method */}
                {
                  selectedType === 'signUp' && nodeName.platform === 'app' ?
                      <div 
                      className="ml_12 mr_12 mt_16"
                      // className="mt_24 mr_12 mb_24 ml_12 pt_24 pr_16 pb_24 pl_16 borderRadius-8" style={{border: '1px solid #DADCE0'}}
                      >
                        {/* <div className="Caption12R color-neutral100 mb_24">Earning Method: <span className="Caption12M color-neutral100">{nodeName.redemptionType||"Remote."}</span></div> */}
                        <CustomSelect 
                            backgroundColor={ColorSchemeCode.textFieldBackground}
                            className = "w-100"
                            options   = {getRedemptionType()}
                            name      = "redemptionType"
                            label     = "Earn Method"
                            value     = {nodeName.redemptionType || RedemptionType.SELF} 
                            onChange  = {(evt) => {
                              // if(evt.target.value === RedemptionType.PROMOTION){
                              //   for (const iterator of elements) {
                              //     if(iterator.type === 'default' && iterator.data.customType !== 'event') return showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot select this platform.' , severity: 'error'})
                              //   }
                              // }
                              // else 
                              setNodeName({...nodeName, redemptionType: evt.target.value})
                            }}
                            />
                    </div>                
                :
                   selectedType === 'checkIn' ?
                    <div 
                    className="ml_12 mr_12 mt_16"
                    // className="mt_24 mr_12 mb_24 ml_12 pt_24 pr_16 pb_24 pl_16 borderRadius-8" style={{border: '1px solid #DADCE0'}}
                    >
                        {/* <div className="Caption12R color-neutral100 mb_24">Earning Method: <span className="Caption12M color-neutral100">{nodeName.redemptionType||"Remote."}</span></div> */}
                        <div id="earnMethod" data-for={'earnMethod'} data-tip={'earnMethod'}>
                          <CustomSelect 
                            backgroundColor={ColorSchemeCode.textFieldBackground}
                            className = "w-100 "
                            options   = {getRedemptionType()}
                            name      = "redemptionType"
                            label     = "Earn Method"
                            value     = {nodeName.redemptionType || RedemptionType.SELF}
                            onChange  = {(evt) => {
                              // if(evt.target.value === RedemptionType.PROMOTION){
                              //   for (const iterator of elements) {
                              //     if(iterator.type === 'default' && iterator.data.customType !== 'event') return showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot select this platform.' , severity: 'error'})
                              //   }
                              // }
                              // else 
                              setNodeName({...nodeName, redemptionType: evt.target.value})
                            }}
                            />
                        </div>
                        <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="earnMethod" getContent={()=>"Select check in type through which customer can check in."} effect='solid' place="left"/>
                    </div>
                    : ''
                  }
                  {/* <div className='ml_12 mr_12 mt_12 mb_12 color-neutral60 Caption12M'>TRIGGER OPTIONS</div>
                  <div className='ml_12 mr_12'>
                    <div id="promotion" data-for={'promotion'} data-tip={'promotion'}>
                    <CustomSelect 
                      backgroundColor={ColorSchemeCode.textFieldBackground}
                      className = "w-100"
                      options   = {<>
                                     <option value={"Visit & Spending"}>{"Visit & Spending"}</option>
                                     <option value={"Event Base"}>{"Event Base"}</option>
                                  </>}
                      name      = "promotion"
                      label     = "Promotion"
                      value     = {nodeName.promotion || "Visit & Spending"}
                      onChange  = {(evt) => {
                        if(evt.target.value === 'Visit & Spending'){
                          // console.log('1');
                          let temp = true
                          for (const iterator of elements) {
                            if(iterator.type === 'default' && (iterator.data.customType === 'event')){
                              temp = false
                              setNodeName({...nodeName, promotion: 'Event Base'})
                              showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot select this condition.' , severity: 'error'})
                              break;
                            } 
                          }
                          if(temp === true) setNodeName({...nodeName, promotion: evt.target.value})
                        }
                        else{
                          // console.log('2');
                          let temp1 = false;
                          for (const iterator of elements) {
                            if(iterator.type === 'default' && (iterator.data.customType === 'point' || iterator.data.customType === 'visit' || iterator.data.customType === 'onetime' || iterator.data.customType === 'expire' || iterator.data.customType === 'action')){
                              temp1 = true
                              setNodeName({...nodeName, promotion: 'Visit & Spending'})
                              showToaster({position: 'bottom-right', title: 'Title Error', message:  'Sorry, you cannot select this condition.' , severity: 'error'})
                              break;
                            }
                          }
                          // console.log('temp1',temp1);
                          if(!temp1) {
                            // console.log('I m called')
                            setNodeName({...nodeName, promotion: evt.target.value})
                          }
                        }
                      }}
                    />
                    </div>
                    <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="promotion" getContent={()=>"Select check in type through which customer can check in."} effect='solid' place="left"/>
                  </div> */}
              </div>
              :
              selectedType === 'point' ? 
              <div className="ml_12 mr_12"
              // className="mt_24 mr_12 mb_24 ml_12 pt_24 pr_16 pb_24 pl_16 borderRadius-8" style={{border: '1px solid #DADCE0'}}
              >
                <div className='bar mt_16 mb_16'></div>
                <div className='color-neutral60 Caption12M'>CONDITIONS OPTIONS</div>
                {/* <div className="Caption12R color-neutral100 mb_24">Check if customer reached <span className="Caption12M color-neutral100">{nodeName +" Points."}</span></div> */}
                <div id="point" data-for={'point'} data-tip={'point'}>
                <CustomTextField 
                  className = " w-100 mt_16"
                  label     = "Points"
                  type      = "number"
                  value     = {nodeName.point || 0}
                  onChange  = {(evt) => {
                    setNodeName({...nodeName, point:evt.target.value})
                    setElements((els) =>
                    els.map((el) => {
                      if (el.id === selected) {
                        el.data = {
                          ...el.data,
                          label: 
                          <div>
                          <div className="d-flex m_12 align-items-center">
                            <div><SvgIcons.PointIcon/></div>
                            <div className="Heading16M color-neutral100 ml_16">Points</div>
                          </div>
                          <div className="bar"></div>
                          <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer reached <span className="Caption12M underline">{evt.target.value+" Points."}</span></div>
                          </div>
                          ,
                        };
                      }

                      return el;
                    })
                  );
                  }}
                />
                </div>
                <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="point" getContent={()=>"Set on how much points you want to perform the action."} effect='solid' place="left"/>
              </div>
              :
              selectedType === 'visit' ? 
              <div 
              className="ml_12 mr_12"
              // className="mt_24 mr_12 mb_24 ml_12 pt_24 pr_16 pb_24 pl_16 borderRadius-8" style={{border: '1px solid #DADCE0'}}
              >
                <div className='bar mt_16 mb_16'></div>
                <div className='color-neutral60 Caption12M'>CONDITIONS OPTIONS</div>
                {/* <div className="Caption12R color-neutral100 mb_24">Check if customer visits for the <span className="Caption12M color-neutral100">{utils.getNumberWithOrdinal(nodeName) +" Time."}</span></div> */}
                {console.log('nodeName',nodeName)}
                <div id="visit" data-for={'visit'} data-tip={'visit'}>
                  <CustomTextField 
                    className = " w-100 mt_16"
                    label     = "Visit"
                    type      = "number"
                    value     = {nodeName.visit || 0}
                    onChange  = {(evt) => {
                      setNodeName({...nodeName, visit: evt.target.value})
                      setElements((els) =>
                      els.map((el) => {
                        if (el.id === selected) {
                          el.data = {
                            ...el.data,
                            label: 
                            <div>
                            <div className="d-flex m_12 align-items-center">
                              <div><SvgIcons.VisitIcon/></div>
                              <div className="Heading16M color-neutral100 ml_16">On Visit</div>
                            </div>
                            <div className="bar"></div>
                            <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visits for the <span className="Caption12M underline">{utils.getNumberWithOrdinal(evt.target.value)+" Time."}</span></div>
                            </div>
                            ,
                          };
                        }

                        return el;
                      })
                    );
                    }}
                  />
                </div>
                <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="visit" getContent={()=>"Set on how much visits you want to perform the action."} effect='solid' place="left"/>
              </div>
              :
              selectedType === 'deal' ? 
              <>
              <div className="mr_12 ml_12"
              // className="mt_24 mr_12 mb_24 ml_12 pt_24 pr_16 pb_24 pl_16 borderRadius-8" style={{border: '1px solid #DADCE0'}}
              >
                <div className='bar mt_16 mb_16'></div>
                <div className='color-neutral60 Caption12M'>REWARD OPTIONS</div>
                {/* <div className="Caption12R color-neutral100 mb_24">{"Give "} <span className="Caption12M color-neutral100">{nodeName.deal}</span> {" to customer."}</div> */}
                <div id="deal" data-for={'deal'} data-tip={'deal'}>
                <CustomSelect 
                  backgroundColor={ColorSchemeCode.textFieldBackground}
                  className = "w-100 mb_24 mt_15"
                  options   = {<>
                  {
                    deal.map((value, index)=> (
                      <option value={value.name}>{utils.capitalize(value.name)}</option>
                    ))
                  }
                  </>}
                  name      = "deal"
                  label     = "Select Deal"
                  value     = {nodeName.deal}
                  onChange  = {(evt) => {
                    let tempImage           = ''
                    let tempId              = ''
                    let tempCode            = ''
                    let temptotalPrice      = ''
                    let tempdiscountedPrice = ''
                    let temppercentOff      = ''

                    deal.map((value, index)=> {
                      // console.log('====================================');
                      // console.log(value.code);
                      // console.log('====================================');
                      if(value.name === evt.target.value){
                        tempImage           = value.image
                        tempId              = value._id
                        tempCode            = value.code&&value.code
                        tempdiscountedPrice = value.discountedPrice&&value.discountedPrice
                        temptotalPrice      = value.totalPrice&&value.totalPrice
                        temppercentOff      = value.percentOff&&value.percentOff
                        
                        setSelectedDeal({
                          name            : value.name,
                          image           : value.image,
                          id              : value._id,
                          code            : value.code&&value.code,
                          discountedPrice : value.discountedPrice&&value.discountedPrice,
                          totalPrice      : value.totalPrice&&value.totalPrice,
                          percentOff      : value.percentOff&&value.percentOff,
                        })
                      }
                    })

                    let index="";
                    elements.forEach((element, idx)=>{
                      if((element?.data?.customType == "deal") && (element.data.value.deal == evt.target.value) ) index = idx
                    })

                    setNodeName({...nodeName, dealeligible :  evt.target.value+'_eligible'+(index ? '_' + index.toString() : ""), dealredeem : evt.target.value+'_avail'+(index ? '_' + index.toString() : ""), dealexpire : evt.target.value+'_expire'+(index ? '_' + index.toString() : ""),  deal: evt.target.value, image: tempImage, id: tempId, code: tempCode, discountedPrice: tempdiscountedPrice, percentOff: temppercentOff, totalPrice: temptotalPrice})
                    setElements((els) =>
                    els.map((el) => {
                      if (el.id === selected) {
                        el.data = {
                          ...el.data,
                          label: 
                          <div>
                          <div className="d-flex m_12 align-items-center">
                            <div><SvgIcons.GiveDealIcon/></div>
                            <div className="Heading16M color-neutral100 ml_16">Give Deal</div>
                          </div>
                          <div className="bar"></div>
                          <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_10 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#E8EFF7'}}>{"Give "} <span className="Caption12M underline">{evt.target.value}</span>{" to customer."}</div>
                          {tempImage&&<img style={{pointerEvents: "none"}} className="pe-none borderRadius-4 pl_12 pr_12 pb_16" width="100%" height="auto" src={tempImage} />}
                          </div>
                          ,
                        };
                      }

                      return el;
                    })
                  );
                  }}
                />
                </div>
                <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="deal" getContent={()=>"Select deal you want to give to your customer on a condition your set in the condition block."} effect='solid' place="left"/>
                {nodeName.image&&<img style={{pointerEvents: "none"}} className="borderRadius-4 " width="100%" height="auto" src={nodeName.image} />}
              </div>
              <div className='bar mt_16 mb_16'></div>
              {/* <div className="mr_12 ml_12 Heading16M color-neutral100 mb_24">Validation</div> */}
              <div className='ml_12 mr_12 color-neutral60 Caption12M'>REWARD OPTIONS</div>
              <div className="w-90" id="valid" data-for={'valid'} data-tip={'valid'}>
                <CustomSelect 
                  backgroundColor={ColorSchemeCode.textFieldBackground}
                  className = " mr_12 ml_12 mb_24 mt_16"
                  options   = {<>
                    <option value={30}>{"30"}</option>
                    <option value={60}>{"60"}</option>
                    <option value={90}>{"90"}</option>
                    <option value={"unlimited"}>{"unlimited"}</option>
                  </>}
                  name      = "valid"
                  label     = "Valid Days"
                  value     = {nodeName.valid || 'unlimited'}
                  onChange  = {(evt) => setNodeName({...nodeName, valid: evt.target.value})}
                />
              </div>
              <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="valid" getContent={()=>"Deal would’nt be available to the customers to avail after valid days."} effect='solid' place="left"/>
              <CustomTextField 
                className = "w-90 mr_12 ml_12 mb_24 "
                label     = "Subtract Points"
                type      = "number"
                value     = {nodeName.subtract ? nodeName.subtract : 0}
                onChange  = {(evt) => setNodeName({...nodeName, subtract: evt.target.value})}
              />
              {/* <div className="mr_12 ml_12 Heading16M color-neutral100 mb_4">Deal Lifetime</div>
              <div className="mr_12 ml_12 Body14R color-neutral60 mb_24">Set days for the deal to expire for the consumer.</div> */}
              
              <div id="expiration" data-for={'expiration'} data-tip={'expiration'}>
              <CustomTextField 
                className = "w-90 mr_12 ml_12 mb_12 "
                label     = "Expiration Days"
                type      = "number"
                value     = {nodeName.days ? nodeName.days : 0}
                onChange  = {(evt) => setNodeName({...nodeName, days: evt.target.value})}
              />
              </div>
              <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="expiration" getContent={()=>"Customer cannot use this deal after expiration day."} effect='solid' place="left"/>




              <div className='bar mt_16 mb_16'></div>
              <div className='color-neutral60 Caption12M ml_12 mb_12'>TAGS</div>

              <div id="dealeligible" data-for={'dealeligible'} data-tip={'dealeligible'}>
              <CustomTextField 
                className = "w-90 mr_12 ml_12 mb_12 "
                label     = "Eligible Deal Tag"
                value     = {nodeName.dealeligible ||''}
                onChange  = {(evt) => setNodeName({...nodeName, dealeligible: evt.target.value})}
              />

              {console.log('nodename ', nodeName)}
              </div>
              <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="dealeligible" getContent={()=>"Deal eligible tag...."} effect='solid' place="left"/>

              <div id="dealredeem" data-for={'dealredeem'} data-tip={'dealredeem'}>
              <CustomTextField 
                className = "w-90 mr_12 ml_12 mb_12 "
                label     = "Redeem Deal Tag"
                value     = {nodeName.dealredeem||''}
                onChange  = {(evt) => setNodeName({...nodeName, dealredeem: evt.target.value})}
              />
              </div>
              <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="dealredeem" getContent={()=>"Deal redeem tag...."} effect='solid' place="left"/>

              <div id="dealexpire" data-for={'dealexpire'} data-tip={'dealexpire'}>
              <CustomTextField 
                className = "w-90 mr_12 ml_12 mb_12 "
                label     = "Expire Deal Tag"
                value     = {nodeName.dealexpire||''}
                onChange  = {(evt) => setNodeName({...nodeName, dealexpire: evt.target.value})}
              />
              </div>
              <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="dealexpire" getContent={()=>"Deal expire tag...."} effect='solid' place="left"/>



              <div className='bar mt_16 mb_16'></div>
              {/* {nodeName.promotion==='Event Base'&& */}
              {<div className='mr_12 ml_12 mb_24 mt_16 align-items-center'>
              <div className=' color-neutral60 Caption12M mb_15 '>NOTIFICATION</div>
              <CustomSelect 
                  backgroundColor={ColorSchemeCode.textFieldBackground}
                  className = "w-100 "
                  options   = {<>
                    <option value={'Monthly'}>{"Monthly"}</option>
                    <option value={'Weekly'}>{"Weekly"}</option>
                    <option value={'Daily'}>{"Daily"}</option>
                  </>}
                  name      = "notification"
                  label     = "Notification"
                  value     = {nodeName.notification || 'Daily'}
                  onChange  = {(evt) => setNodeName({...nodeName, notification: evt.target.value})}
                />
              </div>}
              {/* {nodeName.promotion==='Event Base'&& */}
              {<div className='bar mt_16 mb_16'></div>}
              {/* {nodeName.promotion==='Event Base'&& */}
              {<div className='d-flex align-items-center'>
              <div className='ml_12 mr_12 color-neutral60 Caption12M'>RECURRING DEAL</div>
              <CustomSwitch name="recurring" checked={nodeName.recurring||false} onChange={()=>{
                if(nodeName.recurring === true) setNodeName({...nodeName, recurring: false})
                else if(nodeName.recurring === false) setNodeName({...nodeName, recurring: true})
                else setNodeName({...nodeName, recurring: true})
              }} />
              </div>}
              {/* {<div className='d-flex align-items-center'>
              <div className='ml_12 mr_12 color-neutral60 Caption12M'>FIRST DEAL</div>
              <CustomSwitch name="recurring" checked={nodeName.firstdeal||false} onChange={()=>{
                if(nodeName.firstdeal === true) setNodeName({...nodeName, firstdeal: false})
                else if(nodeName.firstdeal === false) setNodeName({...nodeName, firstdeal: true})
                else setNodeName({...nodeName, firstdeal: true})
              }} />
              </div>} */}
              </>
              :
              selectedType === 'event' ? 
              <div className="ml_12 mr_12"
              // className="mt_24 mr_12 mb_24 ml_12 pt_24 pr_16 pb_24 pl_16 borderRadius-8" style={{border: '1px solid #DADCE0'}}
              >
                <div className='bar mt_16 mb_16'></div>
                <div className='color-neutral60 Caption12M'>CONDITIONS OPTIONS</div>
                {/* <div className="Caption12R color-neutral100 mb_24">Check if customer visit on <span className="Caption12M color-neutral100">New Year event.</span>(01/01/2022) </div> */}
                <div id="eventName" data-for={'eventName'} data-tip={'eventName'}>
                <CustomTextField 
                  className = " w-100 mt_16"
                  label     = "Event Name"
                  value     = {nodeName.event || ''}
                  onChange  = {(evt) => {
                    setNodeName({...nodeName, event: evt.target.value})
                    setElements((els) =>
                    els.map((el) => {
                      if (el.id === selected) {
                        el.data = {
                          ...el.data,
                          label: 
                          <div>
                          <div className="d-flex m_12 align-items-center">
                            <div><SvgIcons.EventIcon/></div>
                            <div className="Heading16M color-neutral100 ml_16">On Event</div>
                          </div>
                          <div className="bar"></div>
                          <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visit on <span className="Caption12M underline">{evt.target.value+". "}</span> {nodeName.date +' - '+nodeName.enddate}</div>
                          </div>
                          ,
                        };
                      }

                      return el;
                    })
                  );
                  }}
                  />
                  </div>
                  <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="eventName" getContent={()=>"Set event name for the event action your creating."} effect='solid' place="left"/>
                  <div id="eventDate" data-for={'eventDate'} data-tip={'eventDate'}>
                  <CustomTextField 
                    className = " w-100 mt_24"
                    // label     = "Date"
                    type      = "date"
                    value     = {nodeName.date || ''}
                    onChange  = {(evt) => {
                      setNodeName({...nodeName, date: evt.target.value})
                      setElements((els) =>
                      els.map((el) => {
                        if (el.id === selected) {
                          el.data = {
                            ...el.data,
                            label: 
                            <div>
                            <div className="d-flex m_12 align-items-center">
                              <div><SvgIcons.EventIcon/></div>
                              <div className="Heading16M color-neutral100 ml_16">On Event</div>
                            </div>
                            <div className="bar"></div>
                            <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visit on <span className="Caption12M underline">{el.data.value.event+". "}</span> ({evt.target.value + " - " + el.data.value.enddate})</div>
                            </div>
                            ,
                          };
                        }

                        return el;
                      })
                    );
                    }}
                  />
                </div>
                <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="eventDate" getContent={()=>"Set start date for the event to check."} effect='solid' place="left"/>
                  <div id="endeventDate" data-for={'endeventDate'} data-tip={'endeventDate'}>
                  <CustomTextField 
                    className = " w-100 mt_24"
                    // label     = "Date"
                    type      = "date"
                    value     = {nodeName.enddate || ''}
                    onChange  = {(evt) => {
                      setNodeName({...nodeName, enddate: evt.target.value})
                      setElements((els) =>
                      els.map((el) => {
                        if (el.id === selected) {
                          el.data = {
                            ...el.data,
                            label: 
                            <div>
                            <div className="d-flex m_12 align-items-center">
                              <div><SvgIcons.EventIcon/></div>
                              <div className="Heading16M color-neutral100 ml_16">On Event</div>
                            </div>
                            <div className="bar"></div>
                            <div className="pt_12 pr_24 pb_12 pl_24 mt_16 mb_16 mr_12 ml_12 Caption12R borderRadius-8 text-align-left" style={{backgroundColor: '#FBEDDF'}}>Check if customer visit on <span className="Caption12M underline">{el.data.value.event+". "}</span> ({el.data.value.date + " - " + evt.target.value})</div>
                            </div>
                            ,
                          };
                        }

                        return el;
                      })
                    );
                    }}
                  />
                </div>
                <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R w-20" id="endeventDate" getContent={()=>"Set end date for the event to check."} effect='solid' place="left"/>
              </div>
              :
              ''
            //   <div className="mb_16">
            //   <div className="Heading16M color-neutral100 mb_4">Enter Value</div>
            //   <div className="">
            //     <CustomTextField
            //       value={nodeName} 
            //       onChange={(evt) => setNodeName(evt.target.value)}
            //     />
            //   </div>
            // </div>
            }
            {/* <label>label:</label>
            <input value={nodeName} onChange={(evt) => setNodeName(evt.target.value)} /> */}

            {/* <label className="updatenode__bglabel">background:</label>
            <input value={nodeBg} onChange={(evt) => setNodeBg(evt.target.value)} /> */}

            {/* <div className="updatenode__checkboxwrapper">
              <label>hidden:</label>
              <input type="checkbox" checked={nodeHidden} onChange={(evt) => setNodeHidden(evt.target.checked)} />
            </div> */}
          </div>}
          </ReactFlow>
        </div>
        
      </ReactFlowProvider>}




      <CustomsModal
        open      = { deleteModal }
        minWidth  = {'600px'}
        component = {<DeleteModal icon={deleteLoader} title="Flow" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
        onClose   = {()=> setDeleteModal(false)}
      />
     
      <CustomsModal
        open      = { nameModal }
        minWidth  = {'450px'}
        component = {<>
          <div className="Heading22M color-neutral100 d-flex">Flow Name</div>
          <div className="mb_24 mt_24">
            <CustomTextField 
              autofocus
              className = " w-100"
              label     = "Flow Name"
              value     = {flowName.name}
              onChange  = {(e)=>setFlowName({...flowName, name: e.target.value} )}
            />
            {flowName.name === '' && <div className="error">Flow Name is a required field</div>}
          </div>
          <div className="text-align-right">
            <CustomButton varient="primary" btntext="Confirm" onClick={()=>{
              if(flowName.name === '') showToaster({position: 'bottom-right', title: 'Title Error', message:  'Title cannot be empty' , severity: 'error'})
              else setNameModal(false)}}/>
          </div>
        </>}
      />
    </div>
    )
}

export default FlowTest
