import React from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import ReferralCampaign from '.././referral/subpages/ReferralCampaign';
import Participations from '.././referral/subpages/Participations';
import Analytics from '.././referral/subpages/Analytics';



export default function Referral(props) {


    return (
        <div>
            <Switch>
                <Route 
                    path={props.match.url + '/create'} 
                    render= {() =>    <ReferralCampaign {...props}  />}
                />
                <Route 
                    path={props.match.url + '/analytics'} 
                    render= {() =>    <Analytics {...props}  />}
                />
                <Route 
                    path={props.match.url + '/participations'} 
                    render= {() =>    <Participations {...props}  />}
                />
                <Redirect path={props.match.url+ '/*'} to={props.match.url+ '/create'} />
                <Redirect path={props.match.url} to={props.match.url+ '/create'} />
            </Switch>
        </div>
    )
}
