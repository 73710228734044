import React, { useContext, useEffect, useState } from 'react'
import { PngIcons, SvgIcons } from '../../../../icons'
import { AgencyContext } from '../../../../context/Agency.context';
import CustomButton from '../../../../components/CustomButton.js'
import { CircularProgress } from '@mui/material'
import PageOneSetupWalletly from './PageOneSetupWalletly';
import EarningWays from './EarningWays'
import SetupCloudWaitress from './CustomerRewards/SetupCloudWaitress';
import SetupCloudAnotherpart from './CustomerRewards/SetupCloudAnotherpart';
import ConnectedAccounts from './CustomerRewards/ConnectedAccounts';
import { useFormik } from 'formik';
import { Campaign } from '../../../../enums';
import { useHistory, useParams } from 'react-router-dom';
import {EarningWayVld, IntegrationVld} from '../../../../validation'
import { BrandService, EarningWayService } from '../../../../services';
import localforage from 'localforage';
import AddRewards from '../RewardsRedeem/AddRewards';
import Fade from 'react-reveal/Fade';

export function MainSetup() {
    const [selected, setSelected] = useState('Earning Ways');
    const [Loader, setloader]     = useState(false);
    const [index, setIndex]       = useState(0);
    const agency                  = useContext(AgencyContext);
    const params                  = useParams();
    const communicationChild      = {
        active      : false,
        referenceId : ''
    }
  

    const communication = {
        notification : communicationChild,
        sms          : communicationChild,
        email        : communicationChild,
        chatFlow     : communicationChild
    }


    const initValues = {
        loader : true ,
        earningWay  : {
            earningName      : '',
            activity         : Campaign.Activities.EARNING.SPENDING,
            earningType      : Campaign.EarningTypes.INCREMENTAL,
            earningValue     : '',
            active           : true,
            earningValueRange: [],
            description      : '',
            approvalType     : 'immediately',
            businessRules    : [],
            communication    : communication,
            iconFile         : '',
            icon             : '',
            passcodeType     : '',
            passCode         : '',
            sendSms          : false,
            validatePasscode : false,
        },
        selectedRewardIndex          : 0,
        rewards: [
            {
                name: '',
                price: '',
                description: '',
                image: '',
            }
        ],
        brandLogoFile                : '',
        walletlyUse : "cloudwaitress",
        cloudwaitress : [
            {
                name     : '',
                key      : '',
                url      : '',
                pabblyurl: '',
            }
        ],
        selectedCWIndex : 0,
    }
  


    const formik = useFormik({
        initialValues   : {...initValues},
        isInitialValid  : false,
        // validationSchema: index == 0 ? EarningWayVld.onBoardEarningWay : IntegrationVld.CloudwaitressVld 
    })

    const addNewCWFunc = (index) => {
        setSelected(state?.components[index]?.title);
        setIndex(index)
    }


    const [setup, setsetup] = useState(true);


    const Components = [
        {
            title    : 'Earning Ways',
            component:  <EarningWays formik={formik} />,
            image   :   <img src={PngIcons.earningWaysSide} className='revL w-100 mt_222 ' alt="image" />,
        },
        {
            title    : 'Setup Walletly',
            component: <PageOneSetupWalletly formik={formik} />,
            image    : <img className='revLtwo w-100 ' src={PngIcons.SetupPhone} alt="image" />
        },
        {
            title    : 'Customer Reward',
            component: <SetupCloudWaitress formik={formik} />,
            image    : <img className='w-100 mt_124 revLthree ' src={PngIcons.cloudWaitressSideImg} alt="image" />

        },
        {
            title    : 'Customer Reward',
            component: <SetupCloudAnotherpart formik={formik}  />,
            image    : <img className='w-100 mt_124 revLfour' src={PngIcons.cloudWaitressSideImgSecond} alt="image" />
        },
        {
            title    : 'Customer Reward',
            component: <ConnectedAccounts formik={formik} addNewCWFunc={addNewCWFunc}   Loader={Loader}  setloader={setloader} />,
            image    : <img className='w-100 mt_150 ml_30 revLfive'  src={PngIcons.ConnectedSideImage} />


        },


    ]

    const [state, setState] = useState({
        components  : [],
        loader      : false,
        brandCreated: '',
    })

    const history = useHistory()

    const handleNextFunc2 = async() => {
        let payload = {}
            if(index == 0){
                payload = {
                    _id              : formik.values.earningWay._id,
                    earningName      : formik.values.earningWay.earningName,
                    earningType      : formik.values.earningWay.earningType,
                    earningValue     : formik.values.earningWay.earningValue,
                    earningValueRange: formik.values.earningWay.earningValueRange,
                }
                if(formik.values.earningWay.earningType ==  Campaign.EarningTypes.RANGE){
                        delete payload.earningValue
                }
                else{
                    delete payload.earningValueRange
                }

                // console.log('payload ', payload);
                
                const {response, error} = EarningWayService.Update({payload: payload})

                // console.log('response ', response)
            }

            if(index < 4){
                if(index === 1 && formik.values.walletlyUse === 'standAlone'){
                    // console.log('asdasds');
                    history.push({
                        pathname: window.location.pathname + '/advance',
                    })
                }
                else{
                    setSelected(state?.components[index+1]?.title);
                    setIndex(index+1)
                }
            }
            else{
                // console.log('Hello Handle Submit Fund')
                payload = {
                    _id : params.id,
                    integrations : {
                        cloudwaitress : formik.values.cloudwaitress,
                    }
                }
                const { error, response } = await BrandService.Update({toaster: true, payload})
                // console.log('brand is ', response, error);
                localforage.setItem('brand', response.data);
                if(response.data){
                    // console.log('Hello Handle Submit Fund')
                    //goto final
                    // console.log('history ', history.location.pathname)
                    let url = history.location.pathname.split('/');
                    url.pop();
                    url.push('rewards')
    
                    let newUrl = url.join('/');
                    // console.log('url is', newUrl);
    
                    history.push(newUrl);
                }
            }

   
        // console.log('setup',setup);
       }

    const handleNextFunc = async() => {
        let payload = {}
            if(index == 0){
                payload = {
                    _id              : formik.values.earningWay._id,
                    earningName      : formik.values.earningWay.earningName,
                    earningType      : formik.values.earningWay.earningType,
                    earningValue     : formik.values.earningWay.earningValue,
                    earningValueRange: formik.values.earningWay.earningValueRange,
                }
                if(formik.values.earningWay.earningType ==  Campaign.EarningTypes.RANGE){
                        delete payload.earningValue
                }
                else{
                    delete payload.earningValueRange
                }

                // console.log('payload ', payload);
                
                const {response, error} = EarningWayService.Update({payload: payload, toaster : false})

                // console.log('response ', response)
            }

            if(index < 4){
                // if(index === 1 && formik.values.walletlyUse === 'standAlone'){
                if(index !== 1 && formik.values.walletlyUse === 'standAlone'){
                    // console.log('asdasds');
                    history.push({
                        pathname: window.location.pathname + '/advance',
                    })
                }
                else{
                    setSelected(state?.components[index+1]?.title);
                    setIndex(index+1)
                }
            }
            else{
                let BrandData = await localforage.getItem('brand')
                payload = {
                    _id : BrandData._id,
                    integrations : {
                        cloudwaitress : formik.values.cloudwaitress,
                    }
// Add a comment to this line
                }
                const { error, response } = await BrandService.Update({toaster: true, payload})
                if(response?.data){
                    setloader(true)
                    // console.log('Hello Handle Submit Fund')
                    //goto final
                    // console.log('history ', history.location.pathname)
                    let url = history.location.pathname.split('/');
                    url.pop();
                    url.push('rewards')
                    localforage.setItem('brand', response.data);
                    let newUrl = url.join('/');
                    // console.log('url is', newUrl);
    
                    history.push(newUrl);
                }
            }
    }
 

    useEffect(()=>{
        if(formik.values.walletlyUse === 'standAlone'){
            setState({...state, components: [
                {
                    title    : 'Earning Ways',
                    component: <EarningWays formik={formik} />,
                    image    : <img src={PngIcons.earningWaysSide} className='w-100 mt_223 revL' alt="image" />,
                },
                {
                    title    : 'Setup Walletly',
                    component: <PageOneSetupWalletly formik={formik} />,
                    image    : <img className='w-100 revLtwo' src={PngIcons.SetupPhone} alt="image" />
                },
                {
                    title: 'Add Rewards',
                    component: <AddRewards setsetup={setsetup}  setup={setup} formik={formik} />,
                    image: <img className='w-100 mt_150 revLthree' src={PngIcons.AddReward} alt="image" />
                }
            ]})
        }
        else if(formik.values.walletlyUse === 'cloudwaitress'){
            setState({...state, components: [
                {
                    title    : 'Earning Ways',
                    component: <EarningWays formik={formik} />,
                    image    : <img src={PngIcons.earningWaysSide} className='w-100 mt_223 revL' alt="image" />,
                },
                {
                    title    : 'Setup Walletly',
                    component: <PageOneSetupWalletly formik={formik} />,
                    image    : <img className='w-100 revLtwo' src={PngIcons.SetupPhone} alt="image" />
                },
                {
                    title    : 'Customer Reward',
                    component: <SetupCloudWaitress formik={formik} />,
                    image    : <img className='w-90 h-75 mt_124 revLthree' src={PngIcons.cloudWaitressSideImg} alt="image" />
                },
                {
                    title    : 'Customer Reward',
                    component: <SetupCloudAnotherpart formik={formik}  />,
                    image    : <img className='w-95 h-75 mt_124 revLfour' src={PngIcons.cloudWaitressSideImgSecond} alt="image" />
                },
                {
                    title    : 'Customer Reward',
                    component: <ConnectedAccounts formik={formik} addNewCWFunc={addNewCWFunc}  Loader={Loader}  setloader={setloader} />,
                    image    : <img className='w-100 mt_150 ml_30 revLfive'  src={PngIcons.ConnectedSideImage} />
                }]
            })
        }
        else setState({...state, components : Components})

    },[formik.values, formik.errors, index, selected, setup])

  
    const SpecialFunction = ()=>{
        setsetup(!setup)
        setSelected(state?.components[index-1]?.title);
        setIndex(index-1)
    }
    

    return (
        <>
            {state.loader ? <Loader /> :
            <div className='w-100 d-flex '>
                <div className='top-header-auth d-flex align-items-center' >
                    <img src={(agency.logo || PngIcons.authLogo)} height="32px" width="32px" className='borderRadius-60 ml_10 mr_10' alt="" />
                    {( index > 0) && <div className='cp back-text' onClick={(selected == 'Add Rewards' && setup == false) ? SpecialFunction :()=>{
                        setSelected(state?.components[index-1]?.title);
                        setIndex(index-1)
                        }}>
                        {selected != 0 && "Back"}
                    </div>}
                </div>

                <div className='w-60 d-flex bg-FFFFFF '>
                    <div className='w-100 d-flex mainSU_C1 bg-FFFFFF' id='mainSU_CU'>
                        <div className='w-20 '>

                        <div className='ParentOfDesgn'>
                            <div className='ChildOneOfDesgn'>
                                { state.components?.map((component, idx)=>formik.values.walletlyUse!=='standAlone'&&idx<=state.components.length-3&&<div className={`${(component.title == selected) ? 'specialRound' : 'simpleRound' }`} ></div>)}
                                { state.components?.map((component, idx)=>formik.values.walletlyUse==='standAlone'&&<div className={`${(component.title == selected) ? 'specialRound' : 'simpleRound' }`} ></div>)}

                            </div>
                            <div className='ChildTwoOfDesgn align-flex-start'>
                            { 
                                state.components?.map((component, idx)=>
                                formik.values.walletlyUse!=='standAlone'&&idx<=state.components.length-3&&
                                <>
                                <p className={`${(selected == component.title)  ? 'livePara' : 'unlivePara'} text-left ml_32 `}>{component.title}</p>
                                </>
                                )
                            }
                            { 
                                state.components?.map((component, idx)=>
                                formik.values.walletlyUse==='standAlone'&&
                                <>
                                <p className={`${(selected == component.title)  ? 'livePara' : 'unlivePara'} text-left ml_32 `}>{component.title}</p>
                                </>
                                )
                            }
                            </div>
                        </div>
                     
                        </div>
                        <div className='w-50 mr_102 mt_124 '>
                            {state?.components[index]?.component}
                            {(setup == true && selected != 'Add Rewards' || setup == false && selected == 'Add Rewards') && <div className="w-100 gap-12 mt_24 d-flex flex-column">
                                <CustomButton
                                    className = "w-100 hpx-52 mb_20"
                                    btntext   = "Next"
                                    onClick   = { index == 4 ? ()=>{handleNextFunc() ; setloader(true)} : ()=>{handleNextFunc()}} 
                                    disabled  = {  formik.values.walletlyUse!=='standAlone' ?( index == 4 && formik.values.cloudwaitress.length == 0) || (Loader || state.loader || formik.values.earningWay.earningValue == '' ||typeof(formik.values.earningWay.earningValue)=="string" || !formik.isValid || (index == 2 && (formik.values.cloudwaitress[formik.values.selectedCWIndex].key == "" || formik.values.cloudwaitress[formik.values.selectedCWIndex].url == "" || formik.values.cloudwaitress[formik.values.selectedCWIndex].name == "")) || (index == 0 && (formik.values.earningWay.earningType == "incremental" && formik.values.earningWay.earningValue == '' ) || (formik.values.earningWay.earningType == "range" && formik.values.earningWay.earningValueRange.length == 0 )  ||  (formik.values.earningWay.earningType == "fixed" && formik.values.TextField_v1 == '' ))): (Loader || state.loader || !formik.isValid  )  }
                                    icon={
                                        state.loader || Loader && <CircularProgress size={20} color={"inherit"} />
                                    }
                                />
                            </div>}

                        </div>
                      

                    </div>

                </div>
                <div  className={ selected == 'Earning Ways' || selected == 'Add Rewards' ?  'w-40 bg-FCFDFD' : 'w-40 vh-100 bg-FCFDFD d-flex flex-column align-items-center' }>
                    {state?.components[index]?.image}
                </div>

            </div>}
        </>
    )
}


export default MainSetup;