import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/transaction'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const BusinessCheckout = async ({payload}) => await axios.post(env.API_URL+service + '/businessCheckout', payload, {headers: { 'passCode': payload.passCode, 'id': payload.idKey }})

const SelfTransaction = async ({payload}) => await axios.post(env.API_URL+service + '/self', payload)

const GiftCardRedemption = async ({payload}) => await axios.post(env.API_URL+service + '/giftcard', payload, {headers: await MainService.getTokenHeader()})

const GiftCardRedemptionBusiness = async ({payload}) => await axios.post(env.API_URL+service + '/business/giftcard', payload,  {headers: {'id': payload.idKey }})

const redeemDealTransaction = async ({payload}) => await axios.put(env.API_URL+service + '/', payload)

const SelfSingleLoyalityTransaction = async ({payload}) => await axios.post(env.API_URL+service + '/singleLoyalitySelf', payload)

const GetFlows = async ({payload}) => await axios.post(env.API_URL+service + '/getFlows', payload, {headers : { 'Accept-Encoding': 'gzip'}})

const GetQueriedTransactions = async ({query}) => await axios.get(env.API_URL+service +'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetTransactionsbyBrandId = async ({query}) => await axios.get(env.API_URL+service+ '/getTransactionsbyBrandId' +'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetTransactionReport = async ({payload}) => await axios.post(env.API_URL+service+ '/getTransactionReport' ,payload ,  {headers: await MainService.getTokenHeader()})

const ShortUrl = async ({query}) => await axios.get(env.API_URL+service +'/shortUrl?'+utils.getQueryString(query))

const sendAppleNotification = async ({payload}) => await axios.post(env.API_URL + '/v4/services/apple/v1/notification', payload)

const TransactionService = {
    ShortUrl                      : Handlers.Services(ShortUrl),
    Create                        : Handlers.Services(Create),
    BusinessCheckout              : Handlers.Services(BusinessCheckout),
    SelfTransaction               : Handlers.Services(SelfTransaction),
    GetQueriedTransactions        : Handlers.Services(GetQueriedTransactions),
    SelfSingleLoyalityTransaction : Handlers.Services(SelfSingleLoyalityTransaction),
    GetFlows                      : Handlers.Services(GetFlows),
    redeemDealTransaction         : Handlers.Services(redeemDealTransaction),
    GiftCardRedemption            : Handlers.Services(GiftCardRedemption),
    GiftCardRedemptionBusiness    : Handlers.Services(GiftCardRedemptionBusiness),
    sendAppleNotification         : Handlers.Services(sendAppleNotification),
    GetTransactionReport          : Handlers.Services(GetTransactionReport),
    GetTransactionsbyBrandId      : Handlers.Services(GetTransactionsbyBrandId)
}

export default TransactionService                          
