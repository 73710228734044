import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ColorSchemeCode } from '../enums/ColorScheme';

export default function TextFieldSizes(props) {

  return (
    <div
      component="form"
      className='position-relative w-100'
      autoComplete='on'
      // style={{width : '100%',
      //       margin      : 0,
      //       padding     : 0,
      //       fontFamily  : 'Inter',
      //       fontSize    : '16px',
      //       fontFamily  : 'Inter',
      //       color       : '#525252',
      //       lineHeight  : '16px'
      //     , fontSize    : '16px',
      //       height      : '48px',
      //       fontFamily  : 'Inter',
      //       paddingLeft : (props.paddingLeft && (props.paddingLeft + " !important")),
      //       paddingRight: (props.paddingRight && (props.paddingRight + " !important"))
      //   }}
      // sx={{
      //   '& .MuiTextField-root': {
      //     m     : 1,
      //     width : '100%',
      //     margin: 0,
      //     padding: 0,
      //     fontFamily: 'Inter',

      //   },
      //   '& .MuiInputLabel-root':{
      //     fontSize  : '16px',
      //     fontFamily: 'Inter',
      //     color     : '#525252',
      //     lineHeight: '16px'
      //   },
      //   '& .MuiOutlinedInput-root': {
      //     '&:hover .MuiOutlinedInput-notchedOutline': {
      //       borderColor: props.helperText ? ColorSchemeCode.danger30 : ColorSchemeCode.primary50,
      //     },
      //     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      //       borderColor: props.helperText ? ColorSchemeCode.danger30 : ColorSchemeCode.primary50,
      //     },
      //   },
      //   '& .MuiInputBase-root': {
      //     fontSize     : 16,
      //     padding: 0,
      //     fontFamily: 'Inter',
      //     paddingLeft : (props.paddingLeft && (props.paddingLeft + " !important")),
      //     paddingRight : (props.paddingRight && (props.paddingRight + " !important"))


      //   },
      // }}
    >
      <TextField
        label        = {props.label}
        id           = {`${props.id}`}
        variant      = "outlined"
        style        = {{width : '100%',height : '50px', fontFamily: 'Inter', color  : '#525252'}}
        defaultValue = {props.value || props.defaultValue}
        ref          = {props.inputRef}
        name         = {props.name}
        type         = {props.type || "text"}
        disabled     = {props.disabled}
        autoComplete = {props.autocomplete ? props.autocomplete : "on"}
        helperText   = {props.helperText}
        error        = {props.error}
        focused      = {props.focused}
        inputProps   = {props.inputProps}
        className    = {`${props.className} ${props.disabled && 'disabled'}`}
        onChange     = {props.onChange || props.onBlur}
        onBlur       = {props.onChange || props.onBlur}
        background   = 'white'
        onKeyDown    = {(event)=>{
          if (event.key === 'Enter') {
            event.preventDefault();
            props.onSubmit();
          }
        }}
      />
      {props.icon && props.position == "start" && (
        <div
          className={`position-absolute Body14R wpx-50`}
          style={{ left: props.left ? `${props.left}px`: 9,right: props.right ? `${props.right}px`:16, top: props.top ? `${props.top}px` :12}}
        >
          {props.icon}
        </div>
      )}


      {props.icon && props.position == "end" && (
        <div
          className={`position-absolute Body14R`}
          style={{ right: props.left ? `${props.left}px`:16, top: props.top ? `${props.top}px` :14  }}
        >
          {props.icon}
        </div>
      )}
    </div>
  );   
 
}