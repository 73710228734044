import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ColorSchemeCode } from '../../enums/ColorScheme';
import { RedemptionType, Triggers } from '../../enums/enums';
import {  SvgIcons } from '../../icons';
import { DealService, TransactionService } from '../../services';
import CustomButton from '../../components/CustomButton'
import CustomsModal from '../../components/CustomModal';
import RedeemModal from '../../components/RedeemModal';
import { AgencyContext } from '../../context/Agency.context';
import { utils } from '../../utils';
import { GrowthTools } from '../../enums';


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Skeleton } from '@mui/material';


function LandingPageDealNew(props) {
    const agency = useContext(AgencyContext)
    const history = useHistory();
    const params = useParams()
    // window.onbeforeunload = function (e) {
    //     if (e) e.returnValue = history.push('/page/'+params.growthtoolId)
    //     return history.push('/page/'+params.growthtoolId);
    // };

    const [state, setState] = useState({
        loader     : false,
        apiError   : '',
        modal      : false,
        modalLoader: false,
        availDeal  : ''
    })


   

    const [selectedIndex, setSelectedIndex] = useState(0)

    const data = history.location.state
   
    const [finalData, setFinalData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [downloadQrLoader, setDownloadQrLoader] = useState(false);
    const [email , setEmail] = useState('');
    const [points , setPoints] = useState('');

    const handleDeal = async (value) => {
        setState({...state, modal : true, availDeal: value});
        return
    }

    // console.log(' history.location.state.payload ',  history.location.state);


    const onLoad = async()=> {
        setLoader(true);
       
        localforage.setItem('flowIds', history.location.state.flowIdArray);
        let payload = {brandId: history.location.state.payload.brandId, passId: history.location.state.payload.passId, flowIds: history.location.state.flowIdArray}
       
        const flows = await TransactionService.GetFlows({payload});
        // console.log('======== flows ======', flows);
        let flowDeals = flows.response.data;
        const query = {brandId: history.location.state.payload.brandId, delete: false}
        const {response, error} = await DealService.Get({query});
        let dealsData = [];
        const deals = await localforage.getItem('deals');
        response && response.data.map((element, idx)=>{
            flowDeals.map((deal, idx)=>{
                if(element.name === deal.deal)
                {  
                    if(deals && deals.length > 0)
                    {
                        delete element.valid
                        let checkDeal = deals.filter((d, idx)=>{ if(d.name === element.name) return true;})
                        if(!checkDeal || checkDeal.length === 0) dealsData.push({...deal, ...element, avail: deal.avail ? deal.avail : false, startDate: new Date(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
                    }
                    else
                    {
                        delete element.valid
                        dealsData.push({...deal, ...element, avail: deal.avail ? deal.avail : false, startDate: new Date().valueOf(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
                    }
                }
            })
        })

        flowDeals.map((element,idx)=>{
            if(element.discountType)dealsData.push({...element, _id : element.id});
        })
        // console.log('dealsData1', dealsData);

        let dataSorting;
        if(deals && deals.length ) dataSorting = [...deals,...dealsData];
        else dataSorting = [...dealsData];
        
        dataSorting.sort(function(a, b) {
            var keyA = a.conditionValue; var keyB = b.conditionValue;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });       

        setFinalData([...dataSorting])
        localforage.setItem('deals', [...dataSorting]);
        localforage.setItem('transaction', data.transaction);
        localforage.setItem('brand', data.brand);
        setLoader(false);
    }

    useEffect(onLoad, [])

    const qrcode = {
        text        : `${(window.location.host.includes('localhost') ? window.location.host : 'https://'+window.location.host)}/page/businesscheckout/${history.location.state.brand._id}/${history.location.state.result.passCode}/${history.location.state.flowIdArray[0]}`,
        size      : 250,
        typeNumber: 10,
    }

    const tempName = history.location.state.payload.firstName.split(" ")
    const name = tempName.join("%20")
    const qrcodeApp = {
        size      : 250,
        typeNumber: 10,
        // text        : (window.location.host.includes('localhost') ? window.location.host : 'https://'+window.location.host)+"/page/businesscheckout/?brandId="+history.location.state.brand._id+"&fullName="+name+"&passId="+history.location.state.payload.passId+"&passCode="+history.location.state.result.passCode+"&flowId="+history.location.state.flowIdArray[0],
        text        : (window.location.host.includes('localhost') ? window.location.host : 'https://'+window.location.host)+"/page/businesscheckout/?brandId="+history.location.state.brand._id+"&fullName=customer&passId="+history.location.state.payload.passId+"&passCode="+history.location.state.result.passCode+"&flowId="+history.location.state.flowIdArray[0],
    }

    const downloadQr = () => {
        setDownloadQrLoader(true)
        setTimeout(() => {
            const canvas = document.getElementById("react-qrcode-logo");
                const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
                let downloadLink = document.createElement("a");
                downloadLink.href = pngUrl;
                downloadLink.download = "Qrcode.jpg";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            setDownloadQrLoader(false)
        }, 5000);
    };


    const handleOnClaimPointFunc = () => {
        // console.log('data ', data);
        if(data.redemptionType === RedemptionType.SELF){
            history.push({pathname:'/page/'+params.growthtoolId+'/points', state: {...history.location.state}})
        }
        else{
            history.push({pathname:'/page/'+params.growthtoolId+'/qrcode', state: {...history.location.state, qrCode: qrcode, qrCodeApp: qrcodeApp}})
        }
    }


    const handleRedeem = async () => {
        setState({...state, modalLoader : true});
       
        if(state.availDeal) {
            let payload = {...history.location.state.payload, availDeal: state.availDeal,  points: 0, flowId: history.location.state.flowIdArray}
            if(state.availDeal.condition === 'point') payload.pointUsed = state.availDeal.conditionValue
            else payload.pointUsed = 0;

            delete payload.firstName
            delete payload.agencyId;

            // console.log('Deal payload', payload);
            const {response, error} = await TransactionService.redeemDealTransaction({payload})
                        
            localforage.removeItem('deals');
            localforage.removeItem('email');
            history.push({pathname:'/page/'+params.growthtoolId})
        }
        localforage.removeItem('deals');
        localforage.removeItem('email');
        setState({...state, modalLoader : false});
    }


    return (
        <div id="DealPageOld"> 
        {/* {console.log('finalData', finalData)} */}
            {loader ? 
            
                (window.screen.width <= 500) ? 
                <div className='middle bg-color-white'>
                    <div className='position-absolute' style={{top: '20px', right: '20px'}}><Skeleton height={50} width={80}/></div>
                    <div className='w-100 middle mt_50'><Skeleton height={100} width={60}/></div>
                    <div style={{marginTop:'-15px'}}><Skeleton height={40} width={280}/></div>
                    <div style={{marginTop:'-5px'}}><Skeleton height={60} width={120}/></div>
                    <div className='w-100 bg-color-cF6F6F9' style={{ padding:'16px 24px', height: '100vh'}}>
                        <div className='position-relative' style={{boxShadow: '0px 4px 5px rgb(109 110 111 / 10%)', padding: '12px',  backgroundColor: 'white', borderRadius : '4px', marginBottom: '24px'}}> 
                            <div className='row position-relative'>
                                <div className='col-5'>
                                    <Skeleton height={40} width={100}/> 
                                    <Skeleton height={25} width={180}/> 
                                </div>
                                <div className='col-2' style={{right: '24', position:'absolute', top: '-22px', right: '30px'}}>
                                    <Skeleton height={100} width={60}/> 
                                </div>
                            </div>
                        </div>
                        <div className='position-relative' style={{boxShadow: '0px 4px 5px rgb(109 110 111 / 10%)', padding: '12px',  backgroundColor: 'white', borderRadius : '4px', marginBottom: '24px'}}> 
                            <div className='row position-relative'>
                                <div className='col-5'>
                                    <Skeleton height={40} width={100}/> 
                                    <Skeleton height={25} width={180}/> 
                                </div>
                                <div className='col-2' style={{right: '24', position:'absolute', top: '-22px', right: '30px'}}>
                                    <Skeleton height={100} width={60}/> 
                                </div>
                            </div>
                        </div>
                        <div className='position-relative' style={{boxShadow: '0px 4px 5px rgb(109 110 111 / 10%)', padding: '12px',  backgroundColor: 'white', borderRadius : '4px', marginBottom: '24px'}}>
                            <div className='row position-relative'>
                                <div className='col-5'>
                                    <Skeleton height={40} width={100}/> 
                                    <Skeleton height={25} width={180}/> 
                                </div>
                                <div className='col-2' style={{right: '24', position:'absolute', top: '-22px', right: '30px'}}>
                                    <Skeleton height={100} width={60}/> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                : 
              
              <div id="NewDealPage" className='middle'>
                    <div className='middle dealOuterCard position-relative pb_40'>
                        <div className='w-fit-content logoutButton'><Skeleton height={50} width={80}/></div>
                        <div className="webScreen middle"><Skeleton height={100} width={60}/></div>
                        <div style={{marginTop:'-15px'}}><Skeleton height={60} width={320}/></div>
                        <div style={{marginTop:'-15px'}}><Skeleton height={80} width={120}/></div>
                        <div style={{marginTop:'-60px'}}><Skeleton height={333} width={200}/></div>
                        <div style={{position: 'absolute', bottom : '-16px'}}><Skeleton height={100} width={480}/></div>
                    </div>
              </div> 
            
            
                 
            :
      
    

            <>
                    {agency&&agency._id&&agency._id==="UA95bkhlgEIkHuEUCXq" ? 
                    <div style={{height: '90vh'}} className='middle'>
                        <div className="d-flex align-items-center space-between pl_16 pr_16 pb_4 pt_16" >
                            <img src={agency.logo} width={48} height={48} className="borderRadius-40" alt=""></img>
                        </div>
                        <div className="Heading22M mb_16 mt_16 color-GeneralBlack text-center">Thanks for signing up <br/> {utils.capitalizeAll(history.location.state.payload.firstName)+" 👋"}</div>
                        <div className='Heading16M color-neutral100'>Check in below to claim your points.</div>
                        <div className='Heading16M color-neutral100'>Use App to redeem rewards</div>
                        <a style={{textAlign: 'center'}} href='http://lnk.shiretalk.com.au/download-app'>http://lnk.shiretalk.com.au/download-app</a>
                    </div> 
                    
                    : 

                    <div>
                        <div id="NewDealPage" className='middle'>
                                <div className='middle dealOuterCard position-relative pb_40'>
                                    <div className='installCard w-fit-content' onClick={()=>{(window.location.hostname.includes('localhost')) ? window.open('http://localhost:3000/pass/'+ history.location.state.result.passCode+'?flowId='+history.location.state.flowIdArray[0], '_blank') : window.open('https://'+window.location.hostname+'/pass/'+ history.location.state.result.passCode, '_blank')}}>
                                        Install Card
                                    </div>
                                    <div className='w-fit-content logoutButton' onClick={()=>{
                                        localforage.removeItem('pass');
                                        localforage.removeItem('username');
                                        localforage.removeItem('flowIds');
                                        localforage.removeItem('deals');
                                        localforage.removeItem('email');
                                        history.push({pathname: '/page/'+params.growthtoolId});
                                    }}>Logout</div>
                                    <div className="webScreen middle">
                                        <img 
                                        src       = {history.location.state.brand.brandLogo}
                                        height    = "64px"
                                        width     = "64px"
                                        className = 'borderRadius-60 mb_24'
                                        alt       = ""
                                    />
                                    <div className='color-neutral60 Body14R'>Claim your point by availing deals applicable.</div>
                                    {(history.location.state&&history.location.state.growthTool&&history.location.state.growthTool.points&&history.location.state.growthTool.points===true)?<></>:<div className='mt_16 pointsBox color-neutral100 position-relative fs-14 pt_12'>
                                        <div className='giftBoxLogo'><SvgIcons.GiftIcon/></div>
                                            {points || history.location.state.result[history.location.state.result.passType].currentPoints} Points
                                    </div>}
                                    <Carousel 
                                        className='mt_32' 
                                        showArrows 
                                        showStatus 
                                        infiniteLoop 
                                        showIndicators 
                                        showThumbs = {false}
                                        swipeable 
                                        useKeyboardArrows 
                                        width  = {310}
                                        height = {200}
                                        onChange={(selectedIndex)=> setSelectedIndex(selectedIndex)}
                                    >
                                        {
                                        finalData.length > 0 && finalData.map((value, index)=>
                                            <div className={`cp ${!value.valid && 'disabled'}`} onClick={()=>{
                                                data.growthTool.growthtoolType === GrowthTools.GrowhttoolTypes.POS ?  console.log('') :
                                                (data.redemptionType === RedemptionType.CASHIER ?  history.push({pathname:'/page/'+params.growthtoolId+'/qrcode', state: {...history.location.state, dealDetail: value}}) : handleDeal(value) )
                                                }}>
                                                <img width={240} height={200} src={value.image} alt=""/>
                                            </div>
                                        )}
                                    
                                    </Carousel>
                                    
                                    <div className={`Heading16M color-neutral100 mt_8 mb_4 ${!finalData[selectedIndex]?.valid && 'disabled'}`}>{finalData[selectedIndex]?.name}</div>  
                                    <div className={`Body14R color-Paragaph text-center mb_8 fs-12 desciprtionText ${!finalData[selectedIndex]?.valid && 'disabled'}`}>{finalData[selectedIndex]?.description}</div>
                                
                                    <div className={`mb_48 ${!finalData[selectedIndex]?.valid && 'disabled'}`}>
                                        { finalData[selectedIndex]?.condition === 'visit'?
                                            <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{finalData[selectedIndex]?.conditionValue + ' ' + finalData[selectedIndex]?.condition} </div>
                                            </div>
                                            :
                                            finalData[selectedIndex]?.condition === 'event'?
                                            <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{finalData[selectedIndex]?.conditionValue.event + ' ' + finalData[selectedIndex]?.condition}</div>
                                            </div>
                                            :
                                            finalData[selectedIndex]?.condition === 'onetime'?
                                            <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{finalData[selectedIndex]?.condition}</div>
                                            </div>
                                            :
                                            <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                             {
                                                finalData[selectedIndex]?.condition.map((element, idx)=>(
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>
                                                        {element.condition + ' ' + element.conditionValue[0] + ' ' + (element.conditionValue[1] ? element.conditionValue[1] : '')}
                                                    </div>
                                                ))
                                             }
                                            </div>
                                        } 
                                    </div>
                                    // {console.log('claim ', data.growthTool.disableClaimPoint)}
                                    {(data.growthTool.disableClaimPoint === 'false' || !data.growthTool.disableClaimPoint) ? <div className={((data.growthTool.growthtoolType === GrowthTools.GrowhttoolTypes.POS) || (agency && agency._id && agency._id==="UA95bkhlgEIkHuEUCXq")) ? "d-none" : ""}>
                                        <div onClick={handleOnClaimPointFunc} className={(history.location.state.disable) ? "d-none" : "Link13M color-white cp claimButton w-100"}>
                                            <CustomButton 
                                                btntext   = {'Claim Points'}
                                                className = "w-100 ClaimBtn"
                                                height    = "56px"
                                            />
                                        </div>
                                    </div>
                                    
                                    :
                                    
                                    ''
                                    }
                                    </div>
                                </div>
                            </div>
                    
                    <div className="mobileScreen">
                        <div className="topBox">
                            <div className='installCard w-fit-content' onClick={()=>{(window.location.hostname.includes('localhost')) ? window.open('http://localhost:3000/pass/'+ history.location.state.result.passCode+'?flowId='+history.location.state.flowIdArray[0], '_blank') : window.open('https://'+window.location.hostname+'/pass/'+ history.location.state.result.passCode, '_blank')}}>
                                    Install Card
                            </div>
                            <div className='w-fit-content logoutButton' onClick={()=>{
                                localforage.removeItem('pass');
                                localforage.removeItem('username');
                                localforage.removeItem('flowIds');
                                localforage.removeItem('deals');
                                localforage.removeItem('email');
                                history.push({pathname: '/page/'+params.growthtoolId});
                            }}>Logout</div>
                            <div className={"middle"}>
                                <img 
                                    src       = {history.location.state.brand.brandLogo}
                                    height    = "72px"
                                    width     = "72px"
                                    className = 'borderRadius-60'
                                    alt       = ""
                                />
                                <div className='color-neutral60 Body14R mt_24'>Claim your point by availing deals applicable.</div>
                            </div>

                            <div className='d-flex justify-content-center'>
                                    {(history.location.state&&history.location.state.growthTool&&history.location.state.growthTool.points&&history.location.state.growthTool.points===true)?<></>:<div className='mt_16 pointsBox color-neutral100 position-relative fs-14 pt_12 w-fit-content text-center'>
                                        <div className='giftBoxLogo'><SvgIcons.GiftIcon/></div>
                                            {points || history.location.state.result[history.location.state.result.passType].currentPoints} Points
                                    </div>}
                            </div>
                        </div>


                        {/* <div className="totalPoints d-flex borderRadius-50 w-fit-content pt_8 pb_8 pl_16 pr_16"  style={{backgroundColor: '#FFF1C5'}}>
                        <div className="mr_8">
                                <SvgIcons.LandingPagePoinIcon />
                        </div>
                        
                            <div classname="Heading22M ml_8 middle pt_16" style={{marginTop: 2, color : '#202124 !important'}}>
                                {points || history.location.state.result[history.location.state.result.passType].currentPoints} Points
                            </div>
                        </div> */}

                        <div className="lowerBox" style={{overflow: 'auto', marginBottom:'100px'}}>
                            {
                                finalData.length > 0 ? finalData.map((value, index)=>(
                                    <>
                                    { 
                                    // (!value.avail) ? 
                                
                                (value.valid) ? 
                                    <div className="mb_24 d-flex space-between singleDealRow" onClick={()=>{
                                        data.growthTool.growthtoolType === GrowthTools.GrowhttoolTypes.POS ?  console.log('') :
                                        (data.redemptionType === RedemptionType.CASHIER ?  history.push({pathname:'/page/'+params.growthtoolId+'/qrcode', state: {...history.location.state, dealDetail: value}}) : handleDeal(value) )
                                        }}>
                                        <div style={{width : '70%'}}>
                                            <div className="d-flex flex-column space-between h-100">
                                                <div className="pr_16">
                                                    <div className="capitalize Heading16M color-neutral100 mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.name}</div>
                                                    <div className="Caption12M color-neutral60 verticalEllipses mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.description}</div>
                                                </div>
                                                { value.condition === 'visit'?
                                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition} </div>
                                                </div>
                                                :
                                                value.condition === 'event'?
                                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue.event + ' ' + value.condition}</div>
                                                </div>
                                                :
                                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition}</div>
                                                </div>
                                                }
                                            </div>
                                        </div>  
                                        <div className="middle">
                                            <img src={value.image} height="60px" width="60px" alt="" className="object-fit-cover borderRadius-4"  />
                                            {/* <div className="backgroundCover" style={{ position: 'relative', width: '60px', height : '60px', backgroundImage: "url('"+value.image+"')",backgroundPosition: 'center', backgroundSize: 'cover' }}></div> */}
                                        </div>
                                        
                                        
                                        {/* <div className="" style={{ position: 'relative', width: '100%', paddingTop: '58.54%', backgroundImage: "url('"+value.image+"')",backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                        <div className="d-flex space-between mt_4">
                                            <div className="mt_12 Heading16M color-neutral100 w-60" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.description}</div>
                                            { value.condition === 'visit'?
                                            <div className="d-flex space-between" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                <SvgIcons.VisitDealIcon />
                                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition} </div>
                                            </div>
                                            :
                                            <div className="d-flex space-between" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                <SvgIcons.PointDealIcon />
                                                <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition}</div>
                                            </div>
                                            }
                                        </div> */}
                                    </div>

                                    :

                                    <div className="mb_24 position-relative disabled d-flex space-between singleDealRow" onClick={()=>{
                                        data.growthTool.growthtoolType === GrowthTools.GrowhttoolTypes.POS ?  console.log('') : 
                                        history.push({pathname:'/page/'+params.growthtoolId+'/deal', state: {...history.location.state, dealDetail: value}})
                                        }}>
                                        <div style={{width : '70%'}}>
                                            <div className="d-flex flex-column space-between h-100">
                                                <div className="pr_16">
                                                    <div className="capitalize Heading16M color-neutral100 mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.name}</div>
                                                    <div className="Caption12M color-neutral60 verticalEllipses mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.description}</div>
                                                </div>
                                            
                                                {
                                                    value.condition === 'event'?
                                                    <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue.event + ' ' + value.condition}</div>
                                                    </div>
                                                    :
                                                    <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition}</div>
                                                    </div>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="middle">
                                            {/* <div className="backgroundCover" style={{ position: 'relative', width: '100px', height:'100px', background: 'url("'+value.image+'")',backgroundPosition: 'center', backgroundSize: 'cover' }}></div> */}
                                            <img src={value.image} height="60px" width="60px" alt="" className="object-fit-cover borderRadius-4"  />
                                            {/* <div className="color-white Link14M">{'Need ' + ((value.condition !== 'visit') ? (value.conditionValue - history.location.state.result[history.location.state.result.passType].currentPoints).toString() : value.conditionValue ) + ' ' + value.condition +' to unlock'}</div> */}
                                        </div>
                                    </div>

                                    
                                        }

                                    <CustomsModal 
                                        open      = { state.modal }
                                        minWidth  = {'160px'}
                                        component = {<RedeemModal icon={state.modalLoader} title="Redeem" onCancel={()=> setState({...state,modal: false})} onDelete={handleRedeem}/>}
                                        onClose   = {()=> setState({...state,modal: false})}
                                    />
                                
                                
                                    </>
                                ))

                                : 
                                <div className=''>
                                No Deals Found
                                </div>
                            }
                        </div>
                    </div>
                </div>}
            </>
                    
         }

            {/* || (data.flow&&data.flow.triggerOption&&data.flow.triggerOption==="Event Base") */}
            {(data.growthTool.disableClaimPoint === 'false' || !data.growthTool.disableClaimPoint ) ? <div className={((data.growthTool.growthtoolType === GrowthTools.GrowhttoolTypes.POS) || (agency && agency._id && agency._id==="UA95bkhlgEIkHuEUCXq")) ? "claimBtn d-none" : "claimBtn"}> 
                <div style={{position: 'fixed', bottom : 0, left: 0, width: '100%', height : '60px', backgroundColor : ColorSchemeCode.themeColor }} onClick={handleOnClaimPointFunc} className={(history.location.state.disable) ? "d-none Link13M color-white cp" : "Link13M color-white cp"}>
                    <div className="middle h-100">
                        Claim Points
                    </div>
                </div>
            </div>
            : ''}
        </div>
    )
}

export default LandingPageDealNew
