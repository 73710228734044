// import localforage from 'localforage';
// import React, { useContext, useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import CustomButton from '../../../../components/CustomButton';
// import CustomsModal from '../../../../components/CustomModal';
// import CustomSearch from '../../../../components/CustomSearch'
// import DeleteModal from '../../../../components/DeleteModal';
// import ViewDetail from '../../../../components/ViewDetail';
// import { AgencyContext } from '../../../../context/Agency.context';
// import { BrandContext } from '../../../../context/Brand.context';
// import { LayoutContext } from '../../../../context/Layout.context';
// import { SvgIcons } from '../../../../icons'
// import { CampaignService, EarningWayService, FlowService } from '../../../../services';
// import localForage from 'localforage'
// import Loader from '../../../../components/Loader';
// import { Campaign } from '../../../../enums';

// function RewardBuilderListing() {

//     const [filteredData, setFilteredData] = useState();
//     const [response, setResponse] = useState();
//     const [dropIdx, setDropIdx] = useState();
//     const [viewMore, setViewMore] = useState(false);
//     const [earningWays, setEarningWays] = useState([]);
//     const [loader, setLoader] = useState(true);

//     const history = useHistory()

//     const brand = useContext(BrandContext)
//     const agency = useContext(AgencyContext)
//     const layout = useContext(LayoutContext)

//     const handleEventSearchFunc = (e) => {
//         const SearchResult = response.filter(element => 
//         {
//             return element.name.toLowerCase().includes(e.target.value.toLowerCase())
//         });
//         setFilteredData([...SearchResult])
//     }
//     console.log('int',brand);

//     useEffect(async() => {
//         let campaign = await localforage.getItem('campaign');
//         if((!campaign) || campaign.length === 0){
//             const ccampaign = await CampaignService.GetPublicCampaign({query:{brandId: brand._id, delete: false}})
//             if(ccampaign.response?.data?.length>0) campaign = ccampaign.response.data[0]
//         }
//         // let ways = await localforage.getItem('selectedLoyalityEarningWays');
//         let ways = await localforage.getItem('earningways');
//         if((!ways) || ways.length === 0){
//             const wways = await EarningWayService.GetQueriedEarningWaysPublic({query: {campaignId: campaign[0]?._id || campaign?._id ,brandId: brand._id}})
//             console.log('wways ',wways)
//             if(wways.response?.data?.length>0) ways = wways.response.data
//             if(wways.error && wways.error.error === "no earning ways found"){
//               const payload = {
//                 brandId     : brand._id,
//                 brandType   : brand.brandType,
//                 campaignId  : campaign[0]?._id || campaign?._id,
//                 campaignType:campaign[0]?.campaignType ||  campaign?.campaignType,
//               }
//               // if(brand.brandRedemptionType && brand.brandRedemptionType === RedemptionType.ORDER) payload.cloudwaitress = true
//               // console.log('====================================');
//               // console.log('CreatePreBuiltEarningWays', payload);
//               // console.log('====================================');
//               const { error, response } = await EarningWayService.CreatePreBuiltEarningWays({payload})
//               if(response?.data?.length>0) ways = response.data
//             }
//             // if(wways.response?.data?.length>0) setEarningWays(wways.response.data)
//         }

//         console.log('ways', ways);
        
//         if(ways && ways.length>0) setEarningWays(ways)

//         let flow = await localforage.getItem('flow');
//         if((!flow) || flow.length === 0){
//             const {response, error} = await FlowService.Get({query: {brandId: brand._id, delete: false, flowType : 'new'}})
//             // const {response, error} = await FlowService.Get({query: {brandId: brand._id, delete: false}})
//             if(response){
//                 localforage.setItem('flow', response.data);
//                 flow = response.data
//             }
//         }
//         // console.log('====================================');
//         // console.log(flow);
//         // console.log('====================================');

//         setResponse(flow)
//         setFilteredData(flow)
//         setLoader(false)
       
//     }, [])

//     useEffect(()=>{
//         layout.setLayout({
//             borderBottom: false,
//             edit        : false,
//             switch      : "",
//             // backTitles  : ["Loyalty Suite"],
//             title       : "Automation",
//             button      : <CustomButton 
//                             varient = {"primary"} btntext = {"Add Rule"} onClick = {()=>{
//                                 history.push({
//                                     pathname: window.location.pathname + '/rewardadd',
//                                     state: filteredData?.length>0 ? { ...filteredData[0], index: filteredData[0].flow.length, delete: true } : {index: 0, new: true, delete: true}
//                                 })
//                             }} />
//         })
//     },[filteredData])

//     console.log("earning Way ",earningWays);
    

//   return (
//     <div>
//         {
//             loader? <Loader baseHeight={"60vh"} width={120} height={120} /> : 
//             <div className="pt_16 pb_16 pl_50 pr_40">
//               <div className='d-flex pt_14 pb_14 pl_30 pr_30 cp w-100 color-neutral90 Link12M audienceTableGrid overflow-hidden'>
//                 <div className='col' 
//                 >
//                     {/* {state?.loyaltyBuilder?.length+" FLOW"} */}
//                     Name
//                 </div>
//                 <div className='col-4 Link11M'>
//                         {'Last Updated'}
//                     </div>
//               </div>
//               {console.log('earningWays',earningWays)}
//               {earningWays&&earningWays.length>0&&earningWays.map((element, idx) => (
//                 ((element.activity === Campaign.Activities.EARNING.BIRTHDAY && !element.active) ||
//                 (element.activity === Campaign.Activities.EARNING.CHECKIN && !element.active))? <></>:
//                 <>
//                 {console.log(element.activity)}
//                   <div
//                     key={idx}
//                     className={
//                       "row align-items-center tableRowNew pt_26 pb_26 pl_30 pr_30 tableRowHover singleProgram cp m_0 "
//                     }
//                     onClick={()=>{
//                       history.push({
//                         pathname: window.location.pathname + '/earn',
//                         state: element ,
//                     })
//                     }}
//                   >
//                     <div className="col">
//                         <div className='Heading15M d-flex align-items-center color-neutral70'>
//                             {element.earningName}
//                             {
//                           element.active ? <div className="TierActive ml_5"><div></div>{"Active"}</div>  :  <div className="TierDeActive ml_5"><div></div>{"Deactive"}</div>
//                         }
//                         </div>
//                     </div>
//                     <div className='col-4 color-neutral90 Link13R'>
//                         {new Date(element.updated || element.created).toDateString()}
//                     </div>
//                   </div>
//                 </>
//               ))}
//               {filteredData?.length>0&&filteredData.map((element, idx) => (
//                 element?.flow?.length>0&&element.flow.map((ele, ind) => (
//                 <>
//                   <div
//                     key={ind}
//                     className={
//                       "row align-items-center pt_26 pb_26 pl_30 pr_30 tableRowNew tableRowHover singleProgram cp m_0"
//                     }
//                     onClick={()=>{
//                       history.push({
//                         pathname: window.location.pathname + '/rewardadd',
//                         state: {...element, index: ind} 
//                     })
//                     }}
//                   >
//                     <div className="col">
//                         <div className='Heading15M color-neutral100'>
//                             {ele.block || element.name}
//                         </div>
//                     </div>
//                     <div className='col-4 color-neutral90 Link13R'>
//                       {new Date(element.updated || element.created ).toDateString()}
//                     </div>
//                   </div>
//                 </>
//               ))
//               ))
//             }
//             </div>
//         }
          
//     </div>
//   )
// }

// export default RewardBuilderListing





import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CustomButton from '../../../../components/CustomButton';
import CustomsModal from '../../../../components/CustomModal';
import CustomSearch from '../../../../components/CustomSearch'
import DeleteModal from '../../../../components/DeleteModal';
import ViewDetail from '../../../../components/ViewDetail';
import { AgencyContext } from '../../../../context/Agency.context';
import { BrandContext } from '../../../../context/Brand.context';
import { LayoutContext } from '../../../../context/Layout.context';
import { SvgIcons } from '../../../../icons'
import { CampaignService, EarningWayService, FlowService } from '../../../../services';
import localForage from 'localforage'
import Loader from '../../../../components/Loader';
import { Campaign } from '../../../../enums';

function RewardBuilderListing() {

  const [filteredData, setFilteredData] = useState();
  const [response, setResponse] = useState();
  const [dropIdx, setDropIdx] = useState();
  const [viewMore, setViewMore] = useState(false);
  const [earningWays, setEarningWays] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState('all');
  const [communication, setCommunication] = useState([]);
  const [refferal, setRefferal] = useState([]);
  const [activity, setActivity] = useState([]);
  const [loyalty, setLoyalty] = useState([]);

  const [communicationClicked, setCommunicationClicked] = useState(false);
  const history = useHistory()

  const brand = useContext(BrandContext)
  const agency = useContext(AgencyContext)
  const layout = useContext(LayoutContext)


  const handleItemClick = (item) => {
    setSelectedItem(item);
  };


  const handleEventSearchFunc = (e) => {
    const SearchResult = response.filter(element => {
      return element.name.toLowerCase().includes(e.target.value.toLowerCase())
    });
    setFilteredData([...SearchResult])
  }
  console.log('int', brand);

  useEffect(async () => {
    let campaign = await localforage.getItem('campaign');
    if ((!campaign) || campaign.length === 0) {
      const ccampaign = await CampaignService.GetPublicCampaign({ query: { brandId: brand._id, delete: false } })
      if (ccampaign.response?.data?.length > 0) campaign = ccampaign.response.data[0]
    }
    // let ways = await localforage.getItem('selectedLoyalityEarningWays');
    let ways = await localforage.getItem('earningways');
    if ((!ways) || ways.length === 0) {
      const wways = await EarningWayService.GetQueriedEarningWaysPublic({ query: { campaignId: campaign[0]?._id || campaign?._id, brandId: brand._id } })
      console.log('wways ', wways)
      if (wways.response?.data?.length > 0){ ways = wways.response.data
        localForage.setItem('earningways', ways) }
      if (wways.error && wways.error.error === "no earning ways found") {
        const payload = {
          brandId: brand._id,
          brandType: brand.brandType,
          campaignId: campaign[0]?._id || campaign?._id,
          campaignType: campaign[0]?.campaignType || campaign?.campaignType,
        }
        // if(brand.brandRedemptionType && brand.brandRedemptionType === RedemptionType.ORDER) payload.cloudwaitress = true
        // console.log('====================================');
        // console.log('CreatePreBuiltEarningWays', payload);
        // console.log('====================================');
        const { error, response } = await EarningWayService.CreatePreBuiltEarningWays({ payload })
        if (response?.data?.length > 0) ways = response.data
      }
      // if(wways.response?.data?.length>0) setEarningWays(wways.response.data)
    }

    console.log('ways', ways);

    if (ways && ways.length > 0) setEarningWays(ways)

    let flow = await localforage.getItem('flow');
    console.log('mskingcommunivstion', flow);

    if ((!flow) || flow.length === 0) {
      const { response, error } = await FlowService.Get({ query: { brandId: brand._id, delete: false, flowType: 'new' } })
      // const {response, error} = await FlowService.Get({query: {brandId: brand._id, delete: false}})
      console.log('loyalty Xoop Jeep', response, error);


      if (response) {
        localforage.setItem('flow', response.data);
        flow = response.data
      }
    }




    setResponse(flow)
    setFilteredData(flow)
    AllFilteredArray(flow)
    setLoader(false)
  }, [])

  useEffect(() => {
    layout.setLayout({
      borderBottom: false,
      edit: false,
      switch: "",
      // backTitles  : ["Loyalty Suite"],
      // title: "Automation",
      title: "Launch Pad",
      button: <CustomButton
        varient={"primary"} btntext={"Add Rule"} onClick={() => {
          filteredData[0].flow.push({
            "state": [
                // {
                //     // "name": "Referral reward for sender",
                //     // "key": "referral",
                //     // "img": "/images/rewardBuilder/oneTimeDeal.png",
                //     // "description": "It triggers when a customer Reffered numbers of customer",
                //     // "type": "activity",
                //     // "customer": "1"
                // }
            ],
            "state1": [
                // {
                //     "name": "Add Tag",
                //     "key": "addTag",
                //     "img": "/images/rewardBuilder/addTag.png",
                //     "description": "Description",
                //     "addTag": "s"
                // },
                // {
                //     "name": "Give Points",
                //     "key": "givePoint",
                //     "img": "/images/rewardBuilder/addPoints.png",
                //     "description": "Description",
                //     "givePoints": "4"
                // }
            ],
            "block": "rule",
            "active": true
        })

        setFilteredData([...filteredData])
          history.push({
            pathname: window.location.pathname + '/rewardadd',
            state: filteredData?.length > 0 ? {
               ...filteredData[0], index: (filteredData[0].flow.length-1),
              //  delete: true 
              //  state: { ...element, index: ind }
              } : { index: 0, new: true, delete: true }
          })
        }} />
    })
  }, [filteredData])

  const AllFilteredArray = (flow) => {
    if ( flow && flow[0]?.flow && flow[0]?.flow?.length >= 1) {

      var communicationArray = []
      var loyaltyArray = []
      var ReferalArray = []
      var ActivityArray = []
      //rewardDeal
      //event

      for (let i = 0; i < flow[0]?.flow?.length; i++) {
        for (let j = 0; j < flow[0].flow[i].state1.length; j++) {
          console.log('insideNewFuction', i, flow[0].flow[i].state1[j].key)

          if (flow[0].flow[i].state1[j].key == "sendSms") {
            communicationArray.push(flow[0].flow[i])
          }
          if (flow[0].flow[i].state1[j].key == "rewardDeal" || flow[0].flow[i].state1[j].key == "deductPoint" || flow[0].flow[i].state1[j].key == "givePoint" || flow[0].flow[i].state1[j].key == "bonusPoints") {
            loyaltyArray.push(flow[0].flow[i])
          }

        }
        for (let j = 0; j < flow[0].flow[i].state.length; j++) {
          console.log('insideNewFuction', i, flow[0].flow[i].state[j].key)


          if (flow[0].flow[i].state[j].key == "referrallogin" || flow[0].flow[i].state[j].key == "referral") {
            ReferalArray.push(flow[0].flow[i])
          }
          if (flow[0].flow[i].state[j].key == "event" || flow[0].flow[i].state[j].key == "dealStatus" ) {
            ActivityArray.push(flow[0].flow[i])
          }
          

        }

      }
      console.log("insideNewFuction loyaltyArray", loyaltyArray)
      console.log("insideNewFuction communicationArray", communicationArray)
      console.log("insideNewFuction ReferalArray", ReferalArray)
      let mskingcommunivstion = [...flow]
      let loyalitymasking = [...flow]
      console.log(' insideNewFuction mskingcommunivstion', mskingcommunivstion);
      console.log(' insideNewFuction loyalitymasking', loyalitymasking);
      // console.log(' insideNewFuction Referalmasking', Referalmasking);
      setCommunication([{
        brandId: mskingcommunivstion[0].brandId,
        condition: mskingcommunivstion[0].condition,
        created: mskingcommunivstion[0].created,
        createdAt: mskingcommunivstion[0].createdAt,
        flowType: mskingcommunivstion[0].flowType,
        name: mskingcommunivstion[0].name,
        updated: mskingcommunivstion[0].updated,
        updatedAt: mskingcommunivstion[0].updatedAt,
        _id: mskingcommunivstion[0]._id,
        flow: communicationArray
      }])

      setLoyalty([{
        brandId: loyalitymasking[0].brandId,
        condition: loyalitymasking[0].condition,
        created: loyalitymasking[0].created,
        createdAt: loyalitymasking[0].createdAt,
        flowType: loyalitymasking[0].flowType,
        name: loyalitymasking[0].name,
        updated: loyalitymasking[0].updated,
        updatedAt: loyalitymasking[0].updatedAt,
        _id: loyalitymasking[0]._id,
        flow: loyaltyArray,
      }])
      setRefferal([{
        brandId: mskingcommunivstion[0].brandId,
        condition: mskingcommunivstion[0].condition,
        created: mskingcommunivstion[0].created,
        createdAt: mskingcommunivstion[0].createdAt,
        flowType: mskingcommunivstion[0].flowType,
        name: mskingcommunivstion[0].name,
        updated: mskingcommunivstion[0].updated,
        updatedAt: mskingcommunivstion[0].updatedAt,
        _id: mskingcommunivstion[0]._id,
        flow: ReferalArray
      }])
      setActivity([{
        brandId: mskingcommunivstion[0].brandId,
        condition: mskingcommunivstion[0].condition,
        created: mskingcommunivstion[0].created,
        createdAt: mskingcommunivstion[0].createdAt,
        flowType: mskingcommunivstion[0].flowType,
        name: mskingcommunivstion[0].name,
        updated: mskingcommunivstion[0].updated,
        updatedAt: mskingcommunivstion[0].updatedAt,
        _id: mskingcommunivstion[0]._id,
        flow: ActivityArray,
      }])
      // setLoyalty(loyaltyArray)
    }
  }





  return (
    <div>
      {
        loader ? <Loader baseHeight={"60vh"} width={120} height={120} /> :
          <div className="pt_16 pb_16 pl_50 pr_40">
            {console.log('filteredData',filteredData)
            }
            <div style={{ display: 'flex', gap: '24px', marginTop: '25px', marginBottom: '12px', padding: '6px 24px', borderBottom: '1px solid #D6DAE9' }}>
              <div className={`hamchnages ${selectedItem === 'all' ? 'selecteechanges' : ''}`}
                onClick={() => handleItemClick('all')}>All</div>
              <div className={`hamchnages ${selectedItem === 'loyalty' ? 'selecteechanges' : ''}`}
                onClick={() => handleItemClick('loyalty')} >Loyalty</div>
              <div className={`hamchnages ${selectedItem === 'communication' ? 'selecteechanges' : ''}`}
                onClick={() => handleItemClick('communication')}>Communication</div>
              <div className={`hamchnages ${selectedItem === 'refferal' ? 'selecteechanges' : ''}`}
                onClick={() => handleItemClick('refferal')}>Referral</div>
              <div className={`hamchnages ${selectedItem === 'activity' ? 'selecteechanges' : ''}`}
                onClick={() => handleItemClick('activity')}>Activity</div>

            </div>


            <div className='d-flex pt_14 pb_14 pl_30 pr_30 cp w-100 color-neutral90 Link12M audienceTableGrid overflow-hidden'>

              <div className='col'
              >
                {/* {state?.loyaltyBuilder?.length+" FLOW"} */}
                Name
              </div>
              <div className='col-4 Link11M'>
                {'Last Updated'}
              </div>
            </div>
            {console.log('earningWays', earningWays)}


            {console.log('filteredData', filteredData)}
            {console.log('loyalty', loyalty)}
            {console.log('newcommunication', communication)}

            {

              selectedItem && selectedItem == 'loyalty' ?
<>              
                  {earningWays && earningWays.length > 0 && earningWays.map((element, idx) => (
                    ((element.activity === Campaign.Activities.EARNING.BIRTHDAY && !element.active) ||
                      (element.activity === Campaign.Activities.EARNING.CHECKIN && !element.active)) ? <></> :
                      <>
                        {console.log(element.activity)}
                        <div
                          key={idx}
                          className={
                            "row align-items-center tableRowNew pt_26 pb_26 pl_30 pr_30 tableRowHover singleProgram cp m_0 "
                          }
                          onClick={() => {
                            history.push({
                              pathname: window.location.pathname + '/earn',
                              state: element,
                            })
                          }}
                        >
                          <div className="col">
                            <div className='Heading15M d-flex align-items-center color-neutral70'>
                              {element.earningName}
                              {
                                element.active ? <div className="TierActive ml_5"><div></div>{"Active"}</div> : <div className="TierDeActive ml_5"><div></div>{"Deactive"}</div>
                              }
                            </div>
                          </div>
                          <div className='col-4 color-neutral90 Link13R'>
                            {new Date(element.updated || element.created).toDateString()}
                          </div>
                        </div>
                      </>
                  ))}    
                     
                {  loyalty && loyalty?.map((element, idx) => (
                    element?.flow?.length > 0 && element.flow.map((ele, ind) => (

                      <>
                        <div
                          key={ind}
                          className={
                            "row align-items-center pt_26 pb_26 pl_30 pr_30 tableRowNew tableRowHover singleProgram cp m_0"
                          }

                          onClick={() => {
                            history.push({
                              pathname: window.location.pathname + '/rewardadd',
                              state: { ...element, index: ind }
                            })
                          }}
                        >
                          <div className="col">
                            <div className='Heading15M color-neutral100'>
                              {ele.block || element.name || element.key}

                            </div>
                          </div>
                          <div className='col-4 color-neutral90 Link13R'>
                            {new Date(element.updated || element.created).toDateString()}
                          </div>
                        </div>
                      </>
                    ))))}
               </>

              // filteredData
               : selectedItem && selectedItem == 'communication' ? communication.map((element, idx) => (

                element?.flow?.length > 0 && element.flow.map((ele, ind) => (

            <>
              <div
                key={ind}
                className={
                  "row align-items-center pt_26 pb_26 pl_30 pr_30 tableRowNew tableRowHover singleProgram cp m_0"
                }
                onClick={() => {
                  history.push({
                    pathname: window.location.pathname + '/rewardadd',
                    state: { ...element, index: ind }
                  })
                }}
              >
                <div className="col">
                  <div className='Heading15M color-neutral100'>
                    {ele.block || element.name}

                  </div>
                </div>
                <div className='col-4 color-neutral90 Link13R'>
                  {new Date(element.updated || element.created).toDateString()}
                </div>
              </div>
            </>
            ))
              )) : selectedItem && selectedItem == 'refferal' ? refferal.map((element, idx) => (

                element?.flow?.length > 0 && element.flow.map((ele, ind) => (

            <>
              <div
                key={ind}
                className={
                  "row align-items-center pt_26 pb_26 pl_30 pr_30 tableRowNew tableRowHover singleProgram cp m_0"
                }
                onClick={() => {
                  history.push({
                    pathname: window.location.pathname + '/rewardadd',
                    state: { ...element, index: ind }
                  })
                }}
              >
                <div className="col">
                  <div className='Heading15M color-neutral100'>
                    {ele.block || element.name}

                  </div>
                </div>
                <div className='col-4 color-neutral90 Link13R'>
                  {new Date(element.updated || element.created).toDateString()}
                </div>
              </div>
            </>
            ))
              )) : selectedItem && selectedItem == 'activity' ? activity.map((element, idx) => (

                element?.flow?.length > 0 && element.flow.map((ele, ind) => (

            <>
              <div
                key={ind}
                className={
                  "row align-items-center pt_26 pb_26 pl_30 pr_30 tableRowNew tableRowHover singleProgram cp m_0"
                }
                onClick={() => {
                  history.push({
                    pathname: window.location.pathname + '/rewardadd',
                    state: { ...element, index: ind }
                  })
                }}
              >
                <div className="col">
                  <div className='Heading15M color-neutral100'>
                    {ele.block || element.name}

                  </div>
                </div>
                <div className='col-4 color-neutral90 Link13R'>
                  {new Date(element.updated || element.created).toDateString()}
                </div>
              </div>
            </>
            ))
              )) : <>              
              {earningWays && earningWays.length > 0 && earningWays.map((element, idx) => (
                ((element.activity === Campaign.Activities.EARNING.BIRTHDAY && !element.active) ||
                  (element.activity === Campaign.Activities.EARNING.CHECKIN && !element.active)) ? <></> :
                  <>
                    {console.log(element.activity)}
                    <div
                      key={idx}
                      className={
                        "row align-items-center tableRowNew pt_26 pb_26 pl_30 pr_30 tableRowHover singleProgram cp m_0 "
                      }
                      onClick={() => {
                        history.push({
                          pathname: window.location.pathname + '/earn',
                          state: element,
                        })
                      }}
                    >
                      <div className="col">
                        <div className='Heading15M d-flex align-items-center color-neutral70'>
                          {element.earningName}
                          {
                            element.active ? <div className="TierActive ml_5"><div></div>{"Active"}</div> : <div className="TierDeActive ml_5"><div></div>{"Deactive"}</div>
                          }
                        </div>
                      </div>
                      <div className='col-4 color-neutral90 Link13R'>
                        {new Date(element.updated || element.created).toDateString()}
                      </div>
                    </div>
                  </>
              ))}    
                 
            {  filteredData && filteredData?.map((element, idx) => (
                element?.flow?.length > 0 && element.flow.map((ele, ind) => (
                  <>
                    <div
                      key={ind}
                      className={
                        "row align-items-center pt_26 pb_26 pl_30 pr_30 tableRowNew tableRowHover singleProgram cp m_0"
                      }

                      onClick={() => {
                        history.push({
                          pathname: window.location.pathname + '/rewardadd',
                          state: { ...element, index: ind }
                        })
                      }}
                    >
                      <div className="col">
                        <div className='Heading15M color-neutral100'>
                          {ele.block || element.name || element.key}

                        </div>
                      </div>
                      <div className='col-4 color-neutral90 Link13R'>
                        {new Date(element.updated || element.created).toDateString()}
                      </div>
                    </div>
                  </>
                ))))}
           </>

            }


          </div>
      }
    </div>
  )
}

export default RewardBuilderListing





