import React ,{useEffect}from 'react'
import { PngIcons, SvgIcons } from '../../../../icons'
import { FlowService, GrowthtoolService } from '../../../../services';
import Fade from 'react-reveal/Fade';

function DisplayFlow({formik ,CheckFlow , setcheckFlow ,params}) {
    useEffect(()=>{
        onLoad();
    },[])
    const onLoad = async()=>{
    const {response, error} = await FlowService.Get({query: { brandId: params.id ,delete: false }})
    if(response.data.length > 0){
    // console.log('respond',response);
    setcheckFlow(true)
    }else{
    // console.log('error',error);
    }
    }
  return (
    <Fade right>
    <div id="DisplayFlow">
        {console.log('formik',formik.values)}
        <div className='d-flex evaluate p_8'>
        Evaluate and refine your strategy's flow
        </div>
        <div className='d-flex space-between p_8 mb_12'>
            <div className='color-neutral40 B12R'>When this happens</div>
            <div className='color-neutral40 B12R'>Perform these actions</div>
        </div>
        {(formik.values.CouponStrategy==="3 Coupons"||formik.values.CouponStrategy==="2 Coupons"||formik.values.CouponStrategy==="One time")&&<div className='d-flex space-between gap-24 align-items-center'>
            <div className='childBox w-100'>
                <div className='d-flex gap-8'>
                    <div><img height={20} width={20} src={PngIcons.RewardBuilder.customerType2} /></div>
                    <div className='B14M color-neutral60'>Customers</div>
                </div>
                <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
            </div>
            <div className='w-100'>
                <div className='childBox w-100'>
                    <div className='d-flex gap-8'>
                        <div><img height={20} width={20} src={PngIcons.RewardBuilder.rewardDeal} /></div>
                        <div className='B14M color-neutral60'>Reward a Customer</div>
                    </div>
                    <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'>
                        <SvgIcons.EyeIcon/> 
                    {
                         (formik?.values?.dealList?.filter(x => x._id === formik?.values?.reward1)[0]?.name)
                    }
                    </div>
                </div>
            </div>
        </div>}
        {(formik.values.CouponStrategy==="3 Coupons"||formik.values.CouponStrategy==="2 Coupons")&&<div className='d-flex space-between mt_16 mb_16'>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
        </div>}
        {(formik.values.CouponStrategy==="3 Coupons"||formik.values.CouponStrategy==="2 Coupons")&&<div className='d-flex space-between gap-24 align-items-center'>
            <div className='childBox w-100'>
                <div className='d-flex gap-8'>
                    <div><img height={20} width={20} src={PngIcons.RewardBuilder.dealStatus} /></div>
                    <div className='B14M color-neutral60'>Reward Status</div>
                </div>
                <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'><SvgIcons.EyeIcon/> {(formik?.values?.dealList?.filter(x => x._id === formik?.values?.reward1))[0]?.name}
                    <div className='color-neutral70 B14M'>is <span className='underline color-neutral90 ml_6'>Redeemed</span></div>
                </div>
                {/* <div className='color-neutral90 B14M mt_8'>Only contacts, that match all of the following conditions:</div>
                <div className='d-flex space-between border borderRadius-6 w-100 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'>
                    <div><SvgIcons.CrossIcon color={'#4B4E5B'} /></div>
                </div> */}
            </div>
            <div className='w-100'>
                <div className='childBox w-100'>
                    <div className='d-flex gap-8'>
                        <div><img height={20} width={20} src={PngIcons.RewardBuilder.rewardDeal} /></div>
                        <div className='B14M color-neutral60'>Reward a Customer</div>
                    </div>
                    <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'><SvgIcons.EyeIcon/> {(formik?.values?.dealList?.filter(x => x._id === formik?.values?.reward2))[0]?.name}</div>
                </div>
            </div>
        </div>}
        {formik.values.CouponStrategy==="3 Coupons"&&<div className='d-flex space-between mt_16 mb_16'>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
        </div>}
        {formik.values.CouponStrategy==="3 Coupons"&&<div className='d-flex space-between gap-24 align-items-center'>
            <div className='childBox w-100'>
                <div className='d-flex gap-8'>
                    <div><img height={20} width={20} src={PngIcons.RewardBuilder.dealStatus} /></div>
                    <div className='B14M color-neutral60'>Reward Status</div>
                </div>
                <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'><SvgIcons.EyeIcon/> {(formik?.values?.dealList?.filter(x => x._id === formik?.values?.reward2))[0]?.name}
                <div className='color-neutral70 B14M'>is <span className='underline color-neutral90 ml_6'>Redeemed</span></div>
                </div>
                {/* <div className='color-neutral90 B14M mt_8'>Only contacts, that match all of the following conditions:</div>
                <div className='d-flex space-between border borderRadius-6 w-100 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'>
                    <div><SvgIcons.CrossIcon color={'#4B4E5B'} /></div>
                </div> */}
            </div>
            <div className='w-100'>
                <div className='childBox w-100'>
                    <div className='d-flex gap-8'>
                        <div><img height={20} width={20} src={PngIcons.RewardBuilder.rewardDeal} /></div>
                        <div className='B14M color-neutral60'>Reward a Customer</div>
                    </div>
                    <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'><SvgIcons.EyeIcon/> {(formik?.values?.dealList?.filter(x => x._id === formik?.values?.reward3))[0]?.name}</div>
                </div>
            </div>
        </div>}
        {formik.values.CustomerEngagement==="repeation"&&<div className='d-flex space-between mt_16 mb_16'>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
        </div>}
        {formik.values.CustomerEngagement==="repeation"&&<div className='d-flex space-between gap-24 align-items-center'>
            <div className='childBox w-100'>
                <div className='d-flex gap-8'>
                    <div><img height={20} width={20} src={PngIcons.RewardBuilder.customerType2} /></div>
                    <div className='B14M color-neutral60'>Customers</div>
                </div>
                {/* <div className='underline border borderRadius-6 color-neutral90 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>Points</div> */}
                <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                <div className='color-neutral90 B14M mt_8'>Only contacts, that match all of the following conditions:</div>
                <div className='d-flex space-between border borderRadius-6 w-100 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'>
                    <div className='color-neutral70 B14M'>When points are <span className='underline color-neutral90'>equal</span><span className='underline color-neutral90 ml_4'>{formik.values.points} Points</span></div>
                    <div><SvgIcons.CrossIcon color={'#4B4E5B'} /></div>
                </div>
            </div>
            <div className='w-100'>
                <div className='childBox w-100'>
                    <div className='d-flex gap-8'>
                        <div><img height={20} width={20} src={PngIcons.RewardBuilder.rewardDeal} /></div>
                        <div className='B14M color-neutral60'>Reward a Customer</div>
                    </div>
                    <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'><SvgIcons.EyeIcon/> 
                    { 
                    (formik.values?.dealList?.filter(x => x._id === formik.values?.rewardPoints))[0]?.name
                }
                    </div>
                </div>
            </div>
        </div>}
        {formik.values.referral==="Yes"&&<div className='d-flex space-between mt_16 mb_16'>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
        </div>}
        {formik.values.referral==="Yes"&&<div className='d-flex space-between gap-24 align-items-center'>
            <div className='childBox w-100'>
                <div className='d-flex gap-8'>
                    <div><img height={20} width={20} src={PngIcons.RewardBuilder.oneTimeDeal} /></div>
                    <div className='B14M color-neutral60'>Referral</div>
                </div>
                <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>Receiver</div>
                <div className='color-neutral90 B14M mt_8'>Only contacts, that match all of the following conditions:</div>
                <div className='d-flex space-between border borderRadius-6 w-100 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'>
                    <div className='color-neutral70 B14M'>is true when receiver perform <span className='underline color-neutral90'>{formik.values.type}</span></div>
                    <div><SvgIcons.CrossIcon color={'#4B4E5B'} /></div>
                </div>
            </div>
            <div className='w-100'>
                <div className='childBox w-100'>
                    <div className='d-flex gap-8'>
                        <div><img height={20} width={20} src={PngIcons.RewardBuilder.rewardDeal} /></div>
                        <div className='B14M color-neutral60'>Reward a Customer</div>
                    </div>
                    <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'><SvgIcons.EyeIcon/> {(formik.values.dealList.filter(x => x._id === formik.values.rewardReceiver))[0].name}</div>
                </div>
            </div>
        </div>}
        {formik.values.referral==="Yes"&&<div className='d-flex space-between mt_16 mb_16'>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
            <div className='w-100 text-align-center color-neutral60 B16M'>and</div>
        </div>}
        {formik.values.referral==="Yes"&&<div className='d-flex space-between gap-24 align-items-center'>
            <div className='childBox w-100'>
                <div className='d-flex gap-8'>
                    <div><img height={20} width={20} src={PngIcons.RewardBuilder.oneTimeDeal} /></div>
                    <div className='B14M color-neutral60'>Referral</div>
                </div>
                <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>Sender</div>
                <div className='color-neutral90 B14M mt_8'>Only contacts, that match all of the following conditions:</div>
                <div className='d-flex space-between border borderRadius-6 w-100 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'>
                    <div className='color-neutral70 B14M'><span className='underline color-neutral90'>0 Invites</span> when receiver perform <span className='underline color-neutral90'>{formik.values.type}</span></div>
                    <div><SvgIcons.CrossIcon color={'#4B4E5B'} /></div>
                </div>
            </div>
            <div className='w-100'>
                <div className='childBox w-100'>
                    <div className='d-flex gap-8'>
                        <div><img height={20} width={20} src={PngIcons.RewardBuilder.rewardDeal} /></div>
                        <div className='B14M color-neutral60'>Reward a Customer</div>
                    </div>
                    {console.log(formik.values)}
                    <div className='underline border borderRadius-6 color-neutral90 B14M w-100 d-flex gap-10 align-items-center pt_8 pb_8 pl_16 pr_16 mt_8'><SvgIcons.EyeIcon/> {(formik.values.dealList.filter(x => x._id === formik.values.rewardSender))[0].name}</div>
                </div>
            </div>
        </div>}
        
        
    </div>
    </Fade>
  )
}

export default DisplayFlow