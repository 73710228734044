import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';

import CustomButton from '../../../components/CustomButton';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import CustomCheckBox from '../../../components/CustomCheckBox';
import CustomTextField from '../../../components/CustomTextField';
import CustomRadio from '../../../components/CustomRadio';
import PageTitle from '../../../components/setting/SettingPageTitles';

export default function Application(props) {

    return (
        <div>
            <PageTitle title={"Application"}/>
        </div>
    )
}
