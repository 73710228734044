import React, { useContext, useEffect, useState } from 'react'
import { PngIcons, SvgIcons } from '../../../../icons'
import { AgencyContext } from '../../../../context/Agency.context';
import CustomButton from '../../../../components/CustomButton.js'
import { CircularProgress } from '@mui/material'
// import PageOneSetupWalletly from './PageOneSetupWalletly';
// import EarningWays from './EarningWays'
import AddRewards from './AddRewards';
import WaystoRedeems from './WaystoRedeems';
import { useFormik } from 'formik';
import { Campaign } from '../../../../enums';
import { RewardRedemptionVld, ReferralVld, BirthdayVld, AddRewardsVld } from '../../../../validation/Inventory.validation'
import { useHistory, useParams } from 'react-router-dom';


export function MainRewardsRedeems() {
    const [selected, setSelected] = useState('Add Rewards');
    const [index, setIndex] = useState(0);
    const [next, setNext] = useState(true);
    const [chkAnoSec, setchkAnoSec] = useState();
    const agency = useContext(AgencyContext);
    const history = useHistory();
    const params = useParams();


    const initValues = {
        selectedRewardIndex          : 0,
        rewardRedemption             : [ ],
        rewards : [
            {
                name: '',
                price: '',
                description: '',
                image: '',
            }
        ],
        brandLogoFile                : '',
        referral                     : {
            PointCostBy : '',
            PointCostTo : '',
            activityType: 'signup',
            description : '',
            name        : ''
        },
        birthday: {
            costingPoint: '',
            description : '',
            name        : 'birthday'
        },
        loader : false ,
    
     
    }
    const SetupCompleted = ()=>{
        history.push(`/${params.id}/rewardSetupCompleted`);
    }


    useEffect(()=>{
        if(formik.values.rewardRedemption.length >= 0 ){
        formik.setValues({ ...formik.values, loader: true })
        }
    },[])
    const formik = useFormik({
        initialValues: { ...initValues },
        validationSchema: selected == 'Add Rewards' ? AddRewardsVld : chkAnoSec == 1 ? RewardRedemptionVld : chkAnoSec == 2 ? ReferralVld : BirthdayVld,
        // isInitialValid: false,
    })


    const Components = [
        {
            title: 'Add Rewards',
            component: <AddRewards setNext={setNext} next={next} formik={formik} />,
            image: <img className='w-100 revLtwo ' src={PngIcons.AddReward} alt="image" />
        },
  
        {
            title: 'Ways to redeem',
            component: <WaystoRedeems setchkAnoSec={setchkAnoSec} chkAnoSec={chkAnoSec} formik={formik} />,
            image: <img src={PngIcons.WaystoRedeems} className='w-100  revLthree ' alt="image" />,
        },
        
    ]


    const [state, setState] = useState({
        components: [],
        brandCreated: '',
    })

    const handleNextFunc = () => {
        setSelected(state?.components[index + 1]?.title);
        setIndex(index + 1)
    }
    async function handleNextAnother() {      
          
        let newReward = {
            name: '',
            price: '',
            description: '',
            image: '',
        }
        formik.values.rewards = [...formik.values.rewards, newReward]
        formik.setValues({ ...formik.values, selectedRewardIndex: formik.values.rewards.length - 1 })

    }

    useEffect(() => {
        setState({ ...state, components: Components })
    }, [formik.values, formik.errors, next, chkAnoSec])
   

    return (
        <>
            <div className='w-100 d-flex '>
                <div className={selected == 'Ways to redeem' ? 'top-header-auth d-flex align-items-center w-60 speHeader': 'top-header-auth d-flex align-items-center'}>
                    <img src={(agency.logo || PngIcons.authLogo)} height="32px" width="auto" className='borderRadius-60 ml_10 mr_10' alt="" />
                    <div className='cp back-text' onClick={() => {
                        setSelected(state?.components[index - 1]?.title);
                        setIndex(index - 1);
                        handleNextAnother();
                    }}>
                        {(selected != 'Add Rewards'  ) && "Back"}
                        {/* {(selected != 'Add Rewards'  ) && <SvgIcons.ArrowRight />} */}
                    </div>
                </div>

                <div className='w-60 d-flex bg-FFFFFF'>
                    <div className='w-100 d-flex mainSU_C1 bg-FFFFFF' id='mainSU_CU'>
                        <div className={selected == 'Ways to redeem' ? 'w-20 SpeLeft' : 'w-20'}>
                           
                                <div className='ParentOfDesgn hpx-76'>
                                <div className='ChildOneOfDesgn hpx-76'>
                                    {state?.components?.map((component, idx) => idx <= state?.components.length && <div className={`${(component.title == selected) ? 'specialRound' : 'simpleRound'}`} ></div>)}
                                </div>
                                <div className='ChildTwoOfDesgn hpx-76 align-flex-start'>
                                    {
                                        state?.components?.map((component, idx) =>
                                            idx <= state?.components.length &&
                                            <>
                                                <p className={`${(selected == component.title) ? 'livePara' : 'unlivePara'} text-left ml_28 `}>{component.title}</p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='w-50  mr_102 mt_124 '>
                            {state?.components[index]?.component}
                           
                            {(next == false ) &&  <div className="w-100 gap-12 mt_24 d-flex flex-column">
                                <CustomButton
                                    varient   = "primary"
                                    className = "w-100 hpx-52 mb_20"
                                    btntext   = "Next"
                                    onClick   = {index ==1 ?SetupCompleted  :handleNextFunc }
                                    disabled  = { !next && formik.values.rewards?.length <= 0 || formik.values.loader}
                                    //   disabled  = {state.loader || !formik.isValid || (selected == 2 && formik.values.brandLogoFile == "")}
                                    icon={
                                        state?.loader && <CircularProgress size={20} color={"inherit"} />
                                    }
                                />
                            </div>}

                        </div>

                    </div>

                </div>
                <div className= {selected == 'Ways to redeem' ? 'w-40 vh-100 bg-FCFDFD speRight d-flex align-items-center' : 'w-40 vh-100 bg-FCFDFD d-flex align-items-center'}>
                    {/* Mobile Images on conditions */}
                    {state?.components[index]?.image}
                </div>
            </div>
        </>
    )
}


export default MainRewardsRedeems