import React, {useState} from 'react';


import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CampaignTypeHeader from '../../../components/referrel/CampaignTypeHeader';
import CustomTable from '../../../components/Tables';


export default function Participations() {

    const [value, setValue] = useState(0);


//data for dropdown-customselect

    const campagins = [
        {
            field: 'Milestone Campaign'
        },
        {
            field: 'Other Campagin'
        }

    ]

    const campaignActions = [
            {
                field: 'Campaign Actions'
            }
    ]


    //table contents i.e table row headings 

    const tableHeadings = [
        'Type',
        'Message',
        'Event Name',
        'Actions'
    ]

    //tableContents i.e dummy Data of rows

    const data = [
        {
            type : 'Onboard',
            message: 'Welcome aboard!',
            eventname: 'Completed on boarding',

        },
        {
            type : 'Update',
            message: 'You have spent money',
            eventname: 'Accumulated spend',

        },
        {
            type : 'Update',
            message: 'You have spent money',
            eventname: 'Accumulated spend',

        },
        {
            type : 'Promotion',
            message: 'for your 5th visit in the app',
            eventname: '5th visit',
        },
        {
            type : 'Onboard',
            message: 'for your 10th visit in the app',
            eventname: 'Completed purchase',
        },
        
    ]

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return(
            <div id="participations">

                <div>
                     <CampaignTypeHeader campaignActions={campaignActions} campaigns={campagins}/> 
                </div>
                
                <div className="col-12">

                    <Tabs value={value} onChange={handleChange} aria-label="Analytics Tab" className="tabs mt_40 pb_24">
                    
                    <Tab label="Participants" />
                            {/* <Tab label="Tab 2" /> */}
                    </Tabs>   

                </div>

                <div className="col-12 tableBox">

                    <CustomTable tableheadings={tableHeadings} rowData={data} type={"multi"}/>

                </div>
                
            </div>
    )

}