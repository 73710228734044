import React, { useContext } from 'react';
import {Switch, Route, Link, Redirect, useHistory} from "react-router-dom";

import * as localForage from 'localforage'

import Login from './Login';
import Signup from './Signup';

import SignupHeader from '../../components/SignupHeader';
import ForgetPassword from './ForgetPassword';
import ResetPassword from './ResetPassword';

import {PngIcons, SvgIcons} from '../../icons'
import { AgencyContext } from '../../context/Agency.context';

function Auth({...props}) {

  const agency = useContext(AgencyContext);

  const history = useHistory();

  console.log('agency ', agency)

  const [title,setTitle] = React.useState('Sign In');

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return (
    <div className="position-relative ">
      {
      <div className='top-header-auth d-flex align-items-center'>
        {/* <img src={(agency?.logo?agency?.logo: (Object.keys(params).length > 0 ? PngIcons.CWW : PngIcons.authLogo))} height={Object.keys(params).length > 0 ?"150px":"32px"} width="auto" className='borderRadius-60 ' alt="" /> */}
        {Object.keys(params).length > 0?
        <img src={(PngIcons.CWW)} height="150px" width="auto" className=' ' style={{marginTop:'-20px', marginLeft: '-35px'}} alt="" />:
        <img src={(PngIcons.Logo)} height="50px" width="auto" className='borderRadius-60 ' alt="" />
        }
      </div>}
      <div id='Auth' className={
        "LoginCardLayout"}>
          <div className='w-100'>
              <div className="auth-form">
                <div className="w-100">
                  <div className='mainDiv' id='auth'>
                    <div className={
                      'main_sub_1 w-100'}>
                  <div  className={
                    'main_sub_1_child2 boxShadow'}>
                  {
                    props.location.pathname.includes('forget')
                    ?
                    <ForgetPassword setTitle={setTitle}/>
                    :
                    props.location.pathname.includes('reset')
                    ?
                    <ResetPassword setTitle={setTitle}/>
                    :
                    props.location.pathname.includes('signup') ? <Signup  setTitle={setTitle}/> : <Login setTitle={setTitle}/> 
                  }
                  </div>
                    </div>
                      </div>
                </div>
                { !agency.whiteLabel && <div className="w-100 middle">
                </div>}
              </div>
          </div>
          { 
          <div className='Footer d-flex space-between'>
                <div className="Body9R color-neutral60 cp">
                  <Link className='Body9R color-neutral60 cp' to="privacy" >Privacy Policy</Link> | <Link className='Body9R color-neutral60 cp'  to="terms">Terms & Conditions</Link>
                </div>
                <div className="Body9R color-neutral60 cp">
                <a className='Body9R color-neutral60 cp' href={'https://webapp.walletly.ai'} >Login with Old Walletly</a>
                </div>
                <div className="Body9R color-neutral60 cp">
                  Copyright     2023 <span className='capitalize'>{agency.agencyName || "Walletly"}</span>. All rights reserved.
                </div>
          </div>}
      </div>

    </div>
  );
}


export default Auth;
