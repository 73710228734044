import React, { useState, useEffect, useContext } from 'react';

import LoyaltyMainComponent from '../../../../components/loyalty/LoyaltyMainComponent';

import { EarningWayService } from '../../../../services';

import PngIcons from '../../../../icons/png.icon'
import { Campaign } from '../../../../enums';
import { Redirect, Route, Switch } from 'react-router';
import StampEarningWayForm from '../../../../components/loyalty/StampEarningWayForm';
import { BrandContext } from '../../../../context/Brand.context';
import { RedemptionType } from '../../../../enums/enums';


export default function Earnpoints({selectedCampaign, ...props}) {

    const EarnPointContent = {
        title   : 'Earn stamps',
        subHead : 'Create ways, your customer can earn points when they join, share, and engage with your brand. Learn more about ',
        link    : 'how customer earn stamps.',
    }

    const brand = useContext(BrandContext)

    const [earningWays, setEarningWays]             = useState([])
    const [processingElement, setProcessingElement] = useState()
    const [activities, setActivities]               = useState([])

    const updateActiveStatus = async ({e, _id, idx}) => {
        setProcessingElement(idx)
        const status = e.target.value === 'true' // getting true false value in string converting it back to boolean
        const payload = {_id, active: status}
        const {error, response} = await EarningWayService.Update({toaster: true, payload})
        if(response){
            earningWays[idx] = response.data
            setEarningWays([...earningWays])
        }
        setProcessingElement('')
    }

    const createPreBuiltEarningWays = async () => {
        const payload = {
            brandId         : brand._id,
            campaignId      : selectedCampaign._id,
            campaignType    : selectedCampaign.campaignType,
        }
        // if(brand.brandRedemptionType && brand.brandRedemptionType === RedemptionType.ORDER) payload.cloudwaitress = true
        const { error, response } = await EarningWayService.CreatePreBuiltEarningWays({payload})
        response && setEarningWays(response.data)
    }

    const getEarningWays = async () => {
        const query = {campaignId: selectedCampaign._id}
        const { error, response } = await EarningWayService.GetQueriedEarningWays({query})
        error && createPreBuiltEarningWays()
        response && setEarningWays(response.data) 
    }

    const updateActivities = () => {
        const Activities = [
            {
                key   : Campaign.Activities.EARNING.SPENDING,
                name  : 'Spending',
                icon  : PngIcons.spendingIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING.SPENDING)
            },
            {
                key   : Campaign.Activities.EARNING.BIRTHDAY,
                name  : 'Celebrate a birthday',
                icon  : PngIcons.birthdayIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING.BIRTHDAY)
    
            },
            {
                key   : Campaign.Activities.EARNING.SIGNUP,
                name  : 'Signup',
                icon  : PngIcons.smileIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING.SIGNUP)
            },
            {
                key   : Campaign.Activities.EARNING.CUSTOM,
                name  : 'Custom',
                icon  : PngIcons.starIcon,
                exist : false
            },
            {
                key   : Campaign.Activities.EARNING.VISIT,
                name  : 'Visit',
                icon  : PngIcons.keyIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING.VISIT)
            },
        ]

        setActivities([...Activities])
    }

    useEffect(getEarningWays, [selectedCampaign])
    useEffect(updateActivities, [earningWays])

    return (
        <div id="EarnPoints">
            <Switch>
                <Route
                    exact
                    path={props.match.url + '/'} 
                    component={() => <LoyaltyMainComponent 
                        details            = {EarnPointContent}
                        selectedCampaign   = {selectedCampaign}
                        list               = {earningWays}
                        updateActiveStatus = {updateActiveStatus}
                        processingElement  = {processingElement}
                        activities         = {activities}
                    />} 
                />
                <Route path={props.match.url + '/create'} component={StampEarningWayForm}/>
                <Route path={props.match.url + '/:_id'} component={StampEarningWayForm}/>

                {/* <Redirect path={props.match.url + '/*'} to={props.match.url} /> */}
            </Switch>
            
        </div>
    )
}
