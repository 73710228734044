import React, { useContext, useState } from 'react'
import { useFormik } from "formik";
import CustomRadio from "../../../../components/CustomRadio";
import { CircularProgress, RadioGroup, Radio } from "@mui/material";
import Fade from 'react-reveal/Fade';

export function PageOneSetupWalletly({formik}) {
  
  return (
    <Fade bottom>
    <div>
      <h1>How do yo want to use walletly?</h1>

      <form >
        <div className="auth-form hpx-383">
          <RadioGroup
            name     = "walletlyUse"
            value    = {formik.values.walletlyUse}
            onChange = {(e)=>{
              // console.log('e ', e.target.value, formik.values.walletlyUse)
              formik.values.walletlyUse = e.target.value;
              formik.setValues({...formik.values})

          }}
            // onFocus  = {formik.handleBlur}
          >

          <div className={formik.values.walletlyUse == "cloudwaitress" ? "w-100 radioParentp selected" : "w-100 radioParentp"}>
              <CustomRadio
                value   = {'cloudwaitress'}
                label   = {
                  <div className='standAlone '>
                    <h3 className="Body14M ">CloudWaitress</h3>
                    <p className="Body10M">Power up your online ordering platfor with cloudwaitress</p>
                  </div>
                }
                className="mt_16"
              />
            </div>

            <div className={formik.values.walletlyUse == "standAlone" ? "w-100 radioParentp selected" : "w-100 radioParentp"}>
              <CustomRadio
                value   = {'standAlone'}
                label   = {
                  <div className='standAlone '>
                    <h3 className="Body14M mt_11">Walletly Stand Alone</h3>
                    <p className="Body10M">You will be able to employ communication channels and construct conditional redemption techiques in advance.</p>
                  </div>
                }
                className="mt_16 "
              />
            </div>


          </RadioGroup>
        </div>
      </form>
    </div>
   </Fade>
  )
}

export default PageOneSetupWalletly