import React, { useContext, useState, useEffect } from 'react'

import CustomTextField from "../../components/CustomTextField";

import { AgencyContext } from '../../context/Agency.context';

import Autocomplete from '../../components/googleMap/Autocomplete';
import { Options, utils } from '../../utils';
import CustomSelect from '../../components/CustomSelect';
import LocationAutoCompleteField from '../../components/LocationAutoCompleteField';


export function Location ({formik}){

    const agency = useContext(AgencyContext);
    const [timezone, setTimezone] = useState('')
    const [focus , setFocus] = useState(false)
    const [focustwo , setFocusTwo] = useState(false)



    const [location, setLocation] = useState({
      lat         : '',
      lng         : '',
      address     : '',
      country     : '',
      city        : '',
      countryCode : '',
      state       : '',
      offset      : ''
  })
  
    const onAddressChange = (event) => {
      setLocation({...location, address: event.target.value})
      formik.setValues({...formik.values, location: {...formik.values.location, address: event.target.value}})
    }

    const onCityChange = (event) => {
      console.log('I am changed');
        setLocation({...location, city: event.target.value})
        formik.setValues({...formik.values, location: {...formik.values.location, city: event.target.value}})
    }

    const onCountryChange = (event) => {
        setLocation({...location, country: event.target.value})
        formik.setValues({...formik.values, location: {...formik.values.location, country: event.target.value}})
    }
    let focu ;
    const handleFocus = () => {
      focu = !focus;
      setFocus(focu)
    }
    const handleBlur = () => {
      focu = !focus;
      setFocus(focu)
    }

    let focuTwo ;
    const handleFocusAno = () => {
      focuTwo = !focustwo;
      setFocusTwo(focuTwo)
    }
    const handleBlurAno = () => {
      focu = !focustwo;
      setFocusTwo(focu)
    }


    const onCurrencyChange = (e) => {
      // console.log(' e.target.value ',  e.target.value)
      const currency = utils.getCurrency({currencyCode: e.target.value})
      formik.setValues({...formik.values, currency})
  }

  const onTimezoneChange = (e) => {
      const selectedTimezone = utils.getTimezone({timezoneName: e.target.value})
      setTimezone(selectedTimezone.name)
      const location = formik.values.location
      location.offset = selectedTimezone.utcOffset;
      formik.setValues({...formik.values, location: location, timezone : selectedTimezone.name})
  }

    const locationUpdate = () => setLocation(formik.values.location)
    useEffect(locationUpdate, [formik.values.location])

    const locationSummary = (location) => {
      const currency = utils.getCurrency({location: location})
      const selectedTimezone = utils.getTimezone({location: location})
      setTimezone(selectedTimezone)
      location.offset = selectedTimezone.utcOffset
      formik.setValues({...formik.values, location, currency, timezone : selectedTimezone})
      setLocation(location)
  }
  console.log('agency',agency);

  
 function impo(){
  return true
 }
  
    return (
        <div>
        {/* <h1 className='pb_32'>Add an address to get an accurate outcome.</h1> */}

       <form onSubmit={formik.handleSubmit} className='mt_15'>
       <div className="auth-form" style={{gap: '15px'}}>

         <div className="w-100">
          <Autocomplete 
             className       = "w-100"
             autoFocus       = {true}
             label           = "Address*"
             value           = {location.address}
             onChange        = {onAddressChange}
             locationSummary = {locationSummary}
             autocomplete    = "on"
          />
          {location.address && (location.lat==='' || location.lng==='' || !formik.values.location.lat || !formik.values.location.lng) &&<div className="error2 fs-10 ml_8 mt_2" >{
              location.address && (location.lat==='' || location.lng==='' || !formik.values.location.lat || !formik.values.location.lng) && "Please type your address and select from dropdown"
          }</div>}
         </div>
         <div className="w-100 d-flex justify-content-space-between">
         <div className="w-48" >
           <LocationAutoCompleteField
             type        = "text"
             placeholder = "City"
             name        = "city"
             label       = "City*"
             onFocus     = {handleFocus}
             onBlur      = {handleBlur}
             checkOne    = {focus}
             value       = {location.city}
             onChange    = {onCityChange}
             error       = {formik.touched.city && formik.errors.city}
             helperText  = {
               formik.touched.city && formik.errors.city
                 ? formik.errors.city
                 : ""
             }

           />
         </div>
         <div className="w-48">
           <LocationAutoCompleteField
             type        = "text"
             placeholder = "Country"
             name        = "country"
             className   = "lh-24"
             fontSize    = "16px"
             label       = "Country*"
             background  = "white"
             onFocus     = {handleFocusAno}
             onBlur      = {handleBlurAno}
             checkOne    = {focustwo}
             padding     = "12px 16px"
             value       = {location.country}
             onChange    = {onCountryChange}
             error       = {formik.touched.country && formik.errors.country}
             helperText  = {
               formik.touched.country && formik.errors.country
                 ? formik.errors.country
                 : ""
             }
           />
         </div>  
         </div >
         {/* <div className="w-100">
           <CustomSelect 
              className  = "w-100"
              options    = {Options?.getTimezones()}
              label      = "Timezone Offset"
              value      = {formik.values.timezone}
              onChange   = {onTimezoneChange}
          />
          </div>

         <div className="w-100 "style={{marginTop:"9px"}}>
               <CustomSelect
                  className  = "w-100"
                  options    = {Options?.getCurrencyCodes()}
                  label      = "Currency"
                  value      = {formik.values?.currency?.code || formik.values?.currency?.value || ''}
                  onChange   = {onCurrencyChange}
              />
          </div> */}
         </div>
        </form>
     </div>
    )
  }


export default Location