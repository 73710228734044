import React, { useState, useEffect, useContext } from 'react';

import LoyaltyMainComponent from '../../../../components/loyalty/LoyaltyMainComponent';

import { EarningWayService } from '../../../../services';

import PngIcons from '../../../../icons/png.icon'
import { Campaign } from '../../../../enums';
import { Redirect, Route, Switch } from 'react-router';
import EarningWayForm from '../../../../components/loyalty/EarningWayForm';
import { BrandContext } from '../../../../context/Brand.context';

import localForage from 'localforage';
import { LayoutContext } from '../../../../context/Layout.context';
import CustomButton from '../../../../components/CustomButton';
import { RedemptionType } from '../../../../enums/enums';
import { SvgIcons } from '../../../../icons';
import ReactTooltip from 'react-tooltip';
import { ColorSchemeCode } from '../../../../enums/ColorScheme';


export default function Earnpoints({selectedCampaign, ...props}) {

    const EarnPointContent = {
        title   : 'Earn points',
        subHead : 'Create ways, your customer can earn points when they join, share, and engage with your brand. Learn more about ',
        link    : 'how customer earn points.',
    }

    const brand = useContext(BrandContext)
    const layout = useContext(LayoutContext)


    useEffect(()=>{
        // console.log('best')

        layout.setLayout({ 
            button      : '',
            borderBottom: true,
            title       : 'Earning Ways',
            backTitles  : ['Loyalty Suite'],
            switch : '',
        }) 
    },[window.location.pathname])


    const [addEarningWays, setAddEarningWays]       = useState(false)
    const [earningWays, setEarningWays]             = useState([]);
    const [processingElement, setProcessingElement] = useState()
    const [activities, setActivities]               = useState([])

    const [tooltipText, setTooltipText] = useState('copy');

    const handleCopy = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const content = selectedCampaign.campaignCode
        navigator.clipboard.writeText(content)
        ReactTooltip.show(document.getElementById('tooltip'+selectedCampaign.campaignCode))
        setTooltipText('copied')
    }


    const updateActiveStatus = async ({e, _id, idx}) => {
        setProcessingElement(idx)
        // const status = e.target.value === 'true' // getting true false value in string converting it back to boolean
        // const payload = {_id, active: status}
        const payload = {_id, active: e}
        // console.log('payload',payload);
        const {error, response} = await EarningWayService.Update({toaster: true, payload})
        // console.log(error, response);
        if(response){
            earningWays[idx] = response.data
            // console.log('earningWays', earningWays);
            // let selectedLoyalityEarningWays = await localForage.getItem('selectedLoyalityEarningWays');
            let selectedLoyalityEarningWays = await localForage.getItem('earningways');
            selectedLoyalityEarningWays[idx] = response.data
            // localForage.setItem('selectedLoyalityEarningWays', selectedLoyalityEarningWays);
            localForage.setItem('earningways', selectedLoyalityEarningWays);
            setEarningWays([...earningWays])
        }
        setProcessingElement('')
    }

    const createPreBuiltEarningWays = async () => {
        const payload = {
            brandId     : brand._id,
            brandType   : brand.brandType,
            campaignId  : selectedCampaign._id,
            campaignType: selectedCampaign.campaignType,
        }
        // if(brand.brandRedemptionType && brand.brandRedemptionType === RedemptionType.ORDER) payload.cloudwaitress = true
        // console.log('====================================');
        // console.log('CreatePreBuiltEarningWays', payload);
        // console.log('====================================');
        const { error, response } = await EarningWayService.CreatePreBuiltEarningWays({payload})
        response && setEarningWays(response.data)
        // if(response) localForage.setItem('selectedLoyalityEarningWays', response.data)
        if(response) localForage.setItem('earningways', response.data)
    }

    const getEarningWays = async () => {
        let selectedLoyalityEarningWays;
        // selectedLoyalityEarningWays = await localForage.getItem('selectedLoyalityEarningWays');
        selectedLoyalityEarningWays = await localForage.getItem('earningways');
        if(!selectedLoyalityEarningWays || selectedLoyalityEarningWays[0].campaignId !== selectedCampaign._id)
        {
            const query = {campaignId: selectedCampaign._id}
            if(selectedCampaign._id) 
            {
                const { error, response } = await EarningWayService.GetQueriedEarningWays({query})
                // console.log('respones', response);
                let temp = []
                temp.push(response?.data?.filter((e)=>e.activity===Campaign.Activities.EARNING.CHECKIN)[0])
                temp.push(response?.data?.filter((e)=>e.activity===Campaign.Activities.EARNING.SIGNUP)[0])
                temp.push(response?.data?.filter((e)=>e.activity===Campaign.Activities.EARNING.BIRTHDAY)[0])
                temp.push(response?.data?.filter((e)=>e.activity===Campaign.Activities.EARNING.SPENDING)[0])
                // if(response) localForage.setItem('selectedLoyalityEarningWays', temp)
                if(response) localForage.setItem('earningways', temp)
                if(response) selectedLoyalityEarningWays = response.data
                error && createPreBuiltEarningWays()

            }
        }
        // console.log('selectedLoyalityEarningWays',selectedLoyalityEarningWays);
        let temp = []
        if(selectedLoyalityEarningWays){
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.CHECKIN)[0])
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.SIGNUP)[0])
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.BIRTHDAY)[0])
            temp.push(selectedLoyalityEarningWays.filter((e)=>e.activity===Campaign.Activities.EARNING.SPENDING)[0])
            // console.log('temp-',temp);
            return setEarningWays(temp) 
        }
    }


    const updateActivities = () => {
        // console.log('earningWays', earningWays);
        const Activities = [
            {
                key   : Campaign.Activities.EARNING.SPENDING,
                name  : 'Spending',
                icon  : PngIcons.spendingIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING.SPENDING)
            },
            {
                key   : Campaign.Activities.EARNING.BIRTHDAY,
                name  : 'Celebrate a birthday',
                icon  : PngIcons.birthdayIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING?.BIRTHDAY)
    
            },
            {
                key   : Campaign.Activities.EARNING.SIGNUP,
                name  : 'Signup',
                icon  : PngIcons.smileIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING.SIGNUP)
            },
            {
                key   : Campaign.Activities.EARNING.CUSTOM,
                name  : 'Custom',
                icon  : PngIcons.starIcon,
                exist : false
            },
            {
                key   : Campaign.Activities.EARNING.VISIT,
                name  : 'Visit',
                icon  : PngIcons.keyIcon,
                exist : earningWays.some(way => way.activity === Campaign.Activities.EARNING.VISIT)
            },
        ]
        setActivities([...Activities])
    }

    useEffect(getEarningWays, [selectedCampaign])
    useEffect(updateActivities, [earningWays])

    return (
        <div id="EarnPoints">
            <Switch>
                <Route
                    exact
                    path={props.match.url + '/'} 
                    component={() => <LoyaltyMainComponent 
                        details            = {EarnPointContent}
                        selectedCampaign   = {selectedCampaign}
                        list               = {earningWays}
                        updateActiveStatus = {updateActiveStatus}
                        processingElement  = {processingElement}
                        activities         = {activities}
                        addEarningWays     = {addEarningWays}
                        setAddEarningWays  = {setAddEarningWays} 

                    />} 
                />
                <Route 
                    path={props.match.url + '/create'}
                    render={props=><EarningWayForm {...props} updateActiveStatus={updateActiveStatus} setEarningWays={setEarningWays} earningWays={earningWays}/>}
                />
                <Route 
                    path={props.match.url + '/:_id'} 
                    render={props=><EarningWayForm {...props} updateActiveStatus={updateActiveStatus} setEarningWays={setEarningWays} earningWays={earningWays}/>}
                />

                {/* <Redirect path={props.match.url + '/*'} to={props.match.url} /> */}
            </Switch>
            
        </div>
    )
}
