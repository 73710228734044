import React, { useEffect } from 'react'
import { utils } from '../../../../utils';
import CustomTableV2 from '../../../../components/CustomTableV2';

export default function LoyaltyCard({customer}) {

    const [tableData, setTableData] = React.useState([])

    const pushArr = (arr, body) => {
        arr.forEach((v,index) => {
            body.push({
                id        : v._id,
                value     : v,
                Points    : v[customer.pass.passType].points,
                pointUsed : v[customer.pass.passType].pointUsed,
                Amount    : v[customer.pass.passType].amount.toFixed(2),
                Type      : v.transactionType,
                Timestamp : v.created ? utils.getDate(v.created, true) : utils.getDate(Date.now(), true),
            })
        });
        return body
    }
    const onLoad = () => {
        if(!customer.transactions) return 
        let body = []
        let arr = []
        arr = pushArr(customer.transactions, body)
        setTableData(arr)
    }
    useEffect(onLoad,[])
    const columns = [
        { field: 'Points', headerName: 'Points', flex: 2},
        { field: 'pointUsed', headerName: 'Point Used', flex: 2},
        { field: 'Amount', headerName: 'Amount', flex: 2},
        { field: 'Type', headerName: 'Type', flex: 2},
        { field: 'Timestamp', headerName: 'Timestamp', flex: 2 },
    ];

    return (
        <div id="TransactionHistory">
             <div className=" borderRadius-4">
                <CustomTableV2
                    pageSize      = {50}
                    height        = {'calc(100vh - 165px)'}
                    checkbox      = {false}
                    rows          = {tableData}
                    title         = {'Transactions'}
                    columns       = {columns}
                    deleteAll     = {false}
                    handleRefresh = {()=>console.log('resfresh')}
                    onRowClick    = {(value)=>console.log(value.row.value)}
                    />
            </div>
        </div>
    )
}
