import * as yup from 'yup';
import { GrowthTools } from '../enums';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    growthtoolName : yup.string().required(REQ_ERROR),
    growthtoolType : yup.string().required()
})

const qrcode = yup.object({
    fill         : yup.string().required(REQ_ERROR),
    text         : yup.string().required(REQ_ERROR),
    label        : yup.string(),
    mSize        : yup.string(),
    fontcolor    : yup.string(),
    back         : yup.string().required(REQ_ERROR),
    padding      : yup.string().required(REQ_ERROR),
    borderRadius : yup.string().required(REQ_ERROR),
    rounded      : yup.string(),
    size         : yup.string().required(REQ_ERROR),
    qrType       : yup.string().required(REQ_ERROR).oneOf(Object.values(GrowthTools.QRTypes)),
})

const widgetBtn = yup.object({
    btnText      : yup.string().required(),
    background   : yup.string().required(),
    color        : yup.string().required(),
    borderRadius : yup.string().required(),
    top          : yup.string(),
    left         : yup.string(),
    right        : yup.string(),
    bottom       : yup.string()
})

const widget = yup.object({
    html      : yup.string().required(),
    json      : yup.object().required(),
    widgetBtn : widgetBtn.required()
})

const userDashboard = yup.object({
    fontColor       : yup.string().required(),
    backgroundColor : yup.string().required(),
    cardColor       : yup.string().required(),
    widgetBtn       : widgetBtn.required()
})

const landingPage = yup.object({
    json : yup.object().required(),
    html : yup.string().required()
})

const Update = yup.object({
    campaignId     : yup.string(),
    growthtoolName : yup.string(),
    qrcode         : qrcode.default(undefined).when('growthtoolType',{
                        is   : value => value === GrowthTools.GrowhttoolTypes.QR_CODE,
                        then : yup.object().required(REQ_ERROR)
                    }),
    widget         : widget.default(undefined).when('growthtoolType',{
                        is   : value => value === GrowthTools.GrowhttoolTypes.WIDGET,
                        then : yup.object().required(REQ_ERROR)
                    }),
    userDashboard  : userDashboard.default(undefined).when('growthtoolType',{
                        is   : value => value === GrowthTools.GrowhttoolTypes.USER_DASHBOARD,
                        then : yup.object().required(REQ_ERROR)
                    }),
    landingPage    : landingPage.default(undefined).when('growthtoolType',{
                        is   : value => value === GrowthTools.GrowhttoolTypes.LANDING_PAGE,
                        then : yup.object().required(REQ_ERROR)
                    }),
})

const ManyChat = yup.object({
    // flow: yup.string().required(REQ_ERROR),
})
const CW = yup.object({
    // flow: yup.string().required(REQ_ERROR),
})

export {
    Create,
    Update,
    ManyChat,
    CW,
}