import React, {useState, useEffect, useContext} from 'react'
import {useHistory} from "react-router-dom";

import CustomButton from '../../../components/CustomButton';
import CustomSearch from '../../../components/CustomSearch';
import CustomTableV2 from '../../../components/CustomTableV2';
import Loader from '../../../components/Loader';
import DeleteModal from '../../../components/DeleteModal';
import CustomsModal from '../../../components/CustomModal';

import { SvgIcons } from '../../../icons';
import { ClickOutside } from '../../../utils';

import { LayoutContext } from '../../../context/Layout.context';
import { BrandContext } from '../../../context/Brand.context';
import { AgencyContext } from '../../../context/Agency.context';

import { GiveawayService, DealService } from '../../../services';
import localForage from 'localforage'
import CustomDateRange from '../../../components/CustomDateRange';
import CustomTextField from '../../../components/CustomTextField';
import EmptyScreen from '../../../components/EmptyScreen';

export default function Listing(props) {
    
    const [result, setResult]                     = useState([])
    const [filtered, setFiltered]                 = useState([])
    const [selectedRows, setSelectedRows]         = useState()
    const [deleteModal, setDeleteModal]           = useState(false)
    const [selectedGiveaway, setSelectedGiveaway] = useState();
    const [dealList, setDealList]                 = useState();
    const [show, setShow]                         = useState({
      filter        : false,
      mainLoader    : false,
      deleteLoader  : false,
    })
    const [filters,setFilters] = useState({
        startDate  : '',
        endDate    : '',
        productCode: '',
        minPrice   : '',
        maxPrice   : '',
    })

    const history = useHistory();
    
    const layout = useContext(LayoutContext)
    const brand   = useContext(BrandContext)
    const agency  = useContext(AgencyContext)
    
    useEffect(()=>{
        layout.setLayout({
            title           : "Giveaway",
            showCollapseIcon: false,
            expandedBar     : false,
            button          : <CustomButton
                            btntext  = {'Add Giveaway'}
                            onClick  = {addGiveaway}
                            disabled = {!dealList ? true : false}
                        />
        });
    },[dealList])

    const addGiveaway = async(item) =>{ 
       let path = history.location.pathname.split('/', -1);
       path.pop();
       path = path.join('/')+'/add'
       if(item._id) {
        setSelectedGiveaway(item);
        history.push({pathname : path , state : {item : item, deal : dealList}})
       }
       else{
        history.push({pathname : path, state : {deal : dealList}})
       }
    }

    const onDealLoad = async(refresh) => {
        let body = []
        let arr  = []
        let deal = await localForage.getItem('deal');
        if(!deal || deal.length === 0 || refresh || (history.location.state && history.location.state.reload)){
            const {response, error} = await DealService.Get({query: {agencyId: agency._id, brandId: brand._id, delete: false}})
            if(response){
                localForage.setItem('deal', response.data);
                deal = response.data
            }
        }

        return deal;
    }

    const onLoad = async(refresh) => {
        setShow({...show, mainLoader : true})
        let giveaway = await localForage.getItem('giveaway');
        let body = []
        let arr  = []
        if(!giveaway || refresh || (history.location.state && history.location.state.reload) ){
            const {response, error} = await GiveawayService.Get({query: {agencyId: agency._id, brandId: brand._id, delete: false}})
            if(response){
                localForage.setItem('giveaway', response.data);
                giveaway = response.data
            }
        }
        let deals = await onDealLoad();
        setDealList(deals);
        arr  = pushArr(giveaway, body , deals)
        setResult(arr)
        setFiltered(arr)
        setShow({...show, mainLoader : false})
        
    }

    useEffect(onLoad, [])

    const columns = [
        { field: 'Name', headerName: 'Giveaway Name', flex: 3, sortable: true, cellClassName : 'd-flex align-items-center'},
        { field: 'StartEndDate', headerName: 'Start - End Date', flex: 3 ,  cellClassName : 'd-flex align-items-center'},
        { field: 'Entries', headerName: 'Entries', flex: 2,  cellClassName : 'd-flex align-items-center' },
        { field: 'WinnerDeal', headerName: 'Winner Deal', flex: 2,  cellClassName : 'd-flex align-items-center' },
        { field: 'LoserDeal', headerName: 'Loser Deal', flex: 2 ,  cellClassName : 'd-flex align-items-center' },
        { field: 'Created', headerName: 'Created', flex: 2 ,  cellClassName : 'd-flex align-items-center' },
    ];

    const searchDealTitle = (dealId, deals) =>{
        for(let i =0 ; i<deals.length ; i ++ ){
            if(dealId == deals[i]._id) return deals[i].name
        }

    }

    const pushArr = (arr, body , deals) => {
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct', 'Nov', 'Dec'];
        arr && arr.forEach(async(v,index) => {
            let winnerdealTitle =  searchDealTitle(v.winnerDeal , deals);
            let loserDealTitle  =  searchDealTitle(v.loserDeal, deals);
            body.push({
                id          : v._id,
                value       : v,
                StartEndDate: months[(new Date(v.startDate).getMonth())] + ' ' + new Date(v.startDate).getDate() + ', ' + new Date(v.startDate).getFullYear() + ' - ' + months[(new Date(v.endDate).getMonth())] + ' ' + new Date(v.endDate).getDate()  + ',  ' + new Date(v.endDate).getFullYear(),
                Entries     : v.entries,
                WinnerDeal  : winnerdealTitle,
                LoserDeal   : loserDealTitle,
                Name        : v.name,
                Created     : new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
            })
        });
        return body
    }

    const handleSearch = (e) => {
    const results = result.filter(item =>
        item.value.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFiltered(results)
    }  

    const handleDelete = async() => {
        setShow({...show, deleteLoader: true})
        let body = []
        let arr  = []
        let temp = []
        const {response, error} = await GiveawayService.Update({toaster : true, payload : {deleteAll : selectedRows, brandId: brand._id, agencyId: agency._id}})
        let giveaway = await localForage.getItem('giveaway');
        if(giveaway && giveaway.length > 0){
            giveaway.map((value, index)=>{
                if(!selectedRows.includes(value._id)) temp.push(value)
            })
        }
        localForage.setItem('giveaway', temp);
        arr  = pushArr(temp, body)
        setResult(arr)
        setFiltered(arr)
        setShow({...show, deleteLoader: false})
        setDeleteModal(false)
        onLoad(true);
    }

    const handleDateChange = (ranges) => {
        let startDate =  new Date(ranges.selection.startDate).valueOf();
        let endDate   =  new Date(ranges.selection.endDate).valueOf();
        setFilters({...filters, startDate : startDate, endDate: endDate})
    }

    const handleFilterSearchFunc = () => {
        let inventory = result;

        if(filters.productCode != ""){
            inventory = inventory.filter(item =>
                item.Prefix.toLowerCase().includes(filters.productCode.toLowerCase())
            );
        }

        if(filters.maxPrice != "" && filters.minPrice != ""){
            inventory = inventory.filter(item =>
                (item.Price >= filters.minPrice) && (item.Price <= filters.maxPrice)
            );
        }

        if((filters.startDate != "" && filters.endDate != "") && inventory.length > 0)
        {   
            var startDate  = new Date (filters.startDate);
            var endDate    = new Date (filters.endDate);
            let inventoryList = [];
            inventory.map((inventory, idx)=>{
                var createDate = new Date (inventory.value.created); 
                if(((createDate.getDate() >= startDate.getDate()) && (createDate.getMonth() >= startDate.getMonth())) && ((createDate.getDate() <= endDate.getDate())) && (createDate.getMonth() >= endDate.getMonth())){
                    inventoryList.push(inventory);
                }
            })
            inventory = inventoryList;
        }
        setFiltered(inventory)
        setShow({...show, showFiter : false})
        
    }
    
    const goToAddDealFunc = () => {
        let path = history.location.pathname.split('/');
        path.splice(-2);
        path = path.join('/')+'/deal/add'
        history.push({pathname : path});
    }

    return (
        <div id="Listing">
            <div>
            {show.mainLoader ? 
                <Loader width={180} height={180}/>
                
                :

                !dealList ?
                
                <div className="middle" style={{height: 'calc(100vh - 150px)'}}>
                    <EmptyScreen
                        title     = "Deals"
                        btnTitle  = "Add Deal"
                        className = "mt_0"
                        btnClick  = {goToAddDealFunc}
                    />
                </div>

                :
                    <div className=" ml_24 pt_16 mr_24">
                        <div className="d-flex space-between align-items-center">
                            <div className="position-relative col-6 pl_0 pr_0">
                                    <CustomSearch
                                        className     = "w-100 "
                                        placeholder   = "Search Deals"
                                        icon          = {<SvgIcons.SearchIcon/>}
                                        position      = {'start'}
                                        onChange      = {(e)=>handleSearch(e)}
                                        onClickFilter = {()=>setShow({...show, filter : !show.filter})}
                                        filterShow    = {show.filter}
                                    />
                                    <ClickOutside onClick={()=>setShow({...show, filter : false})}>
                                        {
                                            show.filter && 
                                            <CustomFilter 
                                                setFilters             = {setFilters}
                                                resetFilter            = {()=>{setFilters({productCode : '', minPrice: '', maxPrice: '', startDate: '', endDate: ''}); setFiltered(result);}}
                                                handleFilterSearchFunc = {handleFilterSearchFunc}
                                                filters                = {filters}
                                                // setFilters             = {setFilters}
                                                handleDateChange       = {handleDateChange}
                                            />
                                        }
                                    </ClickOutside>
                            </div>
                            <div className="d-none">
                                <div id='customRefresh' onClick={()=>onLoad(true)}>
                                    <img className='imageButton cp' height='20px' width='20px' src='/images/Refresh.png'/>
                                </div>
                            </div>
                        </div>

                        {dealList && <div>
                           
                            <CustomTableV2
                                pageSize        = {50}
                                height          = {'100vh'}
                                checkbox        = {true}
                                rows            = {filtered || result}
                                title           = {'Giveaway'}
                                columns         = {dealList.length > 0  && columns}
                                deleteAll       = {true}
                                onRowClick      = {(value)=>addGiveaway(value.row.value)}
                                handleRefresh   = {()=>onLoad(true)}
                                setSelectedRows = {setSelectedRows}
                                handleDelete    = {()=>setDeleteModal(true)}
                            />
                        </div>}
                    </div>
            }

            <CustomsModal
                open      = { deleteModal }
                minWidth  = {'600px'}
                component = {<DeleteModal icon={show.deleteLoader} title="Deal" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
                onClose   = {()=> setDeleteModal(false)}
            />

            </div>
        </div>
    )
}


const CustomFilter = ({handleDateChange, filters, setFilters, handleFilterSearchFunc, resetFilter}) => {

    const [showDate, setShowDate]     = useState(false);
    const [priceError, setPriceError] = useState(false);

    const handlePriceChange = (e, key) => {
        if(e.target.value >= 0) {
            setFilters({...filters, [key] : parseInt(e.target.value)})
            setPriceError(false);
        }
    }

    useEffect(()=>{
        if((filters.maxPrice < filters.minPrice) && filters.minPrice !== "" && filters.maxPrice != "") setPriceError(true)
        else setPriceError(false);
    },[filters.maxPrice, filters.minPrice])

    return(
        <div id="filterComponentAudience">
            
            <div className="row">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Product Id
                </div>
                <div className="col-9">
                    <CustomTextField
                        label     = "Product Id"
                        className = "w-100"
                        onChange  = {(e)=>setFilters({...filters, productCode : e.target.value})}
                        value     = {filters.productCode}
                    />
                </div>
            </div>

            <div className="row mt_24">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Price Range
                </div>
                <div className="col-9 d-flex space-between">
                    <CustomTextField
                        label     = "Min Price"
                        className = "w-50 mr_16"
                        type      = "number"
                        onChange  = {(e)=>handlePriceChange(e,'minPrice')}
                        value     = {filters.minPrice}
                    />
                    <CustomTextField
                        label     = "Max Price"
                        className = "w-50"
                        type      = "number"
                        onChange  = {(e)=>handlePriceChange(e,'maxPrice')}
                        value     = {filters.maxPrice}
                    />
                </div>
               {priceError && <div className="error text-right w-100 mt_8">Max price should be greater than min price</div>}
                
            </div>

            <div className="mt_24 row position-relative">
                <div className="col-3 Link13R color-neutral60 pt_16">
                    Created
                </div>
                <div className="col-9">
                    <div className={`subscribedDate Body14R color-neutral60 cp d-flex ${showDate && 'isActiveDate'}`} onClick={()=>setShowDate(!showDate)}>
                            {(filters.startDate != "" && filters.endDate != "") ? 
                            <>
                                <div>
                                    {new Date(filters.startDate).getDate() + '-' + (new Date(filters.startDate).getMonth() + 1) + '-' +new Date(filters.startDate).getFullYear()}
                                </div>
                                <span className="ml_8 mr_8">
                                    -
                                </span>
                                <div>
                                    {new Date(filters.endDate).getDate() + '-' + (new Date(filters.endDate).getMonth() + 1) + '-' +new Date(filters.endDate).getFullYear()}
                                </div>
                            </>
                            :
                            <div className="Body14R color-neutral60">Overall</div>    
                        }
                    </div>
                    <ClickOutside onClick={()=>setShowDate(false)}>
                        <div className="position-absolute dateBox">
                            {showDate && <CustomDateRange onChange={handleDateChange}/> }
                        </div>
                    </ClickOutside>
                </div>
            </div>

            <div className="d-flex mt_24 justify-flex-end">
                <div>
                    <CustomButton
                        btntext   = "Reset Filter"
                        varient   = "secondary"
                        className = "mr_16"
                        onClick   = {resetFilter}
                    />
                </div>
                <div>
                    <CustomButton
                        btntext  = "Search"
                        varient  = "primary"
                        disabled = {priceError && true}
                        onClick  = {handleFilterSearchFunc}
                    />
                </div>
            </div>
        </div>
    )
}