import localforage from 'localforage';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../components/Loader';
import { BrandService, DealService, UserService, GrowthtoolService, PassService, TransactionService, CampaignService, FlowService } from '../../services';
import { GrowthTools } from '../../enums';
import { ColorSchemeCode } from '../../enums/ColorScheme';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function ManychatDealPage() {
    const history = useHistory();
    const params = useParams();
    const [state, setState] = useState({
        selectedDeal : ''
    })
    const [flow, setFlow]             = useState()
    const [pass, setPass]             = useState('')
    const [growthTool, setGrowthTool] = useState('')
    const [user, setUser]             = useState('')


    const [finalData, setFinalData] = useState([]);
    const [loader, setLoader]       = useState(false);
    const [brand, setBrand]         = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const getPass = async ({apiKey, brandId}) => {
        const query = {_id : params.passId, brandId: brandId, delete: false}
        const headers = {'x-api-key': apiKey}
        const {error, response} = await PassService.GetBusinessCheckoutPass({query, headers})
        if(error) return
        else{
            return  response.data[0]
        }
    }

     const getUser = async(userId) =>{
            const query = {_id : userId}
            const {error, response} = await UserService.GetByEmail({query})
            if(response){
                return response.data
            }
        }

    const getFlow = async () => {
        const {response, error} = await FlowService.Get({query: {_id: params.flowId}})
        if(response) {
            return response.data[0]
        }
    
    }

    const [qrcode, setQrcode] = useState({
        size      : 250,
        typeNumber: 10,
    })
    const [qrcodeApp, setQrcodeApp] = useState({
        size      : 250,
        typeNumber: 10,
    })
        
    const onLoad = async()=> {
        const brand = await BrandService.GetPublic({query: {_id: params.brandId}})
        if(brand.response) {
            setBrand(brand.response.data)
            const pass  = await  getPass({apiKey: brand.response.data.apiKey, brandId: brand.response.data._id});
            if(pass){
                setPass(pass)
                const user  = await getUser(pass.userId);
                setUser(user);
            }

            const campaignData = await CampaignService.GetPublicCampaign({query:{brandId: params.brandId, campaignType: 5}})


            const growthTool = await GrowthtoolService.GetPublicGrowthTool({query: {campaignId: campaignData.response.data[0]._id, brandId : params.brandId, growthtoolType: GrowthTools.GrowhttoolTypes.MANYCHAT}})
            if(growthTool.response){
                setGrowthTool(growthTool.response.data[0]);
            }
        }

       
        
        let payload = {brandId: params.brandId, passId: params.passId, flowIds: [params.flowId]} // flowID array ??
        const flows = await TransactionService.GetFlows({payload});
        let flowDeals = flows.response.data;
        // console.log('====================================');
        // console.log(flowDeals);
        // console.log('====================================');
        const query = {brandId: params.brandId, delete: false}
        const {response, error} = await DealService.Get({query});
        let dealsData = [];
        const deals = await localforage.getItem('deals');
        response && response.data.map((element, idx)=>{
            flowDeals.map((deal, idx)=>{
                if(element.name === deal.deal)
                {  
                    if(deals && deals.length > 0)
                    {
                        delete element.valid
                        let checkDeal = deals.filter((d, idx)=>{ if(d.name === element.name) return true;})
                        if(!checkDeal || checkDeal.length === 0) dealsData.push({...deal, ...element, avail: false, startDate: new Date(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
                    }
                    else
                    {
                        delete element.valid
                        dealsData.push({...deal, ...element, avail: false, startDate: new Date().valueOf(), uniqueId: Math.floor(1000 + Math.random() * 9000)})
                    }
                }
            })
        })
        
        let dataSorting;
        if(deals && deals.length ) dataSorting = [...deals,...dealsData];
        else dataSorting = [...dealsData];
        
        dataSorting.sort(function(a, b) {
            var keyA = a.conditionValue; var keyB = b.conditionValue;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });       

        setFinalData([...dataSorting])
        localforage.setItem('deals', [...dataSorting]);
        // localforage.setItem('transaction', data.transaction);
        // localforage.setItem('brand', data.brand);

        return
    }
    
    const qr = async()=>{
        const brand = await BrandService.GetPublic({query: {_id: params.brandId}})
        const pass = await  getPass({apiKey: brand.response.data.apiKey, brandId: brand.response.data._id});
        const user = await getUser(pass.userId)
        const flow  = await getFlow();
    
        setFlow(flow)
        setQrcode({...qrcode, text : `${(window.location.host.includes('localhost') ? window.location.host : 'https://'+window.location.host)}/page/businesscheckout/${params.brandId}/${pass.passCode}/${params.flowId}`})
        setQrcodeApp({...qrcodeApp, text : (window.location.host.includes('localhost') ? window.location.host : 'https://'+window.location.host)+"/page/businesscheckout/?brandId="+params.brandId+"&fullName=customer&passId="+params.passId+"&passCode="+pass.passCode})
        // setQrcodeApp({...qrcodeApp, text : (window.location.host.includes('localhost') ? window.location.host : 'https://'+window.location.host)+"/page/businesscheckout/?brandId="+params.brandId+"&fullName="+fname+"%20"+lname+"&passId="+params.passId+"&passCode="+pass.passCode,})
    }

    // useEffect(onLoad, [])

    useEffect(async() => {
        setLoader(true)
        await localforage.removeItem('deals')
        await onLoad()
        await qr()
        setLoader(false)
    }, [history.location.state])

    return (
        <div id="ManyChatDealPage"> 
        {console.log('floww',flow)}
            {loader ? <Loader width={120} height={120} /> : 
            <>
                <div id="NewDealPage" className='middle'>
                    <div className="middle dealOuterCard position-relative pb_40">
                        <div className="webScreen middle">
                            <img 
                            src       = {brand?.brandLogo}
                            height    = "64px"
                            width     = "64px"
                            className = 'borderRadius-60 mb_24'
                            alt       = ""
                        />
                        <div className='color-neutral60 Body14R'>Click the claim points button to redeem your coupon.</div>
                    
                        <Carousel 
                            className='mt_32' 
                            showArrows 
                            showStatus 
                            infiniteLoop 
                            showIndicators 
                            showThumbs = {false}
                            swipeable 
                            useKeyboardArrows 
                            width  = {310}
                            height = {200}
                            onChange={(selectedIndex)=> setSelectedIndex(selectedIndex)}
                        >
                            {
                            finalData.length > 0 && finalData.map((value, index)=>
                                <div 
                                    className={`cp ${!value.valid && 'disabled'}`} 
                                    onClick={()=>
                                        {history.push({pathname:'/page/'+growthTool._id+'/qrcode', 
                                        state: {...history.location.state, result: {passCode : pass.passCode }, brand: brand, dealDetail: value, flowIdArray: [params.flowId], mainpageUrl: window.location.href , payload:{firstName : (user.firstName + ' '+ user.lastName) ,passId : pass._id}}
                                    })
                                    }}
                                    >
                                    <img width={240} height={200} src={value.image} alt=""/>
                                </div>
                            )}
                            
                        </Carousel>
                        
                        <div className={`Heading16M color-neutral100 mt_8 mb_4 ${!finalData[selectedIndex]?.valid && 'disabled'}`}>{finalData[selectedIndex]?.name}</div>  
                        <div className={`Body14R color-Paragaph text-center mb_8 fs-12 desciprtionText ${!finalData[selectedIndex]?.valid && 'disabled'}`}>{finalData[selectedIndex]?.description}</div>
                        
                        <div className={`mb_48 ${!finalData[selectedIndex]?.valid && 'disabled'}`}>
                            { finalData[selectedIndex]?.condition === 'visit'?
                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{finalData[selectedIndex]?.conditionValue + ' ' + finalData[selectedIndex]?.condition} </div>
                                </div>
                                : finalData[selectedIndex]?.condition === 'event'?
                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{finalData[selectedIndex]?.conditionValue.event + ' ' + finalData[selectedIndex]?.condition} </div>
                                </div> :
                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{finalData[selectedIndex]?.conditionValue + ' ' + finalData[selectedIndex]?.condition}</div>
                                </div>
                            } 
                        </div>

                        {flow&&flow.triggerOption&&flow.triggerOption==="Event Base"?<></>:<div className={`claimButton w-100 ClaimBtn middle ${loader && 'disabled'}`} style={{height:'48px', backgroundColor : ColorSchemeCode.themeColor}}>
                            <div  
                            onClick={()=>{
                                history.push({
                                    pathname:'/page/'+growthTool._id+'/qrcode', 
                                    state: {
                                        ...history.location.state, 
                                        result: {passCode : pass.passCode }, 
                                        brand: brand, 
                                        mainpageUrl: window.location.href, 
                                        flowIdArray: [params.flowId], 
                                        payload:{firstName : (user.firstName + ' '+ user.lastName) ,passId : pass._id},
                                        qrCode: qrcode, 
                                        qrCodeApp: qrcodeApp
                                    }})}}
                                className={"Link13M color-white cp w-100 text-center"}>
                                    Claim Points
                            </div>
                        </div>}
                        </div>
                    </div>
                </div>   
                <div className="mobileScreen">
                        <div className="topBox">
                            <div className={"middle"}>
                                <img 
                                    src       = {brand?.brandLogo}
                                    height    = "72px"
                                    width     = "72px"
                                    className = 'borderRadius-60'
                                    alt       = ""
                                />
                                <div className='color-neutral60 Body14R mt_24'>Click the claim points button to redeem your coupon.</div>
                            </div>
                        </div>
                        <div className="lowerBox" style={{overflow: 'auto', marginBottom:'100px'}}>
                            {
                                finalData.length > 0 ? finalData.map((value, index)=>(
                                    <>
                                    { 
                                (value.valid) ? 
                                    <div className="mb_24 d-flex space-between singleDealRow" 
                                        onClick={()=>
                                            {history.push({pathname:'/page/'+growthTool._id+'/qrcode', 
                                            state: {...history.location.state, result: {passCode : pass.passCode }, brand: brand, dealDetail: value, flowIdArray: [params.flowId], mainpageUrl: window.location.href , payload:{firstName : (user.firstName + ' '+ user.lastName) ,passId : pass._id}}
                                        })
                                        }}
                                        >
                                        <div style={{width : '70%'}}>
                                            <div className="d-flex flex-column space-between h-100">
                                                <div className="pr_16">
                                                    <div className="capitalize Heading16M color-neutral100 mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.name}</div>
                                                    <div className="Caption12M color-neutral60 verticalEllipses mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.description}</div>
                                                </div>
                                                { value.condition === 'visit'?
                                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition} </div>
                                                </div>
                                                :
                                                value.condition === 'event'?
                                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue.event + ' ' + value.condition}</div>
                                                </div>
                                                :
                                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.conditionValue + ' ' + value.condition}</div>
                                                </div>
                                                }
                                            </div>
                                        </div>  
                                        <div className="middle">
                                            <img src={value.image} height="60px" width="60px" alt="" className="object-fit-cover borderRadius-4"  />
                                        </div>
                                        
                                    </div>

                                    :

                                    <div className="mb_24 position-relative disabled d-flex space-between singleDealRow" 
                                        onClick={()=>history.push({pathname:'/page/'+growthTool._id+'/deal', state: {...history.location.state, dealDetail: value}})}
                                        >
                                        <div style={{width : '70%'}}>
                                            <div className="d-flex flex-column space-between h-100">
                                                <div className="pr_16">
                                                    <div className="capitalize Heading16M color-neutral100 mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.name}</div>
                                                    <div className="Caption12M color-neutral60 verticalEllipses mb_4" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{value.description}</div>
                                                </div>
                                            
                                                <div className="w-fit-content" style={{backgroundColor: '#000000',padding: '4px 8px',borderRadius: '32px', alignItems: 'center'}}>
                                                    <div className="Caption12M ml_4" style={{color: '#FFFFFF'}}>{value.condition === 'event' ? (value.conditionValue.event + ' ' + value.condition) : (value.conditionValue + ' ' + value.condition)}</div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="middle">
                                            <img src={value.image} height="60px" width="60px" alt="" className="object-fit-cover borderRadius-4"  />
                                        </div>
                                    </div>

                                    }

                                    </>
                                ))

                                : 
                                <div className=''>
                                No Deals Found
                                </div>
                            }
                            {/* {flow&&flow.triggerOption&&flow.triggerOption==="Event Base"?<></>: */}
                            {<div className={`claimButton w-100 ClaimBtn middle ${loader && 'disabled'}`} style={{height:'48px', backgroundColor : ColorSchemeCode.themeColor}}>
                                <div  
                                onClick={()=>{
                                    history.push({
                                        pathname:'/page/'+growthTool._id+'/qrcode', 
                                        state: {
                                            ...history.location.state, 
                                            result: {passCode : pass.passCode }, 
                                            brand: brand, 
                                            mainpageUrl: window.location.href, 
                                            flowIdArray: [params.flowId], 
                                            payload:{firstName : (user.firstName + ' '+ user.lastName) ,passId : pass._id},
                                            qrCode: qrcode, 
                                            qrCodeApp: qrcodeApp
                                        }})}}
                                    className={"Link13M color-white cp w-100 text-center"}>
                                        Claim Points
                                </div>
                            </div>}
                        </div>
                    </div>
            </>
         }
         
        </div>
    )
}

export default ManychatDealPage
