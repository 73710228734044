import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/communication'

const Create = async({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const GetQueriedCommunication = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const sendMessage = async ({payload}) => await axios.post(env.API_URL+service + '/sendSms', payload, {headers: await MainService.getTokenHeader()})

const send = async ({payload}) => await axios.post(env.API_URL+service + '/send', payload, {headers: await MainService.getTokenHeader()})

const schedule = async ({payload}) => await axios.post(env.API_URL+service + '/schedule', payload, {headers: await MainService.getTokenHeader()})

const pushNotification = async ({payload}) => await axios.post(env.API_URL+service + '/pushnotification', payload, {headers: await MainService.getTokenHeader()})


const CommunicationService = {
    Create                  : Handlers.Services(Create),
    GetQueriedCommunication : Handlers.Services(GetQueriedCommunication),
    Update                  : Handlers.Services(Update),
    sendMessage             : Handlers.Services(sendMessage),
    send                    : Handlers.Services(send),
    schedule                : Handlers.Services(schedule),
    pushNotification        : Handlers.Services(pushNotification)
}

export default CommunicationService
