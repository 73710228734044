import React, { useContext, useEffect, useState } from 'react'

import localforage, * as localForage from 'localforage'


import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';

import { Options, utils } from '../../../utils';

import { LayoutContext } from '../../../context/Layout.context';
import { BrandService } from '../../../services';
import { SvgIcons } from '../../../icons';
import CustomsModal from '../../../components/CustomModal';
import { CircularProgress, FormControl, FormHelperText, RadioGroup } from '@mui/material';
import CustomButton from '../../../components/CustomButton';
import { Brand } from '../../../enums'
import DeleteModal from '../../../components/DeleteModal';
import { AgencyContext } from '../../../context/Agency.context';
import { showToaster } from '../../../utils/utils';
import CustomRadio from '../../../components/CustomRadio';
import 'react-phone-input-2/lib/style.css'
import CustomSwitch from '../../../components/CustomSwitch';
import { BrandContext } from '../../../context/Brand.context';
import { useFormik } from 'formik';
import { BrandVld } from '../../../validation';
import { UserContext } from '../../../context/User.context';
import CopyText from '../../../components/CopyText';

export default function BusinessWallet() {

    const brand  = useContext(BrandContext);
    const layout = useContext(LayoutContext);
    
    const initState={
        // appleWalletShow   : brand.appleWalletShow || false,
        // inStoreReward: brand.inStoreReward || "false",
        // pointStoreOffering: brand.pointStoreOffering || "false",
        walletApp         : {
            android: brand.walletApp ? brand.walletApp.android: '',
            apple  : brand.walletApp ? brand.walletApp.apple  : '',
        },
    }
    const [state, setState] = useState({
        generalLoader : false,
        apiError      : '',
        brandingLoader: false,
    })

    const handleRadioChange = (event) => {
        const value = event.target.value;
        formik.setValues({ ...formik.values, postPaid: value })

    };

    const formik = useFormik({
        initialValues      : { ...initState },
        validationSchema   : BrandVld.UpdateBrand,
        isInitialValid     : true,
        enableReinitialize : true
    })

    const handlePublicProfileUpdateFunc = async () => {
        setState({generalLoader: true, apiError: ''})

        // const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+'+formik.values.fullPhone)

        // let logo, cover
        // const user        = await localforage.getItem('user')
        // const desiredPath = `${user._id}/${formik.values.brandName}`

        
        // if(formik.values.brandLogoFile){
        //     logo = await utils.uploadImage({file: formik.values.brandLogoFile, desiredPath})
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        // }
        // if(logo && logo.error) return setState({loader: false, brandingLoader : false, apiError: logo.error.message})
        
        // // uploading cover
        // if(formik.values.brandCoverFile){
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        //     cover = await utils.uploadImage({file: formik.values.brandCoverFile, desiredPath})
        // }
        // if(cover && cover.error) return setState({loader: false, brandingLoader : false, apiError: cover.error.message})

        // const permissions        = await localforage.getItem('permissions')

        // if(formik.values.brandLogoFile){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].logo = logo.response.data;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }

        // if(formik.values.brandName){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].brandName = formik.values.brandName;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }


        // const payload = {
        //     _id: brand._id,
        //     postPaid: formik.values.postPaid == '1' ? true : false
        //     appleWalletShow   : formik.values.appleWalletShow,        
            
        // }
        const payload = {
            _id               : brand._id,
            // inStoreReward: formik.values.inStoreReward == "false" ? false : true,
            // pointStoreOffering: formik.values.pointStoreOffering == "false" ? false : true,
            walletApp         : {
                android: formik.values.walletApp.android,
                apple  : formik.values.walletApp.apple,
            }
           
        }

        console.log('payload is ', payload);

        // if(logo && logo.response) payload.brandLogo    = logo.response.data
        // if(cover && cover.response) payload.brandCover = cover.response.data

        const { error, response } = await BrandService.Update({toaster: true, payload})
        if(error) return setState({generalLoader: false, brandingLoader : false, apiError: error.error})

        console.log("Business ",response.data);
        brand.updateBrand(response.data)
        return setState({generalLoader: false, apiError: ''})

    }
    React.useEffect(() => {
        layout.setLayout({
            title: 'Settings',
            borderBottom: false,
            switch: "",
            button: ""
        })
    }, [formik.values, formik.errors, state.loader])



    useEffect(async () => {
        let ways = await localforage.getItem('earningways');
        console.log('ways', ways);
        let spending = ways.filter((ele, idx) => {
            return ele.activity == 'spending'
        })
        if (spending[0]?.earningValueRange?.length > 1 || spending[0].earningType !== 'range') {
            setState({ ...state, valid: false })
        }
        console.log('spending', spending);

    }, [])

    const user = useContext(UserContext);


  return (
    <>
        <div className="w-100">
                <div className='Heading22M color-neutral100'>Scanning Application Settings</div>
                <div className="color-propertySectionTextColor color-neutral60 fs-14">
                    Our Business Connector app allows you to scan customer QR codes for points, rewards, and more.
                </div>
                <CustomButton className="mt_12" btntext={"Business Connector"} icon={<SvgIcons.BCLink/>} position='end' onClick={()=>window.open("https://businessconnector.app/", "_blank")} />

                <div className="Heading14M mt_20">
                    Business Connector ID
                </div>
                <div className="color-propertySectionTextColor color-neutral60 fs-14">
                    Use this ID to log in to the Business Connector app.
                </div>

                <div className="w-50 mb_50 mt_12" style={{position: 'relative'}}>
                    <CustomTextField
                        // label={"User ID"}
                        className="w-100"
                        value={user.idKey}
                        disabled1
                    />
                    <div style={{ position: "absolute", top: "17px", right: "12px" }}>
                        <CopyText content={user.idKey} hideText={true} />
                    </div>
                    </div>
                {(!brand?.integrations?.cloudwaitress?.length>0)&&<div className='col-md-12    col-12 pl_0 pr_0 d-flex align-items-center space-between '>
                    <div className='Heading22M color-neutral100'>Customer Wallet Options</div>
                    {/* onClick={handlePublicProfileUpdateFunc}  */}
                    <CustomButton btntext={"Update"} 
                    disabled={state.generalLoader}
                    icon={state.generalLoader && <CircularProgress color='inherit'  size={14} />} onClick={handlePublicProfileUpdateFunc} />
                </div>}
                {(!brand?.integrations?.cloudwaitress?.length>0)&&<div className="color-propertySectionTextColor color-neutral60 fs-14">
                            Choose where your customers will store their loyalty passes and coupons.
                            </div>}
                {(!brand?.integrations?.cloudwaitress?.length>0)&&<div className="col-md-12 col-12 mb_10 pl_0 pr_0">
                    <div className="bar"></div>
                </div>}
                {console.log('formik ', formik.values)}
                    <div className="w-70">
                        {/* <div className="Heading16M color-neutral100 ">
                            Show wallet Pass
                        </div>
                        <div className="color-propertySectionTextColor color-neutral60 fs-14">
                            Show wallet pass on landing Page
                        </div> */}
                        {/* <div className="mt_8 ml_7" id="b-wallet">
                            <FormControl error={formik.touched.appleWalletShow && formik.errors.appleWalletShow}
                            className='d-flex'
                            >
                                <RadioGroup
                                    
                                    name="appleWalletShow"
                                    value={formik.values.appleWalletShow}
                                    onChange={(e) => {
                                        formik.setValues({ ...formik.values, appleWalletShow: e.target.value === 'true' ? true : false })
                                    }}
                                    onFocus={formik.handleBlur}
                                >
                                    <CustomRadio
                                        value={true}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={false}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>
                                    {formik.touched.appleWalletShow && formik.errors.appleWalletShow ? formik.errors.appleWalletShow : ''}
                                </FormHelperText>
                            </FormControl>
                        </div> */}

                        {(!brand?.integrations?.cloudwaitress?.length>0)&&<div className="col-12 pl_0 pr_0 mt_16">
                            <div className='d-flex mt_20 gap-10 w-100'>
                                <div className="appleApplication w-100">
                                    <div className="d-flex align-items-center  ">
                                        <div className="mb_8">
                                            <SvgIcons.AppleIcon />
                                        </div>
                                        {/* <div className="fs-12 pb_3 fw-5 color-neutral60 pt_5 ml_4 mb_8"> */}
                                        <div className="fs-12 pb_2 mb_0 fw-5 color-neutral60 mb_8 pt_3 ml_4">
                                            Apple Application
                                        </div>
                                    </div>
                                    <div className="col-12 pl_0 pr_0">
                                        <CustomSelect
                                            options={Options.ApplePassRedeemTypes()}
                                            value={formik.values.walletApp ? formik.values.walletApp.apple : ''}
                                            onChange={(e) => formik.setValues({ ...formik.values, walletApp: { ...formik.values.walletApp, apple: e.target.value } })}
                                            inputProps={{ tabIndex: '13' }}
                                        />
                                    </div>

                                </div>

                                <div className="androidApplication w-100">
                                    <div className="d-flex align-items-center ">
                                        <div className="mb_8">
                                            <SvgIcons.AndroidIcon />
                                        </div>
                                        <div className="fs-12 pb_2 mb_0 fw-5 color-neutral60 mb_8 pt_3 ml_4">
                                            Android Application
                                        </div>
                                    </div>
                                    <div className="col-12 pl_0 pr_0" >
                                        <CustomSelect
                                            options={Options.AndroidPassRedeemTypes()}
                                            value={formik.values.walletApp ? formik.values.walletApp.android : ''}
                                            onChange={(e) => formik.setValues({ ...formik.values, walletApp: { ...formik.values.walletApp, android: e.target.value } })}
                                            inputProps={{ tabIndex: '14' }}
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>}
                </div>






                {/*  */}



                {/* <div className='col-md-12   col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8 mt_48'>
                    <div className='Heading22M color-neutral100'>In-Store Reward Type</div>

                </div>
                
                <div className="col-12 col-md-12 pl_0 pr_0">
                    <div className="w-70 mt_10">
                        <div className="Heading16M color-neutral100 ">
                            Are you considering implementing a rewards program that is separate from Walletly?
                        </div>
                        <div className="mt_8 ml_11" id='inStore-radio'>
                            <FormControl
                            >
                                <RadioGroup
                                    name="inStoreReward"
                                    onChange={formik.handleChange}

                                    value={formik.values.inStoreReward}
                                >
                                    <CustomRadio
                                        value={"true"}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={"false"}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>

                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="Heading16M color-neutral100" style={{ marginTop: "40px" }}>
                            Would you prefer to disable Auto Redemption on Pass and instead offer manual rewards?
                        </div>
                        <div className='point-two'>This would allow customers to accrue points, but their pass would not be updated until they are eligible for a reward.</div>
                        <div className="mt_8 ml_11" id='inStore-radio2'>
                            <FormControl

                            >
                                <RadioGroup
                                    name="pointStoreOffering"
                                    onChange={formik.handleChange}
                                    value={formik.values.pointStoreOffering}
                                >
                                    <CustomRadio
                                        value={"true"}
                                        label={<div className='color-propertySectionTextColor fs-14'>Yes</div>}
                                    />

                                    <CustomRadio
                                        value={"false"}
                                        label={<div className='color-propertySectionTextColor fs-14'>No</div>}
                                    />
                                </RadioGroup>
                                <FormHelperText>

                                </FormHelperText>
                            </FormControl>
                        </div>
                    </div>
                </div> */}

            </div>
    </>
  )
}
