import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TransactionService } from '../services';
import localforage from 'localforage';

function Transaction() {

    const [rows, setRows] = React.useState([])
    const [a, setA] = React.useState(false)

    function createData(name, amount, point, removePoints, currentPoints, type, platform, deal, date) {
        // const density = population / size;
        return {name, amount, point, removePoints, currentPoints, type, platform, deal, date};
      }

    React.useEffect(async() => {
        setRows([])
        let brand = await localforage.getItem('brand')
        let abc = await TransactionService.GetTransactionsbyBrandId({query:{brandId:brand._id}})
        console.log('abc',abc);
        if(abc?.response?.data?.length>0){
            for (const element of abc.response.data) {
                console.log('element',element?.pass?.userData?.firstName);
                if(element.type&&element.platform){
                    rows.push(
                        createData(
                            element?.pass?.userData?.firstName || '-',
                            element?.amount || '-' , 
                            element?.points || '-', 
                            element?.removePoints || '-',
                            element?.currentPoints || '-',
                            element?.type, 
                            element?.platform, 
                            element?.deal?.name || '-' , 
                            new Date(element.created).toDateString(), 
                        )
                    )
                    
                }
                // else{
                //     rows.push(
                //         createData(
                //             element.customerName,
                //             new Date(element.created).toDateString(), 
                //             element.transactionType, 
                //             element?.loyaltyCard?.benefits?.dealOffer?.condition?.length>0&&element?.loyaltyCard?.benefits?.dealOffer?.condition[0]?.condition || '-', 
                //             element?.loyaltyCard?.benefits?.dealOffer?.name || '-' , 
                //             element.loyaltyCard.amount || '-' , 
                //             element.loyaltyCard.points || '-', 
                //             element.loyaltyCard.pointUsed || '-' 
                //         )
                //     )
                    
                // }
            }

            // setRows([...rows])
        }
        // setRows([...rows])
        
        setTimeout(() => {
            setRows([...rows])
            
        }, 3000);
        setA(true)
    }, [a])
    

    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 },
        { id: 'amount', label: 'Amount', minWidth: 100 },
        { id: 'point', label: 'Points', minWidth: 100 },
        { id: 'removePoints', label: 'Used Pts', minWidth: 100 },
        { id: 'currentPoints', label: 'Current Pts', minWidth: 100 },
        { id: 'type', label: 'Type', minWidth: 170 },
        { id: 'platform', label: 'Platform', minWidth: 170 },
        { id: 'deal', label: 'Deal', minWidth: 170 },
        { id: 'date', label: 'Date', minWidth: 170 },
        // { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
        // {
        //   id: 'population',
        //   label: 'Population',
        //   minWidth: 170,
        //   align: 'right',
        //   format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //   id: 'size',
        //   label: 'Size\u00a0(km\u00b2)',
        //   minWidth: 170,
        //   align: 'right',
        //   format: (value) => value.toLocaleString('en-US'),
        // },
        // {
        //   id: 'density',
        //   label: 'Density',
        //   minWidth: 170,
        //   align: 'right',
        //   format: (value) => value.toFixed(2),
        // },
      ];
      
      
      
      const rows1 = [
        createData('India', 'IN', 1324171354, 3287263),
        createData('China', 'CN', 1403500365, 9596961),
        createData('Italy', 'IT', 60483973, 301340),
        createData('United States', 'US', 327167434, 9833520),
        createData('Canada', 'CA', 37602103, 9984670),
        createData('Australia', 'AU', 25475400, 7692024),
        createData('Germany', 'DE', 83019200, 357578),
        createData('Ireland', 'IE', 4857000, 70273),
        createData('Mexico', 'MX', 126577691, 1972550),
        createData('Japan', 'JP', 126317000, 377973),
        createData('France', 'FR', 67022000, 640679),
        createData('United Kingdom', 'GB', 67545757, 242495),
        createData('Russia', 'RU', 146793744, 17098246),
        createData('Nigeria', 'NG', 200962417, 923768),
        createData('Brazil', 'BR', 210147125, 8515767),
      ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    
  return (
    <div id='Transaction'>
            {rows?.length>0&&<Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell className='B14M'
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover className='B14R' role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell className='B14R' key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 1000]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>}
    </div>
  )
}

export default Transaction