import React, {useState, useEffect, useContext} from 'react'
import {useHistory} from "react-router-dom";

import CustomButton from '../../../components/CustomButton';
import CustomSearch from '../../../components/CustomSearch';
import CustomTableV2 from '../../../components/CustomTableV2';
import Loader from '../../../components/Loader';
import DeleteModal from '../../../components/DeleteModal';
import CustomsModal from '../../../components/CustomModal';

import { SvgIcons } from '../../../icons';
import { ClickOutside } from '../../../utils';

import { LayoutContext } from '../../../context/Layout.context';
import { BrandContext } from '../../../context/Brand.context';
import { AgencyContext } from '../../../context/Agency.context';

import { InventoryService } from '../../../services';
import localForage from 'localforage'
import CustomDateRange from '../../../components/CustomDateRange';
import CustomTextField from '../../../components/CustomTextField';
import AddInventory from './AddInventory';



export default function Listing(props) {
    
    const [result, setResult]                       = useState([])
    const [filtered, setFiltered]                   = useState([])
    const [selectedRows, setSelectedRows]           = useState()
    const [addedInventory, setAddedInventory]       = useState(false)
    const [deleteModal, setDeleteModal]             = useState(false)
    const [selectedInventory, setSelectedInventory] = useState();
    const [show, setShow]                           = useState({
      filter        : false,
      mainLoader    : false,
      deleteLoader  : false,
      inventoryModal: false,
    })
    const [filters,setFilters] = useState({
        startDate  : '',
        endDate    : '',
        productCode: '',
        minPrice   : '',
        maxPrice   : '',
    })

    const history = useHistory();
    
    const layout = useContext(LayoutContext)
    const brand   = useContext(BrandContext)
    const agency  = useContext(AgencyContext)
    
    useEffect(()=>{
        layout.setLayout({
            title       : "Products",
            backTitles  : ['Loyalty Suite'],
            borderBottom: true,
            edit        : false,
            button      : <CustomButton
                            btntext      = {'Add Item'}
                            onClick      = {addInventory}
                        />
        });
    },[])

    const addInventory = (item) =>{ 
        setShow({...show, inventoryModal : true})
        setSelectedInventory(item);
    }
   
    const onLoad = async(refresh) => {
        setShow({...show, mainLoader : true})
        let inventory = await localForage.getItem('inventory');
        let body = []
        let arr  = []
        if(!inventory || refresh || (history.location.state && history.location.state.reload) ){
            const {response, error} = await InventoryService.Get({query: {brandId: brand._id, delete: false}})
            if(response){
                localForage.setItem('inventory', response.data);
                inventory = response.data
            }
        }
        arr  = pushArr(inventory, body)
        setResult(arr)
        setFiltered(arr)
        setShow({...show, mainLoader: false, inventoryModal: false})
    }

    useEffect(onLoad, [])

    const columns = [
        { field: 'Name', headerName: 'Product Name', flex: 6, sortable: true,
            valueFormatter : (data) => data.row.value.name,
            renderCell     : (params) => {
                return (
                    result.map((v,i)=>(
                        v.id === params.id && 
                        <div style={{display: 'grid', gridAutoFlow:'column', gridGap: '0px'}}>
                            <div className="mr_16" style={{
                                backgroundImage    : 'url("'+v.value.image+'")',
                                height             : '40px',
                                width              : '40px',
                                borderRadius       : '4px',
                                backgroundPosition : 'center',
                                backgroundRepeat   : 'no-repeat',
                                backgroundSize     : 'cover',
                            }}></div>
                            <div className="middle" >
                                <div style={{lineHeight: '16px !important'}} className="Link13M color-neutral100 text-capitalize">{v.value.name}</div>
                            </div>
                            {/* <div>
                                <div>{v.value.deal.title}</div>
                                <div>{v.value.deal.description}</div>
                            </div> */}
                        </div>
                    ))
                );
            }
        },
        { field: 'Prefix', headerName: 'Product Id', flex: 2 },
        { field: 'Price', headerName: 'Price', flex: 2 },
        { field: 'Created', headerName: 'Created', flex: 2 },
    ];

    const pushArr = (arr, body) => {
        arr && arr.forEach((v,index) => {
            body.push({
                id     : v._id,
                value  : v,
                Prefix : v.productCode,
                Price  : v.price,
                Name   : v.name,
                Created: new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
                // Created     : new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
            })
        });
        return body
    }

    const handleSearch = (e) => {
    const results = result.filter(item =>
        item.value.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFiltered(results)
    }  

    const handleDelete = async() => {
        setShow({...show, deleteLoader: true})
        let body = []
        let arr  = []
        let temp = []
        const {response, error} = await InventoryService.Update({toaster : true, payload : {deleteAll : selectedRows, brandId: brand._id, agencyId: agency._id}})
        let inventory = await localForage.getItem('inventory');
        if(inventory && inventory.length > 0){
            inventory.map((value, index)=>{
                if(!selectedRows.includes(value._id)) temp.push(value)
            })
        }
        localForage.setItem('inventory', temp);
        arr  = pushArr(temp, body)
        setResult(arr)
        setFiltered(arr)
        setShow({...show, deleteLoader: false})
        setDeleteModal(false)
        onLoad(true);
    }

    const handleDateChange = (ranges) => {
        let startDate =  new Date(ranges.selection.startDate).valueOf();
        let endDate   =  new Date(ranges.selection.endDate).valueOf();
        setFilters({...filters, startDate : startDate, endDate: endDate})
    }

    const handleFilterSearchFunc = () => {
        let inventory = result;

        if(filters.productCode != ""){
            inventory = inventory.filter(item =>
                item.Prefix.toLowerCase().includes(filters.productCode.toLowerCase())
            );
        }

        if(filters.maxPrice != "" && filters.minPrice != ""){
            inventory = inventory.filter(item =>
                (item.Price >= filters.minPrice) && (item.Price <= filters.maxPrice)
            );
        }

        if((filters.startDate != "" && filters.endDate != "") && inventory.length > 0)
        {   
            var startDate  = new Date (filters.startDate);
            var endDate    = new Date (filters.endDate);
            let inventoryList = [];
            inventory.map((inventory, idx)=>{
                var createDate = new Date (inventory.value.created); 
                if(((createDate.getDate() >= startDate.getDate()) && (createDate.getMonth() >= startDate.getMonth())) && ((createDate.getDate() <= endDate.getDate())) && (createDate.getMonth() >= endDate.getMonth())){
                    inventoryList.push(inventory);
                }
            })
            inventory = inventoryList;
        }
        setFiltered(inventory)
        setShow({...show, showFiter : false})
        
    }

    return (
        <div id="Listing" className="">
            <div className="d-flex space-between align-items-center ml_24 pt_16 mr_24 d-none">
                <div className="position-relative col-6 pl_0 pr_0">
                    <CustomSearch
                        className     = "w-100 "
                        placeholder   = "Search Deals"
                        icon          = {<SvgIcons.SearchIcon/>}
                        position      = {'start'}
                        onChange      = {(e)=>handleSearch(e)}
                        onClickFilter = {()=>setShow({...show, filter : !show.filter})}
                        filterShow    = {show.filter}
                    />
                    <ClickOutside onClick={()=>setShow({...show, filter : false})}>
                        {
                            show.filter && 
                            <CustomFilter 
                                setFilters             = {setFilters}
                                resetFilter            = {()=>{setFilters({productCode : '', minPrice: '', maxPrice: '', startDate: '', endDate: ''}); setFiltered(result);}}
                                handleFilterSearchFunc = {handleFilterSearchFunc}
                                filters                = {filters}
                                handleDateChange       = {handleDateChange}
                            />
                        }
                    </ClickOutside>
                </div>
                <div className="d-none">
                    <div id='customRefresh' onClick={()=>onLoad(true)}>
                        <img className='imageButton cp' height='20px' width='20px' src='/images/Refresh.png'/>
                    </div>
                </div>
            </div>
            <div>
            {show.mainLoader ? 
                <Loader width={180} height={180}/>
                :

                <CustomTableV2
                    pageSize        = {50}
                    height          = {result.length <= 4 ? '550px' : result.length > 4 && result.length < 8 ? '800px' : '1050px'}
                    checkbox        = {true}
                    rows            = {filtered || result}
                    title           = {'Products'}
                    columns         = {columns}
                    deleteAll       = {true}
                    onRowClick      = {(value)=>addInventory(value.row.value)}
                    handleRefresh   = {()=>onLoad(true)}
                    setSelectedRows = {setSelectedRows}
                    handleDelete    = {()=>setDeleteModal(true)}
                />
            }

            <CustomsModal
                open      = { deleteModal }
                minWidth  = {'600px'}
                component = {<DeleteModal icon={show.deleteLoader} title="Deal" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
                onClose   = {()=> setDeleteModal(false)}
            />

            <CustomsModal 
                component = {<AddInventory onLoad={onLoad} item={selectedInventory} onClose={()=> setShow({...show, inventoryModal : false})}/>}
                open      = { show.inventoryModal }
                minWidth  = {'550px'}
                title     = {'Add Product'}
                onClose   = {()=> setShow({...show, inventoryModal : false})}
            />
            </div>
        </div>
    )
}


const CustomFilter = ({handleDateChange, filters, setFilters, handleFilterSearchFunc, resetFilter}) => {

    const [showDate, setShowDate]     = useState(false);
    const [priceError, setPriceError] = useState(false);

    const handlePriceChange = (e, key) => {
        if(e.target.value >= 0) {
            setFilters({...filters, [key] : parseInt(e.target.value)})
            setPriceError(false);
        }
    }

    useEffect(()=>{
        if((filters.maxPrice < filters.minPrice) && filters.minPrice !== "" && filters.maxPrice != "") setPriceError(true)
        else setPriceError(false);
    },[filters.maxPrice, filters.minPrice])


    return(
        <div id="filterComponentAudience">
            
            <div className="row">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Product Id
                </div>
                <div className="col-9">
                    <CustomTextField
                        label     = "Product Id"
                        className = "w-100"
                        onChange  = {(e)=>setFilters({...filters, productCode : e.target.value})}
                        value     = {filters.productCode}
                    />
                </div>
            </div>

            <div className="row mt_24">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Price Range
                </div>
                <div className="col-9 d-flex space-between">
                    <CustomTextField
                        label     = "Min Price"
                        className = "w-50 mr_16"
                        type      = "number"
                        onChange  = {(e)=>handlePriceChange(e,'minPrice')}
                        value     = {filters.minPrice}
                    />
                    <CustomTextField
                        label     = "Max Price"
                        className = "w-50"
                        type      = "number"
                        onChange  = {(e)=>handlePriceChange(e,'maxPrice')}
                        value     = {filters.maxPrice}
                    />
                </div>
               {priceError && <div className="error text-right w-100 mt_8">Max price should be greater than min price</div>}
                
            </div>

            <div className="mt_24 row position-relative">
                <div className="col-3 Link13R color-neutral60 pt_16">
                    Created
                </div>
                <div className="col-9">
                    <div className={`subscribedDate Body14R color-neutral60 cp d-flex ${showDate && 'isActiveDate'}`} onClick={()=>setShowDate(!showDate)}>
                            {(filters.startDate != "" && filters.endDate != "") ? 
                            <>
                                <div>
                                    {new Date(filters.startDate).getDate() + '-' + (new Date(filters.startDate).getMonth() + 1) + '-' +new Date(filters.startDate).getFullYear()}
                                </div>
                                <span className="ml_8 mr_8">
                                    -
                                </span>
                                <div>
                                    {new Date(filters.endDate).getDate() + '-' + (new Date(filters.endDate).getMonth() + 1) + '-' +new Date(filters.endDate).getFullYear()}
                                </div>
                            </>
                            :
                            <div className="Body14R color-neutral60">Overall</div>    
                        }
                    </div>
                    <ClickOutside onClick={()=>setShowDate(false)}>
                        <div className="position-absolute dateBox">
                            {showDate && <CustomDateRange onChange={handleDateChange}/> }
                        </div>
                    </ClickOutside>
                </div>
            </div>

            <div className="d-flex mt_24 justify-flex-end">
                <div>
                    <CustomButton
                        btntext   = "Reset Filter"
                        varient   = "secondary"
                        className = "mr_16"
                        onClick   = {resetFilter}
                    />
                </div>
                <div>
                    <CustomButton
                        btntext  = "Search"
                        varient  = "primary"
                        disabled = {priceError && true}
                        onClick  = {handleFilterSearchFunc}
                    />
                </div>
            </div>
        </div>
    )
}