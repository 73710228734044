import axios from 'axios'
import { Handlers, utils } from '../utils'
import MainService from './Main.service'
import env from '../config'
const service  = '/v4/tag'

const Create = async({payload}) => await axios.post(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})

const GetQueriedTag = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const UpdateTag = async ({payload}) => await axios.put(env.API_URL+service+'/', payload, {headers: await MainService.getTokenHeader()})


const TagService = {
    Create                  : Handlers.Services(Create),
    GetQueriedTag           : Handlers.Services(GetQueriedTag),
    UpdateTag               : Handlers.Services(UpdateTag)
}

export default TagService
