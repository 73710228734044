import React, { useContext, useState } from 'react'

import {ChromePicker} from 'react-color';

import LogoUploader from '../../components/LogoUploader'
import CustomImageDropbox from '../../components/Imagedropbox';
import ClickOutside from '../../utils/ClickOutside';    
import CustomTextArea from '../../components/CustomTextArea';
import { Campaign, Fields } from '../../enums';
import CustomSelect from '../../components/CustomSelect';
import { Options, utils } from '../../utils';
import CustomSwitch from '../../components/CustomSwitch';

import Autocomplete from '../../components/googleMap/Autocomplete';
import GoogleMap from '../../components/googleMap/GoogleMap';
import CustomTextField from '../../components/CustomTextField';
import { CampaignContext } from '../../context/Campaign.context';
import ImageModal from '../../components/ImageModal';
import CustomsModal from '../../components/CustomModal';
import { ImageModalV2 } from '../../components/ImageModalV2/ImageModalV2';


const Logo = ({formik}) => {
    const logoChange =  (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        formik.setValues({...formik.values, logoFile: file[0]})
    }

    return (
        <div className="caption color-textfieldColor innerPadding opacity-10 cp pl_0">
            <div className='row'>
                <LogoUploader 
                    label="Brand Logo" 
                    btntext="Change" 
                    onChange={logoChange} 
                    src={formik.values.logoFile ? URL.createObjectURL(formik.values.logoFile) : formik.values.logo}
                />
            </div>
        </div>
    )
}

const Cover = ({formik}) => {

    const [imageModal, setImageModal] = useState({
        coverFile         : {open: false, imageEvent: '', return: ''},
        filledImageFile   : {open: false, imageEvent: '', return: ''},
        unFilledImageFile : {open: false, imageEvent: '', return: ''}
    })

    const campaign = useContext(CampaignContext)

    const coverChange = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        const resizedImage = await utils.resizeImage({file: file[0], width: 750, height: 280})
        formik.setValues({...formik.values, coverFile: resizedImage})
    }

    const filledChange = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        const resizedImage = await utils.resizeImage({file: file[0], width: 63, height: 63})
        formik.setValues({...formik.values, filledImageFile: resizedImage})
    }

    const unfilledChange = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        const resizedImage = await utils.resizeImage({file: file[0], width: 63, height: 63})
        formik.setValues({...formik.values, unFilledImageFile: resizedImage})
    }

    const onChange = (e) => {
        formik.setValues({...formik.values, numberOfStamps: e.target.value})
    }

    return (
        <>
            <div className="caption color-textfieldColor innerPadding opacity-10 cp">
                Brand Cover: 
                <div className="caption color-caption opacity-10 cp fs-10">
                    (Recommended image size: 750x288)
                </div>
            </div>
            <div className="innerPadding">
                <CustomImageDropbox 
                    onChange = {coverChange}
                    // onChange = {(e)=> {setImageModal({...imageModal, coverFile: {...imageModal.coverFile, open: true, imageEvent: e}}) }}
                    text     = {<>
                        Drop your brand cover image here <br/><div className="fw-6"> Or </div>
                        <div className="mb_0">
                            <span className="subtitle1 color-Secondary fw-5">Browse</span><br/>
                            <span className="Body14R color-Secondary text-center fs-10">Recommended image size: 750x288 </span>
                        </div>
                        </>
                    }
                />
            </div>

            <CustomsModal
                open      = { imageModal.coverFile.open }
                onClose   = { ()=> setImageModal({...imageModal, coverFile: {...imageModal.coverFile, open: false}}) }
                minWidth  = "470px"
                minHeight = "470px"
                padding   = "0px"
                component = { 
                    <ImageModalV2 
                        imageModal    = { imageModal }
                        setImageModal = { setImageModal }
                        image         = { 'coverFile' }
                        type          = { 'cover' }
                        formik        = { formik }
                    />
                }
            />  

            {
                campaign.campaignType === Campaign.CampaignTypes.STAMP_CARD &&
                <>
                <div className='row'>
                    <LogoUploader 
                        htmlFor  = "filledImage"
                        label    = "Filled Image"
                        btntext  = "Change"
                        onChange = {filledChange}
                        // onChange = {(e)=> {setImageModal({...imageModal, filledImageFile: {...imageModal.filledImageFile, open: true, imageEvent: e}}) }}
                        src      = {formik.values.filledImageFile ? URL.createObjectURL(formik.values.filledImageFile) : formik.values.filledImage}
                    />
                </div>

                <CustomsModal
                    open      = { imageModal.filledImageFile.open }
                    onClose   = { ()=> setImageModal({...imageModal, filledImageFile: {...imageModal.filledImageFile, open: false}}) }
                    minWidth  = "470px"
                    minHeight = "470px"
                    padding   = "0px"
                    component = { 
                        <ImageModalV2 
                            imageModal    = { imageModal }
                            setImageModal = { setImageModal }
                            image         = { 'filledImageFile' }
                            formik        = { formik }
                        />
                    }
                />  

                <div className='row'>
                    <LogoUploader 
                        htmlFor  = "unFilledImage"
                        label    = "UnFilled Image"
                        btntext  = "Change"
                        onChange = {unfilledChange}
                        // onChange = {(e)=> {setImageModal({...imageModal, unFilledImageFile: {...imageModal.unFilledImageFile, open: true, imageEvent: e}}) }}
                        src      = {formik.values.unFilledImageFile ? URL.createObjectURL(formik.values.unFilledImageFile) : formik.values.unFilledImage}
                    />
                </div>

                <CustomsModal
                    open      = { imageModal.unFilledImageFile.open }
                    onClose   = { ()=> setImageModal({...imageModal, unFilledImageFile: {...imageModal.unFilledImageFile, open: false}}) }
                    minWidth  = "470px"
                    minHeight = "470px"
                    padding   = "0px"
                    component = { 
                        <ImageModalV2 
                            imageModal    = { imageModal }
                            setImageModal = { setImageModal }
                            image         = { 'unFilledImageFile' }
                            formik        = { formik }
                        />
                    }
                />  

                <div>
                    <CustomSelect 
                        className   = "w-100"
                        options     = {Options.getNumberOfStamps()}
                        name        = "numberOfStamps"
                        label       = "Number Of Stamps"
                        value       = {formik.values.numberOfStamps}
                        onChange    = {onChange}
                    />
                </div>
                </>
            }
        </>
    )
}

const Colors = ({formik}) => {
    const [colorPallete, setColorPallete] = useState({
        background : false,
        font       : false
    })

    const handleColorChange = ({key, color}) => formik.setValues({...formik.values, [key]: color})

    return (
        <>
            <div className="caption color-textfieldColor innerPadding opacity-10 cp">
                Background Color:
            </div>
            <ClickOutside onClick={() => setColorPallete({...colorPallete, background: false})}>
                <div className="backgroundColorBox d-flex ml_15 cp" onClick = {()=> setColorPallete({...colorPallete, background: !colorPallete.background})}>
                    <div className="colorCircle" style={{backgroundColor: formik.values.backgroundColor}}>
                    </div>
                    <div className="colorCodeText ml_24 Body14R mt_5">
                        {formik.values.backgroundColor}
                    </div>
                </div>
                {colorPallete.background &&
                    <ChromePicker 
                        className = "pallette position-absolute ml_15"
                        color     = {formik.values.backgroundColor}
                        onChange  = {changedColor => handleColorChange({key: 'backgroundColor', color: changedColor.hex})}          
                    />
                }  
            </ClickOutside>
            <div className="caption color-textfieldColor innerPadding mt_24 opacity-10 cp">
                Font Color:
            </div>
            <ClickOutside onClick={() => setColorPallete({...colorPallete, font: false})}>
                <div className="backgroundColorBox d-flex ml_15 cp" onClick = {()=> setColorPallete({...colorPallete, font: !colorPallete.font})}>
                    <div className="colorCircle" style={{backgroundColor: formik.values.fontColor}}>
                    </div>
                    <div className="colorCodeText ml_24 Body14R mt_5">
                        {formik.values.fontColor}
                    </div>
                </div>
                {colorPallete.font &&
                    <ChromePicker 
                        className = "pallette position-absolute ml_15"
                        color     = {formik.values.fontColor}
                        onChange  = {changedColor => handleColorChange({key: 'fontColor', color: changedColor.hex})}
                    />
                }  
            </ClickOutside>
        </>
    )
}

const LogoText = ({formik, property}) => {
    console.log('formik.values.apple[property].key',formik.values.apple);

    return (
        <div className="mt_24">
            <div>
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Key</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8"
                        placeholder = "key"
                        name        = {`apple.${property}.key`}
                        value       = {'%'+formik.values.apple[property].key+'%'}
                        readOnly    = {formik.values.apple[property].reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property].key}</div>
            </div>


            <div className="mt_24">
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Value</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8"
                        name        = {`apple.${property}.value`}
                        placeholder = "value"
                        value       = {formik.values.apple[property].value || ''}
                        readOnly    = {formik.values.apple[property].reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property].value}</div>
            </div>

        </div>
    )
}

const FieldDetails = ({formik, property, position}) => {

    return (
        <div className="mt_24">
            <div>
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Key</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8"
                        placeholder = "key"
                        name        = {`apple.${property}[${position}].key`}
                        value       = {'%'+formik.values.apple[property][position].key+'%'}
                        onChange    = {formik.handleChange}
                        readOnly    = {formik.values.apple[property][position].reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property][position] && formik.errors.apple[property][position].key}</div>
            </div>

            <div className="mt_16">
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Label</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8 text-capitalize"
                        placeholder = "label"
                        name        = {`apple.${property}[${position}].label`}
                        value       = {formik.values.apple[property][position].label}
                        onChange    = {formik.handleChange}
                        // readOnly    = {selectedField.reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property][position] && formik.errors.apple[property][position].label}</div>
            </div>

            <div className="mt_24">
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Value</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8"
                        name        = {`apple.${property}[${position}].value`}
                        placeholder = "value"
                        value       = {formik.values.apple[property][position].value || ''}
                        onChange    = {formik.handleChange}
                        readOnly    = {formik.values.apple[property][position].reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property][position] && formik.errors.apple[property][position].value}</div>
            </div>

            <div className="mt_24">
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Notification Message</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8"
                        name        = {`apple.${property}[${position}].changeMessage`}
                        placeholder = "updates"
                        value       = {formik.values.apple[property][position].changeMessage || ''}
                        onChange    = {formik.handleChange}
                        // readOnly    = {selectedField.reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property][position] && formik.errors.apple[property][position].changeMessage}</div>
            </div>

            <div>
                <div className="fieldDetail body1 mt_24">Text Alignment</div>
                <CustomSelect 
                    options  = {Options.textAlignment()}
                    name     = {`apple.${property}[${position}].textAlignment`}
                    value    = {formik.values.apple[property][position].textAlignment}
                    onChange = {formik.handleChange}
                />
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property][position] && formik.errors.apple[property][position].textAlignment}</div>
            </div>

            <div>
                <div className="mt_24"></div>
                <CustomTextArea 
                    classes  = "col-12 fieldDetailBox resize-none p_0 fs-14"
                    rows     = "3"
                    label    = "Field Detail"
                    name     = {`apple.${property}[${position}].description`}
                    value    = {formik.values.apple[property][position].description}
                    onChange = {formik.handleChange}
                    readOnly = {formik.values.apple[property][position].reference === Fields.FieldReference.CUSTOM ? false : true}
                />
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple[property] && formik.errors.apple[property][position] && formik.errors.apple[property][position].description}</div>
            </div>
        </div>
    )
}


const Barcodes = ({formik}) => {

    return (
        <div className="mt_24">

            <div className="mt_24">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="caption2 fw-5 fs-12 color-textfieldColor">Show QR Code</div>
                    <CustomSwitch 
                        name     = "qrcode"
                        checked  = {formik.values.qrcode}
                        onChange = {formik.handleChange}
                    />
                </div>
            </div>

            <div className="mt_24">
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Alternate Text</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8 text-capitalize"
                        placeholder = "label"
                        name        = {'apple.barcodes.altText'}
                        value       = {formik.values.apple.barcodes.altText}
                        onChange    = {formik.handleChange}
                        readOnly
                        // readOnly    = {selectedField.reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
            </div>

            <div className="mt_24">
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Message</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8"
                        placeholder = "value"
                        name        = {'apple.barcodes.message'}
                        value       = {formik.values.apple.barcodes.message}
                        onChange    = {formik.handleChange}
                        readOnly
                        // readOnly    = {selectedField.reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
            </div>

            <div>
                <div className="fieldDetail body1 mt_24">Code Format</div>
                <CustomSelect 
                    options  = {Options.appleCodesFormat()}
                    name     = {'apple.barcodes.format'}
                    value    = {formik.values.apple.barcodes.format}
                    onChange = {formik.handleChange}
                />
            </div>
        </div>
    )
}

const Location = ({formik, position}) => {

    const getPlace = (e) => {
        const Geocoder = new window.google.maps.Geocoder()
        const placeRequest = {location: {lat: e.latLng.lat(), lng: e.latLng.lng()}}

        Geocoder.geocode(placeRequest, (places, status) => {
            if(status !== window.google.maps.GeocoderStatus.OK) return
            const locations = utils.summarisedLocation(places[0])

            const location = {
                latitude     : locations.lat,
                longitude    : locations.lng,
                relevantText : formik.values.apple.locations[position].relevantText,
                address      : locations.address
            }
            formik.values.apple.locations[position] = location
            const apple = {...formik.values.apple, locations: [...formik.values.apple.locations]}
    
            formik.setValues({...formik.values, apple})        
        })
    }
    
    const locationSummary     = (e) => {
        const location = {
            latitude     : e.lat,
            longitude    : e.lng,
            relevantText : formik.values.apple.locations[position].relevantText,
            address      : e.address
        }
        formik.values.apple.locations[position] = location
        // const apple = {...formik.values.apple, locations: [...formik.values.apple.locations]}

        formik.setValues({...formik.values})
    }


    return(
        <>
        <div>
            <CustomTextField 
              className   = "w-100"
              type        = "text"
              name        = {`apple.locations[${position}].relevantText`}
              value       = {formik.values.apple.locations[position].relevantText}
              onChange    = {formik.handleChange}
              inputProps  = {{ onFocus: formik.handleBlur }}
            />
        </div>
        <div className='mt_20'>
            <Autocomplete 
                className       = "w-100"
                variant         = "standard"
                label           = "Address"
                name            = {`apple.locations[${position}].address`}
                value           = {formik.values.apple.locations[position].address ? formik.values.apple.locations[position].address : ''}
                onChange        = {formik.handleChange}
                InputProps      = {{ disableUnderline: false, style: { fontSize: 16,color: 'color(neutral80)'} }}
                locationSummary = {locationSummary}
                // error           = {formik.errors.apple.locations[position] && !formik.values.apple.locations[position] ? formik.errors.apple.locations[position].address : false}
                // helperText      = {formik.errors.apple.locations[position] && !formik.values.apple.locations[position] ? formik.errors.apple.locations[position].address : ''}
            /> 
        </div>
         <div className='w-100 mt_20' style={{height: '300px'}}>
                 <GoogleMap 
                    width     = {"100%"}
                    height    = {"100%"}
                    zoom      = {formik.values.apple.locations[position] && formik.values.apple.locations[position].lat ? 15 : 4}
                    draggable = {true}
                    position  = {{lat: formik.values.apple.locations[position].latitude, lng: formik.values.apple.locations[position].longitude}}
                    dragEnd   = {getPlace}
                />
        </div>

     </>
    )
}

const Beacon = ({formik, position}) => {
    return(
        <div className="mt_24">
            <div>
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Relevant Text</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8"
                        placeholder = "relevantText"
                        name        = {`apple.beacons[${position}].relevantText`}
                        value       = {formik.values.apple.beacons[position].relevantText}
                        onChange    = {formik.handleChange}
                        style       = {{width: '100%'}}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple.beacons && formik.errors.apple.beacons[position] && formik.errors.apple.beacons[position].relevantText}</div>
            </div>

            <div className="mt_16">
                <div className="caption2 fw-5 fs-12 color-textfieldColor">Proximity UUID</div>
                <div className="descriptionField pb_5">
                    <input 
                        className   = "caption2 fw-4 fs-16 color-textfieldColor border-none mt_8 text-capitalize"
                        placeholder = "proximityUUID"
                        name        = {`apple.beacons[${position}].proximityUUID`}
                        value       = {formik.values.apple.beacons[position].proximityUUID}
                        onChange    = {formik.handleChange}
                        style       = {{width: '100%'}}
                        // readOnly    = {selectedField.reference === Fields.FieldReference.CUSTOM ? false : true}
                    />
                </div>
                <div className="error ml_0">{formik.errors.apple && formik.errors.apple.beacons && formik.errors.apple.beacons[position] && formik.errors.apple.beacons[position].proximityUUID}</div>
            </div>
        </div>
    )
}




function ApplePassFieldDetails({formik, passFieldFocused}) {
    const PassFieldsDetails = {
        body            : <Colors       formik = {formik} />,
        logo            : <Logo         formik = {formik} />,
        logoText        : <LogoText     formik = {formik} property={'logoText'} />,
        headerFields    : <FieldDetails formik = {formik} property={'headerFields'} position={passFieldFocused.index}/>,
        cover           : <Cover        formik = {formik} />,
        secondaryFields : <FieldDetails formik = {formik} property={'secondaryFields'} position={passFieldFocused.index}/>,
        auxiliaryFields : <FieldDetails formik = {formik} property={'auxiliaryFields'} position={passFieldFocused.index}/>,
        qrcode          : <Barcodes     formik = {formik} selectedField = {formik.values.apple.barcodes}/>, // pending
        backFields      : <FieldDetails formik = {formik} property={'backFields'} position={passFieldFocused.index}/>,
        locations       : <Location     formik = {formik} position={passFieldFocused.index}/>,
        beacons         : <Beacon       formik = {formik} position={passFieldFocused.index}/>
    }

    return (
        <>
            {PassFieldsDetails[Object.keys(passFieldFocused)[0]]}
        </>
    )
}

export default ApplePassFieldDetails
