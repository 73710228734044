const GrowhttoolTypes = {
    LANDING_PAGE  : 'landingPage',
    WIDGET        : 'widget',
    USER_DASHBOARD: 'userDashboard',
    QR_CODE       : 'qrcode',
    API           : 'api',
    APP           : 'app',
    POS           : 'pos',
    MANYCHAT      : 'manychat',
    SILFERBOT     : 'silferbot',
    ACTIVECHAT    : 'activechat',
    CHATFUEL      : 'chatfuel',
    UCHAT         : 'uchat',
    GHL           : 'ghl',
    CHATRACE      : 'chatrace',
    LIGHTSPEED    : 'lightspeed',
    POPUP         : 'popup',

}

const QRTypes = {
    IPHONE   : 'iphone',
    WEBSITE  : 'website',
    IN_STORE : 'instore',
    COVID    : 'covid'
}

const ManyChatCustomFields = {
    CURRENTPOINTS     : 'currentPoints',
    REFERRAL          : 'referral',
    PASSURL           : 'passUrl',
    LASTSPENDINGAMOUNT: 'lastSpendingAmount',
    LASTREDEEMDATE    : 'lastRedeemDate',
    LASTDEALAVAIL     : 'lastDealAvail',
    LASTPOINTS        : 'lastPoints',
    TOTALVISITS       : 'totalVisits',
    TOTALSPENDING     : 'totalSpending',
    TOTALPOINTSUSED   : 'totalPointsUsed',
    isWallet          : 'isWallet',
    totalReffered     : 'totalReffered',
}

const GhlCustomFields = {
    CURRENTPOINTS        : 'currentPoints',
    LASTSPENDINGAMOUNT   : 'lastSpendingAmount',
    LASTREDEEMDATE       : 'lastRedeemDate',
    TOTALVISITS          : 'totalVisits',
    TOTALSPENDING        : 'totalSpending',
    LASTDEALAVAIL        : 'lastDealAvail',
    LASTPOINTS           : 'lastPoints',
    TOTALPOINTSUSED      : 'totalPointsUsed',
    isWallet             : 'isWallet',
    totalReffered        : 'totalReffered',
    join_platform        : 'join_platform',
    walletly_pass_url    : 'walletly_pass_url',
    walletly_referral_url: 'walletly_referral_url'

}

const UChatCustomFields = {
    CURRENTPOINTS     : 'currentPoints',
    LASTSPENDINGAMOUNT: 'lastSpendingAmount',
    LASTREDEEMDATE    : 'lastRedeemDate',
    TOTALVISITS       : 'totalVisits',
    TOTALSPENDING     : 'totalSpending',
    LASTDEALAVAIL     : 'lastDealAvail',
    LASTPOINTS        : 'lastPoints',
    TOTALPOINTSUSED   : 'totalPointsUsed',
    isWallet          : 'isWallet',
    totalReffered     : 'totalReffered',
    REFERRAL          : 'referral',
    PASSURL           : 'passUrl',
}

const CharaceCustomFields = {
    CURRENTPOINTS     : 'currentPoints',
    LASTSPENDINGAMOUNT: 'lastSpendingAmount',
    LASTREDEEMDATE    : 'lastRedeemDate',
    TOTALVISITS       : 'totalVisits',
    TOTALSPENDING     : 'totalSpending',
    LASTDEALAVAIL     : 'lastDealAvail',
    LASTPOINTS        : 'lastPoints',
    TOTALPOINTSUSED   : 'totalPointsUsed',
}

const GrowthTools = {
    GrowhttoolTypes,
    QRTypes,
    ManyChatCustomFields,
    GhlCustomFields,
    UChatCustomFields,
    CharaceCustomFields
}

export default GrowthTools;   