import React , {useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router';

import { LayoutContext } from '../../../../context/Layout.context';
import { UserContext } from '../../../../context/User.context';
import { BrandContext } from '../../../../context/Brand.context';

import CustomButton from '../../../../components/CustomButton';

import { CircularProgress } from '@mui/material';
import CustomsModal from '../../../../components/CustomModal';
import AddGiftCard from './AddGiftCard';

import { BrandService, PassService, TransactionService, UserService } from '../../../../services';
import localforage from 'localforage';
import { PassDesign, User } from '../../../../enums';

import Loader from '../../../../components/Loader';
import EmptyScreen from '../../../../components/EmptyScreen';

export default function SingleGiftCard({formik}) {

    const layout = useContext(LayoutContext)
    const brand  = useContext(BrandContext)
    const user   = useContext(UserContext)

    const history = useHistory()

    const [state, setState] = useState({
        giftCardData: history.location.state,
        userData    : '',
        loader      : false,
        passesData  : [],
        loaderBtn   : false,
        index       : ''
    })

    const onLoad = async () => {
        setState({...state, loader : true});
        let campaignSubscribers = await localforage.getItem('campaignSubscribers');
        if(campaignSubscribers && campaignSubscribers[state.giftCardData.campaignCode]) setState({...state, userData : campaignSubscribers[state.giftCardData.campaignCode], loader : false})
        if(!campaignSubscribers || !campaignSubscribers[state.giftCardData.campaignCode] || true){
            if(!campaignSubscribers) campaignSubscribers = {};
            const {error, response} = await PassService.GetQueriedPass({query : {brandId: brand._id, campaignId : state.giftCardData._id, delete: false}});
            if(response) {
                setState({...state,userData: campaignSubscribers && campaignSubscribers[state.giftCardData.campaignCode] ? campaignSubscribers[state.giftCardData.campaignCode] : '' , passesData : response.data})
                let query = {_id : brand._id}
                const subscriber = await BrandService.GetSubscribers({query});
                query = {delete: false}
                const senders = await UserService.GetByEmail({query});
                let data = []; 
                for (const pass of response.data){
                    for (const user of subscriber.response.data){
                        for(const sender of senders.response.data){
                            if(pass.userId == user.user._id && pass.senderId === sender._id) data.push({
                                redeemStatus: pass[PassDesign.PassTypes.COUPON].redeemed,
                                passCode : pass.passCode,
                                user: {...user.user, senderEmail : sender.email, senderName : sender.firstName}
                            })
                        }
                    }
                }
                campaignSubscribers[state.giftCardData.campaignCode] = data;
                localforage.setItem('campaignSubscribers',campaignSubscribers);
                setState({...state, userData : data, loader : false})
            }
            else{
                campaignSubscribers[state.giftCardData.campaignCode] = []
                localforage.setItem('campaignSubscribers',campaignSubscribers);
                setState({...state, userData :[], loader : false})
            }
        }
        // else 
        // {
        //     if(campaignSubscribers && campaignSubscribers[state.giftCardData.campaignCode] && campaignSubscribers[state.giftCardData.campaignCode].length > 0) setState({...state, userData : campaignSubscribers[state.giftCardData.campaignCode], loader : false})
        //     else {
        //         campaignSubscribers[state.giftCardData.campaignCode] = []
        //         localforage.setItem('campaignSubscribers',campaignSubscribers);
        //         setState({...state, userData : [], loader : false})
        //     }
        // }
    }

    
    useEffect(onLoad, [])

    const [show, setShow] = useState({
        deleteModal  : false,
        giftcardModal: false,
    })

    useEffect(()=>{
        layout.setLayout({
            title      : "Gift Card Detail",
            expandedBar: false,
            switch : '',
            button     : <div>
            {state.giftCardData && <CustomButton 
                btntext = {"Delete"}
                varient = "warningSecondary"
                onClick = {()=>setShow({...show, deleteModal : true})}
                
            />}
            <CustomButton 
                btntext   = {"Cancel"}
                varient   = "secondary"
                className = "ml_8"
                onClick   = {()=>{
                        const location = window.location.pathname.split('/')
                        location.pop()
                        history.push(location.join('/'))
                }}
            />
            <CustomButton 
                btntext   = {"Edit Gift Card"}
                // disabled  = {state.loader || !formik.isValid}
                icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                onClick   = {()=>setShow({...show, giftcardModal : true})}
                className = "ml_8"
            />
        </div>
        });

    },[])

    const tableHeadings = [
        'Sender Name', 
        'Sender Email',
        'Reciever Name', 
        'Receiver Email',
        'Redeem Status',
        'Test Redeem'
    ]


    //formik.isValid, formik.values, state.loader, formik.dirty
  return( 
      <>
    {console.log('userData', state.userData)}
            {state.loader || !state.userData ? <Loader baseHeight={'85vh'} width={180} height={180} /> 
                :
            state.userData.length > 0 ?
    
            <div id="SingleGiftCard">
                <div className="giftcardTopTitleBox Heading22M color-neutral100 capitalize">
                {state.giftCardData.campaignName}
        </div>
                <div className="userTable mt_16">
                    <div className="tableHeaderRow d-flex">
                    ` {tableHeadings.map((heading,idx)=>
                            <div key={idx} className="singleHeading Link13M color-neutral60">
                            {heading}
                            </div>
                        )}
                    </div>`
                    <div className="tableDataRow">
                        {state.userData.map((item, idx)=>
                        <div className="d-flex singleDataRow">
                            <div key={idx} className="Link13R color-Paragaph singleData capitalize">
                                {item.user.senderName}
                            </div>
                            <div key={idx} className="Link13R color-Paragaph singleData">
                                {item.user.senderEmail}
                            </div>
                            <div key={idx} className="Link13R color-Paragaph singleData capitalize">
                                {item.user.firstName + ' ' + item.user.lastName}
                            </div>
                            <div key={idx} className="Link13R color-Paragaph singleData">
                                {item.user.email}
                            </div>
                            <div key={idx} className={`Link13R color-Paragaph singleData w-fit-content`}>
                                <div className={`redeemBox ${item.redeemStatus ? 'active' : 'notActive'}`}>
                                {item.redeemStatus ? 'Yes' : 'No'}
                                </div>
                            </div>
                            <div key={idx} className={`Link13R color-Paragaph singleData w-fit-content`}>
                                <div>
                                    <CustomButton 
                                        disabled = {item.redeemStatus}
                                        icon     = {state.loaderBtn && state.index === idx && <CircularProgress className="align-middle" size={20} color={'inherit'}/>}
                                        btntext  = {'redeem'}
                                        onClick  = {async ()=>{
                                            setState({...state, loaderBtn : true , index : idx });
                                            const campaignSubscribers = await localforage.getItem('campaignSubscribers');
                                            // console.log(item.passCode);
                                            const {response, error} = await TransactionService.GiftCardRedemption({toaster : true, payload : {passCode : item.passCode}})
                                            if(response)
                                            {
                                                let data = [];
                                                state.userData.map((element, idx)=>{                                      
                                                    if(element.passCode === item.passCode)
                                                    {
                                                        data.push({
                                                            ...element,
                                                            redeemStatus : true
                                                        })
                                                    }
                                                    else data.push({...element})
                                                })

                                                campaignSubscribers[state.giftCardData.campaignCode].map((value, idx)=>{
                                                    if(value.passCode === item.passCode) campaignSubscribers[state.giftCardData.campaignCode][idx].redeemStatus = true;
                                                })
                                                localforage.setItem('campaignSubscribers', campaignSubscribers)
                                                setState({...state,userData: data, loaderBtn : false, index : ''});
                                            }
                                            if(error) setState({...state, loaderBtn : false, index : ''});
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        )}
                     </div>

                </div>

      {
          show.giftcardModal && 
          <CustomsModal 
            component = {<AddGiftCard item={state.giftCardData} onClose={()=> setShow({...show, giftcardModal : false})}/>}
            open      = { show.giftcardModal }
            minWidth  = {'530px'}
            onClose   = {()=> setShow({...show, giftcardModal : false})}
        />
      }


            </div>
            
            :
            <>
            {state.userData && state.userData.length === 0 && !state.loader && <EmptyScreen otherCaption={'You donot have any subscribers yet'} showBtn={false} title={'Subscribers'} />}
            </>
        }
  </>
  )}
