import React, {useState, useEffect, useContext} from 'react'

import {useHistory} from 'react-router-dom'
import localforage, * as localForage from 'localforage'

import CustomButton from '../../../components/CustomButton'
import Loader from '../../../components/Loader'
import ReactTooltip from 'react-tooltip'
import CustomSwitch from '../../../components/CustomSwitch'
import CustomRadio from '../../../components/CustomRadio'


import {SvgIcons} from '../../../icons'

import {AgencyContext} from '../../../context/Agency.context'
import {LayoutContext} from '../../../context/Layout.context'
import {BrandContext} from '../../../context/Brand.context'

import { ColorSchemeCode } from '../../../enums/ColorScheme'
import { CircularProgress, colors, RadioGroup } from '@mui/material'

import { CampaignService, FlowService, GrowthtoolService } from '../../../services'

import { useFormik } from 'formik';
import { GrowthTools } from '../../../enums'
import { ChromePicker } from 'react-color'
import { ClickOutside, utils } from '../../../utils'

export default function WidgetNew() {
    const [flow, setFlow] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})
    const [loader, setLoader] = useState(false);
    
    const brand   = useContext(BrandContext)
    const layout  = useContext(LayoutContext)
    const agency  = useContext(AgencyContext)
    const history = useHistory();
    
    const [growthtool, setGrowthtool] = useState(history.location.state)

    const [state, setState] = useState({
        processing : false,
        apiError   : '',
    })

    const [expand,setExpand] = useState({
        redeemingWays : false,
    });

    const onLoad = async() => {
        setLoader(true)
        layout.setLayout({editTitle  : formik.values.growthtoolName || ""})
// 
        // const {response, error} = await FlowService.Get({query: {triggerType : "signUp",brandId: brand._id, delete: false}})
        const {response, error} = await FlowService.Get({query: { brandId: brand._id, flowType:'new' ,delete: false }})
        if(response) {
            setFlow(response.data);
        }
        setLoader(false)
    }
    
    const handleCampaigns = (campaigns) => {
        setCampaigns([...campaigns])
        const campaign = campaigns.find(campaign => campaign._id === formik.values.campaignId)
        setSelectedCampaign(campaign || {})
    }
    
    const CallServices = async () => {
        const query = {brandId: brand._id,campaignType: 5}
        const { error, response } = await CampaignService.GetQueriedCampaign({query})
        response && handleCampaigns(response.data)
    }
    
    useEffect(()=>{
        layout.setLayout({
            borderBottom : true
        })
        onLoad();
        CallServices();
        
    },[])
    
    const initState = {
        flow             : history.location.state && history.location.state.flowId ? history.location.state.flowId[0]                                                                   : '',
        growthtoolName   : history.location.state && history.location.state.growthtoolName ? history.location.state.growthtoolName                                                      : '',
        growthtoolType   : history.location.state && history.location.state.growthtoolType ? history.location.state.growthtoolType                                                      : '',
        flowName         : '',
        logo             : history.location.state && history.location.state.widget && history.location.state.widget.logo  ? history.location.state.widget.logo                          : brand.brandLogo,
        redeemingWays    : history.location.state && history.location.state.redeemingWays ? history.location.state.redeemingWays                                                        : false,
        points           : history.location.state && history.location.state.points ? history.location.state.points                                                                      : false,
        script           : history.location.state && history.location.state.widget && history.location.state.widget.script  ? history.location.state.widget.script                      : `<script type="text/javascript" brand="${window.location.origin}/&${brand._id}" src="https://widget.walletly.ai/app.c328ef1a.js"></script>`,
        primaryColor     : history.location.state && history.location.state.widget && history.location.state.widget.primaryColor  ? history.location.state.widget.primaryColor          : '#B2831E',
        theme            : history.location.state && history.location.state.widget && history.location.state.widget.theme  ? history.location.state.widget.theme                        : 'dark',
        heading          : history.location.state && history.location.state.widget && history.location.state.widget.heading  ? history.location.state.widget.heading                    : brand.brandName,
        description      : history.location.state && history.location.state.widget && history.location.state.widget.description  ? history.location.state.widget.description            : brand.description,
        subHeading       : history.location.state && history.location.state.widget && history.location.state.widget.subHeading  ? history.location.state.widget.subHeading              : "Points",
        subDescription   : history.location.state && history.location.state.widget && history.location.state.widget.subDescription  ? history.location.state.widget.subDescription      : "Earn more Points for different actions, and turn those Points into awesome rewards!",
        referral         : history.location.state && history.location.state.referral ? history.location.state.referral                                                                  : false,
        authentication   : history.location.state && history.location.state.widget && history.location.state.widget.authentication  ? history.location.state.widget.authentication      : "none",
        rewardHeading1   : history.location.state && history.location.state.widget && history.location.state.widget.rewardHeading1  ? history.location.state.widget.rewardHeading1      : "Ways to Earn",
        rewardHeading2   : history.location.state && history.location.state.widget && history.location.state.widget.rewardHeading2  ? history.location.state.widget.rewardHeading2      : "Ways to Redeem",
        redeemDescription: history.location.state && history.location.state.widget && history.location.state.widget.redeemDescription  ? history.location.state.widget.redeemDescription: "Redeem now:",
        buttonone        : history.location.state && history.location.state.widget && history.location.state.widget.buttonone  ? history.location.state.widget.buttonone                : "Sign In",
        buttontwo        : history.location.state && history.location.state.widget && history.location.state.widget.buttontwo  ? history.location.state.widget.buttontwo                : "Sign Up",

  
    }

    const formik = useFormik({
        initialValues      : { ...initState },
        isInitialValid     : true,
        enableReinitialize : true
    })

    const setFlowName = () => {
        flow.filter((element, idx)=>{
            (element._id == formik.values.flow) && formik.setValues({...formik.values, flowName : element.name})
        })
    }

    useEffect(()=>{
        setFlowName();
    },[formik.values.flow, flow])

    const handleSubmit = async() => {
            setState({...state, processing: true})

            let logo
            const user        = await localForage.getItem('user')
            const desiredPath = `${user._id}/${brand.brandName}`

            // uploading logo
            if(typeof formik.values.logo != "string"){
                let logoupload = await utils.uploadImage({file: formik.values.logo, desiredPath})
                logo = logoupload.response.data;
            }
            else{
                logo = formik.values.logo
            }

            if(logo && logo.error) return setState({loader: false, apiError: logo.error.message})


            const payload = {
                _id           : growthtool._id,
                campaignId    : campaigns[0]._id,
                flowId: flow.length === 1 ? [flow[0]._id] : [formik.values.flow],
                growthtoolName: layout.elements.editTitle,
                redeemingWays : formik.values.redeemingWays,
                points        : formik.values.points,
                referral      : formik.values.referral,
                widget        : { 
                    script           : formik.values.script,
                    primaryColor     : formik.values.primaryColor,
                    theme            : formik.values.theme,
                    heading          : formik.values.heading,
                    description      : formik.values.description,
                    subHeading       : formik.values.subHeading,
                    subDescription   : formik.values.subDescription,
                    rewardHeading1   : formik.values.rewardHeading1,
                    rewardHeading2   : formik.values.rewardHeading2,
                    redeemDescription: formik.values.redeemDescription,
                    authentication   : formik.values.authentication,
                    buttonone        : formik.values.buttonone,
                    buttontwo        : formik.values.buttontwo,
                    logo             : logo,
                }
            }
            // console.log('payload ', payload);
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.WIDGET){
                const user = await localForage.getItem('user')
            }
    
            if(growthtool.growthtoolType === GrowthTools.GrowhttoolTypes.USER_DASHBOARD){
                const user = await localForage.getItem('user')
                payload.userDashboard.desiredPath = `${user._id}/${brand.brandName}`
            }

            console.log('payload',payload);
    
            const { error, response } = await GrowthtoolService.UpdateGrowthTool({toaster: true, payload})
            console.log('error ', error)
            console.log('response ', response)
            setState({...state, processing: false})
            if(error) return setState({...state, processing: false, apiError: error.message})

            let campaign = await localforage.getItem('campaign');
            console.log('campaign',campaign);
            const query = {brandId: brand._id,campaignId: campaign[0]._id || campaign._id};
            const GetQueriedGrowthTool = await GrowthtoolService.GetQueriedGrowthTool({query});
            localForage.setItem("GetQueriedGrowthTool", GetQueriedGrowthTool.response.data);
    
            const location = window.location.pathname.split('/')
            location.pop()
            location.pop()
            history.push(location.join('/'))
            return
        // }
    }

    useEffect(()=>{
        layout.setLayout({
            borderBottom: true,
            edit        : false,
            title       : "Widget",
            backTitles  : ['Channels'],
            switch : '',
            button: <>
            <CustomButton
                    btntext  = {'Update'}
                    type     = {'submit'}
                    onClick  = {handleSubmit}
                    disabled = {!formik.isValid}
                    icon     = {state.processing && <CircularProgress size={20} color={'inherit'}/>}
                />
            </>
        })
        // layout.setLayout({
        //     edit        : true,
        //     placeholder : "Widget",
        //     borderBottom: true,
        //     backTitles  : ['Channels'],
        //     switch      : "",
        //     button      : 
        //         <CustomButton
        //             btntext  = {'Update'}
        //             type     = {'submit'}
        //             onClick  = {handleSubmit}
        //             disabled = {!formik.isValid}
        //             icon     = {state.processing && <CircularProgress size={20} color={'inherit'}/>}
        //         />
        // })
    },[campaigns, formik.values, growthtool ,state.processing,layout.elements.editTitle])



    useEffect(()=>{
        layout.setLayout({
            editTitle : growthtool.growthtoolName || ''
        })
    },[])
    // WIDGET PREVIEW
    return (
        <div id="manyChatGrowthTool" >
            {!loader ? 
            <div className="h-100 d-flex">
                <div className="w-32">
                    <Leftpane flow={flow} formik={formik} expand={expand} setExpand={setExpand} />
                </div>
                <div className="flowSection w-100 bg-color-neutral0 middle">
                   <div className="widgetBox d-flex flex-column position-relative">
                       {/* TOP HEADER */}
                        <div className="topBox position-relative" style={{background: formik.values.primaryColor}}>
                            {/* <div className="topBoxOverlay"></div> */}
                            {/* <div className="position-absolute z-index-1"> */}
                            {/* <img className="widgetLogo" src={(typeof formik.values.logo == 'string') ? formik.values.logo : URL.createObjectURL(formik.values.logo)} alt=""/> */}
                            <div className="mt_16 Body16R"  style={{color : formik.values.theme != 'dark' ? ColorSchemeCode.black : ColorSchemeCode.white}}>
                                Join the community      
                            </div>
                            <div className="Heading32M" style={{lineHeight: '35px !important', color : formik.values.theme != 'dark' ? ColorSchemeCode.black : ColorSchemeCode.white}}>
                               {formik.values.heading}
                            </div>  
                            {/* </div> */}
                        </div>

                        {/* BODY START */}
                        <div className="d-flex flex-column p_24" style={{borderBottomLeftRadius: 4, borderBottomRightRadius:4, background : formik.values.theme == 'dark' ? "#141414" : ColorSchemeCode.white}}>
                            <div className="upperBox z-index-20" style={{background : formik.values.theme == 'dark' ? ColorSchemeCode.neutral90 : '#F5F5F5'}}>
                                {/* <div className="Heading16M"  style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.white : ColorSchemeCode.neutral100}}>
                                    Become a member
                                </div> */}
                                <div className="Body14R mt_8 " style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.neutral0 : "#596173", wordBreak : 'break-all'}}>
                                  {formik.values.description}
                                </div>
                                <div className="joinBtn middle U14M"  style={{background : formik.values.primaryColor}}>
                                    {formik.values.buttonone}
                                </div>
                                <div className="joinBtn middle U14M"  style={{background : 'lightgray', color: 'black'}}>
                                {formik.values.buttontwo}

                                </div>
                            </div>

                            <div className="lowerBox" style={{background : formik.values.theme == 'dark' ? ColorSchemeCode.neutral90 : '#F5F5F5'}}>
                                <div className="Heading16M" style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.white : ColorSchemeCode.neutral100}}>
                                    {formik.values.subHeading}
                                </div>
                                <div className="Body14R mt_8 " style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.neutral0 : "#596173"}}>
                                    {formik.values.subDescription}
                                </div>
                                <div className="mt_20 mb_20 d-flex space-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <SvgIcons.WidgetEarnIcon color={formik.values.primaryColor} />
                                        <span className="ml_8 Body14R" style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.white : "#596173"}}>{formik.values.rewardHeading1}</span>
                                    </div>
                                    <span className="flip">
                                        <SvgIcons.ArrowExpand height="13" width="13" color={formik.values.theme == 'dark' ? ColorSchemeCode.white : "#596173"}/>
                                    </span>
                                </div>  
                                <div className="bar"></div>
                                <div className="mt_20 d-flex space-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <SvgIcons.WidgetRedeemIcon color={formik.values.primaryColor} />
                                        <span className="ml_8 Body14R" style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.white : "#596173"}}>{formik.values.rewardHeading2}</span>
                                    </div>
                                    <span className="flip">
                                        <SvgIcons.ArrowExpand height="13" width="13" color={formik.values.theme == 'dark' ? ColorSchemeCode.white : "#596173"}/>
                                    </span>
                                </div>  
                                <div className={`${expand.redeemingWays && 'expand'} redeemingWays pl_8`}>
                                        <p className="m_0 pt_16 pb_16 Body14R" style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.white : "#596173"}}>{formik.values.redeemDescription}</p>
                                        <div className='d-flex mb_24 singleFlow'>
                                            <img src="https://i0.wp.com/www.thinkingnutrition.com.au/wp-content/uploads/2020/08/Coffee-Cup-scaled.jpg?fit=2560%2C1920&ssl=1"  className="borderRadius-4 object-fit-cover" height="60px" width="80px"  alt=""/>
                                            <div className='ml_16 middle align-items-flex-start'>
                                                <p style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.white : "#596173"}} className="m_0 Body14M capitalize">
                                                    Free Coffee
                                                </p>
                                                <p className="Body12R capitalize m_0" style={{color : formik.values.theme == 'dark' ? ColorSchemeCode.white : "#596173"}}>
                                                    40 Points
                                                </p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div className="crossIcon position-absolute">
                            <SvgIcons.CrossIcon color={formik.values.theme == 'dark' ? ColorSchemeCode.white : ColorSchemeCode.black}/>
                        </div>

                        <div className="widgetCircle middle" style={{backgroundColor: formik.values.primaryColor}}>
                            <img className="widgetLogo" height="60" width="60" src={(typeof formik.values.logo == 'string') ? formik.values.logo : URL.createObjectURL(formik.values.logo)} alt=""/>
                        </div>
                   
                   </div>
                </div>
            </div> 
            : <Loader height="32px" width="32px" />}
        </div>
    )
}
 
const Leftpane = ({formik, flow, expand, setExpand}) => {

    const brand = useContext(BrandContext)
    const [selectedSection, setSelectedSection] = useState('styling')
    const [tooltipText, setTooltipText]         = useState('copy')
    const [show, setShow] = useState({
        primaryColorPallette   : false,
    })

    const handleCopy = () => {
        const element = document.getElementById('content')
        const r = document.createRange()
        r.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(r)
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        ReactTooltip.show(document.getElementById('tooltip'))
        setTooltipText('copied')
    }

    const hiddenFileInput = React.useRef(null);
  
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded){
            formik.setValues({...formik.values, logo : fileUploaded})
        }

    };
    
    return(
        <div className="leftSectionWidget">
            {/* Tab Bar */}
            <div className='d-flex cp pt_12 pb_12 border-bottom'>
                    <div className={`tabButton Link13R transition w-50 text-center ${selectedSection == 'styling' && 'selectedTabButton Link13M'}`} onClick={()=>setSelectedSection('styling')}>
                        Styling 
                    </div>
                    <div className={`tabButton Link13R transition w-50 text-center  ${selectedSection == 'setting' && 'selectedTabButton Link13M'} `} onClick={()=>setSelectedSection('setting')}>
                        Setting
                    </div>
            </div>
            {/* Tab Form */}
            <div className="tabBody">
                {
                    selectedSection == 'setting' ? 
                    // Main Body
                        <div>
                            {/* Script Section */}
                            <div className="border-bottom pt_8 pb_8">
                            <div className="Link12M color-neutral100 pt_8 pb_8">
                                Script
                            </div>

                            <div id="CopyText">
                                <div className="w-100">
                                    <div onClick={handleCopy} id="tooltip" className="cp w-100" data-for={'tooltipCopy'} data-tip={tooltipText} >
                                    <input 
                                            onClick   = {handleCopy}
                                            id        = "content"
                                            className = 'panelDropdown Link11R w-100 cp transition'
                                            value     = {formik.values.script}  
                                        /> 
                                    </div>
                                </div>
                                {<ReactTooltip 
                                    backgroundColor = {ColorSchemeCode.black}
                                    className       = "opacity-8 p_4 borderRadius-6"
                                    id              = "tooltipCopy"
                                    getContent      = {()=>tooltipText}/>
                                }
                            </div>
                            
                            </div>

                            {/* Connect Flow Section */}
                            <div className="border-bottom pt_8 pb_8 ">
                            <div className="Link12M color-neutral100 pt_8 pb_8">
                                Connect Flow
                            </div>

                            <div className='d-flex align-items-center gap-12'>
                                <div className="Link11R color-neutral70 w-30">
                                    Flow
                                </div>
                                <select 
                                    className   = {`panelDropdown panelSelect Link11R w-70 transition ${flow.length == 0 && 'disabled'}`}
                                    value       = {formik.values.flow}
                                    placeholder = "Flow"
                                    onChange    = {(e)=>formik.setValues({...formik.values, flow: e.target.value})}
                                >
                                    {flow && flow.length > 0 && flow.map((value, index)=> (
                                        <option value={value._id}>{value.name}</option>
                                    ))}
                                </select>

                                </div>
                            </div>
                            {/* Configuration Section */}
                            <div className="border-bottom pt_8 pb_8">
                            <div className="Link12M color-neutral100 pt_8 pb_8">
                                Configuration
                            </div>

                            <div className="d-flex space-between pt_8 pb_8">
                                <div className="Link11R color-neutral70">Hide Points</div>
                                <CustomSwitch 
                                    checked = {formik.values.points}
                                    onChange = {(e)=>formik.setValues({...formik.values , points : e.target.checked})}
                                />
                            </div>

                            <div className="d-flex space-between pt_8 pb_8">
                                <div className="LinkL11R color-neutral70">Redeeming ways</div>
                                {console.log('formik.values.redeemingWays ', formik.values.redeemingWays)}
                                <CustomSwitch 
                                    checked = {formik.values.redeemingWays}
                                    onChange = {(e)=>formik.setValues({...formik.values , redeemingWays : e.target.checked})}
                                />
                            </div>

                            <div className="d-flex space-between pt_8 pb_8">
                                <div className="LinkL11R color-neutral70">Referral</div>
                                {console.log('formik.values.redeemingWays ', formik.values.referral)}
                                <CustomSwitch 
                                    checked = {formik.values.referral}
                                    onChange = {(e)=>formik.setValues({...formik.values , referral : e.target.checked})}
                                />
                            </div>
                            </div>
{/*  */}
                            <div className="border-bottom pt_8 pb_8">
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Authentication
                                </div>

                                <div className='d-flex align-items-center gap-12'>
                                <div className="Link11R color-neutral70 w-30">
                                    Service
                                </div>
                                <select 
                                    className   = {`panelDropdown panelSelect Link11R w-70 transition`}
                                    value       = {formik.values.authentication}
                                    placeholder = "Flow"
                                    onChange    = {(e)=>formik.setValues({...formik.values, authentication: e.target.value})}
                                >
                                    <option value = {"none"}>{"None"}</option>
                                    <option disabled={brand && brand.integrations && brand.integrations.emailService ? false : true}  value = {"email"}>{"EMAIL"}</option>
                                    <option disabled={brand && brand.integrations && brand.integrations.smsService ? false : true} value = {"sms"}>{"SMS"}</option>
                                </select>
                                </div>
                                
                            </div>

                        </div>

                        :
                        // Styling Section -----------------------

                        <>
                             <div className='mt_16 border-bottom'>
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Text
                                </div>

                                <div className='d-flex gap-12 align-items-center mb_16'>
                                    <div className="Link11R panelLabel">Heading</div>
                                    <input 
                                        name        = "heading"
                                        onChange    = {formik.handleChange}
                                        value       = {formik.values.heading}
                                        className   = "outline-none heading border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>

                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Description</div>
                                    <textarea 
                                        name      = "description"
                                        onChange  = {formik.handleChange}
                                        rows      = "3"
                                        value     = {formik.values.description}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />

                                </div>

                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Button text 01</div>
                                    <textarea 
                                        name      = "buttonone"
                                        onChange  = {formik.handleChange}
                                        rows      = "1"
                                        value     = {formik.values.buttonone}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>
                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Button text 02</div>
                                    <textarea 
                                        name      = "buttontwo"
                                        onChange  = {formik.handleChange}
                                        rows      = "1"
                                        value     = {formik.values.buttontwo}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>

                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Sub-heading</div>
                                    <textarea 
                                        name      = "subHeading"
                                        onChange  = {formik.handleChange}
                                        rows      = "1"
                                        value     = {formik.values.subHeading}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>

                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Sub-description</div>
                                    <textarea 
                                        name      = "subDescription"
                                        onChange  = {formik.handleChange}
                                        rows      = "3"
                                        value     = {formik.values.subDescription}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>

                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Reward 1 Heading</div>
                                    <textarea 
                                        name      = "rewardHeading1"
                                        onChange  = {formik.handleChange}
                                        rows      = "1"
                                        value     = {formik.values.rewardHeading1}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>

                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Reward 2 Heading</div>
                                    <textarea 
                                        name      = "rewardHeading2"
                                        onChange  = {formik.handleChange}
                                        rows      = "1"
                                        value     = {formik.values.rewardHeading2}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>
                                <div className='d-flex gap-12 mb_16'>
                                <div className="Link11R panelLabel">Redeem Description</div>
                                    <textarea 
                                        name     = "redeemDescription"
                                        onClick  = {()=>setExpand({...expand, redeemingWays : true})}
                                        onChange = {formik.handleChange}
                                        onBlur   = {()=>setExpand({...expand, redeemingWays : false})}
                                        rows      = "1"
                                        value     = {formik.values.redeemDescription}
                                        className = "descriptionBox underline outline-none border-none Link11R color-neutral100 bg-color-inherit"
                                    />
                                </div>

                            </div>
                            
                            <div className="pt_8 pb_8 border-bottom">
                                {/* Heading */}
                                <div className="Link12M color-neutral100 pt_8 pb_8">
                                    Theme
                                </div>

                                {/* Appearance Dropdown */}
                                <div className='d-flex gap-12 align-items-center'>
                                    <div className="Link11R panelLabel">Appearance</div>
                                    <select 
                                        className = "panelDropdown panelSelect Link11R w-70 transition"
                                        value     = {formik.values.theme}
                                        name      = "theme"
                                        onChange  = {formik.handleChange}
                                    >   
                                        <option value = {'light'} className = "color-neutral100 capitalize">Light</option>
                                        <option value = {'dark'} className  = "color-neutral100 capitalize">Dark</option>
                                    </select>
                                </div>

                                {/* Primary Background */}
                                <div className='d-flex gap-12 align-items-center'>
                                    <div className="Link11R panelLabel">Primary</div>
                                    <ClickOutside className="panelDropdown panelSelect Link11R w-70 transition " onClick={()=>setShow({...show, primaryColorPallette : false})}>
                                        <div className='d-flex align-items-center cp position-relative' onClick={()=>setShow({...show,primaryColorPallette : !show.primaryColorPallette })}>
                                            <div className="colorIcon" style={{background: formik.values.primaryColor}}></div>
                                            <div className="color-neutral100 Link11R ml_8 upperCase ">{formik.values.primaryColor}</div>
                                            {show.primaryColorPallette &&
                                                <ChromePicker 
                                                    className = "pallette position-absolute z-index-1"
                                                    color     = {formik.values.primaryColor}
                                                    onChange  = {(e)=> formik.setValues({...formik.values, primaryColor : e.hex})}
                                                />
                                            }  
                                        </div>
                                    </ClickOutside>
                                </div>
                                            
                            </div>

                            {/* <div className='mt_16 border-bottom d-flex space-between align-items-center pb_16 cp' >
                                <div className="Link12M color-neutral100 ">
                                    Background Image
                                </div>
                                <div className='mr_8' onClick={handleClick}>
                                    <SvgIcons.Add/>
                                </div>
                                <input 
                                    type      = "file"
                                    className = "d-none"
                                    accept    = "image/*"
                                    ref       = {hiddenFileInput}
                                    onChange  = {handleChange}
                                /> 
                            </div> */}

                            {formik.values.logo && <div className='mt_16 border-bottom d-flex space-between align-items-center pb_16 cp' >
                                <img class="objec-fit-contain" src={(typeof formik.values.logo != "string") ?  URL.createObjectURL(formik.values.logo) : formik.values.logo} height="32px" width="32px" alt="" />
                                <div className='mr_12' onClick={()=>{formik.setValues({...formik.values, logo : ''}); hiddenFileInput.current.value = ""}}>
                                    <SvgIcons.CrossIcon/>
                                </div>
                            </div>}


                        
                        </>
                        
                }
            </div>
        </div>
    )
}
