import React, { Component } from "react";
import Loader from "../components/Loader";
import Notfound from "../components/Notfound";
import * as localForage from 'localforage'
import { BrandService } from "../services";

const BrandContext = React.createContext();

class BrandContextComponent extends Component {
  state = {loader: true}

  componentDidMount = async () => {
      const state = this.props.location.state || {}
      if(!state.activeBrand) return this.getBrand()
      this.setState({loader: false, ...state.activeBrand})
  }

  getBrand = async () =>{
      const query = {...this.props.match.params, delete: false}
      const { error, response } = await BrandService.Get({query})
      console.log('gpp',response);
      if(error && error.error && !error.error.includes('jwt expired')) 
      return this.setState({loader: false, error: error.error})

      if(response){
        localForage.setItem('brand', {...response.data[0]}) 
        return this.setState({loader: false, ...response.data[0]})
      }
  }

  updateBrand = (updates) => {
    localForage.setItem('brand', {...this.state, ...updates}) 
    this.setState({...this.state, ...updates})
  }

  render() {
    return (
      <>
      {this.state.loader && <Loader width={300} height={300} />}
      {this.state.error && <div className="middle"><Notfound /></div>}
      {
        !this.state.loader && !this.state.error &&
        <BrandContext.Provider value={{...this.state, updateBrand: this.updateBrand}}>
         {this.props.children}
        </BrandContext.Provider>
      }
      </>
    )
  }
}

export { BrandContext, BrandContextComponent }