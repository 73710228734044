import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function BasicSwitches({ className, onChange, onClick, checked, name }) {
  return (
       <FormControlLabel 
       control={<Switch 
        checked  = {checked}
        onChange = {onChange}
        onClick  = {onClick}
        name     = {name}
        control  = {<Switch size="small"/>}
       />
      } 
       />
  );
}