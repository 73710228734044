import * as yup from 'yup';
import { Campaign, enums } from '../enums'

const REQ_ERROR = 'This field is required'

// const date = Date.now()
const today = new Date();
today.setHours(0, 0, 0, 0)

// new Date(value).valueOf() <= Date.now()

function handleTime(value, context){
    const startDate = new Date(this.parent.startDate).valueOf()
    const endDate   = new Date(this.parent.endDate).valueOf()
    
    
    if(startDate != endDate) return value
    
    if(context.path.split('.')[1] === 'startTime')
    return this.parent.endTime > value

    if(context.path.split('.')[1] === 'endTime')
    return this.parent.startTime < value
}

const detail = yup.object({
    codeType       : yup.string().required(),
    uniquePasses   : yup.boolean().required(),
    redemptionLimit: yup.string().required(),
    passLimit      : yup.number().required(),
    isFixedAmount  : yup.boolean().required(),
    startDate      : yup.date().required().max(
                        yup.ref('endDate'),
                        "start date can't be after end date"
                    ),
    endDate        : yup.date().required().min(
                        yup.ref('startDate'),
                        "end date can't be before start date"
                    ),
    startTime       : yup.string().required().test('startTimeTest', 'start date can\'t be after end date', handleTime),
    endTime         : yup.string().required().test('endTimeTest', 'end date can\'t be before start date', handleTime),
    expiryValidation: yup.boolean().required(),
    couponLife      : yup.number(), //
    daysValidation  : yup.boolean().required(),
    validDays       : yup.array(),  //
    discountDeal    : yup.mixed()
                    .when('campaignType',{
                        is   : value => value === Campaign.CampaignTypes.GIVEAWAY_COUPON,
                        then : yup.string().required(),
                    }),
                    
    discountDeal    : yup.mixed()
                    .when('campaignType',{
                        is   : value => value === !Campaign.CampaignTypes.GIVEAWAY_COUPON,
                        then : yup.number().required(),
                    })
})

const Coupon = yup.object({
    campaignName: yup.string().required(),
    campaignType: yup.number().required(),
    detail      : yup.string().required(),
    amount      : yup.number().required(),
    image       : yup.string()
})


export {
    Coupon,
}