import React, {useContext, useEffect} from 'react'

import LoyaltyCard  from './tables/Loyaltycard';
import Coupon from './tables/Coupon';
import { LayoutContext } from '../../../context/Layout.context';
import CustomButton from '../../../components/CustomButton';


export default function TransactionHistory({customer, selected, setSelected}) {

    const temp = {
        coupon      : <Coupon customer      = {{...customer , transactions : customer.transaction}}/>,
        loyaltyCard : <LoyaltyCard customer = {{...customer , transactions : customer.transaction}}/>
    }

    const [type, setType] = React.useState()
    const [loader, setLoader] = React.useState()

    const onLoad = () => {
        const type = customer.pass.passType;
        setType(type);
    }

    useEffect(onLoad,[])
  return(
      <div>
          {customer.transaction ? temp[type] : <div className="middle" style={{height: '70vh'}}>No Transaction made!</div>}
      </div>
  )
}




















var a = {
    "_fieldsProto": {
    "growthToolType": {
        "stringValue": "qrcode",
        "valueType": "stringValue"
    },
    "wallet": {
        "booleanValue": false,
        "valueType": "booleanValue"
    },
    "userData": {
        "mapValue": {
            "fields": {
                "firstName": {
                    "stringValue": "Mads",
                    "valueType": "stringValue"
                },
                "lastName": {
                    "stringValue": "Bandholm",
                    "valueType": "stringValue"
                },
                "phone": {
                    "stringValue": "+4530782344",
                    "valueType": "stringValue"
                },
                "created": {
                    "integerValue": "1681889326749",
                    "valueType": "integerValue"
                },
                "email": {
                    "stringValue": "mads_bandholm@hotmail.com",
                    "valueType": "stringValue"
                }
            }
        },
        "valueType": "mapValue"
    },
    "color": {
        "stringValue": "#230689",
        "valueType": "stringValue"
    },
    "os": {
        "stringValue": "ios",
        "valueType": "stringValue"
    },
    "campaignId": {
        "stringValue": "SGjIwhehEO9FPuSoGU6d",
        "valueType": "stringValue"
    },
    "created": {
        "integerValue": "1681889328010",
        "valueType": "integerValue"
    },
    "platformType": {
        "stringValue": "app",
        "valueType": "stringValue"
    },
    "passCode": {
        "stringValue": "PA480483",
        "valueType": "stringValue"
    },
    "userId": {
        "stringValue": "1l92Ob4HRbFagwysaLPD",
        "valueType": "stringValue"
    },
    "delete": {
        "booleanValue": false,
        "valueType": "booleanValue"
    },
    "passType": {
        "stringValue": "loyaltyCard",
        "valueType": "stringValue"
    },
    "referalCode": {
        "stringValue": "RFF3D21246",
        "valueType": "stringValue"
    },
    "unsubscribed": {
        "booleanValue": false,
        "valueType": "booleanValue"
    },
    "brandId": {
        "stringValue": "pGuZEyq4Fmkw9rTB3zfA",
        "valueType": "stringValue"
    },
    "_id": {
        "stringValue": "0AeAGf2Ih9V4zahGQgDG",
        "valueType": "stringValue"
    },
    "passDesignId": {
        "stringValue": "mRq9AGMvGJVqEJMdt41O",
        "valueType": "stringValue"
    },
    "createdAt": {
        "timestampValue": {
            "seconds": "1681889328",
            "nanos": 454000000
        },
        "valueType": "timestampValue"
    },
    "loyaltyCard": {
        "mapValue": {
            "fields": {
                "currentPoints": {
                    "integerValue": "0",
                    "valueType": "integerValue"
                },
                "totalSpending": {
                    "integerValue": "0",
                    "valueType": "integerValue"
                },
                "lastAmountSpent": {
                    "integerValue": "0",
                    "valueType": "integerValue"
                },
                "lastRedeemAt": {
                    "integerValue": "0",
                    "valueType": "integerValue"
                },
                "totalPoints": {
                    "integerValue": "0",
                    "valueType": "integerValue"
                },
                "pointsUpgrades": {
                    "arrayValue": {
                        "values": []
                    },
                    "valueType": "arrayValue"
                },
                "currentTier": {
                    "mapValue": {
                        "fields": {
                            "currentTierName": {
                                "stringValue": "Silver Tier",
                                "valueType": "stringValue"
                            },
                            "currentTierId": {
                                "stringValue": "zhqR8EQ3d8qu6uPzn2S8",
                                "valueType": "stringValue"
                            },
                            "startDate": {
                                "integerValue": "1681889328009",
                                "valueType": "integerValue"
                            },
                            "expirationDate": {
                                "integerValue": "1631270386208",
                                "valueType": "integerValue"
                            }
                        }
                    },
                    "valueType": "mapValue"
                },
                "redeemCount": {
                    "integerValue": "0",
                    "valueType": "integerValue"
                }
            }
        },
        "valueType": "mapValue"
    },
    "updated": {
        "integerValue": "1688366803811",
        "valueType": "integerValue"
    },
    "preLaunch": {
        "booleanValue": false,
        "valueType": "booleanValue"
    },
    "updatedAt": {
        "timestampValue": {
            "seconds": "1688366805",
            "nanos": 848000000
        },
        "valueType": "timestampValue"
    }
},
"_ref": {
    "_firestore": {
        "projectId": "walletly"
    },
    "_path": {
        "segments": [
            "V2_Passes",
            "0AeAGf2Ih9V4zahGQgDG"
        ],
        "projectId": "walletly",
        "databaseId": "(default)"
    },
    "_converter": {}
},
"_serializer": {
    "allowUndefined": false
},
"_readTime": {
    "_seconds": 1688473910,
    "_nanoseconds": 845898000
},
"_createTime": {
    "_seconds": 1681889328,
    "_nanoseconds": 485622000
},
"_updateTime": {
    "_seconds": 1688366806,
    "_nanoseconds": 6184000
}
}


console.log('ppefe',a['_fieldsProto']['_id']['stringValue']);