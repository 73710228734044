import React, { useContext } from 'react'
import CopyText from '../../../../components/CopyText';
import CustomTextField from '../../../../components/CustomTextField';
import QrCode from '../../../../components/QrCode';
import { UserContext } from '../../../../context/User.context';
import { PngIcons, SvgIcons } from '../../../../icons';
import Fade from 'react-reveal/Fade';

function BC() {
    const user = useContext(UserContext)
    const qrcode = {
        size: 200,
        text: 'https://apps.apple.com/pk/app/business-connector/id1609580178',
        typeNumber: 10,
      };
  return (
    <Fade left>
    <div>
        <div className='H24M color-neutral90 mb_8'>Test your business case</div>
        <div className='B16M color-neutral60'>Scan QR from app and test your business.</div>
        <div className="position-relative mb_20 mt_20">
          <CustomTextField
            label={"User ID"}
            className="w-100 disabled"
            value={user?.idKey}
          />
          <div style={{ position: "absolute", top: "16px", right: "16px" }}>
            <CopyText content={user?.idKey} hideText={true} />
          </div>
        </div>
        <div className=''>
            <div className='d-flex justify-content-center'><div className='qrBoxOnboard p_8 w-fit'><QrCode qrcode={qrcode} /></div></div>
            <div className='H16M color-neutral80 mt_8 mb_8 text-align-center'>Scan QR to download app on your mobile</div>
            <div className='d-flex justify-content-center gap-24'>
              <div className='cursor-pointer mt_10 mb_20' onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.businessconnector', '_blank')} >
                <SvgIcons.PlaystoreGray  />
              </div>
              <div className='cursor-pointer mt_10 mb_20' onClick={()=>window.open(qrcode.text, '_blank')}>
                <SvgIcons.AppleGray/>
              </div>
              <div className='cursor-pointer mt_10 mb_20' onClick={()=>window.open('https://3.25.255.255', '_blank')}>
                <SvgIcons.WebsiteGray/>
              </div>
              
                {/* <img className='cp' src={PngIcons.appstore} height={67} onClick={()=>window.open(qrcode.text, '_blank')} />
                <img className='cp' src={PngIcons.playstore} height={67} /> */}
            </div>
        </div>
    </div>
    </Fade>
  )
}

export default BC