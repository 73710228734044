import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/logs'

const Get = async({query}) => await axios.get(env.API_URL+service+'/?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const BrandService = {
    Get        : Handlers.Services(Get),
}

export default BrandService
