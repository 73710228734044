import * as yup from 'yup';


const TagsVld = yup.object({
    tagName: yup.string().required('field is required')
})



export {
    TagsVld,
}