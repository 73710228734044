import React, { useContext, useEffect, useState } from "react";
import { useParams  } from "react-router-dom";
import QrCode from "../../components/QrCode";
import {
  BrandService,
  CampaignService,
  FlowService,
  PassService,
  TransactionService,
} from "../../services";
import { SvgIcons } from "../../icons";
import { Formik, useFormik } from "formik";

import localforage, * as localForage from "localforage";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import CustomButton from "../../components/CustomButton";
import VerificationCode from "../../components/VerificationCode";

import { AuthService, UserService } from "../../services";
import { utils } from "../../utils";
import { AgencyContext } from "../../context/Agency.context";

import CustomTextField from "../../components/CustomTextField";
import Loader from "../../components/Loader";
import PngIcons from '../../icons/png.icon';
import CopyText from "../../components/CopyText";
import { IntegrationVld } from '../../validation'

function BusinessPage() {
  const agency = useContext(AgencyContext);
  const params = useParams();
  const [state, setState] = useState({
    loader: true,
    brand: {},
    apiLoader: false,
    campaign: {},
    flowId: '',
    pass: {},
  });
  const history = useHistory();
  const [verificationCode, setVerificationCode] = useState("");

  const initValues = {
    firstName: "",
    lastName: "",
    email: "",
    dialingCode: state?.brand?.dialingCode || "",
    phone: "",
    signInemail: "",
  };

  const [join, setJoin] = useState(0);

  const [qrCode, setqrCode] = useState({
    selected: "",
    show: false,
    cwCode: "",
  });
  console.log('join', join);

  useEffect(async () => {
    setState({ ...state, loader: true });
    const loggedIn = await localForage.getItem("loggedIn");
    if (loggedIn && loggedIn === true) setJoin(4);
    else setJoin(0);   /////set to 0 after finalizing css

    onLoad();
    // setState({ ...state, loader: false });
  }, []);

  // console.log("state ", state.brand.dialingCode);

  const myFormik = useFormik({
    initialValues: { ...initValues },
    validationSchema: join == 2 ? IntegrationVld.BusinessPageSignIn : join == 1 ? IntegrationVld.BusinessPageRegister : '',
    isInitialValid: false,
  });
  console.log("myFormik.values", myFormik.values);

  // uncomment after making responsive 
  const forVerification = async () => {
    const verificationcodeStorage = await localForage.getItem("verificationCode");
    // console.log('verificationCode ', verificationCode)
    // console.log('verificationcodeStorage ', verificationcodeStorage)
    if (typeof verificationCode != "string") {
      setVerificationCode(verificationCode.join(""))
    }
    if (verificationcodeStorage.toString() === verificationCode) {
      // console.log("correct");
      localForage.setItem("loggedIn", true);
      setJoin(4);
      onLoad();
    } else {
      // console.log("incorrect");
    }
  };

  // console.log("formikv ", myFormik.errors);

  const handleSubmitFun = async () => {
    setState({ ...state, apiLoader: true });

    let payload = {
      ...myFormik.values,
      brandId: state.brand._id,
      campaignCode: state.campaign.campaignCode,
    };

    // console.log('payload',payload);

    if (payload.signInemail || payload.signInemail === "") delete payload.signInemail
    // console.log('payload2',payload);

    const headers = { "x-api-key": state.brand.apiKey };

    const { response, error } = await PassService.Create({
      payload,
      headers,
      toaster: true,
    });

    // console.log("zee ", response);

    if (response.success) {
      await localForage.setItem("loggedInUser", response.data);
      setState({ ...state, pass: response.data })

      const payload = {
        email: myFormik.values.email,
        verificationCode: utils.getRandomNumber(4),
      };

      const emm = await localForage.setItem(
        "verificationCode",
        payload.verificationCode
      );

      // console.log("verification: ", emm);

      // console.log("api ", payload);
      const sendEmail = await AuthService.sendEmail({
        toaster: true,
        payload,
      });

      // console.log("sendEmail ", sendEmail);

      setJoin(3);
    }

    // console.log("payload ", payload);

    setState({ ...state, apiLoader: false });
  };

  const forLogOut = async () => {
    // console.log("Logged Out");

    // const loggedOutUser = await localForage.getItem("loggedInUser");
    // const logOutCondition =await localForage.getItem("loggedIn");

    localForage.removeItem("loggedInUser")
    localForage.removeItem("loggedIn");



    setJoin(0);
  };
  console.log('${state.brand.website}',state.brand.website) 

  const onLoad = async () => {
    setState({ ...state, loader: true });
    let query = {
      _id: params.brandId,
    };
    const { response, error } = await BrandService.GetPublic({ query });
    if (response) {
      let CampaignData = await CampaignService.GetPublicCampaign({
        query: { brandId: response.data._id, campaignType: 5 },
      });

      let alreadyPassCreated = await localforage.getItem('loggedInUser');

      if (alreadyPassCreated) {
        setState({ ...state, pass: alreadyPassCreated })
      }

      const all = await FlowService.Get({ query: { brandId: response.data._id } })
      if (join === 0) {
        let payload = {
          brandId: response.data._id,
          flowIds: all?.response?.data[0]?._id ? [all?.response?.data[0]?._id] : [],
          widget: true,
        }
        // console.log('payload 1 ', payload);
        const flows = await TransactionService.GetFlows({ payload });
        // console.log('flows 1', flows)
        setState({
          ...state,
          brand: response.data,
          flows: flows.response.data,
          campaign: CampaignData.response.data[0],
          loader: false,
          flowId: all?.response?.data[0]?._id || []
        });
      }
      else {
        // console.log("1");
        const loggedIn = await localForage.getItem("loggedIn");
        const loggedInUser = await localForage.getItem("loggedInUser");
        if (loggedIn && loggedIn === true && loggedInUser._id) {
          // console.log("22");
          let payload = {
            brandId: response.data._id,
            flowIds: all?.response?.data[0]?._id ? [all?.response?.data[0]?._id] : [],
            passId: loggedInUser?._id,
          };
          const flows = await TransactionService.GetFlows({ payload });
          // console.log("flows2", flows);
          setState({
            ...state,
            brand: response.data,
            flows: flows.response.data,
            campaign: CampaignData.response.data[0],
            loader: false,
            flowId: all.response.data[0]._id
          });
        }
      }
    }
    else setState({ ...state, loader: false });
  };

  const onCompleteFunc = (code) => {
    setVerificationCode(code);
  }
  console.log('state', state);

  // src={state.brand.brandCover}
  return (
    <div id="" className="wmb-main-container">
      {state.loader ? (
        <Loader />
      ) : (
        <>
          {console.log("objects", state)}

          {join === 0 && (
            <div
              className="bg-color-white borderRadius-20 main-cont-signin"

            >
              <div className="w-100 f1-div">
                <img src={state?.brand?.brandCover} className="big-screen w-100 f1 object-fit-cover" height={180} />
              </div>
              <div
                className="row first-page align-items-center w-100 "
                style={{ marginTop: "-53px" }}
              >
                <div className="col-4 first-page-profile text-center w-100 mb_20 ml_0">
                  <img
                    src={state?.brand?.brandLogo}
                    height={130}
                    // width={130}
                    className="borderRadius-100 profile-pic" //w-100 object-fit-cover
                    style={{ border: "4px solid white" }}
                  />
                </div>
                <div className="col-5 mt-5 pl_0 ml_0 w-100">
                  <div className="H24M color-neutral100 mt-4 mb_4 brand-name capitalize">
                    {state?.brand?.brandName}
                  </div>
                  <div className="B16R color-neutral60 brand-service capitalize">
                    {state?.brand?.businessService}
                  </div>
                </div>

                {/* bg-color-neutral0 border mt-5 cp pt_10 pb_10 pl_24 pr_24 borderRadius-6 d-flex gap-10 align-items-center color-primary50 H18M */}
                <div className="col-3 row justify-flex-end mt_50 w100 pr_0 mr_0  ">
                  <CustomButton
                    btntext={"SignIn"}
                    varient="secondary"
                    className={"bg-color-neutral0 border cp pt_10 pb_10 pl_24 pr_24 borderRadius-6 d-flex gap-8 align-items-center color-primary50 H18M"}
                    icon={<img src={PngIcons.userAdd} width="15" height={15} />}
                    position="start"
                    onClick={() => setJoin(2)}
                  />
                </div>
              </div>
              <div className="d-flex pl_30 pr_30 gap-19 mb_15 mt-2">
                <div className="bg-color-neutral10 pt_8 pl_24 pr_24 pb_8 borderRadius-6 d-flex align-items-center cp">
                  <a href={`tel:${state?.brand?.dialingCode} ${state?.brand?.phone}`}><SvgIcons.VectorPhone1 /></a>
                </div>
                <div className="bg-color-neutral10 pt_8 pl_24 pr_24 pb_8 borderRadius-6 d-flex align-items-center cp">
                {/* <a  onClick={()=>window.location.replace(`${state?.brand?.website}`) }><SvgIcons.VectorWorld1 /></a> */}
                <a  onClick={()=>
                  {
                    state?.brand.website.includes('https://')?
                    window.location.replace(`${state?.brand.website}`) 
                    :
                    window.location.replace(`https://${state.brand.website}`); 
                  // console.log("Historyy",window.location.href);
                    
                }
                  }>
                    <SvgIcons.VectorWorld1 /></a>

                </div>
                <div className="w-100 d-flex flex-column">
                  <CustomButton
                    type="submit"
                    onClick={() => setJoin(1)}
                    varient="primary"
                    className="w-100 hpx-52 "
                    btntext="Join Now"
                  />
                </div>
              </div>
              <div className="pl_30">
                <div className="H22M color-neutral90 mb_13">Products</div>
                <div
                  className="mb_19 d-flex gap-12 w-100"
                  style={{ overflowX: "scroll" }}
                >
                  {
                    console.log("state.flows", state.flows)
                  }
                  {state.flows?.map((value, index) => (

                    <div className="" key={index}>
                      <img className="scroll-items borderRadius-4 object-fit-cover" width={180} src={value?.image} height={180} />
                      <div className="H16M color-neutral90 mt_4 mb_0">
                        {value?.deal}
                      </div>
                      <div className="B14M color-neutral60">{`${value?.condition[0].conditionValue[1]} ${value?.condition[0].condition}`} </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* signup form */}

          {join === 1 && (
            <div
              className="bg-color-white borderRadius-20 main-cont-signin"

            >
              <div className="cover-up f1-div">
                <img src={state?.brand?.brandCover} className="w-100 f1" height={180} />
              </div>

              <div
                className=" row align-items-center w-100 "
                style={{ marginTop: "-53px" }}
              >
                <div className="col-4 text-center ml_0">
                  <img
                    src={state.brand?.brandLogo}
                    height={130}
                    width={130}
                    className="borderRadius-100"
                    style={{ border: "4px solid white" }}
                  />
                </div>
                <div className="col-5 mt-5 pl_0 ml_0">
                  <div className="H24M color-neutral100 mt-4 mb_4 capitalize">
                    {state.brand?.brandName}
                  </div>
                  <div className="B16R color-neutral60 capitalize">
                    {state.brand?.businessService}
                  </div>
                </div>

                <div className="col-3 row justify-flex-end mt_50 w100  pr_0 mr_0">
                  <CustomButton
                    btntext={"Back"}
                    varient="secondary"
                    className={"bg-color-neutral0 border cp pt_10 pb_10 pl_24 pr_24 borderRadius-6 d-flex gap-8 align-items-center color-primary50 H18M"}
                    icon={<SvgIcons.BackIcon />}
                    // varient   = "secondaryprimary"
                    // className={"bg-color-neutral0 border cp pt_10 pb_10 pl_24 pr_24 borderRadius-6 d-flex gap-8 align-items-center color-primary50 H18M"}
                    // icon      = {<SvgIcons.BackIcon/>}
                    position="start"
                    onClick={() => setJoin(0)}
                  />
                </div>

              </div>

              {/* form start */}

              <div className="allForm w-100 pb_24">
                <form onSubmit={myFormik.handleSubmit}>
                  <div className=" w-100  mt_50 mb_16 d-flex justify-content-space-between pl_24 pr_24">
                    <div className="w-48 forChildw-100">
                      <CustomTextField
                        variant="standard"
                        id="outlined-basic"
                        name="firstName"
                        className="fName w-100"
                        placeholder="First Name"
                        value={myFormik.values.firstName}
                        helperText={myFormik.errors.firstName && myFormik.errors.firstName}
                        onChange={myFormik.handleChange}
                        label="First Name"
                      />
                    </div>

                    <div className="w-48 forChildw-100">
                      <CustomTextField
                        variant="standard"
                        name="lastName"
                        placeholder="Last Name"
                        className="lName w-100"
                        value={myFormik.values.lastName}
                        onChange={myFormik.handleChange}
                        label="Last Name"
                      />
                      {myFormik.errors.lastName && (
                        <span className="error w-100">
                          {myFormik.errors.lastName}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="email-field w-100 pl_24 pr_24 forChildw-100">
                    <CustomTextField
                      variant="standard"
                      id="outlined-basic"
                      name="email"
                      className="emailAdd w-100"
                      placeholder="Email"
                      value={myFormik.values.email}
                      onChange={myFormik.handleChange}
                      label="Email"
                      helperText={myFormik.errors.email && myFormik.errors.email}
                    />
                  </div>

                  <div className="code w-100 d-flex justify-content-space-between pl_24 pr_24">
                    <div className="w-20 forChildw-100">
                      <CustomTextField
                        variant="standard"
                        id="outlined-basic"
                        name="dialingCode"
                        className="code-input "
                        placeholder="Enter Code"
                        value={myFormik.values.dialingCode}
                        onChange={myFormik.handleChange}
                        helperText={myFormik.errors.dialingCode && myFormik.errors.dialingCode}
                        label="Code"
                      />
                    </div>

                    <div className="w-76 forChildw-100">
                      <CustomTextField
                        variant="standard"
                        name="phone"
                        placeholder="Phone Number"
                        className="ph-no w-100"
                        value={myFormik.values.phone}
                        helperText={myFormik.errors.phone && myFormik.errors.phone}
                        onChange={myFormik.handleChange}
                        label="Phone Number"
                      />
                    </div>
                  </div>

                  <div className="w-100 second-join-btn mt_32 pl_24 pr_24 d-flex flex-column">
                    <CustomButton
                      onClick={handleSubmitFun}
                      varient="primary"
                      icon={
                        state.apiLoader && (
                          <CircularProgress size={15} color="inherit" />
                        )
                      }
                      disabled={state.apiLoader && !myFormik.isValid}
                      className="w-100 hpx-52"
                      btntext="Join Now"
                    />
                    {console.log(
                      "state.apiLoader && !myFormik.isValid",
                      state.apiLoader && !myFormik.isValid
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}

          {join == 2 && (
            <div
              className="bg-color-white borderRadius-20 main-cont-signin"
            // style={{ height: "85vh", overflowY: "scroll" }}
            >
              <div className="w-100 f1-div">
                <img src={state?.brand?.brandCover} className="big-screen w-100 f1" height={180} />
              </div>
              <div
                className=" row align-items-center w-100 "
                style={{ marginTop: "-53px" }}
              >
                <div className="col-4  text-center">
                  <img
                    src={state.brand.brandLogo}
                    height={130}
                    width={130}
                    className="borderRadius-100"
                    style={{ border: "4px solid white" }}
                  />
                </div>
                <div className="col-5 mt-5 ml_0 pl_0">
                  <div className="H24M color-neutral100 mt-4 mb_4 capitalize">
                    {state?.brand?.brandName}
                  </div>
                  <div className="B16R color-neutral60 capitalize">
                    {state?.brand?.businessService}
                  </div>
                </div>

                <div className="col-3 row justify-flex-end mt_50 w100  pr_0 mr_0">
                  <CustomButton
                    btntext={"Back"}
                    varient="secondary"
                    icon={<SvgIcons.BackIcon />}
                    position="start"
                    onClick={() => setJoin(0)}
                  />
                </div>

              </div>

              <div className="sign-in-form pl_30 pr_30 pb_24">
                <div className="signIn-heading "> Sign In</div>

                <div className="final-email w100">
                  <CustomTextField
                    variant="standard"
                    name="signInemail"
                    placeholder="Email"
                    className="ph-no w-100"
                    value={myFormik.values.signInemail}
                    onChange={myFormik.handleChange}
                    label="Email Address"
                  />
                  {myFormik.errors.signInemail && (
                    <span className="error w-100">{myFormik.errors.signInemail}</span>
                  )}
                </div>
                <div className="w-100 second-join-btn d-flex flex-column">
                  <CustomButton
                    name="signInemail"
                    type="submit"
                    onClick={async () => {

                      const { response, error } = await PassService.GetPublicPass({
                        query: {
                          email: myFormik.values.signInemail,
                          brandId: state?.brand?._id,
                          campaignCode: state?.campaign?.campaignCode,
                        }
                      });

                      if (response?.success) {
                        await localForage.setItem("loggedInUser", response.data[0]);
                        setState({ ...state, pass: response.data[0] })
                        // console.log("check",response.data[0]);
                        const payload = {
                          email: myFormik.values.signInemail,
                          verificationCode: utils.getRandomNumber(4),
                        };
                        console.log("response?.success: ", response?.success);

                        const emm = await localForage.setItem(
                          "verificationCode",
                          payload.verificationCode
                        );

                        console.log("verification: ", emm);

                        console.log("api ", payload);
                        const sendEmail = await AuthService.sendEmail({
                          toaster: true,
                          payload,
                        });

                        // console.log("sendEmail ", sendEmail);

                        setJoin(3);
                      }
                      else {
                        myFormik.setErrors({ signInemail: "Invalid Login!" })
                      }

                    }}
                    varient="primary"
                    className="w-100 hpx-52"
                    btntext="Continue"
                  />
                </div>
              </div>
            </div>
          )}
          {/* third form end  */}

          {join === 3 && (
            <div
              className="bg-color-white last-form borderRadius-20 main-cont-signin"
            // style={{ height: "85vh", overflowY: "scroll" }}
            >
              <div className="w-100 f1-div">
                <img src={state?.brand?.brandCover} className=" big-screen w-100 f1" height={180} />
              </div>

              {/* <div
                className="row align-items-center verify-mail gap-15"
                style={{ marginTop: "-53px" }}
              >
                <div className="col-3 ml_15 text-center">
                  <img
                    src={state?.brand?.brandLogo}
                    height={160}
                    width={160}
                    className="borderRadius-100"
                    style={{ border: "4px solid white" }}
                  />
                </div>

                <div className="col-6">
                  <div className="H24M color-neutral100 mb_4">
                    {state?.brand?.brandName}
                  </div>
                  <div className="B16R color-neutral60">
                    {state?.brand?.businessService}
                  </div>
                </div>
              </div> */}

              <div
                className=" row align-items-center w-100 "
                style={{ marginTop: "-53px" }}
              >
                <div className="col-4 text-center">
                  <img
                    src={state?.brand?.brandLogo}
                    height={130}
                    width={130}
                    className="borderRadius-100"
                    style={{ border: "4px solid white" }}
                  />
                </div>
                <div className="col-5 mt-5 pl_0 ml_0">
                  <div className="H24M color-neutral100 mt-4 mb_4 capitalize">
                    {state?.brand?.brandName}
                  </div>
                  <div className="B16R color-neutral60 capitalize">
                    {state?.brand?.businessService}
                  </div>
                </div>

                <div className="col-3 row justify-flex-end mt_50 w100  pr_0 mr_0">
                  <CustomButton
                    btntext={"Back"}
                    varient="secondary"
                    icon={<SvgIcons.BackIcon />}
                    position="start"
                    onClick={() => {
                      if (join - 1 == 2) {
                        setJoin(2)
                      }
                      else {
                        if (join - 2 == 1) { setJoin(1); }
                      }
                    }
                    }
                  />
                </div>

              </div>

              <div className="myMiddle w-100 pb_24" id="myEmail">
                <div className="my-cardForm ">
                  <div className=" verification H24M w-100 mail color-neutral100 ">
                    Email Verification
                  </div>
                  <form onSubmit="#">
                    <div className="subtitle1  mt_8 mb_24">
                      Please enter the code we emailed you.
                    </div>
                    <div className="w-90">
                      <div className="inputfields w-100 d-flex">
                        <VerificationCode
                          setVerificationCode={setVerificationCode}
                          length={4}
                          onComplete={onCompleteFunc}
                        />
                      </div>
                    </div>
                    <CustomButton
                      varient="primary"
                      className="fs-14 fw-5 mt_32 w-100"
                      textColor="white"
                      // type="submit"
                      onClick={forVerification}
                      btntext="Verify"
                    />
                    {/* <span className="error w-100">{state.apiError}</span> */}
                    {/* {state.apiResponse} */}
                  </form>

                  {/* <div id='verifycode'>{defaultCode}</div> */}

                  <div className=" text-center mt_15">
                    <Link className="resendLogout" onClick={

                      async () => {
                        const { response, error } = await PassService.GetPublicPass({
                          query: {
                            email: myFormik.values.signInemail,
                            brandId: state?.brand?._id,
                            campaignCode: state?.campaign?.campaignCode,
                          }
                        });



                        if (response.success) {
                          await localForage.setItem("loggedInUser", response.data[0]);
                          setState({ ...state, pass: response.data[0] })
                          // console.log("check",response.data[0]);
                          const payload = {
                            email: myFormik.values.signInemail,
                            verificationCode: utils.getRandomNumber(4),
                          };

                          const emm = await localForage.setItem(
                            "verificationCode",
                            payload.verificationCode
                          );

                          // console.log("verification: ", emm);

                          // console.log("api ", payload);
                          const sendEmail = await AuthService.sendEmail({
                            toaster: true,
                            payload,
                          });

                          // console.log("sendEmail ", sendEmail);

                          setJoin(3); ///
                        }

                      }}>
                      Resend Code
                    </Link>
                    {/* )}{" "} */}
                    &nbsp;
                    or
                    <Link className="resendLogout" >
                      &nbsp;
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {join === 4 && (
            <div
              className="bg-color-white borderRadius-20 main-cont-signin-4"
            // style={{ height: "85vh", overflowY: "scroll" }}
            >
              <div className="w-100 f1-div">
                <img src={state?.brand?.brandCover} className=" big-screen w-100 f1" height={180} />
              </div>
              <div
                className="row align-items-center"
                style={{ marginTop: "-53px" }}
              >
                <div className="col-3 ml_15 text-center">
                  <img
                    src={state?.brand?.brandLogo}
                    height={130}
                    width={130}
                    className="borderRadius-100"
                    style={{ border: "4px solid white" }}
                  />
                </div>
                <div className="col-5 mt-5 pl_0 ml_0">
                  <div className="H24M mt-4 color-neutral100 mb_4 capitalize">
                    {state?.brand?.brandName}
                  </div>
                  <div className="B16R color-neutral60 capitalize">
                    {state?.brand?.businessService}
                  </div>
                </div>
                <div className="col-3 row justify-flex-center mt-5">
                  <div className="w-100 bg-color-neutral0 border mt-5 cp pt_10 pb_10 pl_24 pr_24 borderRadius-6 d-flex gap-10 align-items-center color-primary50 H18M"
                    onClick={forLogOut}>
                    <SvgIcons.LogOutWmb /> Logout
                  </div>

                </div>
              </div>
              <div className="d-flex p_16 gap-12">
                <div className="bg-color-neutral10 pt_8 pl_24 pr_24 pb_8 borderRadius-6 d-flex align-items-center cp">
                <a href={`tel:${state?.brand?.dialingCode} ${state?.brand?.phone}`}><SvgIcons.VectorPhone1 /></a>
                </div>
                <div className="bg-color-neutral10 pt_8 pl_24 pr_24 pb_8 borderRadius-6 d-flex align-items-center cp">
                <a  onClick={()=>window.location.replace(`${state?.brand?.website}`) }><SvgIcons.VectorWorld1 /></a>




                </div>

              </div>
              <div className=" last-div pl_20 pr_20 ">
                <div className="H22M color-neutral90 mb_13">Products</div>
                {console.log('qrcode ', state.flows)}
                <div
                  className="d-flex gap-12 w-100 "
                  style={{ overflowX: "scroll" }}

                >
                  {state?.flows?.map((value, index) =>


                    !value.cwCode ?

                      <>
                        {(qrCode.show == true && qrCode.selected == index) ?
                          <div className="each-qr " style={{width : '180px',  height : '250px' }}>
                            <QrCode className="borderRadius-4"
                              qrcode={

                                {
                                  size: 160,
                                  text:
                                    value.uniqueId ? (window.location.host.includes('localhost') ? 'http://' : 'https://') + `${window.location.host}/page/businesscheckout/?brandId=${state.brand._id}&flowId=${state.flowId}&passId=${state.pass._id}&passCode=${state.pass.passCode}&fullName=customer&dealUniqueId=${value.uniqueId}`
                                      :

                                      (window.location.host.includes('localhost') ? 'http://' : 'https://')`${window.location.host}/page/businesscheckout/?brandId='/page/businesscheckout/?brandId=${state.brand._id}&flowId=${state.flowId}&passId=${state.pass._id}&passCode=${state.pass.passCode}&fullName=customer&dealId=${value.id}&subtract=${value.subtract}&condition=${value.condition}&conditionValue=${value.condition === 'event' ? (value.conditionValue.event + "&startdate=" + value.conditionValue.date + "&enddate=" + value.conditionValue.enddate) : value.conditionValue}`,
                                }
                              }
                            />
                            <div className="H16M color-neutral90 mt_4 mb_4" >
                              {value?.deal}
                            </div>
                             <div className="B14M d-flex w-90 for-qr color-neutral60">{`${value?.condition[0].conditionValue[1]} ${value?.condition[0].condition}`} <div
                              onClick={() => {
                                setqrCode({
                                  selected: index,
                                  show: false,
                                })
                              }} className="color-primary50 qr-btn Body12R">Back</div>
                              </div>
                              </div>
                            /* <div className="B14M d-flex w-100 for-qr-back color-neutral60">{value.condition[0].conditionValue[1]}
                      <div 
                      onClick={()=>{
                        setqrCode({
                          selected :index,
                          show     : false,
                        })
                      }} 
                      className="color-primary50 Body12R back-btn">Back</div></div>
                      </div> */



                           

                          :



                          <div key={index} className="eachImage ">
                            <img src={value?.image} className="  borderRadius-4" width={180} height={184} />
                            <div className="H16M color-neutral90 mt_4 mb_4">
                              {value?.deal}
                            </div>



                            {/* <CustomTextField 
                       variant     = "standard"
                       name        = "cwcode"
                       placeholder = "CW-Code"
                       className   = "codee w-70"
                       value       = {state.flows[0].cwCode}
                      //  onChange    = {myFormik.handleChange}
                       label       = ""
                      /><br/>
                      <div className="H16M color-neutral90 mt_4 mb_4">
                        {state?.brand.brandName}
                      </div> */}

                            {console.log('flows ', state?.pass)}

                            <div className="B14M d-flex w-90 for-qr color-neutral60">{`${value?.condition[0].conditionValue[1]} ${value?.condition[0].condition}`} <div
                              onClick={() => {
                                setqrCode({
                                  selected: index,
                                  show: true,
                                  cwCode: value.cwCode,
                                })
                              }
                              } className="color-primary50 qr-btn Body12R">Click to redeem</div></div>
                          </div>}
                      </>

                      :


                      <>

                        {(qrCode.show == true && qrCode.selected == index) ?
                          <div className="eachImage "> {/* add class each-qr if doesnt work}
                    {/* <div className="H16M color-neutral90 mt_4 mb_4">
                      {value?.deal}
                    </div> */}

                            <div className="copy-cw">
                              <CustomTextField
                                variant="standard"
                                name="cwcode"
                                placeholder="CW-Code"
                                className="codee "
                                value={value.cwCode}
                                //  onChange    = {myFormik.handleChange}
                                label=""
                              />
                              <CopyText id="webhook" content={value?.cwCode} hideText={true} />
                            </div>
                            <br />
                            <div className="H16M color-neutral90 mt_4 mb_4">
                              {state?.brand?.brandName}
                            </div>



                            <div className="B14M d-flex w-90  color-neutral60"
                              style={{ gap: 80 }}
                            >{`${value?.condition[0].conditionValue[1]} ${value?.condition[0].condition}`} 
                              <div
                                onClick={() => {
                                  setqrCode({
                                    selected: index,
                                    show: false,
                                  })
                                }}
                                className="color-primary50 Body12R back-btn">Back</div>
                            </div>
                          </div>

                          :



                          <div key={index} className="eachImage ">
                            <img src={value?.image} className="  borderRadius-4" width={180} height={180} />
                            <div className="H16M color-neutral90 mt_4 mb_4">
                              {value?.deal}
                            </div>



                            {/* <CustomTextField 
                     variant     = "standard"
                     name        = "cwcode"
                     placeholder = "CW-Code"
                     className   = "codee w-70"
                     value       = {state.flows[0].cwCode}
                    //  onChange    = {myFormik.handleChange}
                     label       = ""
                    /><br/>
                    <div className="H16M color-neutral90 mt_4 mb_4">
                      {state?.brand.brandName}
                    </div> */}

                            {console.log('flows ', state?.pass)}

                            <div className="B14M d-flex w-90 for-qr color-neutral60">{value?.condition[0].conditionValue[1]}{value?.condition[0].condition} <div
                              onClick={() => {
                                setqrCode({
                                  selected: index,
                                  show: true,
                                  cwCode: value?.cwCode,
                                })

                                // console.log("my qr",qrCode);
                              }
                              } className="color-primary50 qr-btn Body12R">Click to redeem</div></div>
                          </div>}


                      </>

                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BusinessPage;