import React, { useContext, useEffect, useState } from 'react'

import localforage, * as localForage from 'localforage'


import CustomTextField from '../../../components/CustomTextField';
import CustomSelect from '../../../components/CustomSelect';

import { Options, utils } from '../../../utils';

import { LayoutContext } from '../../../context/Layout.context';
import { BrandService, CampaignService, TierService } from '../../../services';
import { SvgIcons } from '../../../icons';
import CustomsModal from '../../../components/CustomModal';
import { CircularProgress, FormControl, FormHelperText, RadioGroup } from '@mui/material';
import CustomButton from '../../../components/CustomButton';
import { Brand } from '../../../enums'
import DeleteModal from '../../../components/DeleteModal';
import { AgencyContext } from '../../../context/Agency.context';
import { showToaster } from '../../../utils/utils';
import CustomRadio from '../../../components/CustomRadio';
import 'react-phone-input-2/lib/style.css'
import CustomSwitch from '../../../components/CustomSwitch';
import { BrandContext } from '../../../context/Brand.context';
import { useFormik } from 'formik';
import { BrandVld } from '../../../validation';
import { CampaignContext } from '../../../context/Campaign.context';

export default function ExtraServices() {

    const brand = useContext(BrandContext);
    const layout = useContext(LayoutContext)
    const campaignC = useContext(CampaignContext);

    const [show, setShow] = useState({
        validation: true,
    });

    useEffect(async () => {
        console.log('HHHHSsss');
         _getValidation();
}, [])


    const initState = {

        upgradePlanUrl: brand.upgradePlanUrl ? brand.upgradePlanUrl : '',
        vip: brand.vip || false,
        tier: brand.tier || false
    }
    const [state, setState] = useState({
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
    })

    const [url, setUrl] = useState(false);

    const formik = useFormik({
        initialValues: { ...initState },
        validationSchema: BrandVld.UpdateBrand,
        isInitialValid: true,
        enableReinitialize: true
    })
    const _getValidation = async () => {
            const ddata = await CampaignService.GetPublicCampaign({
                query: { brandId: brand._id, campaignType: 5 },
            })
            console.log('ddata',ddata.response?.data[0]?._id);

        const data = await TierService.GetValidation({
            query: { brandId: brand._id, campaignId: ddata.response?.data[0]?._id},
        });
        if (data && data.error) {
            setShow({ ...show, validation: data.error.success });
            console.log('ddata',show);

        }
        return;
    };





    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })

        // const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+'+formik.values.fullPhone)

        // let logo, cover
        // const user        = await localforage.getItem('user')
        // const desiredPath = `${user._id}/${formik.values.brandName}`


        // if(formik.values.brandLogoFile){
        //     logo = await utils.uploadImage({file: formik.values.brandLogoFile, desiredPath})
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        // }
        // if(logo && logo.error) return setState({loader: false, brandingLoader : false, apiError: logo.error.message})

        // // uploading cover
        // if(formik.values.brandCoverFile){
        //     setState({loader: false, brandingLoader : true, apiError: ""})
        //     cover = await utils.uploadImage({file: formik.values.brandCoverFile, desiredPath})
        // }
        // if(cover && cover.error) return setState({loader: false, brandingLoader : false, apiError: cover.error.message})

        // const permissions        = await localforage.getItem('permissions')

        // if(formik.values.brandLogoFile){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].logo = logo.response.data;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }

        // if(formik.values.brandName){
        //     permissions.map((element, idx)=>{
        //         if(element.businessId === brand._id) permissions[idx].brandName = formik.values.brandName;
        //     })
        //     localforage.setItem('permissions', permissions)
        // }

        const payload = {
            _id: brand._id,
            upgradePlanUrl: formik.values.upgradePlanUrl,
            vip: formik.values.vip,
        }
        if (!show?.validation) {
            payload.tier = formik.values.tier
        }


        console.log('show?.validation ', show?.validation);
        const campaign = await localForage.getItem('campaign')
        console.log('campaign', campaign);

        if (!show?.validation) {
        console.log('enter ');

            if (payload.tier == false && campaign) {
                const payload = {
                    active: false,
                    brandId: brand._id,
                    campaignId: campaign?._id|| campaign[0]._id ,
                };
        console.log('enter payload',payload);

                const tierUpdate = await TierService.Update({ payload, toaster: true });
                console.log('doneeeee',tierUpdate);

                if (tierUpdate.response) {
                    console.log('doneeeee');
                    let tiers = await localForage.getItem("tiers");
                    let array = [];
                    tiers.map((element, idx) => {
                        array.push({ ...element, active: false });
                    });

                    localForage.setItem("tiers", array);
                }
            }
        }


        // if(logo && logo.response) payload.brandLogo    = logo.response.data
        // if(cover && cover.response) payload.brandCover = cover.response.data

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })

        console.log('response ', response.data)
        brand.updateBrand(response.data)
        return setState({ generalLoader: false, apiError: '' })
    }

    React.useEffect(() => {
        layout.setLayout({
            title: 'Settings',
            borderBottom: false,
            switch: "",
            button: ""
        })
    }, [formik.values, formik.errors, state.loader])



    return (

        <div>
            <div className='w-100'>
                <div className='col-md-12   col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Extra Business Services</div>
                    <CustomButton btntext={"Update"}
                        disabled={state.generalLoader}
                        icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={handlePublicProfileUpdateFunc} />
                </div>
                <div className=" col-md-12 col-12 pl_0 pr_0 mb_11">
                    <div className="bar"></div>
                </div>
                <div className='w-70'>


                    <div className="Heading16M color-neutral100 d-flex w-100 space-between align-items-center">


                        Upgrade Plan Link

                        <CustomSwitch
                            checked={url}
                            value={url}
                            onChange={(e) => {
                                // formik.setValues({ ...formik.values, vip: !formik.values.vip })
                                setUrl(!url);
                            }}
                        />
                    </div>

                    <div className="Body14R color-neutral60">
                        Set the link to redirect the user to upgrade their membership.
                    </div>
                    <div className="col-md-12 col-12 pl_2 pr_0 mt_8 mb_16">
                        {url && (
                            <CustomTextField
                                className="w-100"

                                placeholder="URL"
                                name="upgradePlanUrl"
                                inputProps={{ tabIndex: '11' }}
                                defaultValue={formik.values.upgradePlanUrl ? formik.values.upgradePlanUrl : ''}
                                onBlur={formik.handleChange}
                                error={formik.errors.upgradePlanUrl && formik.values.upgradePlanUrl && !formik.values.upgradePlanUrl.state ? formik.errors.upgradePlanUrl.state : false}
                                helperText={formik.errors.upgradePlanUrl && formik.values.upgradePlanUrl && !formik.values.upgradePlanUrl.state ? formik.errors.upgradePlanUrl.state : ''}
                            />

                        )}


                    </div>

                    {/* {brand.brandType === Brand.BrandTypes.NORMAL &&
                        <>
                            <div className='d-flex space-between w-100 align-items-center'>
                                <div className="Heading16M fs-18 color-c000000">
                                    VIP Club
                                </div>
                                <div>
                                    <CustomSwitch
                                        checked={formik.values.vip}
                                        value={formik.values.vip}
                                        onChange={async (e) => {
                                            formik.setValues({ ...formik.values, vip: !formik.values.vip })
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="Body14R color-neutral60">
                                Beware, if you Refresh API Key all connected API methods will be disabled. Here is the link to Swagger where you can try our API. Help article is available here.
                            </div>
                        </>} */}
                    <div className= "col-12 pl_0 pr_0 mt_16 mb_36  ">
                        <div className='d-flex space-between w-100 align-items-center'>
                            <div className="Heading16M fs-18 color-c000000">
                                Loyalty Tiers
                            </div>
                            <div className={show?.validation ? "disabled" : ""}>
                                <CustomSwitch
                                    checked={formik.values.tier}
                                    value={formik.values.tier}
                                    onChange={async (e) => {
                                        formik.setValues({ ...formik.values, tier: !formik.values.tier })
                                    }}
                                />
                            </div>

                        </div>
                        <div className="Body14R color-neutral60">
                            Beware, if you Refresh API Key all connected API methods will be disabled. Here is the link to Swagger where you can try our API. Help article is available here.
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
