import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
import MainService from './Main.service'
const service  = '/v4/agency'

const Get = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetPublic = async ({query}) => await axios.get(env.API_URL+service+'/public/get?'+utils.getQueryString(query))

const ConnectBrand = async ({payload}) => await axios.post(env.API_URL+service+'/connect', payload, {headers: await MainService.getTokenHeader()})

const StripeCoupon = async ({query}) => await axios.get(env.API_URL+service+'/stripeCoupon?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const update = async ({payload}) => await axios.put(env.API_URL+service+'/'+payload, {headers: await MainService.getTokenHeader()})

const WhiteLabelBrandConnection = async ({payload}) => await axios.post(env.API_URL+service+'/connectSubscription', payload, {headers: await MainService.getTokenHeader()})

const AgencyService = {
    Get                      : Handlers.Services(Get),
    GetPublic                : Handlers.Services(GetPublic),
    ConnectBrand             : Handlers.Services(ConnectBrand),
    StripeCoupon             : Handlers.Services(StripeCoupon),
    update                   : Handlers.Services(update),
    WhiteLabelBrandConnection: Handlers.Services(WhiteLabelBrandConnection)
}

export default AgencyService
