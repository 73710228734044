import React, { useContext, useEffect, useState } from 'react'
import * as localForage from 'localforage'

import CustomButton from '../../components/CustomButton';
import { AgencyContext } from '../../context/Agency.context';
import { BrandContext } from '../../context/Brand.context';
import { ColorSchemeCode } from '../../enums/ColorScheme';

import SvgIcons from '../../icons/svg.icon';
import { utils } from '../../utils';
import { CircularProgress } from '@mui/material';
import { AgencyService, BrandService } from '../../services';
import CustomsModal from '../../components/CustomModal';
import CustomTextField from '../../components/CustomTextField';
import { Brand } from '../../enums';

function AgencyInvite() {

    const agency = useContext(AgencyContext);

    const [state, setState] = useState({
        brands       : [],
        apiError     : '',
        id           : false,
        coupon       : '',
        paymentModal : false,
        selectedBrand: '',
    })

    const [show, setShow] = useState({
        paymentModal : false,
    })

    const onLoad = async () => {
        setState({...state, brands : []})
        const user = await localForage.getItem('user')
        let query = {createdBy: user._id, delete: false}
        const {error, response} = await BrandService.Get({query})
        if(error) setState({...state, apiError: 'No brand Found'})
        if(response) 
        response.data.map((element, idx) => {
            if(element.agency || !utils.emptyJSON(response.agency)) state.brands.push({...element})
        })
        setState({...state})
    }
    useEffect(onLoad, [])


    const handleConnect = async (e, id, brand) => {
        e.stopPropagation()
        setState({...state, id})

        const payload = {
            agencyId : agency._id,
            brandId  : brand._id,
        }


        const {error, response} = await AgencyService.ConnectBrand({toaster: true, payload})

        if(response){
            brand.agency = {
                id           : agency.id,
                subscribed   : true,
                subscribedAt : Date.now()
            }
    
            setState({...state})
        }
    }

    return (
        <div id="agencyInvite">
            <div className="cardBox middle">
                <div className="col-6 card position-relative">
                    <div className="ConfirmationText mt_24">
                        Would you like to connect with <br/> {agency.agencyName} ?
                    </div>   
                    <div className="description mt_10 text-center">
                        <div>
                         {
                            state.brands.length > 0 && !state.apiError &&  
                                state.brands.map((brand, idx)=>(
                                    <>
                                    <div className='w-100 brand cp ' onClick={()=> setState({...state , selectedBrand: state.brands[idx]})}>
                                        <div className='d-flex flex-row space-between mt_20'>
                                            <div className='d-flex space-between'>
                                                <div className=''>
                                                    {   brand.brandLogo ?
                                                        <img src={brand.brandLogo} width='40px' className='brandimg' height='40px' alt=''/> :
                                                        utils.getAvatar({firstName: brand.brandName, width: '40px', heigth: '40px'})
                                                    }
                                                </div>
                                                <div className='pl_8 middle'>
                                                    <div className='Body14R'>
                                                        {brand.brandName}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="middle text-align-center">
                                                <div 
                                                    onClick={()=>{setShow({...show, paymentModal: true})}}
                                                    style={{height: "36px", width: '120px'}} // remove inline style
                                                    className="borderRadius-2 middle pr_14 pl_14 pt_4 pb_4 color-whiteColor">
                                                       {(brand.agency && brand.agency.subscribed) ? "Disconnect" : "Connect"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        <CustomsModal 
                                        component = {<PaymentModal state={state} setState={setState} show={show} setShow={setShow} selectedBrand={brand}/>}
                                        open      = {show.paymentModal}
                                        onClose   = {()=>setShow({...show, paymentModal : false})}
                                        minWidth  = "560px"
                                    />
                                </>
                                ))
                         }   
                        </div>
                        <div className='error'>
                            {state.apiError}
                        </div>


                    </div> 
                </div>
            </div>
        </div>
    )
}



const PaymentModal = ({selectedBrand, setShow, show, state, setState}) => {
    const agency = useContext(AgencyContext)
    const [discounted, setDiscounted] = useState()
    const [loader, setLoader] = useState(false)
    const handleCouponCode = async(e, coupon) => {
        setState({...state, coupon : e.target.value})
        const {response, error} = await AgencyService.StripeCoupon({query: {couponCode: coupon ? coupon : e.target.value}})
        response && response.data && response.data.length>0 && // console.log('blablabla',parseInt((response.data[0].couponData.amount_off.toString()).slice(0, -2)));
        response && response.data && response.data.length>0 && setDiscounted(parseInt((response.data[0].couponData.amount_off.toString()).slice(0, -2)))
        error && setDiscounted()
    }

    return(
        <div>
            <div className="fw-5 fs-22 color-c000000">
                Your Payment
            </div>
            <div className="mt_24 d-flex justify-content-center">
                <div className="fs-14 fw-5 color-c000000 mr_16 middle">
                    Connect Brand:
                </div>
                <div className="d-flex">
                    <img src={selectedBrand.brandLogo} className="borderRadius-50 border" height="40px" width="40px" alt="" />
                    <div className="fs-14 color-c000000 ml_8 fw-5 middle">
                         {selectedBrand.brandName}
                    </div>
                </div>
            </div>
            <div className="mt_24 pl_16 pr_16 pt_16 pb_16 bg-color-buttonTertiaryColor w-100 borderRadius-6">
                <div className="d-flex space-between">
                    <div className="fs-16 color-c000000 fw-5">
                        Ulitmate Agency
                    </div>
                    <div className="d-flex">
                        {discounted && <div className="fs-16 middle color-c000000 fw-5">
                            {"$"+(99-discounted)}
                        </div>}
                        <div className={`ml_8 fs-14 middle color-neutral60InputColor ${discounted && 'text-decoration-line'}`}>
                            $99
                        </div>
                        <div className="color-c000000 fs-12 middle ml_8">
                            /per brand
                        </div>
                    </div>
                </div>
                <div className="bar mt_24 mb_24"></div>
                <div className="d-flex space-between">
                    <div className="fs-16 color-c000000 fw-5">
                        Start Billing Date
                    </div>
                    <div className="fs-16 color-c000000 fw-5">
                        Jan 4, 2022
                    </div>
                </div>
            </div>
            <div className="d-flex space-between mt_24">
                <div className="color-themeColor fs-14 fw-5 middle">
                    Add Discount Code
                </div>
                <div>
                    <CustomTextField 
                        value    = {state.coupon}
                        onChange = {handleCouponCode}
                    />
                </div>
            </div>
            <div className="mt_32 d-flex justify-flex-end">
                <div className="mr_16">
                    <CustomButton 
                        btntext         = "Cancel"
                        onClick         = {()=>setShow({...show, paymentModal : false})}
                        height          = {36}
                        color           = {ColorSchemeCode.c000000}
                        backgroundColor = {ColorSchemeCode.cF1F1F1}
                        hover           = {ColorSchemeCode.cF1F1F1}
                    />
                </div>
                <div>
                    <CustomButton 
                        btntext  = "Confirm Payment"
                        icon     = {loader && <CircularProgress size={10} color={'inherit'}/>}
                        position = "start"
                        disabled = {loader}
                        onClick  = {async()=>{
                            setLoader(true)
                            // setProcessing({id : brand._id, show : true})
                            const payload = {
                                // agencyId : agency._id,
                                myBrand  : selectedBrand.myBrand,
                                _id      : agency._id,
                                brandId  : selectedBrand._id,
                                planName : Brand.Plans.BRAND_CONNECTION,
                                email    : agency.email,
                                version  : 1,
                                couponId : state.coupon
                            }

                            // const {error, response} = await AgencyService.ConnectBrand({toaster: true, payload})
                            const {error, response} = await AgencyService.WhiteLabelBrandConnection({toaster: true, payload})

                            if(response){
                             let arr = []
                             state.brands.map((element, idx)=>{
                                 if(element._id === selectedBrand._id)
                                 {
                                    arr.push({...element, agency: {id : agency.id, subscribed: true}})
                                 }
                                 else arr.push(element);
                             })
                             setState({...state, selectedBrand : {...selectedBrand, agency: {id : agency.id, subscribed: true}}, brands : arr})
                            }
                            setLoader(false)
                            setShow({...show, paymentModal : false})
                            
                            // setProcessing({id : '', show : false})
                        }}
                            // setState({...state, paymentModal : false})}
                        height          = {36}
                    />
                </div>
            </div>
        </div>

    )
}

export default AgencyInvite
