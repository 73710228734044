import React, {useContext} from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import BarcodeScanner from './subpages/BarcodeScanner';
import CameraScanner from './subpages/CameraScanner';
import CodeInput from './subpages/CodeInput';
import CustomerInfo from './subpages/CustomerInfo';
import BusinessUsers from './subpages/BusinessUsers';
import Redemption from './subpages/Redemption';
import { LayoutContext } from '../../context/Layout.context';


export default function WalletScan(props) {
    const layout = useContext(LayoutContext)

    return (
        <div id="WalletScan" >
            <div className="">
                <Switch>
                    <Route path = {props.match.url + '/barcodescanner'}  render = {() =>    <BarcodeScanner {...props}  />}/>
                    <Route path = {props.match.url + '/camerascan'}      render = {() =>    <CameraScanner  {...props}  />} />
                    {/* <Route path = {props.match.url + '/codeinput'}       render = {() =>    <CodeInput      {...props}  />}/> */}
                    {/* <Route path = {props.match.url + '/customerinfo'}    render = {() =>    <CustomerInfo   {...props}  />} /> */}
                    <Route path = {props.match.url + '/businessusers'}   render = {() =>    <BusinessUsers  {...props}  />} />
                    <Route path = {props.match.url + '/redemption'}   render = {() =>    <Redemption  {...props}  />} />
                    
                    <Redirect path = {props.match.url + '/*'} to = {props.match.url+ '/barcodescanner'} />
                    <Redirect path = {props.match.url} to        = {props.match.url+ '/barcodescanner'} />
                </Switch>
            </div>
        </div>
    )
}
