import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomButton from '../../../../components/CustomButton'
import CustomTextField from '../../../../components/CustomTextField'
import { AgencyContext } from '../../../../context/Agency.context'
import { BrandContext } from '../../../../context/Brand.context'
import { Brand, enums } from '../../../../enums'
import { SvgIcons } from '../../../../icons'
import { BrandService, CampaignService, NotificationService } from '../../../../services';
import localForage from 'localforage';
import CustomTableV2 from '../../../../components/CustomTableV2';
import { LayoutContext } from '../../../../context/Layout.context';
import CustomSearch from '../../../../components/CustomSearch';
import { ClickOutside, utils } from '../../../../utils';
import CustomDateRange from '../../../../components/CustomDateRange';
import { ColorSchemeCode } from '../../../../enums/ColorScheme';
import ReactTooltip from 'react-tooltip';
import EmptyScreen from '../../../../components/EmptyScreen';
import Loader from '../../../../components/Loader';

function Listing() {

    const brand    = useContext(BrandContext)
    const agency   = useContext(AgencyContext)

    const history = useHistory()
    

    const [loader, setLoader] = useState(true)
    const [filters, setFilters] = useState({
        from   : '',
        startDate: '',
        endDate  : '',
    })

    const [show, setShow] = useState({
        filter : false,
    })


    const [result, setResult]               = useState([])
    const [campaigns, setCampaigns]         = useState([])
    const [notifications, setNotifications] = useState([])

    const columns = [
        { field: 'NotificationTitle', headerName: 'Notification Title', flex: 3},
        { field: 'From', headerName: 'From', flex: 2},
        { field: 'Sent', headerName: 'Sent', flex: 2},
        { field: 'Created', headerName: 'Created', flex: 2 },
        { field: 'Status', headerName: 'Status', flex: 2 ,
            renderCell: (params) => {
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
                const date = new Date(params.row.value.scheduleDate).getDate() + " - " + monthNames[new Date(params.row.value.scheduleDate).getMonth()] + " - " + new Date(params.row.value.scheduleDate).getFullYear()
                const time = new Date(params.row.value.scheduleDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                return (
                        params.row.Status === 'Schedule' ? 
                        <>
                            <div id="Schedule" data-for={'Schedule'} data-tip={'Schedule'} className="color-themeColor">Schedule</div>
                            <ReactTooltip backgroundColor={ColorSchemeCode.Paragraph} className="opacity-8 p_8 borderRadius-6 Caption12R" id="Schedule" getContent={()=>date+" "+time} place="bottom"/>
                        </>
                        : <div className="color-selectGreenBackgroundColor">Sent</div>
                );
            }
        },
    ];


    const pushArr = (arr, body) => {
        arr.forEach(async(v,index) => {
            body.push({
                id                : v._id,
                value             : v,
                NotificationTitle : v.displayTitle ? v.displayTitle                                                                                                                     : v.title || '-',
                Sent              : v.sendTo.length + " customers",
                Status            : v.scheduleDate ? 'Schedule'                                                                                                                         : 'Sent',
                From              : v.brandName ? v.brandName                                                                                                                           : '-',
                Created           : new Date(v.created).toLocaleDateString() + '  ' + new Date(v.created).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
            })
        });
        return body
    }

    const handleSearch = (e) => {
        const result = notifications.filter(item =>
            item.NotificationTitle.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setResult(result)
    } 

    const getCampaigns = async () => {
        let allLoyalityCampaigns = await localForage.getItem('allLoyalityCampaigns');
        if(!allLoyalityCampaigns)
        {
            const query = {
                brandId      : (brand.brandType === Brand.BrandTypes.SUB_BRAND) ? brand.parentBrandId : brand._id,
                campaignType : 5
            }
            const {error, response} = await CampaignService.GetQueriedCampaign({query})
            allLoyalityCampaigns = response.data
            localForage.setItem('allLoyalityCampaigns', allLoyalityCampaigns)
            if(error) return
        }
        setCampaigns(allLoyalityCampaigns)
        return
    }

    const getBrandNotification = async (refresh) => {

        setLoader(true)

        let notifications = await localforage.getItem('notifications')
        let subBrands     = await localforage.getItem('subBrands')
        if(subBrands === null || subBrands.length === 0){
            const query = {delete: false, parentBrandId: brand._id, brandType : Brand.BrandTypes.SUB_BRAND}
            const { error, response } = await BrandService.Get({query})
            if(response) subBrands = response.data
        }

        if((!notifications) || notifications.length === 0 || refresh || history.location.state === "reload") 
        {
            const query = {agencyId: agency._id, delete: false}
            const {response, error} = await NotificationService.Get({query})
            if(response){
                let body = []
                let arr = []
                const noti = response.data.map((value, idx) => {
                    if(value.type === enums.Notification_Type.IMAGE || value.type === enums.Notification_Type.TEXT){
                        subBrands && subBrands.map((element)=>{
                            if(element._id === value.brandId) value.brandName = element.brandName
                        })
                        return value
                    }
                })
                var filtered = noti.filter(function(x) {
                        return x !== undefined;
                });
                arr = await pushArr(filtered, body)
                arr = await filterNotificationWithBrandIdFunc(arr)
                setNotifications(arr);
                setResult(arr);
                localForage.setItem('notifications', arr)
            }
            if(error) return;
        }
        else{
            let body = []
            let arr = []
            const noti = notifications.map((value, idx) => {
                subBrands && subBrands.length > 0 && subBrands.map((element)=>{
                    if(element._id === value.value.brandId) value.value.brandName = element.brandName
                })
                return value.value
            })

            var filtered = noti.filter(function(x) {
                return x !== undefined;
            });
            arr = await pushArr(filtered, body)
            arr = await filterNotificationWithBrandIdFunc(arr)
            setNotifications(arr);
            setResult(arr);
        }

        setLoader(false)
        return
    }

    const filterNotificationWithBrandIdFunc = async(notifications) => {
        const notificationList = await notifications.filter( notification => 
            notification.value.brandId  == brand._id
          )

        return notificationList
    }

    const onLoad = async() => {
        await getCampaigns()
    }
    
    useEffect(() => {
        if(campaigns && campaigns.length > 0){
            setResult([])
            setNotifications([])
            // getSubBrandNotification()
            getBrandNotification()

        }
    }, [campaigns])

    useEffect(onLoad,[]);

    const layout = useContext(LayoutContext)

    useEffect(()=>{
        layout.setLayout({
            expandedBar  : true,
            title        : 'Stories',
            mainPage     : 'Communication',
            backTitles  : ['Communication'],
            borderBottom : true,
            edit         : false,
            button       : <CustomButton btntext = {"Create Notifcation"} onClick={()=>{
                const location = window.location.pathname.split('/')
                history.push(location.join('/') + '/create')
            }}
            />
        })
    },[])

    const handleDateChange = (ranges) => {
        let startDate =  new Date(ranges.selection.startDate).valueOf();
        let endDate   =  new Date(ranges.selection.endDate).valueOf();

        setFilters({...filters, startDate : startDate, endDate: endDate})
    }

    const handleFilterSearchFunc = () => {
        let result=notifications;
        if(filters.from != ""){
            result = notifications.filter(item =>
                item.From.toLowerCase().includes(filters.from.toLowerCase())
            );
        }
        else if((filters.startDate != "") && (filters.endDate != ""))
        {   
            var endDate    = new Date (filters.endDate);
            var startDate  = new Date (filters.startDate);
            let notificationCustomers = [];
            result.length > 0 && result.map((notification, idx)=>{
                var createDate = new Date (notification.value.created); 
                if(((createDate.getDate() >= startDate.getDate()) && (createDate.getMonth() >= startDate.getMonth())) && ((createDate.getDate() <= endDate.getDate())) && (createDate.getMonth() >= endDate.getMonth())){
                    notificationCustomers.push(notification);
                }
            })
            result = notificationCustomers;
        }
    
        setResult(result)
        setShow({...show, showFiter : false})
    }

    return (
        <div id="NotificationListing">
            {
                loader ? <Loader height={180} width={180}/>
                :
                <>
                    {
                        notifications && notifications.length > 0 ? <div>
                        <div className="d-flex space-between align-items-center">
                        <div className="position-relative col-8 pl_0 pr_0">
                            <CustomSearch
                                className     = "w-100"
                                placeholder   = "Search by notification title"
                                icon          = {<SvgIcons.SearchIcon/>}
                                position      = {'start'}
                                onChange      = {(e)=>handleSearch(e)}
                                onClickFilter = {()=>setShow({...show, showFiter : !show.showFiter})}
                                filterShow    = {show.showFiter}
                                
                            />
                            <div className="w-50">
                                <ClickOutside onClick={()=>setShow({...show, showFiter : false})}>
                                    {
                                        show.showFiter && 
                                        <CustomFilter resetFilter={()=>{setFilters({from : '', startDate: '', endDate: ''}); setShow({...show, showFiter : false}); setResult(notifications)}} handleFilterSearchFunc={handleFilterSearchFunc} filters={filters} setFilters={setFilters} handleDateChange={handleDateChange} />
                                    }
                                </ClickOutside>
                            </div>
                        </div>
                        <div className="d-none">
                                <div id='customRefresh' onClick={()=>getBrandNotification(true)}>
                                    <img className='imageButton cp' height='20px' width='20px' src='/images/Refresh.png'/>
                                </div>
                            </div>
                        </div>

                        <CustomTableV2
                            pageSize      = {50}
                            height        = {'calc(100vh - 120px)'}
                            checkbox      = {false}
                            rows          = {result}
                            title         = {'Notification'}
                            columns       = {columns}
                            deleteAll     = {false}
                            handleRefresh = {()=>getBrandNotification(true)}
                            onRowClick    = {(value)=>console.log(value.row.value)}
                            />
                    </div>
                        :
                        <EmptyScreen 
                        title    = {'Notification'}
                        btnTitle = {'Add Notification'}
                        btnClick ={()=>{
                            const location = window.location.pathname.split('/')
                            location.pop()
                            history.push(location.join('/') + '/create')
                        }}
                        />    
                    }
                </>
            }
        </div>
    )
}

const CustomFilter = ({handleDateChange, filters, setFilters, handleFilterSearchFunc, resetFilter}) => {

    const [showDate, setShowDate] = useState(false);

    const handleChange = (e,value)=>{
    }

    return(
        <div id="filterComponentAudience">
            <div className="row mt_24">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    From
                </div>
                <div className="col-9">
                    <CustomTextField 
                        label     = "From"
                        className = "w-100"
                        onChange  = {(e)=>setFilters({...filters, from : e.target.value})}
                        value     = {filters.from}
                    />
                </div>
            </div>
            
            <div className="row mt_24 position-relative">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Created
                </div>
                <div className="col-9">
                    <div className={`subscribedDate Body14R color-neutral60 cp d-flex ${showDate && 'isActiveDate'}`} onClick={()=>setShowDate(!showDate)}>
                        {(filters.startDate != "" && filters.endDate != "") ? 
                        <>
                            <div>
                                {new Date(filters.startDate).getDate() + '-' + (new Date(filters.startDate).getMonth() + 1) + '-' +new Date(filters.startDate).getFullYear()}
                            </div>
                            <span className="ml_8 mr_8">
                                -
                            </span>
                            <div>
                                {new Date(filters.endDate).getDate() + '-' + (new Date(filters.endDate).getMonth() + 1) + '-' +new Date(filters.endDate).getFullYear()}
                            </div>
                        </>
                        :
                        <div className="Body14R color-neutral60">Overall</div>    
                    }
                    </div>
                    <ClickOutside onClick={()=>setShowDate(false)}>
                        <div className="position-absolute dateBox">
                            {showDate && <CustomDateRange onChange={handleDateChange}/> }
                        </div>
                    </ClickOutside>
                </div>
            </div>

                <div className="d-flex mt_24 justify-flex-end">
                <div>
                    <CustomButton
                        btntext   = "Reset Filter"
                        varient   = "secondary"
                        className = "mr_16"
                        onClick   = {resetFilter}
                    />
                </div>
                <div>
                    <CustomButton
                        btntext = "Search"
                        varient = "primary"
                        onClick = {handleFilterSearchFunc}
                    />
                </div>
            </div>
           
            
        </div>
    )
}

export default Listing
