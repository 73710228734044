import React, { useContext, useEffect } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import { LayoutContext } from '../../context/Layout.context';
import Home from './subpages/Home';
import RefferalPage from './subpages/RefferalPage';
import Audience from './subpages/Audience';
import { useHistory } from 'react-router-dom'

export default function Setting(props) {
    
    const layout = useContext(LayoutContext)
    const history = useHistory()


    React.useEffect(()=>{
        layout.setLayout({showCollapseIcon: false, expandedBar : false, borderBottom: false , title : 'Audience', edit : false})
      },[])
    
    return (
        <div id="Dashboard">
            <Switch>    
                <Route    exact path = {props.match.url + '/home'} component = {Home}/>
                <Redirect path       = {props.match.url+ '/*'} to            = {props.match.url+ '/home'} />
                <Redirect path       = {props.match.url} to                  = {props.match.url+ '/home'} />
            </Switch>
        </div>
    )
}


