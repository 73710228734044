import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { ColorSchemeCode } from "../enums/ColorScheme";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((props) => ({
  root: {
    backgroundColor: (props) =>
      props.disabled ? 'rgba(0, 0, 0, 0.12) !important' :
      props.varient == "tertiary"
        ? "transparent"
        : props.varient === "dangerPrimary"
        ? ColorSchemeCode.danger30 + ' !important'
        : props.varient === "dangerSecondary"
        ? ColorSchemeCode.white + ' !important'
        : props.varient === "dangerTertiary"
        ? ColorSchemeCode.white + ' !important'
        : props.varient === "tertiary"
        ? ColorSchemeCode.white + ' !important'
        : props.varient === "secondary"
        ? "transparent" + ' !important'
        : props.varient === "secondary1"
        ? "transparent" + ' !important'
        : props.varient === "secondaryOutline"
        ? "transparent" + ' !important'
        : props.varient === "primary"
        ? ColorSchemeCode.primary50 + ' !important'
        : props.varient === "warning"
        ? ColorSchemeCode.danger30 + ' !important'
        : props.varient === "warningSecondary"
        ? ColorSchemeCode.white + ' !important'
        : props.varient === "warningSecondary1"
        ? ColorSchemeCode.white + ' !important'
        : ColorSchemeCode.primary50 + ' !important' ,
    borderRadius: "3px" + ' !important',
    boxShadow  : 'none '+ ' !important',
    outline: (props) =>
      props.varient == "secondary"
        ? "1px solid " + ColorSchemeCode.neutral20 + ' !important'
        : props.varient == "secondary1"
        ? "none !important"
        : props.varient == "dangerPrimary"
        ? "1px solid " + ColorSchemeCode.neutral20 + ' !important'
        : props.varient === "warningSecondary"
        ? "1px solid " + ColorSchemeCode.neutral20 + ' !important'
        : props.varient === "warningSecondary1"
        ? "1px solid black !important"
        : props.varient == "secondaryOutline"
        ? "0px !important"
        : "1px solid transparent" + ' !important',
    padding: (props) =>
      props.size === "xl"
        ? "16px 16px + ' !important'"
        : props.size === "l"
        ? "12px 16px + ' !important'"
        : props.size === "s"
        ? "6px 16px + ' !important'"
        : "8px 16px + ' !important'",
    color: (props) =>
      props.varient === "dangerPrimary"
        ? ColorSchemeCode.primary0 + ' !important'
        : props.varient === "dangerSecondary"
        ? ColorSchemeCode.danger30+ ' !important'
        : props.varient === "dangerTertiary"
        ? ColorSchemeCode.danger30+ ' !important'
        : props.varient === "tertiary"
        ? ColorSchemeCode.primary50+ ' !important'
        : props.varient === "secondary"
        ? ColorSchemeCode.primary50+ ' !important'
        : props.varient === "secondary1"
        ? 'black !important'
        : props.varient === "secondaryOutline"
        ? ColorSchemeCode.primary50+ ' !important'
        : props.varient === "primary"
        ? ColorSchemeCode.primary0+ ' !important'
        : props.varient === "warning"
        ? ColorSchemeCode.white+ ' !important'
        : props.varient === "warningSecondary"
        ? ColorSchemeCode.danger30+ ' !important'
        : props.varient === "warningSecondary1"
        ? 'black !important'
        : ColorSchemeCode.primary0+ ' !important',
    textTransform: "capitalize" + ' !important',
    "&:hover": {
      backgroundColor: (props) =>
        props.varient === "dangerPrimary"
          ? ColorSchemeCode.danger40 + ' !important'
          : props.varient === "dangerSecondary"
          ? ColorSchemeCode.danger0 + ' !important'
          : props.varient === "dangerTertiary"
          ? ColorSchemeCode.danger0 + ' !important'
          : props.varient === "tertiary"
          ? ColorSchemeCode.primary0 + ' !important'
          : props.varient === "secondary"
          ? ColorSchemeCode.primary0 + ' !important'
          : props.varient === "secondary1"
          ? 'none !important'
          : props.varient == "secondaryOutline"
          ? ColorSchemeCode.primary0 + ' !important'
          : props.varient === "primary"
          ? ColorSchemeCode.primary60 + ' !important'
          : props.varient === "warning"
          ? ColorSchemeCode.danger20 + ' !important'
          : props.varient === "warningSecondary"
          ? ColorSchemeCode.danger0 + ' !important'
          : props.varient === "warningSecondary1"
          ? 'none !important'
          : ColorSchemeCode.primary60 + ' !important',
      color: (props) =>
        props.varient === "dangerPrimary"
          ? ColorSchemeCode.primary0 + ' !important'
          : props.varient === "dangerSecondary"
          ? ColorSchemeCode.danger30 + ' !important'
          : props.varient === "dangerTertiary"
          ? ColorSchemeCode.danger30 + ' !important'
          : props.varient === "tertiary"
          ? ColorSchemeCode.primary50 + ' !important'
          : props.varient === "secondary"
          ? ColorSchemeCode.primary50 + ' !important'
          : props.varient === "secondary1"
          ?  'black !important'
          : props.varient === "secondaryOutline"
          ? ColorSchemeCode.primary50 + ' !important'
          : props.varient === "primary"
          ? ColorSchemeCode.primary0 + ' !important'
          : props.varient === "warning"
          ? ColorSchemeCode.white + ' !important'
          : props.varient === "warningSecondary1"
          ? 'black !important'
          : props.varient === "warningSecondary"
          ? ColorSchemeCode.danger40 + ' !important'
          : ColorSchemeCode.primary0 + ' !important',
      outline: (props) =>
        props.varient === "secondary"
          ? "1px solid" + ColorSchemeCode.primary50 + ' !important'
          :props.varient === "secondary1"
          ? "none !important"
          :props.varient === "secondaryOutline"
          ? "0px !important "
          : props.varient == "dangerSecondary"
          ? "1px solid " + ColorSchemeCode.danger30 + ' !important'
          : props.varient === "warningSecondary"
        ? "1px solid " + ColorSchemeCode.danger40+ ' !important'
          : props.varient === "warningSecondary1"
        ? "1px solid " +'black !important'
          : "1px solid transparent " + ' !important',
      boxShadow: "none" + ' !important',
    },
    "&:focus": {
      backgroundColor: (props) =>
        props.varient === "dangerPrimary"
          ? ColorSchemeCode.danger50+ ' !important'
          : props.varient === "dangerSecondary"
          ? ColorSchemeCode.danger10+ ' !important'
          : props.varient === "dangerTertiary"
          ? ColorSchemeCode.danger10+ ' !important'
          : props.varient === "tertiary"
          ? ColorSchemeCode.white+ ' !important'
          : props.varient === "secondary"
          ? ColorSchemeCode.primary10+ ' !important'
          : props.varient === "secondary1"
          ? 'none !important'
          : props.varient === "secondaryOutline"
          ? ColorSchemeCode.primary10+ ' !important'
          : props.varient === "primary"
          ? ColorSchemeCode.primary70+ ' !important'
          : props.varient === "warning"
          ? ColorSchemeCode.ButtonWarningPressedText + ' !important'
          : props.varient === "warningSecondary"
          ? ColorSchemeCode.danger10 + ' !important'
          : props.varient === "warningSecondary1"
          ? 'none !important'
          : ColorSchemeCode.primary70 + ' !important',
      color: (props) =>
        props.varient === "dangerPrimary"
          ? ColorSchemeCode.primary0+ ' !important'
          : props.varient === "dangerSecondary"
          ? ColorSchemeCode.danger60+ ' !important'
          : props.varient === "dangerTertiary"
          ? ColorSchemeCode.danger50+ ' !important'
          : props.varient === "tertiary"
          ? ColorSchemeCode.primary70+ ' !important'
          : props.varient === "secondary"
          ? ColorSchemeCode.primary70+ ' !important'
          : props.varient === "secondary1"
          ?  'black !important'
          : props.varient === "secondaryOutline"
          ? ColorSchemeCode.primary70+ ' !important'
          : props.varient === "primary"
          ? ColorSchemeCode.primary0+ ' !important'
          : props.varient === "warning"
          ? ColorSchemeCode.primary0+ ' !important'
          : props.varient === "warningSecondary"
          ? ColorSchemeCode.danger50+ ' !important'
          : props.varient === "warningSecondary1"
          ? 'black !important'
          : ColorSchemeCode.white+ ' !important',
      outline: (props) =>
        props.varient === "secondary"
          ? "2px solid" + ColorSchemeCode.primary50 + ' !important'
          :props.varient === "secondary1"
          ? 'none !important'
          :props.varient === "secondaryOutline"
          ? "0px solid" + ColorSchemeCode.primary50 + ' !important'
          : props.varient == "dangerSecondary"
          ? ColorSchemeCode.danger50+ ' !important'
          : props.varient === "warningSecondary"
          ? "1px solid " + ColorSchemeCode.danger50 + ' !important'
          : props.varient === "warningSecondary1"
          ? "1px solid " + 'black !important'
          : "1px solid transparent"+ ' !important',
    },
  },
}));

export default function CustomButton({
  backgroundColor,
  color,
  className,
  onClick,
  disabled,
  btntext,
  icon,
  ...props
}) {
  const propsObj = {
    className: className,
    ...props,
  };
  const classes = useStyles({ ...props });
  const customClass = propsObj.className;

  delete propsObj.className;

  return (
    <Button
      {...propsObj}
      onClick   = {onClick}
      variant   = "contained"
      
      disabled  = {disabled}
      className = {
        "U14M " +
        (props.varient === "secondary" 
          ? classes.root + " secondary " + customClass
          : props.varient === "secondary1"
          ? classes.root + " secondary " + customClass
          : props.varient === "primary"
          ? classes.root + " customButton " + customClass
          : props.varient === "warning"
          ? classes.root + " warning " + customClass
          : props.varient === "warningSecondary"
          ? classes.root + " warningSecondary " + customClass
          : props.varient === "warningSecondary1"
          ? classes.root + " warningSecondary " + customClass
          : classes.root + " customButton " + customClass)
      }
      color     = {color}
    >
      {icon ? (
        <>
          {props.position!=='end'&&<div className="d-flex">{icon} </div>}
          {btntext != "" && <div className="ml_6">{btntext}</div>}
          {props.position==='end'&&<div className="ml_10 d-flex">{icon} </div>}
        </>
      ) : (
        <div> {btntext} </div>
      )}
    </Button>
  );
}
