import axios from 'axios'
import { MainService } from '.'
import { Handlers, utils } from '../utils'
import env from '../config'
const service = '/v4/flow'

const Create = async ({payload}) => await axios.post(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const Get = async({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query))

const Update = async ({payload}) => await axios.put(env.API_URL+service, payload, {headers: await MainService.getTokenHeader()})

const FlowService = {
    Create : Handlers.Services(Create),
    Get    : Handlers.Services(Get),
    Update : Handlers.Services(Update),
}

export default FlowService
