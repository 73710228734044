import * as yup from 'yup';
import { Transactions } from '../enums';

const location = yup.object({
    lat     : yup.number().required(),
    lng     : yup.number().required(),
    address : yup.string()
})

const Create = yup.object({
    location        : location.required(),
    transactionType : yup.string().oneOf(Object.values(Transactions.TransactionTypes)),
    amount          : yup.number().when('transactionType', {
                        is   : value => value === Transactions.TransactionTypes.AMOUNT,
                        then : yup.number().min(0).moreThan(0).required()
                    }),
    points          : yup.number().when('transactionType', {
                        is   : value => value === Transactions.TransactionTypes.BONUS,
                        then : yup.number().moreThan(0).required()
                    }),
    pointUsed       : yup.number().min(0),
    discountValue   : yup.number().min(0),
    discountDeal    : yup.string(),
    stampUsed       : yup.number().min(0)
})





export {
    Create,
}