import { CircularProgress } from '@mui/material';
import localforage from 'localforage';
import React, { useState } from 'react'
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router-dom'
import CustomButton from '../../components/CustomButton';
import CustomsModal from '../../components/CustomModal';
import RedeemModal from '../../components/RedeemModal';
import { RedemptionType } from '../../enums/enums';
import { SvgIcons } from '../../icons';
import { TransactionService } from '../../services';
import { utils } from '../../utils';

function LandingPageDeal() {
    const history = useHistory();
    const params = useParams();

    const [state, setState] = useState({
        loader     : false,
        apiError   : '',
        modal      : false,
        modalLoader: false
    })

    window.onbeforeunload = function (e) {
        if (e) e.returnValue = history.push('/page/'+params.growthtoolId)
        return history.push('/page/'+params.growthtoolId);
    };

    const handleDeal = async () => {
        setState({...state, modal : true});
        return
        // return history.push({pathname: '/page/'+params.growthtoolId+'/points',state: {...history.location.state}})
    }

    const handleRedeem = async () => {
        setState({...state, modalLoader : true});
        // let payload = {...history.location.state.payload, points : selectedValue.points, amount: selectedValue.minValue, flowId: history.location.state.flowIdArray}
        let payload = {...history.location.state.payload, points: 0, flowId: history.location.state.flowIdArray}
        delete payload.firstName
        delete payload.agencyId;
        if(!history.location.state.dealDetail) payload.actionTriggerCall = true
        let SelfSingleLoyalityTransaction = await TransactionService.SelfSingleLoyalityTransaction({payload, headers: history.location.state.headers})
        if(SelfSingleLoyalityTransaction.error && SelfSingleLoyalityTransaction.error.data) return history.push({pathname:'/page/'+params.growthtoolId+'/redeem', state: {...SelfSingleLoyalityTransaction.error.data, tiers : history.location.state.tiers}})
       
        if(SelfSingleLoyalityTransaction.response && history.location.state.dealDetail) {
            payload = {
                transaction : SelfSingleLoyalityTransaction.response.data.transaction,
                availDeal   : history.location.state.dealDetail,
                flowId      : history.location.state.flowIdArray
            }
            const {response, error} = await TransactionService.redeemDealTransaction({payload})
            
            let deals = await localforage.getItem('deals');
            deals.map((element, idx)=> {
                if(element._id === history.location.state.dealDetail._id && element.uniqueId === history.location.state.dealDetail.uniqueId )
                {
                    deals[idx] = {...element, avail: true}
                }  
            })
            
            localforage.removeItem('deals');
            localforage.removeItem('email');
            history.push({pathname:'/page/'+params.growthtoolId})
            localforage.setItem('deals', deals);
        }


        localforage.removeItem('deals');
        localforage.removeItem('email');
        history.push({pathname:'/page/'+params.growthtoolId})
        setState({...state, modalLoader : false});
    }

    return (
        <div className="w-100 position-relative" style={{height: '100vh'}}>
            <div className="position-relative" >
                <div className="" style={{ position: 'relative', width: '100%', paddingTop: '58.54%', backgroundImage: "url('"+history.location.state.dealDetail.image+"')",backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                <div className="cp borderRadius-50 pt_6 pb_6 pr_6 pl_6" onClick={()=>history.push({pathname: '/page/'+params.growthtoolId+'/listing', state: history.location.state})} style={{position: 'absolute', top: '12px',left: '12px', boxShadow :'0px 4px 4px rgba(0, 0, 0, 0.25)'}}><SvgIcons.CrossIcon color="black"/></div>
            </div>
            <div className="middle Link13M" style={{padding: '8px 24px 8px 24px', color: '#428A52', backgroundColor: '#EBF5ED'}}>Show the staff member at the counter when paying to avail this deal.</div>
            <div style={{fontWeight: '700 !important'}} className="Heading22M mr_16 ml_16 mt_16 color-GeneralBlack">{history.location.state.dealDetail.deal}</div>
            <div className="d-flex mr_16 ml_16 mt_8">
                <div className="Link13R mr_8" style={{color: '#1863C9'}}>{utils.capitalizeAll(history.location.state.brand.brandName)}</div>
                <div className="Link13R" style={{color: 'black'}}>{utils.capitalizeAll(history.location.state.brand.locationType)}</div>
            </div>
            <div className="mt_16 Link13R mr_16 ml_16" style={{color: '#8C8B91', lineHeight: '22px !important'}}>{history.location.state.dealDetail.description}</div>
            <div className="w-100 position-absolute" style={{bottom: '30px'}}>
                <CustomButton disabled={history.location.state.disable} icon = {state.loader && <CircularProgress size={20} color={'inherit'}/>} btntext="Redeem Deal" className={history.location.state.brand.brandRedemptionType === RedemptionType.ORDER ? 'd-none':"ml_20 w-90"} height="48px" onClick={handleDeal}/>
            </div>
            
            <CustomsModal 
                open      = { state.modal }
                minWidth  = {'160px'}
                component = {<RedeemModal icon={state.modalLoader} title="Redeem" onCancel={()=> setState({...state,modal: false})} onDelete={handleRedeem}/>}
                onClose   = {()=> setState({...state,modal: false})}
            />
        </div>
    )
}

export default LandingPageDeal
