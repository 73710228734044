import axios from 'axios'
import { Handlers } from '../utils'
import env from '../config'
const service  = '/v4/stripeCoupon'

const Create = async({payload}) => await axios.post(env.API_URL+service+'/multiple', payload)

const StripeCouponService = {
    Create : Handlers.Services(Create),
}

export default StripeCouponService
