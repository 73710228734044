import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import CopyText from "../../components/CopyText";
import { UserService } from '../../services/index.js'
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { AgencyContext } from "../../context/Agency.context";
import { SvgIcons,PngIcons } from "../../icons";
import { useContext } from "react";
function InfoPage() {
    const [email, setEmail]     = useState('demo@monsterhub.io')
    const [error, seterror]     = useState('')
    const [loader, setLoader]   = useState(false)
    const [backbtn, setbackBtn] = useState(false);
    const [userId, setuserId]   = useState(false);


    const [incomingData, setinComingData] = useState({
        code:  '' ,
        _id:  '',
        key : ''
    }
    )


    const callit = async () => {
        setLoader(true)
        setinComingData({
            code:  '' ,
            _id:  '',
            key : ''
        })
        const query = { email: email }
        const { error, response } = await UserService.GetUserDataByEmail({ query, toaster: false})
        console.log('response ', response)
        if (response?.success) {
            setuserId(response.data?.id)
            seterror('')
            console.log('respond', response);
            setinComingData({...incomingData, code: response?.data?.code , _id : `https://api.walletly.ai/reset/${response.data?.id}` , key : response?.data?.key })
            setLoader(false)
        }
        else if(error) {
            console.log('error', error);
            seterror(error.message)
            setLoader(false)
        }

        setbackBtn(!backbtn);
    }
    
    const agency = useContext(AgencyContext);

    const createIdKeyFunc = async() => {
        setLoader(true)
        console.log('dadsa', incomingData)
       let payload = {
            _id : userId
       }

       const {response, error} =  await UserService.UpdateId({payload})
       if(response){
            setinComingData({...incomingData , key : response.data.idKey})
       }
       console.log('response ', response)
       console.log('error ', error)
       setLoader(false)

    }
    
    return (
        <>
        <div className="info-main w-100 " id="info-main">
        <form >
        <div className="auth-form w-40 shadow-info" style={{margin : '0 auto'}}>
          <div className="d-flex justify-content-center w-100">
            <div className="position-relative"  style={{height: '160px', width : '160px', backgroundSize : 'cover',  backgroundImage: `url(${PngIcons.logoImageBorder})`  } }>
              <img  style={{position : 'absolute'}} className='borderRadius-60 object-fit-cover absoluteMiddle' width={'50px'} height={'50px'} src={(agency.logo || PngIcons.authLogo)} />
            </div>
          </div>
          

          <div className="mt_24 Heading24M color-Heading text-center w-100">
             <span className="capitalize">{agency?.agencyName || "Walletly"}</span>
          </div>

    <div id="LoginBtn" className="w-100">

        {!backbtn &&
          <div className="w-100 mt_24">
          <CustomTextField
                      label='Enter Email'
                       value={email}
                    onChange={(e) => setEmail(e.target.value)}
                            />
          </div>
        }
          
          </div>
  

          {incomingData.code &&
                <>
                <span style={{marginTop:"10px"}}>Verification Code:</span>
                    <div className="w-100  hpx-48" style={{ border: '1px solid #c4c4c4', borderRadius: '4px', position: "relative", }}>
                        {
                            error ? 
                            <div className="Body16R mt_11 ml_9" style={{color : 'red'}}>{error}</div>
                            :
                            <>
                            <div className="Body16R mt_11 ml_9">{ incomingData?.code}</div>
                            <div style={{ position: "absolute", top: "15px", right: "15px" }}>
                                <CopyText id="webhook" content={incomingData?.code} hideText={true} />
                            </div>
                            </>
                        }
                        </div></>
                    }

                    {incomingData._id &&
                <>
                <span style={{marginTop:"10px"}}>Password Reset Url:</span>
                        <div className="w-100 hpx-48" style={{ border: '1px solid #c4c4c4', borderRadius: '4px', position: "relative", }}>
                        {
                            error ? 
                            <div className="Body16R mt_11 ml_9" style={{color : 'red'}}>{error}</div>
                            :
                            <>
                            <div className="Body16R mt_11 ml_9">{ incomingData?._id}</div>
                    
                    <div style={{ position: "absolute", top: "15px", right: "15px" }}>
                        <CopyText id="webho" content={ incomingData?._id} hideText={true} />
                    </div>
                            </>
                        }
                        
                    </div></>
                }

{
                      incomingData.key &&

                      <> <span style={{marginTop:"10px"}}>Business Connecter idKey</span>
                        <div className="w-100 hpx-48" style={{ border: '1px solid #c4c4c4', borderRadius: '4px', position: "relative", }}>
                       
                            <div className="Body16R mt_11 ml_9">{ incomingData?.key}</div>
                    
                    <div style={{ position: "absolute", top: "15px", right: "15px" }}>
                        <CopyText id="webhoo" content={ incomingData?.key} hideText={true} />
                    </div>
                           
                        
                    </div></>
                    }


{!backbtn &&
          <div className="w-100 pt_8 gap-12 mt_10 d-flex flex-column">
                     <CustomButton
                                btntext='Submit'
                                disabled={loader || !email}
                                icon={loader && <CircularProgress size={20} color={"inherit"} />}
                                className='w-100 h-100'
                                onClick={() => callit()} 
                                // disabled={!email}
                            />
             {/* {state.apiError && <div className={'error'}>{state.apiError}</div>} */}
            
          </div>
          
}
{
    backbtn &&
    <div className="w-100 pt_8 gap-12 mt_10 d-flex flex-column">
         {!incomingData.key && <CustomButton
                btntext   = 'Generate Id Key'
                className = 'w-100 h-100'
                varient   = "secondary"
                icon      = {loader && <CircularProgress size={16} color={"inherit"} />}
                onClick   = {()=>createIdKeyFunc()}
        />}
        <CustomButton
                btntext   = 'Back'
                className = 'w-100 h-100'
                onClick   = {() => {
                    setinComingData({...incomingData, code: '' , _id : '' , key : ''});
                    setbackBtn(!backbtn);
                    setEmail('');
               }} 
        />

</div>
}
          
          
        </div>
      </form>
      </div>
    
       
        </>
    )
}

export default InfoPage;