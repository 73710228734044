import React, {useContext, useEffect} from 'react'
import {Route, Switch, Redirect, useHistory} from "react-router-dom";

import SettingHeader from '../../components/setting/SettingHeader';

import AddDeal from './subpages/AddDeal';

import { LayoutContext } from '../../context/Layout.context';
import Deal from './subpages/Deal';
import FlowTest from './subpages/FlowTest';
import Detail from './subpages/Detail';
import DealNew from './subpages/DealNew';

export default function DealMain(props) {
    const layout = useContext(LayoutContext)

    return (
        <div id="AddDeal">
            <div className = "">
               <Switch>
                    <Route exact path = {props.match.url + '/'}            component    = {DealNew}/>
                    {/* <Route exact path = {props.match.url + '/'}            component    = {Deal}/> */}
                    {/* <Route exact path = {props.match.url + '/add'}         component    = {AddDeal}/>
                    <Route exact path = {props.match.url + '/detail'}  component        = {Detail}/>
                    <Route exact path = {props.match.url + '/addflow'}        component = {FlowTest}/> */}
                    {/* <Route path       = {props.match.url + '/addflow'}     component = {FlowTest}/> */}

                    <Redirect path={props.match.url+ '/*'} to={props.match.url+ '/'} />
                    <Redirect path={props.match.url} to={props.match.url+ '/'} />
               </Switch>
            </div>
        </div>
    )
}
