import React, {useEffect, useState, useContext, createRef} from 'react'
import { useHistory } from 'react-router';

import CustomButton from '../../../components/CustomButton';
import CustomModal from '../../../components/CustomModal';
import CustomTextField from '../../../components/CustomTextField';
import DeleteModal from '../../../components/DeleteModal';

import { LayoutContext } from '../../../context/Layout.context';
import { GiveawayVld } from '../../../validation';
import { Options, utils } from '../../../utils';

import {  useFormik } from 'formik';
import localforage, * as localForage from 'localforage'
import { GiveawayService } from '../../../services';
import { CircularProgress } from '@mui/material';
import { BrandContext } from '../../../context/Brand.context';
import { AgencyContext } from '../../../context/Agency.context';
import CustomScroll from '../../../components/CustomScroll';
import CustomSelect from '../../../components/CustomSelect';


export default function AddInventory() {
    const history = useHistory();

    const [giveawayData, setGiveawayData] = useState({
        edit: history.location.state.item ? true                       : false,
        deal: history.location.state.item ? history.location.state.item: '',
    })

    const [state, setstate] = useState({
        apiError    : '',
        loader      : false,
        deleteLoader: false,
        deleteModal : false,
    })

    const [filled,setFilled] = useState({
        generalInformation: false,
        giveawayDeal      : false,
        optInMethod       : false,
    })
    
    const layout  = useContext(LayoutContext)
    const brand   = useContext(BrandContext)
    const agency  = useContext(AgencyContext)


    const setDealImage = (dealId) =>{
        let deals = history.location.state.deal;
        for(let i =0 ; i<deals.length ; i ++ ){
            if(dealId == deals[i]._id) return deals[i].image
        }
    }

    const createDateForInputDate = (date) =>{ 
        // console.log('date is ', date);
        let fullDate;
        fullDate =  date ? new Date(date) : new Date();
        let Dates = fullDate.getDate();
        fullDate = fullDate.getFullYear() + '-' + fullDate.getMonth()+ '-' + (Dates < 10 ? ('0' + Dates) : Dates)
        // console.log('Full date is ', fullDate);
        return fullDate;
    }

    const initState = {
        name           : giveawayData.deal && giveawayData.deal.name ||'',
        startDate      : createDateForInputDate(giveawayData.deal.startDate),   //tomorrow's date
        endDate        : createDateForInputDate(giveawayData.deal.endDate),   //tomorrow's date
        entries        : giveawayData.deal && giveawayData.deal.entries ||'',
        winnerDeal     : giveawayData.deal && giveawayData.deal.winnerDeal || '',
        winnerDealImage: giveawayData.deal && setDealImage(giveawayData.deal.winnerDeal) || '',
        loserDeal      : giveawayData.deal && giveawayData.deal.loserDeal || '',
        loserDealImage : giveawayData.deal && setDealImage(giveawayData.deal.loserDeal) || '',
        optinMethod    : giveawayData.deal && giveawayData.deal.optinMethod || 'Web',
        dealList       : history.location.state.deal,
    }

    const handleSubmit = async() =>{
        setstate({...state, loader: true});
        let payload = {
            name       : formik.values.name,
            startDate  : new Date(formik.values.startDate).valueOf(),
            endDate    : new Date(formik.values.endDate).valueOf(),
            entries    : formik.values.entries,
            winnerDeal : formik.values.winnerDeal,
            loserDeal  : formik.values.loserDeal,
            optInMethod: formik.values.optinMethod,
            agencyId   : agency._id,
            brandId    : brand._id
        }
        
        
        if(!giveawayData.edit){
            const {response , error} = await GiveawayService.Create({toaster: true, payload})
            if(error) setstate({...state, loader: false, apiError: error.error})
            else{
                let giveaway = await localforage.getItem('giveaway');
                if(giveaway == null) giveaway = [];
                giveaway.push(response.data);
                localforage.setItem('giveaway', giveaway);
                history.goBack();
            }
            setstate({...state, loader: false})
        }
        else{
            const {response, error} = await GiveawayService.Update({toaster: true, payload: {...payload, _id: giveawayData.deal._id}})
            if(error) setstate({...state, loader: false, apiError: error.error})
            else{
                let giveaway = await localForage.getItem('giveaway')
                giveaway.map((element, idx)=> {
                    if(element._id === giveawayData.deal._id) giveaway[idx] = response.data
                })
                localForage.setItem('giveaway', giveaway);
                history.goBack();
            }
            setstate({...state, loader: false})
        }
        
        setstate({...state, loader: false})
    }
    
    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : handleSubmit,
        validationSchema : GiveawayVld.Create,
        // isInitialValid   : giveawayData && giveawayData.edit,
        isInitialValid     : false,
        enableReinitialize : true
    })
    
    useEffect(()=>{
        layout.setLayout({
            title      : "Create Giveaway",
            expandedBar: false,
            button     : <div>
            {giveawayData.edit && <CustomButton 
                btntext = {"Delete"}
                varient = "warningSecondary"
                onClick = {()=>setstate({...state, deleteModal : true})}
                
                />}
            <CustomButton 
                btntext   = {"Cancel"}
                varient   = "secondary"
                className = "ml_8"
                onClick   = {()=>history.goBack()}
            />
            <CustomButton 
                btntext   = {giveawayData.edit ? "Save Changes" : "Add Item"}
                disabled  = {state.loader || !formik.isValid}
                onClick   = {handleSubmit}
                icon      = {state.loader && <CircularProgress size={20} color={'inherit'}/>}
                className = "ml_8"
            />
        </div>
        });
    },[formik.isValid, formik.values, state.loader, formik.dirty])

    const handleGiveawayDelete = async() => {
        setstate({...state, deleteLoader: true})
        const {response, error} = await GiveawayService.Update({toaster: true, payload: {delete: true, _id: giveawayData.deal._id}})
        if(response.data){
            let giveaway = await localforage.getItem('giveaway');
            let newGiveaway = giveaway.filter((element)=>{
               return element._id !== response.data._id
            })
            localforage.setItem('giveaway', newGiveaway);
            history.goBack();
        }        
        if(error) setstate({...state, loader: false, apiError: error.error})
        else{ 
            setstate({...state, deleteModal : false})
        }
        setstate({...state, deleteLoader: false})
    }

    useEffect(()=>{
        let checkGeneralInfoError, checkGiveawayDealError, checkOptInMethodError;

        if(formik.errors.name || formik.values.name == "" || formik.values.entries == "" || formik.errors.entries) checkGeneralInfoError = false
        else checkGeneralInfoError = true

        if(formik.errors.winnerDeal || formik.values.winnerDeal == "") checkGiveawayDealError = false
        else checkGiveawayDealError = true

        if(formik.errors.optinMethod) checkOptInMethodError = false;
        else checkOptInMethodError = true

        setFilled({...filled, generalInformation : checkGeneralInfoError, giveawayDeal : checkGiveawayDealError , optInMethod : checkOptInMethodError});
    },[formik.errors, formik.values])


     //refs
     const section1Ref = createRef(null);
     const section2Ref = createRef(null);
     const section3Ref = createRef(null);

    const Components = [
        {title: 'General Information', filled: filled.generalInformation, link : '#section-1', component: <GeneralInformation state={state} formik={formik}/>, ref:{section1Ref}},
        {title: 'Giveaway Deal', filled: filled.giveawayDeal, link : '#section-2', component:<GiveawayDeal setDealImage={setDealImage} formik={formik}/> ,ref:{section2Ref}},
        {title: 'Opt-In Method', filled: filled.optInMethod, link : '#section-3', component:<OptInMethod formik={formik}/> ,ref:{section3Ref}},
    ]

    return (
        <div id="AddGiveaway">
            <div className="">
                <CustomScroll components={Components}/>
            </div>

            
            <CustomModal 
                open      = { state.deleteModal }
                minWidth  = {'600px'}
                component = {<DeleteModal icon={state.deleteLoader} title="sub brand" onCancel={()=> setstate({...state, deleteModal : false})} onDelete={handleGiveawayDelete}/>}
                onClose   = {()=> setstate({...state, deleteModal : false})}
            />

        </div>
        
    )
}

const GeneralInformation = ({formik}) => {

    const handleEntriesChange = (e) => {
        if(e.target.value >= 0) {
            formik.setValues({...formik.values, entries : e.target.value})
        }
    }

    return(
        <div>
            <form onSubmit={formik.handleSubmit}>
                {/* <SaveCloseButtons formik={formik} state={state} name={'earningName'} /> */}
                <div className="Heading22M color-neutral100">
                    General Information
                </div>
                <div className="Body14R color-neutral60 mt_4">
                    Set activity name, and activity type.
                </div>
                <div className="mt_24 ">
                    <CustomTextField
                        label        = "Giveaway Name"
                        className    = "w-55"
                        onBlur       = {(e)=>formik.setValues({...formik.values, name: e.target.value})}
                        error        = {formik.touched.name && formik.errors.name}
                        helperText   = {formik.errors.name && formik.errors.name}
                        defaultValue = {formik.values.name}
                    />
                </div>

                <div className="mt_24 d-flex space-between w-55">
                    <div className="w-48">
                        <CustomTextField
                            label      = "Start date"
                            className  = "w-100"
                            type       = "date"
                            onChange   = {(e)=>formik.setValues({...formik.values, startDate: e.target.value})}
                            value      = {formik.values.startDate}
                            error      = {formik.touched.startDate && formik.errors.startDate}
                            helperText = {formik.errors.startDate && formik.errors.startDate}
                        />

                    </div>
                    <div className="w-48 ml_4">
                        <CustomTextField
                            label      = "End date"
                            className  = "w-100"
                            type       = "date"
                            onChange   = {(e)=>formik.setValues({...formik.values, endDate: e.target.value})}
                            value      = {formik.values.endDate}
                            error      = {formik.touched.endDate && formik.errors.endDate}
                            helperText = {formik.errors.endDate && formik.errors.endDate}
                        />
                    </div>
                </div>

                <div className="mt_24 w-55">
                    <CustomTextField
                        label      = "Number of Entries"
                        className  = "w-48"
                        type       = "number"
                        name       = "entries"
                        onChange   = {handleEntriesChange}
                        icon       = {<div>Entries</div>}
                        value      = {formik.values.entries}
                        error      = {formik.touched.entries && formik.errors.entries}
                        helperText = {formik.errors.entries && formik.errors.entries}
                        position   = {'end'}
                    />
                </div>
    
                </form>
        </div>
    )
}

const GiveawayDeal = ({formik, setDealImage}) => {

    const CreateDeal = () => {
        return(
            <>  
                {formik.values.dealList && formik.values.dealList.map((value, idx)=>(
                    <option value={value._id}>{value.name}</option>
                ))}
            </> 
        )
    }

    useEffect(()=>{
        formik.setValues({...formik.values , winnerDealImage : setDealImage(formik.values.winnerDeal), loserDealImage : setDealImage(formik.values.loserDeal)})
    },[formik.values.winnerDeal, formik.values.loserDeal])


    return(
        <form>
        {/* <SaveCloseButtons formik={formik} state={state} name={'earningName'} /> */}
            <div className="Heading22M color-neutral100">
                Giveaway Deal
            </div>
            <div className="Body14R color-neutral60 mt_4">
                Set activity name, and activity type.
            </div>

            <div className="mt_24 d-flex space-between w-55">
                <div className="w-48">
                    <div className="customLabel">Winner Deal</div>
                    <CustomSelect
                        options     = {CreateDeal()}
                        name        = "winnerDeal"
                        placeholder = "Winner Deal Placeholder"
                        onChange    = {(e)=>formik.setValues({...formik.values, winnerDeal: e.target.value})}
                        value       = {formik.values.winnerDeal}
                        error       = {formik.touched.winnerDeal && formik.errors.winnerDeal}
                        helperText  = {formik.errors.winnerDeal && formik.errors.winnerDeal}
                    />

                    {formik.values.winnerDealImage && <div className="dealImage" style={{backgroundImage: `url('${setDealImage(formik.values.winnerDeal)}')`, backgroundSize : 'cover'}}>
                    </div>}
                </div>
                <div className="w-48 ml_4"> 
                    <div className="customLabel">Loser Deal</div>
                    <CustomSelect
                         options     = {CreateDeal()}
                         name        = "loserDeal"
                         placeholder = ""
                         onChange    = {(e)=>formik.setValues({...formik.values, loserDeal: e.target.value})}
                         value       = {formik.values.loserDeal}
                         error       = {formik.touched.loserDeal && formik.errors.loserDeal}
                         helperText  = {formik.errors.loserDeal && formik.errors.loserDeal}

                    />
                    {formik.values.loserDealImage &&<div className="dealImage" style={{backgroundImage: `url('${setDealImage(formik.values.loserDeal)}')`, backgroundSize : 'cover'}}>
                    </div>}
                </div>
            </div>

        </form>
    )
}

const OptInMethod = ({formik}) => {
    return(
        <form onSubmit={formik.handleSubmit}>
            <div className="Heading22M color-neutral100">
                Opt-In Method
            </div>
            <div className="Body14R color-neutral60 mt_4">
                Set activity name, and activity type.
            </div>

            <div className="w-55 ml_4 mt_24"> 
                <div className="customLabel">Opt in method</div>
                <CustomSelect
                    options    = {Options.SubmitActions()}
                    name       = {'optInMethod'}
                    className  = "disabled"
                    value      = {formik.values.optinMethod}
                    onBlur     = {(e)=>formik.setValues({...formik.values, optinMethod: e.target.value})}
                    error      = {formik.touched.optinMethod && formik.errors.optinMethod}
                    helperText = {formik.errors.optinMethod && formik.errors.optinMethod}
                />
            </div>
        </form>
    )
}