import React, { useContext } from 'react';
import {Redirect, Route} from 'react-router-dom';

import { BrandContext } from '../context/Brand.context';
import { UserContext } from '../context/User.context';

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
function AuthGuard({component: Component, ...rest}){
    const user = useContext(UserContext)
    
    return (
        <Route
            {...rest}
            render={(props) => {
                if(props.history.location.pathname === '/points') return <Redirect to='/points'/>
                if(!user.email) return <Component {...props} />
                if(user.email && !user.verified) return <Redirect to={Object.keys(params).length === 0?'/verify':'/verify?cw'} />
                if(user.email && !user.activeBrand) return <Redirect to={Object.keys(params).length === 0?'/brand':'/brand?cw'} />
                else return  <Redirect to={'/'+user.activeBrand} />
            }}
        />
    )
}

function VerifyGuard({component: Component, ...rest}){
    const user = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                if(user.email && !user.verified) return <Component {...props} />
                // if(user.email && !user.walletlyReferences) return <Redirect to='/onboard' />
                if(!user.email) return  <Redirect to='/' />
                else return  <Redirect to={'/'+user.activeBrand} />
            }}
        />
    )
}

function UserOnBoardGuard({component: Component, ...rest}){
    const user = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                if(user.email && !user.verified) return <Redirect to={Object.keys(params).length === 0?'/verify':'/verify?cw'} />
                if(user.email && !user.walletlyReferences) return <Component {...props} />
                if(!user) return  <Redirect to='/' />
                else return  <Redirect to={'/'+user.activeBrand} />
            }}
        />
    )
}

function CreateBrandGuard({component: Component, role, ...rest}){
    const user       = useContext(UserContext)
    const activePerm = getActivePermission()

    function getActivePermission(){
        if(user.activePermission) return user.activePermission
        return {roles: user.roles}
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if(!user.email) return <Redirect to='/' />
                if(user.email && !user.verified) return <Redirect to={Object.keys(params).length === 0?'/verify':'/verify?cw'} />
                // if(user.email && !user.walletlyReferences) return <Redirect to='/onboard' />
                if(role && !activePerm.roles.some(currentRole=>role.includes(currentRole))) return <Redirect to='/' />
                if(user.email) return <Component {...props} />
                else return  <Redirect to='/' />
            }}
        />
    )
}

function CentralGuard({component: Component, ...rest}){
    const user = useContext(UserContext)

    return (
        <Route
            {...rest}
            render={(props) => {
                if(user.email && !user.verified) return <Redirect to={Object.keys(params).length === 0?'/verify':'/verify?cw'} />
                // if(user.email && !user.walletlyReferences) return <Redirect to='/onboard' />
                if(user.email && !user.activeBrand) return <Redirect to={Object.keys(params).length === 0?'/brandType':'/brandType?cw'} />
                // if(user.email && !user.activeBrand) return <Redirect to={Object.keys(params).length === 0?'/brand':'/brand?cw'} />
                if(user.email) return <Component {...props} {...rest} />
                else return  <Redirect to='/' />
            }}
        />
    )
}

function  RoleGuard({component: Component, analytics, path, role, ...rest}){
    const activePermission = useContext(UserContext).activePermission
    // console.log('role',role);
    // console.log('activePermission',activePermission);
    return (
        <Route
            path={path}
            render={(props) => {
                if(role && !activePermission.roles.some(currentRole=>role.includes(currentRole))) return <Redirect to='/' />
                return <Component {...props} analytics = {analytics} {...rest} />
            }}
        />
    )
}


export {
    AuthGuard,
    VerifyGuard,
    UserOnBoardGuard,
    CreateBrandGuard,
    CentralGuard,
    RoleGuard
}