import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom';

import CustomButton from '../../../components/CustomButton';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import CustomCheckBox from '../../../components/CustomCheckBox';
import CustomTextField from '../../../components/CustomTextField';
import CustomRadio from '../../../components/CustomRadio';
import PageTitle from '../../../components/setting/SettingPageTitles';

import RadioGroup from '@mui/material/RadioGroup';
import { LayoutContext } from '../../../context/Layout.context';

export default function Notification(props) {

    const Adminactions = 
        {   
            title      : 'Notify Admin Action',
            action     : ['Messenger', 'SMS', 'Email', 'Mobile push notification'],
            detail     : 'Enable to let ManyChat send you notifications when a contact performs a specific action in your broadcasts, welcome messages, opt-in messages, etc. \n \nDownload ManyChat application to receive push notifications and stay informed.',

        }
    

    const Livechat = 
        {   
            title      : 'Live Chat',
            action     : ['Messenger', 'SMS', 'Email', 'Mobile push notification'],
            detail     : 'A range of Live Chat notifications to help you support your audience and track leads',

        }
    

    const Messengernotification = 
        {   
            title       : 'Messenger Notification',
            detail      : 'A range of Live Chat notifications to help you support your audience and track leads',
            btnDetails  : {btntext: 'Subscribe'},
            currentUser : 'Naveed Ali '
        }
    

    const Emailnotification = 
        {   
            title      : 'My Email for Notifications',
            inputField : {label : 'Email' , placeholder : 'Your Email'},
            actionBtnDetails : {btntext: 'Update'}

        }
    

    const Phonenotification = 
        {   
            title            : 'Phone Number for Notification',
            inputField       : {label : 'Phone Number' , placeholder : 'Enter Phone'},
            action           : ['I confirm that the phone number is owned by me and I give permission to send me SMS notifications.'],
            detail           : 'Add your phone number to receive SMS notifications. Please note that you will be charged for every SMS notification - the price for one Text Message is $0.01. \nLearn More',
            actionBtnDetails : {btntext: 'Save'}

        }
    
    
    const Contactreport = 
        {   
            title        : 'Contact Reports',
            planDuration : ['Daily', 'Weekly', 'Monthly', 'Yearly'],
            detail       : 'Regular updates on how your contact base is doing',
            
        }

    const layout = useContext(LayoutContext)
    useEffect(()=>{
        layout.setLayout({
            title       : "Notification",
            button      : "",
            borderBottom: false,
            switch      : "",

        });
    },[])
    

    return (
        <div id="Notification">
            {/* <PageTitsle title={"Notification"}/> */}
            <div className="col-12 mt_24">
                <div className="card h-100">
                        <Singleaction checkbox={Adminactions} titleButton={true}/>
                            <div className="bar"></div>
                        <Singleaction checkbox={Livechat}/>
                            <div className="bar"></div>
                        <Singleaction checkbox={Messengernotification}/>
                            <div className="bar"></div>
                </div>

                <div className="card h-100 mt_48">
                            <Singleaction checkbox={Emailnotification}/>
                            <div className="bar"></div>
                            <Singleaction checkbox={Phonenotification} changeColor={true}/> 
                            <div className="bar"></div>
                            <Singleaction checkbox={Contactreport}/> 
                            <div className="bar"></div>
                </div>

            </div>
            
        </div>
    )
}

const Singleaction = (props) => {
    return (
        <div className="singleAction mt_15 mb_15">
            <div className="row pl_0">

                <div className="col-12 col-md-3 pr_0 leftBox">
                    <div className="body1"> 
                           {props.checkbox.title}
                    </div>
                    {props.titleButton && 
                        <div className="mt_8">
                            <CustomButton
                                btntext  = {"PRO"}
                                fontSize = {'12px'}
                                // height   = {'32px'}
                            />
                        </div>
                    }
                </div>

                <div className="col-md-4 col-12 pl_50 centerBox">
                {props.checkbox.inputField && 
                        <CustomTextField
                            placeholder = {props.checkbox.inputField.placeholder}
                            label       = {props.checkbox.inputField.placeholder}
                            className   = {"w-90 mb_10"}
                        />
                    }

                    {props.checkbox.action && props.checkbox.action.map((value,index)=>
                        <div>
                            <CustomCheckBox label={value} fontSize={14} color={props.changeColor ? ColorSchemeCode.tableActionDropdownColor : ColorSchemeCode.c000000} backgroundColor={ColorSchemeCode.c000000}/>
                        </div>
                    )}
                    {
                        props.checkbox.btnDetails &&
                        <div>
                            <CustomButton
                                btntext      = {props.checkbox.btnDetails.btntext}
                            />
                            <div className="Body14R mt_16 fs-14">{props.checkbox.currentUser} <Link>Not you?</Link></div>
                        </div>
                    }

                    {
                        props.checkbox.planDuration &&
                        
                        <RadioGroup 
                                name     = "planDuration"
                            >
                                {props.checkbox.planDuration.map ((value)=>
                                    <CustomRadio 
                                        value = {value}
                                        label = {value}
                                        borderRadius={"10px"}
                                />
                                )}

                            </RadioGroup>
                    }
                    
                </div>

                <div className="col-md-5 col-12 pl_50 rightBox">
                    {
                        props.checkbox.actionBtnDetails &&
                        <CustomButton
                            btntext      = {props.checkbox.actionBtnDetails.btntext}
                            fontSize     = {props.checkbox.actionBtnDetails.fontSize}
                            borderRadius = {props.checkbox.actionBtnDetails.borderRadius}
                            // height       = {props.checkbox.actionBtnDetails.height}
                            className    = {"mb_20"}
                        />
                    }

                    {
                    props.checkbox.detail && 
                        <div className="Body14R color-tableActionDropdownColor detail">
                            {props.checkbox.detail}
                        </div>
                    }
                </div>  


            </div>

        </div>
    )
}
