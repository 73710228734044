import React from 'react'

import CustomSwitch from '../../../components/CustomSwitch'
import CustomTextField from '../../../components/CustomTextField'
import Header from '../../../components/Header'
import Cards from '../../../components/Cards'
import { ColorScheme } from '../../../enums'
import PngIcons from '../../../icons/png.icon'
import SvgIcons from '../../../icons/svg.icon'


export default function ReferralCampaign(props) {


    const cards = [
        {
            name    : 'Cafe Loyalty',
            subHead : 'Best suite for mid size businesses with multiple outlets',
            img     : <PngIcons.SampleImg/>,
            bgColor : '#FAFAFA',
        },
        {
            name    : 'Dental Loyalty',
            subHead : 'Best suite for mid size businesses with multiple outlets',
            img     : <PngIcons.SampleImg2/>,
            bgColor : '#FAFAFA',
        },
        {
            name    : 'Restaurant Loyalty',
            subHead : 'Best suite for mid size businesses with multiple outlets',
            img     : <PngIcons.SampleImg3/>,
            bgColor : '#FAFAFA',
        },
    ]


    return (
        <div id='ReferralCampaign'> 
            <Header 
                className   = {'bg-color-automationHeaderColor'}
                title       = {"Referrals"}
                textColor   = {ColorScheme.ColorSchemeCode.c3D0A65}
                bgColor     = {'bg-color-referralCampaignHeaderColor'}
                btnTitle    = {'Create Referrals'}
                description = {'Automate your business through our flow builder.'}
                path        = ""
            />

            <CustomTextField 
                className   = "searchField ml_35 mt_15"
                placeholder = 'Search'
                icon        = {<SvgIcons.SearchIcon height="24px" width="24px"/>}
                position    = 'start'
            />

            <div className="Heading22R mt_10 ml_35 fs-14">Referral List</div>

            <Cards 
                cards        = {cards}
                textColor    = {'color-c1D3B56'}
                subtextColor = {'color-c486381'}
                switch       = {<CustomSwitch/>}
            />
            
        </div>
    )
}
