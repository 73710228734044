import React, { useContext, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";

import * as localForage from "localforage";

import { AuthVld } from "../../validation";
import { AuthService, UserService } from "../../services";
import { PermissionService } from "../../services";

import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import { SvgIcons } from "../../icons";

import { BusinessType } from "../../enums/enums";
import { UserContext } from "../../context/User.context";
import { Brand, User } from "../../enums";

function ResetPassword({ setTitle }) {
  const history = useHistory();
  // const userContext = useContext(UserContext)

  const [state, setState] = React.useState({
    apiError: "",
    loader: false,
  });

  const [type, settype] = React.useState("password");

  const initState = {
    password: "",
  };

  const formik = useFormik({
    // validateOnChange : false,
    // validateOnBlur   : false,
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: AuthVld.ResetPassword,
    isInitialValid: false,
  });

  async function handleSubmit() {
    setState({ ...state, loader: true });
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    const payload = {
      _id: id,
      password: formik.values.password,
    };
    const { response, error } = await AuthService.UpdatePassword({
      toaster: true,
      payload,
    });
    setState({ ...state, loader: false });
    if (response.success) history.push("/");
  }

  useEffect(() => {
    setTitle("New Password");
  }, []);

  const eyeClick = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      settype("password");
    } else {
      x.type = "password";
      settype("text");
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="input">
          <div className="mt_40 text-left col-12">
            <CustomTextField
              label="Password"
              className="inputTextField"
              type="password"
              placeholder="Password"
              name="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              icon={
                <div className={"cp"} onClick={eyeClick}>
                  {type !== "password" ? (
                    <SvgIcons.EyeIconPassword />
                  ) : (
                    <SvgIcons.CrossEyeIcon />
                  )}
                </div>
              }
              position="end"
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.password && formik.errors.password}
              helperText={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""
              }
            />
          </div>

          <div className="col-12">
            <CustomButton
              varient="primary"
              className="mt_24 w-100 hpx-50"
              btntext="Submit"
              type="submit"
              disabled={state.loader || !formik.isValid}
              icon={
                state.loader && <CircularProgress size={20} color={"inherit"} />
              }
            />
          </div>
          {state.apiError && <div className={"error"}>{state.apiError}</div>}
        </div>
      </form>
    </>
  );
}

export default ResetPassword;
