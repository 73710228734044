import React, { useState, useContext } from "react";

import { CircularProgress, SvgIcon } from '@mui/material';

import * as localForage from 'localforage'

import { useFormik } from "formik";

import SvgIcons from '../../icons/svg.icon';
import PngIcons from '../../icons/png.icon';

import { ColorSchemeCode } from '../../enums/ColorScheme';
import { Campaign } from '../../enums';
import { ClickOutside, utils } from "../../utils";
import { PassDesignVld } from "../../validation";

import { PassDesignService, PassService, TransactionService } from "../../services";


import ApplePass from "./ApplePass";
import ApplePassFieldDetails from "./ApplePassFieldDetails";
import DraggableComponent from "../../components/DraggableComponent";

import { useHistory } from 'react-router-dom';
import { BrandContext } from "../../context/Brand.context";
import { CampaignContext } from '../../context/Campaign.context';
import { AgencyContext } from "../../context/Agency.context";
import env from "../../config";

export default function Designer({ design }) {

    const [state, setState] = useState({
        loader: true,
        processing: false,
    })

    const [passFieldFocused, setPassFieldFocused] = useState({})
    const [preview, setPreview] = useState(false);
    const [passView, setPassView] = useState('front');
    const campaign = useContext(CampaignContext)

    const setFocus = (focusedElement) => setPassFieldFocused(focusedElement)

    const apple = {
        model: design.apple.model,
        logoText: design.apple.logoText,
        headerFields: design.apple.headerFields,
        primaryFields: design.apple.primaryFields,
        secondaryFields: design.apple.secondaryFields,
        auxiliaryFields: design.apple.auxiliaryFields,
        backFields: design.apple.backFields,
        locations: design.apple.locations,
        beacons: design.apple.beacons,
        barcodes: design.apple.barcodes,
    }

    const initState = {
        designName: design.designName,
        backgroundColor: design.backgroundColor,
        fontColor: design.fontColor,
        qrcode: design.qrcode,
        apple: apple,
        numberOfStamps: design.numberOfStamps,
        logo: design.logo,
        logoFile: '',
        filledImage: design.filledImage,
        filledImageFile: '',
        unFilledImage: design.unFilledImage,
        unFilledImageFile: '',
        cover: design.cover,
        baseUrl: env.STAMP_COVER_URL + '/' + design._id + '/2',
        coverFile: '',
    }
    console.log('design', design);

    const history = useHistory()
    const brand = useContext(BrandContext)

    const handleSubmit = async () => {

        setState({ processing: true, apiError: '' })

        let logo, cover, filledImage, unFilledImage
        const user = await localForage.getItem('user')
        const desiredPath = `${user._id}/${brand.brandName}/pd/${user?.firstName}`

        const payload = { _id: design._id, ...formik.values }
        if (campaign.campaignType === Campaign.CampaignTypes.STAMP_CARD) payload.numberOfStamps = parseInt(formik.values.numberOfStamps)
        else delete payload.numberOfStamps



        delete payload.logoFile
        delete payload.coverFile
        delete payload.filledImageFile
        delete payload.unFilledImageFile

        // uploading logo
        if (formik.values.logoFile)
            logo = await utils.uploadImage({ file: formik.values.logoFile, desiredPath })
        if (logo && logo.error) return setState({ processing: false, apiError: logo.error.message })

        //filledImage
        if (formik.values.filledImageFile)
            filledImage = await utils.uploadImage({ file: formik.values.filledImageFile, desiredPath })
        if (filledImage && filledImage.error) return setState({ processing: false, apiError: filledImage.error.message })

        //unfilledImage
        if (formik.values.unFilledImageFile)
            unFilledImage = await utils.uploadImage({ file: formik.values.unFilledImageFile, desiredPath })
        if (unFilledImage && unFilledImage.error) return setState({ processing: false, apiError: unFilledImage.error.message })


        // uploading cover
        if (formik.values.coverFile)
            cover = await utils.uploadImage({ file: formik.values.coverFile, desiredPath })
        if (cover && cover.error) return setState({ processing: false, apiError: cover.error.message })


        if (logo && logo.response) payload.logo = logo.response.data
        if (filledImage && filledImage.response) payload.filledImage = filledImage.response.data
        if (unFilledImage && unFilledImage.response) payload.unFilledImage = unFilledImage.response.data
        if (cover && cover.response) payload.cover = cover.response.data

        await PassDesignService.UpdateDesign({ toaster: true, payload })
        setState({ processing: false });

        const passes = await PassService.GetQueriedPass({ query: { brandId: brand._id, delete: false } });
        if (passes.response) {
            let deviceToken = [];
            for (const element of passes.response.data) {
                if (element.passPushToken && element.passDesignId === design._id) {
                    deviceToken.push(element.passPushToken)
                }
            }

            if (deviceToken.length > 0) {
                const notifications = await TransactionService.sendAppleNotification({ payload: { deviceToken: deviceToken } })
            }
        }


        history.goBack()
        // const location = window.location.pathname.split('/')
        // location.pop()
        // history.push(location.join('/'))
    }

    const formik = useFormik({
        // validateOnChange : false,
        // validateOnBlur   : false,
        initialValues: { ...initState },
        onSubmit: handleSubmit,
        validationSchema: PassDesignVld.updateDesign,
        // isInitialValid     : false,
        enableReinitialize: true
    })
    // console.log('FORMIS',formik.values.apple.headerFields[0].textAlignment);


    const borderTopPatternColor = {
        background: `linear-gradient( 45deg, transparent 33.333%, #222 33.333%, ${formik.values.backgroundColor}  66.667%,transparent 66.667%),   linear-gradient(-45deg, transparent 33.333%,#222 33.333%, ${formik.values.backgroundColor}  66.667%, transparent 66.667%)`,
        content: "",
        display: 'block',
        position: 'absolute',
        top: '-10px',
        width: '100%',
        height: '10px',
        backgroundSize: '13.4px 41px',
        transitionDelay: '2s'
    }

    const borderBottomPatternColor = {
        background: `linear-gradient( 45deg, transparent 33.333%, #222 33.333%, ${formik.values.backgroundColor}  66.667%,transparent 66.667%),   linear-gradient(-45deg, transparent 33.333%,#222 33.333%, ${formik.values.backgroundColor}  66.667%, transparent 66.667%)`,
        content: "",
        display: 'block',
        position: 'absolute',
        bottom: '-10px',
        width: '100%',
        height: '10px',
        transform: 'rotate(180deg)',
        backgroundSize: '13.4px 41px',
    }
    console.log('fom', formik.isValid);

    return (
        <div id="Editpass">
            <form onSubmit={formik.handleSubmit}>
                <TopHeader formik={formik} state={state}
                    preview={preview}
                    setPreview={setPreview}
                    passView={passView}
                    setPassView={setPassView}
                    focused={{ items: { ...passFieldFocused }, setFocus }}
                />
                {/* <SubHeader 
                    preview     = {preview}
                    setPreview  = {setPreview}
                    passView    = {passView}
                    setPassView = {setPassView}
                    focused     = {{items: {...passFieldFocused}, setFocus}}
                /> */}

                <div className="d-flex cardComponent">
                    <LeftPane
                        preview={preview}
                        passView={passView}
                        formik={formik}
                        focused={{ items: { ...passFieldFocused }, setFocus }}
                    />

                    <Pass
                        preview={preview}
                        passView={passView}
                        formik={formik}
                        focused={{ items: { ...passFieldFocused }, setFocus }}
                    />

                    <RightPane
                        preview={preview}
                        formik={formik}
                        passFieldFocused={passFieldFocused}
                    />
                </div>
            </form>
        </div>
    )
}

const TopHeader = ({ formik, state, preview, setPreview, passView, setPassView, focused }) => {

    const [editDesignName, setEditDesignName] = useState(false)

    const setCarretPosition = (e) => {
        e.target.focus()
        if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(e.target);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(e.target);
            textRange.collapse(false);
            textRange.select();
        }
    }

    const handleDesignName = (e) => {
        setCarretPosition(e)
        formik.setValues({ ...formik.values, designName: e.target.innerText })
    }

    return (
        <div id="TopHeader" className="d-flex col-12 postion-relative align-items-center">
            <div className="d-flex pl_0 frontBackBtns cp">
                <div onClick={() => { setPassView('front'); focused.setFocus({}) }} className={'middlebtn text-center pt_10 fw-5' + (passView === 'front' ? ' bg-color-primary0 B14M color-neutral80' : ' B14M color-neutral60')}>
                    Front
                </div>
                <div onClick={() => { setPassView('back'); focused.setFocus({}) }} className={` middlebtn text-center fw-5 pt_10` + (passView === 'back' ? ' bg-color-primary0 B14M color-neutral80' : ' B14M color-neutral60')}>
                    Back
                </div>
            </div>
            <div className="passTitle w-100">

                <div>
                    <ClickOutside onClick={() => setEditDesignName(false)}>
                        <div className="d-flex B16M color-Heading justify-content-center">
                            {!editDesignName ? <div>{formik.values.designName}</div> :
                                <div
                                    className="designName headline3 border-none fs-16"
                                    suppressContentEditableWarning={true}
                                    contentEditable={true}
                                    onInput={handleDesignName}
                                    onFocus={() => formik.setTouched({ ...formik.touched, designName: true })}
                                >{formik.values.designName}</div>
                            }

                            <div className="ml_4" onClick={() => setEditDesignName(true)}>
                                <SvgIcons.EditIcon
                                    height={'16px'}
                                    width={'16px'}
                                    color={ColorSchemeCode.tableActionDropdownColor}
                                />
                            </div>
                        </div>
                    </ClickOutside>
                    {formik.touched.designName && formik.errors.designName && <div className="error ml_0">{formik.errors.designName}</div>}
                </div>



            </div>
            {
                console.log('formik.isValid', !formik.isValid)
            }
            {console.log('formik.isValid', formik.errors)}

            <div className=' outline-none saveButtons color-textfieldColor d-flex B14M'>
                <button
                    type="submit"
                    className={`saveChanges w-max-content B14M color-textfieldColor border-none outline-none bg-transparent ${state.processing || !formik.isValid && 'disabled'}`}
                    disabled={state.processing || !formik.isValid}
                >
                    {state.processing ? <CircularProgress size={20} color={'inherit'} /> :
                        <>
                            <span className="mr_5"><SvgIcons.IconTick height="13px" width="13px" color={ColorSchemeCode.textfieldColor} /></span>
                            <span>Save & Close</span>
                        </>
                    }
                </button>
            </div>
        </div>
    )
}

const SubHeader = ({ preview, setPreview, passView, setPassView, focused }) => {
    const agency = useContext(AgencyContext)
    return (
        <div className="d-none singleRow">

            {/* Left pane Field list */}
            <div className={`col-2 pl_0 pr_0 d-flex + ${preview && 'bg-color-white border-none'}`}>
                {!preview &&
                    <>
                        <div className={"col-12 caption2 text-center leftsideBtn pt_10 bottomBorder cp fw-5 bg-color-themeColor color-white"}>
                            Navigator
                        </div>
                    </>
                }
            </div>

            {/* Mid pane design selection */}
            <div className="col-7 pl_0 pr_0 d-flex position-relative border-none" >
                <div onClick={() => setPreview(!preview)} className={"viewIcon pt_5 text-center postion-absolute " + (preview && 'bg-color-cF8FAFB leftBorder bottomBorderNone')}>
                    <SvgIcons.EyeIcon color={preview ? agency.agencyColor : ColorSchemeCode.tableActionDropdownColor} />
                </div>
                <div className="d-flex passesIcons space-between">
                    <img className="bg-color-cF2F2F2 cp" src={PngIcons.ApplePassIcon} height="40px" width="40px" alt="" />
                    <img src={PngIcons.GooglePassIcon} className={'grayed-image disabled'} height="40px" width="40px" alt="" />
                    <img src={PngIcons.BeastyPassIcon} className={'grayed-image disabled'} height="40px" width="40px" alt="" />
                </div>

                <div className="d-flex pl_0 position-absolute frontBackBtns cp">
                    <div onClick={() => { setPassView('front'); focused.setFocus({}) }} className={'middlebtn caption2 text-center pt_10 fw-5' + (passView === 'front' ? ' bg-color-cF2F2F2 color-textfieldColor' : ' color-tableActionDropdownColor')}>
                        Front
                    </div>
                    <div onClick={() => { setPassView('back'); focused.setFocus({}) }} className={` middlebtn caption2 text-center fw-5 pt_10` + (passView === 'back' ? ' bg-color-cF2F2F2 color-textfieldColor bottomBorderNone' : ' color-tableActionDropdownColor')}>
                        Back
                    </div>
                </div>
            </div>

            {/* Right pane */}
            <div className={"col-3 pl_0 pr_0 " + (preview ? 'bg-color-white border-none' : 'bottomBorder')}>
                {!preview &&
                    <div className="stylePanel headline4 d-flex">
                        {passView === 'front' &&
                            <div>
                                <span className="mr_5"><SvgIcons.BrushIcon /></span>
                                <span>Style</span>
                            </div>
                        }
                        {passView === 'back' &&
                            <div>
                                <span className="mr_5"><SvgIcons.NavSettingIcon height="16px" width="17px" color={ColorSchemeCode.neutral80} /></span>
                                <span>Properties</span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const FrontFields = ({ formik, focused }) => {

    // console.log('formik', formik);

    const [show, setShow] = useState({
        showSecondary: false,
        showLocations: false,
        showAuxiliary: false,
        deleteBtn: false
    })

    const removeAuxiliaryFields = (e) => {
        e.stopPropagation()
        const apple = { ...formik.values.apple, auxiliaryFields: [] }
        focused.setFocus({})
        formik.setValues({ ...formik.values, apple })
    }

    return (
        <div id='FrontField'>
            <div className="cp p_8 pl_16 fs-14 fw-5 frontFields" onClick={() => focused.setFocus({ logo: true })}>Logo</div>
            <div className="cp p_8 pl_16 fs-14 fw-5 frontFields" onClick={() => focused.setFocus({ logoText: true })}>LogoText</div>
            <div className="cp p_8 pl_16 fs-14 fw-5 frontFields" onClick={() => focused.setFocus({ headerFields: true, index: 0 })}>headerFields</div>
            <div className="cp p_8 pl_16 fs-14 fw-5 frontFields" onClick={() => focused.setFocus({ cover: true })}>cover</div>
            <div className="cp p_8 pl_16 fs-14 fw-5 frontFields" onClick={() => focused.setFocus({ qrcode: true })}>qrcode</div>
            <div className='cp p_8 pl_16 fs-14 fw-5 frontFields' onClick={() => setShow({ ...show, showSecondary: !show.showSecondary })}> Secondary Fields <span className="ml_5">{show.showSecondary ? <SvgIcons.DropDownTriangleIcon color={ColorSchemeCode.c828282} /> : <SvgIcons.DropDownTriangleHorizontalIcon color={ColorSchemeCode.c828282} />}</span> </div>
            {(show.showSecondary) ?
                formik.values.apple.secondaryFields && formik.values.apple.secondaryFields.map((secondaryField, idx) => (
                    <div className="cp ml_16 p_6 frontFields fw-4 fs-12" onClick={() => focused.setFocus({ secondaryFields: true, index: idx })}>
                        {secondaryField.label}
                    </div>
                ))
                : ''}

            {formik.values.apple.auxiliaryFields.length > 0 && <><div onMouseEnter={() => setShow({ ...show, deleteBtn: true })} onMouseLeave={() => setShow({ ...show, deleteBtn: false })} className='cp p_8 pl_16 fs-14 fw-5 frontFields d-flex' onClick={() => setShow({ ...show, showAuxiliary: !show.showAuxiliary })}>
                Auxiliary Fields
                <span className="ml_5">
                    {show.showAuxiliary ? <SvgIcons.DropDownTriangleIcon color={ColorSchemeCode.c828282} /> : <SvgIcons.DropDownTriangleHorizontalIcon color={ColorSchemeCode.c828282} />}
                </span>
                {show.deleteBtn && <div onClick={e => removeAuxiliaryFields(e)} className="ml_auto">
                    <SvgIcons.DeleteIcon color={ColorSchemeCode.c828282} />
                </div>}
            </div>
                {show.showAuxiliary &&
                    formik.values.apple.auxiliaryFields && formik.values.apple.auxiliaryFields.map((auxiliaryField, idx) => (
                        <div className="cp ml_16 p_6 frontFields fw-4 fs-12" onClick={() => focused.setFocus({ auxiliaryFields: true, index: idx })}>
                            {auxiliaryField.label}
                        </div>
                    ))
                }
            </>}


        </div>
    )
}

const BackFields = ({ formik, focused }) => {

    const deleteBackField = (e, idx) => {
        e.stopPropagation();
        focused.items.backFields && focused.setFocus({})
        formik.values.apple.backFields.splice(idx, 1)
        formik.setValues({ ...formik.values })
    }

    const deleteBackFieldLocation = (e, idx) => {
        e.stopPropagation();
        focused.items.locations && focused.setFocus({})
        formik.values.apple.locations.splice(idx, 1)
        formik.setValues({ ...formik.values })
    }

    const deleteBackFieldBeacons = (e, idx) => {
        e.stopPropagation();
        focused.items.beacons && focused.setFocus({})
        formik.values.apple.beacons.splice(idx, 1)
        formik.setValues({ ...formik.values })
    }

    const [show, setShow] = useState({
        showLocations: false,
        showBeacons: false,
    })

    const handleAddLocation = () => {
        const locations = formik.values.apple.locations || []
        const location = {
            latitude: -27.4704528,
            longitude: 153.0260341,
            relevantText: 'You are new store',
            address: 'select address',
        }

        locations.push(location)

        const apple = { ...formik.values.apple, locations }
        formik.setValues({ ...formik.values, apple })
    }


    const handleAddBeacon = () => {
        const beacons = formik.values.apple.beacons || []
        const beacon = {
            proximityUUID: 'uwueikdmnsdf-sndsdjkklsd',
            relevantText: 'beacon1'
        }


        beacons.push(beacon)

        const apple = { ...formik.values.apple, beacons }
        formik.setValues({ ...formik.values, apple })
    }

    const getBackFieldItemComponent = ({ item, idx, focused }) => {
        return (
            <div onClick={(e) => focused.setFocus({ backFields: true, index: idx })} className={`Body14R pl_16 mt_10 opacity-10 fw-4 color-textfieldColor cp frontFields p_8`}>
                <span className={`d-flex space-between pr_10 ${!item.label && 'placeholder'}`}>• {item.label || 'Label'}
                    <span onClick={(e) => deleteBackField(e, idx)}><SvgIcons.DeleteIcon color={ColorSchemeCode.cBDBDBD} /></span>
                </span>
            </div>
        )
    }

    const handleBackFieldListChange = (list) => {
        const apple = { ...formik.values.apple, backFields: list }
        formik.setValues({ ...formik.values, apple })
    }

    return (
        <div id="BackField">
            <DraggableComponent
                list={formik.values.apple.backFields}
                ItemComponent={({ item, idx }) => getBackFieldItemComponent({ item, idx, focused })}
                setList={handleBackFieldListChange}
            />
            {!formik.values.apple.backFields.length && <div className="addBtn" onClick={() => formik.setValues({ ...formik.values, apple: { ...formik.values.apple, backFields: [{}] } })}>Add Field</div>}

            <div className='cp p_8 pl_16 fs-14 fw-5 frontFields' onClick={() => setShow({ ...show, showLocations: !show.showLocations })}> locations <span className="ml_5">{show.showLocations ? <SvgIcons.DropDownTriangleIcon color={ColorSchemeCode.c828282} /> : <SvgIcons.DropDownTriangleHorizontalIcon color={ColorSchemeCode.c828282} />}</span> </div>

            {(show.showLocations) ?
                <>
                    <div className="p_8 pl_16 cp fs-14 fw-5" onClick={handleAddLocation}>+ Add Field</div>
                    {formik.values.apple.locations && formik.values.apple.locations.map((location, idx) => (
                        <div className="cp ml_16 p_6 frontFields fw-4 fs-12 d-flex space-between pr_16" onClick={() => focused.setFocus({ locations: true, index: idx })}>
                            {location.relevantText}
                            <span onClick={(e) => deleteBackFieldLocation(e, idx)}><SvgIcons.DeleteIcon color={ColorSchemeCode.cBDBDBD} /></span>
                        </div>
                    ))}
                </>
                : ''}

            <div className='cp p_8 pl_16 fs-14 fw-5 frontFields' onClick={() => setShow({ ...show, showBeacons: !show.showBeacons })}> IBeacons <span className="ml_5">{show.showLocations ? <SvgIcons.DropDownTriangleIcon color={ColorSchemeCode.c828282} /> : <SvgIcons.DropDownTriangleHorizontalIcon color={ColorSchemeCode.c828282} />}</span> </div>
            {(show.showBeacons) ?
                <>
                    <div className="p_8 pl_16 cp fs-14 fw-5" onClick={handleAddBeacon}>+ Add Field</div>
                    {formik.values.apple.locations && formik.values.apple.beacons.map((beacon, idx) => (
                        <div className="cp ml_16 p_6 frontFields fw-4 fs-12 d-flex space-between pr_16" onClick={() => focused.setFocus({ beacons: true, index: idx })}>
                            {beacon.relevantText}
                            <span onClick={(e) => deleteBackFieldBeacons(e, idx)}><SvgIcons.DeleteIcon color={ColorSchemeCode.cBDBDBD} /></span>
                        </div>
                    ))}
                </>
                : ''}

        </div>
    )

}

const LeftPane = ({ preview, passView, formik, focused }) => {

    return (
        <div id="LeftPane" className={`col-2 pl_0 pr_0 d-flex ${preview && 'bg-color-cF2F2F2 border-none'}`}>
            {!preview &&
                <div className="headline4 navigatorBox col-12 pl_0 pr_0">

                    <div className="items">
                        <div className={'Body14R color-textfieldColor opacity-10 item cp'} >
                            <span className="ml_8 fs-16 fw-6">{passView === 'front' ? 'Front Fields' : 'Back Fields'}</span>
                        </div>

                        <div className='expand w-100'>
                            {passView === 'front' && <FrontFields formik={formik} focused={focused} />}
                            {passView === 'back' && <BackFields formik={formik} focused={focused} />}

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const RightPane = ({ preview, formik, passFieldFocused }) => {
    console.log('For', formik);
    return (
        <div className={`col-3 cardComponent ${preview && 'bg-color-neutral10'}`}>
            {/* make a check of apple here */}
            {!preview && <ApplePassFieldDetails formik={formik} passFieldFocused={passFieldFocused} />}

            {!preview && utils.emptyJSON(passFieldFocused) &&
                <div className="noneSelectedBox w-100 mt_40">
                    <span><SvgIcons.CursorIcon /></span>
                    <div className="mt_8">Select an element on the pass to activate this panel.</div>
                </div>
            }
        </div>
    )
}

const Pass = ({ preview, passView, formik, focused }) => {
    return (
        <div onClick={() => focused.setFocus({})} className="col-7 middleColumn text-center bg-color-cF2F2F2">
            {/* make a check of apple here */}
            <ApplePass
                preview={preview}
                passView={passView}
                formik={formik}
                focused={focused}
            />
        </div>
    )
}

