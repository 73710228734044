import React, { useState } from 'react'
import CustomButton from '../../../../components/CustomButton'
import CustomTextField from '../../../../components/CustomTextField'

function SheduleNotifcationModal({state, setState, formik}) {
    const [error, setError] = useState(false)
    return (
        <div>
            <div className="fs-18 fw-6 color-c000000 mb_4">Shedule Notifcation</div>   
            <div className="fs-14 color-c000000 mb_16">Select a date and time in the future for your notification to be published.</div>   
            <div>
                <CustomTextField
                    className  = "w-100"
                    type       = "datetime-local"
                    name       = "scheduleDate"
                    value      = {formik.values.scheduleDate ? formik.values.scheduleDate : ''}
                    onChange   = {(e)=>{formik.setValues({...formik.values, scheduleDate: e.target.value}); if(e.target.value !== "") setError(false)}}
                    error      = {formik.errors.scheduleDate  ? formik.errors.scheduleDate : false}
                    helperText = {formik.errors.scheduleDate ? formik.errors.scheduleDate : ''}
                />
                {error&&<div className="error">This field is required</div>}
            </div>
            <div className="d-flex justify-content-right mt_16">
                <CustomButton 
                    onClick={()=>setState({...state, sheduleOpen: false})}
                    varient="secondary"
                    hover={"#F0F0F0"} className="mr_8" backgroundColor="#F0F0F0" btntext="Cancel" />
                <CustomButton 
                    onClick={()=>{
                        if(formik.values.scheduleDate === "") setError(true)
                        else {
                            setError(false)
                            setState({...state, sendnow: false, sheduleOpen: false})
                        }
                        
                    }} 
                    btntext="Save" />
            </div>
        </div>
    )
}

export default SheduleNotifcationModal
