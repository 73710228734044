import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import localforage from "localforage";
import React, { useContext, useEffect, useState } from "react";
import CopyText from "../../../components/CopyText";
import CustomButton from "../../../components/CustomButton";
import CustomTextField from "../../../components/CustomTextField";
import { LayoutContext } from "../../../context/Layout.context";
import { UserContext } from "../../../context/User.context";
import { SvgIcons } from "../../../icons";
import { UserService } from "../../../services";
import { UserVld } from "../../../validation";

function Account() {
  const user = useContext(UserContext);

  const [state, setState] = useState({
    updateLoader: false,
    emailLoader: false,
  });

  const initState = {
    fullName: user.firstName + " " + user.lastName,
    email: user.email,
  };

  const handleSubmit = async () => {
    setState({ ...state, updateLoader: true });

    let names = formik.values.fullName.split(" ");
    let firstName = names.shift();
    let lastName = names.join(" ");

    const payload = {
      _id: user._id,
      firstName: firstName,
      lastName: lastName,
      email: formik.values.email,
    };

    const { error, response } = await UserService.Update({
      toaster: true,
      payload,
    });
    if (response) localforage.setItem("user", response.data);
    setState({ ...state, updateLoader: false });
    if (response) user.updateUser(response.data);
    return;
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: UserVld.Update,
    isInitialValid: false,
    enableReinitialize: true,
  });

  const handleEmailChange = async (e) => {
    if (
      e.target.value === initState.email ||
      !e.target.value ||
      formik.errors.email
    )
      return;

    setState({ ...state, emailLoader: true });
    const email = e.target.value;
    const query = { email };
    const { error, response } = await UserService.GetByQuery({ query });
    if (response) {
      formik.setErrors({ email: "email already exist" });
      return setState({ ...state, emailLoader: false });
    }
    return setState({ ...state, emailLoader: false });
  };

  const layout = useContext(LayoutContext);

  useEffect(() => {
    layout.setLayout({
      title       : "Settings",
      borderBottom: false,
      button      : ""
    });
  }, [state, formik.values, formik.errors]);

  return (
    // ------------ MAIN BODY ------------
    <div className="d-flex flex-column">
      {/* ------------ SUBHEADER -ACCOUNT ------------ */}
      <div className='d-flex space-between mb_12 w-100 align-items-center'>
          <div className='Heading22M color-neutral100'>Account</div>
          <CustomButton
            btntext = "Update"
            onClick = {handleSubmit}
            icon    = {
              state.updateLoader && (
                <CircularProgress size={20} color={"inherit"} />
              )
            }
            disabled={
              state.updateLoader ||
              formik.errors.profilePhotoFile ||
              formik.errors.email ||
              formik.errors.fullName
            }
        />
      </div>
      <div className='bar w-100'></div>     

      {/* ------------ FORM START ------------*/}
      <div className="w-63 mt_24 d-flex flex-column gap-16 pl_4">
        {/*USER ID*/}
        <div className="position-relative">
          <CustomTextField
            label={"User ID"}
            className="w-100 disabled"
            value={user.idKey}
          />
          <div style={{ position: "absolute", top: "17px", right: "16px" }}>
            <CopyText content={user.idKey} hideText={true} />
          </div>
        </div>

        {/*------------ ACCOUNT NAME ------------*/}
        <CustomTextField
          label={"Account Name"}
          placeholder="Full Name"
          type="text"
          name="fullName"
          value={formik.values.fullName}
          onChange={formik.handleChange}
          inputProps={{ onFocus: formik.handleBlur }}
          error={formik.touched.fullName && formik.errors.fullName}
          helperText={
            formik.touched.fullName &&
            formik.errors.fullName &&
            formik.errors.fullName
          }
        />

        {/*------------ EMAIL ADDRESS ------------*/}
        <div className="position-relative">
          <CustomTextField
            label={"Email Address"}
            placeholder="Email"
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onKeyUp={handleEmailChange}
            position="end"
            inputProps={{ onFocus: formik.handleBlur }}
            error={formik.touched.email && formik.errors.email}
            helperText={
              formik.touched.email && formik.errors.email && formik.errors.email
            }
          />
          <div
            style={{ position: "absolute", top: "17px", right: "16px" }}
            className=""
          >
            {state.emailLoader ? (
              <CircularProgress size={16} color={"inherit"} />
            ) : !formik.errors.email ? (
              <SvgIcons.Check />
            ) : (
              <SvgIcons.Failure />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
