import { CircularProgress, FormControl, RadioGroup } from '@mui/material';
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomButton from '../../../../components/CustomButton';
import CustomDropDown from '../../../../components/CustomDropDown';
import CustomsModal from '../../../../components/CustomModal';
import CustomRadio from '../../../../components/CustomRadio';
import CustomSwitch from '../../../../components/CustomSwitch';
import Tags from '../../../../components/CustomTagComponent';
import CustomTextField from '../../../../components/CustomTextField'
import CustomCheckBox from '../../../../components/CustomCheckBox'
import DeleteModal from '../../../../components/DeleteModal';
import Autocomplete from '../../../../components/googleMap/Autocomplete';
import GoogleMap from '../../../../components/googleMap/GoogleMap';
import ViewDetail from '../../../../components/ViewDetail';
import { BrandContext } from '../../../../context/Brand.context'
import { LayoutContext } from '../../../../context/Layout.context';
import { UserContext } from '../../../../context/User.context'
import { Communication } from '../../../../enums'
import { ColorSchemeCode } from '../../../../enums/ColorScheme';
import { SvgIcons } from '../../../../icons';
import { CommunicationService, PassService } from '../../../../services';
import { ClickOutside, utils } from '../../../../utils';
import { CommunicationVld } from '../../../../validation'
import NewTagComponent from '../../../../components/NewTagComponent';

function CreateSMS(props) {

    
    const template                = props.location.state || {}
    const brand                   = useContext(BrandContext)
    const layout                  = useContext(LayoutContext)
    const user                    = useContext(UserContext)
    const history                 = useHistory()
    const [viewMore, setViewMore] = useState(false)
    
    useEffect(()=>{
        layout.setLayout({
            title        : template.communicationName?"Update SMS":"Create SMS",
            backTitles   : ['Communication','SMS'],
            button       : '',
            borderBottom : true,
            edit         : false
        });
    },[])

    const  [filterAudience, setFilterAudience] = useState([]);
    const  [filteredOptions, setFilteredOptions] = useState([]);
    const  [deleteModal, setDeleteModal] = useState(false);
    
    
    const [ state, setState ]     = useState({
        apiError     : '',
        processing   : false,
        deleteLoader : false,
        viewMessage  : template.viewMessage || '',
        message      : template.message || '',
        audience     : '',
        // filteredOptions : []
    })

    const initState = {
        communicationName : template.communicationName || 'New SMS',
        communicationType : template.communicationType || Communication.CommunicationTypes.SMS,
        geo               : template.geo || true,
        schedule          : template.schedule,
        radio             : 'save',
    }
    
    const handleDelete = async() => {
        setState({...state, deleteLoader: true})
        const payload = {
            _id     : template._id,
            delete  : true,
        }

        const {error, response} = await CommunicationService.Update({toaster: true, payload})
        setState({...state, deleteLoader: false})
        if(response) history.goBack()
    }

    const suggestions = [
            {
              id: Communication.CustomFields.FIRSTNAME,
              display: "First Name",
            },
            {
              id: Communication.CustomFields.LASTNAME,
              display: "Last Name",
            },
            {
              id: Communication.CustomFields.EMAIL,
              display: "Email",
            },
            {
              id: Communication.CustomFields.POINTS,
              display: "Points",
            },
            {
                id: Communication.CustomFields.passUrl,
                display: "Loyalty Card",
            },
            {
                id: Communication.CustomFields.referral,
                display: "Referral",
            },
      ];

    const onAddressChange     = (event) => formik.setValues({...formik.values, location: {...formik.values.location, address: event.target.value}})
    const locationSummary     = (location) => formik.setValues({...formik.values, location})

    const getPlace = (e) => {
        const Geocoder = new window.google.maps.Geocoder()
        const placeRequest = {location: {lat: e.latLng.lat(), lng: e.latLng.lng()}}

        Geocoder.geocode(placeRequest, (places, status) => {
            if(status !== window.google.maps.GeocoderStatus.OK) return
            const location = utils.summarisedLocation(places[0])
            formik.setValues({...formik.values, location})            
        })
    }

    const handleTagChange = (e) => {
        setState({...state, viewMessage : e.target.value, message : e.target.value})
    };


    const handleSubmit = async() =>{
        // console.log('filter Audience ', filterAudience);
        const payload = {
            communicationName : formik.values.communicationName,
            message           : state.message,
            viewMessage       : state.viewMessage,
            brandId           : brand._id,
            linkCheck         : formik.values.linkCheck,
            communicationType : formik.values.communicationType,
            smsType           : "draft"

        }

        let newAudience = [];

        filterAudience.forEach((audience)=>{
            // newAudience.push(audience.data.passCode);
            newAudience.push({...audience.data.userData, currentPoints : audience.data.loyaltyCard?.currentPoints || 0, passCode : audience.data.passCode, referalCode: audience.data?.referalCode, growthToolId: audience.data?.growthToolId});
        })



        // // console.log("data ", newAudience )

        if(formik.values.radio === 'send'){
            payload.audience = newAudience
            payload.smsType  = "published"
        }
        
        if(formik.values.radio === 'sendtoall'){
            payload.smsType  = "sendtoall"
        }

        if(formik.values.radio === 'schedule'){
            payload.schedule = new Date(formik.values.schedule).valueOf()
            payload.audience = newAudience
            payload.smsType  = "scheduled"
        }

        setState({...state, processing: true})
        // console.log('payload ', payload)

        if(formik.values.radio === 'schedule'){
            const { error, response } = await CommunicationService.schedule({toaster: true, payload})
            // console.log('response ', response);
            if(error) return setState({...state, apiError: error.message})
        }
        else if(formik.values.radio === 'send'){
            const { error, response } = await CommunicationService.send({toaster: true, payload})
            // console.log('response ', response);
            if(error) return setState({...state, apiError: error.message})
        }
        else if(formik.values.radio === 'sendtoall'){
            const { error, response } = await CommunicationService.send({toaster: true, payload})
            // console.log('response ', response);
            if(error) return setState({...state, apiError: error.message})
        }
        else{
            if(!utils.emptyJSON(template)){
                delete payload.communicationType
                payload._id = template._id
            }
            // console.log('====================================');
            // console.log('CommunicationService', payload);
            // console.log('====================================');
            const { error, response } = utils.emptyJSON(template) ? 
                                            await CommunicationService.Create({toaster: true, payload}) :
                                            await CommunicationService.Update({toaster: true, payload})
            // console.log('response ', response)
            if(error) {
                // console.log('error' , error);
                return setState({...state, apiError: error.message})}
        }
                                        
        setState({...state, processing: false})
        // history.goBack();
    }

    useEffect(async() => {
      const user = await PassService.GetQueriedPass({query: {brandId: brand._id, delete: false}})
      if(user.response&&user.response.data&&user.response.data.length>0){
          let temp = []
          for (const value of user.response.data) {
            if(value.userData && value.userData.phone){
                temp.push({
                    label : value.userData?.firstName + ' ' + value.userData?.lastName,
                    value : value.userId,
                    image : utils.getAvatar({fontSize: '10px',firstName: value.userData?.firstName, lastName: value.userData?.lastName&&value.userData.lastName, id: value.userId, tableName : 'customers', width: '20px', heigth: '20px', bgColor: value.color}),
                    data  : value
                })
            }
          }
          setFilteredOptions([...temp])
      }
    }, [])
    

    const formik = useFormik({
        initialValues      : { ...initState },
        onSubmit           : handleSubmit,
        validationSchema   : CommunicationVld.CreateSMS,
    })

  return (
    <div id="SMSDetailScreen" className='pt_40 pb_40 w-50 container'>
        <div className='Heading22M color-neutral100'>SMS Information</div>
        <div className='bar mb_16 mt_12'></div>
        <CustomTextField 
            label    = "SMS Name"
            name     = "communicationName"
            value    = {formik.values.communicationName}
            onChange = {formik.handleChange}
            className='mb_16'
         />
        <NewTagComponent 
            suggestions = {suggestions}
            placeholder = {'Content'}
            onChange    = {handleTagChange}
            value       = {state.message}
        />
        <div className='mt_6 Body14R color-neutral60'>Use <span className='color-neutral100'>“@”</span> to add custom fields like firstname,lastname etc</div>

        <div className='Heading22M color-neutral100 mt_32'>Manage Audience</div>
        <div className='bar mt_12 mb_12'></div>
        <div className='Heading16M color-neutral100'>Filter Audience</div>
        <div className='mt_6 mb_16 Body14R color-neutral60'>Filter out the audience with whom you want to send this push.</div>
            <CustomDropDown 
                showCheckBox  = {true}
                setFilterData = {setFilterAudience}
                search        = {true}
                label         = {'Audience'}
                allFilterShow = {false}
                options       = {filteredOptions}
                className     = {'w-50'}
                classNameB    = {'w-30'}
            />
        <div className='bar mt_20 mb_20'></div>

        {formik.values.schedule&&<><div className="mt_16">
            <Autocomplete 
                className       = "w-100"
                variant         = "standard"
                label           = "Enter Location"
                name            = "address"
                value           = {formik.values.location ? formik.values.location.address : ''}
                onChange        = {onAddressChange}
                InputProps      = {{tabIndex:'6', disableUnderline: false, style: { fontSize: 16,color: 'color(neutral80)'} }}
                locationSummary = {locationSummary}
                error           = {formik.errors.location && !formik.values.location ? formik.errors.location.address : false}
                helperText      = {formik.errors.location && !formik.values.location ? formik.errors.location.address : ''}
            /> 
        </div>
        <div className="mt_16" style={{height: '200px'}}>
            <GoogleMap 
                width     = {"100%"}
                height    = {"100%"}
                zoom      = {formik.values.location && formik.values.location.lat ? 15 : 4}
                draggable = {true}
                position  = {formik.values.location ? {lat: formik.values.location.lat, lng: formik.values.location.lng } : ''}
                dragEnd   = {getPlace}
                className = {'borderRadius-4'}
            />
        </div></>}

        <div className='Heading22M color-neutral100 mt_32'>Scheduling Option</div>
        <div className='bar mt_16 mb_12'></div>

        <FormControl className="w-100" >
            <RadioGroup 
                name     = "radio"
                value    = {formik.values.radio}
                onChange = {formik.handleChange}
                onFocus  = {formik.handleBlur}
            >
                <CustomRadio 
                    value = {"send"}
                    label = {<div className='color-neutral100 Body14R'>Send Now</div>}
                    // checked={(formik.values.checkCustom[0][formik.values.tierIndex] === 'DiscountValue' || formik.values.checkCustom[0][formik.values.tierIndex] === false) ? true : false}
                />
                <CustomRadio 
                    value = {"sendtoall"}
                    label = {<div className='color-neutral100 Body14R'>Send To All</div>}
                    // checked={(formik.values.checkCustom[0][formik.values.tierIndex] === 'DiscountValue' || formik.values.checkCustom[0][formik.values.tierIndex] === false) ? true : false}
                />
                <div className='mt_12 mb_12'>
                    <CustomRadio 
                        value={"save"}
                        label={<div className='color-neutral100 Body14R'>Save as drafts</div>}
                        // checked={(formik.values.checkCustom[0][formik.values.tierIndex] === 'Custom Discounts' || formik.values.checkCustom[0][formik.values.tierIndex] === true ) ? true : false}
                    />
                </div>
                <div className='radiotop'>
                    <CustomRadio 
                        value={"schedule"}
                        label={<div>
                            <div className='color-neutral100 Body14R'>Schedule</div>
                            <div className='color-neutral60 Body14R'>Manually select a date and time in the future to send your sms.</div>

                            <CustomTextField name='schedule' onChange = {formik.handleChange} value={formik.values.schedule} className='w-92 mt_16' type={'datetime-local'} />
                        </div>}
                    />
                </div>
            </RadioGroup>
        </FormControl>

        <div className='bar mt_20 mb_20'></div>
                            {console.log('formik ', formik.values)}
        <div className='d-flex btncolorsms '>
            <CustomButton 
                icon     = {state.processing ? <CircularProgress size={20} color={'inherit'}/> : ''}
                // disabled = {((formik.values.radio != 'save' )|| (formik.values.radio != 'sendtoall')) && filterAudience.length <= 0}
                disabled = {(formik.values.radio === 'save' || formik.values.radio === 'sendtoall') ? false : filterAudience.length <= 0}
                onClick  = {handleSubmit} btntext = {template.communicationName ? 'Update' : formik.values.radio === 'sendtoall' ? 'Send To All' : formik.values.radio}
            />
            {template&&template.communicationName&&<div className='position-relative'>
                <CustomButton className={'ml_10'} varient="tertiary" icon={<div className='rotate-right'><SvgIcons.ViewMoreIcon color={ColorSchemeCode.themeColor}/></div>} onClick={()=>setViewMore(!viewMore)} />
                {viewMore &&  <ClickOutside className="position-relative cp" onClick={()=>setViewMore(false)}>
                    <div>
                        <div className="viewdetail cp position-absolute" style={{left : '10px', bottom:'30px', zIndex : 100}}>
                            <ViewDetail items={[{name: 'Delete', function: ()=>setDeleteModal(true)}]}/>
                        </div>
                    </div>
                </ClickOutside>}
            </div>}
        </div>

        <CustomsModal
            open      = { deleteModal }
            minWidth  = {'600px'}
            component = {<DeleteModal icon={state.deleteLoader} title="SMS" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
            onClose   = {()=> setDeleteModal(false)}
        />

    </div>
  )
}

export default CreateSMS