import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomSelect from '../../components/CustomSelect';
import CustomTextField from '../../components/CustomTextField';
import { LocationService, UserService } from '../../services';
import { useParams } from 'react-router-dom'
import { Options } from '../../utils';
import { CountryCode } from '../../enums';

function LandingPageContact() {
    const [state, setState] = useState({
        phone       : '',
        dialingCode : ''
    })

    const params = useParams();
    const history = useHistory();

    useEffect(async ()=>{
        const ipAddress = await LocationService.GetIpAddress()
        if(ipAddress.error) return
        const {error, response} = await LocationService.GetLocation({ipAddress: ipAddress.response.ip})
        if(error) return
        CountryCode.map((element,idx)=> {
            if(element.code.toLowerCase() === response.country_code.toLowerCase()){
                setState({...state, dialingCode: element.dial_code})
            }  
        })
    },[])

    return (
        <div style={{height: '90vh'}} className="middle">
            <div className="p_24">
                <div className="Heading20M color-GeneralBlack mb_24 text-align-center">Enter phone number</div>
                <div className="row">
                    <CustomSelect
                        value       = {state.dialingCode}
                        options     = {Options.getContryCodes()}
                        onChange    = {(e)=>setState({...state, dialingCode: e.target.value})}
                        label       = "DialingCode"
                        placeholder = "DialingCode"
                    />
                    <CustomTextField
                        value     = {state.phone}
                        onChange  = {(e)=>setState({...state, phone: e.target.value})}
                        label     = "Phone"
                        className = "ml_4"
                    />
                </div>
                <CustomButton
                    btntext   = "Next"
                    className = "w-100 mt_24"
                    disabled  = {(!state.dialingCode || !state.phone) ? true : false}
                    onClick   = {async()=>{
                        const {response, error} = await UserService.UpdatePublic({payload: {_id: history.location.state.result.userId, phone: state.phone, dialingCode: state.dialingCode}})
                        if(response) history.push({pathname: '/page/'+params.growthtoolId+'/listing', state: {...history.location.state}})
                    }}
                />
            </div>
        </div>
    )
}

export default LandingPageContact
