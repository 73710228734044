import React, { useState } from 'react'
import CustomButton from '../../components/CustomButton'
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { TransactionService } from '../../services';
import { CircularProgress } from '@mui/material';
import localforage from 'localforage';
import CustomTextField from '../../components/CustomTextField';

function LandingPagePoints() {
    const history = useHistory();
    const params = useParams()

    window.onbeforeunload = function (e) {
        if (e) e.returnValue = history.push('/page/'+params.growthtoolId)
        return history.push('/page/'+params.growthtoolId);
    };
    

    const [loader, setLoader] = useState(false)
    const [selected, setSelected] = useState()
    const [selectedValue, setSelectedValue] = useState()
    const [amount,setAmount] = useState('');

    const handleAmountChange = (e) => {
        if(e.target.value > 0){
            setAmount(e.target.value)
        }
        else{
            setAmount('')
        }
    }

    return (
        <div id="ClaimPointsPage" className="position-relative middle" style={{height: '100vh'}}>
            <div className="webScreen">
                <div className='text-center'>
                    <img 
                        src       = {history.location.state.brand.brandLogo}
                        height    = "64px"
                        width     = "64px"
                        className = 'borderRadius-60'
                        alt       = ""
                    />
                </div>
                <div className="Body14R color-neutral60 mt_32 mb_32 text-center">Enter your spending  to earn points.</div>
                <div className="w-100">
                    <CustomTextField 
                        className = "w-100"
                        label     = "Spending amount"
                        type      = "number"
                        onChange  = {handleAmountChange}
                        value     = {amount}
                        icon      = {<div>$</div>}
                        position  = "start"
                    />

                {/* {
                    history.location.state.earningWays.earningValueRange.map((value, index)=> (
                        <div className="mb_16 pl_24 pr_24 pt_24 pb_24 d-flex space-between" style={{border: index === selected ? '1px solid #2F80ED':'1px solid #E6E6E6', borderRadius: '4px', backgroundColor: index === selected && '#E8EFF7', boxShadow: index === selected && '0px 3px 8px rgba(12, 65, 136, 0.14)'}} onClick={()=>{setSelected(index); setSelectedValue(value)}}>
                            <div className={index === selected ? "Link14M color-themeColor":"Link14M color-neutral100"}>{value.points+" Points"}</div>
                            <div className={index === selected ? "Link14M color-themeColor":"Link14M color-neutral100"}>{"$"+value.minValue+" - $"+value.maxValue}</div>
                        </div>
                    ))
                } */}
                </div>
                <div className="w-100 position-absolute l-0 b-0">
                    <CustomButton 
                    disabled  = {loader || amount==""}
                    icon      = {loader && <CircularProgress size={20} color={'inherit'}/>}
                    btntext   = "Next" className = "w-100 ClaimBtn" height = "48px" onClick = {async()=>{
                        // if(selectedValue.validPassCode) history.push({pathname: '/page/'+params.growthtoolId+'/validation', state: {...history.location.state, points: selectedValue.points, amount: selectedValue.minValue}})
                        // if(history.location.state.earningWays.validatePasscode) history.push({pathname: '/page/'+params.growthtoolId+'/validation', state: {...history.location.state, amount: amount}})
                        // else {
                            setLoader(true)
                            // let payload = {...history.location.state.payload, points : selectedValue.points, amount: selectedValue.minValue, flowId: history.location.state.flowIdArray}
                            let payload = {...history.location.state.payload, amount: parseInt(amount), flowId: history.location.state.flowIdArray}
                            delete payload.firstName
                            delete payload.agencyId;
                            if(!history.location.state.dealDetail) payload.actionTriggerCall = true
                            let SelfSingleLoyalityTransaction = await TransactionService.SelfSingleLoyalityTransaction({payload, headers: history.location.state.headers})
                            if(SelfSingleLoyalityTransaction.error && SelfSingleLoyalityTransaction.error.data) return history.push({pathname:'/page/'+params.growthtoolId+'/redeem', state: {...SelfSingleLoyalityTransaction.error.data, tiers : history.location.state.tiers}})
                        
                            if(SelfSingleLoyalityTransaction.response && history.location.state.dealDetail) {
                                payload = {
                                    transaction : SelfSingleLoyalityTransaction.response.data.transaction,
                                    availDeal   : history.location.state.dealDetail,
                                    flowId      : history.location.state.flowIdArray
                                }
                                const {response, error} = await TransactionService.redeemDealTransaction({payload})
                                
                                let deals = await localforage.getItem('deals');
                                deals.map((element, idx)=> {
                                    if(element._id === history.location.state.dealDetail._id && element.uniqueId === history.location.state.dealDetail.uniqueId )
                                    {
                                        deals[idx] = {...element, avail: true}
                                    }  
                                })

                                localforage.removeItem('deals');
                                localforage.removeItem('email');
                                
                                history.push({pathname:'/page/'+params.growthtoolId})
                                localforage.setItem('deals', deals);
                            }
                            
                            localforage.removeItem('deals');
                            localforage.removeItem('email');

                            history.push({pathname:'/page/'+params.growthtoolId})
                    
                            setLoader(false)
                        // }
                    }}/>
                </div>
            </div>
            
            <div className="mobileScreen">
                <div className='topBox'>
                    <div className='text-center'>
                        <img 
                            src       = {history.location.state.brand.brandLogo}
                            height    = "64px"
                            width     = "64px"
                            className = 'borderRadius-60'
                            alt       = ""
                        />
                    </div>
                    <div className="Body14R color-neutral60 mt_32 mb_32 text-center">Enter your spending  to earn points.</div>
                </div>
                <div className='lowerBox'>
                    <div className="w-100">
                        <CustomTextField 
                            className = "w-100"
                            label     = "Spending amount"
                            type      = "number"
                            onChange  = {handleAmountChange}
                            value     = {amount}
                            icon      = {<div>$</div>}
                            position  = "start"
                        />

                    {/* {
                        history.location.state.earningWays.earningValueRange.map((value, index)=> (
                            <div className="mb_16 pl_24 pr_24 pt_24 pb_24 d-flex space-between" style={{border: index === selected ? '1px solid #2F80ED':'1px solid #E6E6E6', borderRadius: '4px', backgroundColor: index === selected && '#E8EFF7', boxShadow: index === selected && '0px 3px 8px rgba(12, 65, 136, 0.14)'}} onClick={()=>{setSelected(index); setSelectedValue(value)}}>
                                <div className={index === selected ? "Link14M color-themeColor":"Link14M color-neutral100"}>{value.points+" Points"}</div>
                                <div className={index === selected ? "Link14M color-themeColor":"Link14M color-neutral100"}>{"$"+value.minValue+" - $"+value.maxValue}</div>
                            </div>
                        ))
                    } */}
                    </div>
                    <div className="w-100 position-absolute l-0 b-0">
                        <CustomButton 
                        disabled  = {loader || amount==""}
                        icon      = {loader && <CircularProgress size={20} color={'inherit'}/>}
                        btntext   = "Next" className = "w-100 ClaimBtn" height = "48px" onClick = {async()=>{
                            // if(selectedValue.validPassCode) history.push({pathname: '/page/'+params.growthtoolId+'/validation', state: {...history.location.state, points: selectedValue.points, amount: selectedValue.minValue}})
                            // if(history.location.state.earningWays.validatePasscode) history.push({pathname: '/page/'+params.growthtoolId+'/validation', state: {...history.location.state, amount: amount}})
                            // else {
                                setLoader(true)
                                // let payload = {...history.location.state.payload, points : selectedValue.points, amount: selectedValue.minValue, flowId: history.location.state.flowIdArray}
                                let payload = {...history.location.state.payload, amount: parseInt(amount), flowId: history.location.state.flowIdArray}
                                delete payload.firstName
                                delete payload.agencyId;
                                if(!history.location.state.dealDetail) payload.actionTriggerCall = true
                                let SelfSingleLoyalityTransaction = await TransactionService.SelfSingleLoyalityTransaction({payload, headers: history.location.state.headers})
                                if(SelfSingleLoyalityTransaction.error && SelfSingleLoyalityTransaction.error.data) return history.push({pathname:'/page/'+params.growthtoolId+'/redeem', state: {...SelfSingleLoyalityTransaction.error.data, tiers : history.location.state.tiers}})
                            
                                if(SelfSingleLoyalityTransaction.response && history.location.state.dealDetail) {
                                    payload = {
                                        transaction : SelfSingleLoyalityTransaction.response.data.transaction,
                                        availDeal   : history.location.state.dealDetail,
                                        flowId      : history.location.state.flowIdArray
                                    }
                                    const {response, error} = await TransactionService.redeemDealTransaction({payload})
                                    
                                    let deals = await localforage.getItem('deals');
                                    deals.map((element, idx)=> {
                                        if(element._id === history.location.state.dealDetail._id && element.uniqueId === history.location.state.dealDetail.uniqueId )
                                        {
                                            deals[idx] = {...element, avail: true}
                                        }  
                                    })

                                    localforage.removeItem('deals');
                                    localforage.removeItem('email');
                                    
                                    history.push({pathname:'/page/'+params.growthtoolId})
                                    localforage.setItem('deals', deals);
                                }
                                
                                localforage.removeItem('deals');
                                localforage.removeItem('email');

                                history.push({pathname:'/page/'+params.growthtoolId})
                        
                                setLoader(false)
                            // }
                        }}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LandingPagePoints
