import * as yup from "yup";
import { Campaign, enums } from "../enums";

const REQ_ERROR = "This field is required";

const CloudwaitressVld = yup.object().shape({
  cloudwaitress: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Name is required")
        .min(1, "Name cannot be empty"),
      key: yup
        .string()
        .required("Key is required")
        .min(1, "Key cannot be empty"),
      url: yup
        .string()
        .required("URL is required")
        .min(1, "URL cannot be empty"),
      pabblyUrl: yup.string().trim(),
    })
  ),
});

const BusinessPageRegister = yup.object().shape({
  firstName: yup
    .string("Enter your First Name")
    .required("This field is required"),
  lastName: yup.string("Enter Last Name"),
  phone: yup
    .string()
    .matches(/^\d{1,10}$/, "Enter a valid phone number")
    .min(8, "Minimum 8 characters required")
    .required("This field is required"),
  dialingCode: yup
    .string()
    .matches(
      /^(\+{1}\d{1,3}|\+{1}\d{1,4})$/,
      "Please enter valid code eg(+61)"
    ).required,
  email: yup.string().email("Invalid email").required("Required"),
  
  signInemail:yup.string().email("Invalid email").required("Required"),
});

const BusinessPageSignIn = yup.object().shape({
  signInemail: yup.string().email("Invalid email").required("Required"),
});

export { CloudwaitressVld, BusinessPageSignIn, BusinessPageRegister };
