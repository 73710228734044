import React, {useState, useEffect, useContext} from 'react'
import {useHistory} from "react-router-dom";

import CustomButton from '../../../../components/CustomButton';
import CustomSearch from '../../../../components/CustomSearch';
import CustomTableV2 from '../../../../components/CustomTableV2';
import Loader from '../../../../components/Loader';
import DeleteModal from '../../../../components/DeleteModal';
import CustomsModal from '../../../../components/CustomModal';

import { SvgIcons } from '../../../../icons';
import { ClickOutside } from '../../../../utils';

import { LayoutContext } from '../../../../context/Layout.context';
import { BrandContext } from '../../../../context/Brand.context';
import { AgencyContext } from '../../../../context/Agency.context';

import { CampaignService, InventoryService, PassService } from '../../../../services';
import localForage from 'localforage'
import CustomDateRange from '../../../../components/CustomDateRange';
import CustomTextField from '../../../../components/CustomTextField';
import AddGiftCard from './AddGiftCard';

import {GiftCardVld} from '../../../../validation'

import { useFormik } from 'formik';
import CustomSelect from '../../../../components/CustomSelect';

import { Options } from '../../../../utils';
import { CircularProgress } from '@mui/material';
import localforage from 'localforage';
import { UserContext } from '../../../../context/User.context';

export default function Giftcards(props) {
    
    const [result, setResult]                       = useState([])
    const [filtered, setFiltered]                   = useState([])
    const [selectedRows, setSelectedRows]           = useState()
    const [addedInventory, setAddedInventory]       = useState(false)
    const [deleteModal, setDeleteModal]             = useState(false)
    const [selectedInventory, setSelectedInventory] = useState();
    const [show, setShow]                           = useState({
      filter           : false,
      mainLoader       : false,
      deleteLoader     : false,
      giftcardModal    : false,
      sendGiftCardModal: false,
    })
    const [filters,setFilters] = useState({
        startDate  : '',
        endDate    : '',
        productCode: '',
        minPrice   : '',
        maxPrice   : '',
    })

    const history = useHistory();
    
    const layout = useContext(LayoutContext)
    const brand   = useContext(BrandContext)
    const agency  = useContext(AgencyContext)
    
    const addGiftCard = (item) =>{ 
        setShow({...show, giftcardModal : true})
        setSelectedInventory(item);
    }
   

    const onLoad = async(refresh) => {
        setShow({...show, mainLoader : true})
        let giftCardCampaigns = await localForage.getItem('giftCardCampaigns');
        let body = []
        let arr  = []
        if(!giftCardCampaigns || refresh || (history.location.state && history.location.state.reload) ){
            const {response, error} = await CampaignService.GetQueriedCampaign({query: {brandId: brand._id, delete: false, campaignType : 10}})
            if(response){
                localForage.setItem('giftCardCampaigns', response.data);
                giftCardCampaigns = response.data
            }
        }

        arr  = pushArr(giftCardCampaigns, body)
        
        setResult(arr)
        setFiltered(arr)
        setShow({...show, mainLoader: false, giftcardModal: false})
    }

    useEffect(onLoad, [])


    const initState = {
        giftCard     : result.length > 0 ? result[0]._id: '',
        senderEmail  : '',
        senderName   : '',
        customerName : '',
        customerEmail: '',
        dialingCode  : '',
        phone        : '',
        loader       : false,
    }

    const sendGiftCardFunc = async () => {
        formik.setValues({...formik.values, loader : true});
        const campaignSubscribers = await localForage.getItem('campaignSubscribers');
        let payload = {
            campaignCode : formik.values.giftCard,
            brandId      : brand._id,
            firstName    : formik.values.customerName,
            email        : formik.values.customerEmail,
            dialingCode  : formik.values.dialingCode,
            phone        : formik.values.phone,
            senderEmail  : formik.values.senderEmail,
            senderName   : formik.values.senderName,
        }
        const headers = {'x-api-key': brand.apiKey}
        const {error, response} = await PassService.Create({toaster: true, payload, headers})
        if(response) { setShow({...show, sendGiftCardModal : false})
            // console.log('payload.campaignCode',payload.campaignCode);
            if(campaignSubscribers[payload.campaignCode])
            {
                campaignSubscribers[payload.campaignCode].push({
                    redeemStatus : false,
                    passCode     : response.data.passCode,
                    user         :  {
                        firstName   : payload.firstName,
                        lastName    : ' ',
                        email       : payload.email,
                        senderEmail : payload.senderEmail,
                        senderName  : payload.senderName,
                    }
                })
            }
            else {
                campaignSubscribers[payload.campaignCode] = [{
                    redeemStatus : false,
                    passCode     : response.data.passCode,
                    user         :  {
                        firstName   : payload.firstName,
                        lastName    : ' ',
                        email       : payload.email,
                        senderEmail : payload.senderEmail,
                        senderName  : payload.senderName,
                    }
                }]
            }

            localforage.setItem('campaignSubscribers', campaignSubscribers)
        }
        formik.setValues({...formik.values, loader : false})
    }

    const formik = useFormik({
        initialValues    : { ...initState },
        onSubmit         : sendGiftCardFunc,
        validationSchema : GiftCardVld.sendGiftCard,
    })

  

    const columns = [
        { field: 'Name', headerName: 'Gift Card', flex: 6, sortable: true,
            valueFormatter : (data) => data.row.value.name,
            renderCell     : (params) => {
                return (
                    result.map((v,i)=>(
                        v.id === params.id && 
                        <div style={{display: 'grid', gridAutoFlow:'column', gridGap: '0px'}}>
                            <div className="mr_16" style={{
                                backgroundImage    : 'url("'+v.value.image+'")',
                                height             : '71px',
                                width              : '128px',
                                backgroundPosition : 'center',
                                backgroundRepeat   : 'no-repeat',
                                backgroundSize     : 'cover',
                            }}></div>
                            <div>
                                <div className="" >
                                    <div style={{lineHeight: '16px !important'}} className="Link13M color-neutral100 ">{v.value.name}</div>
                                </div>
                                <div>{v.value.detail}</div>
                            </div>
                        </div>
                    ))
                );
            }
        },
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'amount', headerName: 'Amount', flex: 2 },
        { field: 'created', headerName: 'Created', flex: 2 },
    ];

    const pushArr = (arr, body) => {
        arr && arr.forEach((v,index) => {
            body.push({
                id     : v._id,
                value  : v,
                prefix : v.productCode,
                detail : v.detail,
                amount : v.amount,
                name   : v.campaignName,
                created: new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
                // Created     : new Date(v.created).getDate() + '-' + (new Date(v.created).getMonth()+1) + '-' + new Date(v.created).getFullYear(),
            })
        });
        return body
    }

    const handleSearch = (e) => {
    const results = result.filter(item =>
        item.value.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFiltered(results)
    }  

    const handleDelete = async() => {
        // setShow({...show, deleteLoader: true})
        // let body = []
        // let arr  = []
        // let temp = []
        // const {response, error} = await InventoryService.Update({toaster : true, payload : {deleteAll : selectedRows, brandId: brand._id, agencyId: agency._id}})
        // let inventory = await localForage.getItem('inventory');
        // if(inventory && inventory.length > 0){
        //     inventory.map((value, index)=>{
        //         if(!selectedRows.includes(value._id)) temp.push(value)
        //     })
        // }
        // localForage.setItem('inventory', temp);
        // arr  = pushArr(temp, body)
        // setResult(arr)
        // setFiltered(arr)
        // setShow({...show, deleteLoader: false})
        // setDeleteModal(false)
        // onLoad(true);
    }

    const handleDateChange = (ranges) => {
        let startDate =  new Date(ranges.selection.startDate).valueOf();
        let endDate   =  new Date(ranges.selection.endDate).valueOf();
        setFilters({...filters, startDate : startDate, endDate: endDate})
    }

    const handleFilterSearchFunc = () => {
        let inventory = result;

        if(filters.productCode != ""){
            inventory = inventory.filter(item =>
                item.Prefix.toLowerCase().includes(filters.productCode.toLowerCase())
            );
        }

        if(filters.maxPrice != "" && filters.minPrice != ""){
            inventory = inventory.filter(item =>
                (item.Price >= filters.minPrice) && (item.Price <= filters.maxPrice)
            );
        }

        if((filters.startDate != "" && filters.endDate != "") && inventory.length > 0)
        {   
            var startDate  = new Date (filters.startDate);
            var endDate    = new Date (filters.endDate);
            let inventoryList = [];
            inventory.map((inventory, idx)=>{
                var createDate = new Date (inventory.value.created); 
                if(((createDate.getDate() >= startDate.getDate()) && (createDate.getMonth() >= startDate.getMonth())) && ((createDate.getDate() <= endDate.getDate())) && (createDate.getMonth() >= endDate.getMonth())){
                    inventoryList.push(inventory);
                }
            })
            inventory = inventoryList;
        }
        setFiltered(inventory)
        setShow({...show, showFiter : false})
        
    }

    const handleSingleGiftCardClick = (value) => {
        let selectedGiftCard = value;
        let location = history.location.pathname.split('/');
        location.pop();
        location.push(`giftcard/${selectedGiftCard._id}`)
        location = location.join('/')
        history.push({pathname : location, state : selectedGiftCard})
    }

    useEffect(()=>{
        // console.log('sdbjskd');
        layout.setLayout({
            title           : "Gift Cards",
            showCollapseIcon: false,
            expandedBar     : false,
            switch : '',
            subTitle        : 'Create gift cards, send to your customers and create a meaningful relationship with your customers.',
            button          : <div className="d-flex">
                <CustomButton
                    btntext      = {'Send Gift Card'}
                    onClick      = {()=>setShow({...show, sendGiftCardModal : true})}
                    varient      = {'secondary'}
                    className    = {'mr_16'}
                    icon         = {<SvgIcons.SendIcon/>}
                />
                <CustomButton
                    btntext      = {'Create Gift Card'}
                    onClick      = {addGiftCard}
                />
            </div>
        });
    },[formik.values,formik.errors])

    return (
        <div id="Listing" >
            <div className="d-flex space-between align-items-center ml_24 pt_16 mr_24">
                <div className="position-relative col-6 pl_0 pr_0">
                    <CustomSearch
                        className     = "w-100 "
                        placeholder   = "Search Gift Card"
                        icon          = {<SvgIcons.SearchIcon/>}
                        position      = {'start'}
                        onChange      = {(e)=>handleSearch(e)}
                        onClickFilter = {()=>setShow({...show, filter : !show.filter})}
                        filterShow    = {show.filter}
                    />
                    <ClickOutside onClick={()=>setShow({...show, filter : false})}>
                        {
                            show.filter && 
                            <CustomFilter 
                                setFilters             = {setFilters}
                                resetFilter            = {()=>{setFilters({productCode : '', minPrice: '', maxPrice: '', startDate: '', endDate: ''}); setFiltered(result);}}
                                handleFilterSearchFunc = {handleFilterSearchFunc}
                                filters                = {filters}
                                handleDateChange       = {handleDateChange}
                            />
                        }
                    </ClickOutside>
                </div>
                <div className="d-none">
                    <div id='customRefresh' onClick={()=>onLoad(true)}>
                        <img className='imageButton cp' height='20px' width='20px' src='/images/Refresh.png'/>
                    </div>
                </div>
            </div>
            <div>
            {show.mainLoader ? 
                <Loader width={180} height={180}/>
                :
                <CustomTableV2
                    pageSize        = {50}
                    height          = {'100vh'}
                    // checkbox        = {true}
                    rows            = {filtered || result}
                    title           = {'Gift Cards'}
                    columns         = {columns}
                    deleteAll       = {true}
                    onRowClick      = {(value)=>handleSingleGiftCardClick(value.row.value)}
                    handleRefresh   = {()=>onLoad(true)}
                    setSelectedRows = {setSelectedRows}
                    handleDelete    = {()=>setDeleteModal(true)}
                />
            }

            <CustomsModal
                open      = { deleteModal }
                minWidth  = {'600px'}
                component = {<DeleteModal icon={show.deleteLoader} title="Deal" onCancel={()=> setDeleteModal(false)} onDelete={handleDelete}/>}
                onClose   = {()=> setDeleteModal(false)}
            />

            <CustomsModal 
                component = {<AddGiftCard onLoad={onLoad} item={selectedInventory} onClose={()=> setShow({...show, giftcardModal : false})}/>}
                open      = { show.giftcardModal }
                minWidth  = {'530px'}
                onClose   = {()=> setShow({...show, giftcardModal : false})}
            />

            <CustomsModal 
                component = {<SendGiftCardComponent result={result} sendGiftCardFunc={sendGiftCardFunc} formik={formik} onClose={()=> setShow({...show, sendGiftCardModal : false})}/>}
                open      = { show.sendGiftCardModal }
                minWidth  = {'530px'}
                onClose   = {()=> setShow({...show, sendGiftCardModal : false})}
            />
            </div>
        </div>
    )
}


const CustomFilter = ({handleDateChange, filters, setFilters, handleFilterSearchFunc, resetFilter}) => {

    const [showDate, setShowDate]     = useState(false);
    const [priceError, setPriceError] = useState(false);

    const handlePriceChange = (e, key) => {
        if(e.target.value >= 0) {
            setFilters({...filters, [key] : parseInt(e.target.value)})
            setPriceError(false);
        }
    }

    useEffect(()=>{
        if((filters.maxPrice < filters.minPrice) && filters.minPrice !== "" && filters.maxPrice != "") setPriceError(true)
        else setPriceError(false);
    },[filters.maxPrice, filters.minPrice])


    return(
        <div id="filterComponentAudience">
            
            <div className="row">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Product Id
                </div>
                <div className="col-9">
                    <CustomTextField
                        label     = "Product Id"
                        className = "w-100"
                        onChange  = {(e)=>setFilters({...filters, productCode : e.target.value})}
                        value     = {filters.productCode}
                    />
                </div>
            </div>

            <div className="row mt_24">
                <div className="col-3 Link13R color-neutral60 pt_8">
                    Price Range
                </div>
                <div className="col-9 d-flex space-between">
                    <CustomTextField
                        label     = "Min Price"
                        className = "w-50 mr_16"
                        type      = "number"
                        onChange  = {(e)=>handlePriceChange(e,'minPrice')}
                        value     = {filters.minPrice}
                    />
                    <CustomTextField
                        label     = "Max Price"
                        className = "w-50"
                        type      = "number"
                        onChange  = {(e)=>handlePriceChange(e,'maxPrice')}
                        value     = {filters.maxPrice}
                    />
                </div>
               {priceError && <div className="error text-right w-100 mt_8">Max price should be greater than min price</div>}
                
            </div>

            <div className="mt_24 row position-relative">
                <div className="col-3 Link13R color-neutral60 pt_16">
                    Created
                </div>
                <div className="col-9">
                    <div className={`subscribedDate Body14R color-neutral60 cp d-flex ${showDate && 'isActiveDate'}`} onClick={()=>setShowDate(!showDate)}>
                            {(filters.startDate != "" && filters.endDate != "") ? 
                            <>
                                <div>
                                    {new Date(filters.startDate).getDate() + '-' + (new Date(filters.startDate).getMonth() + 1) + '-' +new Date(filters.startDate).getFullYear()}
                                </div>
                                <span className="ml_8 mr_8">
                                    -
                                </span>
                                <div>
                                    {new Date(filters.endDate).getDate() + '-' + (new Date(filters.endDate).getMonth() + 1) + '-' +new Date(filters.endDate).getFullYear()}
                                </div>
                            </>
                            :
                            <div className="Body14R color-neutral60">Overall</div>    
                        }
                    </div>
                    <ClickOutside onClick={()=>setShowDate(false)}>
                        <div className="position-absolute dateBox">
                            {showDate && <CustomDateRange onChange={handleDateChange}/> }
                        </div>
                    </ClickOutside>
                </div>
            </div>

            <div className="d-flex mt_24 justify-flex-end">
                <div>
                    <CustomButton
                        btntext   = "Reset Filter"
                        varient   = "secondary"
                        className = "mr_16"
                        onClick   = {resetFilter}

                    />
                </div>
                <div>
                    <CustomButton
                        btntext  = "Search"
                        varient  = "primary"
                        disabled = {priceError && true}
                        onClick  = {handleFilterSearchFunc}
                    />
                </div>
            </div>
        </div>
    )
}

const SendGiftCardComponent = ({onClose, formik, sendGiftCardFunc, result}) =>{

    const giftCards = result.map((element)=>{

    })

    const getGiftCardOptions = () => {
        return (
            <>
                <option value="">None</option>
                {result.map((value)=>(
                    <option value={value.value.campaignCode}>{value.name}</option>
                ))}
            </>
        )
    }
    
    return(
        <div className="">
            <div className="Heading22M color-neutral100 mb_8">
                Send Gift Card
            </div>
            <div className="Body14R color-neutral60 mb_24">
                Select gift card to send to your customer.
            </div>
            <div>
                <select 
                    className = "SimpleSelect w-100"
                    value     = {formik.values.giftCard}
                    onChange  = {(e)=>formik.setValues({...formik.values, giftCard : e.target.value})}
                >
                    {getGiftCardOptions()}
                </select>
            </div>

            <div className="mt_24">
                <CustomTextField 
                    label            = "Sender Name"
                    className        = "w-100"
                    labelPaddingLeft = "0px"
                    name             = "senderName"
                    value            = {formik.values.senderName}
                    onChange         = {formik.handleChange}
                    inputProps       = {{ onFocus: formik.handleBlur }}
                    error            = {formik.touched.senderName && formik.errors.senderName}
                    helperText       = {formik.touched.senderName && formik.errors.senderName && formik.errors.senderName}
                    />
            </div>

            <div className="mt_24">
                <CustomTextField 
                    label            = "Sender Email"
                    className        = "w-100"
                    labelPaddingLeft = "0px"
                    name             = "senderEmail"
                    value            = {formik.values.senderEmail}
                    onChange         = {formik.handleChange}
                    inputProps       = {{ onFocus: formik.handleBlur }}
                    error            = {formik.touched.senderEmail && formik.errors.senderEmail}
                    helperText       = {formik.touched.senderEmail && formik.errors.senderEmail && formik.errors.senderEmail}
                    />
            </div>

            <div className="mt_24">
                <CustomTextField 
                    label            = "Reciever Name"
                    className        = "w-100"
                    labelPaddingLeft = "0px"
                    name             = "customerName"
                    value            = {formik.values.customerName}
                    onChange         = {formik.handleChange}
                    inputProps       = {{ onFocus: formik.handleBlur }}
                    error            = {formik.touched.customerName && formik.errors.customerName}
                    helperText       = {formik.touched.customerName && formik.errors.customerName && formik.errors.customerName}
                    />
            </div>

            <div className="mt_24">
                <CustomTextField 
                    label            = "Reciever Email"
                    className        = "w-100"
                    labelPaddingLeft = "0px"
                    name             = "customerEmail"
                    value            = {formik.values.customerEmail}
                    onChange         = {formik.handleChange}
                    inputProps       = {{ onFocus: formik.handleBlur }}
                    error            = {formik.touched.customerEmail && formik.errors.customerEmail}
                    helperText       = {formik.touched.customerEmail && formik.errors.customerEmail && formik.errors.customerEmail}
                    />
            </div>
            

            <div className="mt_24 row">
                <div className="col-3 pr_0">
                        <select 
                            className = "SimpleSelect w-100"
                            value     = {formik.values.dialingCode}
                            onChange  = {(e)=>formik.setValues({...formik.values, dialingCode : e.target.value})}
                        >
                            {Options.getContryCodes()}
                        </select>
                        {/* <CustomSelect 
                            className  = "w-100"
                            label      = "Phone number"
                            options    = {Options.getContryCodes()}
                            name       = "dialingCode"
                            value      = {formik.values.dialingCode}
                            onChange   = {(e)=>formik.setValues({...formik.values, dialingCode: e.target.value})}
                            error      = {formik.touched.dialingCode && formik.errors.dialingCode}
                            helperText = {formik.errors.dialingCode && formik.errors.dialingCode}
                        /> */}
                </div>
                <div className="col-9 pr_0">
                <CustomTextField 
                    label            = "Phone Number"
                    className        = "w-100"
                    labelPaddingLeft = "0px"
                    name             = "phone"
                    value            = {formik.values.phone}
                    onChange         = {formik.handleChange}
                    inputProps       = {{ onFocus: formik.handleBlur }}
                    error            = {formik.touched.phone && formik.errors.phone}
                    helperText       = {formik.touched.phone && formik.errors.phone && formik.errors.phone}
                    />
                </div>
            </div>
            <div className="mt_24 d-flex justify-flex-end">
                <CustomButton 
                    onClick   = {onClose}
                    varient   = {'secondary'}
                    btntext   = {'Cancel'}
                    className = "mr_16"
                />
                <CustomButton 
                    onClick   = {sendGiftCardFunc}
                    btntext   = {'Send Gift Card'}
                    className = "mr_16"
                    icon      = {formik.values.loader && <CircularProgress className="align-middle" size={20} color={'inherit'}/>}

                />
            </div>
        </div>
    )
}