import React, { useContext, useEffect, useState } from 'react'
import { PngIcons, SvgIcons } from '../../../../icons'
import { AgencyContext } from '../../../../context/Agency.context';
import CustomButton from '../../../../components/CustomButton.js'
import { CircularProgress } from '@mui/material'
import PageOneSetupWalletly from '../CloudWaitress/PageOneSetupWalletly';
import EarningWays from '../CloudWaitress/EarningWays'
import SetupCloudWaitress from '../CloudWaitress/CustomerRewards/SetupCloudWaitress';
import SetupCloudAnotherpart from '../CloudWaitress/CustomerRewards/SetupCloudAnotherpart';
import ConnectedAccounts from '../CloudWaitress/CustomerRewards/ConnectedAccounts';
import { useFormik } from 'formik';
import { Campaign } from '../../../../enums';
import { useHistory, useParams } from 'react-router-dom';
import VisitTriggers from './VisitTriggers';
import CouponStrategy from './CouponStrategy';
import CustomerEngagement from './CustomerEngagement';
import DisplayFlow from './DisplayFlow';
import { FlowService, GrowthtoolService } from '../../../../services';
import localforage from 'localforage';
import { BrandContext } from '../../../../context/Brand.context';
import { utils } from '../../../../utils';

export function MainSetupAdvanced() {
    const [selected, setSelected] = useState('Customer Engagement');
    const [index, setIndex] = useState(0);
    const agency = useContext(AgencyContext);
    const [CheckFlow , setcheckFlow] = useState(false)
    // const brand = useContext(BrandContext);
    const params = useParams();

    const communicationChild = {
        active      : false,
        referenceId : ''
    }

    const communication = {
        notification : communicationChild,
        sms          : communicationChild,
        email        : communicationChild,
        chatFlow     : communicationChild
    }


    const initValues = {
        CustomerEngagement : 'visits',
        CouponStrategy     : 'One time',
        referral           : 'No',
        type               : 'signup'
    }

    const formik = useFormik({
        initialValues : {...initValues},
        isInitialValid: false,
    })

    const addNewCWFunc = (index) => {
        setSelected(state?.components[index]?.title);
        setIndex(index)
    }
 


    const Components = [
        {
            title    : 'Customer Engagement',
            component: <CustomerEngagement formik={formik} />,
            image    : <img src={PngIcons.ruleSide} className='w-100  revL' alt="image" />,
        },
        {
            title    : 'Coupon Strategy',
            component: <CouponStrategy formik={formik} />,
            image    : <img className='w-100 revLtwo' src={ formik.values.CustomerEngagement =='visits'? PngIcons.rule1Side : PngIcons.rule3} alt="image" />
        },
        {
            title    : 'Formation',
            component: <VisitTriggers formik={formik} />,
            image    : <img className='w-100 revLthree ' src={PngIcons.rule2Side} alt="image" />
        },
        {
            title    : 'Display',
            component: <DisplayFlow formik={formik} params={params} CheckFlow={CheckFlow} setcheckFlow={setcheckFlow}/>,
            image    : <img className='w-100 revLfour' src={PngIcons.rule2Side} alt="image" />
        }
    ]

    const [state, setState] = useState({
        components: [],
        brandCreated: '',
    })

    const history = useHistory()

    const handleNextFunc = async() => {
        let deal = await localforage.getItem("deal");
        let brand = await localforage.getItem("brand");
        let campaign = await localforage.getItem("campaign");
        if(index < 3){
            setSelected(state?.components[index+1]?.title);
            setIndex(index+1)
        }
        else{

            setState({...state, loader: true})
            let temp = []
            if (deal !== null || deal.length > 0)
            for (const value of deal) {
                if(value._id === formik.values.reward1) temp.push(value)
            }

            // console.log('temp',temp);
            
            let payload = {
                name: "demo",
                // brandId: brand._id,
                brandId: params.id,
                agencyId: agency._id,
                flowType: "new",
                flow: [
                    {
                        state: [
                            {
                                name: "All Customer",
                                key: "allCustomer",
                                img: "/images/rewardBuilder/checkIn.png",
                                description: "It will trigger for all customer not depending on any condition."
                            }
                        ],
                        state1: [
                            {
                                name        : "Reward",
                                key         : "rewardDeal",
                                img         : "/images/rewardBuilder/rewardDeal.png",
                                description : "Description",
                                rewardDeal  : formik.values.reward1,
                                dealname    : temp.length>0 && temp[0].name,
                                uniqueId    : utils.getRandomString(12)
                            }
                        ],
                        block: "all customer"
                    }
                ]
            }

            if(formik.values.referral === 'Yes'){
                let temp1 = []
                let temp2 = []
                if (deal !== null || deal.length > 0)
                for (const value of deal) {
                    if(value._id === formik.values.rewardReceiver) temp1.push(value)
                    if(value._id === formik.values.rewardSender) temp2.push(value)
                }
                payload.flow.push({
                    state: [
                        {
                            name: "Referral reward for receiver",
                            key: "referrallogin",
                            img: "/images/rewardBuilder/checkIn.png",
                            description: "It triggers when a customer create pass using referral",
                            type: "signup"
                        }
                    ],
                    state1: [
                        {
                            name: "Reward",
                            key: "rewardDeal",
                            img: "/images/rewardBuilder/rewardDeal.png",
                            description: "Description",
                            rewardDeal  : formik.values.rewardReceiver,
                            dealname    : temp1.length>0 && temp1[0].name,
                            uniqueId    : utils.getRandomString(12)
                        }
                    ],
                    block: "reward receiver"
                })
                payload.flow.push({
                    state: [
                        {
                            name: "Referral reward for sender",
                            key: "referral",
                            img: "/images/rewardBuilder/oneTimeDeal.png",
                            description: "It triggers when a customer Reffered numbers of customer",
                            type: formik.values.type,
                            customer: formik.values.invites
                        }
                    ],
                    state1: [
                        {
                            name: "Reward",
                            key: "rewardDeal",
                            img: "/images/rewardBuilder/rewardDeal.png",
                            description: "Description",
                            rewardDeal  : formik.values.rewardSender,
                            dealname    : temp2.length>0 && temp2[0].name,
                            uniqueId    : utils.getRandomString(12)
                        }
                    ],
                    block: "reward sender"
                })
            }

            if(formik.values.CustomerEngagement === "repeation"){
                let temp1 = []
                if (deal !== null || deal.length > 0)
                for (const value of deal) {
                    if(value._id === formik.values.rewardPoints) temp1.push(value)
                }
                payload.flow.push( {
                    state: [
                        {
                            name: "Points",
                            key: "point",
                            img: "/images/rewardBuilder/points.png",
                            description: "It triggers when a specific amount of points are achieved.",
                            condition: "equal",
                            points: formik.values.points
                        }
                    ],
                    state1: [
                        {
                            name: "Reward",
                            key: "rewardDeal",
                            img: "/images/rewardBuilder/rewardDeal.png",
                            description: "Description",
                            rewardDeal  : formik.values.rewardPoints,
                            dealname    : temp1.length>0 && temp1[0].name,
                            uniqueId    : utils.getRandomString(12)
                        }
                    ],
                    block: "reward points"
                })
            }

            if(formik.values.CouponStrategy === '2 coupons'){
                let temp1 = []
                if (deal !== null || deal.length > 0)
                for (const value of deal) {
                    if(value._id === formik.values.reward2) temp1.push(value)
                }
                payload.flow.push({
                    state: [
                        {
                            name        : "Reward Status",
                            key         : "dealStatus",
                            img         : "/images/rewardBuilder/dealStatus.png",
                            description : "It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)",
                            deal        : formik.values.reward1,
                            dealname    : temp.length>0 && temp[0].name,
                            status      : "Redeemed"
                        }
                    ],
                    state1: [
                        {
                            name        : "Reward",
                            key         : "rewardDeal",
                            img         : "/images/rewardBuilder/rewardDeal.png",
                            description : "Description",
                            rewardDeal  : formik.values.reward2,
                            dealname    : temp1.length>0 && temp1[0].name,
                            uniqueId    : utils.getRandomString(12)
                        }
                    ],
                    block: "after redemption of first deal"
                })
            }
            
            if(formik.values.CouponStrategy === '3 coupons'){
                let temp1 = []
                let temp2 = []
                if (deal !== null || deal.length > 0)
                for (const value of deal) {
                    if(value._id === formik.values.reward2) temp1.push(value)
                    if(value._id === formik.values.reward3) temp2.push(value)
                }
                payload.flow.push({
                    state: [
                        {
                            name        : "Reward Status",
                            key         : "dealStatus",
                            img         : "/images/rewardBuilder/dealStatus.png",
                            description : "It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)",
                            deal        : formik.values.reward1,
                            dealname    : temp.length>0 && temp[0].name,
                            status      : "Redeemed"
                        }
                    ],
                    state1: [
                        {
                            name        : "Reward",
                            key         : "rewardDeal",
                            img         : "/images/rewardBuilder/rewardDeal.png",
                            description : "Description",
                            rewardDeal  : formik.values.reward2,
                            dealname    : temp1.length>0 && temp1[0].name,
                            uniqueId    : utils.getRandomString(12)
                        }
                    ],
                    block: "after redemption of first deal"
                })
                payload.flow.push({
                    state: [
                        {
                            name        : "Reward Status",
                            key         : "dealStatus",
                            img         : "/images/rewardBuilder/dealStatus.png",
                            description : "It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)",
                            deal        : formik.values.reward2,
                            dealname    : temp1.length>0 && temp1[0].name,
                            status      : "Redeemed"
                        }
                    ],
                    state1: [
                        {
                            name        : "Reward",
                            key         : "rewardDeal",
                            img         : "/images/rewardBuilder/rewardDeal.png",
                            description : "Description",
                            rewardDeal  : formik.values.reward3,
                            dealname    : temp2.length>0 && temp2[0].name,
                            uniqueId    : utils.getRandomString(12)
                        }
                    ],
                    block: "after redemption of second deal"
                })
            }
            console.log('campaign',campaign);

            const {response, error} = await FlowService.Create({payload})
            // console.log('response, error',response, error);

            let flow = await localforage.getItem('flow');
            if(flow){
                flow.push({...response.data, created: new Date().valueOf()})
            }
            else{
                flow = []
                flow.push({...response.data, created: new Date().valueOf()})
            }
            localforage.setItem('flow', flow);
            
            let payload2 = {
                brandId           : params.id,
                campaignId        : campaign._id,
                growthtoolType    : 'landingPage',
                flowId            : [response.data._id],
                growthtoolName    : 'New Channel',
                points            : 'false',
                disableClaimPoint : 'false',
                landingPage       : {
                    primaryColor: '#B2831E',
                    theme: 'dark',
                    backgroundColor: '#1E1904',
                    heading: 'testing brand',
                    description: 'jhello',
                    background: '',
                    cover: '',
                    showPass: 'true',
                    redirectUrl: '',
                    labels: {
                        firstName: 'First Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        code: 'Code',
                        phone: 'Phone Number',
                        dateOfBirth: 'Date of birth',
                        referral: 'Referral'
                    }
                }
            }

            const gt = await GrowthtoolService.CreateGrowthTool({payload: payload2})
            // console.log('gt',gt);
            if(gt.response){
                localforage.setItem('channel', [gt.response.data])
            }


            // console.log('Hello Handle Submit Fund')
            //goto final
            // console.log('history ', history.location.pathname)
            let url = history.location.pathname.split('/');
            // console.log("URL",url);
            url.pop();
            url.push('final');

            let newUrl = url.join('/');
            console.log('url is', newUrl);
            setState({...state, loader: false})
            history.push(newUrl);

        }
    }

    useEffect(()=>{
        setState({...state, components : Components})
    },[formik.values, formik.errors])

    useEffect(async() => {
        let deal = await localforage.getItem("deal");
        formik.setValues({...formik.values, dealList: deal})
    }, [])
    

    return (
        <>
            <div className='w-100 d-flex '>
                <div className='top-header-auth d-flex align-items-center'>
                    <img src={(agency.logo || PngIcons.authLogo)} height="32px" width="auto" className='borderRadius-60 ml_10 mr_10' alt="" />
                    
                    <div className='cp back-text' onClick={()=>{
                        if(index === 0){
                            const location = window.location.pathname.split('/')
                            location.pop()
                            history.push(location.join('/'))
                        }
                        else{
                            setSelected(state?.components[index-1]?.title);
                            setIndex(index-1)
                        }
                        }}>
                        {/* {index != 0 && <SvgIcons.ArrowRight />} */}
                        {index !=0 && "Back"}
                    </div>
                </div>

                <div className={index!==3?'w-60 d-flex ':'w-100'}>
                    <div className='w-100 d-flex mainSU_C1 bg-FFFFFF' id='mainSU_CU'>
                        {index!==3&&<div className={'w-24 '}>

                        <div className='ParentOfDesgn'>
                            <div className='ChildOneOfDesgn'>
                                { state?.components?.map((component, idx)=>idx<=state?.components?.length-2&&<div className={`${(component.title == selected) ? 'specialRound' : 'simpleRound' }`} ></div>)}
                            </div>
                            <div className='ChildTwoOfDesgn align-flex-start'>
                            { 
                                state?.components?.map((component, idx)=>
                                idx<=state?.components?.length-2&&
                                <>
                                <p className={`${(selected == component.title)  ? 'livePara' : 'unlivePara'} text-left ml_32 `}>{component.title}</p>
                                </>
                                )
                            }
                            </div>
                        </div>

                        </div>}
                       
                        
                        {<div className={index!==3?'w-50  mr_102 mt_124':'w-100 ml_24 mr_24 mt_124'} style={{height: 'calc(100vh - 124px)', overflowX: 'scroll'}}>
                            {/* {console.log(state?.components[index]?.component)} */}
                            {state?.components[index]?.component}
                            {<div className=" gap-12 mt_24 d-flex  " style={{justifyContent:"center"}}>
                                <CustomButton


                                    className = "w-100 hpx-52 mb_40"
                                    btntext   = "Next"
                                    onClick   = {handleNextFunc}
                                    disabled = {((selected == 'Formation' && formik.values.CustomerEngagement == "visits") ? formik.values.CouponStrategy == 'One time' ? 
                                    !formik.values.reward1 || formik.values.referral == 'Yes' ? !formik.values.invites || !formik.values.type || !formik.values.rewardSender || !formik.values.rewardReceiver : false  : formik.values.CouponStrategy == "2 Coupons" ? 
                                    !formik.values.reward1 || !formik.values.reward2 || formik.values.referral == 'Yes' ? !formik.values.invites || !formik.values.type || !formik.values.rewardSender || !formik.values.rewardReceiver : false :
                                    formik.values.CouponStrategy == "3 Coupons" ?!formik.values.reward1  || !formik.values.reward2  || !formik.values.reward3 || formik.values.referral == 'Yes' ? !formik.values.invites || !formik.values.type || !formik.values.rewardSender || !formik.values.rewardReceiver : false  : false : false)
                                    ||
                                     ((selected == 'Formation' && formik.values.CustomerEngagement == "repeation") ? formik.values.CouponStrategy == 'One time' ? 
                                    !formik.values.reward1 || !formik.values.points || !formik.values.rewardPoints || formik.values.referral == 'Yes' ? !formik.values.invites || !formik.values.type || !formik.values.rewardSender || !formik.values.rewardReceiver : false  : formik.values.CouponStrategy == "2 Coupons" ? 
                                    !formik.values.reward1 || !formik.values.reward2 || !formik.values.points || !formik.values.rewardPoints|| formik.values.referral == 'Yes' ? !formik.values.invites || !formik.values.type || !formik.values.rewardSender || !formik.values.rewardReceiver : false :
                                    formik.values.CouponStrategy == "3 Coupons" ? !formik.values.reward1  || !formik.values.reward2  || !formik.values.reward3 ||  !formik.values.points || !formik.values.rewardPoints || formik.values.referral == 'Yes' ? !formik.values.invites || !formik.values.type || !formik.values.rewardSender || !formik.values.rewardReceiver : false  : false : false)
                    }
                                    icon={
                                        state.loader && <CircularProgress size={20} color={"inherit"} />
                                    }
                                />
                            </div>}

                        </div>}

                    </div>


                </div>
                {index!==3&&<div className='w-40 d-flex align-items-center vh-100 bg-FCFDFD '>
                    {state?.components[index]?.image}
                </div>}
            </div>
        </>
    )
}


export default MainSetupAdvanced;