import * as yup from 'yup';
import { enums } from '../enums';

const REQ_ERROR = 'This field is required'

const Create = yup.object({
    name           : yup.string().required(REQ_ERROR),
    description    : yup.string().min(25).required(REQ_ERROR),
    brandCover     : yup.string(),
    brandCoverFile : yup.mixed()
                        .test('coverSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true),
    discountValue: yup.number(),
    discountType : yup.string().required(),
    eligible     : yup.string(),
    redeemed     : yup.string(),
    expired      : yup.string(),
})


export {
    Create,
}