import axios from 'axios'
import { Handlers } from '../utils'

const GetIpAddress = async () => await axios.get('https://jsonip.com', { mode: 'cors' })

const GetLocation = async ({ipAddress}) => await axios.get(`https://ipapi.co/${ipAddress}/json/`,{ mode: 'cors' })


const CampaignService = {
    GetIpAddress : Handlers.Services(GetIpAddress),
    GetLocation : Handlers.Services(GetLocation),
}

export default CampaignService
