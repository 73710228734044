

import localforage, * as localForage from 'localforage'


import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom';

import CustomButton from '../../../components/CustomButton'
import CustomTextField from '../../../components/CustomTextField'
import CustomModal from '../../../components/CustomModal';
import CustomCopytoClip from '../../../components/CustomCopyToClip';
import { SvgIcons } from '../../../icons'
import CopyText from "../../../components/CopyText";
import { BrandContext } from '../../../context/Brand.context';
import { utils } from '../../../utils'
import { useFormik } from 'formik'
import { AgencyVld } from '../../../validation'
import { AgencyService, BrandService, UserService } from '../../../services'

import { AgencyContext } from '../../../context/Agency.context';

import { CircularProgress } from '@material-ui/core';

import { ColorSchemeCode } from '../../../enums/ColorScheme';
import env from '../../../config';
import CustomCheckBox from '../../../components/CustomCheckBox';


function Domain({ setBtn }) {



    //  setTitleFunc('Domain')

    const agency = useContext(AgencyContext)
    const brand = useContext(BrandContext);

    const [updatedomain, setUpdatedomain] = useState(false);
    const [updategrowthdomain, setUpdategrowthdomain] = useState(false);

  
    useEffect(() => {
        if(brand.referralDomain) setUpdatedomain(true)
        if(brand.channelDomain) setUpdategrowthdomain(true)
    }, [brand])
    

    const initState = {
        Domain: {
            url: brand?.referralDomain || '',
            growthtoolurl: brand?.channelDomain || '',

        },
        termsAndConditions: brand.termsAndConditions|| ''
    }
    const [state, setState] = useState({
        generalLoader1: false,
        generalLoader2: false,
        generalLoader3: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false
    })

    const formik = useFormik({
        initialValues: { ...initState },
        enableReinitialize: true
    })



    const handleSubmit1 = async () => {
        // 
        setState({ generalLoader1: true, apiError: '' })
        const payload = {
            _id: brand._id,
        }
        
        if(formik.values.Domain.url){
            let match = formik.values.Domain.url.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+)/);
            if (match !== null) {
                payload.referralDomain= match[1].toLowerCase()
            }
            // payload.referralDomain= formik.values.Domain.url.includes('www.') ? formik.values.Domain.url.replace('www.', '').toLowerCase() : formik.values.Domain.url.toLowerCase()
        }
        console.log('payload is ', payload);
        const { error, response } = await BrandService.Update({ toaster: true, payload })
        console.log(' error, response ',error, response);
        if (error) setState({ generalLoader2: false, brandingLoader: false, apiError: error.error })
        console.log("Business ", response.data);
        localForage.setItem('brand',response.data)
        brand.updateBrand(response.data)
        setState({ generalLoader2: false, apiError: '' })
        setUpdatedomain(!updatedomain);
    }
    const handleSubmit = async () => {
        setState({ generalLoader2: true, apiError: '' })
        const payload = {
            _id: brand._id,
        }
        
        if(formik.values.Domain.growthtoolurl){
            let match = formik.values.Domain.growthtoolurl.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+)/);
            if (match !== null) {
                payload.channelDomain= match[1].toLowerCase()
            }
        }

        console.log('payload is ', payload);
        const { error, response } = await BrandService.UpdateChannel({ toaster: true, payload })
        console.log(' error, response ',error, response);
        if (error) setState({ generalLoader1: false, brandingLoader: false, apiError: error.error })
        console.log("Business ", response.data);
        localForage.setItem('brand',response.data)
        brand.updateBrand(response.data)
        setState({ generalLoader1: false, apiError: '' })
        
        setUpdategrowthdomain(!updategrowthdomain);
    }
    const handleSubmit2 = async () => {
        setState({ generalLoader3: true, apiError: '' })
        const payload = {
            _id: brand._id,
        }
        
        if(formik.values.termsAndConditions){
            // let match = formik.values.termsAndConditions.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+)/);
            let match = formik.values.termsAndConditions.match(/(?:https?:\/\/)?([^\/]+)/);
            if (match !== null) {
                payload.termsAndConditions= match[1].toLowerCase()
            }
            // payload.referralDomain= formik.values.Domain.url.includes('www.') ? formik.values.Domain.url.replace('www.', '').toLowerCase() : formik.values.Domain.url.toLowerCase()
        }

        console.log('payload is ', payload);
        const { error, response } = await BrandService.Update({ toaster: true, payload })
        console.log(' error, response ',error, response);
        if (error) setState({ generalLoader3: false, brandingLoader: false, apiError: error.error })
        console.log("Business ", response.data);
        localForage.setItem('brand',response.data)
        brand.updateBrand(response.data)
        setState({ generalLoader3: false, apiError: '' })
        // setUpdatedomain(!updatedomain);
    }



    const termsRef = useRef();
    useEffect(()=>{
        if(state.termConditions == false){
        formik.values.termsAndConditions = '';
        formik.setValues({...formik.values});
        if(termsRef&&termsRef.current&&termsRef.current.value) termsRef.current.value = ""
        
        }
      },[state.termConditions])

    return (
        <>

            <div id='Domain' className="w-100">
                <div className='col-md-12 col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Branded URL Setup</div>
                </div>
                <div className="col-md-12 col-12 mb_20 pl_0 pr_0">
                    <div className="bar"></div>
                </div>
                <div className='col-md-12 col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8 mt_20'>
                <div>
                        <div className="Heading16M  mb_8 " style={{color:"2E3039"}}>
                            {updategrowthdomain ? "Your Share Page Domain":"Your Share Page Domain"}
                        </div>
                        <div className="B14R mb_8 " style={{color:"84889B"}}>Set up your custom domain to point to Walletly's share page. This enables you to use your own branded URL for sharing content with your customers.
                        </div>
                    </div>
                <div style={{zIndex: 1}}>
                    <CustomButton onClick={() => handleSubmit1()} btntext={"Update"}
                        disabled={state.generalLoader1 || brand.referralDomain}
                        icon={state.generalLoader1 && <CircularProgress color='inherit' size={14} />} />
                </div>
                </div>
                <div className="position-relative">
                    <CustomTextField
                        placeholder="www.example.com"
                        className="w-100 "
                        value={formik.values.Domain.url}
                        onChange={(e) => formik.setValues({ ...formik.values, Domain: { ...formik.values.Domain, url: e.target.value } })}
                    //value={user.idKey}
                    />
                    {/* <div style={{ position: "absolute", zIndex:'1', bottom: "12px", right: "16px" }}>
                        <CustomCopytoClip
                            icon={<SvgIcons.CopyIcon />}
                            data={formik.values.Domain.url}
                        />
                    </div> */}
                </div>

                {!updatedomain ?
                    <>

                        <div className='mt_20 p_16' style={{backgroundColor: "#F2F6FF"}}>
                            <div className='d-flex gap-15'>
                                <div className='circle'>

                                    <div className='number'>1</div>

                                </div>
                                <div className='Heading16M color-neutral70 mt_5'>Go to your domain setting.</div>
                            </div>


                            <div className='d-flex  mt_16 justifybetween'>

                                <div className='d-flex gap-15'>
                                    <div className='circle'>

                                        <div className='number'>2</div>

                                    </div>
                                    <div className='Heading16M color-neutral70 mt_5'> Copy & Paste this ARECORD.</div>
                                </div>

                                <div className='d-flex gap-15'>

                                    <div className='Heading16M color-neutral70 '> 54.66.160.20</div>

                                    <div className='w-5'>
                                        <CustomCopytoClip
                                            icon={<SvgIcons.CopyIcon />}
                                            data={"54.66.160.20"}

                                        />
                                    </div>
                                </div>

                            </div>


                            <div className='mt_16'>
                                <div className='d-flex gap-15'>
                                    <div className='circle'>

                                        <div className='number'>3</div>

                                    </div>
                                    <div className='Heading16M color-neutral70 mt_5'>Copy updated domain and paste it here & update your domain</div>
                                </div>


                            </div>
                        </div>
                </>: 
                <>
                    <div className='pt_20 pb_20 pl_0 pr_0'>
                        <div className='d-flex gap-15'>
                            <div className='wpx-34 hpx-32 '>
                                <SvgIcons.newcorrect />
                            </div>

                            <div className='Heading16M color-neutral190 mt_5'>Domain is ready!</div>
                        </div>
                    </div>
                </>}
                        
                        
                        <div className="col-md-12 col-12 mb_20 mt_20 pl_0 pr_0">
                            <div className="bar"></div>
                        </div>
                        
                <div className='col-md-12 col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8 mt_20'>
                    <div>
                        <div className="Heading16M  mb_8 " style={{color:"2E3039"}}>
                            {updatedomain ? "Your Referral/Landing Page Domain":"Your Referral/Landing Page Domain"}
                        </div>
                        <div className="B14R mb_8 " style={{color:"84889B"}}>Connect your own domain to Walletly's referral page. This allows you to share referral links using your branded domain.
                        </div>
                    </div>
                    <div style={{zIndex: 1}}>
                    <CustomButton onClick={() => handleSubmit()} btntext={"Update"}
                        disabled={state.generalLoader2 || brand.channelDomain}
                        icon={state.generalLoader2 && <CircularProgress color='inherit' size={14} />} />
                </div>
                </div>
                        <div className="position-relative">
                            <CustomTextField

                                placeholder="www.example.com"
                                className="w-100 "
                                value={formik.values.Domain.growthtoolurl}
                                onChange={(e) => formik.setValues({ ...formik.values, Domain: { ...formik.values.Domain, growthtoolurl: e.target.value } })}
                            //value={user.idKey}
                            />
                            <div style={{ position: "absolute", bottom: "12px", right: "16px" }}>
                                <CustomCopytoClip
                                    icon={<SvgIcons.CopyIcon />}
                                    data={formik.values.Domain.growthtoolurl}
                                />
                            </div>
                        </div>
                {!updategrowthdomain ? 
                <>

                        <div className='mt_20 p_16 borderRadius-8' style={{backgroundColor: "#F2F6FF"}}>
                            <div className='d-flex gap-15'>
                                <div className='circle'>

                                    <div className='number'>1</div>

                                </div>
                                <div className='Heading16M color-neutral70 mt_5'>Go to your domain setting.</div>
                            </div>


                            <div className='d-flex  mt_16 justifybetween'>

                                <div className='d-flex gap-15'>
                                    <div className='circle'>

                                        <div className='number'>2</div>

                                    </div>
                                    <div className='Heading16M color-neutral70 mt_5'> Copy & Paste this ARECORD.</div>
                                </div>

                                <div className='d-flex gap-15'>

                                    <div className='Heading16M color-neutral70 '> 54.66.83.156</div>

                                    <div className='w-5'>
                                        <CustomCopytoClip
                                            icon={<SvgIcons.CopyIcon />}
                                            data={"54.66.83.156"}

                                        />
                                    </div>
                                </div>

                            </div>


                            <div className='mt_16'>
                                <div className='d-flex gap-15'>
                                    <div className='circle'>

                                        <div className='number'>3</div>

                                    </div>
                                    <div className='Heading16M color-neutral70 mt_5'>Copy updated domain and paste it here & update your domain</div>
                                </div>



                            </div>
                        </div>



                </>:
                <>
                    <div className='pt_20 pb_20 pl_0 pr_0'>
                        <div className='d-flex gap-15'>
                            <div className='wpx-34 hpx-32 '>
                                <SvgIcons.newcorrect />
                            </div>

                            <div className='Heading16M color-neutral190 mt_5'>Domain is ready!</div>
                        </div>
                    </div>
                </>}
                    


            </div>


            <div className="col-md-12 col-12 mb_20 mt_20 pl_0 pr_0">
                    <div className="bar"></div>
                </div>

                <div className='col-md-12 col-12 pl_0 pr_0 d-flex align-items-center space-between mb_8 mt_20'>
                    <div>
                    <div className="Heading16M " style={{color:"2E3039"}}>
                    Add Terms & Condition</div>
                        {/* <div className="w-100 mt_20">
                            <CustomCheckBox
                                label={<p className="Heading16M mb_0 ml_4 " style={{color:"2E3039"}}>
                                    Add Terms & Condition</p>}
                                fontSize='16px '
                                Color='#2E3039'
                                
                                onChange={()=>{
                                state.termConditions = !state.termConditions
                                if(!state.termConditions){
                                formik.values.termsAndConditions = '';
                                formik.setValues({...formik.values});
                                }
                                setState({ ...state })
                            }} 
                            />
                        </div> */}
                        <div className="B14R mt_16 mb_20 " style={{color:"84889B"}}>Add the URL of your Terms & Conditions page. This will be linked in relevant areas of your Walletly-powered pages.</div>
                    </div>
                    <div style={{zIndex: 1}}>
                        <CustomButton onClick={() => handleSubmit2()} btntext={"Update"}
                            // disabled={state.generalLoader1 || brand.referralDomain}
                            icon={state.generalLoader3 && <CircularProgress color='inherit' size={14} />}
                        />
                    </div>
                </div>

                <div className='w-70 col-md-12 col-12 pl_0 pr_0 mb_50'>
                    {/* <div className="w-100 mt_20 mb_10 ml_7">
                        <CustomCheckBox
                            label={<p className="mb_0 Link13R">Add Terms & Condition</p>}
                            fontSize='16px '
                            Color='#2E3039'
                            
                          onChange={()=>{
                          state.termConditions = !state.termConditions
                          if(!state.termConditions){
                            formik.values.termsAndConditions = '';
                            formik.setValues({...formik.values});
                          }
                          setState({ ...state })
                        }} 
                        />
                    </div> */}
                    <div className="position-relative w-100">
                        <CustomTextField
                            label={"Terms & Condition URL"}
                            placeholder="http://www.example.com/terms-and-conditions"
                            type="text"
                            inputRef    = {termsRef}
                            name="termsAndConditions"
                            value={formik.values.termsAndConditions}
                          onChange    = { formik.handleChange}
                        //   className   = {!state.termConditions && 'disabled'}
                        //   disabled    = {!state.termConditions}
                      
                        />
                    </div>
                </div>

        </>

    )

}



export default Domain

