import localforage from 'localforage';
import React, { Component, useContext, useEffect, useRef, useState } from 'react'

import CustomTextField from "../../../../../components/CustomTextField";
import LocationAutoCompleteField from "../../../../../components/LocationAutoCompleteField";


import { BrandContext } from '../../../../../context/Brand.context';
import CopyText from "../../../../../components/CopyText";
import { ColorScheme } from '../../../../../enums';
import { ColorSchemeCode } from '../../../../../enums/ColorScheme';
import Fade from 'react-reveal/Fade';


export function SetupCloudAnotherpart ({formik}) {

    const [state, setState]=useState({
      brand   : {},
      campaign: {},
    })

    const brandRef = useRef({
      brand : ''
    });

    useEffect(()=>{
      getBrand();
    },[formik.values, formik.errors])

    const getBrand = async()=>{
     let brand = await localforage.getItem('brand');
     let campaign = await localforage.getItem('campaign');
     setState({...state, brand : brand, campaign : campaign});
     brandRef.current.brand = brand;

    }

    console.log('state.campaign?.campaignCode ', state.campaign)

    return (
      <Fade bottom>
        <div>
        <h1 className='Heading24M'>CloudWaitress</h1>
        <p className='hedingbelowPara mb_32'>Please copy and paste following into your CloudWaitress account</p>
       <form>
          <div  className = "auth-form hpx-326">
            {console.log(' formik.values.cloudwaitress[formik.values.selectedCWIndex] ', window.location.hostname+"/api/v4/pass/webhook/"+state.brand?._id+"/"+state.campaign?.campaignCode+"/"+formik.values.cloudwaitress[formik.values.selectedCWIndex]?.key+"")}

            <div className="w-100 position-relative">
                <LocationAutoCompleteField
                  label     = "Integration ID"
                  type      = "text"
                  value     = {"https://"+window.location.hostname+"/api/v4/pass/webhook/"+state.brand?._id+"/"+state.campaign?.campaignCode+"/"+formik.values.cloudwaitress[formik.values.selectedCWIndex]?.key+""}
                  className = "lh-24"
                  disabled  = {true}
                  color     = '#00000042'
                />
                <div style={{ position: "absolute", top: "15px", right: "14px", background: ColorSchemeCode.white }}>
                  <CopyText id="integId" content={"https://"+window.location.hostname+"/api/v4/pass/webhook/"+state.brand?._id+"/"+state?.campaign?.campaignCode+"/"+formik.values.cloudwaitress[formik.values.selectedCWIndex]?.key+""} hideText={true} />
                </div>
              </div>
              <div className="w-100 position-relative">
                <LocationAutoCompleteField
                  label     = "API Key"
                  type      = "text"
                  className = "lh-24"
                  value     = {state?.brand?.apiKey}
                  disabled  = {true}
                  color     = '#00000042'
                />
                 <div style={{ position: "absolute", top: "15px", right: "14px", background: ColorSchemeCode.white }}>
                  <CopyText id="apiKey" content={state?.brand?.apiKey} hideText={true} />
                </div>


              </div>



              <div className="w-100 position-relative">
                <LocationAutoCompleteField
                  label     = "Brand ID"
                  type      = "text"
                  className = "lh-24"
                  value     = {state?.brand?._id}
                  disabled  = {true}
                  color     = '#00000042'
                />
                <div style={{ position: "absolute", top: "15px", right: "14px", background: ColorSchemeCode.white }}>
                  <CopyText id="brandId" content={state?.brand?._id} hideText={true} />
                </div>
              </div>
           
          </div>
        </form>
     </div>
     </Fade>
    )
  }


export default SetupCloudAnotherpart