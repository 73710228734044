import * as yup from 'yup';
import { enums, Fields } from '../enums';

const REQ_ERROR = 'This field is required'

const CustomFieldVld = yup.object({
    customFieldType     : yup.string().required(REQ_ERROR).oneOf([...Object.values(Fields.CustomFiedlTypes)]),
    key                 : yup.string().required(REQ_ERROR),
    subBrands: yup.string()
            // yup.mixed()
                // .when('customFieldType',{
                //     is   : value => value === Fields.CustomFiedlTypes.SUB_BRAND_FIELD,
                //     then : yup.string().required(REQ_ERROR)
                // })
                // .when('customFieldType',{
                //     is   : value => value !== Fields.CustomFiedlTypes.SUB_BRAND_FIELD,
                //     then : yup.string()
                // })
                ,
    type  : yup.string().required().oneOf([...Object.values(Fields.FieldTypes)]),


    label               : yup.string(),
    value               : yup.mixed()
                        .when('type',{
                            is   : value => value === Fields.FieldTypes.TEXT,
                            then : yup.string()
                        })
                        .when('type',{
                            is   : value => value === Fields.FieldTypes.DATE,
                            then : yup.date()
                        })
                        .when('type',{
                            is   : value => value === Fields.FieldTypes.NUMBER,
                            then : yup.number()
                        })
                        .when('type',{
                            is   : value => value === Fields.FieldTypes.BOOLEAN,
                            then : yup.boolean()
                        })
                        .when('type',{
                            is   : value => value === Fields.FieldTypes.URL,
                            then : yup.string().matches(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,'Enter correct url')
                        })
                        .when('type',{
                            is   : value => value === Fields.FieldTypes.IMAGE_URL,
                            then : yup.string().matches(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,'Enter correct url')
                        }),
    description    : yup.string(),
    brandCoverFile : yup.mixed()
                        .test('coverSize', 'File too large, it can be maximum of 5mb each.', value => value ? value.size <= enums.Images.LOGO_FILE_SIZE : true) // true will neglect the validation case
                        .test('coverType', 'Ivalid file type', value => value ? enums.Images.LOGO_SUPPORTED_FORMAT.includes(value.type) : true),
})



export {
    CustomFieldVld,
}