import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { LayoutContext } from "../../../context/Layout.context";
import { SvgIcons } from "../../../icons";
import CustomButton from "../../../components/CustomButton";
import { ClickOutside } from "../../../utils";
import { CircularProgress, IconButton } from "@mui/material";
import { ColorSchemeCode } from "../../../enums/ColorScheme";
import CustomTextField from "../../../components/CustomTextField";
import CustomSelect from "../../../components/CustomSelect";
import FitContentInput from "../../../components/FitContentInput";
import CustomSelectCopy from "../../../components/CustomSelect";
import { GrowthTools, Communication } from "../../../enums";
import * as localForage from "localforage";
import { BrandContext } from "../../../context/Brand.context";
import { Actions, Triggers } from "../../../enums/enums";
import { showToaster } from "../../../utils/utils";
import AutomationService from "../../../services/Automation.service";
import DeleteModal from "../../../components/DeleteModal";
import CustomsModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import {
  ManychatService,
  UchatService,
  CommunicationService,
  ChatraceService,
  DealService,
  FlowService,
} from "../../../services";

export default function AddRule() {
  const [show, setShow] = useState({
    triggerDropdown: false,
    actionDropDown: false,
  });

  const layout = useContext(LayoutContext);
  const brand = useContext(BrandContext);
  const history = useHistory();

  const [trigger, setTrigger] = useState([]);
  const [action, setAction] = useState([]);
  const [daysMonth, setdaysMonth] = useState("days");

  const [editStatus, setEditStatus] = useState(true);
  const [name, setName] = useState("Automation Name");
  const [loader, setLoader] = useState(false);
  const [deal, setDeal] = useState([]);
  const [flow, setFlow] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    if (name === "" && !editStatus) {
      showToaster({
        position: "bottom-right",
        title: "Title Error",
        message: "Title cannot be empty",
        severity: "error",
      });
      setName("Automation Name");
    }
  }, [editStatus]);

  const [data1, setData1] = useState({
    deal: [],
    flow: [],
    manychatFlow: [],
    uchatFlow: [],
    chatraceFlow: [],
    smsList: [],
  });
  const [data, setData] = useState({
    deal: [],
    flow: [],
    manychatFlow: [],
    uchatFlow: [],
    chatraceFlow: [],
    smsList: [],
  });

  const [triggerList, setTriggerList] = useState([
    {
      // icon : <SvgIcons.RuleCheckInIcon color={ColorSchemeCode.themeColor}/>,
      title: "Check In",
    },
    {
      // icon : <SvgIcons.RuleFlowIcon color={ColorSchemeCode.themeColor}/>,
      title: "Flow",
    },
    {
      // icon : <SvgIcons.RuleDealIcon color={ColorSchemeCode.themeColor}/>,
      title: "Deals",
    },
    {
      // icon : <SvgIcons.RuleDealIcon color={ColorSchemeCode.themeColor}/>,
      title: "Tags",
    },
    {
      // icon : <SvgIcons.RulePointsIcon color={ColorSchemeCode.themeColor}/>,
      title: "Points",
    },
    {
      // icon : <SvgIcons.RulePointsIcon color={ColorSchemeCode.themeColor}/>,
      title: "Points Update",
    },
    {
      // icon : <SvgIcons.RuleDaysIcon color={ColorSchemeCode.themeColor}/>,
      title: "Days Inactive",
    },
    {
      // icon : <SvgIcons.RuleDaysIcon color={ColorSchemeCode.themeColor}/>,
      title: "Days",
    },
    {
      // icon : <SvgIcons.RuleDaysIcon color={ColorSchemeCode.themeColor}/>,
      title: "Event",
    },
  ]);

  const [actionList, setActionList] = React.useState([
    {
      title: "Add Tag",
    },
    {
      title: "Remove Tag",
    },
    {
      title: "Give Deal",
    },
    {
      title: "Manychat",
    },
    {
      title: "U Chat",
    },
    {
      title: "Chatrace",
    },
    {
      title: "GHL",
    },
    {
      title: "Webhooks",
    },
    {
      title: "Email",
      className: "disabled",
    },
    {
      title: "SMS",
      className:
        brand &&
        brand.integrations &&
        brand.integrations.smsService &&
        brand.integrations.smsService.authToken
          ? ""
          : "disabled",
    },
    {
      title: "Welcome SMS",
      className:
        brand &&
        brand.integrations &&
        brand.integrations.smsService &&
        brand.integrations.smsService.authToken
          ? ""
          : "disabled",
    },
    {
      title: "Push Notification",
      className: "disabled",
    },
  ]);

  useEffect(async () => {
    setMainLoader(true);

    const deal = await localForage.getItem("deal");
    // let deal = []
    // const {response, error} = await DealService.Get({query: {brandId: brand._id, delete: false}})
    // // console.log('deal response',response);
    // if(response&&response.data&&response.data.length>0) deal=response.data
    // if(response&&response.data&&response.data.length>0) setDeal(response.data)

    const flow = await localForage.getItem("flow");
    // let flow = []
    // const tempflow = await FlowService.Get({query:{brandId: brand._id, delete: false}})
    // if(tempflow.response&&tempflow.response.data&&tempflow.response.data.length>0) flow=tempflow.response.data
    // if(tempflow.response&&tempflow.response.data&&tempflow.response.data.length>0) setFlow(tempflow.response.data)

    let UchatFlows = [];
    let ChatraceFlows = [];
    let ManychatFlows = [];
    let SMSList = [];

    if (
      brand &&
      brand.integrations &&
      brand.integrations.manychat &&
      brand.integrations.manychat.apiKey
    ) {
      const { response, error } = await ManychatService.GetFlows({
        query: { apiKey: brand.integrations.manychat.apiKey },
      });
      if (response) {
        setData({
          ...data,
          deal: deal,
          flow: flow,
          manychatFlow: response.data.flows,
        });
        setData1({
          ...data,
          deal: deal,
          flow: flow,
          manychatFlow: response.data.flows,
        });
        ManychatFlows = response.data.flows;
      } else {
        setData({ ...data, deal: deal, flow: flow });
        setData1({ ...data, deal: deal, flow: flow });
      }
    }

    if (
      brand &&
      brand.integrations &&
      brand.integrations.smsService &&
      brand.integrations.smsService.authToken
    ) {
      const query = {
        brandId: brand._id,
        communicationType: Communication.CommunicationTypes.SMS,
        // delete            : false
      };
      const { error, response } =
        await CommunicationService.GetQueriedCommunication({ query });
      // console.log(error, response);
      if (response) {
        setData({ ...data, deal: deal, flow: flow, smsList: response.data });
        setData1({ ...data, deal: deal, flow: flow, smsList: response.data });
        SMSList = response.data;
      } else {
        setData({ ...data, deal: deal, flow: flow });
        setData1({ ...data, deal: deal, flow: flow });
      }
    }

    if (
      brand &&
      brand.integrations &&
      brand.integrations.uchat &&
      brand.integrations.uchat.apiKey
    ) {
      const { response, error } = await UchatService.GetFlows({
        query: { apiKey: brand.integrations.uchat.apiKey },
      });
      if (response) {
        setData({ ...data, uchatFlow: response.data });
        setData1({ ...data, uchatFlow: response.data });
        UchatFlows = response.data;
      } else {
        setData({ ...data, deal: deal, flow: flow });
        setData1({ ...data, deal: deal, flow: flow });
      }
    }

    if (
      brand &&
      brand.integrations &&
      brand.integrations.chatrace &&
      brand.integrations.chatrace.apiKey
    ) {
      const { response, error } = await ChatraceService.GetFlows({
        query: { apiKey: brand.integrations.chatrace.apiKey },
      });
      if (response) {
        setData({ ...data, chatraceFlow: response.data });
        setData1({ ...data, chatraceFlow: response.data });
        ChatraceFlows = response.data;
      } else {
        setData({ ...data, deal: deal, flow: flow });
        setData1({ ...data, deal: deal, flow: flow });
      }
    } else {
      setData({ ...data, deal: deal, flow: flow });
      setData1({ ...data, deal: deal, flow: flow });
    }

    setData({
      ...data,
      flow: flow,
      deal: deal,
      uchatFlow: UchatFlows,
      manychatFlow: ManychatFlows,
      chatraceFlow: ChatraceFlows,
      smsList: SMSList,
    });
    setData1({
      ...data,
      flow: flow,
      deal: deal,
      uchatFlow: UchatFlows,
      manychatFlow: ManychatFlows,
      chatraceFlow: ChatraceFlows,
      smsList: SMSList,
    });

    if (history.location.state && history.location.state.edit) {
      setTrigger(history.location.state.frontEnd.trigger);
      setAction(history.location.state.frontEnd.action);
      setdaysMonth(history.location.state.daysMonth);
      history.location.state.frontEnd.trigger.map((v, i) => {
        if (v.name === "Check In") {
          triggerList[0].className = "disabled";
          if (v.checkIn !== GrowthTools.GrowhttoolTypes.MANYCHAT)
            actionList[2].className = "disabled";
        }
        if (v.name === "Points") triggerList[1].className = "disabled";
        if (v.name === "Deals") triggerList[2].className = "disabled";
        if (v.name === "Flow") triggerList[3].className = "disabled";
        if (v.name === "Days Inactive") triggerList[4].className = "disabled";
        if (v.name === "Days") triggerList[5].className = "disabled";
      });
      setActionList([...actionList]);
      setTriggerList([...triggerList]);
      setName(history.location.state.name);
    }
    setMainLoader(false);
  }, []);

  useEffect(() => {
    layout.setLayout({
      borderBottom: true,
      edit: true,
      switch      : "",
      placeholder: "Automation",
      backTitles: ["Communication", "Automation"],
      button: (
        <div>
          {history.location.state && history.location.state.edit && (
            <CustomButton
              btntext={"Delete"}
              varient="warningSecondary"
              onClick={() => setDeleteModal(true)}
            />
          )}
          <CustomButton
            btntext={"Cancel"}
            varient="secondary"
            className="ml_8"
            onClick={() => {
              const location = window.location.pathname.split("/");
              location.pop();
              history.push({
                pathname: location.join("/"),
                state: { tab: "1" },
              });
            }}
          />
          <CustomButton
            btntext={"Save changes"}
            varient={"primary"}
            disabled={loader}
            className="ml_8"
            icon={loader && <CircularProgress size={20} color={"inherit"} />}
            onClick={async () => {
              setLoader(true);

              let tempAction = [];
              let tempTrigger = [];

              trigger.map((value, index) => {
                // console.log("careeem", value);
                if (value[Triggers.CHECK_IN])
                  tempTrigger.push({
                    key: Triggers.CHECK_IN,
                    value: value[Triggers.CHECK_IN],
                  });
                if (value[Triggers.POINTS])
                  tempTrigger.push({
                    key: Triggers.POINTS,
                    value: parseInt(value[Triggers.POINTS]),
                  });
                if (value[Triggers.DEALS])
                  tempTrigger.push({
                    key: Triggers.DEALS,
                    value: value[Triggers.DEALS],
                  });
                if (value[Triggers.TAGS])
                  tempTrigger.push({
                    key: Triggers.TAGS,
                    value: value[Triggers.TAGS],
                  });
                if (value[Triggers.FLOW])
                  tempTrigger.push({
                    key: Triggers.FLOW,
                    value: value[Triggers.FLOW],
                  });
                if (value[Triggers.DAYS_INACTIVE])
                  tempTrigger.push({
                    key: Triggers.DAYS_INACTIVE,
                    value: parseInt(value[Triggers.DAYS_INACTIVE]),
                  });
                if (value[Triggers.DAYS])
                  tempTrigger.push({
                    key: Triggers.DAYS,
                    value: parseInt(value[Triggers.DAYS]),
                  });
                if (value.name === "Event")
                  tempTrigger.push({
                    key: Triggers.EVENT,
                    value: {
                      eventstart: value.eventstart,
                      eventend: value.eventend,
                      recurring: value.recurring,
                    },
                  });
                if (value.name.toLowerCase() == Triggers.POINTS_UPDATE)
                  tempTrigger.push({ key: Triggers.POINTS_UPDATE });
              });

              action.map((value, index) => {
                // console.log("value");
                if (value[Actions.ADD_TAG])
                  tempAction.push({
                    key: Actions.ADD_TAG,
                    value: value[Actions.ADD_TAG],
                  });
                if (value[Actions.REMOVE_TAG])
                  tempAction.push({
                    key: Actions.REMOVE_TAG,
                    value: value[Actions.REMOVE_TAG],
                  });
                if (value[Actions.MANYCHAT])
                  tempAction.push({
                    key: Actions.MANYCHAT,
                    value: value[Actions.MANYCHAT],
                  });
                if (value[Actions.U_CHAT])
                  tempAction.push({
                    key: Actions.U_CHAT,
                    value: value[Actions.U_CHAT],
                  });
                if (value[Actions.CHATRACE])
                  tempAction.push({
                    key: Actions.CHATRACE,
                    value: value[Actions.CHATRACE],
                  });
                if (value[Actions.GHL])
                  tempAction.push({
                    key: Actions.GHL,
                    value: value[Actions.GHL],
                  });
                if (value[Actions.WEBHOOKS])
                  tempAction.push({
                    key: Actions.WEBHOOKS,
                    value: value[Actions.WEBHOOKS],
                  });
                if (value[Actions.EMAIL])
                  tempAction.push({
                    key: Actions.EMAIL,
                    value: value[Actions.EMAIL],
                  });
                if (value[Actions.GIVE_DEAL])
                  tempAction.push({
                    key: Actions.GIVE_DEAL,
                    value: value[Actions.GIVE_DEAL],
                  });
                if (value[Actions.SMS])
                  tempAction.push({
                    key: Actions.SMS,
                    value: value[Actions.SMS],
                  });
                if (value[Actions.WELCOME_SMS])
                  tempAction.push({
                    key: Actions.WELCOME_SMS,
                    value: value[Actions.WELCOME_SMS],
                  });
                if (value[Actions.PUSH_NOTIFICATION])
                  tempAction.push({
                    key: Actions.PUSH_NOTIFICATION,
                    value: value[Actions.PUSH_NOTIFICATION],
                  });
              });
              // sadasd
              // console.log("tempAction", tempAction);
              const payload = {
                brandId: brand._id,
                name: layout.elements.editTitle,
                action: tempAction,
                trigger: tempTrigger,
                daysMonth: daysMonth,
                frontEnd: { action: action, trigger: trigger },
              };

              // console.log("payload ", payload);

              if (history.location.state && history.location.state.edit) {
                payload._id = history.location.state._id;
                const { response, error } = await AutomationService.Update({
                  payload,
                  toaster: true,
                });
                const location = window.location.pathname.split("/");
                location.pop();
                history.push({
                  pathname: location.join("/"),
                  state: { reload: true, tab: "1" },
                });
              } else {
                const { response, error } = await AutomationService.Create({
                  payload,
                  toaster: true,
                });
                const location = window.location.pathname.split("/");
                location.pop();
                history.push({
                  pathname: location.join("/"),
                  state: { reload: true, tab: "1" },
                });
              }
              setLoader(false);
            }}
          />
        </div>
      ),
    });
  }, [trigger, action, name, loader, daysMonth, layout.elements.editTitle]);

  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      history.location.state.name
    ) {
      layout.setLayout({
        editTitle: history.location.state.name || "",
      });
    }
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleDelete = async () => {
    setDeleteLoader(true);
    let payload = {
      _id: history.location.state._id,
      delete: true,
    };
    const { response, error } = await AutomationService.Update({
      toaster: true,
      payload,
    });
    if (response) {
      setDeleteLoader(true);
      setDeleteModal(false);
      const location = window.location.pathname.split("/");
      location.pop();
      history.push({
        pathname: location.join("/"),
        state: { reload: true, tab: "1" },
      });
    }
    if (error) {
      setDeleteLoader(true);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    let temp = []; //flows
    let temp1 = []; //deals
    let temp2 = []; //tags
    if (trigger && trigger.length > 0)
      for (const value of trigger) {
        // console.log(value);

        if (value.name === "Check In") {
          if (value.checkIn === "manychat") {
            for (const el of data1.flow) {
              for (const ele of el.flow) {
                if (
                  ele &&
                  ele.id === "1" &&
                  ele.data.value.platform === "Manychat"
                )
                  temp.push(el);
              }
            }
            setData({ ...data, flow: temp });
          }
          if (value.checkIn === "qrcode") {
            for (const el of data1.flow) {
              if (el.triggerType === "checkIn") temp.push(el);
            }
            setData({ ...data, flow: temp });
          }
          if (value.checkIn === "uchat") {
            for (const el of data1.flow) {
              for (const ele of el.flow) {
                if (
                  ele &&
                  ele.id === "1" &&
                  ele.data.value.platform === "uchat"
                )
                  temp.push(el);
              }
            }
            setData({ ...data, flow: temp });
          }
          if (value.checkIn === "chatrace") {
            for (const el of data1.flow) {
              for (const ele of el.flow) {
                if (
                  ele &&
                  ele.id === "1" &&
                  ele.data.value.platform === "chatrace"
                )
                  temp.push(el);
              }
            }
            setData({ ...data, flow: temp });
          }
          if (value.checkIn === "app") {
            for (const el of data1.flow) {
              for (const ele of el.flow) {
                if (ele && ele.id === "1" && ele.data.value.platform === "app")
                  temp.push(el);
              }
            }
            setData({ ...data, flow: temp });
          }
          if (value.checkIn === "ghl") {
            for (const el of data1.flow) {
              for (const ele of el.flow) {
                if (ele && ele.id === "1" && ele.data.value.platform === "ghl")
                  temp.push(el);
              }
            }
            setData({ ...data, flow: temp });
          }
          if (value.checkIn === "pos") {
            for (const el of data1.flow) {
              for (const ele of el.flow) {
                if (ele && ele.id === "1" && ele.data.value.platform === "pos")
                  temp.push(el);
              }
            }
            setData({ ...data, flow: temp });
          }
        }

        if (value.name === "Flow") {
          if (value.flow)
            for (const el of data.flow) {
              if (el._id === value.flow) {
                for (const ele of el.flow) {
                  if (
                    ele &&
                    ele.data &&
                    ele.data.customType &&
                    ele.data.customType === "deal"
                  )
                    // console.log(ele.data.value);
                  if (
                    ele &&
                    ele.data &&
                    ele.data.customType &&
                    ele.data.customType === "deal"
                  )
                    temp1.push({
                      ...ele.data.value,
                      name: ele.data.value.deal,
                      _id: ele.data.value.id,
                    });
                  if (
                    ele &&
                    ele.data &&
                    ele.data.customType &&
                    ele.data.customType === "deal" &&
                    ele.data.value.dealeligible &&
                    ele.data.value.dealexpire &&
                    ele.data.value.dealredeem
                  ) {
                    temp2.push(ele.data.value.dealeligible);
                    temp2.push(ele.data.value.dealexpire);
                    temp2.push(ele.data.value.dealredeem);
                  }
                }
                setData({ ...data, tags: temp2, deal: temp1 });
              }
            }
        }

        // if(value.name==="Deal"){
        //     if(value.flow)
        //     for (const el of data.flow) {
        //         if(el._id===value.flow){
        //             for (const ele of el.flow) {
        //                 if(ele&&ele.data&&ele.data.customType&&ele.data.customType==='deal'){
        //                     // console.log(ele);
        //                     // temp1.push({...ele.data.value, name: ele.data.value.deal, _id: ele.data.value.id})
        //                 }
        //             }
        //             setData({...data, deal: temp1})
        //         }
        //     }
        // }
      }
  }, [trigger]);

  return (
    <div id="CreateRule">
      {console.log(trigger)}
      {console.log(triggerList)}
      {console.log(data)}
      {console.log("flow", flow)}
      {console.log("deal", deal)}
      {/* {console.log('====================================')}
        {console.log('hhhhh',history)}
        {console.log('====================================')} */}
      {mainLoader ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="Body16R color-neutral60">When this happens</div>
            {trigger &&
              trigger.length > 0 &&
              trigger.map((value, index) => (
                <div
                  style={{
                    border: "1px solid #E6E6E6",
                    boxShadow: "0px 0px 12px rgba(128, 128, 128, 0.24)",
                    borderRadius: "6px",
                    background: "#FFFFFF",
                    padding: "8px 16px",
                    marginBottom: "13px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex" style={{ padding: "10px 12px" }}>
                    {value.name === "Check In" && <SvgIcons.RuleCheckInIcon />}
                    {value.name === "Points" && <SvgIcons.RulePointsIcon />}
                    {value.name === "Points Update" && (
                      <SvgIcons.RulePointsIcon />
                    )}
                    {value.name === "Deals" && <SvgIcons.RuleDealIcon />}
                    {value.name === "Tags" && <SvgIcons.RuleDealIcon />}
                    {value.name === "Flow" && <SvgIcons.RuleFlowIcon />}
                    {value.name === "Days Inactive" && (
                      <SvgIcons.RuleDaysIcon />
                    )}
                    {value.name === "Days" && <SvgIcons.RuleDaysIcon />}
                    {value.name === "Event" && <SvgIcons.RuleDaysIcon />}

                    {value.name === "Check In" && (
                      <div className="Link14M color-neutral100 ml_8">
                        Check In
                      </div>
                    )}
                    {value.name === "Points" && (
                      <div className="Link14M color-neutral100 ml_8">Points</div>
                    )}
                    {value.name === "Points Update" && (
                      <div className="Link14M color-neutral100 ml_8">
                        Points Update
                      </div>
                    )}
                    {value.name === "Deals" && (
                      <div className="Link14M color-neutral100 ml_8">Deals</div>
                    )}
                    {value.name === "Tags" && (
                      <div className="Link14M color-neutral100 ml_8">Tags</div>
                    )}
                    {value.name === "Flow" && (
                      <div className="Link14M color-neutral100 ml_8">Flow</div>
                    )}
                    {value.name === "Days Inactive" && (
                      <div className="Link14M color-neutral100 ml_8">
                        Days Inactive
                      </div>
                    )}
                    {value.name === "Days" && (
                      <div className="Link14M color-neutral100 ml_8">Days</div>
                    )}
                    {value.name === "Event" && (
                      <div className="Link14M color-neutral100 ml_8">Event</div>
                    )}
                  </div>
                  <div
                    className="d-flex align-items-center w-100"
                    style={{ padding: "10px 12px" }}
                  >
                    {value.name === "Check In" && (
                      <div className="Body14R color-neutral100">
                        When customers check-ins through{" "}
                      </div>
                    )}
                    {value.name === "Points" && (
                      <div className="Body14R color-neutral100">
                        Check if customer reached{" "}
                      </div>
                    )}
                    {value.name === "Points Update" && (
                      <div className="Body14R color-neutral100">
                        Check if customer points update.{" "}
                      </div>
                    )}
                    {value.name === "Deals" && (
                      <div className="Body14R color-neutral100">
                        If customer avail{" "}
                      </div>
                    )}
                    {value.name === "Tags" && (
                      <div className="Body14R color-neutral100">
                        If customer tagged with{" "}
                      </div>
                    )}
                    {value.name === "Flow" && (
                      <div className="Body14R color-neutral100">
                        If customer is added to{" "}
                      </div>
                    )}
                    {value.name === "Days Inactive" && (
                      <div className="Body14R color-neutral100">
                        If customer is inactive for{" "}
                      </div>
                    )}
                    {value.name === "Days" && (
                      <div className="Body14R color-neutral100">After </div>
                    )}

                    {value.name === "Check In" && (
                      <div className="ml_8 Link14M color-neutral100 underline">
                        <select
                          value={value.checkIn || ""}
                          className="w-100 border-none underline"
                          onChange={(e) => {
                            // console.log("1");
                            let tempManychat = [];
                            // console.log("2");
                            let uchat = [];
                            // console.log("3");
                            let chatrace = [];
                            // console.log("actionList", actionList);

                            // for (const [i, v] of actionList) {
                            // }
                            actionList.map((v, i) => {
                              // console.log("4");
                              if (
                                v.title === "Manychat" &&
                                e.target.value !==
                                  GrowthTools.GrowhttoolTypes.MANYCHAT
                              ) {
                                // console.log("5");
                                actionList[i] = {
                                  title: "Manychat",
                                  className: "disabled",
                                };
                                // console.log("6");
                              }
                              if (
                                v.title === "Manychat" &&
                                e.target.value ===
                                  GrowthTools.GrowhttoolTypes.MANYCHAT
                              ) {
                                // console.log("7");
                                actionList[i] = { title: "Manychat" };
                                // console.log("8");
                                for (const el of data1.flow) {
                                  // console.log("9");
                                  if (
                                    el &&
                                    el.id === "1" &&
                                    el.data.value.platform &&
                                    el.data.value.platform === "manychat"
                                  ) {
                                    // console.log("10");
                                    tempManychat.push(el);
                                    // console.log("11");
                                  }
                                  // console.log("12");
                                }
                                // console.log("data", data);
                                setData({ ...data, flow: tempManychat });
                                // console.log("13");
                              }
                              if (
                                v.title === "U Chat" &&
                                e.target.value !==
                                  GrowthTools.GrowhttoolTypes.UCHAT
                              ) {
                                // console.log("14");
                                actionList[i] = {
                                  title: "U Chat",
                                  className: "disabled",
                                };
                                // console.log("15");
                              }
                              if (
                                v.title === "U Chat" &&
                                e.target.value ===
                                  GrowthTools.GrowhttoolTypes.UCHAT
                              ) {
                                // console.log("16");
                                actionList[i] = { title: "U Chat" };
                                // console.log("17");
                                for (const el of data1.flow) {
                                  // console.log("18");
                                  if (
                                    el &&
                                    el.id === "1" &&
                                    el.data.value.platform &&
                                    el.data.value.platform === "uChat"
                                  ) {
                                    // console.log("19");
                                    uchat.push(el);
                                    // console.log("20");
                                  }
                                  // console.log("21");
                                }
                                // console.log("data", data);
                                setData({ ...data, flow: uchat });
                                // console.log("22");
                              }
                              if (
                                v.title === "Chatrace" &&
                                e.target.value !==
                                  GrowthTools.GrowhttoolTypes.CHATRACE
                              ) {
                                // console.log("23");
                                actionList[i] = {
                                  title: "Chatrace",
                                  className: "disabled",
                                };
                                // console.log("24");
                              }
                              if (
                                v.title === "Chatrace" &&
                                e.target.value ===
                                  GrowthTools.GrowhttoolTypes.CHATRACE
                              ) {
                                // console.log("25");
                                actionList[i] = { title: "Chatrace" };
                                // console.log("26");
                                for (const el of data1.flow) {
                                  // console.log("27");
                                  if (
                                    el &&
                                    el.id === "1" &&
                                    el.data.value.platform &&
                                    el.data.value.platform === "chatrace"
                                  ) {
                                    // console.log("28");
                                    chatrace.push(el);
                                    // console.log("29");
                                  }
                                }
                                // console.log("data", data);
                                setData({ ...data, flow: chatrace });
                                // console.log("30");
                              }
                            });
                            // console.log("actionList", actionList);
                            setActionList([...actionList]);
                            // console.log("e.target.value", e.target.value);
                            trigger[index].checkIn = e.target.value;
                            // console.log("trigger", trigger);
                            setTrigger([...trigger]);
                            // console.log("31");
                          }}
                        >
                          <option value={""}>{"None"}</option>
                          <option value={GrowthTools.GrowhttoolTypes.QR_CODE}>
                            {GrowthTools.GrowhttoolTypes.QR_CODE}
                          </option>
                          <option value={GrowthTools.GrowhttoolTypes.MANYCHAT}>
                            {GrowthTools.GrowhttoolTypes.MANYCHAT}
                          </option>
                          <option value={GrowthTools.GrowhttoolTypes.UCHAT}>
                            {GrowthTools.GrowhttoolTypes.UCHAT}
                          </option>
                          <option value={GrowthTools.GrowhttoolTypes.CHATRACE}>
                            {GrowthTools.GrowhttoolTypes.CHATRACE}
                          </option>
                          <option value={GrowthTools.GrowhttoolTypes.APP}>
                            {GrowthTools.GrowhttoolTypes.APP}
                          </option>
                          <option value={GrowthTools.GrowhttoolTypes.GHL}>
                            {GrowthTools.GrowhttoolTypes.GHL}
                          </option>
                          <option value={GrowthTools.GrowhttoolTypes.POS}>
                            {GrowthTools.GrowhttoolTypes.POS}
                          </option>
                        </select>
                        {/* Select check-in method */}
                      </div>
                    )}
                    {/* {console.log('data',data)} */}
                    {value.name === "Event" && (
                      <div
                        className="d-flex align-items-center ml_8 Link14M color-neutral100 "
                        style={{ flexDirection: "column" }}
                      >
                        <div className="d-flex align-items-center space-between ml_8 Link14M color-neutral100 w-100">
                          <div className="Body14R  color-neutral100">
                            Start Date{" "}
                          </div>
                          <input
                            value={value.eventstart || 0}
                            type="date"
                            className="w-30 mr_4 border-none underline"
                            onChange={(e) => {
                              trigger[index].eventstart = e.target.value;
                              setTrigger([...trigger]);
                            }}
                          />
                          <div className="Body14R color-neutral100 mr_4 ml_30">
                            End Date{" "}
                          </div>
                          <input
                            value={value.eventend || 0}
                            type="date"
                            className="w-30 mr_4 border-none underline"
                            onChange={(e) => {
                              trigger[index].eventend = e.target.value;
                              setTrigger([...trigger]);
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center ml_8 Link14M color-neutral100 w-100">
                          <div className="Body14R color-neutral100 mr_4 ml_4">
                            Recurring Days{" "}
                          </div>
                          <input
                            value={value.recurring || 0}
                            type="number"
                            className="w-25 mr_4 border-none underline"
                            onChange={(e) => {
                              trigger[index].recurring = e.target.value;
                              setTrigger([...trigger]);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {value.name === "Points" && (
                      <div className="d-flex align-items-center ml_8 Link14M color-neutral100 underline">
                        <input
                          value={value.points || 0}
                          type="number"
                          className="w-25 mr_4 border-none underline"
                          onChange={(e) => {
                            trigger[index].points = e.target.value;
                            setTrigger([...trigger]);
                          }}
                        />
                        Points
                      </div>
                    )}
                    {value.name === "Points Update" && (
                      <div className="d-flex align-items-center ml_8 Link14M color-neutral100 underline">
                        {/* <input 
                                            value     = {value.points || 0}
                                            type      = "number"
                                            className = "w-25 mr_4 border-none underline"
                                            onChange  = {(e)=>{
                                                trigger[index].points = e.target.value
                                                setTrigger([...trigger])
                                            }}
                                        /> */}
                        {/* Points */}
                      </div>
                    )}
                    {value.name === "Deals" && (
                      <div className="d-flex align-items-center ml_8 Link14M color-neutral100 underline">
                        {console.log("in")}
                        <div className="mr_4">
                          <select
                            value={value.deals || ""}
                            className="w-100 border-none underline"
                            onChange={(e) => {
                              trigger[index].deals = e.target.value;
                              setTrigger([...trigger]);
                            }}
                          >
                            {data.deal &&
                              data.deal.length > 0 &&
                              data.deal.map((value, index) =>
                                index === 0 ? (
                                  <>
                                    {console.log("====>", value)}
                                    <option value={""}>{"None"}</option>
                                    <option value={value._id}>
                                      {value.name}
                                    </option>
                                  </>
                                ) : (
                                  <option value={value._id}>
                                    {value.name}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                        Deal
                      </div>
                    )}
                    {value.name === "Tags" && (
                      <div className="d-flex align-items-center ml_8 Link14M color-neutral100 underline">
                        <div className="mr_4">
                          <select
                            value={value.tags || ""}
                            className="w-100 border-none underline"
                            onChange={(e) => {
                              trigger[index].tags = e.target.value;
                              setTrigger([...trigger]);
                            }}
                          >
                            {data.tags &&
                              data.tags.length > 0 &&
                              data.tags.map((value, index) =>
                                index === 0 ? (
                                  <>
                                    <option value={""}>{"None"}</option>
                                    <option value={value}>{value}</option>
                                  </>
                                ) : (
                                  <option value={value}>{value}</option>
                                )
                              )}
                          </select>
                        </div>
                      </div>
                    )}
                    {value.name === "Flow" && (
                      <div className="d-flex align-items-center ml_8 Link14M color-neutral100 underline">
                        <div className="mr_4">
                          <select
                            value={value.flow || ""}
                            className="w-100 border-none underline"
                            onChange={(e) => {
                              trigger[index].flow = e.target.value;
                              setTrigger([...trigger]);
                            }}
                          >
                            {data.flow &&
                              data.flow.length > 0 &&
                              data.flow.map((value, index) =>
                                index === 0 ? (
                                  <>
                                    <option value={""}>{"None"}</option>
                                    <option value={value._id}>
                                      {value.name}
                                    </option>
                                  </>
                                ) : (
                                  <option value={value._id}>
                                    {value.name}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                        Flow
                      </div>
                    )}
                    {value.name === "Days Inactive" && (
                      <div className="d-flex align-items-center ml_8 Link14M color-neutral100 underline">
                        <input
                          value={value.daysInactive || 0}
                          type="number"
                          className="w-25 mr_4 border-none underline"
                          onChange={(e) => {
                            trigger[index].daysInactive = e.target.value;
                            setTrigger([...trigger]);
                          }}
                        />
                        days
                      </div>
                    )}
                    {value.name === "Days" && (
                      <div className="d-flex align-items-center ml_8 Link14M color-neutral100 underline">
                        <input
                          value={value.days || 0}
                          type="number"
                          className="w-25 mr_4 border-none underline"
                          onChange={(e) => {
                            trigger[index].days = e.target.value;
                            setTrigger([...trigger]);
                          }}
                        />
                        <select
                          value={daysMonth}
                          className="w-100 border-none underline"
                          onChange={(e) => setdaysMonth(e.target.value)}
                        >
                          <option value={"days"}>{"Days"}</option>
                          <option value={"hours"}>{"Hours"}</option>
                        </select>
                      </div>
                    )}
                  </div>
                  <div>
                    <IconButton
                      className="position-absolute deleteIcon"
                      style={{ top: "0px", right: "0px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        triggerList[value.idx] = {
                          title: value.name,
                          icon: value.icon,
                        };
                        setTriggerList([...triggerList]);
                        trigger.splice(index, 1);
                        setTrigger([...trigger]);
                        // // console.log('value',value);
                        if (value.name === "Check In")
                          setData({ ...data, flow: data1.flow });
                        if (value.name === "Flow")
                          setData({ ...data, deal: data1.deal });
                      }}
                    >
                      <SvgIcons.CrossIcon
                        height="15"
                        width="11.67"
                        color={ColorSchemeCode.Paragraph}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            <div
              className="triggerBox middle cp position-relative"
              onClick={() => setShow({ ...show, triggerDropdown: true })}
            >
              <div className="Heading16M">
                <span className="mr_8 addIcon">
                  <SvgIcons.Add />
                </span>
                Triggers
              </div>
              <ClickOutside
                onClick={() => setShow({ ...show, triggerDropdown: false })}
              >
                {show.triggerDropdown && (
                  <div className="triggerBoxDropdown pt_8 pb_8">
                    {triggerList.map((element, idx) => (
                      <div
                        onClick={() => {
                          setTrigger([
                            ...trigger,
                            {
                              name: element.title,
                              idx: idx,
                              icon: element.icon,
                            },
                          ]);
                          triggerList[idx] = {
                            title: element.title,
                            icon: element.icon,
                            className: "disabled",
                          };
                          setTriggerList([...triggerList]);
                          setShow({ ...show, triggerDropdown: false });
                        }}
                        key={idx}
                        className={
                          "singleRowDetail Link14M color-neutral100 d-flex " +
                          element.className
                        }
                      >
                        <span className={"mr_8"}>{element.icon}</span>
                        <div>{element.title}</div>
                      </div>
                    ))}
                  </div>
                )}
              </ClickOutside>
            </div>
          </div>
          <div className="col-md-6">
            <div className=" Body16R color-neutral60">
              Perform these actions{" "}
              <span className="underline color-neutral100">
                any number of times
              </span>
            </div>
            {action && action.length > 0 && (
              <div
                style={{
                  border: "1px solid #E6E6E6",
                  boxShadow: "0px 0px 12px rgba(128, 128, 128, 0.24)",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  padding: "8px 16px",
                  marginBottom: "13px",
                }}
              >
                {action.map((value, index) => (
                  <div className="mb_16">
                    <div className="mt_10 mb_10 ml_12 Link14M color-neutral100">
                      {value.name === "Add Tag" && "Add Tag"}
                      {value.name === "Remove Tag" && "Remove Tag"}
                      {value.name === "Manychat" && "Manychat"}
                      {value.name === "U Chat" && "U Chat"}
                      {value.name === "GHL" && "GHL"}
                      {value.name === "Webhooks" && "Webhooks"}
                      {value.name === "SMS" && "SMS"}
                      {value.name === "Welcome SMS" && "Welcome SMS"}
                      {value.name === "Give Deal" && "Give Deal"}
                    </div>
                    <div className="row">
                      <div className="col-11 align-self-center">
                        {value.name === "Add Tag" && (
                          <CustomTextField
                            label="Tag"
                            value={value.addTag || ""}
                            onChange={(e) => {
                              action[index].addTag = e.target.value;
                              setAction([...action]);
                            }}
                            className="w-100"
                          />
                        )}
                        {value.name === "Remove Tag" && (
                          <CustomTextField
                            label="Tag"
                            value={value.removeTag || ""}
                            onChange={(e) => {
                              action[index].removeTag = e.target.value;
                              setAction([...action]);
                            }}
                            className="w-100"
                          />
                        )}
                        {value.name === "Give Deal" && (
                          <div className="d-flex">
                            <div className="Body14R color-neutral100 mr_10">
                              Give customers deal{" "}
                            </div>
                            <div>
                              <select
                                className="w-100 border-none underline Link14M color-neutral100"
                                value={value.givedeal || ""}
                                onChange={(e) => {
                                  action[index].givedeal = e.target.value;
                                  setAction([...action]);
                                }}
                              >
                                <>
                                  {data.deal &&
                                    data.deal.length > 0 &&
                                    data.deal.map((v, i) =>
                                      i === 0 ? (
                                        <>
                                          <option value={""}>{"None"}</option>
                                          <option value={v._id}>
                                            {v.name}
                                          </option>
                                        </>
                                      ) : (
                                        <option value={v._id}>{v.name}</option>
                                      )
                                    )}
                                </>
                              </select>
                            </div>
                          </div>
                        )}
                        {value.name === "Manychat" && (
                          <select
                            className="w-100 border-none underline Link14M color-neutral100"
                            value={value.manychat || ""}
                            onChange={(e) => {
                              action[index].manychat = e.target.value;
                              setAction([...action]);
                            }}
                          >
                            <>
                              {data.manychatFlow &&
                                data.manychatFlow.length > 0 &&
                                data.manychatFlow.map((v, i) => (
                                  <option value={v.ns}>{v.name}</option>
                                ))}
                            </>
                          </select>
                        )}
                        {value.name === "U Chat" && (
                          <select
                            className="w-100 border-none underline Link14M color-neutral100"
                            value={value.uChat || ""}
                            onChange={(e) => {
                              action[index].uChat = e.target.value;
                              setAction([...action]);
                            }}
                          >
                            <>
                              {data.uchatFlow &&
                                data.uchatFlow.length > 0 &&
                                data.uchatFlow.map((v, i) => (
                                  <option value={v.sub_flow_ns}>
                                    {v.name}
                                  </option>
                                ))}
                            </>
                          </select>
                        )}
                        {value.name === "Chatrace" && (
                          <select
                            className="w-100 border-none underline Link14M color-neutral100"
                            value={value.chatrace || ""}
                            onChange={(e) => {
                              action[index].chatrace = e.target.value;
                              setAction([...action]);
                            }}
                          >
                            <>
                              {data.uchatFlow &&
                                data.chatraceFlow.length > 0 &&
                                data.chatraceFlow.map((v, i) => (
                                  <option value={v.id}>{v.name}</option>
                                ))}
                            </>
                          </select>
                        )}
                        {value.name === "GHL" && (
                          <CustomSelect
                            className="w-100"
                            value={"ghl Flow"}
                            options={[{
                              name  : 'ghl Flow',
                              value : 'ghl Flow'
                               },
                                {
                                  name  : 'ghl Flow',
                                  value : 'ghl Flow'
                                }]
                              // <>
                              //   <option value="ghl Flow">ghl Flow</option>
                              //   <option value="ghl Flow">ghl Flow</option>
                              // </>
                            }
                          />
                        )}

                        {value.name === "SMS" && (
                          <select
                            className="w-100 border-none underline Link14M color-neutral100"
                            value={value.SMS || action[index].sms}
                            onChange={(e) => {
                              action[index].sms = e.target.value;
                              setAction([...action]);
                            }}
                          >
                            <>
                              <option value="">None</option>
                              {data.smsList &&
                                data.smsList.length > 0 &&
                                data.smsList.map((v, i) => (
                                  <option value={v._id}>
                                    {v.communicationName}
                                  </option>
                                ))}
                            </>
                          </select>
                        )}
                        {value.name === "Welcome SMS" && (
                          <select
                            className="w-100 border-none underline Link14M color-neutral100"
                            value={value.SMS || action[index].welcome_sms}
                            onChange={(e) => {
                              action[index].welcome_sms = e.target.value;
                              setAction([...action]);
                            }}
                          >
                            <>
                              <option value="">None</option>
                              {data.smsList &&
                                data.smsList.length > 0 &&
                                data.smsList.map((v, i) => (
                                  <option value={v._id}>
                                    {v.communicationName}
                                  </option>
                                ))}
                            </>
                          </select>
                        )}

                        {value.name === "Webhooks" && (
                          <CustomTextField
                            label="URL"
                            value={value.webhooks || ""}
                            onChange={(e) => {
                              action[index].webhooks = e.target.value;
                              setAction([...action]);
                            }}
                            className="w-100"
                          />
                        )}
                      </div>
                      <IconButton
                        className="deleteIcon"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          action.splice(index, 1);
                          setAction([...action]);
                        }}
                      >
                        <SvgIcons.CrossIcon color={ColorSchemeCode.Paragraph} />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div
              className="actionBox middle cp position-relative"
              onClick={() => setShow({ ...show, actionDropDown: true })}
            >
              <div className="Heading16M">
                <span className="mr_8 addIcon">
                  <SvgIcons.Add />
                </span>
                Actions
              </div>
              <ClickOutside
                onClick={() => setShow({ ...show, actionDropDown: false })}
              >
                {show.actionDropDown && (
                  <div className="triggerBoxDropdown pt_8 pb_8">
                    {actionList.map((element, idx) => (
                      <div
                        onClick={() => {
                          if (element.className === "disabled") return;
                          setAction([...action, { name: element.title }]);
                          setShow({ ...show, actionDropDown: false });
                        }}
                        key={idx}
                        className="singleRowDetail Link14M color-neutral100 d-flex"
                      >
                        <div className={element.className && element.className}>
                          {element.title}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ClickOutside>
            </div>
          </div>
        </div>
      )}

      <CustomsModal
        open={deleteModal}
        minWidth={"600px"}
        component={
          <DeleteModal
            icon={deleteLoader}
            title="Flow"
            onCancel={() => setDeleteModal(false)}
            onDelete={handleDelete}
          />
        }
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}
